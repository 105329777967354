import {endOfMonth} from "date-fns";
import {startOfMonth} from "date-fns";
import {isValid} from "date-fns";
import {differenceInMilliseconds} from "date-fns";
import {parse} from "date-fns";
import {isThisWeek} from "date-fns";
import {isYesterday} from "date-fns";
import {isToday} from "date-fns";
import {format} from "date-fns";
import {isEmpty} from "lodash";
import {StudioBuildTime} from "../../api/meta/base/dto/StudioBuildTime";
import {TimeZoneKey} from "../../api/meta/base/Types";
import {AnyTime} from "../../api/meta/base/Types";
import DateTimeFormat from "../../nucleus/atom/assets/DateFormat.json";
import DisplayDateFormat from "../../nucleus/atom/assets/DateFormat.json";
import timeZoneArray from "../../nucleus/atom/assets/TimeZone.json";
import {logError} from "../util/AppLog";
import {nowDate} from "./CalendarPlus";

interface TimezoneInfo
{
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string[];
}

const FORMAT_DETAILED_DATE_TIME = "MM/dd/yyyy, hh:mm:ss a";
const FORMAT_DEFAULT_DATE = "MM/dd/yyyy";
let DATE_TIME_FORMAT: string;

// should return in locale specific string
export function formatCaptionDateTime(date: Date, includeTime?: boolean)
{
  if(isToday(date))
  {
    if(Boolean(includeTime))
    {
      return `today at ${format(date, "hh:mm a")}`;
    }
    else
    {
      return format(date, "hh:mm a");
    }
  }
  else if(isYesterday(date))
  {
    if(Boolean(includeTime))
    {
      return `yesterday at ${format(date, "hh:mm a")}`;
    }
    else
    {
      return "Yesterday";
    }
  }
  else if(isThisWeek(date))
  {
    if(Boolean(includeTime))
    {
      return `${format(date, "EEEE").toLowerCase()} at ${format(date, "hh:mm a")}`;
    }
    else
    {
      return format(date, "EEEE");
    }
  }
  else
  {
    return formatDate(
      formatDateToISO(date),
      "local",
      undefined,
      includeTime
    ) as string;
  }
}

export function formatChatInfoDate(date: Date, dateFormat?: string)
{
  if(isToday(date))
  {
    return "Today";
  }
  else if(isYesterday(date))
  {
    return "Yesterday";
  }
  else if(isThisWeek(date))
  {
    return format(date, "EEEE");
  }
  else
  {
    return formatDate(formatDateToISO(date), dateFormat || "local");
  }
}

// should return in locale specific string
export function formatCaptionOnlyTime(date: Date, showSeconds?: boolean)
{
  return showSeconds ? format(date, "hh:mm:ss a") : format(date, "hh:mm a");
}

export function extractTimeFormat(format: string, showSeconds?: boolean)
{
  const parts = format.split(",").map(part => part.trim());

  if(parts.length > 1)
  {
    let timeFormat = parts[1];

    if(!showSeconds)
    {
      timeFormat = timeFormat.replace(/:ss/, "");
      timeFormat = timeFormat.replace(/:s/, "");
    }
    return timeFormat;
  }

  return "hh:mm a";
}

export function dateToDefaultDateString(date: Date, includeTime?: boolean, time?: AnyTime): string
{
  if(includeTime && time)
  {
    return formatWithCustomTime(date, time);
  }
  else if(Boolean(includeTime))
  {
    return format(date, FORMAT_DETAILED_DATE_TIME);
  }
  else
  {
    return format(formatDateToUTC(date), FORMAT_DEFAULT_DATE);
  }
}

function formatWithCustomTime(date: Date, time: AnyTime): string
{
  const split = time.split(":");
  const hour = parseInt(split[0]);
  const min = parseInt(split[1]);
  const sec = parseInt(split[2]);

  const formattedDate = formatDateToUTC(date);
  formattedDate.setHours(hour);
  formattedDate.setMinutes(min);
  formattedDate.setSeconds(sec);

  return formatDateToISO(formattedDate);
}

export function formatDateToISO(date: Date)
{
  return date.toISOString();
}

export function dateToLocalString(value: string)
{
  const date = new Date(value);
  return date.toLocaleDateString();
}

// should return in locale specific string
export function formatDetailedDateTime(date: Date): string
{
  return format(date, FORMAT_DETAILED_DATE_TIME);
}

export function formatDetailedNow(): string
{
  return formatDetailedDateTime(new Date());
}

export function isDateWithinRange(dateNow: Date, oldDateStr: string, timeInMilliSeconds: number): boolean
{
  const oldDate = parse(oldDateStr, FORMAT_DETAILED_DATE_TIME, new Date());
  return differenceInMilliseconds(dateNow, oldDate) < timeInMilliSeconds;
}

export function toDateFromDetailedDateTime(detailedDateTime: string): Date
{
  return new Date(detailedDateTime);
}

export function getDateFromString(value?: string | number | null)
{
  if(!value)
  {
    return "";
  }
  return formatCaptionDateTime(new Date(value), true);
}

export function isoDateTimeNow(): string
{
  return formatDateToISO(new Date());
}

export function toTimeStringToDate(date: string)
{
  if(!date || isEmpty(date) || date.length === 0)
  {
    return undefined;
  }

  let tempTime = date.split(":");
  let dt = new Date();

  const hour = parseFloat(tempTime[0]);
  const minute = parseFloat(tempTime[1]);
  const second = parseFloat(tempTime[2]);

  hour !== undefined && dt.setHours(hour);
  minute !== undefined && dt.setMinutes(minute);
  second !== undefined && dt.setSeconds(second);

  return dt;
}

function convertMilliseconds(milliseconds: number): {
  years: number,
  months: number,
  days: number,
  hours: number,
  minutes: number,
  seconds: number
}
{
  const seconds: number = Math.floor(milliseconds / 1000);
  const minutes: number = Math.floor(seconds / 60);
  const hours: number = Math.floor(minutes / 60);
  const days: number = Math.floor(hours / 24);
  const months: number = Math.floor(days / 30.44); // Average number of days in a month
  const years: number = Math.floor(months / 12);

  const remainingSeconds: number = seconds % 60;
  const remainingMinutes: number = minutes % 60;
  const remainingHours: number = hours % 24;
  const remainingDays: number = days % 30.44;
  const remainingMonths: number = months % 12;

  return {
    years,
    months: remainingMonths,
    days: remainingDays,
    hours: remainingHours,
    minutes: remainingMinutes,
    seconds: remainingSeconds
  };
}

type TypeTimeUnits =
  | "years"
  | "year"
  | "months"
  | "month"
  | "days"
  | "day"
  | "hours"
  | "hour"
  | "minutes"
  | "minute"
  | "seconds"
  | "second"

interface ITimeUnits
{
  label: TypeTimeUnits;
  value: number;
}

function toTimeUnitLabel(value: number, label: TypeTimeUnits): TypeTimeUnits
{
  return (value === 0 || value === 1) ? label : label + "s" as TypeTimeUnits;
}

const getTimeUnit = (value: number, label: TypeTimeUnits) =>
{
  if(value > 0)
  {
    return {
      label: toTimeUnitLabel(value, label),
      value: value
    };
  }
};

export function convertMillisecondsToLargestTwoUnit(milliseconds: number): {curr?: ITimeUnits, prev?: ITimeUnits}
{
  const {
    years,
    months,
    days,
    hours,
    minutes,
    seconds
  } = convertMilliseconds(milliseconds);

  if(years > 0)
  {
    return {
      curr: getTimeUnit(years, "year"),
      prev: getTimeUnit(months, "month")
    };
  }
  else if(months > 0)
  {
    return {
      curr: getTimeUnit(months, "month"),
      prev: getTimeUnit(days, "day")
    };
  }
  else if(days > 0)
  {
    return {
      curr: getTimeUnit(days, "day"),
      prev: getTimeUnit(hours, "hour")
    };
  }
  else if(hours > 0)
  {
    return {
      curr: getTimeUnit(hours, "hour"),
      prev: getTimeUnit(minutes, "minute")
    };
  }
  else if(minutes > 0)
  {
    return {
      curr: getTimeUnit(minutes, "minute"),
      prev: getTimeUnit(seconds, "second")
    };
  }
  else
  {
    return {
      curr: getTimeUnit(seconds, "second")
    };
  }
}

export function formatDateToUTC(date: Date): Date
{
  // Directly get UTC components from the input date
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth();
  const utcDate = date.getUTCDate();
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const utcSeconds = date.getUTCSeconds();
  const utcMs = date.getUTCMilliseconds();

  // Create new UTC date preserving all components
  const utcNumber = Date.UTC(utcYear, utcMonth, utcDate, utcHours, utcMinutes, utcSeconds, utcMs);

  return new Date(utcNumber);
}

function convertDateToTimezone(isoDate: string, timeZone?: TimeZoneKey): Date
{
  const date = new Date(isoDate);

  const formattedDate = date.toLocaleString("en-US", {
    timeZone: timeZone
  });

  return new Date(formattedDate);
}

export function getFirstUtcValue(): string | null
{
  const timezoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone as string;
  const matchingTimezone = (timeZoneArray as TimezoneInfo[]).find((timezone) => timezone.utc.includes(timezoneValue));

  return matchingTimezone ? matchingTimezone.utc[0] : null;
}

export function getLocalDateFormat(): string
{
  if(DATE_TIME_FORMAT)
  {
    return DATE_TIME_FORMAT;
  }
  else
  {
    const now = new Date();
    const localeDateString = now.toLocaleString();
    const dateFormatKeys = Object.keys(DisplayDateFormat);

    for(const formatStr of dateFormatKeys)
    {
      if(format(now, formatStr) === localeDateString)
      {
        DATE_TIME_FORMAT = formatStr;
      }
    }

    if(!Boolean(DATE_TIME_FORMAT))
    {
      DATE_TIME_FORMAT = "dd/MM/yyyy, hh:mm:ss a";
    }

    return DATE_TIME_FORMAT;
  }
}

export function is12HourFormat(format?: string)
{
  const regex24Hour = /HH|H/;
  const regex12Hour = /hh|h\s*a/i;

  if(!format)
  {
    return undefined;
  }

  if(regex24Hour.test(format))
  {
    return false;
  }

  if(regex12Hour.test(format))
  {
    return true;
  }

  return undefined;
}

export function extractDateFormat(dateTimeFormat: string): string
{
  const dateFormat = dateTimeFormat.split(",");
  dateFormat.pop();
  return dateFormat.join();
}

export function formatDate(
  _dateStrISO: string,
  dateTimeFormat?: "ISO" | "UTC" | "local" | string,
  timeZone?: TimeZoneKey,
  includeTime?: boolean,
  customTime?: AnyTime)
{
  const isInValidDate = isNaN(Date.parse(_dateStrISO));

  if(isInValidDate)
  {
    logError("DatePlus > formatDate", `Invalid date string ${_dateStrISO}`);
    return;
  }

  const dateStrISO = formatDateToISO(convertDateToTimezone(_dateStrISO, timeZone));

  if(Boolean(includeTime))
  {
    const date = new Date(dateStrISO);
    const formatStr = dateTimeFormat || getLocalDateFormat();

    if(dateTimeFormat)
    {
      switch(dateTimeFormat)
      {
        case "ISO":
          return formatDateToISO(date);
        case "UTC":
          return date.toUTCString();
        case "local":
          return date.toLocaleString();
      }
    }

    if(customTime)
    {
      return formatWithCustomTime(date, customTime);
    }

    return format(date, formatStr);
  }
  else
  {
    const date = formatDateToUTC(new Date(dateStrISO));
    const formatStr = extractDateFormat(dateTimeFormat || getLocalDateFormat());

    if(dateTimeFormat)
    {
      switch(dateTimeFormat)
      {
        case "ISO":
          return formatDateToISO(date);
        case "UTC":
          return date.toUTCString();
        case "local":
          return date.toLocaleDateString();
      }
    }

    return format(date, formatStr);
  }
}

export function extractDate(dateStrISO: string): Date | undefined
{
  const isValidDate = isNaN(Date.parse(dateStrISO));

  if(isValidDate)
  {
    logError("DatePlus > extractDate", "Invalid date string");
    return;
  }

  const date = new Date(dateStrISO);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return new Date(year, month, day, 0, 0, 0);
}

export function timeToDate(timeString: string)
{
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds);
  return date;
}

export function resolveTimeAsString(time: StudioBuildTime): AnyTime
{
  if(time.value === "now")
  {
    return getCurrentTimeAsString();
  }
  else
  {
    return time.customValue as AnyTime;
  }
}

export function getCurrentTimeAsString(): AnyTime
{
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

export function formatDateByMonth(date: Date)
{
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

function getDateTimeFormats()
{
  const dateFormats = new Set<string>();
  const dateTimeFormats = new Set<string>();

  Object.entries(DateTimeFormat).forEach(([key, value]) =>
  {
    dateFormats.add(value);

    dateTimeFormats.add(key);
  });

  return {
    dateFormats: [...dateFormats],
    dateTimeFormats: [...dateTimeFormats]
  };
}

function removeLeadingZeros(input: string)
{
  return input.replace(/\b0+(\d)/g, "$1");
}

export function getValidUTCDateTime(
  dateTimeString: string,
  includeDateTime?: boolean,
  includeOnlyTime?: boolean
): string | undefined
{
  const {
    dateFormats,
    dateTimeFormats
  } = getDateTimeFormats();

  const formats = includeOnlyTime
    ? [
      "HH:mm:ss",
      "H:mm:ss",
      "HH:mm",
      "H:mm",
      "h:mm:ss a",
      "h:mm a",
      "hh:mm:ss a",
      "hh:mm a"
    ]
    : includeDateTime
      ? dateTimeFormats
      : dateFormats;

  for(let formatString of formats)
  {
    const parsedFormat = parse(dateTimeString, formatString, new Date());

    if(isValid(parsedFormat))
    {
      const formatted = format(parsedFormat, formatString);

      if(includeOnlyTime)
      {
        if(formatted.toLowerCase() === dateTimeString.toLowerCase())
        {
          return dateTimeString;
        }
      }
      else
      {
        if(removeLeadingZeros(formatted).toLowerCase() === removeLeadingZeros(dateTimeString).toLowerCase())
        {
          return formatDateToISO(new Date(parsedFormat));
        }
      }
    }
  }
}

export function parseDateStrToDate(dateStr: string)
{
  let date: Date | undefined;
  const {
    dateFormats,
    dateTimeFormats
  } = getDateTimeFormats();

  const formats = [
    ...dateFormats,
    ...dateTimeFormats
  ];

  for(const format of formats)
  {
    date = parse(dateStr, format, new Date());
    if(isValid(date))
    {
      return date;
    }
  }
}

export function checkIsDateToday(randomDate: Date): boolean
{
  return nowDate.getDate() === randomDate.getDate() &&
    nowDate.getMonth() === randomDate.getMonth() &&
    nowDate.getFullYear() === randomDate.getFullYear();
}

export const getMonthRange = (date: Date): {startDate: Date; endDate: Date} =>
{
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);
  return {
    startDate,
    endDate
  };
};

export function formatDateByYear(date: Date)
{
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month}-${year}`;
}

export function getMonthName(num: number)
{
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  return months[num - 1];
}

export function dateFormatBackWordCompatibility(dateStr: string): string
{
  switch(dateStr)
  {
    case "MMM dd, yyyy, h:mm:ss":
      return "MMM dd, yyyy, hh:mm:ss";

    case "MMM dd, yyyy, h:mm:ss a":
      return "MMM dd, yyyy, hh:mm:ss a";

    default:
      return dateStr;
  }
}
