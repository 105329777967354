import {isEmpty} from "lodash";
import {useMemo} from "react";
import {useCallback} from "react";
import {useState} from "react";
import {useEffect} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickGridRow} from "../../../../api/meta/base/dto/DefnFieldPickGridRow";
import {DefnFieldRef} from "../../../../api/meta/base/dto/DefnFieldRef";
import {DefnFieldRefUser} from "../../../../api/meta/base/dto/DefnFieldRefUser";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getLabel} from "../../../../base/plus/StringPlus";
import {TypeDefnFieldRef} from "../../../../base/types/TypesForm";
import {TypeDefnFieldRefWithRefUser} from "../../../../base/types/TypesForm";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {CLOSE} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";

type typeRefButton =
  | "add"
  | "refresh"
  | "close"

export default function FieldRawRefButton(props: {
  defn: DefnField,
})
{
  const defn = props.defn;
  const parentRefId = (defn as DefnFieldUi)._parentRefId;

  if(parentRefId)
  {
    return <RealFieldRawRefButton defn={defn} />;
  }

  return null;
}

function RealFieldRawRefButton(props: {
  defn: DefnField,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const hookFormCtx = useFormContext();

  const defn = props.defn;

  const fieldId = defn.metaId;
  const apiContext = formCtx.getApiCtx();
  const cbOnClick = formCtx.getOnClick();
  const tooltip = formCtx.getTooltipMap && formCtx.getTooltipMap(fieldId);
  const callerEnt = apiContext?.getCallerEnt();
  const defnFormUi = formSectionCtx.getDefnForm();

  const valueMap = hookFormCtx.getValues && hookFormCtx.getValues();
  const parentRefId = (defn as DefnFieldUi)._parentRefId;
  const ref = parentRefId
    ? defnFormUi.compMap[parentRefId] as TypeDefnFieldRefWithRefUser
    : undefined;
  const copyFieldMap = ref?.type === "refUser"
    ? (ref as DefnFieldRefUser).copyFieldVarMap
    : (ref as TypeDefnFieldRef)?.copyFieldMap;
  const refCopyFieldId = copyFieldMap
    ? copyFieldMap[fieldId]
    : undefined;
  const refType = ref?.type;
  const fieldRef = refType === "ref"
    ? ref as DefnFieldRef
    : undefined;
  const canCreateRefRecord = fieldRef?.canCreateRefRecord;
  const refreshOn = fieldRef?.refreshOn;
  const showRefreshOnFieldIdSet = fieldRef?.showRefreshOnFieldIdSet;
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);

  const [refFirstFieldId, setRefFirstFieldId] = useState<MetaIdField>();
  const [showRefDelete, setShowRefDelete] = useState<boolean>(false);

  const showRefRefresh = useMemo(() =>
  {
    if(refreshOn === "refreshOnDemand")
    {
      if(showRefreshOnFieldIdSet
        && refCopyFieldId
        && showRefreshOnFieldIdSet.includes(refCopyFieldId))
      {
        return true;
      }
      else if(!showRefreshOnFieldIdSet?.length)
      {
        return true;
      }
    }

    return false;
  }, [showRefreshOnFieldIdSet, refCopyFieldId]);

  const onClickIcon = useCallback((icon: typeRefButton) =>
  {
    if(icon === "add")
    {
      cbOnClick && cbOnClick(fieldId, "refAddNew");
    }
    else if(icon === "refresh")
    {
      cbOnClick && cbOnClick(fieldId, "refRefresh");
    }
    else if(icon === CLOSE && parentRefId)
    {
      cbOnClick && cbOnClick(parentRefId, "refDelete");
      setShowRefDelete(false);
    }

  }, [cbOnClick, fieldId, parentRefId]);

  const isRefFirstFieldId = useMemo(() => Boolean(refFirstFieldId), [refFirstFieldId]);

  useEffect(() =>
  {
    if(!isEmpty(copyFieldMap))
    {
      const copyFieldMapKeys = Object.keys(copyFieldMap);

      for(let i = 0; i < copyFieldMapKeys.length; i++)
      {
        const copyFieldId = copyFieldMapKeys[i];
        const field = defnFormUi.compMap[copyFieldId] as DefnFieldEditable;

        if(!field.invisible && !field.hidden)
        {
          if(fieldId === copyFieldId)
          {
            const label = (refType === "refReport" || refType === "pickReportRow")
              ? getLabel(defnFormUi)
              : (refType === "ref" && (ref as DefnFieldRef).spreadsheetId && callerEnt?.spreadsheetMap)
                ? getLabel(callerEnt?.spreadsheetMap[(ref as DefnFieldRef).spreadsheetId])
                : refType === "pickGridRow" && (ref as DefnFieldPickGridRow).gridId
                  ? getLabel(defnFormUi.compMap[(ref as DefnFieldPickGridRow).gridId])
                  : undefined;

            setRefFirstFieldId(fieldId);

            if(label)
            {
              formCtx.setFieldHelperText(fieldId, `Pick from ${label}`);
            }
          }
          break;
        }
      }
    }

  }, [copyFieldMap, fieldId]);

  useEffect(() =>
  {
    if(!isEmpty(copyFieldMap)
      && !isEmpty(valueMap)
      && isRefFirstFieldId)
    {
      const copyFieldMapKeys = Object.keys(copyFieldMap);

      for(let i = 0; i < copyFieldMapKeys.length; i++)
      {
        const copyFieldId = copyFieldMapKeys[i];

        if(!isEmpty(valueMap[copyFieldId]))
        {
          setShowRefDelete(true);
          break;
        }
      }
    }

  }, [isRefFirstFieldId, valueMap]);

  if(disabled)
  {
    return null;
  }

  return <RawRefButton
    showRefCreateRecord={canCreateRefRecord && isRefFirstFieldId}
    showRefRefresh={showRefRefresh}
    showRefDelete={showRefDelete && isRefFirstFieldId}
    cbOnClick={onClickIcon}
    tooltip={tooltip?.plusButton}
  />;
}

function RawRefButton(props: {
  showRefCreateRecord?: boolean,
  showRefRefresh?: boolean,
  showRefDelete?: boolean,
  tooltip?: string,
  cbOnClick?: (icon: typeRefButton) => void
})
{
  const {
    showRefCreateRecord,
    cbOnClick,
    showRefRefresh,
    showRefDelete
  } = props;

  if(!(showRefCreateRecord || showRefRefresh || showRefDelete))
  {
    return null;
  }

  return (
    <LayoutFlexRow>
      {
        showRefRefresh &&
        <RawIconStrip
          iconStrip={["refresh"]}
          onClick={(icon) =>
          {
            if(icon === "refresh")
            {
              cbOnClick && cbOnClick("refresh");
            }
          }}
        />
      }

      {
        showRefCreateRecord &&
        <RawIconStrip
          iconStrip={["add"]}
          onClick={(icon) =>
          {
            if(icon === "add")
            {
              cbOnClick && cbOnClick("add");
            }
          }}
          toolTipMap={{
            add: props.tooltip ?? ""
          } as Record<EnumIconStrip, string>}
        />
      }

      {
        showRefDelete &&
        <RawIconStrip
          iconStrip={[CLOSE]}
          onClick={(icon) =>
          {
            if(icon === CLOSE)
            {
              cbOnClick && cbOnClick(CLOSE as typeRefButton);
            }
          }}
        />
      }
    </LayoutFlexRow>
  );
}
