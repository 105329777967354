import {EntId} from "../../api/meta/base/Types";
import ISrvc from "../../base/ISrvc";
import {EnumStudioPluginStep} from "../../base/types/TypesStudio";
import {EnumStudioEntStep} from "../../base/types/TypesStudio";
import {EnumProductionPluginFooterTab} from "../../base/types/TypesStudio";
import {EnumAdminPanelEntFooterTab} from "../../base/types/TypesStudio";
import {Srvc} from "../Srvc";
import {SrvcAdminPanelDrawer} from "./drawer/SrvcAdminPanelDrawer";
import {SrvcAdminPanelMain} from "./main/SrvcAdminPanelMain";

export default class SrvcAdminPanel extends ISrvc
{
  public readonly drawer = new SrvcAdminPanelDrawer();
  public readonly main = new SrvcAdminPanelMain();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.drawer,
      this.main
    );
  }

  setCurrentTab(
    entId: EntId,
    currentTab: EnumAdminPanelEntFooterTab | EnumProductionPluginFooterTab,
    currentStep?: EnumStudioEntStep | EnumStudioPluginStep)
  {
    Srvc.cache.adminPanel.setCurrentTab({
      artifactId: entId,
      currentTab: currentTab,
      currentStep: currentStep
    });
  }

}
