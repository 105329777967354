// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgAutomationExecutionId} from './msg/MsgAutomationExecutionId';
import {MsgAutomationStateInfoList} from './msg/MsgAutomationStateInfoList';
import {MsgEntJoin} from './msg/MsgEntJoin';
import {MsgEntUserIdNoVersion} from '../../core/base/msg/MsgEntUserIdNoVersion';
import {MsgEntVarSeqIncr} from './msg/MsgEntVarSeqIncr';
import {MsgFormMappingResultGet} from './msg/MsgFormMappingResultGet';
import {MsgFormPdfDownload} from './msg/MsgFormPdfDownload';
import {MsgFormResultCalc} from './msg/MsgFormResultCalc';
import {MsgPromptActionsGet} from './msg/MsgPromptActionsGet';
import {MsgPromptTest} from './msg/MsgPromptTest';
import {MsgReportOutputFormGet} from './msg/MsgReportOutputFormGet';
import {MsgReportShare} from './msg/MsgReportShare';
import {MsgSpreadsheetBulkRowCommentCountGet} from './msg/MsgSpreadsheetBulkRowCommentCountGet';
import {MsgSpreadsheetBulkRowGet} from './msg/MsgSpreadsheetBulkRowGet';
import {MsgSpreadsheetBulkRowInsert} from '../main/msg/MsgSpreadsheetBulkRowInsert';
import {MsgSpreadsheetClear} from './msg/MsgSpreadsheetClear';
import {MsgSpreadsheetEditorShare} from './msg/MsgSpreadsheetEditorShare';
import {MsgSpreadsheetInsertShare} from './msg/MsgSpreadsheetInsertShare';
import {MsgSpreadsheetPartitionSend} from './msg/MsgSpreadsheetPartitionSend';
import {MsgSpreadsheetRowCommentCountGet} from './msg/MsgSpreadsheetRowCommentCountGet';
import {MsgSpreadsheetRowExpiryGet} from './msg/MsgSpreadsheetRowExpiryGet';
import {MsgSpreadsheetRowGet} from './msg/MsgSpreadsheetRowGet';
import {MsgSpreadsheetRowMarkRead} from './msg/MsgSpreadsheetRowMarkRead';
import {MsgSpreadsheetRowRemove} from './msg/MsgSpreadsheetRowRemove';
import {MsgSpreadsheetRowSend} from './msg/MsgSpreadsheetRowSend';
import {MsgSpreadsheetRowShare} from './msg/MsgSpreadsheetRowShare';
import {MsgSpreadsheetRowUpdate} from './msg/MsgSpreadsheetRowUpdate';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {ServiceName} from '../../meta/base/Types';
import {SigAutomationState} from './sig/SigAutomationState';
import {SigAutomationStateInfoList} from './sig/SigAutomationStateInfoList';
import {SigDebuggerLogsGet} from './sig/SigDebuggerLogsGet';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigEntFormPdfDownload} from './sig/SigEntFormPdfDownload';
import {SigEntInvitationList} from './sig/SigEntInvitationList';
import {SigEntUserAvatarListGet} from './sig/SigEntUserAvatarListGet';
import {SigEntVarSeq} from './sig/SigEntVarSeq';
import {SigFormMappingResultGet} from './sig/SigFormMappingResultGet';
import {SigFormValue} from './sig/SigFormValue';
import {SigPromptActions} from './sig/SigPromptActions';
import {SigReportOutputFormGet} from '../ent/sig/SigReportOutputFormGet';
import {SigSpreadsheetBulkRowCommentCount} from '../main/sig/SigSpreadsheetBulkRowCommentCount';
import {SigSpreadsheetBulkRowGet} from '../../home/main/sig/SigSpreadsheetBulkRowGet';
import {SigSpreadsheetBulkRowInsert} from '../main/sig/SigSpreadsheetBulkRowInsert';
import {SigSpreadsheetRow} from '../../home/main/sig/SigSpreadsheetRow';
import {SigSpreadsheetRowCommentCount} from '../../home/main/sig/SigSpreadsheetRowCommentCount';
import {SigSpreadsheetRowExpiry} from './sig/SigSpreadsheetRowExpiry';
import {SigSpreadsheetRowRemove} from './sig/SigSpreadsheetRowRemove';
import {SigSpreadsheetRowSend} from './sig/SigSpreadsheetRowSend';
import {SigUrl} from '../../core/deeplink/sig/SigUrl';
import {SigUserLastOnline} from '../../home/main/sig/SigUserLastOnline';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcEntMain
{
  static readonly SN: ServiceName = "entMain";

  static automationExecutionStatusGet(entId: EntId, msg: MsgAutomationExecutionId, sigAcceptor: ISigAcceptor<SigAutomationState>): void
  {
    rpcCall<SigAutomationState>(entId, RpcEntMain.SN, "automationExecutionStatusGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static debuggerLogsGet(entId: EntId, sigAcceptor: ISigAcceptor<SigDebuggerLogsGet>): void
  {
    rpcCall<SigDebuggerLogsGet>(entId, RpcEntMain.SN, "debuggerLogsGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static entAutomationResume(entId: EntId, msg: MsgAutomationExecutionId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcEntMain.SN, "entAutomationResume")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entAutomationStateInfoListGet(entId: EntId, msg: MsgAutomationStateInfoList, sigAcceptor: ISigAcceptor<SigAutomationStateInfoList>): void
  {
    rpcCall<SigAutomationStateInfoList>(entId, RpcEntMain.SN, "entAutomationStateInfoListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entFormMappingResultGet(entId: EntId, msg: MsgFormMappingResultGet, sigAcceptor: ISigAcceptor<SigFormMappingResultGet>): void
  {
    rpcCall<SigFormMappingResultGet>(entId, RpcEntMain.SN, "entFormMappingResultGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entFormPdfDownload(msg: MsgFormPdfDownload, sigAcceptor: ISigAcceptor<SigEntFormPdfDownload>): void
  {
    rpcCall<SigEntFormPdfDownload>(ENT_ID_GLOBAL, RpcEntMain.SN, "entFormPdfDownload")
      .sendRefreshToken()
      .post(msg, sigAcceptor);
  }

  static entFormResultCalc(entId: EntId, msg: MsgFormResultCalc, sigAcceptor: ISigAcceptor<SigFormValue>): void
  {
    rpcCall<SigFormValue>(entId, RpcEntMain.SN, "entFormResultCalc")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entInvitationListGet(sigAcceptor: ISigAcceptor<SigEntInvitationList>): void
  {
    rpcCall<SigEntInvitationList>(ENT_ID_GLOBAL, RpcEntMain.SN, "entInvitationListGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static entJoin(msg: MsgEntJoin, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcEntMain.SN, "entJoin")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entPromptActionsGet(entId: EntId, msg: MsgPromptActionsGet, sigAcceptor: ISigAcceptor<SigPromptActions>): void
  {
    rpcCall<SigPromptActions>(entId, RpcEntMain.SN, "entPromptActionsGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entPromptExecutorTest(msg: MsgPromptTest, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcEntMain.SN, "entPromptExecutorTest")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entReportOutputFormGet(entId: EntId, msg: MsgReportOutputFormGet, sigAcceptor: ISigAcceptor<SigReportOutputFormGet>): void
  {
    rpcCall<SigReportOutputFormGet>(entId, RpcEntMain.SN, "entReportOutputFormGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entReportShare(entId: EntId, msg: MsgReportShare, sigAcceptor: ISigAcceptor<SigUrl>): void
  {
    rpcCall<SigUrl>(entId, RpcEntMain.SN, "entReportShare")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static entSpreadsheetEditorShare(entId: EntId, msg: MsgSpreadsheetEditorShare, sigAcceptor: ISigAcceptor<SigUrl>): void
  {
    rpcCall<SigUrl>(entId, RpcEntMain.SN, "entSpreadsheetEditorShare")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entSpreadsheetInsertShare(entId: EntId, msg: MsgSpreadsheetInsertShare, sigAcceptor: ISigAcceptor<SigUrl>): void
  {
    rpcCall<SigUrl>(entId, RpcEntMain.SN, "entSpreadsheetInsertShare")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entSpreadsheetRowShare(entId: EntId, msg: MsgSpreadsheetRowShare, sigAcceptor: ISigAcceptor<SigUrl>): void
  {
    rpcCall<SigUrl>(entId, RpcEntMain.SN, "entSpreadsheetRowShare")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entUserAvatarListGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigEntUserAvatarListGet>): void
  {
    rpcCall<SigEntUserAvatarListGet>(entId, RpcEntMain.SN, "entUserAvatarListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entUserLastOnlineGet(entId: EntId, msg: MsgEntUserIdNoVersion, sigAcceptor: ISigAcceptor<SigUserLastOnline>): void
  {
    rpcCall<SigUserLastOnline>(entId, RpcEntMain.SN, "entUserLastOnlineGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entVarSeqIncr(entId: EntId, msg: MsgEntVarSeqIncr, sigAcceptor: ISigAcceptor<SigEntVarSeq>): void
  {
    rpcCall<SigEntVarSeq>(entId, RpcEntMain.SN, "entVarSeqIncr")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static spreadsheetBulkRowCommentCountGet(entId: EntId, msg: MsgSpreadsheetBulkRowCommentCountGet, sigAcceptor: ISigAcceptor<SigSpreadsheetBulkRowCommentCount>): void
  {
    rpcCall<SigSpreadsheetBulkRowCommentCount>(entId, RpcEntMain.SN, "spreadsheetBulkRowCommentCountGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static spreadsheetBulkRowGet(entId: EntId, msg: MsgSpreadsheetBulkRowGet, sigAcceptor: ISigAcceptor<SigSpreadsheetBulkRowGet>): void
  {
    rpcCall<SigSpreadsheetBulkRowGet>(entId, RpcEntMain.SN, "spreadsheetBulkRowGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static spreadsheetBulkRowInsert(entId: EntId, msg: MsgSpreadsheetBulkRowInsert, sigAcceptor: ISigAcceptor<SigSpreadsheetBulkRowInsert>): void
  {
    rpcCall<SigSpreadsheetBulkRowInsert>(entId, RpcEntMain.SN, "spreadsheetBulkRowInsert")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static spreadsheetClear(entId: EntId, msg: MsgSpreadsheetClear, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcEntMain.SN, "spreadsheetClear")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static spreadsheetPartitionSend(entId: EntId, msg: MsgSpreadsheetPartitionSend, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcEntMain.SN, "spreadsheetPartitionSend")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static spreadsheetRowCommentCountGet(entId: EntId, msg: MsgSpreadsheetRowCommentCountGet, sigAcceptor: ISigAcceptor<SigSpreadsheetRowCommentCount>): void
  {
    rpcCall<SigSpreadsheetRowCommentCount>(entId, RpcEntMain.SN, "spreadsheetRowCommentCountGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static spreadsheetRowExpiryGet(entId: EntId, msg: MsgSpreadsheetRowExpiryGet, sigAcceptor: ISigAcceptor<SigSpreadsheetRowExpiry>): void
  {
    rpcCall<SigSpreadsheetRowExpiry>(entId, RpcEntMain.SN, "spreadsheetRowExpiryGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static spreadsheetRowGet(entId: EntId, msg: MsgSpreadsheetRowGet, sigAcceptor: ISigAcceptor<SigSpreadsheetRow>): void
  {
    rpcCall<SigSpreadsheetRow>(entId, RpcEntMain.SN, "spreadsheetRowGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static spreadsheetRowMarkRead(entId: EntId, msg: MsgSpreadsheetRowMarkRead, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcEntMain.SN, "spreadsheetRowMarkRead")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static spreadsheetRowRemove(entId: EntId, msg: MsgSpreadsheetRowRemove, sigAcceptor: ISigAcceptor<SigSpreadsheetRowRemove>): void
  {
    rpcCall<SigSpreadsheetRowRemove>(entId, RpcEntMain.SN, "spreadsheetRowRemove")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static spreadsheetRowSend(entId: EntId, msg: MsgSpreadsheetRowSend, sigAcceptor: ISigAcceptor<SigSpreadsheetRowSend>): void
  {
    rpcCall<SigSpreadsheetRowSend>(entId, RpcEntMain.SN, "spreadsheetRowSend")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static spreadsheetRowUpdate(entId: EntId, msg: MsgSpreadsheetRowUpdate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcEntMain.SN, "spreadsheetRowUpdate")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }
}