import {Box} from "@mui/material";
import {useEffect} from "react";
import {useMemo} from "react";
import {useState} from "react";
import React from "react";
import {useCallback} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DtoCloneConfig} from "../../api/core/base/dto/DtoCloneConfig";
import {isPluginId} from "../../api/meta/base/ApiPlus";
import {isEntId} from "../../api/meta/base/ApiPlus";
import {isTemplateId} from "../../api/meta/base/ApiPlus";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldParagraph} from "../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioSetOfSpreadsheetId} from "../../api/meta/base/dto/DefnStudioSetOfSpreadsheetId";
import {StoreItem} from "../../api/meta/base/dto/StoreItem";
import {StudioEnt} from "../../api/meta/base/dto/StudioEnt";
import {StudioEntDetails} from "../../api/meta/base/dto/StudioEntDetails";
import {EnumDefnStoreItem} from "../../api/meta/base/Types";
import {EnumStoreLabel} from "../../api/meta/base/Types";
import {ArtifactId} from "../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {isDeepEqual} from "../../base/plus/JsPlus";
import {selectCacheStudioEnt} from "../../base/plus/StudioPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import theme from "../../base/plus/ThemePlus";
import {IFormRef} from "../../base/types/TypesForm";
import {useAppSelector} from "../../nucleus/app/AppHooks";
import {IDialogAtomRef} from "../../nucleus/atom/dialog/DialogAtom";
import DialogAtom from "../../nucleus/atom/dialog/DialogAtom";
import {Cancel} from "../../nucleus/atom/dialog/DialogConfirm";
import RawFadeLoader from "../../nucleus/atom/raw/RawFadeLoader";
import {fieldGap1} from "../../nucleus/form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../nucleus/form/builder/base/TypesFormBuilder";
import Form from "../../nucleus/form/viewer/Form";
import {Srvc} from "../../srvc/Srvc";

const fieldStoreLabel = "store";
const fieldStoreItemType = "storeItemType";
const fieldStoreLabels = "storeLabelSet";
const fieldStoreAbout = "storeAbout";
const fieldDataLabel = "data";
const fieldIncludeData = "includeData";
const fieldCloneAdmin = "cloneAdmin";
const fieldCloneEntUser = "cloneEntUser";
const fieldCloneSpreadsheetIdSet = "cloneSpreadsheetIdSet";

const artifactSubscriberId = "UtoDialogArtifactMenuActions";
const adminCallerSubscriberId = "UtoDialogArtifactMenuActionsAdminCaller";

export default function DialogArtifactMenuActions(props: {
  artifactId?: ArtifactId,
  artifactName?: string,
  type: "Duplicate" | "PublishOnStore" | "AddToStudio",
  storeItem?: StoreItem,
})
{
  const artifactId = props.artifactId;
  const artifactName = props.artifactName;
  const type = props.type;
  const storeItem = props.storeItem;

  const cacheStudioEnt = useAppSelector(state =>
  {
    return (artifactId && isEntId(artifactId))
      ? selectCacheStudioEnt(state, artifactId)?.ent
      : undefined;
  });

  const formRef = useMemo(() => ({} as IFormRef), []);
  const cbDialogRef = useMemo(() => ({} as IDialogAtomRef), []);

  const dialogTitle = type === "PublishOnStore"
    ? `Publish on store`
    : type === "Duplicate"
      ? `Duplicate ${artifactName}`
      : `Add to studio`;

  const submitButtonTitle = type === "PublishOnStore"
    ? "Publish"
    : type === "Duplicate"
      ? "Duplicate"
      : "Add";

  const includeDataLabel = type === "PublishOnStore"
    ? "Publish with data"
    : type === "Duplicate"
      ? "Duplicate with data"
      : "Copy with data";

  const [studioEnt, setStudioEnt] = useState<StudioEnt | undefined>(cacheStudioEnt);
  const [includeData, setIncludeData] = useState<boolean>(false);

  const getDefnForm = useCallback(() =>
  {
    return createDefaultDefnFormStudio({

      [fieldStoreLabel]: {
        type: "label",
        metaId: fieldStoreLabel,
        name: fieldStoreLabel,
        label: "Store info",
        disabled: true
      } as DefnFieldLabel,

      [fieldStoreItemType]: {
        type: "enumStoreItem",
        metaId: fieldStoreItemType,
        name: fieldStoreItemType,
        label: "Type",
        disabled: true
      } as DefnFieldPickEnum,

      [fieldStoreLabels]: {
        type: "studioSetOfStoreItemCategory",
        metaId: fieldStoreLabels,
        name: fieldStoreLabels,
        label: "Labels"
      } as DefnFieldEditable,

      [fieldStoreAbout]: {
        type: "paragraph",
        placeHolderVar: stringToDefnDtoText("Say what this enterprise is about in few words"),
        label: "About",
        metaId: fieldStoreAbout,
        name: fieldStoreAbout,
        lineCount: theme.common.defaultLineCount,
        pb: gapStd
      } as DefnFieldParagraph,

      ...getFieldGap(fieldGap1, "thick"),

      [fieldDataLabel]: {
        type: "label",
        metaId: fieldDataLabel,
        name: fieldDataLabel,
        label: "Seed data",
        disabled: true
      } as DefnFieldLabel,

      [fieldIncludeData]: {
        type: "bool",
        metaId: fieldIncludeData,
        name: fieldIncludeData,
        label: includeDataLabel,
        showAsCheckbox: true
      } as DefnFieldSwitch,

      [fieldCloneSpreadsheetIdSet]: {
        type: "studioSetOfSpreadsheetId",
        metaId: fieldCloneSpreadsheetIdSet,
        name: fieldCloneSpreadsheetIdSet,
        label: "Select spreadsheets to copy data from",
        helperText: "By default, data will be copied from all spreadsheets"
      } as DefnStudioSetOfSpreadsheetId,

      [fieldCloneAdmin]: {
        type: "bool",
        metaId: fieldCloneAdmin,
        name: fieldCloneAdmin,
        label: "Copy admins",
        showAsCheckbox: true
      } as DefnFieldSwitch,

      [fieldCloneEntUser]: {
        type: "bool",
        metaId: fieldCloneEntUser,
        name: fieldCloneEntUser,
        label: "Copy users",
        showAsCheckbox: true
      } as DefnFieldSwitch,

      [defaultSectionKey]: {
        type: "section",
        metaId: defaultSectionKey,
        fieldIdSet: [
          ...type === "PublishOnStore"
            ? [
              fieldStoreLabel,
              fieldStoreItemType,
              fieldStoreLabels,
              fieldStoreAbout,
              fieldGap1,
              fieldDataLabel
            ]
            : [],
          fieldIncludeData,
          ...includeData
            ? [
              fieldCloneSpreadsheetIdSet,
              fieldCloneAdmin,
              fieldCloneEntUser
            ]
            : []
        ]
      } as DefnSection
    });

  }, [type, includeData]);

  const onSubmit = useCallback((values: FieldValues) =>
  {
    const storeLabelSet = fnFieldValueToRawValue("studioSetOfStoreItemCategory",
      values[fieldStoreLabels]
    ) as EnumStoreLabel[] | undefined;
    const storeAbout = fnFieldValueToRawValue("paragraph",
      values[fieldStoreAbout]
    ) as string | undefined;
    const includeData = Boolean(fnFieldValueToRawValue("bool", values[fieldIncludeData]));
    const cloneAdmin = Boolean(fnFieldValueToRawValue("bool", values[fieldCloneAdmin]));
    const cloneEntUser = Boolean(fnFieldValueToRawValue("bool", values[fieldCloneEntUser]));
    const cloneSpreadsheetIdSet = fnFieldValueToRawValue("studioSetOfSpreadsheetId",
      values[fieldCloneSpreadsheetIdSet]
    );

    const cloneConfig = includeData
      ? {
        cloneAdmin: cloneAdmin,
        cloneEntUser: cloneEntUser,
        cloneSpreadsheetIdSet: cloneSpreadsheetIdSet
      } as DtoCloneConfig
      : undefined;

    if(type === "PublishOnStore" && artifactId)
    {
      const ent = {
        ...studioEnt,
        details: {
          ...studioEnt?.details,
          storeLabelSet: storeLabelSet,
          storeAbout: storeAbout
        }
      } as StudioEnt;

      if(studioEnt?.details && ent?.details && isDeepEqual(studioEnt.details, ent.details))
      {
        Srvc.store.storeItem.rpcStoreItemCreate(
          artifactId,
          includeData,
          cloneConfig,
          () =>
          {
            Srvc.app.toast.showSuccessToast(`${artifactName} published to store successfully`);
          }
        );
      }
      else
      {
        Srvc.studio.ent.rpcEntPut(
          artifactId,
          ent,
          () =>
          {
            Srvc.studio.ent.deploy.rpcEntDeploy(ent.entId, false, () =>
            {
              Srvc.app.pubsub.studio.entDeployStatus("EntDeployStatus", ent.entId);

              Srvc.store.storeItem.rpcStoreItemCreate(
                artifactId,
                includeData,
                cloneConfig,
                () =>
                {
                  Srvc.app.toast.showSuccessToast(`${artifactName} published to store successfully`);
                }
              );
            });
          }
        );
      }
    }
    else if(type === "Duplicate" && artifactId)
    {
      Srvc.studio.drawer.duplicateArtifact(
        artifactId,
        includeData,
        cloneConfig
      );
    }
    else if(type === "AddToStudio" && storeItem?.storeItemId)
    {
      Srvc.store.storeItem.rpcStoreItemProvision(
        storeItem.storeItemId,
        includeData,
        cloneConfig,
        () =>
        {
          Srvc.app.toast.showSuccessToast(`${artifactName} added to studio successfully`);
        }
      );
    }

  }, [
    artifactId,
    artifactName,
    storeItem?.storeItemId,
    type,
    studioEnt
  ]);

  const onWatch = useCallback((key: string, value: any) =>
  {
    if(key === fieldIncludeData)
    {
      const includeData = Boolean(fnFieldValueToRawValue("bool", value));

      setIncludeData(includeData);
    }

  }, [formRef]);

  const dtoToValue = useCallback((details?: StudioEntDetails) =>
  {
    const storeItemType: EnumDefnStoreItem = isTemplateId(artifactId) ? "template" : "application";

    return {
      [fieldStoreItemType]: fnRawValueToFieldValue("enumStoreItem", storeItemType),
      [fieldStoreLabels]: fnRawValueToFieldValue("studioSetOfStoreItemCategory", details?.storeLabelSet),
      [fieldStoreAbout]: fnRawValueToFieldValue("paragraph", details?.storeAbout)
    };

  }, [artifactId]);

  useEffect(() =>
  {
    if(type === "AddToStudio")
    {
      setStudioEnt(storeItem?.studioEnt);
    }
    else
    {
      if(!cacheStudioEnt && artifactId)
      {
        Srvc.studio.drawer.studioEntGet(artifactId, (ent) =>
        {
          setStudioEnt(ent);
        });
      }
      else
      {
        setStudioEnt(cacheStudioEnt);
      }
    }

  }, [cacheStudioEnt, artifactId, type, storeItem?.studioEnt]);

  useEffect(() =>
  {
    if(artifactId)
    {
      Srvc.app.pubsub.studio.artifactAdminCaller(adminCallerSubscriberId, artifactId);

      if(isEntId(artifactId))
      {
        Srvc.app.pubsub.studio.ent(artifactSubscriberId, artifactId);
      }
      else if(isPluginId(artifactId))
      {
        Srvc.app.pubsub.studio.plugin(artifactSubscriberId, artifactId);
      }
    }

    return () =>
    {
      if(artifactId)
      {
        Srvc.app.pubsub.studio.artifactAdminCaller(adminCallerSubscriberId, artifactId, true);

        if(isEntId(artifactId))
        {
          Srvc.app.pubsub.studio.ent(artifactSubscriberId, artifactId, true);
        }
        else if(isPluginId(artifactId))
        {
          Srvc.app.pubsub.studio.plugin(artifactSubscriberId, artifactId, true);
        }
      }
    };

  }, [artifactId]);

  useEffect(() =>
  {
    if(studioEnt)
    {
      cbDialogRef.disableFooterActions && cbDialogRef.disableFooterActions([]);

      cbDialogRef.setContent && cbDialogRef.setContent(
        <Box
          height={"100%"}
          width={"100%"}
          overflow={"auto"}
        >
          <Form
            store={studioEnt}
            cbRef={formRef}
            defnForm={getDefnForm()}
            onSubmit={onSubmit}
            initValues={dtoToValue(studioEnt?.details)}
            onWatch={onWatch}
          />
        </Box>
      );
    }

  }, [
    studioEnt,
    formRef,
    cbDialogRef,
    onSubmit,
    dtoToValue,
    getDefnForm,
    onWatch
  ]);

  return (
    <DialogAtom
      title={dialogTitle}
      cbRef={cbDialogRef}
      content={
        <RawFadeLoader />
      }
      rightFooterActions={[Cancel, submitButtonTitle]}
      disableRightFooterActions={[Cancel, submitButtonTitle]}
      onClickFooterAction={(action) =>
      {
        if(action === submitButtonTitle)
        {
          formRef.remoteSubmit();
        }
      }}
      contentWidth={550}
      contentHeight={type === "PublishOnStore"
        ? 700
        : 350}
    />
  );
}
