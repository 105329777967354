import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickLayoutFormContentId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutFormContentId";
import {StudioDtoLayoutForm} from "../../../../api/meta/base/dto/StudioDtoLayoutForm";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {EnumDefnFormLayoutType} from "../../../../api/meta/base/Types";
import {MetaIdLayoutForm} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

export default function FieldRawStudioPickLayoutFormContentId(props: {
  defn: DefnStudioPickLayoutFormContentId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean
})
{
  const defn = props.defn;

  const formCtx = useFormCtx();
  const metaIdForm = defn.formId;

  const formStore = formCtx.getStore();
  const studioForm = formStore?.formMap?.map[metaIdForm];

  const fieldValue = props.fieldProps.field.value;

  const excludeFormIdLayoutSet = defn.excludeLayoutFormContentIdSet;
  const filterKindSet = defn.filterKindSet;

  const dtoOptions = useMemo(() => studioForm
    ? getPickLayoutGridOptions(studioForm, excludeFormIdLayoutSet, filterKindSet)
    : [], [excludeFormIdLayoutSet, studioForm]);

  const [options] = useInsertTrashOptions({
    type: "contentMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    multiSelect={props.multiSelect}
    showChip={props.showChip}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getPickLayoutGridOptions(
  studioForm: StudioForm,
  excludeFormIdLayoutSet?: MetaIdLayoutForm[],
  filterKindSet?: EnumDefnFormLayoutType[]
): DefnDtoOption[]
{

  const options: DefnDtoOption[] = [];
  const contentLayoutMap = studioForm.layoutMap;

  if(!contentLayoutMap)
  {
    return options;
  }

  contentLayoutMap.keys.forEach((key) =>
  {
    const contentLayout = contentLayoutMap.map[key] as StudioDtoLayoutForm;

    const label = `${contentLayout.name}`;
    const optionId = contentLayout.metaId;
    const contentLayoutType: EnumDefnFormLayoutType = contentLayout.type ?? "content";

    if(excludeFormIdLayoutSet && excludeFormIdLayoutSet.includes(optionId))
    {
      return;
    }

    if(filterKindSet && !filterKindSet.includes(contentLayoutType))
    {
      return;
    }

    options.push({
      value: label || optionId,
      metaId: optionId
    });
  });
  return options;
}
