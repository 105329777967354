import {EntId} from "../../../api/meta/base/Types";
import {SigEntAdminCaller} from "../../../api/studio/studioMain/sig/SigEntAdminCaller";
import {SigEntAdminList} from "../../../api/studio/studioMain/sig/SigEntAdminList";
import ISrvc from "../../../base/ISrvc";
import {setEntAdminCaller} from "../../studio/ent/SliceCacheStudioEnt";
import {removeEntAdminList} from "../../studio/ent/SliceCacheStudioEnt";
import {setEntAdminList} from "../../studio/ent/SliceCacheStudioEnt";
import {dispatchEnt} from "../../studio/SrvcCacheStudio";

export default class SrvcCacheAdminPanelAdmins extends ISrvc
{
  setEntAdminList(entId: EntId, sig: SigEntAdminList)
  {
    dispatchEnt(entId, setEntAdminList({
      entId: entId,
      sigEntAdminList: sig
    }));
  }

  removeEntAdminList(entId: EntId)
  {
    dispatchEnt(entId, removeEntAdminList(entId));
  }

  setEntAdminCaller(entId: EntId, adminCaller: SigEntAdminCaller)
  {
    dispatchEnt(entId, setEntAdminCaller({
      entId: entId,
      sigEntAdminCaller: adminCaller
    }));
  }
}
