import {format} from "date-fns";
import {IDebugServiceReport} from "../../../base/ISrvc";
import ISrvc from "../../../base/ISrvc";
import {isoDateTimeNow} from "../../../base/plus/DatePlus";
import {logQueue} from "../../../base/util/AppLog";
import {logNow} from "../../../base/util/AppLog";
import {logDebug} from "../../../base/util/AppLog";
import {fnCanStoreLog} from "../../../routes/uiLogs/impl/UiLogPlus";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";
import {setDebugServiceReport} from "./SliceDebug";

export default class SrvcDebug extends ISrvc
{
  private logMap = new Map<string, string[]>(); // hour -> log
  private onSaveCb: ((() => void) | undefined) = undefined;

  public debugReport(download?: boolean): void
  {
    const debugServiceReport = {
      serviceReportDate: logNow(),
      serviceMap: {}
    } as IDebugServiceReport;

    Srvc.debug(debugServiceReport);
    if(!download)
    {
      store.dispatch(setDebugServiceReport(debugServiceReport));
    }

    const _debugServiceReport = {
      ...debugServiceReport,
      serviceMap: {
        ...debugServiceReport.serviceMap,
        "AppLog": {}
      }
    } as IDebugServiceReport;
    this.logMap.forEach((logs, hour) =>
    {
      _debugServiceReport.serviceMap["AppLog"][hour] = {logs: logs};
    });
    if(download)
    {
      this.downloadJson(_debugServiceReport);
    }
    else
    {
      logDebug("DebugReport", JSON.stringify(_debugServiceReport));
    }
  }

  saveLog(logs: string[])
  {
    const currentHour = format(new Date(), "MM/dd/yyyy-hha");

    if(!this.logMap.has(currentHour))
    {
      this.logMap.set(currentHour, logs);
    }
    else
    {
      const existingLogs = this.logMap.get(currentHour);
      if(existingLogs)
      {
        this.logMap.set(currentHour, [...existingLogs, ...logs]);
      }
    }
    this.onSaveCb?.();
  }

  addOnSaveCb(cb: () => void)
  {
    this.onSaveCb = cb;
  }

  removeOnSaveCb()
  {
    this.onSaveCb = undefined;
  }

  getLogs()
  {
    return {
      logMap: this.logMap,
      current: format(new Date(), "MM/dd/yyyy-hha")
    };
  }

  protected onWsocAuth()
  {
    logQueue.initLogQueue(this.saveLog.bind(this), fnCanStoreLog(store.getState()));
  }

  protected doSignOut()
  {
    logQueue.clearAllLogs();
    this.logMap.clear();
  }

  protected doInit()
  {
    // @ts-ignore
    window["debug"] = this.debugReport.bind(this);
    // @ts-ignore
    window["saveLogs"] = () => this.debugReport.call(this, true);
  }

  private downloadJson(debugServiceReport: IDebugServiceReport)
  {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(debugServiceReport));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `${isoDateTimeNow()}.json`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
}
