import {EnumTimeDuration} from "../../../../api/core/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {EnumAnalyticEventType} from "../../../../api/nucleus/base/Types";
import {MsgAnalyticEventCount} from "../../../../api/studio/studioMain/msg/MsgAnalyticEventCount";
import {MsgAnalyticEventData} from "../../../../api/studio/studioMain/msg/MsgAnalyticEventData";
import {MsgClusterAnalyticEventCount} from "../../../../api/studio/studioMain/msg/MsgClusterAnalyticEventCount";
import {MsgClusterAnalyticEventData} from "../../../../api/studio/studioMain/msg/MsgClusterAnalyticEventData";
import {MsgSpreadsheetHistory} from "../../../../api/studio/studioMain/msg/MsgSpreadsheetHistory";
import {RpcStudioMain} from "../../../../api/studio/studioMain/RpcStudioMain";
import {SigAnalyticEventCount} from "../../../../api/studio/studioMain/sig/SigAnalyticEventCount";
import {SigAnalyticEventData} from "../../../../api/studio/studioMain/sig/SigAnalyticEventData";
import {SigSpreadsheetHistory} from "../../../../api/studio/studioMain/sig/SigSpreadsheetHistory";
import ISrvc from "../../../../base/ISrvc";
import {Srvc} from "../../../Srvc";

export default class SrvcAdminPanelManage extends ISrvc
{
  rpcSpreadsheetHistoryGet(
    entId: EntId,
    cbSuccess?: (sig: SigSpreadsheetHistory) => void,
    cbError?: () => void
  )
  {
    const msg: MsgSpreadsheetHistory = {};

    RpcStudioMain.spreadsheetHistoryGet(entId, msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        // Srvc.app.toast.showErrorToast(envSig);
        cbError && cbError();
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  rpcEventCountGet(
    isCluster: boolean,
    eventType: EnumAnalyticEventType,
    entIdSet?: EntId[],
    startDate?: string,
    endDate?: string,
    cbSuccess?: (sig: SigAnalyticEventCount) => void
  )
  {
    if(isCluster)
    {
      this.rpcClusterAnalyticEventCountGet(
        eventType,
        entIdSet,
        startDate,
        endDate,
        cbSuccess
      );
    }
    else if(entIdSet)
    {
      this.rpcAnalyticEventCountGet(
        entIdSet[0],
        eventType,
        startDate,
        endDate,
        cbSuccess
      );
    }
  }

  rpcEventDataGet(
    isCluster: boolean,
    duration: EnumTimeDuration,
    eventType: EnumAnalyticEventType,
    entIdSet?: EntId[],
    cbSuccess?: (sig: SigAnalyticEventData) => void
  )
  {
    if(isCluster)
    {
      this.rpcClusterAnalyticEventDataGet(
        duration,
        eventType,
        entIdSet,
        cbSuccess
      );
    }
    else if(entIdSet)
    {
      this.rpcAnalyticEventDataGet(
        entIdSet[0],
        duration,
        eventType,
        cbSuccess
      );
    }
  }

  //region private

  private rpcClusterAnalyticEventCountGet(
    eventType: EnumAnalyticEventType,
    entIdSet?: EntId[],
    startDate?: string,
    endDate?: string,
    cbSuccess?: (sig: SigAnalyticEventCount) => void
  )
  {
    const msg = {
      eventType: eventType,
      startDate: startDate,
      endDate: endDate,
      entIdSet: entIdSet
    } as MsgClusterAnalyticEventCount;

    RpcStudioMain.clusterAnalyticEventCountGet(msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  private rpcAnalyticEventCountGet(
    entId: EntId,
    eventType: EnumAnalyticEventType,
    startDate?: string,
    endDate?: string,
    cbSuccess?: (sig: SigAnalyticEventCount) => void
  )
  {
    const msg = {
      eventType: eventType,
      startDate: startDate,
      endDate: endDate
    } as MsgAnalyticEventCount;

    RpcStudioMain.analyticEventCountGet(entId, msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  private rpcClusterAnalyticEventDataGet(
    duration: EnumTimeDuration,
    eventType: EnumAnalyticEventType,
    entIdSet?: EntId[],
    cbSuccess?: (sig: SigAnalyticEventData) => void
  )
  {
    const msg = {
      duration: duration,
      eventType: eventType,
      entIdSet: entIdSet
    } as MsgClusterAnalyticEventData;

    RpcStudioMain.clusterAnalyticEventDataGet(msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  private rpcAnalyticEventDataGet(
    entId: EntId,
    duration: EnumTimeDuration,
    eventType: EnumAnalyticEventType,
    cbSuccess?: (sig: SigAnalyticEventData) => void
  )
  {
    const msg = {
      duration: duration,
      eventType: eventType
    } as MsgAnalyticEventData;

    RpcStudioMain.analyticEventDataGet(entId, msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  //endregion
}
