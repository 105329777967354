import {Box, useTheme} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {StudioVisibilityRule} from "../../../../api/meta/base/dto/StudioVisibilityRule";
import {StudioVisibilityRuleMap} from "../../../../api/meta/base/dto/StudioVisibilityRuleMap";
import {MetaIdVisibilityRule} from "../../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf, gapStd} from "../../../../base/plus/ThemePlus";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItem, IListItemsById, TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IAsidePropsStudioFormVisibilityRule} from "../../../../base/types/TypesAside";
import {ILineSecondary} from "../../../../base/types/TypesGlobal";
import {IMainPropsStudio, IMainPropsStudioFormBuilderVisibilityRules} from "../../../../base/types/TypesMain";
import {TypeListSortType} from "../../../../base/types/TypesStudio";
import {IStudioJumpPath} from "../../../../base/types/TypesStudio";
import {useStepListDefaultConfig} from "../../../../routes/studio/app/UiStudioPlus";
import {useAppSelector} from "../../../app/AppHooks";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawSearch from "../../../atom/raw/RawSearch";
import {useAppCtx} from "../../../ctx/CtxApp";
import {usePageCtx} from "../../../ctx/CtxPage";
import {List, ListClickVariant} from "../../../list/List";
import {useValidationErrorList} from "../../../list/validation/ListValidation";
import {sortFormItemList} from "../base/FormBuilderPlus";
import useCopyPasteStudioFormItem from "../base/StudioFormCopyPaste";
import {TypeActionSortFormList} from "../base/TypesFormBuilder";
import {IFormBuilderProps} from "../base/TypesFormBuilder";

export default function FormBuilderFormVisibility(props: IFormBuilderProps)
{
  const theme = useTheme();
  const appCtx = useAppCtx();
  const pageCtx = usePageCtx();
  const [selectedMetaIdVisibilityRule, setSelectedMetaIdVisibilityRule] = useState<MetaIdVisibilityRule>();
  const {
    copyStudioVisibilityRules
  } = useCopyPasteStudioFormItem();

  const formStore = props.formStore;
  const setupMode = props.setupMode;
  const readOnly = props.readOnly;
  const selectListMap = props.selectListMap;
  const cbOnSubmit = props.cbOnSubmit;
  const cbOnRemove = props.cbOnRemove;
  const cbGetCliCode = props.cbGetCliCode;

  const formMap = formStore.formMap;
  const validationResult = formStore.validationResult;

  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilderVisibilityRules;
  const selectList = mainProps.selectList;
  const entId = mainProps.itemId;
  const formId = mainProps.formId;

  const listName = useAppSelector(state => selectList(state).listName);

  const form = useMemo(() => (formMap?.map && formId) ? formMap.map[formId] : undefined, [formMap?.map, formId]);

  const onClickItemInfo = useValidationErrorList(selectList, "visibilityRuleMap", undefined, validationResult);

  const cbOnSubmitFormVisibilityRule = useCallback((
    studioVisibilityRule: StudioVisibilityRule,
    isDuplicate?: boolean) =>
  {
    cbOnSubmit && cbOnSubmit(
      "visibilityRule",
      {
        formId,
        visibilityRule: studioVisibilityRule,
        isDuplicate: Boolean(isDuplicate)
      }
    );
  }, [cbOnSubmit, formId]);

  const cbOnRemoveFormVisibilityRule = useCallback((visibilityRuleId: MetaIdVisibilityRule) =>
  {
    cbOnRemove && cbOnRemove(
      "visibilityRule",
      {
        visibilityRuleId: visibilityRuleId
      }
    );
  }, [cbOnRemove]);

  const onClickListItem = useCallback((metaIdVisibilityRule: TypeListItemId, jumpPath?: IStudioJumpPath[]) =>
  {
    appCtx.setAsideProps({
      type: "FormVisibilityRules",
      formId: formId,
      formStore: formStore,
      setupMode: setupMode,
      readOnly: readOnly,
      selectListMap: selectListMap,
      cbOnVisibilityRuleSubmit: cbOnSubmitFormVisibilityRule,
      cbOnVisibilityRuleRemove: cbOnRemoveFormVisibilityRule,
      metaId: metaIdVisibilityRule,
      jumpPath: jumpPath,
      reset: true
    } as IAsidePropsStudioFormVisibilityRule);

    setSelectedMetaIdVisibilityRule(metaIdVisibilityRule);
  }, [
    appCtx,
    formId,
    formStore,
    setupMode,
    readOnly,
    selectListMap,
    cbOnSubmitFormVisibilityRule,
    cbOnRemoveFormVisibilityRule
  ]);

  const onClickMenu = useCallback((menuAnchor: Element, itemId: TypeListItemId, item?: IListItem) =>
    {
      pageCtx.showMenu(menuAnchor,
        {
          "Copy": () => copyStudioVisibilityRules(item?.userField?.value as StudioVisibilityRule),
          "Get CLI code": () => cbGetCliCode && cbGetCliCode(entId, itemId),
          "Duplicate": () => cbOnSubmitFormVisibilityRule(item?.userField?.value as StudioVisibilityRule, true),
          "Remove": () =>
          {
            cbOnRemoveFormVisibilityRule(itemId);
            if(selectedMetaIdVisibilityRule === itemId)
            {
              appCtx.setAsideProps(undefined);
            }
          },
          "gap": undefined,
          "Move up": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("visibilityRule", {
              visibilityRuleId: itemId,
              direction: "up"
            }),
            disabled: !item?.userField?.showMoveUp
          },
          "Move down": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("visibilityRule", {
              visibilityRuleId: itemId,
              direction: "down"
            }),
            disabled: !item?.userField?.showMoveDown
          },
          "Move top": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("visibilityRule", {
              visibilityRuleId: itemId,
              direction: "top"
            }),
            disabled: !item?.userField?.showMoveUp
          },
          "Move bottom": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("visibilityRule", {
              visibilityRuleId: itemId,
              direction: "bottom"
            }),
            disabled: !item?.userField?.showMoveDown
          }
        }
      );
    },
    [
      copyStudioVisibilityRules,
      cbGetCliCode,
      entId,
      cbOnSubmitFormVisibilityRule,
      cbOnRemoveFormVisibilityRule,
      selectedMetaIdVisibilityRule
    ]);

  const cbOnClick = useCallback((
    menuAnchor: Element,
    itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant,
    pickValue?: boolean,
    selected?: boolean
  ) =>
  {
    switch(variant)
    {
      case "listItem":
        selected && onClickListItem(itemId);
        break;
      case "spotMenu":
        !readOnly && onClickMenu(menuAnchor, itemId, item);
        break;
      case "spotInfo":
        onClickItemInfo(menuAnchor, itemId);
        break;
    }
  }, [onClickItemInfo, onClickListItem, onClickMenu, readOnly]);

  const cbOnClickSort = useCallback((type: TypeListSortType) =>
  {
    const actionSort = {
      sortType: type,
      setKey: "visibilityList"
    } as TypeActionSortFormList;
    const studioForm = sortFormItemList(actionSort, form);

    if(studioForm)
    {
      cbOnSubmit && cbOnSubmit("form", {
        formId,
        form: studioForm
      });
    }
  }, [form, formId]);

  useEffect(() =>
  {
    if(form?.visibilityRuleMap)
    {
      doLoad(listName, form?.visibilityRuleMap);
    }
  }, [form?.visibilityRuleMap, listName]);

  useStepListDefaultConfig(selectList, "visibilityRuleMap", onClickListItem, false, true);

  return (
    <>
      <LayoutFlexRow
        width={"100%"}
        pl={px(gapStd)}
        pt={px(gapHalf)}
        justifyContent={"flex-start"}
      >
        <RawIconStrip
          iconStrip={["backIos"]}
          onClick={() =>
          {
            appCtx.setMainProps({
              ...mainProps,
              jumpStepItem: "FormDetails"
            } as IMainPropsStudio);
            appCtx.setAsideProps(undefined);
          }}
        />
        <RawHighlighter
          value={"Form visibility rules"}
          variant={"body1"}
        />
      </LayoutFlexRow>

      <RawSearch
        placeHolder={"Search visibility rules"}
        onSearchBindListName={listName}
        showSorting={!readOnly}
        onClickSorting={cbOnClickSort}
      />

      <LayoutFlexCol
        flexGrow={1}
        width={"100%"}
        pl={px(gapStd)}
        pr={px(gapStd)}
      >
        <Box
          width={"100%"}
          height={"100%"}
          bgcolor={theme.common.bgcolorContent}
          border={`1px solid ${theme.common.borderColor}`}
        >
          <List
            selectList={selectList}
            emptyComp={"Add visibility rules"}
            onClickListItem={cbOnClick}
            emptySearch={STR_NOTHING_HERE}
          />
        </Box>
      </LayoutFlexCol>
    </>
  );
}

function doLoad(listName: string, visibilityRuleMap: StudioVisibilityRuleMap)
{
  const uiItemIds = [] as TypeListItemId[];
  const uiItemsById = {} as IListItemsById;

  const visibilityRuleKeys = visibilityRuleMap.keys;
  visibilityRuleKeys.forEach((itemId, index) =>
  {
    const visibilityRule = visibilityRuleMap.map[itemId];
    uiItemIds.push(itemId);

    const showMoveUp = visibilityRuleKeys.length !== 1 && index !== 0;
    const showMoveDown = visibilityRuleKeys.length !== 1 && index !== visibilityRuleKeys.length - 1;

    const description: ILineSecondary = {};

    if(visibilityRule.description && visibilityRule.description.length > 0)
    {
      description.text = visibilityRule.description;
    }
    else
    {
      description.text = "No description";
      description.color = "textDisabled";
    }

    uiItemsById[itemId] = {
      type: "ps",
      primary: {
        text: visibilityRule.name
      },
      secondary: description,
      userField: {
        value: visibilityRule,
        showMoveUp: showMoveUp,
        showMoveDown: showMoveDown
      }
    } as IListItemAPSA;
  });

  dispatchList(listName, listRefresh({
    itemIds: uiItemIds,
    itemsById: uiItemsById
  }));
}
