import {DropResult} from "react-beautiful-dnd";
import {DraggableRubric} from "react-beautiful-dnd";
import {Action} from "redux";
import {isOrphanOptionId} from "../../api/meta/base/ApiPlus";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {FieldValueOptionId} from "../../api/meta/base/dto/FieldValueOptionId";
import {MetaIdOption} from "../../api/meta/base/Types";
import {RootState} from "../../Store";
import {store} from "../../Store";
import {fnCreateKanbanSlice} from "../slices/kanban/SliceKanban";
import {getKanbanNameFake} from "../slices/kanban/SliceKanban";
import {IKanbanConfig} from "../types/TypeKanban";
import {OnChangeKanbanPayload} from "../types/TypeKanban";
import {KANBAN_UNCATEGORIZED_COLUMN_TITLE} from "../types/TypeKanban";
import {IKanban} from "../types/TypeKanban";
import {getFormFieldValueAsTextWithPrefixSuffix} from "./FieldValuePlus";
import {toLabel} from "./StringPlus";

export function dispatchKanban(kanban: IKanban | string, action: Action<string>)
{
  action.type = action.type.replace(getKanbanNameFake(), typeof kanban === "string" ? kanban : kanban.kanbanName);
  return store.dispatch(action);
}

export type SelectKanban = (state: RootState) => IKanban;

export function kanbanName(getState: () => RootState, selectKanban: SelectKanban): string
{
  return selectKanban(getState()).kanbanName;
}

export function kanbanVersion(getState: () => RootState, selectKanban: SelectKanban): string | undefined
{
  return selectKanban(getState()).version;
}

export function createKanbanSlice(name: string, config?: IKanbanConfig)
{
  if(name === getKanbanNameFake())
  {
    throw new Error(`Kanban name, ${name}, not allowed`);
  }
  else
  {
    return fnCreateKanbanSlice(name, config);
  }
}

export function fnGetKanbanFieldValueAsText(defnField?: DefnField, optionId?: MetaIdOption): string | undefined
{
  if(!defnField)
  {
    return KANBAN_UNCATEGORIZED_COLUMN_TITLE;
  }
  if(isOrphanOptionId(optionId))
  {
    return KANBAN_UNCATEGORIZED_COLUMN_TITLE;
  }
  if(defnField?.type === "pickText")
  {
    const value = getFormFieldValueAsTextWithPrefixSuffix(defnField, {
      value: optionId,
      optionId: optionId
    } as FieldValueOptionId);

    return toLabel(value ?? "");
  }
}

export function fnGetOnChangeKanbanPayload(result: DraggableRubric): OnChangeKanbanPayload
{
  const itemId = result.draggableId;
  const source = result.source;
  const sourceColumnId = source.droppableId;

  const destination = (result as DropResult)?.destination;
  const destinationDroppableId = destination?.droppableId;
  const destinationColumnId = destinationDroppableId
    ? destinationDroppableId
    : sourceColumnId;

  return {
    itemId,
    sourceColumnId,
    destinationColumnId,
    destinationIndex: destination?.index
  };
}
