import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {Property} from "csstype";
import {MouseEventHandler} from "react";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";

export default function LayoutFlexCol(props: {
  children: React.ReactNode;
  bgcolor?: CssBackgroundColor;
  mt?: StandardCSSProperties["marginTop"],
  mb?: StandardCSSProperties["marginBottom"],
  ml?: StandardCSSProperties["marginLeft"],
  mr?: StandardCSSProperties["marginRight"],
  padding?: Property.Padding,
  pt?: StandardCSSProperties["paddingTop"],
  pb?: StandardCSSProperties["paddingBottom"],
  pl?: StandardCSSProperties["paddingLeft"],
  pr?: StandardCSSProperties["paddingRight"],
  border?: Property.Border,
  borderRadius?: Property.BorderRadius,
  flexGrow?: StandardCSSProperties["flexGrow"],
  flexShrink?: StandardCSSProperties["flexShrink"],
  alignItems?: Property.AlignItems,
  justifyContent?: Property.JustifyContent,
  width?: Property.Width,
  height?: Property.Height,
  maxHeight?: Property.MaxHeight,
  minHeight?: Property.MaxHeight,
  maxWidth?: Property.MaxWidth,
  minWidth?: Property.MinWidth,
  overflowX?: Property.OverflowX,
  overflowY?: Property.OverflowY,
  cursorPointer?: boolean,
  onMouseEnter?: MouseEventHandler<HTMLDivElement>,
  onMouseLeave?: MouseEventHandler<HTMLDivElement>,
  onClick?: MouseEventHandler<HTMLDivElement>,
  displayNone?: boolean,
  flex?: Property.Flex
})
{
  return (
    <Box
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
      sx={{
        display: Boolean(props.displayNone) ? "none" : "flex",
        position: "relative",
        flexDirection: "column",
        border: props.border,
        flexGrow: props.flexGrow,
        flexShrink: props.flexShrink ? props.flexShrink : 0,
        overflowX: props.overflowX ? props.overflowX : "hidden",
        overflowY: props.overflowY ? props.overflowY : "hidden",
        bgcolor: props.bgcolor,
        mt: props.mt ?? px(0),
        mb: props.mb ?? px(0),
        ml: props.ml ?? px(0),
        mr: props.mr ?? px(0),
        width: props.width,
        height: props.height,
        maxHeight: props.maxHeight,
        minHeight: props.minHeight,
        maxWidth: props.maxWidth,
        minWidth: props.minWidth,
        padding: props.padding,
        pt: props.pt,
        pb: props.pb,
        pl: props.pl,
        pr: props.pr,
        borderRadius: props.borderRadius,
        cursor: Boolean(props.cursorPointer) ? "pointer" : undefined,
        alignItems: props.alignItems ? props.alignItems : "center",
        justifyContent: props.justifyContent ? props.justifyContent : "center",
        flex: props.flex
      }}
    >
      {props.children}
    </Box>
  );
}
