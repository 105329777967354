import _ from "lodash";
import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {MsgStudioEntCreate} from "../../../api/studio/studioDrawer/msg/MsgStudioEntCreate";
import {MsgStudioPluginCreate} from "../../../api/studio/studioDrawer/msg/MsgStudioPluginCreate";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import ISrvc from "../../../base/ISrvc";
import {SelectList} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getListItemStudio} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {hasEditLockPlugin} from "../../../base/plus/StudioPlus";
import {hasEditLockEnt} from "../../../base/plus/StudioPlus";
import {listSetIfExistSecondaryLine} from "../../../base/slices/list/SliceListAPSAActions";
import {listClearItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetCanShowMenu} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListBinderTwo} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigStudioItemAvatar = SigStudioEntAvatar | SigStudioPluginAvatar | undefined;

const subscriberId = "SrvcStudioDrawerRecentList";

export default class SrvcStudioDrawerRecentList extends ISrvc
{
  listTopicRpcEntDelete = "listTopicRpcEntDelete";

  constructor(readonly selectList: SelectList)
  {
    super();
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.onBindSourceItem.bind(this),
      selectSourceItem2: this.selectEditLock.bind(this),
      onBindSourceItem2: this.onBindEditLock.bind(this)
    } as IListBinderTwo<TypeSigStudioItemAvatar, boolean>;
  }

  subscribe(artifactId: ArtifactId, unSubscribe?: boolean)
  {
    if(isEntId(artifactId) || isPluginId(artifactId))
    {
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
  }

  subscribeBulk(artifactIdSet: ArtifactId[], unSubscribe?: boolean)
  {
    artifactIdSet.forEach(artifactId =>
    {
      if(isEntId(artifactId))
      {
        Srvc.app.pubsub.caller.entAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
      else if(isPluginId(artifactId))
      {
        Srvc.app.pubsub.caller.pluginAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
    });
  }

  load(
    listName: string,
    entIdAdminSet: EntId[],
    pluginIdAdminSet: PluginBundleId[],
    filter?: EntId[],
    hideMenu?: boolean)
  {
    const itemIds = (!filter || filter.length === 0)
      ? [...entIdAdminSet, ...pluginIdAdminSet]
      : [..._.intersection(entIdAdminSet, filter), ..._.intersection(pluginIdAdminSet, filter)];
    const itemsById = {} as IListItemsById;
    const rootState = store.getState();
    const entIdAdminAvatarMap = rootState.cache.app.caller.entIdAdminAvatarMap;
    const pluginIdAdminAvatarMap = rootState.cache.app.caller.pluginIdAdminAvatarMap;

    Promise.all(itemIds.map(itemId =>
    {
      return new Promise<void>((resolve) =>
      {
        if(isEntId(itemId))
        {
          if(entIdAdminAvatarMap[itemId])
          {
            itemsById[itemId] = getListItemStudio(itemId, entIdAdminAvatarMap[itemId]) as IListItem;
            resolve();
          }
          else
          {
            Srvc.cache.app.caller.wsocEntAvatarAdminGet(
              itemId,
              (sig) =>
              {
                itemsById[itemId] = getListItemStudio(itemId, sig) as IListItem;
                resolve();
              },
              resolve
            );
          }
        }
        else if(isPluginId(itemId))
        {
          if(pluginIdAdminAvatarMap[itemId])
          {
            itemsById[itemId] = getListItemStudio(itemId, pluginIdAdminAvatarMap[itemId]) as IListItem;
            resolve();
          }
          else
          {
            Srvc.cache.app.caller.wsocPluginAvatarAdminGet(
              itemId,
              (sig) =>
              {
                itemsById[itemId] = getListItemStudio(itemId, sig) as IListItem;
                resolve();
              },
              resolve
            );
          }
        }
        else
        {
          resolve();
        }
      });
    })).then(() =>
    {
      const listData = {
        itemIds: itemIds,
        itemsById: itemsById,
        version: random()
      } as IListData;

      dispatchList(listName, listRefresh(listData));

      if(hideMenu)
      {
        dispatchList(listName, listSetCanShowMenu(false));
      }
    });
  }

  setRecentListIdSelected(listName: string, oldSelectedItemId?: ArtifactId)
  {
    if(oldSelectedItemId)
    {
      dispatchList(listName, listSetSelectedItemId(oldSelectedItemId));
    }
  }

  rpcEntCreate(msg: MsgStudioEntCreate, cbSuccess: CbSuccess)
  {
    RpcStudioDrawer.studioEntCreate(msg, cbProgress =>
    {
      if(cbProgress.error)
      {
        Srvc.app.toast.showErrorToast(cbProgress);
      }
      else
      {
        cbSuccess();
      }
    });
  }

  rpcPluginCreate(msg: MsgStudioPluginCreate, cbSuccess: CbSuccess)
  {
    RpcStudioDrawer.studioPluginCreate(msg, cbProgress =>
    {
      if(cbProgress.error)
      {
        Srvc.app.toast.showErrorToast(cbProgress);
      }
      else
      {
        cbSuccess();
      }
    });
  }

  //region private

  private selectSourceItem(state: RootState, itemId: TypeListItemId)
  {
    if(isEntId(itemId))
    {
      return state.cache.app.caller.entIdAdminAvatarMap[itemId];
    }
    else
    {
      return state.cache.app.caller.pluginIdAdminAvatarMap[itemId];
    }
  }

  private onBindSourceItem(listName: string, itemId: TypeListItemId, avatar?: TypeSigStudioItemAvatar): void
  {
    const listItemStudio = getListItemStudio(itemId, avatar);

    if(listItemStudio)
    {
      dispatchList(listName, listSetItem({
        itemId: itemId,
        newItem: listItemStudio
      }));
    }
    else
    {
      dispatchList(listName, listClearItem(itemId));
    }
  }

  private selectEditLock(state: RootState, itemId: TypeListItemId)
  {
    if(isEntId(itemId))
    {
      return hasEditLockEnt(state, itemId);
    }
    else if(isPluginId(itemId))
    {
      return hasEditLockPlugin(state, itemId);
    }
  }

  private onBindEditLock(listName: string, itemId: TypeListItemId, hasLock?: boolean): void
  {
    if(isEntId(itemId) || isPluginId(itemId))
    {
      dispatchList(listName, listSetIfExistSecondaryLine({
        itemId: itemId,
        icon4: hasLock ? "unlock" : undefined
      }));
    }
  }

  //endregion
}
