import {EntId} from "../../../api/meta/base/Types";
import {SigEntUserList} from "../../../api/studio/studioMain/sig/SigEntUserList";
import ISrvc from "../../../base/ISrvc";
import {TypeAdminPanelUserFilterType} from "../../../base/plus/AdminPanelPlus";
import {store} from "../../../Store";
import {removeEntUserList} from "../../studio/ent/SliceCacheStudioEnt";
import {setEntUserList} from "../../studio/ent/SliceCacheStudioEnt";
import {dispatchEnt} from "../../studio/SrvcCacheStudio";
import {setUserFilter} from "../SliceCacheAdminPanel";

export default class SrvcCacheAdminPanelUsers extends ISrvc
{
  setEntUserList(entId: EntId, sig: SigEntUserList)
  {
    dispatchEnt(entId, setEntUserList({
      entId: entId,
      sigEntUserList: sig
    }));
  }

  removeEntUsersList(entId: EntId)
  {
    dispatchEnt(entId, removeEntUserList(entId));
  }

  setUserFilter(entId: EntId, filterType: TypeAdminPanelUserFilterType)
  {
    store.dispatch(setUserFilter({
      artifactId: entId,
      userFilterType: filterType
    }));
  }
}
