import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {ArtifactId} from "../../api/meta/base/Types";
import {TypeAdminPanelUserFilterType} from "../../base/plus/AdminPanelPlus";
import {ActionUpdateEntDeployLock} from "./TypeCacheAdminPanel";
import {ActionSortAdminPanelList} from "./TypeCacheAdminPanel";
import {ActionSetAdminPanelCurrentTab} from "./TypeCacheAdminPanel";
import {ICacheAdminPanelMap} from "./TypeCacheAdminPanel";

export const sliceCacheAdminPanel = createSlice({
  name: "adminPanel",
  initialState: {
    stateMap: {}
  } as ICacheAdminPanelMap,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<ActionSetAdminPanelCurrentTab>) =>
    {
      const {
        artifactId,
        currentStep,
        currentTab
      } = action.payload;

      if(currentTab && state.stateMap[artifactId]?.currentTab !== currentTab)
      {
        state.stateMap[artifactId] = {
          ...state.stateMap[artifactId],
          currentTab: currentTab
        };
      }

      if(currentStep && state.stateMap[artifactId]?.currentStep !== currentStep)
      {
        state.stateMap[artifactId] = {
          ...state.stateMap[artifactId],
          currentStep: currentStep
        };
      }
    },

    setUserFilter(
      state: ICacheAdminPanelMap,
      action: PayloadAction<{userFilterType: TypeAdminPanelUserFilterType} & {artifactId: ArtifactId}>)
    {
      const {
        artifactId,
        userFilterType
      } = action.payload;

      if(userFilterType && state.stateMap[artifactId]?.userFilter !== userFilterType)
      {
        state.stateMap[artifactId] = {
          ...state.stateMap[artifactId],
          userFilter: userFilterType
        };
      }
    },

    setSort(state: ICacheAdminPanelMap, action: PayloadAction<ActionSortAdminPanelList>)
    {
      const {
        artifactId,
        tab,
        sortType
      } = action.payload;

      if(tab === "Users")
      {
        if(sortType && state.stateMap[artifactId]?.userSort !== sortType)
        {
          state.stateMap[artifactId] = {
            ...state.stateMap[artifactId],
            userSort: sortType
          };
        }
      }
      else if(tab === "Admins")
      {
        if(sortType && state.stateMap[artifactId]?.adminSort !== sortType)
        {
          state.stateMap[artifactId] = {
            ...state.stateMap[artifactId],
            adminSort: sortType
          };
        }
      }
    },

    updateSettings(state: ICacheAdminPanelMap, action: PayloadAction<ActionUpdateEntDeployLock>)
    {

    }
  }
});

export const {
  setCurrentTab,
  setUserFilter,
  setSort,
  updateSettings
} = sliceCacheAdminPanel.actions;
