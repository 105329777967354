// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ArtifactId} from '../../meta/base/Types';
import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgAdminId} from '../../studio/studioMain/msg/MsgAdminId';
import {MsgAdminInvite} from '../../studio/studioMain/msg/MsgAdminInvite';
import {MsgStoreAdminAdd} from './msg/MsgStoreAdminAdd';
import {MsgStoreAdminUpdate} from '../studioMain/msg/MsgStoreAdminUpdate';
import {MsgStoreFilters} from './msg/MsgStoreFilters';
import {MsgStoreItemCreate} from './msg/MsgStoreItemCreate';
import {MsgStoreItemId} from './msg/MsgStoreItemId';
import {MsgStoreItemProvision} from './msg/MsgStoreItemProvision';
import {MsgStoreSearch} from './msg/MsgStoreSearch';
import {MsgStudioEntMerge} from './msg/MsgStudioEntMerge';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {ServiceName} from '../../meta/base/Types';
import {SigAdminEditLockDetail} from '../../studio/studioMain/sig/SigAdminEditLockDetail';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigStoreAdminCaller} from './sig/SigStoreAdminCaller';
import {SigStoreAdminList} from './sig/SigStoreAdminList';
import {SigStoreFilterList} from './sig/SigStoreFilterList';
import {SigStoreItem} from './sig/SigStoreItem';
import {SigStoreItemAvatar} from './sig/SigStoreItemAvatar';
import {SigStoreItemListGet} from './sig/SigStoreItemListGet';
import {SigStoreSearch} from './sig/SigStoreSearch';
import {SigStudioEntMerge} from './sig/SigStudioEntMerge';
import {StoreItemId} from '../../meta/base/Types';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcStore
{
  static readonly SN: ServiceName = "store";

  static migrateArtifacts(sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcStore.SN, "migrateArtifacts")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static storeAdminAdd(storeItemId: StoreItemId, msg: MsgStoreAdminAdd, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(storeItemId, RpcStore.SN, "storeAdminAdd")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static storeAdminCallerGet(storeItemId: StoreItemId, sigAcceptor: ISigAcceptor<SigStoreAdminCaller>): void
  {
    rpcCall<SigStoreAdminCaller>(storeItemId, RpcStore.SN, "storeAdminCallerGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static storeAdminEditLockDetailGet(storeItemId: StoreItemId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigAdminEditLockDetail>): void
  {
    rpcCall<SigAdminEditLockDetail>(storeItemId, RpcStore.SN, "storeAdminEditLockDetailGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static storeAdminEditLockTransfer(storeItemId: StoreItemId, msg: MsgAdminId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(storeItemId, RpcStore.SN, "storeAdminEditLockTransfer")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static storeAdminExit(storeItemId: StoreItemId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(storeItemId, RpcStore.SN, "storeAdminExit")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static storeAdminInvite(storeItemId: StoreItemId, msg: MsgAdminInvite, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(storeItemId, RpcStore.SN, "storeAdminInvite")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static storeAdminListGet(msg: MsgStoreItemId, sigAcceptor: ISigAcceptor<SigStoreAdminList>): void
  {
    rpcCall<SigStoreAdminList>(ENT_ID_GLOBAL, RpcStore.SN, "storeAdminListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static storeAdminRemove(storeItemId: StoreItemId, msg: MsgAdminId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(storeItemId, RpcStore.SN, "storeAdminRemove")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static storeAdminUpdate(storeItemId: StoreItemId, msg: MsgStoreAdminUpdate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(storeItemId, RpcStore.SN, "storeAdminUpdate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static storeFilterListGet(msg: MsgVersion, sigAcceptor: ISigAcceptor<SigStoreFilterList>): void
  {
    rpcCall<SigStoreFilterList>(ENT_ID_GLOBAL, RpcStore.SN, "storeFilterListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static storeItemAvatarGet(msg: MsgStoreItemId, sigAcceptor: ISigAcceptor<SigStoreItemAvatar>): void
  {
    rpcCall<SigStoreItemAvatar>(ENT_ID_GLOBAL, RpcStore.SN, "storeItemAvatarGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static storeItemCreate(artifactId: ArtifactId, msg: MsgStoreItemCreate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(artifactId, RpcStore.SN, "storeItemCreate")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static storeItemEntMerge(msg: MsgStudioEntMerge, sigAcceptor: ISigAcceptor<SigStudioEntMerge>): void
  {
    rpcCall<SigStudioEntMerge>(ENT_ID_GLOBAL, RpcStore.SN, "storeItemEntMerge")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static storeItemGet(msg: MsgStoreItemId, sigAcceptor: ISigAcceptor<SigStoreItem>): void
  {
    rpcCall<SigStoreItem>(ENT_ID_GLOBAL, RpcStore.SN, "storeItemGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static storeItemListGet(msg: MsgStoreFilters, sigAcceptor: ISigAcceptor<SigStoreItemListGet>): void
  {
    rpcCall<SigStoreItemListGet>(ENT_ID_GLOBAL, RpcStore.SN, "storeItemListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static storeItemProvision(msg: MsgStoreItemProvision, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcStore.SN, "storeItemProvision")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static storeItemRemove(storeItemId: StoreItemId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(storeItemId, RpcStore.SN, "storeItemRemove")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static storeSearch(msg: MsgStoreSearch, sigAcceptor: ISigAcceptor<SigStoreSearch>): void
  {
    rpcCall<SigStoreSearch>(ENT_ID_GLOBAL, RpcStore.SN, "storeSearch")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }
}