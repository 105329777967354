import ISrvc from "../../base/ISrvc";
import SrvcCacheToolsAutomation from "./automation/SrvcCacheToolsAutomation";
import SrvcCacheToolsLog from "./log/SrvcCacheToolsLog";
import SrvcCacheToolsQuery from "./query/SrvcCacheToolsQuery";

export default class SrvcCacheTools extends ISrvc
{
  public readonly query = new SrvcCacheToolsQuery();
  public readonly automation = new SrvcCacheToolsAutomation();
  public readonly log = new SrvcCacheToolsLog();

  constructor()
  {
    super();
    this.setSrvcArray(
      this.query,
      this.automation,
      this.log
    );
  }
}
