import {useTheme} from "@mui/material";
import {Box, Chip, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {DateTimeValidationError} from "@mui/x-date-pickers";
import {FieldSection} from "@mui/x-date-pickers";
import {BaseSingleInputFieldProps} from "@mui/x-date-pickers";
import {DateTimePicker} from "@mui/x-date-pickers";
import {PickerChangeHandlerContext} from "@mui/x-date-pickers/models";
import {PickerValidDate} from "@mui/x-date-pickers/models/pickers";
import {isArray} from "lodash";
import {useMemo} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {DefnFieldChipSetDateTime} from "../../../api/meta/base/dto/DefnFieldChipSetDateTime";
import {DefnFieldDateTime} from "../../../api/meta/base/dto/DefnFieldDateTime";
import {FieldChipSetDateTime} from "../../../api/meta/base/dto/FieldChipSetDateTime";
import {AnyTime} from "../../../api/meta/base/Types";
import {formatDateToISO} from "../../../base/plus/DatePlus";
import {is12HourFormat} from "../../../base/plus/DatePlus";
import {formatDate} from "../../../base/plus/DatePlus";
import {getLocalDateFormat} from "../../../base/plus/DatePlus";
import {px} from "../../../base/plus/StringPlus";
import IFormCtx from "../../form/viewer/base/CtxForm";
import {getRawIcon} from "./RawIcon";
import RawLocalizationProvider from "./RawLocalizationProvider";

export default function RawDateTimeSet(props: {
  defn: DefnFieldDateTime | DefnFieldChipSetDateTime
  onChange: (values?: FieldChipSetDateTime | null) => void;
  fieldValue?: FieldChipSetDateTime;
  formCtx: IFormCtx,
  fieldId: string,
  readonly?: boolean;
  disabled?: boolean;
  label?: string;
  placeHolder?: string;
  helperText?: string;
  name?: string;
  allowDuplicate?: boolean;
  inputFieldSize?: "small" | "medium";
  hideLabel?: boolean;
  error?: FieldError;
  required?: boolean,
  icon?: string,
})
{
  const {
    defn,
    fieldValue,
    onChange,
    readonly,
    disabled,
    placeHolder,
    allowDuplicate,
    formCtx,
    fieldId,
    icon
  } = props;

  const fieldValueDateTimeSet = useMemo(() => fieldValue?.valueSet && isArray(fieldValue.valueSet)
    ? fieldValue.valueSet
    : [], [fieldValue?.valueSet]);
  const is12hrFormat = is12HourFormat(defn.displayDateFormat);

  const onAccept = useCallback((value: any, context: PickerChangeHandlerContext<DateTimeValidationError>) =>
  {
    if(context.validationError !== "invalidDate")
    {
      const isoString = formatDateToISO(new Date(value));
      const updatedValue = [...fieldValueDateTimeSet, isoString];

      if(!allowDuplicate && fieldValueDateTimeSet && fieldValueDateTimeSet.length > 0)
      {
        fieldValueDateTimeSet.indexOf(isoString) === -1 &&
        onChange({
          valueSet: updatedValue
        });
      }
      else
      {
        onChange({
          valueSet: updatedValue
        });
      }
    }
  }, [allowDuplicate, fieldValueDateTimeSet, onChange]);

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const dateTimeFormat = defn?.displayDateFormat
    ? defn.displayDateFormat
    : getLocalDateFormat();

  return (
    <RawLocalizationProvider>
      <DateTimePicker
        sx={{
          ...borderColor && {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: borderColor
              }
            }
          }
        }}
        format={dateTimeFormat}
        readOnly={readonly}
        disabled={disabled}
        ampm={is12hrFormat}
        views={["year", "month", "day", "hours", "minutes", "seconds"]}
        timeSteps={{
          hours: 1,
          minutes: 1,
          seconds: 1
        }}
        slots={{
          field: AutocompleteField,
          openPickerIcon: getRawIcon(icon ?? "InsertInvitation")
        }}
        slotProps={{
          field: () => ({
            ...props,
            placeHolder: placeHolder,
            dateTimeFormat: dateTimeFormat,
            onChangeField: onChange
          })
        }}
        onAccept={onAccept}
      />
    </RawLocalizationProvider>
  );
}

type AutoCompleteFieldProps = BaseSingleInputFieldProps<any, PickerValidDate, FieldSection, boolean, any> &
  {
    onChangeField: (values?: FieldChipSetDateTime | null) => void,
    openPickerIcon: React.ReactNode,
    fieldValue?: FieldChipSetDateTime;
    readonly?: boolean;
    disabled?: boolean;
    label?: string;
    placeHolder?: string;
    helperText?: string;
    name?: string;
    allowDuplicate?: boolean;
    inputFieldSize?: "small" | "medium";
    hideLabel?: boolean;
    error?: FieldError;
    required?: boolean,
    fieldId: string,
    dateTimeFormat?: string,
    formCtx: IFormCtx,
  }

function AutocompleteField(props: AutoCompleteFieldProps)
{
  const {
    label,
    disabled,
    readOnly,
    fieldValue,
    onChangeField,
    error,
    placeHolder,
    inputFieldSize,
    InputProps: {
      ref,
      endAdornment
    } = {},
    inputProps,
    dateTimeFormat,
    formCtx,
    fieldId,
    hideLabel
  } = props;

  const fieldValueDateTimeSet = fieldValue?.valueSet && isArray(fieldValue.valueSet)
    ? fieldValue.valueSet
    : [];

  const theme = useTheme();

  const onDelete = useCallback((chipToDelete: string) => () =>
  {
    if(fieldValue?.valueSet?.length)
    {
      const chips = fieldValue.valueSet.filter((currChip: AnyTime) =>
        currChip !== chipToDelete);

      onChangeField({
        valueSet: chips
      });
    }
    else
    {
      onChangeField(null);
    }

  }, [onChangeField, fieldValue]);

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  return (
    <Autocomplete
      multiple
      freeSolo
      readOnly={true}
      ref={ref}
      fullWidth={true}
      disabled={disabled}
      renderTags={(chips: string[]) =>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.7
          }}
        >
          {
            chips.map((chip, index) =>
            {
              const formatedDateTime = formatDate(
                chip,
                dateTimeFormat,
                undefined,
                true
              );

              return (
                <Chip
                  key={chip + index}
                  deleteIcon={disabled ? <></> : undefined}
                  label={formatedDateTime}
                  onDelete={!readOnly && !disabled
                    ? onDelete(chip)
                    : undefined}
                  sx={{
                    height: px(theme.common.chipHeight),
                    borderRadius: "4px",
                    "& .MuiSvgIcon-root": {
                      height: px(theme.common.chipCrossButtonSize),
                      fontSize: px(theme.common.chipCrossButtonSize)
                    }
                  }}
                />
              );
            })
          }
        </Box>
      }
      renderInput={(params) =>
      {
        return <TextField
          {...params}
          inputProps={{...params.inputProps, ...inputProps}}
          InputProps={{
            ...params.InputProps,
            endAdornment: endAdornment || params.InputProps.endAdornment
          }}
          onClick={onClick}
          sx={{
            "& .MuiInputAdornment-positionEnd": {
              "& .MuiButtonBase-root": {
                position: "absolute",
                right: theme.common.gapStd
              }
            },
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          label={!hideLabel ? label : undefined}
          placeholder={placeHolder}
          error={Boolean(error?.message)}
          aria-readonly={readOnly}
          disabled={disabled}
          size={inputFieldSize ?? "small"}
        />;
      }}
      options={[]}
      value={fieldValueDateTimeSet}
    />
  );
}
