import {DeleteRounded} from "@mui/icons-material";
import {FormHelperText, IconButton} from "@mui/material";
import {useTheme} from "@mui/material";
import {Stack} from "@mui/material";
import {cloneDeep} from "lodash";
import {useRef} from "react";
import React from "react";
import {useFieldArray} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioCompArray} from "../../../../api/meta/base/dto/DefnStudioCompArray";
import {MetaIdComp} from "../../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../../base/plus/ConstantsPlus";
import {ensureInitValues} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {getComp} from "../../../../base/plus/FormPlus";
import {getStudioArrayKey} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {DividerHorizontal} from "../../../atom/layout/DividerHorizontal";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import {useFormCtx} from "../base/CtxForm";
import IFormSectionCtx from "../base/CtxFormSection";
import {ctxFormSection} from "../base/CtxFormSection";
import FieldFactory from "../base/FieldFactory";
import FieldBase from "../raw/FieldBase";

export default function FieldStudioArray(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnStudioCompArray,
})
{
  const defnForm = props.defnForm;
  const defnFieldArray = props.defn as DefnStudioCompArray;
  const fieldId = getFieldKey(defnFieldArray);

  const formSectionCtx = useRef({} as IFormSectionCtx);
  const formCtx = useFormCtx();
  const theme = useTheme();

  const control = formCtx.control();
  formSectionCtx.current.getParent = () => defnFieldArray;
  formSectionCtx.current.getDefnForm = () => defnForm;
  formSectionCtx.current.getDefn = (key) => defnForm.compMap[key] as DefnFieldUi;

  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: fieldId
  });

  return (
    <ctxFormSection.Provider value={formSectionCtx.current}>
      <Controller
        name={fieldId}
        control={formCtx.control()}
        render={({
          field,
          fieldState
        }) =>
        {
          const {error} = fieldState;

          const isError = Boolean(error);

          const isDisabled = formCtx.isFieldDisable(defnFieldArray as DefnFieldEditable) || defnFieldArray.disabled;

          return (
            <FieldBase
              fieldId={fieldId}
              width={"100%"}
            >
              <LayoutFlexCol
                overflowY={"visible"}
                overflowX={"visible"}
              >
                {fields.length === 0 && <RawNothingHere
                  helperTextData={{
                    title: STR_NOTHING_HERE
                  }}
                />}
                <Stack
                  spacing={props.defnTheme.rowSpacing}
                  width={"100%"}
                >
                  {fields?.map((item, index) =>
                    {
                      const showSeparator = defnFieldArray.showSeparator && (index !== (fields.length - 1));

                      return (
                        <>
                          <Stack
                            spacing={props.defnTheme.colSpacing}
                            direction={"row"}
                            key={item.id}
                          >
                            {
                              defnFieldArray?.fieldIdSet?.map((compKey) =>
                              {
                                const _defnForm = cloneDeep(defnForm);
                                const defnSectionComp = getComp(_defnForm, compKey) as DefnFieldUi;
                                defnSectionComp.metaId = getStudioArrayKey(fieldId, index, compKey);
                                defnSectionComp._key = getStudioArrayKey(fieldId, index, compKey);

                                if(defnSectionComp.type === "section")
                                {
                                  replaceSectionChildMetaIds(defnSectionComp as DefnSection, _defnForm, fieldId, index);
                                }

                                return (
                                  <FieldFactory
                                    defnForm={_defnForm}
                                    defnTheme={props.defnTheme}
                                    defnComp={defnSectionComp}
                                    key={getStudioArrayKey(fieldId, index, compKey)}
                                  />
                                );
                              })
                            }
                            <LayoutFlexRow>
                              <IconButton
                                onClick={() =>
                                {
                                  remove(index);
                                }}
                                size={"small"}
                                disabled={isDisabled}
                              >
                                <DeleteRounded />
                              </IconButton>
                            </LayoutFlexRow>
                          </Stack>
                          {showSeparator && <DividerHorizontal />}
                        </>
                      );
                    }
                  )}
                </Stack>
              </LayoutFlexCol>
              {isError &&
                <LayoutFlexRow
                  width={"100%"}
                  justifyContent={"flex-start"}
                  pl={px(theme.common.gapStd)}
                >
                  <FormHelperText error={isError}>
                    {error?.message}
                  </FormHelperText>
                </LayoutFlexRow>
              }
              <LayoutFlexRow
                width={"100%"}
                justifyContent={"flex-end"}
                pt={px(theme.common.gapStd)}
              >
                <RawButtonStrip
                  iconButtonList={["add"]}
                  onClick={(iconName) =>
                  {
                    if(iconName === "add")
                    {
                      append(getInitValues(defnForm, defnFieldArray?.fieldIdSet), {});
                    }
                  }}
                  iconButtonDisable={isDisabled ? ["add"] : []}
                />
              </LayoutFlexRow>

            </FieldBase>
          );
        }
        }
      />
    </ctxFormSection.Provider>
  );
}

function getInitValues(defnForm: DefnFormUi, fieldIdSet?: MetaIdComp[])
{
  const initObj: Record<MetaIdComp, any> = ensureInitValues(defnForm, {});
  const initValues: Record<MetaIdComp, any> = {};
  fieldIdSet?.forEach(value =>
  {
    initValues[value] = initObj[value];
  });
  return initValues;
}

function replaceSectionChildMetaIds(child: DefnSection, defnForm: DefnFormUi, fieldId: MetaIdComp, index: number)
{
  child.fieldIdSet?.forEach((childFieldId) =>
  {
    const defnFieldComp = getComp(defnForm, childFieldId);

    if(defnFieldComp.type === "section")
    {
      defnFieldComp.metaId = getStudioArrayKey(fieldId, index, childFieldId);
      defnFieldComp.name = getStudioArrayKey(fieldId, index, childFieldId);
      defnFieldComp._key = getStudioArrayKey(fieldId, index, childFieldId);
      replaceSectionChildMetaIds(defnFieldComp as DefnSection, defnForm, fieldId, index);
    }
    else
    {
      defnFieldComp.metaId = getStudioArrayKey(fieldId, index, childFieldId);
      defnFieldComp.name = getStudioArrayKey(fieldId, index, childFieldId);
      defnFieldComp._key = getStudioArrayKey(fieldId, index, childFieldId);
    }
  });
}


