import {cloneDeep} from "lodash";
import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextRowId} from "../../api/meta/base/ApiPlus";
import {isVariableId} from "../../api/meta/base/ApiPlus";
import {DefnBuildDate} from "../../api/meta/base/dto/DefnBuildDate";
import {DefnBuildDateTime} from "../../api/meta/base/dto/DefnBuildDateTime";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnDtoText} from "../../api/meta/base/dto/DefnDtoText";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldChipSetDate} from "../../api/meta/base/dto/DefnFieldChipSetDate";
import {DefnFieldChipSetDateTime} from "../../api/meta/base/dto/DefnFieldChipSetDateTime";
import {DefnFieldChipSetDay} from "../../api/meta/base/dto/DefnFieldChipSetDay";
import {DefnFieldChipSetDeviceSize} from "../../api/meta/base/dto/DefnFieldChipSetDeviceSize";
import {DefnFieldChipSetDeviceType} from "../../api/meta/base/dto/DefnFieldChipSetDeviceType";
import {DefnFieldChipSetTime} from "../../api/meta/base/dto/DefnFieldChipSetTime";
import {DefnFieldColor} from "../../api/meta/base/dto/DefnFieldColor";
import {DefnFieldCounter} from "../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldDate} from "../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldDateRange} from "../../api/meta/base/dto/DefnFieldDateRange";
import {DefnFieldDateTime} from "../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldDateTimeRange} from "../../api/meta/base/dto/DefnFieldDateTimeRange";
import {DefnFieldDecimal} from "../../api/meta/base/dto/DefnFieldDecimal";
import {DefnFieldDuration} from "../../api/meta/base/dto/DefnFieldDuration";
import {DefnFieldDynamic} from "../../api/meta/base/dto/DefnFieldDynamic";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldEmail} from "../../api/meta/base/dto/DefnFieldEmail";
import {DefnFieldGeoPoint} from "../../api/meta/base/dto/DefnFieldGeoPoint";
import {DefnFieldHandle} from "../../api/meta/base/dto/DefnFieldHandle";
import {DefnFieldHtml} from "../../api/meta/base/dto/DefnFieldHtml";
import {DefnFieldHyperlink} from "../../api/meta/base/dto/DefnFieldHyperlink";
import {DefnFieldHyperlinkRow} from "../../api/meta/base/dto/DefnFieldHyperlinkRow";
import {DefnFieldInfo} from "../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLanguage} from "../../api/meta/base/dto/DefnFieldLanguage";
import {DefnFieldLineStroke} from "../../api/meta/base/dto/DefnFieldLineStroke";
import {DefnFieldLocation} from "../../api/meta/base/dto/DefnFieldLocation";
import {DefnFieldLogCounter} from "../../api/meta/base/dto/DefnFieldLogCounter";
import {DefnFieldLogDecimal} from "../../api/meta/base/dto/DefnFieldLogDecimal";
import {DefnFieldLogNumber} from "../../api/meta/base/dto/DefnFieldLogNumber";
import {DefnFieldMobileNumber} from "../../api/meta/base/dto/DefnFieldMobileNumber";
import {DefnFieldMonth} from "../../api/meta/base/dto/DefnFieldMonth";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldParagraph} from "../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickRole} from "../../api/meta/base/dto/DefnFieldPickRole";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldPickTree} from "../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldPickUser} from "../../api/meta/base/dto/DefnFieldPickUser";
import {DefnFieldPinShape} from "../../api/meta/base/dto/DefnFieldPinShape";
import {DefnFieldPropertyMap} from "../../api/meta/base/dto/DefnFieldPropertyMap";
import {DefnFieldQuarter} from "../../api/meta/base/dto/DefnFieldQuarter";
import {DefnFieldSetOfRole} from "../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSetOfText} from "../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSetOfUser} from "../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnFieldShowCode} from "../../api/meta/base/dto/DefnFieldShowCode";
import {DefnFieldSlider} from "../../api/meta/base/dto/DefnFieldSlider";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldSymbol} from "../../api/meta/base/dto/DefnFieldSymbol";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnFieldTextSize} from "../../api/meta/base/dto/DefnFieldTextSize";
import {DefnFieldTime} from "../../api/meta/base/dto/DefnFieldTime";
import {DefnFieldTimeZone} from "../../api/meta/base/dto/DefnFieldTimeZone";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../api/meta/base/dto/DefnGrid";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {DefnWizard} from "../../api/meta/base/dto/DefnWizard";
import {FieldChipSetDateTime} from "../../api/meta/base/dto/FieldChipSetDateTime";
import {FieldChipSetDay} from "../../api/meta/base/dto/FieldChipSetDay";
import {FieldChipSetDeviceSize} from "../../api/meta/base/dto/FieldChipSetDeviceSize";
import {FieldChipSetDeviceType} from "../../api/meta/base/dto/FieldChipSetDeviceType";
import {FieldChipSetTime} from "../../api/meta/base/dto/FieldChipSetTime";
import {FieldDtoDuration} from "../../api/meta/base/dto/FieldDtoDuration";
import {FieldDtoGridRow} from "../../api/meta/base/dto/FieldDtoGridRow";
import {FieldDtoLocation} from "../../api/meta/base/dto/FieldDtoLocation";
import {FieldSetOfEntUserId} from "../../api/meta/base/dto/FieldSetOfEntUserId";
import {FieldSetOfLanguageKeys} from "../../api/meta/base/dto/FieldSetOfLanguageKeys";
import {FieldSetOfOptionId} from "../../api/meta/base/dto/FieldSetOfOptionId";
import {FieldSetOfRole} from "../../api/meta/base/dto/FieldSetOfRole";
import {FieldSetOfString} from "../../api/meta/base/dto/FieldSetOfString";
import {FieldValueAudio} from "../../api/meta/base/dto/FieldValueAudio";
import {FieldValueCamera} from "../../api/meta/base/dto/FieldValueCamera";
import {FieldValueColor} from "../../api/meta/base/dto/FieldValueColor";
import {FieldValueDate} from "../../api/meta/base/dto/FieldValueDate";
import {FieldValueDateRange} from "../../api/meta/base/dto/FieldValueDateRange";
import {FieldValueDecimal} from "../../api/meta/base/dto/FieldValueDecimal";
import {FieldValueDecimalRange} from "../../api/meta/base/dto/FieldValueDecimalRange";
import {FieldValueDocument} from "../../api/meta/base/dto/FieldValueDocument";
import {FieldValueDuration} from "../../api/meta/base/dto/FieldValueDuration";
import {FieldValueDynamic} from "../../api/meta/base/dto/FieldValueDynamic";
import {FieldValueEmail} from "../../api/meta/base/dto/FieldValueEmail";
import {FieldValueEntUserId} from "../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueError} from "../../api/meta/base/dto/FieldValueError";
import {FieldValueGeoPoint} from "../../api/meta/base/dto/FieldValueGeoPoint";
import {FieldValueGrid} from "../../api/meta/base/dto/FieldValueGrid";
import {FieldValueHandle} from "../../api/meta/base/dto/FieldValueHandle";
import {FieldValueImage} from "../../api/meta/base/dto/FieldValueImage";
import {FieldValueLocation} from "../../api/meta/base/dto/FieldValueLocation";
import {FieldValueMobile} from "../../api/meta/base/dto/FieldValueMobile";
import {FieldValueNumber} from "../../api/meta/base/dto/FieldValueNumber";
import {FieldValueOptionId} from "../../api/meta/base/dto/FieldValueOptionId";
import {FieldValueParagraph} from "../../api/meta/base/dto/FieldValueParagraph";
import {FieldValuePropertyMap} from "../../api/meta/base/dto/FieldValuePropertyMap";
import {FieldValueRole} from "../../api/meta/base/dto/FieldValueRole";
import {FieldValueRowId} from "../../api/meta/base/dto/FieldValueRowId";
import {FieldValueScanCode} from "../../api/meta/base/dto/FieldValueScanCode";
import {FieldValueSignature} from "../../api/meta/base/dto/FieldValueSignature";
import {FieldValueSwitch} from "../../api/meta/base/dto/FieldValueSwitch";
import {FieldValueText} from "../../api/meta/base/dto/FieldValueText";
import {StudioBuildArgBinder} from "../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioDtoArgValueConstant} from "../../api/meta/base/dto/StudioDtoArgValueConstant";
import {StudioModuleSelection} from "../../api/meta/base/dto/StudioModuleSelection";
import {StudioSetOfDate} from "../../api/meta/base/dto/StudioSetOfDate";
import {StudioValueVarIdParagraph} from "../../api/meta/base/dto/StudioValueVarIdParagraph";
import {StudioValueVarIdText} from "../../api/meta/base/dto/StudioValueVarIdText";
import {AnyTime} from "../../api/meta/base/Types";
import {MetaIdRole} from "../../api/meta/base/Types";
import {EnumArrayDefnMonth} from "../../api/meta/base/Types";
import {EnumDefnMonth} from "../../api/meta/base/Types";
import {MetaIdModule} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {RowId} from "../../api/meta/base/Types";
import {EnumDefnDurationUnit} from "../../api/meta/base/Types";
import {MetaIdOption} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {MetaIdComp} from "../../api/meta/base/Types";
import {EntUserId} from "../../api/meta/base/Types";
import {LanguageKey} from "../../api/meta/base/Types";
import languages from "../../nucleus/atom/assets/Languages.json";
import TimezoneArray from "../../nucleus/atom/assets/TimeZone.json";
import {DefnFormUi} from "../types/TypesForm";
import {getConstValueKeyArgBinderFromDto} from "./ArgBinderPlus";
import {calcIsParamResolved} from "./ArgBinderPlus";
import {extractDerivedValues} from "./ArgBinderPlus";
import {getFieldStudioBuildArgBinderKey} from "./ArgBinderPlus";
import {resolveDateValue} from "./ArgBinderPlus";
import {defnDtoTextToString} from "./ArgBinderPlus";
import {dateFormatBackWordCompatibility} from "./DatePlus";
import {formatDateToISO} from "./DatePlus";
import {resolveTimeAsString} from "./DatePlus";
import {formatDateToUTC} from "./DatePlus";
import {formatDate} from "./DatePlus";
import {getGeoPointAsString} from "./FormPlus";
import {getTimeAsString} from "./FormPlus";
import {formatDecimalDigitsAfterPeriod} from "./FormPlus";
import {isDynamicValueFieldId} from "./FormPlus";
import {getDynamicValueFieldId} from "./FormPlus";
import {getLabelFromOptionTree} from "./FormPlus";
import {loopDefnForm} from "./FormPlus";
import {getLabelsFromOptions} from "./FormPlus";
import {getLabelFromOption} from "./FormPlus";
import {getLocationAsString} from "./FormPlus";
import {fnResolveFieldPropShowSeconds} from "./FormYupPlus";
import {mapToOptions} from "./JsPlus";
import {maskSecureText} from "./StringPlus";
import {toLabel} from "./StringPlus";
import {toSymbolCase} from "./StringPlus";
import {getEmptyKeysAndMap} from "./StudioPlus";
import {validYouTubeUrl} from "./UrlPlus";

type TypeDefnFormRawFieldValue = string | string[] | number | boolean | object | undefined | null
type TypeDefnFormFieldValue = string | string[] | object | undefined | null | number | boolean
type TypeFieldValueDefaultType = "null" | "undefined"

const invalidLanguageData = "Invalid languages data";
const invalidTimeZoneData = "Invalid timeZone data";

export function getPrefixSuffixText(comp?: DefnFieldEditable, value?: string): string | undefined
{
  if(!comp || !value)
  {
    return undefined;
  }

  let result = "";

  result += getPrefixAndSuffixTextFromComp(comp).prefix;
  result += value;
  result += getPrefixAndSuffixTextFromComp(comp).suffix;

  return !isEmpty(result) ? result : undefined;
}

export function getPrefixAndSuffixTextFromComp(comp: DefnFieldEditable)
{
  let prefix = "";
  let suffix = "";

  if(comp.prefix)
  {
    prefix += comp.prefix;
  }
  else if(comp.prefixVar)
  {
    prefix += defnDtoTextToString(comp.prefixVar);
  }

  if(comp.suffix)
  {
    suffix += comp.suffix;
  }
  else if(comp.suffixVar)
  {
    suffix += defnDtoTextToString(comp.suffixVar);
  }

  return {
    prefix: prefix,
    suffix: suffix
  };
}

// region fieldValue to rawValue
function fnFieldValueToRawValueBool(value: FieldValueSwitch, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueDate(value: FieldValueDate, defaultVal: undefined | null)
{
  if(value.value === undefined || isEmpty(value.value))
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueDecimal(value: FieldValueDecimal, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueColor(value: FieldValueColor, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueNumber(value: FieldValueNumber, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueNumberLogged(value: FieldValueNumber, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueDecimalLogged(value: FieldValueDecimal, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueParagraph(value: FieldValueParagraph, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueText(value: FieldValueText, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueSymbol(value: string, defaultVal: undefined | null)
{
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function fnFieldValueToRawValuePickText(value: FieldValueOptionId, defaultVal: undefined | null)
{
  if(!value.optionId)
  {
    return defaultVal;
  }

  return value.optionId;
}

function fnFieldValueToRawValuePickTree(value: FieldValueOptionId, defaultVal: undefined | null)
{
  if(!value.optionId)
  {
    return defaultVal;
  }

  return value.optionId;
}

function fnFieldValueToRawValueStudioSetOfModule(value: MetaIdModule[], defaultVal: undefined | null)
{
  if(value.length === 0)
  {
    return defaultVal;
  }

  return {
    moduleIdSet: value
  } as StudioModuleSelection;
}

function fnFieldValueToRawValueSetOfLanguageKeys(value: FieldSetOfLanguageKeys, defaultVal: undefined | null)
{
  if(value.valueSet.length === 0)
  {
    return defaultVal;
  }

  return value.valueSet;
}

function fnFieldValueToRawValueSetOfOptionId(value: FieldSetOfOptionId, defaultVal: undefined | null)
{
  if(value.valueSet.length === 0)
  {
    return defaultVal;
  }

  return value.valueSet;
}

function fnFieldValueToRawValueDateRange(value: FieldValueDateRange, defaultVal: undefined | null)
{
  if(value.from === undefined
    || value.to === undefined
    || isEmpty(value.from)
    || isEmpty(value.to)
  )
  {
    return defaultVal;
  }

  return value.from + " - " + value.to;
}

function fnFieldValueToRawValueDuration(value: FieldValueDuration, defaultVal: undefined | null)
{
  if(value.value === undefined
    || isEmpty(value.value)
    || value.value.value === undefined
    || value.value.unit === undefined)
  {
    return defaultVal;
  }

  return value.value.value + " " + value.value.unit;
}

function fnFieldValueToRawValueMobileNumber(value: FieldValueMobile, defaultVal: undefined | null)
{
  if(value.value === undefined || isEmpty(value.value))
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueStudioVarIdParagraphEditor(value: unknown, defaultVal: undefined | null)
{
  function isFieldValueVarIdText(value?: unknown): value is StudioValueVarIdParagraph
  {
    return !!(value as StudioValueVarIdParagraph)?.argVarId;
  }

  if(isFieldValueVarIdText(value))
  {
    return value as StudioValueVarIdParagraph;
  }
  else if(typeof value === "string" && isVariableId(value))
  {
    return {
      argVarId: value
    };
  }
  else
  {
    return defaultVal;
  }
}

function fnFieldValueToRawValueStudioVarIdTextEditor(value: unknown, defaultVal: undefined | null)
{
  function isFieldValueVarIdText(value?: unknown): value is StudioValueVarIdText
  {
    return !!(value as StudioValueVarIdText)?.argVarId;
  }

  if(isFieldValueVarIdText(value))
  {
    return value as StudioValueVarIdText;
  }
  else if(typeof value === "string" && isVariableId(value))
  {
    return {
      argVarId: value
    };
  }
  else
  {
    return defaultVal;
  }
}

function fnFieldValueToRawValueImage(value: FieldValueImage, defaultVal: undefined | null)
{
  if(value.value === undefined)
  {
    return defaultVal;
  }

  return value.value.mediaIdImage;
}

function fnFieldValueToRawValueChipSet(value: FieldSetOfString, defaultVal: undefined | null)
{
  if(!value.valueSet || value.valueSet.length === 0)
  {
    return defaultVal;
  }

  return value.valueSet;
}

function fnFieldValueToRawValueChipSetDate(value: StudioSetOfDate, defaultVal: undefined | null)
{
  if(!value.valueSet || value.valueSet.length === 0)
  {
    return defaultVal;
  }

  return value.valueSet;
}

function fnFieldValueToRawValuePickUser(value: FieldValueEntUserId, defaultVal: undefined | null)
{
  if(!value.value)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValuePickRole(value: FieldValueRole, defaultVal: undefined | null)
{
  if(!value.value)
  {
    return defaultVal;
  }

  return value.value;
}

function fnFieldValueToRawValueSetOfRole(value: FieldSetOfRole, defaultVal: undefined | null)
{
  if(!value.valueSet || value.valueSet.length === 0)
  {
    return defaultVal;
  }

  return value.valueSet;
}

function fnFieldValueToRawValueSlider(value: FieldValueDecimalRange, defaultVal: undefined | null)
{
  if(value.minValue === undefined && value.maxValue === undefined)
  {
    return defaultVal;
  }

  if(value.maxValue !== undefined)
  {
    return value.maxValue;
  }

  return defaultVal;
}

function fnFieldValueToRawValueEmail(value: FieldValueEmail, defaultVal: undefined | null)
{
  if(!value.value)
  {
    return defaultVal;
  }
  return value.value;
}

function fnFieldValueToRawValueHandle(value: FieldValueHandle, defaultVal: undefined | null)
{
  if(!value.value)
  {
    return defaultVal;
  }
  return value.value;
}

function fnFieldValueToRawValueLocation(value: FieldValueLocation, defaultVal: undefined | null)
{
  if(!value.value)
  {
    return defaultVal;
  }
  return value.value;
}

function fnFieldValueToRawValueGeoPoint(value: FieldValueGeoPoint, defaultVal: undefined | null)
{
  if(!value.value)
  {
    return defaultVal;
  }
  return value.value;
}

function fnFieldValueToRawValueIcon(value: FieldValueText, defaultVal: undefined | null)
{
  if(!value.value)
  {
    return defaultVal;
  }
  return value.value;
}

function fnFieldValueToRawValueScanCode(value: FieldValueScanCode)
{
  return value.scanCode;
}

function fnFieldValueToRawValueError(value: FieldValueError)
{
  return value.errorReason;
}

// endregion

// region rawValue to fieldValue

function fnRawValueToFieldValueBool(value: boolean)
{
  return {
    value: value
  } as FieldValueSwitch;
}

function fnRawValueToFieldValueDate(value: string)
{
  return {
    value: value
  } as FieldValueDate;
}

function fnRawValueToFieldValueDecimal(value: number)
{
  return {
    value: value
  } as FieldValueDecimal;
}

function fnRawValueToFieldValueColor(value: string)
{
  return {
    value: value
  } as FieldValueColor;
}

function fnRawValueToFieldValueNumber(value: number)
{
  return {
    value: value
  } as FieldValueNumber;
}

function fnRawValueToFieldValueNumberLogged(value: number)
{
  return {
    value: value
  } as FieldValueNumber;
}

function fnRawValueToFieldValueDecimalLogged(value: number)
{
  return {
    value: value
  } as FieldValueDecimal;
}

function fnRawValueToFieldValueParagraph(value: string)
{
  return {
    value: value
  } as FieldValueParagraph;
}

function fnRawValueToFieldValueText(value: string)
{
  return {
    value: value
  } as FieldValueText;
}

function fnRawValueToFieldValuePickText(value: string)
{
  return {
    optionId: value
  } as FieldValueOptionId;
}

function fnRawValueToFieldValuePickTree(value: string)
{
  return {
    optionId: value
  } as FieldValueOptionId;
}

function fnRawValueToFieldValueStudioSetOfModule(value: StudioModuleSelection)
{
  if(value.moduleIdSet.length === 0)
  {
    return undefined;
  }

  return value.moduleIdSet;
}

function fnRawValueToFieldValueSetOfLanguageKeys(value: LanguageKey[])
{
  return {
    valueSet: value
  } as FieldSetOfLanguageKeys;
}

function fnRawValueToFieldValueSetOfOptionId(value: MetaIdOption[])
{
  return {
    valueSet: value
  } as FieldSetOfOptionId;
}

function fnRawValueToFieldValueDateRange(value: string)
{
  const val = value.split(" - ");
  return {
    from: val[0],
    to: val[1]
  } as FieldValueDateRange;
}

function fnRawValueToFieldValueDuration(value: string)
{
  const val = value.split(" ");
  return {
    value: {
      value: Number(val[0]),
      unit: val[1]
    }
  } as FieldValueDuration;
}

function fnRawValueToFieldValueMobileNumber(value: string)
{
  return {
    value: value
  } as FieldValueMobile;
}

function fnRawValueToFieldValueImage(value: string | FieldValueImage)
{
  if(typeof value === "string")
  {
    return {
      value: {
        mediaIdImage: value
      }
    } as FieldValueImage;
  }
  return value;
}

function fnRawValueToFieldValueChipSet(value: string[])
{
  return {
    valueSet: value
  } as FieldSetOfString;
}

function fnRawValueToFieldValueChipSetDate(value: string[])
{
  return {
    valueSet: value
  } as StudioSetOfDate;
}

function fnRawValueToFieldValuePropertyMap(value: Record<string, string>)
{
  return {
    keys: Object.keys(value),
    values: Object.values(value)
  } as FieldValuePropertyMap;
}

function fnRawValueToFieldValuePickUser(value: EntUserId)
{
  return {
    value: value
  } as FieldValueEntUserId;
}

function fnRawValueToFieldValuePickRole(value: MetaIdRole)
{
  return {
    value: value
  } as FieldValueRole;
}

function fnRawValueToFieldValueSetOfRole(value: MetaIdRole[])
{
  return {
    valueSet: value
  } as FieldSetOfRole;
}

function fnRawValueToFieldValueSlider(value: number)
{
  return {
    minValue: 0,
    maxValue: value
  } as FieldValueDecimalRange;
}

function fnRawValueToFieldValueEmail(value: string)
{
  return {
    value: value
  } as FieldValueEmail;
}

function fnRawValueToFieldValueHandle(value: string)
{
  return {
    value: value
  } as FieldValueHandle;
}

function fnRawValueToFieldValueLocation(value: FieldDtoLocation)
{
  return {
    value: value
  } as FieldValueLocation;
}

function fnRawValueToFieldValueGeoPoint(value: string)
{
  return {
    value: value
  } as FieldValueGeoPoint;
}

function fnRawValueToFieldValueIcon(value: string)
{
  return {
    value: value
  } as FieldValueText;
}

// endregion

// region argBinder

export function fnFieldValueToRawValueArgBinder(value?: StudioBuildArgBinder): StudioBuildArgBinder | undefined
{
  if(value && calcIsParamResolved(value))
  {
    return value;
  }
}

export function fnRawValueToFieldValueArgBinder(fieldId: MetaIdField, dto?: StudioBuildArgBinder)
{
  const fieldValueId = getFieldStudioBuildArgBinderKey(fieldId);
  const constValueKey = getConstValueKeyArgBinderFromDto(fieldValueId, dto);
  const constValue = dto?.kind === "constant" ? (dto?.value as StudioDtoArgValueConstant) : undefined;

  return {
    [fieldId]: dto ?? null,
    [fieldValueId]: extractDerivedValues(dto) ?? null,
    ...constValueKey && {
      [constValueKey]: constValue?.value || null
    }
  };
}

// endregion

export function fnFieldValueToRawValue(
  compType: EnumDefnCompType,
  value: TypeDefnFormFieldValue,
  defaultValue: TypeFieldValueDefaultType = "undefined"): TypeDefnFormRawFieldValue
{
  const defaultVal = defaultValue === "null" ? null : undefined;

  if(value === undefined
    || value === null
    || (typeof value === "object" && isEmpty(value)))
  {
    return defaultVal;
  }

  switch(compType)
  {
    case "bool":
      return fnFieldValueToRawValueBool(value as FieldValueSwitch, defaultVal);
    case "date":
    case "dateTime":
      return fnFieldValueToRawValueDate(value as FieldValueDate, defaultVal);
    case "decimal":
    case "rating":
      return fnFieldValueToRawValueDecimal(value as FieldValueDecimal, defaultVal);
    case "logDecimal":
      return fnFieldValueToRawValueDecimalLogged(value as FieldValueDecimal, defaultVal);
    case "image":
      return fnFieldValueToRawValueImage(value as FieldValueImage, defaultVal);
    case "counter":
    case "number":
      return fnFieldValueToRawValueNumber(value as FieldValueNumber, defaultVal);
    case "logCounter":
    case "logNumber":
      return fnFieldValueToRawValueNumberLogged(value as FieldValueNumber, defaultVal);
    case "paragraph":
      return fnFieldValueToRawValueParagraph(value as FieldValueParagraph, defaultVal);
    case "text":
    case "identifier":
    case "hyperlink":
    case "password":
    case "showCode":
      return fnFieldValueToRawValueText(value as FieldValueText, defaultVal);
    case "symbol":
      return fnFieldValueToRawValueSymbol(value as string, defaultVal);
    case "pickText":
      return fnFieldValueToRawValuePickText(value as FieldValueOptionId, defaultVal);
    case "pickTree":
      return fnFieldValueToRawValuePickTree(value as FieldValueOptionId, defaultVal);
    case "setOfLanguageKeys":
      return fnFieldValueToRawValueSetOfLanguageKeys(value as FieldSetOfLanguageKeys, defaultVal);
    case "setOfText":
      return fnFieldValueToRawValueSetOfOptionId(value as FieldSetOfOptionId, defaultVal);
    case "color":
      return fnFieldValueToRawValueColor(value as FieldValueColor, defaultVal);
    case "dateRange":
    case "dateTimeRange":
      return fnFieldValueToRawValueDateRange(value as FieldValueDateRange, defaultVal);
    case "duration":
      return fnFieldValueToRawValueDuration(value as FieldValueDuration, defaultVal);
    case "mobileNumber":
      return fnFieldValueToRawValueMobileNumber(value as FieldValueMobile, defaultVal);
    case "studioVarIdTextEditor":
      return fnFieldValueToRawValueStudioVarIdTextEditor(value, defaultVal);
    case "studioVarIdParagraphEditor":
      return fnFieldValueToRawValueStudioVarIdParagraphEditor(value, defaultVal);
    case "studioSetOfModule":
      return fnFieldValueToRawValueStudioSetOfModule(value as string[], defaultVal);
    case "chipSetDay":
    case "chipSetTime":
    case "chipSetDeviceSize":
    case "chipSetDeviceType":
    case "chipSet":
    case "chipSetDateTime":
      return fnFieldValueToRawValueChipSet(value as FieldSetOfString, defaultVal);
    case "chipSetDate":
      return fnFieldValueToRawValueChipSetDate(value as StudioSetOfDate, defaultVal);
    case "pickUser":
    case "userId":
      return fnFieldValueToRawValuePickUser(value as FieldValueEntUserId, defaultVal);
    case "pickRole":
      return fnFieldValueToRawValuePickRole(value as FieldValueRole, defaultVal);
    case "setOfRole":
      return fnFieldValueToRawValueSetOfRole(value as FieldSetOfRole, defaultVal);
    case "slider":
      return fnFieldValueToRawValueSlider(value as FieldValueDecimalRange, defaultVal);
    case "handle":
      return fnFieldValueToRawValueHandle(value as FieldValueHandle, defaultVal);
    case "email":
      return fnFieldValueToRawValueEmail(value as FieldValueEmail, defaultVal);
    case "location":
      return fnFieldValueToRawValueLocation(value as FieldValueLocation, defaultVal);
    case "geoPoint":
      return fnFieldValueToRawValueGeoPoint(value as FieldValueGeoPoint, defaultVal);
    case "icon":
      return fnFieldValueToRawValueIcon(value as FieldValueText, defaultVal);
    case "scanCode":
      return fnFieldValueToRawValueScanCode(value as FieldValueScanCode);
    case "error":
      return fnFieldValueToRawValueError(value as FieldValueError);

    default:
      return value;
  }
}

export function fnRawValueToFieldValue(
  compType: EnumDefnCompType,
  value: TypeDefnFormRawFieldValue,
  defaultValue: TypeFieldValueDefaultType = "null"): TypeDefnFormFieldValue
{
  const _defaultValue = defaultValue === "null" ? null : undefined;

  if(value === undefined
    || value === null
    || (typeof value === "object" && isEmpty(value)))
  {
    return _defaultValue;
  }

  switch(compType)
  {
    case "bool":
      return fnRawValueToFieldValueBool(value as boolean);
    case "date":
    case "dateTime":
      return fnRawValueToFieldValueDate(value as string);
    case "decimal":
    case "rating":
      return fnRawValueToFieldValueDecimal(value as number);
    case "logDecimal":
      return fnRawValueToFieldValueDecimalLogged(value as number);
    case "image":
      return fnRawValueToFieldValueImage(value as FieldValueImage | string);
    case "counter":
    case "number":
      return fnRawValueToFieldValueNumber(value as number);
    case "logCounter":
    case "logNumber":
      return fnRawValueToFieldValueNumberLogged(value as number);
    case "paragraph":
      return fnRawValueToFieldValueParagraph(value as string);
    case "text":
    case "identifier":
    case "hyperlink" :
    case "showCode" :
      return fnRawValueToFieldValueText(value as string);
    case "pickText":
      return fnRawValueToFieldValuePickText(value as string);
    case "pickTree":
      return fnRawValueToFieldValuePickTree(value as string);
    case "setOfLanguageKeys":
      return fnRawValueToFieldValueSetOfLanguageKeys(value as LanguageKey[]);
    case "setOfText":
      return fnRawValueToFieldValueSetOfOptionId(value as string[]);
    case "color":
      return fnRawValueToFieldValueColor(value as string);
    case "dateRange":
    case "dateTimeRange":
      return fnRawValueToFieldValueDateRange(value as string);
    case "duration":
      return fnRawValueToFieldValueDuration(value as string);
    case "mobileNumber":
      return fnRawValueToFieldValueMobileNumber(value as string);
    case "studioSetOfModule":
      return fnRawValueToFieldValueStudioSetOfModule(value as StudioModuleSelection);
    case "chipSet":
    case "chipSetDay":
    case "chipSetTime":
    case "chipSetDeviceSize":
    case "chipSetDeviceType":
    case "chipSetDateTime":
      return fnRawValueToFieldValueChipSet(value as string[]);
    case "chipSetDate":
      return fnRawValueToFieldValueChipSetDate(value as string[]);
    case "propertyMap":
      return fnRawValueToFieldValuePropertyMap(value as Record<string, string>);
    case "pickUser":
    case "userId":
      return fnRawValueToFieldValuePickUser(value as EntUserId);
    case "pickRole":
      return fnRawValueToFieldValuePickRole(value as MetaIdRole);
    case "setOfRole":
      return fnRawValueToFieldValueSetOfRole(value as MetaIdRole[]);
    case "slider":
      return fnRawValueToFieldValueSlider(value as number);
    case "handle":
      return fnRawValueToFieldValueHandle(value as string);
    case "email":
      return fnRawValueToFieldValueEmail(value as string);
    case "location":
      return fnRawValueToFieldValueLocation(value as FieldDtoLocation);
    case "geoPoint":
      return fnRawValueToFieldValueGeoPoint(value as string);
    case "icon":
      return fnRawValueToFieldValueIcon(value as string);
    case "dateFormat":
      return dateFormatBackWordCompatibility(value as string);

    default:
      return value as TypeDefnFormFieldValue;
  }

}

export function getFormFieldValueAsText(comp?: DefnComp, value?: any): string | undefined
{
  if(comp === undefined || value === undefined || value === null || isEmpty(value))
  {
    return undefined;
  }

  switch(comp.type)
  {
    case "hyperlink":
      if((comp as DefnFieldHyperlink).defaultVar?.kind === "youtube")
      {
        if(validYouTubeUrl(value.value))
        {
          return undefined;
        }
      }
      return fnFieldValueToRawValue("text", value) as string;

    case "hyperlinkRow":
      return (comp as DefnFieldHyperlinkRow).displayTextVar?.value?.join();

    case "label":
    case "password":
    case "identifier":
    case "icon":
    case "email":
    case "handle":
    case "info":
    case "pickReportRow":
    case "showCode":
    case "text":
      const defnText = comp as DefnFieldText;
      const formattedValue = defnText?.secureTextEntry
        ? fnFieldValueToRawValue("text", {
          value: maskSecureText((value as FieldValueText)?.value)
        } as FieldValueText)
        : fnFieldValueToRawValue("text", value);

      return formattedValue as string;

    case "dynamic":
      return getFormFieldValueAsTextWithPrefixSuffix({
        ...comp,
        type: (value as FieldValueDynamic)?.fieldType ?? "text"
      }, value);

    case "error":
      return fnFieldValueToRawValue("error", value) as string;

    case "bool":
      const valueBool = fnFieldValueToRawValue("bool", value);
      return value
        ? valueBool ? "Yes" : "No"
        : undefined;

    case "signature":
      const valueSignature = value as FieldValueSignature;
      return valueSignature?.handle;

    case "slider":
      const valueSlider = value as FieldValueDecimalRange;

      if((comp as DefnFieldSlider).allowRangePicker)
      {
        if(valueSlider.minValue && valueSlider.maxValue)
        {
          return valueSlider.minValue + " - " + valueSlider.maxValue;
        }
        else if(valueSlider.minValue && !valueSlider.maxValue)
        {
          return valueSlider.minValue.toString();
        }
        else if(!valueSlider.minValue && valueSlider.maxValue)
        {
          return valueSlider.maxValue.toString();
        }
        else
        {
          return undefined;
        }
      }
      else
      {
        return valueSlider.maxValue ? valueSlider.maxValue.toString() : undefined;
      }

    case "rating":
    case "number":
    case "counter":
    case "logCounter":
    case "logNumber":
      const valueNumber = fnFieldValueToRawValue("number", value) as number;
      return valueNumber !== undefined ? valueNumber.toString() : undefined;

    case "decimal":
    case "logDecimal":
      const defnDecimal = comp as DefnFieldDecimal;
      const valueDecimal = fnFieldValueToRawValue("number", value) as number;
      const numberOfDigitsAfterPeriod = defnDecimal.numberOfDigitsAfterPeriod
        ?? defnDecimal.numberOfDigitsAfterPeriodVar;

      return valueDecimal !== undefined
        ? numberOfDigitsAfterPeriod !== undefined
          ? valueDecimal.toFixed(numberOfDigitsAfterPeriod)
          : valueDecimal.toString()
        : undefined;

    case "date":
    case "dateTime":
      const valueDate = fnFieldValueToRawValue("date", value) as string;
      const defnDate = comp as DefnFieldDate | DefnFieldDateTime;

      return valueDate
        ? formatDate(
          valueDate,
          defnDate.displayDateFormat,
          defnDate.timeZone,
          defnDate.type === "dateTime"
        )
        : undefined;

    case "dateRange":
    case "dateTimeRange":
      const valueDateRange = value as FieldValueDateRange;
      const defnDateRange = comp as DefnFieldDateRange | DefnFieldDateTimeRange;

      const dateFrom = valueDateRange?.from;
      const dateTo = valueDateRange?.to;

      const formattedDateFrom = dateFrom
        ? formatDate(
          dateFrom,
          defnDateRange.displayDateFormat,
          defnDateRange.timeZone
        )
        : undefined;
      const formattedDateTo = dateTo
        ? formatDate(
          dateTo,
          defnDateRange.displayDateFormat,
          defnDateRange.timeZone
        )
        : undefined;

      return (dateFrom && dateTo)
        ? formattedDateFrom + " - " + formattedDateTo
        : dateFrom ?
          formattedDateFrom
          : dateTo
            ? formattedDateTo
            : undefined;

    case "chipSetDate":
    case "chipSetDateTime":
      const defnChipSetDate = comp as DefnFieldChipSetDate | DefnFieldChipSetDateTime;

      const dateValue = (value as StudioSetOfDate | FieldChipSetDateTime)?.valueSet.map((date) =>
        {
          return formatDate(
            date,
            defnChipSetDate.displayDateFormat,
            defnChipSetDate.timeZone,
            defnChipSetDate.type === "chipSetDateTime"
          );
        }
      );

      return dateValue && dateValue.length > 0
        ? dateValue.join(", ")
        : undefined;

    case "location":
      return getLocationAsString(value);

    case "geoPoint":
      return getGeoPointAsString(value);

    case "camera":
      const valueCamera = (value as FieldValueCamera)?.value;
      return valueCamera
        ? valueCamera.fileName
        : undefined;

    case "image":
      const valueImage = (value as FieldValueImage)?.value;
      return valueImage
        ? valueImage.fileName
        : undefined;

    case "pickText":
      const options = mapToOptions((comp as DefnFieldPickText).optionMap) || [];
      return getLabelFromOption(options, value);

    case "time":
      const defnTime = comp as DefnFieldTime;
      const displayDateFormat = defnTime.displayDateFormat ?? "hh:mm";
      const showSeconds = fnResolveFieldPropShowSeconds(defnTime);
      const valueTime = fnFieldValueToRawValue("time", value) as AnyTime;

      return valueTime
        ? getTimeAsString(
          valueTime,
          displayDateFormat,
          showSeconds
        )
        : undefined;

    case "mobileNumber":
      return fnFieldValueToRawValue("mobileNumber", value) as string;

    case "paragraph":
      return fnFieldValueToRawValue("paragraph", value) as string;

    case "audio":
      return (value as FieldValueAudio)?.fileName;

    case "color":
      return (value as FieldValueColor)?.value;

    case "document":
      return (value as FieldValueDocument)?.value?.fileName;

    case "duration":
      const durationValue = (value as FieldValueDuration)?.value as FieldDtoDuration;
      const defnDuration = comp as DefnFieldDuration;

      if(defnDuration?.autoFormatValue)
      {
        return (durationValue && !isEmpty(resolveDurationValue(durationValue)))
          ? resolveDurationValue(durationValue)
          : undefined;
      }
      else
      {
        return (durationValue?.value !== undefined && durationValue?.value !== null)
          ? durationValue.value + " " + durationValue.unit
          : undefined;
      }

    case "setOfText":
      const setOfTextOptions = mapToOptions((comp as DefnFieldSetOfText).optionMap) || [];
      const setOfTextValue = value as FieldSetOfOptionId;

      return setOfTextValue?.valueSet && setOfTextValue.valueSet.length
        ? getLabelsFromOptions(setOfTextOptions, setOfTextValue).join(", ")
        : undefined;

    case "propertyMap":
      const propertyMap = value as FieldValuePropertyMap;
      const result = [] as string[];

      if(propertyMap?.keys && propertyMap.keys.length > 0)
      {
        propertyMap.keys.forEach((key, index) =>
        {
          const value = propertyMap.values ? propertyMap.values[index] : "";
          result.push(key + ": " + value);
        });
      }

      return result.join(", ");

    case "chipSetDay":
    case "chipSetDeviceSize":
    case "chipSetDeviceType":
      const chipSetValue = value as
        | FieldChipSetDay
        | FieldChipSetDeviceSize
        | FieldChipSetDeviceType;

      return (chipSetValue?.valueSet)?.map((day) =>
      {
        return toLabel(day);
      })?.join(", ");

    case "chipSet":
      return ((value as FieldSetOfString)?.valueSet)?.join(", ");

    case "setOfLanguageKeys":
      return (fnFieldValueToRawValue("setOfLanguageKeys", value) as string[])?.join(", ");

    case "chipSetTime":
      return ((value as FieldChipSetTime)?.valueSet)?.map((time) =>
      {
        const displayDateFormat = (comp as DefnFieldChipSetTime).displayDateFormat ?? "hh:mm";

        return getTimeAsString(
          time,
          displayDateFormat
        );
      })?.join(", ");

    case "setOfUser":
      return (value as FieldSetOfEntUserId)?.displaySet?.join(", ");

    case "language":
      if(!languages || typeof languages !== "object")
      {
        return invalidLanguageData;
      }

      let languageValue: string | undefined;

      Object.entries(languages).forEach(([languageKey, language]) =>
      {
        if(languageKey === value)
        {
          languageValue = `${language.name}, ${language.nativeName}`;
          return;
        }
      });

      return languageValue;

    case "timeZone":
      if(!TimezoneArray || TimezoneArray.length === 0)
      {
        return invalidTimeZoneData;
      }

      let timeZoneValue: string | undefined;

      TimezoneArray.forEach((timeZone) =>
      {
        if(timeZone.utc[0] === value)
        {
          timeZoneValue = `${timeZone.text}`;
          return;
        }
      });

      return timeZoneValue;

    case "currency":
    case "month":
    case "rowId":
    case "symbol":
      return fnFieldValueToRawValue(comp.type, value) as string;

    case "quarter":
      return (value as string)?.replace("_", " ");

    case "scanCode":
      return (value as FieldValueScanCode)?.scanCode;

    case "video":
    case "voice":
    case "button":
    case "divider":
    case "html":
    case "ref":
    case "grid":
      break;

    case "pickTree":
      const defnTree = comp as DefnFieldPickTree;
      const treeValue = value as FieldValueOptionId;

      if(defnTree.pluginApi)
      {
        return treeValue?.value;
      }

      const labelFromOptionTree = getLabelFromOptionTree(
        defnTree.sourceVar,
        treeValue?.optionId
      );

      if(defnTree.forceLeafSelection)
      {
        const splitArr = labelFromOptionTree?.split(" > ");

        return splitArr?.[splitArr?.length - 1];
      }

      return labelFromOptionTree;

    case "pickUser":
    case "userId":
      return (value as FieldValueEntUserId)?.displayField;

    case "pickRole":
      return (value as FieldValueRole)?.displayValue;

    case "setOfRole":
      return ((value as FieldSetOfRole)?.displaySet)?.join(", ");

    case "pickGridRow":
      return (value as FieldValueRowId)?.displayField;

    case "textSize":
    case "paymentStatus":
    case "pinShape":
    case "lineStroke":
      return (value && !isEmpty(value))
        ? toSymbolCase(value)
        : undefined;
  }

  return typeof value !== "object"
    ? value
    : "";
}

export function getFormFieldValueAsTextWithPrefixSuffix(comp?: DefnComp, value?: any): string | undefined
{
  if(comp === undefined || value === undefined || value === null || isEmpty(value))
  {
    return undefined;
  }

  let formFieldValueAsText = getFormFieldValueAsText(comp, value);

  switch(comp.type)
  {
    case "chipSetDate":
    case "chipSetDateTime":
    case "setOfText":
    case "propertyMap":
    case "chipSetDay":
    case "chipSetDeviceSize":
    case "chipSetDeviceType":
    case "chipSet":
    case "setOfLanguageKeys":
    case "chipSetTime":
    case "setOfUser":
    case "setOfRole":
      formFieldValueAsText = formFieldValueAsText?.split(", ").map(val =>
      {
        return getPrefixSuffixText(comp as DefnFieldEditable, val);
      }).join(", ");
      break;

    default:
      formFieldValueAsText = getPrefixSuffixText(comp as DefnFieldEditable, formFieldValueAsText);
  }

  return formFieldValueAsText;
}

function excludeFieldValue(metaId: MetaIdComp, defnForm?: DefnForm)
{
  if(defnForm)
  {
    const comp = defnForm.compMap[metaId];
    const formulaFieldIdSet = defnForm.formulaFieldIdSet;

    if(comp?.hidden)
    {
      if(comp.type !== "rowId")
      {
        if(!(formulaFieldIdSet && formulaFieldIdSet.includes(metaId)))
        {
          return true;
        }
      }
    }
    if(isDynamicValueFieldId(metaId))
    {
      return true;
    }
  }

  return false;
}

export function getValidFormFieldValueMap(
  values: FieldValues,
  callerUserId?: EntUserId,
  callerHandle?: string,
  defnForm?: DefnForm,
  callerGeoLocation?: FieldDtoLocation
): FieldValues
{
  const compMap = defnForm?.compMap;

  if(!compMap)
  {
    return values;
  }

  const formValues = {} as FieldValues;
  const keys = Object.keys(values);

  for(let i = 0; i < keys.length; i++)
  {
    const metaId = keys[i];
    const value = values[metaId];

    if(value !== undefined && value !== null && !isEmpty(value))
    {
      const comp = compMap[metaId];
      const compType = comp?.type;

      if(excludeFieldValue(metaId, defnForm))
      {
        continue;
      }

      switch(compType)
      {
        case "tab":
        case "wizard":
          break;

        case "grid":
        {
          const gridValue = value as FieldValueGrid;
          const keys = [] as RowId[];
          const map = {} as Record<RowId, FieldDtoGridRow>;

          if(gridValue && gridValue.keys?.length)
          {
            gridValue.keys.forEach(rowId =>
            {
              const row = gridValue.map[rowId];
              const rowValueMap = getValidFormFieldValueMap(row?.valueMap || {},
                callerUserId,
                callerHandle,
                defnForm,
                callerGeoLocation
              );

              keys.push(rowId);
              map[rowId] = {
                ...gridValue.map[rowId],
                valueMap: rowValueMap
              };
            });
          }

          formValues[metaId] = {
            keys: keys,
            map: map
          } as FieldValueGrid;
        }
          break;

        case "camera":
          const valueCamera = value as FieldValueCamera;
          if(valueCamera.value && !isEmpty(valueCamera.value))
          {
            formValues[metaId] = valueCamera;
          }
          break;

        case "image":
          const valueImage = value as FieldValueImage;
          if(valueImage?.value && !isEmpty(valueImage.value))
          {
            formValues[metaId] = valueImage;
          }
          break;

        case "logNumber":
        case "logCounter":
        case "logDecimal":
          formValues[metaId] = value as FieldValueNumber | FieldValueDecimal;
          break;

        case "text":
        case "color":
        case "date":
        case "email":
        case "handle":
        case "mobileNumber":
        case "paragraph":
        case "hyperlink":
        case "password":
        case "html":
        case "otp":
        case "identifier":
          const _value = value as
            | FieldValueText
            | FieldValueColor
            | FieldValueDate
            | FieldValueEmail
            | FieldValueHandle
            | FieldValueMobile
            | FieldValueParagraph;

          if(_value.value)
          {
            formValues[metaId] = _value;
          }
          break;

        default:
          formValues[metaId] = value;
          break;
      }
    }
  }
  return formValues;
}

export function getBuildDateDefaultValue(
  defnBuildDate?: DefnBuildDate | DefnBuildDateTime,
  excludeTime?: boolean,
  time?: string): string | undefined
{
  if(!defnBuildDate)
  {
    return;
  }

  if(defnBuildDate.customValue)
  {
    return defnBuildDate.customValue;
  }

  if(defnBuildDate.value)
  {
    const _resolveDateValue = defnBuildDate.value
      ? resolveDateValue(
        defnBuildDate.value,
        !excludeTime,
        time ?? (defnBuildDate as DefnBuildDateTime)?.time
      )
      : undefined;

    if(_resolveDateValue)
    {
      const selectedDate = new Date(_resolveDateValue);

      if(excludeTime)
      {
        return formatDateToISO(formatDateToUTC(selectedDate));
      }
      return formatDateToISO(selectedDate);
    }
  }
}

function getGridDefaultRows(
  defnForm: DefnFormUi,
  defnGrid: DefnGrid,
  _fieldValue?: FieldValueGrid): FieldValueGrid
{
  const showAllRowsFieldId = defnGrid?.showAllRowsFieldId;
  let fieldValue = cloneDeep(_fieldValue) as FieldValueGrid;
  const isReport = defnForm.theme?.formVariant === "report";
  let keys = [] as RowId[];
  let map = {} as Record<RowId, FieldDtoGridRow>;
  if(!fieldValue || fieldValue.keys.length === 0)
  {
    fieldValue = getEmptyKeysAndMap() as FieldValueGrid;
  }
  else
  {
    keys = fieldValue.keys;
    map = fieldValue.map;
  }
  if(showAllRowsFieldId && !isReport)
  {
    const fieldPickText = defnForm.compMap[showAllRowsFieldId] as DefnFieldPickText;
    const optionMap = fieldPickText?.optionMap;

    if(optionMap && optionMap.keys.length > 0)
    {
      const presentOptionIdSet = {} as Record<string, boolean>;

      fieldValue.keys.forEach(rowId =>
      {
        const row = map[rowId];
        const valueMap = row?.valueMap || {};
        defnGrid.fieldIdSet?.forEach(fieldId =>
        {
          const comp = defnForm.compMap[fieldId] as DefnField;
          ensureInitValue(defnForm, comp, valueMap, "null");
        });
        if(valueMap[showAllRowsFieldId])
        {
          const optionId = (valueMap[showAllRowsFieldId] as FieldValueOptionId).optionId;
          presentOptionIdSet[optionId] = true;
        }
      });

      optionMap.keys.forEach(optionId =>
      {
        if(!presentOptionIdSet[optionId])
        {
          const rowId = nextRowId();
          const valueMap = {
            [showAllRowsFieldId]: {
              optionId: optionId,
              value: optionMap.map[optionId]?.value
            } as FieldValueOptionId
          };
          defnGrid.fieldIdSet?.forEach(fieldId =>
          {
            const comp = defnForm.compMap[fieldId] as DefnField;
            ensureInitValue(defnForm, comp, valueMap, "null");
          });
          keys.push(rowId);
          map[rowId] = {
            rowId: rowId,
            valueMap: valueMap
          };
        }
      });

      fieldValue = {
        keys: keys,
        map: map
      };
    }
  }

  return fieldValue;
}

export function ensureInitValue(
  defnForm: DefnFormUi,
  defnComp: DefnField,
  defaultValues: FieldValues,
  _defaultValue: TypeFieldValueDefaultType,
  initialFieldId?: MetaIdField): any
{
  const compKey = defnComp?.metaId;
  const type = defnComp?.type;
  const defaultValue = _defaultValue === "null" ? null : undefined;

  if(initialFieldId === compKey)
  {
    return defaultValue;
  }

  if(defaultValues[compKey] &&
    (type !== "dynamic" && type !== "grid"))
  {
    return defaultValues[compKey];
  }

  if(compKey)
  {
    switch(type)
    {
      case "tab":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnTab;
          return defn.tabIdSet ? defn.tabIdSet[0] : defaultValue;
        }
        break;

      case "wizard":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnWizard;
          return defn.compositeIdSet ? defn.compositeIdSet[0] : defaultValue;
        }
        break;

      case "grid":
        const valueGrid = getGridDefaultRows(defnForm,
          defnComp as DefnGrid,
          defaultValues[compKey]
        ) as FieldValueGrid | undefined;
        return valueGrid?.keys.length ? valueGrid : defaultValue;

      case "bool":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldSwitch;

          if(defn.defaultValue !== undefined)
          {
            return fnRawValueToFieldValue("bool", defn.defaultValue, _defaultValue);
          }
          else if(defn.defaultVar !== undefined)
          {
            return fnRawValueToFieldValue("bool", defn.defaultVar, _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }
      case "date":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldDate;

          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue(type, getBuildDateDefaultValue(defn.defaultValue, true), _defaultValue);
          }
          else if(defn.defaultVar)
          {
            return fnRawValueToFieldValue(type, getBuildDateDefaultValue(defn.defaultVar, true), _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }

      case "dateTime":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldDateTime;

          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue(type, getBuildDateDefaultValue(defn.defaultValue), _defaultValue);
          }
          else if(defn.defaultVar)
          {
            return fnRawValueToFieldValue(type, getBuildDateDefaultValue(defn.defaultVar), _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }

      case "counter":
      case "logCounter":
      case "number":
      case "logNumber":
      case "decimal":
      case "logDecimal" :
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as
            | DefnFieldNumber
            | DefnFieldDecimal
            | DefnFieldCounter
            | DefnFieldLogNumber
            | DefnFieldLogDecimal
            | DefnFieldLogCounter;

          if(defn.defaultValue !== undefined)
          {
            return (type === "decimal" || type === "logDecimal")
              ? fnRawValueToFieldValue(type,
                formatDecimalDigitsAfterPeriod(defn.defaultValue,
                  (defn as DefnFieldDecimal | DefnFieldLogDecimal).numberOfDigitsAfterPeriod
                ),
                _defaultValue
              )
              : fnRawValueToFieldValue(type, defn.defaultValue, _defaultValue);
          }
          else if(defn.defaultVar !== undefined)
          {
            return (type === "decimal" || type === "logDecimal")
              ? fnRawValueToFieldValue(type,
                formatDecimalDigitsAfterPeriod(defn.defaultVar,
                  (defn as DefnFieldDecimal | DefnFieldLogDecimal).numberOfDigitsAfterPeriodVar
                ),
                _defaultValue
              )
              : fnRawValueToFieldValue(type, defn.defaultVar, _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        else
        {
          return (type === "decimal" || type === "logDecimal")
            ? fnRawValueToFieldValue(type,
              formatDecimalDigitsAfterPeriod(fnFieldValueToRawValue(type, defaultValues[compKey]) as number,
                (defnComp as DefnFieldDecimal | DefnFieldLogDecimal).numberOfDigitsAfterPeriod
                ?? (defnComp as DefnFieldDecimal | DefnFieldLogDecimal).numberOfDigitsAfterPeriodVar
              )
            )
            : defaultValues[compKey];
        }
      }

      case "slider":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldSlider;

          function getDefaultValue(defaultValue?: number, defaultVar?: number, defaultFieldId?: MetaIdField)
          {
            if(defaultValue !== undefined)
            {
              return defaultValue;
            }
            else if(defaultVar !== undefined)
            {
              return defaultVar;
            }
            else if(defaultFieldId)
            {
              return fnFieldValueToRawValue("number", ensureInitValue(defnForm,
                defnForm.compMap[defaultFieldId] as DefnField,
                defaultValues,
                _defaultValue,
                initialFieldId || defn.metaId
              ));
            }
            else
            {
              return defaultValue;
            }
          }

          return {
            minValue: 0,
            maxValue: getDefaultValue(defn.defaultValue, defn.defaultVar, defn.defaultFieldId)
          } as FieldValueDecimalRange;
        }
        return defaultValues[compKey];
      }

      case "mobileNumber":
      case "location":
      case "handle":
      case "email":
      case "symbol":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as
            | DefnFieldMobileNumber
            | DefnFieldLocation
            | DefnFieldHandle
            | DefnFieldEmail
            | DefnFieldSymbol;

          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue(type, defn.defaultValue, _defaultValue);
          }
          else if(defn.defaultVar)
          {
            return fnRawValueToFieldValue(type, defn.defaultVar, _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }

      case "geoPoint":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldGeoPoint;
          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue(type, defn.defaultValue, _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }

      case "showCode":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldShowCode;

          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue(type, defn.defaultValue, _defaultValue);
          }
          else if(defn.defaultVar)
          {
            return fnRawValueToFieldValue(type, defn.defaultVar.value?.join(), _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            const defnField = defnForm.compMap[defn.defaultFieldId] as DefnField;

            const fieldValue = ensureInitValue(defnForm,
              defnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
            const resultantFieldValue = getFormFieldValueAsText(defnField, fieldValue);

            return resultantFieldValue
              ? {
                value: resultantFieldValue
              } as FieldValueText
              : undefined;
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }

      case "text":
      case "paragraph":
      case "info":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as
            | DefnFieldText
            | DefnFieldParagraph
            | DefnFieldInfo;

          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue("text", defn.defaultValue, _defaultValue);
          }
          else if(defn.defaultVar)
          {
            return fnRawValueToFieldValue("text", (defn.defaultVar as DefnDtoText)?.value?.join(""), _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }

      case "propertyMap":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldPropertyMap;
          if(defn.defaultVar)
          {
            return fnRawValueToFieldValue(type, defn.defaultVar);
          }
          else
          {
            return defaultValue;
          }
        }
        break;
      case "currency":
      case "textSize":
      case "icon":
      case "language":
      case "lineStroke":
      case "month":
      case "pinShape":
      case "quarter":
      case "timeZone":
      case "chipSetDay":
      case "chipSetTime":
      case "chipSetDeviceSize":
      case "chipSetDeviceType":
      case "chipSetDate":
      case "paymentStatus":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as
            | DefnFieldLanguage
            | DefnFieldPinShape
            | DefnFieldLineStroke
            | DefnFieldMonth
            | DefnFieldQuarter
            | DefnFieldTextSize
            | DefnFieldChipSetDay
            | DefnFieldChipSetTime
            | DefnFieldChipSetDeviceSize
            | DefnFieldChipSetDeviceType
            | DefnFieldTimeZone;

          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue(type, resolveEnum(type, defn.defaultValue), _defaultValue);
          }
          else if(defn.defaultVar)
          {
            return fnRawValueToFieldValue(type, resolveEnum(type, defn.defaultVar), _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }

      case "time":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldTime;

          if(defn.defaultValue)
          {
            return resolveTimeAsString(defn.defaultValue);
          }
          else if(defn.defaultVar)
          {
            return resolveTimeAsString(defn.defaultVar);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }
      case "hyperlink":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldHyperlink;

          if(defn.defaultVar)
          {
            return fnRawValueToFieldValue(type, defn.defaultVar.value, _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        return defaultValues[compKey];
      }
      case "rating":
      {
        if(!defaultValues[compKey])
        {
          return defaultValue;
        }
        return defaultValues[compKey];
      }

      case "dateRange":
      case "dateTimeRange":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldDateRange | DefnFieldDateTimeRange;

          const getDefaultValue = (
            defaultValue?: DefnBuildDate,
            defaultVar?: DefnBuildDate,
            defaultFieldId?: MetaIdField,
            fieldValue?: any) =>
          {
            if(defaultValue)
            {
              return getBuildDateDefaultValue(defaultValue);
            }
            else if(defaultVar)
            {
              return getBuildDateDefaultValue(defaultVar);
            }
            else if(defaultFieldId)
            {
              return fnFieldValueToRawValue(type === "dateRange" ? "date" : "dateTime", fieldValue);
            }
            else
            {
              return defaultValue;
            }
          };

          const initializeField = (defaultFieldId: string | undefined, defaultValue: any) =>
          {
            return defaultFieldId
              ? ensureInitValue(defnForm,
                defnForm.compMap[defaultFieldId] as DefnField,
                defaultValues,
                _defaultValue,
                initialFieldId || defn.metaId
              )
              : defaultValue;
          };

          const dateRangeFromFieldId = initializeField(defn.fromDefaultFieldId, defaultValue);
          const dateRangeToFieldId = initializeField(defn.toDefaultFieldId, defaultValue);

          const fromValue = getDefaultValue(defn.fromDefault,
            defn.fromDefaultVar,
            defn.fromDefaultFieldId,
            dateRangeFromFieldId
          );
          const toValue = getDefaultValue(defn.toDefault, defn.toDefaultVar, defn.toDefaultFieldId, dateRangeToFieldId);

          return (fromValue || toValue)
            ? {
              ...(fromValue && {from: fromValue}),
              ...(toValue && {to: toValue})
            } as FieldValueDateRange
            : defaultValue;
        }
        break;

      case "setOfText":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldSetOfText;
          if(defn.defaultValue && defn.defaultValue.length > 0)
          {
            defaultValues[compKey] = {
              valueSet: defn.defaultValue
            } as FieldSetOfOptionId;
            return fnRawValueToFieldValue("setOfText", defn.defaultValue);
          }
          else if(defn.defaultValueFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultValueFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
        }
        break;

      case "duration":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldDuration;
          if(!isEmpty(defn.defaultValue))
          {
            return {
              value: {
                unit: defn.defaultValue?.unit,
                value: defn.defaultValue?.value
              }
            } as FieldValueDuration;
          }
          else if(!isEmpty(defn.defaultVar))
          {
            return {
              value: {
                unit: defn.defaultVar?.unit,
                value: defn.defaultVar?.value
              }
            } as FieldValueDuration;
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        break;

      case "pickText":
      case "pickTree":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldPickText;

          if(defn.defaultOptionId && defn.defaultOptionId.length > 0)
          {
            return {
              optionId: defn.defaultOptionId
            } as FieldValueOptionId;
          }
          else if(defn.defaultOptionFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultOptionFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        break;

      case "pickUser":
      case "setOfUser":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldPickUser | DefnFieldSetOfUser;
          if(defn.defaultValueFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultValueFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        break;

      case "pickRole":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldPickRole;

          if(defn.defaultRoleFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultRoleFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        break;
      case "setOfRole":
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldSetOfRole;

          if(defn.defaultRoleFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultRoleFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else
          {
            return defaultValue;
          }
        }
        break;

      case "html":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldHtml;

          const defaultValue = defn.defaultValue || "";
          const placeholder = defn.placeHolder || "";

          const defaultValueVar = defn.defaultVar?.value?.join() || "";
          const placeholderVar = defn.placeHolderVar?.value?.join() || "";

          const text = defaultValue ? defaultValue : placeholder ? placeholder : undefined;
          const textVar = defaultValueVar ? defaultValueVar : placeholderVar ? placeholderVar : undefined;

          if(text)
          {
            return fnRawValueToFieldValue("paragraph", text, _defaultValue);
          }
          if(textVar)
          {
            return fnRawValueToFieldValue("paragraph", textVar, _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
          else if(defn.placeHolderFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.placeHolderFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }

        }
        return defaultValues[compKey];
      }

      case "color":
      {
        if(!defaultValues[compKey])
        {
          const defn = defnComp as DefnFieldColor;
          if(defn.defaultValue)
          {
            return fnRawValueToFieldValue(type, defn.defaultValue, _defaultValue);
          }
          else if(defn.defaultFieldId)
          {
            return ensureInitValue(defnForm,
              defnForm.compMap[defn.defaultFieldId] as DefnField,
              defaultValues,
              _defaultValue,
              initialFieldId || defn.metaId
            );
          }
        }
        return defaultValues[compKey];
      }

      case "dynamic":
        return ensureInitDynamicValue(defnComp as DefnFieldDynamic, defaultValues);
      default:
        if(!defaultValues[compKey])
        {
          return defaultValue;
        }
    }
  }
}

export function ensureInitValues(
  defnForm: DefnFormUi,
  _defaultValues: FieldValues,
  cb?: (defn: DefnField, defaultValues: FieldValues) => FieldValues | void): FieldValues
{
  let defaultValues = cloneDeep(_defaultValues);
  const defaultValue = "null";

  loopDefnForm(defnForm, (parent, defnComp) =>
  {
    if(parent.type !== "grid")
    {
      if(defnComp.type === "dynamic")
      {
        const fieldValueId = getDynamicValueFieldId(defnComp.metaId);

        defaultValues[fieldValueId] = ensureInitDynamicValue(defnComp as DefnFieldDynamic, defaultValues);
      }
      else if(isEmpty(defaultValues[defnComp.metaId]))
      {
        defaultValues[defnComp.metaId] = ensureInitValue(defnForm, defnComp, defaultValues, defaultValue);
      }
      cb && cb(defnComp, defaultValues);
    }
  }, {excludeGridItem: true});

  return defaultValues;
}

function ensureInitDynamicValue(defnComp: DefnFieldDynamic, _defaultValues: FieldValues)
{
  const fieldId = defnComp.metaId;
  const value = _defaultValues[fieldId] as FieldValueDynamic | undefined;

  if(value && value?.value)
  {
    switch(value.fieldType)
    {
      case "bool":
        return fnRawValueToFieldValue("bool", value.value === "Yes") as FieldValueSwitch;
      case "date":
      case "dateTime":
      case "text":
        return fnRawValueToFieldValue("text", value.value) as FieldValueText;
      case "decimal":
      case "number":
        return fnRawValueToFieldValue("number", parseInt(value.value)) as FieldValueNumber;
      case "pickText":
        return fnRawValueToFieldValue("pickText", value.value) as FieldValueOptionId;
    }
  }
}

function resolveDurationValue(value: FieldDtoDuration): string
{
  const timeUnits: Record<EnumDefnDurationUnit, number> = {
    seconds: 1,
    minutes: 60,
    hours: 3600,
    days: 86400,
    weeks: 604800,
    months: 2592000,
    quarters: 7776000,
    years: 31536000
  };

  if(value?.unit && value?.value)
  {
    const totalSeconds = timeUnits[value.unit] * value.value;

    const unitOrder: EnumDefnDurationUnit[] = [
      "years",
      "quarters",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds"
    ];

    let remainingSeconds = totalSeconds;
    const convertedUnits: string[] = [];

    for(const unit of unitOrder)
    {
      if(remainingSeconds >= timeUnits[unit])
      {
        const unitCount = Math.floor(remainingSeconds / timeUnits[unit]);
        const unitName = unitCount === 1 ? unit.substring(0, unit.length - 1) : unit;
        convertedUnits.push(`${unitCount} ${unitName}`);
        remainingSeconds -= unitCount * timeUnits[unit];
      }
    }

    if(convertedUnits.length > 1)
    {
      const lastUnit = convertedUnits.pop();
      return `${convertedUnits.join(", ")} and ${lastUnit}`;
    }
    else if(convertedUnits.length === 1)
    {
      return convertedUnits[0];
    }
  }
  return "";
}

function resolveEnum(type: EnumDefnCompType, enumValue?: string | string[])
{
  if(type === "month" && enumValue)
  {
    const month = enumValue as EnumDefnMonth;

    switch(month)
    {
      case "CurrentMonth":
      {
        return getCurrentMonth();
      }
      case "PreviousMonth":
      {
        return getPreviousMonth();
      }
      case "NextMonth":
      {
        return getNextMonth();
      }
    }
  }

  return enumValue;
}

function getMonthName(date: Date)
{
  const months = EnumArrayDefnMonth.filter(month => (month !== "currentMonth" && month !== "previousMonth" && month
    !== "nextMonth"));
  return months[date.getMonth()];
}

function getPreviousMonth()
{
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  return getMonthName(currentDate);
}

function getCurrentMonth()
{
  return getMonthName(new Date());
}

function getNextMonth()
{
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  return getMonthName(currentDate);
}
