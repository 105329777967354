// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ArtifactId} from '../../meta/base/Types';
import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgAdminId} from './msg/MsgAdminId';
import {MsgAdminInvite} from './msg/MsgAdminInvite';
import {MsgAnalyticEventCount} from './msg/MsgAnalyticEventCount';
import {MsgAnalyticEventData} from './msg/MsgAnalyticEventData';
import {MsgClusterAnalyticEventCount} from './msg/MsgClusterAnalyticEventCount';
import {MsgClusterAnalyticEventData} from './msg/MsgClusterAnalyticEventData';
import {MsgEntAdminAdd} from './msg/MsgEntAdminAdd';
import {MsgEntAdminInviteDeeplinkGet} from './msg/MsgEntAdminInviteDeeplinkGet';
import {MsgEntAdminUpdate} from './msg/MsgEntAdminUpdate';
import {MsgEntConfigUpdate} from './msg/MsgEntConfigUpdate';
import {MsgEntDemoTemplate} from './msg/MsgEntDemoTemplate';
import {MsgEntUserAdd} from './msg/MsgEntUserAdd';
import {MsgEntUserBulkImport} from './msg/MsgEntUserBulkImport';
import {MsgEntUserDeeplinkGet} from './msg/MsgEntUserDeeplinkGet';
import {MsgEntUserGet} from './msg/MsgEntUserGet';
import {MsgEntUserIdNoVersion} from '../../core/base/msg/MsgEntUserIdNoVersion';
import {MsgEntUserIdSet} from '../../core/base/msg/MsgEntUserIdSet';
import {MsgEntUserInvite} from './msg/MsgEntUserInvite';
import {MsgEntUserListGet} from './msg/MsgEntUserListGet';
import {MsgEntUserUpdate} from './msg/MsgEntUserUpdate';
import {MsgFormId} from './msg/MsgFormId';
import {MsgNeoQLResult} from './msg/MsgNeoQLResult';
import {MsgPluginAdminAdd} from './msg/MsgPluginAdminAdd';
import {MsgPluginAdminUpdate} from './msg/MsgPluginAdminUpdate';
import {MsgPluginApiSpecGet} from './msg/MsgPluginApiSpecGet';
import {MsgPluginId} from './msg/MsgPluginId';
import {MsgScheduleNextExecutionList} from './msg/MsgScheduleNextExecutionList';
import {MsgSpreadsheetHistory} from './msg/MsgSpreadsheetHistory';
import {MsgSpreadsheetHistoryRecordId} from './msg/MsgSpreadsheetHistoryRecordId';
import {MsgSpreadsheetHistoryRecordIdSet} from './msg/MsgSpreadsheetHistoryRecordIdSet';
import {MsgStudioEnt} from './msg/MsgStudioEnt';
import {MsgStudioPlugin} from './msg/MsgStudioPlugin';
import {MsgStudioSearchUsages} from './msg/MsgStudioSearchUsages';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {PluginBundleId} from '../../meta/base/Types';
import {ServiceName} from '../../meta/base/Types';
import {SigAdminEditLockDetail} from './sig/SigAdminEditLockDetail';
import {SigAnalyticEventCount} from './sig/SigAnalyticEventCount';
import {SigAnalyticEventData} from './sig/SigAnalyticEventData';
import {SigDashboardData} from './sig/SigDashboardData';
import {SigDefnForm} from './sig/SigDefnForm';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigEntAdminCaller} from './sig/SigEntAdminCaller';
import {SigEntAdminList} from './sig/SigEntAdminList';
import {SigEntDashboardData} from './sig/SigEntDashboardData';
import {SigEntDemoTemplate} from './sig/SigEntDemoTemplate';
import {SigEntDeployStatus} from './sig/SigEntDeployStatus';
import {SigEntUser} from './sig/SigEntUser';
import {SigEntUserBulkImport} from './sig/SigEntUserBulkImport';
import {SigEntUserList} from './sig/SigEntUserList';
import {SigMediaIdDocument} from '../studioEnt/sig/SigMediaIdDocument';
import {SigNeoQLResult} from './sig/SigNeoQLResult';
import {SigPluginAdminCaller} from './sig/SigPluginAdminCaller';
import {SigPluginAdminList} from './sig/SigPluginAdminList';
import {SigPluginApiSpec} from './sig/SigPluginApiSpec';
import {SigScheduleNextExecutionList} from './sig/SigScheduleNextExecutionList';
import {SigSpreadsheetHistory} from './sig/SigSpreadsheetHistory';
import {SigStudioEntConfig} from './sig/SigStudioEntConfig';
import {SigStudioPluginMap} from './sig/SigStudioPluginMap';
import {SigStudioSearchUsages} from './sig/SigStudioSearchUsages';
import {SigSysDefnFormMapGet} from './sig/SigSysDefnFormMapGet';
import {SigUrlPassword} from '../../core/deeplink/sig/SigUrlPassword';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcStudioMain
{
  static readonly SN: ServiceName = "studioMain";

  static analyticEventCountGet(entId: EntId, msg: MsgAnalyticEventCount, sigAcceptor: ISigAcceptor<SigAnalyticEventCount>): void
  {
    rpcCall<SigAnalyticEventCount>(entId, RpcStudioMain.SN, "analyticEventCountGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static analyticEventDataGet(entId: EntId, msg: MsgAnalyticEventData, sigAcceptor: ISigAcceptor<SigAnalyticEventData>): void
  {
    rpcCall<SigAnalyticEventData>(entId, RpcStudioMain.SN, "analyticEventDataGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static clusterAnalyticEventCountGet(msg: MsgClusterAnalyticEventCount, sigAcceptor: ISigAcceptor<SigAnalyticEventCount>): void
  {
    rpcCall<SigAnalyticEventCount>(ENT_ID_GLOBAL, RpcStudioMain.SN, "clusterAnalyticEventCountGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static clusterAnalyticEventDataGet(msg: MsgClusterAnalyticEventData, sigAcceptor: ISigAcceptor<SigAnalyticEventData>): void
  {
    rpcCall<SigAnalyticEventData>(ENT_ID_GLOBAL, RpcStudioMain.SN, "clusterAnalyticEventDataGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static dashBoardDataGet(entId: EntId, sigAcceptor: ISigAcceptor<SigDashboardData>): void
  {
    rpcCall<SigDashboardData>(entId, RpcStudioMain.SN, "dashBoardDataGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static defnFormGet(entId: EntId, msg: MsgFormId, sigAcceptor: ISigAcceptor<SigDefnForm>): void
  {
    rpcCall<SigDefnForm>(entId, RpcStudioMain.SN, "defnFormGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entAdminAdd(entId: EntId, msg: MsgEntAdminAdd, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entAdminAdd")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entAdminCallerGet(entId: EntId, sigAcceptor: ISigAcceptor<SigEntAdminCaller>): void
  {
    rpcCall<SigEntAdminCaller>(entId, RpcStudioMain.SN, "entAdminCallerGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static entAdminEditLockDetailGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigAdminEditLockDetail>): void
  {
    rpcCall<SigAdminEditLockDetail>(entId, RpcStudioMain.SN, "entAdminEditLockDetailGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entAdminEditLockTransfer(entId: EntId, msg: MsgAdminId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entAdminEditLockTransfer")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static entAdminExit(entId: EntId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entAdminExit")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static entAdminInvite(entId: EntId, msg: MsgAdminInvite, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entAdminInvite")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entAdminInviteDeeplinkGet(entId: EntId, msg: MsgEntAdminInviteDeeplinkGet, sigAcceptor: ISigAcceptor<SigUrlPassword>): void
  {
    rpcCall<SigUrlPassword>(entId, RpcStudioMain.SN, "entAdminInviteDeeplinkGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entAdminListGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigEntAdminList>): void
  {
    rpcCall<SigEntAdminList>(entId, RpcStudioMain.SN, "entAdminListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entAdminRemove(entId: EntId, msg: MsgAdminId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entAdminRemove")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static entAdminUpdate(entId: EntId, msg: MsgEntAdminUpdate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entAdminUpdate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static entDashboardDataGet(entId: EntId, sigAcceptor: ISigAcceptor<SigEntDashboardData>): void
  {
    rpcCall<SigEntDashboardData>(entId, RpcStudioMain.SN, "entDashboardDataGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static entDemoTemplateGet(entId: EntId, sigAcceptor: ISigAcceptor<SigEntDemoTemplate>): void
  {
    rpcCall<SigEntDemoTemplate>(entId, RpcStudioMain.SN, "entDemoTemplateGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static entDemoTemplatePut(entId: EntId, msg: MsgEntDemoTemplate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entDemoTemplatePut")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static entDemoTemplateRemove(entId: EntId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entDemoTemplateRemove")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static entScheduleNextExecutionListGet(entId: EntId, msg: MsgScheduleNextExecutionList, sigAcceptor: ISigAcceptor<SigScheduleNextExecutionList>): void
  {
    rpcCall<SigScheduleNextExecutionList>(entId, RpcStudioMain.SN, "entScheduleNextExecutionListGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entUserActivate(entId: EntId, msg: MsgEntUserIdNoVersion, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entUserActivate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static entUserAdd(entId: EntId, msg: MsgEntUserAdd, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entUserAdd")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entUserBulkImport(entId: EntId, msg: MsgEntUserBulkImport, sigAcceptor: ISigAcceptor<SigEntUserBulkImport>): void
  {
    rpcCall<SigEntUserBulkImport>(entId, RpcStudioMain.SN, "entUserBulkImport")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entUserDeactivate(entId: EntId, msg: MsgEntUserIdSet, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entUserDeactivate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static entUserExit(entId: EntId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entUserExit")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static entUserGet(msg: MsgEntUserGet, sigAcceptor: ISigAcceptor<SigEntUser>): void
  {
    rpcCall<SigEntUser>(ENT_ID_GLOBAL, RpcStudioMain.SN, "entUserGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entUserInvite(entId: EntId, msg: MsgEntUserInvite, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entUserInvite")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entUserInviteDeeplinkGet(entId: EntId, msg: MsgEntUserDeeplinkGet, sigAcceptor: ISigAcceptor<SigUrlPassword>): void
  {
    rpcCall<SigUrlPassword>(entId, RpcStudioMain.SN, "entUserInviteDeeplinkGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entUserListGet(entId: EntId, msg: MsgEntUserListGet, sigAcceptor: ISigAcceptor<SigEntUserList>): void
  {
    rpcCall<SigEntUserList>(entId, RpcStudioMain.SN, "entUserListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entUserRemove(entId: EntId, msg: MsgEntUserIdNoVersion, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entUserRemove")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static entUserUpdate(entId: EntId, msg: MsgEntUserUpdate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "entUserUpdate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static neoQLResultGet(entId: EntId, msg: MsgNeoQLResult, sigAcceptor: ISigAcceptor<SigNeoQLResult>): void
  {
    rpcCall<SigNeoQLResult>(entId, RpcStudioMain.SN, "neoQLResultGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static pluginAdminAdd(pluginBundleId: PluginBundleId, msg: MsgPluginAdminAdd, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "pluginAdminAdd")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static pluginAdminCallerGet(pluginBundleId: PluginBundleId, sigAcceptor: ISigAcceptor<SigPluginAdminCaller>): void
  {
    rpcCall<SigPluginAdminCaller>(pluginBundleId, RpcStudioMain.SN, "pluginAdminCallerGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static pluginAdminEditLockDetailGet(pluginBundleId: PluginBundleId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigAdminEditLockDetail>): void
  {
    rpcCall<SigAdminEditLockDetail>(pluginBundleId, RpcStudioMain.SN, "pluginAdminEditLockDetailGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static pluginAdminEditLockTransfer(pluginBundleId: PluginBundleId, msg: MsgAdminId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "pluginAdminEditLockTransfer")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static pluginAdminExit(pluginBundleId: PluginBundleId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "pluginAdminExit")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static pluginAdminInvite(pluginBundleId: PluginBundleId, msg: MsgAdminInvite, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "pluginAdminInvite")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static pluginAdminListGet(pluginBundleId: PluginBundleId, sigAcceptor: ISigAcceptor<SigPluginAdminList>): void
  {
    rpcCall<SigPluginAdminList>(pluginBundleId, RpcStudioMain.SN, "pluginAdminListGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static pluginAdminRemove(pluginBundleId: PluginBundleId, msg: MsgAdminId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "pluginAdminRemove")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static pluginAdminUpdate(pluginBundleId: PluginBundleId, msg: MsgPluginAdminUpdate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "pluginAdminUpdate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static pluginApiSpecGet(entId: EntId, msg: MsgPluginApiSpecGet, sigAcceptor: ISigAcceptor<SigPluginApiSpec>): void
  {
    rpcCall<SigPluginApiSpec>(entId, RpcStudioMain.SN, "pluginApiSpecGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static pluginSourceCodeDownload(pluginBundleId: PluginBundleId, sigAcceptor: ISigAcceptor<SigMediaIdDocument>): void
  {
    rpcCall<SigMediaIdDocument>(pluginBundleId, RpcStudioMain.SN, "pluginSourceCodeDownload")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static spreadsheetHistoryBookmark(entId: EntId, msg: MsgSpreadsheetHistoryRecordId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "spreadsheetHistoryBookmark")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static spreadsheetHistoryGet(entId: EntId, msg: MsgSpreadsheetHistory, sigAcceptor: ISigAcceptor<SigSpreadsheetHistory>): void
  {
    rpcCall<SigSpreadsheetHistory>(entId, RpcStudioMain.SN, "spreadsheetHistoryGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static spreadsheetHistoryRemove(entId: EntId, msg: MsgSpreadsheetHistoryRecordIdSet, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "spreadsheetHistoryRemove")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static studioEntConfigGet(entId: EntId, sigAcceptor: ISigAcceptor<SigStudioEntConfig>): void
  {
    rpcCall<SigStudioEntConfig>(entId, RpcStudioMain.SN, "studioEntConfigGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static studioEntConfigUpdate(entId: EntId, msg: MsgEntConfigUpdate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "studioEntConfigUpdate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static studioEntDeployStatusGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigEntDeployStatus>): void
  {
    rpcCall<SigEntDeployStatus>(entId, RpcStudioMain.SN, "studioEntDeployStatusGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static studioEntMerge(entId: EntId, msg: MsgStudioEnt, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "studioEntMerge")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static studioEntPut(entId: EntId, msg: MsgStudioEnt, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "studioEntPut")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static studioEntRevertToDeploy(entId: EntId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioMain.SN, "studioEntRevertToDeploy")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static studioFindUsages(artifactId: ArtifactId, msg: MsgStudioSearchUsages, sigAcceptor: ISigAcceptor<SigStudioSearchUsages>): void
  {
    rpcCall<SigStudioSearchUsages>(artifactId, RpcStudioMain.SN, "studioFindUsages")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static studioPluginMapGet(sigAcceptor: ISigAcceptor<SigStudioPluginMap>): void
  {
    rpcCall<SigStudioPluginMap>(ENT_ID_GLOBAL, RpcStudioMain.SN, "studioPluginMapGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static studioPluginMarkObsolete(pluginBundleId: PluginBundleId, msg: MsgPluginId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "studioPluginMarkObsolete")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static studioPluginPut(pluginBundleId: PluginBundleId, msg: MsgStudioPlugin, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "studioPluginPut")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static studioPluginRemove(pluginBundleId: PluginBundleId, msg: MsgPluginId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioMain.SN, "studioPluginRemove")
      .sendBearerToken()
      .delete(msg, sigAcceptor);
  }

  static sysDefnFormMapGet(sigAcceptor: ISigAcceptor<SigSysDefnFormMapGet>): void
  {
    rpcCall<SigSysDefnFormMapGet>(ENT_ID_GLOBAL, RpcStudioMain.SN, "sysDefnFormMapGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }
}