import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import DateTimeFormat from "../../../atom/assets/DateFormat.json";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

const key1 = "key1";
const key2 = "key2";
const key3 = "key3";

const date = Object.keys(DateTimeFormat).reduce((prev, curr) =>
{
  if(curr.includes("/"))
  {
    prev[key1] = [...(prev[key1] ?? []), curr];
  }
  else if(curr.includes("-"))
  {
    prev[key2] = [...(prev[key2] ?? []), curr];
  }
  else
  {
    prev[key3] = [...(prev[key3] ?? []), curr];
  }

  return prev;

}, {} as Record<string, string[]>);

const options: DefnDtoOption[] = (() =>
{
  const options = [] as DefnDtoOption[];
  const keys = Object.keys(date);

  keys.forEach((key, index) =>
  {
    const formats = date[key];

    formats.forEach(format =>
    {
      options.push({
        metaId: format,
        value: format
      });
    });

    if(index < keys.length - 1)
    {
      options.push({
        metaId: "",
        value: ""
      });
    }
  });

  return options;
})();

export default function FieldPickDateTimeFormat(props: {
  defn: DefnFieldEditable;
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
      {
        return <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={props.defn}
          optionMap={optionsToMapOfOption(options)}
        />;
      }}
    />
  );
}
