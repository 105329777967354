import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldRefUser} from "../../../../api/meta/base/dto/DefnFieldRefUser";
import {DefnFieldUserId} from "../../../../api/meta/base/dto/DefnFieldUserId";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {CLEAR} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {CLOSE} from "../../../../base/types/TypesIcon";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldRawUser(props: {
  defn: DefnFieldUserId | DefnFieldRefUser
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueEntUserId | null;
        const disabled = formCtx.isFieldDisable(defn);
        const readOnly = formCtx.isFieldReadonly(defn);

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowY={"visible"}
                overflowX={"visible"}
                width={"100%"}
              >
                <FieldRawTextField
                  {...props}
                  value={fnRawValueToFieldValue("text", fieldValue?.displayField) as FieldValueText | undefined}
                  onBlur={field.onBlur}
                  error={error}
                  ref={field.ref}
                  clearButton={Boolean(fieldValue)}
                  onChange={field.onChange}
                />

                {(defn.type === "refUser") && !(readOnly || disabled) &&
                  <RawIconStrip
                    toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
                    iconStrip={[CLOSE]}
                    onClick={() =>
                    {
                      field.onChange(null);
                    }}
                    iconStripDisable={(!fieldValue || disabled) ? [CLOSE] : []}
                  />
                }
              </LayoutFlexRow>
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
