import {DtoChatMessageOffset} from "../../../../api/core/base/dto/DtoChatMessageOffset";
import {MsgPromptActionsGet} from "../../../../api/ent/entMain/msg/MsgPromptActionsGet";
import {RpcEntMain} from "../../../../api/ent/entMain/RpcEntMain";
import {SigPromptActions} from "../../../../api/ent/entMain/sig/SigPromptActions";
import {SigGroupInfo} from "../../../../api/home/aside/sig/SigGroupInfo";
import {DtoMessagePayloadLinkText} from "../../../../api/home/base/dto/DtoMessagePayloadLinkText";
import {DtoMessagePayloadReport} from "../../../../api/home/base/dto/DtoMessagePayloadReport";
import {DtoMessagePayloadText} from "../../../../api/home/base/dto/DtoMessagePayloadText";
import {DtoMessagePayloadVoice} from "../../../../api/home/base/dto/DtoMessagePayloadVoice";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {MsgChatId} from "../../../../api/home/base/msg/MsgChatId";
import {MsgEntUserId} from "../../../../api/home/base/msg/MsgEntUserId";
import {SigUserAvatar} from "../../../../api/home/drawer/sig/SigUserAvatar";
import {WsocDrawer} from "../../../../api/home/drawer/WsocDrawer";
import {MsgMessageSend} from "../../../../api/home/main/msg/MsgMessageSend";
import {MsgUrl} from "../../../../api/home/main/msg/MsgUrl";
import {RpcMain} from "../../../../api/home/main/RpcMain";
import {SigLinkPreview} from "../../../../api/home/main/sig/SigLinkPreview";
import {WsocMain} from "../../../../api/home/main/WsocMain";
import {isNonGlobalEntId} from "../../../../api/meta/base/ApiPlus";
import {nextRowId} from "../../../../api/meta/base/ApiPlus";
import {nextMessageId} from "../../../../api/meta/base/ApiPlus";
import {nextMediaIdAudio} from "../../../../api/meta/base/ApiPlus";
import {EntUserId} from "../../../../api/meta/base/Types";
import {ContactId} from "../../../../api/meta/base/Types";
import {GroupId} from "../../../../api/meta/base/Types";
import {ChatId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {EnvError} from "../../../../api/nucleus/base/dto/EnvError";
import ISrvc from "../../../../base/ISrvc";
import {formatDetailedDateTime} from "../../../../base/plus/DatePlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {uploadMedia} from "../../../../base/plus/MediaPlus";
import {textUser} from "../../../../base/plus/SrvcPlus";
import {getLinkFromText} from "../../../../base/plus/StringPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {FN_NOOP} from "../../../../base/plus/SysPlus";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {IList} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListBinder} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {comboToEntId} from "../../../../base/types/TypesComboId";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import {toComboId} from "../../../../base/types/TypesComboId";
import {TypeUserField} from "../../../../base/types/TypesGlobal";
import {CbSuccess} from "../../../../base/types/TypesGlobal";
import {logError} from "../../../../base/util/AppLog";
import {getCallerEntUserId} from "../../../../Store";
import {RootState} from "../../../../Store";
import {getCallerEntUserIdGlobal} from "../../../../Store";
import {store} from "../../../../Store";
import {Srvc} from "../../../Srvc";
import {TypeSigUserAvatar} from "../../aside/groupInfo/SrvcHomeAsideGroupInfoSearchMembers";

export default class SrvcHomeMainFooterInputBar extends ISrvc
{
  subscriberId = "SrvcHomeMainFooterInputBar";

  constructor(public selectMainGroupMemberMentionList: SelectList, public selectAsideGroupMemberMentionList: SelectList)
  {
    super();
  }

  canShowInputBar(entId: EntId, chatId: ChatId)
  {
    const rootState = store.getState();
    const callerEntUserId = getCallerEntUserIdGlobal(rootState);

    const comboId = toComboId(entId, chatId);
    const onlyAdminCanSendMessages = rootState.cache.app.group.groupInfo[comboId]?.settings.onlyAdminCanSendMessages;
    const isAdmin = rootState.cache.app.group.groupInfo[comboId]?.adminMap[callerEntUserId];
    return (!Boolean(onlyAdminCanSendMessages && !isAdmin));
  }

  sendVoice(
    entId: EntId,
    chatId: ChatId,
    blobData: {mediaBlobUrl?: string | undefined, blob: Blob | undefined, audioBuffer?: Promise<AudioBuffer>},
    replyPayload?: DtoMessageReplyPayload
  )
  {
    if(blobData.blob)
    {

      const mediaId = nextMediaIdAudio(getFileExt(blobData.blob as File));
      const file = new File([blobData.blob],
        mediaId,
        {
          type: "mp3",
          lastModified: Date.now()
        }
      );

      uploadMedia(
        entId,
        mediaId,
        file,
        (mediaId) =>
        {
          blobData.audioBuffer?.then((audioBuffer: AudioBuffer) =>
          {
            this.wsocMessageSendVoice(
              entId,
              chatId,
              {
                mediaIdAudio: mediaId,
                durationMs: audioBuffer.duration * 1000,
                fileSize: blobData.blob?.size as number,
                text: `neoXl-${formatDetailedDateTime(new Date())}.mp3`
              } as DtoMessagePayloadVoice,
              replyPayload
            );
          });
        },
        (error?: string) =>
        {
          logError("sendVoice", error);
        }
      );
    }
  }

  sendText(
    entId: EntId,
    chatId: ChatId,
    text: string,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload,
    mentionMap?: Record<string, ContactId>,
    isLinkPreviewOpen?: boolean
  )
  {
    text = text.trim();
    const link = getLinkFromText(text);

    if(link.url && !isLinkPreviewOpen)
    {
      this.wsocMessageSendLink(
        entId,
        chatId,
        {
          text: link.text,
          pageUrl: link.url,
          messageType: "linkText",
          mentionMap: mentionMap
        },
        chatMessageOffset,
        replyPayload
      );
    }
    else
    {
      this.wsocMessageSendText(entId, chatId, text, chatMessageOffset, replyPayload, mentionMap);
    }
  }

  sendPrompt(
    entId: EntId,
    groupId: GroupId,
    promptText: string,
    cbSuccess?: (sig: SigPromptActions) => void,
    cbError?: (error: EnvError) => void)
  {
    const msg: MsgPromptActionsGet = {
      groupId,
      promptText
    };

    RpcEntMain.entPromptActionsGet(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && envSig.sig && cbSuccess(envSig.sig);
      }
      else
      {
        cbError && envSig.error && cbError(envSig.error);
      }
    });
  }

  sendLinkText(
    entId: EntId,
    chatId: ChatId,
    text: string,
    pageTitle?: string,
    pageIconUrl?: string,
    pageSubTitle?: string,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload,
    mentionMap?: Record<string, ContactId>
  )
  {
    text = text.trim();
    const link = getLinkFromText(text);
    if(link)
    {
      const payload: DtoMessagePayloadLinkText = {
        text: link.text,
        pageUrl: link.url || "",
        pageTitle: pageTitle,
        pageIconUrl: pageIconUrl,
        pageSubTitle: pageSubTitle,
        messageType: "linkText",
        mentionMap: mentionMap
      };

      this.wsocMessageSendLink(
        entId,
        chatId,
        payload,
        chatMessageOffset,
        replyPayload
      );
    }
  }

  subscribeGroupMember(entChatId: TypeComboId, unsubscribe?: boolean): void
  {
    const subscriberId = this.subscriberId;
    Srvc.app.pubsub.homeAvatar(subscriberId, entChatId, unsubscribe);
  }

  getGroupMemberListBinder()
  {
    return {
      selectSourceItem1: this.selectAvatar.bind(this),
      onBindSourceItem1: this.bindAvatar.bind(this)
    } as IListBinder<TypeSigUserAvatar>;
  }

  groupMemberListLoad(listName: string, entId: EntId, sig: SigGroupInfo, defaultSearchWords?: string[])
  {
    const rootState = store.getState();
    const itemIds = [] as TypeComboId[];
    const itemsById = {} as IListItemsById;

    const callerId = getCallerEntUserId(rootState, entId);
    const groupMemberMap = sig?.memberMap;
    const groupMemberKeys = Object.keys(groupMemberMap);

    if(groupMemberKeys.length !== 0)
    {
      Promise.all(groupMemberKeys.map((entUserId) =>
      {

        return new Promise<void>((resolve) =>
        {
          this.wsoUserAvtarGet(entId, entUserId, (sigUserAvatar) =>
          {
            if(sigUserAvatar)
            {
              const memberAvtarSrc = this.getGroupMemberAvtarSrc(rootState, sigUserAvatar, entId);
              const newItem = {
                version: sigUserAvatar.version,
                type: "aps",
                avatarLeft: {
                  src: memberAvtarSrc,
                  icon: "user"
                },
                primary: {
                  text: textUser(sigUserAvatar)
                },
                secondary: {
                  text: ""
                },
                userField: {value: sigUserAvatar.entUserId} as TypeUserField,
                hideMenu: true
              } as IListItemAPSA;

              if(entUserId !== callerId)
              {
                const memberComboId = toComboId(entId, entUserId);
                itemIds.push(memberComboId);
                itemsById[memberComboId] = newItem;
              }
            }
            resolve();
          });
        });

      })).then(() =>
      {
        dispatchList(listName, listRefresh({
          version: sig.version,
          itemIds: itemIds,
          itemsById: itemsById,
          selectedItemId: "",
          searchWords: defaultSearchWords
        } as IList));
      });
    }
  }

  wsoUserAvtarGet(entId: EntId, entUserId: EntUserId, cbSuccess?: (sigUserAvatar?: SigUserAvatar) => void)
  {
    const msg: MsgEntUserId = {
      entUserId: entUserId
    };
    WsocDrawer.userAvatarGet(entId, msg, (envSig) =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  rpcLinkPreviewGet(url: string, cbSuccess?: (sig?: SigLinkPreview) => void)
  {
    const msg: MsgUrl = {
      url
    };
    RpcMain.linkPreviewGet(msg, envSig => cbSuccess && cbSuccess(envSig.sig));
  }

  //region rpc

  wsocMessageTyping(entId: EntId, chatId: ChatId)
  {
    const msg: MsgChatId = {
      chatId: chatId
    };

    WsocMain.messageTyping(entId, msg, FN_NOOP);
  }

  //endregion

  // region wsoc

  wsocMessageSendReport(entId: EntId, chatId: ChatId, payLoad: DtoMessagePayloadReport, cbSuccess?: CbSuccess)
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      payload: {
        ...payLoad,
        rowId: payLoad.formValueRaw ? payLoad.formValueRaw?.rowId : nextRowId(),
        messageType: "report"
      } as DtoMessagePayloadReport
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  private selectAvatar(state: RootState, entChatId: TypeComboId): TypeSigUserAvatar
  {
    return state.cache.app.user.userAvatarMap[entChatId as EntUserId];
  }

  private bindAvatar(listName: string, entChatId: TypeComboId, avatar?: TypeSigUserAvatar): void
  {
    if(avatar)
    {
      const rootState = store.getState();
      const sig = avatar as SigUserAvatar;

      const entId = comboToEntId(entChatId);
      const memberAvtarSrc = this.getGroupMemberAvtarSrc(rootState, sig, entId);

      const newItem = {
        version: sig.version,
        type: "aps",
        avatarLeft: {
          src: memberAvtarSrc,
          icon: "user"
        },
        primary: {
          text: textUser(sig)
        },
        secondary: {
          text: ""
        },
        userField: {value: sig.entUserId} as TypeUserField,
        hideMenu: true
      } as IListItemAPSA;

      dispatchList(listName, listSetItem({
        itemId: entChatId,
        newItem: newItem
      }));
    }
  }

  private getGroupMemberAvtarSrc(rootState: RootState, avatar: SigUserAvatar, entId: EntId)
  {
    let avtarSrc: string | undefined;

    if(isNonGlobalEntId(entId))
    {
      const entAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];
      avtarSrc = getImageThumbnail(avatar?.avatarId) || getMediaSrc(entAvatar?.avatarId);
    }
    else
    {
      avtarSrc = getImageThumbnail(avatar?.avatarId);
    }

    return avtarSrc;
  }

  private wsocMessageSendText(
    entId: EntId,
    chatId: ChatId,
    text: string,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload,
    mentionMap?: Record<string, ContactId>
  )
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: {
        text: text,
        messageType: "text",
        mentionMap: mentionMap
      } as DtoMessagePayloadText,
      replyPayload: replyPayload
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  private wsocMessageSendLink(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayloadLinkText,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: payload,
      replyPayload: replyPayload
    };
    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  private wsocMessageSendVoice(
    entId: EntId,
    chatId: ChatId,
    payLoad: DtoMessagePayloadVoice,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      payload: {
        ...payLoad,
        messageType: "voice"
      } as DtoMessagePayloadVoice,
      replyPayload: replyPayload
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  //endregion
}
