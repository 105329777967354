import {darken} from "@mui/system/colorManipulator";
import {LegendItem} from "chart.js";
import {ChartEvent} from "chart.js";
import {Chart} from "chart.js";
import {ChartOptions} from "chart.js";
import {ChartDataset} from "chart.js";
import {isEmpty} from "lodash";
import {useMemo} from "react";
import {useCallback} from "react";
import {FieldValues} from "react-hook-form";
import {DefnDtoChartYAxis} from "../../../api/meta/base/dto/DefnDtoChartYAxis";
import {DefnDtoColor} from "../../../api/meta/base/dto/DefnDtoColor";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnLayoutGrid} from "../../../api/meta/base/dto/DefnLayoutGrid";
import {DefnLayoutGridXYChart} from "../../../api/meta/base/dto/DefnLayoutGridXYChart";
import {DefnStudioMapOfChartXAxis} from "../../../api/meta/base/dto/DefnStudioMapOfChartXAxis";
import {DefnStudioMapOfChartYAxis} from "../../../api/meta/base/dto/DefnStudioMapOfChartYAxis";
import {FieldDtoGridRow} from "../../../api/meta/base/dto/FieldDtoGridRow";
import {YAxisFieldType} from "../../../api/meta/base/StudioSetsFieldType";
import {RowId} from "../../../api/meta/base/Types";
import {EnumArrayDefnMonth} from "../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../base/plus/FieldValuePlus";
import {isNumericField} from "../../../base/plus/FormPlus";
import {getDefnDtoColorToCssColor} from "../../../base/plus/FormPlus";
import {loopKeysMap} from "../../../base/plus/JsPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {IChartValues} from "../../../base/types/TypeCharts";
import {IChart} from "../../../base/types/TypeCharts";

export interface IChartDataSet
{
  dataMap: Record<string, number>;
  label?: string;
  backgroundColor?: string | string[];
  borderColor?: string | string[];
}

const labelColorSet = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 0, 0, 1)",
  "rgba(0, 255, 0, 1)",
  "rgba(0, 0, 255, 1)",
  "rgba(255, 255, 0, 1)",
  "rgba(255, 0, 255, 1)",
  "rgba(0, 255, 255, 1)",
  "rgba(255, 128, 0, 1)",
  "rgba(255, 0, 128, 1)",
  "rgba(0, 128, 255, 1)",
  "rgba(128, 0, 255, 1)",
  "rgba(255, 128, 255, 1)",
  "rgba(128, 255, 255, 1)",
  "rgba(255, 128, 0, 1)",
  "rgba(0, 128, 128, 1)",
  "rgba(128, 0, 128, 1)",
  "rgba(128, 128, 0, 1)",
  "rgba(128, 128, 128, 1)",
  "rgba(192, 192, 0, 1)",
  "rgba(192, 0, 192, 1)",
  "rgba(0, 192, 192, 1)",
  "rgba(192, 128, 0, 1)",
  "rgba(192, 0, 128, 1)",
  "rgba(0, 128, 192, 1)",
  "rgba(128, 0, 192, 1)",
  "rgba(192, 128, 192, 1)",
  "rgba(128, 192, 192, 1)",
  "rgba(192, 128, 128, 1)",
  "rgba(128, 192, 128, 1)",
  "rgba(128, 128, 192, 1)",
  "rgba(192, 192, 128, 1)",
  "rgba(128, 128, 64, 1)",
  "rgba(128, 64, 128, 1)",
  "rgba(64, 128, 128, 1)",
  "rgba(192, 64, 64, 1)",
  "rgba(64, 192, 64, 1)",
  "rgba(0, 0, 0, 1)"
];

export default function useXYChart(props: IChart)
{
  const defnForm = props.defnForm;
  const values = props.values;
  const layout = props.layout;
  const xAxis = layout.xAxis;
  const xAxisMap = layout.xAxisMap;
  const yAxisMap = layout.yAxisMap;
  const xField = defnForm.compMap[xAxis] as DefnField;

  const labels = useMemo(() => (xField)
    ? getXEnumLabels(xField, values, xAxisMap)
    : [], [values, xField]);

  const getDataLineAndBar = useCallback(() =>
  {
    const datasets = [] as IChartDataSet[];
    if(yAxisMap)
    {
      loopKeysMap(values, (_: RowId, gridValue: FieldDtoGridRow) =>
      {
        const valueMap = gridValue.valueMap || {};
        insertDataSetForYAxisMap(xField, yAxisMap, defnForm, valueMap, datasets);
      });
    }
    else
    {
      datasets[0] = getHitCount(xField, values);
    }

    return datasets.map((dataSet, index) =>
    {
      const newDataMap: Record<string, number> = {};

      const color = xAxisMap?.keys.reduce((prev, curr) =>
      {
        const chartXAxis = xAxisMap?.map[curr];
        const xColor = chartXAxis?.color || chartXAxis?.colorVar;
        const clr = getChartColor(index, xColor);

        const xLabel = chartXAxis?.label;
        const dataLabel = Object.keys(dataSet.dataMap).find(key => chartXAxis?.valueOptionId === key);

        if(xLabel && dataLabel)
        {
          newDataMap[xLabel] = dataSet.dataMap[dataLabel];
        }
        else if(dataLabel)
        {
          newDataMap[dataLabel] = dataSet.dataMap[dataLabel];
        }

        const bgColor = (xColor ? clr.backgroundColor : dataSet.backgroundColor) as string;
        const borderColor = (xColor ? clr.borderColor : dataSet.borderColor) as string;
        prev.backgroundColor.push(bgColor);
        prev.borderColor.push(borderColor);
        return prev;
      }, {
        backgroundColor: [] as string[],
        borderColor: [] as string[]
      });

      if(!isEmpty(newDataMap))
      {
        dataSet.dataMap = newDataMap;
      }

      return {
        ...dataSet,
        ...color,
        data: labels.map(value => dataSet.dataMap[value] || 0)
      } as ChartDataset<"bar" | "line">;
    });

  }, [defnForm, values, xField, yAxisMap]);

  const getDataScatterPlot = useCallback(() =>
  {
    const datasets = [] as ChartDataset<"scatter">[];
    loopKeysMap(values, (_: RowId, gridValue: FieldDtoGridRow) =>
    {
      const valueMap = gridValue.valueMap || {};

      if(yAxisMap)
      {
        insertScatterPlotDataSet(xField, yAxisMap, defnForm, valueMap, datasets);
      }
    });

    return datasets as ChartDataset<"scatter">[];
  }, [defnForm, values, xField, yAxisMap]);

  const getDataPieAndDoughnut = useCallback(() =>
  {
    const datasets = [] as IChartDataSet[];
    const first = yAxisMap?.keys[0];
    const sig = first ? yAxisMap?.map[first] : undefined;
    if(sig)
    {
      loopKeysMap(values, (_: RowId, gridValue: FieldDtoGridRow) =>
      {
        const valueMap = gridValue.valueMap || {};

        const yField = defnForm.compMap[sig.fieldId] as DefnField | undefined;

        if(!datasets[0])
        {
          datasets[0] = {dataMap: {}};
        }
        insertDataSetBgColorFromXAxisColorVar(datasets[0], labels, xAxisMap, yField);
        insertDataSetValue(datasets[0], xField, yField, valueMap);
      });
    }
    else
    {
      datasets[0] = getHitCount(xField, values);
    }

    const pieAndDonutChartData = datasets.map((dataSet) =>
    {
      const newDataMap: Record<string, number> = {};
      xAxisMap?.keys.forEach(curr =>
      {
        const chartXAxis = xAxisMap?.map[curr];
        const xLabel = chartXAxis?.label;
        const dataLabel = Object.keys(dataSet.dataMap).find(key => chartXAxis?.valueOptionId === key);

        if(xLabel && dataLabel)
        {
          newDataMap[xLabel] = dataSet.dataMap[dataLabel];
        }
        else if(dataLabel)
        {
          newDataMap[dataLabel] = dataSet.dataMap[dataLabel];
        }
      });

      if(!isEmpty(newDataMap))
      {
        dataSet.dataMap = newDataMap;
      }

      return {
        ...dataSet,
        data: labels.map(value => dataSet.dataMap[value] || 0)
      } as ChartDataset<"pie" | "doughnut">;
    });

    return pieAndDonutChartData;
  }, [defnForm.compMap, values, xField, yAxisMap?.keys, yAxisMap?.map]);

  return {
    getOptions: getOptions,
    labels: labels,
    getDataLineAndBar: getDataLineAndBar,
    getDataPieAndDoughnut: getDataPieAndDoughnut,
    getDataScatterPlot: getDataScatterPlot
  };
}

function insertScatterPlotDataSet(
  xField: DefnField,
  yAxis: DefnStudioMapOfChartYAxis,
  defnForm: DefnForm,
  valueMap: FieldValues,
  datasets: ChartDataset<"scatter">[])
{
  loopKeysMap(yAxis, (_, sig, index) =>
  {
    const yField = defnForm.compMap[sig.fieldId] as DefnField | undefined;

    if(!datasets[index])
    {
      datasets[index] = {data: []};
    }

    if(yField && YAxisFieldType.includes(yField?.type) && isNumericField(xField.type))
    {
      const xValue = fnFieldValueToRawValue(xField.type, valueMap[xField.metaId]) as number;
      const yValue = fnFieldValueToRawValue(yField.type, valueMap[yField.metaId]) as number;
      if(yValue !== undefined && xValue !== undefined)
      {
        datasets[index].data.push({
          x: parseFloat(xValue.toString()),
          y: parseFloat(yValue.toString())
        });
      }
    }
    // @ts-ignore
    insertDataSetBgColorAndLabel(datasets[index] as IChartDataSet, index, sig, yField);
  });
}

function insertDataSetForYAxisMap(
  xField: DefnField,
  yAxis: DefnStudioMapOfChartYAxis,
  defnForm: DefnForm,
  valueMap: FieldValues,
  datasets: IChartDataSet[])
{
  loopKeysMap(yAxis, (_, sig, index) =>
  {
    const yField = defnForm.compMap[sig.fieldId] as DefnField | undefined;

    if(!datasets[index])
    {
      datasets[index] = {dataMap: {}};
    }
    insertDataSetBgColorAndLabel(datasets[index], index, sig, yField);
    insertDataSetValue(datasets[index], xField, yField, valueMap);
  });
}

function insertDataSetValue(
  dataSet: IChartDataSet,
  xField: DefnField,
  yField?: DefnField,
  valueMap?: FieldValues)
{
  if(isEmpty(dataSet.dataMap))
  {
    dataSet.dataMap = {};
  }

  const xLabel = getFieldValue(xField, valueMap);
  const yLabel = yField ? fnFieldValueToRawValue(yField?.type, valueMap?.[yField?.metaId]) as number : undefined;

  if(xLabel)
  {
    if(yField)
    {
      if(YAxisFieldType.includes(yField.type))
      {
        if(yLabel)
        {
          if(!dataSet.dataMap[xLabel])
          {
            dataSet.dataMap[xLabel] = 0;
          }
          dataSet.dataMap[xLabel] += parseFloat(yLabel.toString());
        }
      }
    }
    else
    {
      if(!dataSet.dataMap[xLabel])
      {
        dataSet.dataMap[xLabel] = 0;
      }
      dataSet.dataMap[xLabel] += 1;
    }
  }
}

function insertDataSetBgColorFromXAxisColorVar(
  dataSet: IChartDataSet,
  labels: string[],
  xAxisMap?: DefnStudioMapOfChartXAxis,
  yField?: DefnField)
{
  const label = yField?.label ? yField.label : yField?.name ? toLabel(yField?.name) : "YAxis";

  if(!dataSet.backgroundColor)
  {
    if(labels.length)
    {
      dataSet.backgroundColor = [];
      dataSet.borderColor = [];
    }

    labels.forEach((label, index) =>
    {
      const xAxisKey = xAxisMap?.keys.find((key) =>
      {
        return xAxisMap?.map[key]
          ? xAxisMap?.map[key]?.valueOptionId === label
          : xAxisMap?.keys[0];
      });

      const xAxis = xAxisKey ? xAxisMap?.map[xAxisKey] : undefined;
      const xAxisColor = xAxis?.color || xAxis?.colorVar;

      const chartColor = getChartColor(index, xAxisColor);
      if(chartColor)
      {
        const backgroundColor = chartColor.backgroundColor as string;
        const borderColor = chartColor.borderColor;
        if(backgroundColor)
        {
          (dataSet.backgroundColor as string[]).push(backgroundColor);
        }
        if(borderColor)
        {
          (dataSet.borderColor as string[]).push(borderColor);
        }
      }
    });
  }
  dataSet.label = label;
}

function insertDataSetBgColorAndLabel(
  dataSet: IChartDataSet,
  index: number,
  sig?: DefnDtoChartYAxis,
  yField?: DefnField)
{
  if(!dataSet?.backgroundColor || !dataSet.label)
  {
    const label = yField?.label ? yField.label : yField?.name ? toLabel(yField?.name) : "rows";
    const chartColor = getChartColor(index, sig?.color || sig?.colorVar);

    dataSet.label = label;
    dataSet.backgroundColor = chartColor.backgroundColor as string;
    dataSet.borderColor = chartColor.borderColor;
  }
}

function getXEnumLabels(field: DefnField, values: IChartValues, xAxisMap?: DefnStudioMapOfChartXAxis): string[]
{
  const enumOptions = getEnumFieldOptions(field.type);
  if(enumOptions)
  {
    return enumOptions;
  }
  if(field.type === "pickText")
  {
    const optionMap = (field as DefnFieldPickText).optionMap;

    return optionMap?.keys.map((key) =>
    {
      const optionValue = optionMap.map[key].value;
      const matchingKey = xAxisMap?.keys.find(xKey => xAxisMap.map[xKey]?.valueOptionId === optionValue);
      const xAxisLabel = matchingKey ? xAxisMap?.map[matchingKey].label : undefined;

      return xAxisLabel || optionValue;
    }) || [];
  }
  else
  {
    const labels = new Set<string>();
    loopKeysMap(values, (_: RowId, gridValue: FieldDtoGridRow) =>
    {
      const valueMap = gridValue.valueMap || {};
      const xLabel = getFieldValue(field, valueMap);
      if(xLabel)
      {
        labels.add(xLabel);
      }
    });
    return [...labels];
  }
}

function getEnumFieldOptions(fieldType: EnumDefnCompType)
{
  switch(fieldType)
  {
    case "month":
      return EnumArrayDefnMonth;
    default:
      return undefined;
  }
}

function generatedLabels(chart: Chart, yAxisMap?: DefnStudioMapOfChartYAxis): LegendItem[]
{
  return chart.config.data.datasets.map((value, index) =>
    {
      const meta = chart.getDatasetMeta(index);

      const yAxisKey = yAxisMap?.keys[index];
      const yAxis = yAxisKey ? yAxisMap?.map[yAxisKey] : undefined;
      const yCol = yAxis?.color || yAxis?.colorVar;
      const fillStyle = getChartColor(index, yCol);

      return {
        text: value.label,
        fillStyle: fillStyle.backgroundColor,
        strokeStyle: fillStyle.borderColor,
        hidden: meta.hidden
      } as LegendItem;
    }
  );
}

function onClickLegend(_: ChartEvent, legendItem: LegendItem, legend: any)
{
  const chart = legend.chart;
  const datasetIndex = chart.data.datasets.findIndex((dataset: any) => dataset.label === legendItem.text);

  if(datasetIndex !== -1 && chart.data.datasets[datasetIndex])
  {
    const meta = chart.getDatasetMeta(datasetIndex);
    meta.hidden = meta.hidden === null ? true : null;
    chart.update();
  }
}

function getOptions(allowAnimation?: boolean, layout?: DefnLayoutGrid)
{
  const chartType = layout?.kind;
  const yAxisMap = (layout as DefnLayoutGridXYChart).yAxisMap;

  const options: ChartOptions = {
    animation: {
      duration: !allowAnimation ? 0 : undefined
    },
    responsive: true,
    maintainAspectRatio: false
  };

  if(chartType !== "xyChartPieChart" && chartType !== "xyChartDoughnut")
  {
    options.plugins = {
      legend: {
        labels: {
          generateLabels(chart: Chart)
          {
            return generatedLabels(chart, yAxisMap);
          }
        },
        onClick: onClickLegend
      }
    };
  }

  return options;
}

function getChartColor(index: number, color?: DefnDtoColor)
{
  const defaultColor = labelColorSet[index] || labelColorSet.at(-1);
  const bgColor = getDefnDtoColorToCssColor(color) || defaultColor;
  const hoverBgColor = bgColor ? darken(bgColor as string, 0.2) : undefined;

  return {
    backgroundColor: bgColor,
    borderColor: hoverBgColor
  };
}

function getFieldValue(
  field?: DefnField,
  valueMap?: FieldValues)
{
  const metaId = field?.metaId;

  const yValue = (metaId && valueMap)
    ? valueMap[metaId]
    : undefined;
  return getFormFieldValueAsTextWithPrefixSuffix(field, yValue);
}

function getHitCount(
  xField: DefnField,
  values: IChartValues)
{
  const dataSet = {
    dataMap: {}
  } as IChartDataSet;
  loopKeysMap(values, (_: RowId, gridValue: FieldDtoGridRow) =>
  {
    const valueMap = gridValue.valueMap || {};
    insertDataSetBgColorAndLabel(dataSet, 0);
    insertDataSetValue(dataSet, xField, undefined, valueMap);
  });

  return dataSet;
}

