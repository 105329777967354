import {DtoCloneConfig} from "../../../api/core/base/dto/DtoCloneConfig";
import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {ArtifactId} from "../../../api/meta/base/Types";
import {EnumDefnSetupKind} from "../../../api/meta/base/Types";
import {StoreItemId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {MsgStoreItemCreate} from "../../../api/store/store/msg/MsgStoreItemCreate";
import {MsgStoreItemProvision} from "../../../api/store/store/msg/MsgStoreItemProvision";
import {RpcStore} from "../../../api/store/store/RpcStore";
import {SigStoreFilterList} from "../../../api/store/store/sig/SigStoreFilterList";
import ISrvc from "../../../base/ISrvc";
import {CbStoreItemEntGetSuccess} from "../../../cache/store/SrvcCacheStore";
import {setStoreItem} from "../../../cache/store/storeItem/SliceCacheStoreItem";
import {setupModeStoreItem} from "../../../cache/store/storeItem/SliceCacheStoreItem";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export class SrvcStoreItem extends ISrvc
{
  rpcStoreItemGet(storeItemId: StoreItemId, onSuccess?: CbStoreItemEntGetSuccess)
  {
    RpcStore.storeItemGet({storeItemId}, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }

      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setStoreItem(sig));
        onSuccess && onSuccess(sig.storeItem);
      }
    });
  }

  rpcStoreItemCreate(
    artifactId: ArtifactId,
    isSeedEnt?: boolean,
    cloneConfig?: DtoCloneConfig,
    cbSuccess?: () => void)
  {
    const msg = {
      isSeedEnt: isSeedEnt,
      cloneConfig: cloneConfig
    } as MsgStoreItemCreate;

    RpcStore.storeItemCreate(artifactId, msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }
      else
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcStoreItemRemove(storeItemId: StoreItemId, cbSuccess?: () => void)
  {
    RpcStore.storeItemRemove(storeItemId, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }
      else
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcStoreFilterListGet(version?: string, cbSuccess?: (sig: SigStoreFilterList) => void)
  {
    const msg = {
      version: version
    } as MsgVersion;

    RpcStore.storeFilterListGet(msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
      return;
    });
  }

  rpcStoreItemProvision(
    storeItemId: StoreItemId,
    includeData?: boolean,
    cloneConfig?: DtoCloneConfig,
    cbSuccess?: () => void)
  {
    const msg = {
      storeItemId: storeItemId,
      includeData: includeData,
      cloneConfig: cloneConfig
    } as MsgStoreItemProvision;

    RpcStore.storeItemProvision(msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }
      else
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  setSetupMode(storeItemId: StoreItemId, setupMode: EnumDefnSetupKind)
  {
    store.dispatch(setupModeStoreItem({
      storeItemId,
      setupMode
    }));
  }

}
