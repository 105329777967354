import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigLastMessage} from "../../../../api/home/main/sig/SigLastMessage";
import {ChatId, EntId} from "../../../../api/meta/base/Types";
import {formatCaptionDateTime} from "../../../../base/plus/DatePlus";
import {toComboId} from "../../../../base/types/TypesComboId";
import {ILastMessage} from "./TypesCacheHomeDrawerMsgLast";
import {ICacheLastMessageState} from "./TypesCacheHomeDrawerMsgLast";

export const sliceCacheHomeDrawerMsgLast = createSlice({
  name: "cacheHomeDrawerMsgLast",
  initialState: {
    lastMessageMap: {}
  } as ICacheLastMessageState,
  reducers: {
    setLastMessage: (state, action: PayloadAction<SigLastMessage>) =>
    {
      const sig = action.payload;
      const entChatId = toComboId(sig.entId, sig.chatId);

      state.lastMessageMap[entChatId] = {
        ...sig,
        messageTimeStr: formatCaptionDateTime(new Date(sig.messageTime))
      } as ILastMessage;
    },
    removeLastMessage: (state, action: PayloadAction<{entId: EntId, chatId: ChatId}>) =>
    {
      const payload = action.payload;
      const entChatId = toComboId(payload.entId, payload.chatId);
      delete state.lastMessageMap[entChatId];

    }
  }
});

export const {
  setLastMessage,
  removeLastMessage
} = sliceCacheHomeDrawerMsgLast.actions;
