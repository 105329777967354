import {UnknownAction} from "@reduxjs/toolkit";
import {combineReducers} from "@reduxjs/toolkit";
import {Action, configureStore, ThunkAction} from "@reduxjs/toolkit";
import {SigUserAvatar} from "./api/home/drawer/sig/SigUserAvatar";
import {ENT_ID_GLOBAL} from "./api/meta/base/ApiPlus";
import {EntId} from "./api/meta/base/Types";
import {STR_YOU} from "./base/plus/ConstantsPlus";
import {textUser} from "./base/plus/SrvcPlus";
import {reducersCache} from "./cache/StoreCache";
import {reducersProduction} from "./srvc/adminPanel/StoreAdminPanel";
import {reducersApiBr} from "./srvc/apiBr/StoreApiBr";
import {reducersApp} from "./srvc/app/StoreApp";
import {reducersDeeplink} from "./srvc/deeplink/StoreDeeplink";
import {reducersHome} from "./srvc/home/StoreHome";
import {reducersStore} from "./srvc/store/StoreStore";
import {reducersStudio} from "./srvc/studio/StoreStudio";

const appReducer = combineReducers({
  app: reducersApp(),
  cache: reducersCache(),
  deeplink: reducersDeeplink(),
  home: reducersHome(),
  studio: reducersStudio(),
  apiBr: reducersApiBr(),
  store: reducersStore(),
  adminPanel: reducersProduction()
});

const resetStoreAction = {
  type: "resetStore",
  payload: undefined
};

export const store = configureStore({
  reducer: (state: ReturnType<typeof appReducer> | undefined, action: UnknownAction) =>
  {
    if(action.type === resetStoreAction.type)
    {
      return appReducer(undefined, {type: ""});
    }
    else
    {
      return appReducer(state, action);
    }
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;

export function resetStore()
{
  store.dispatch(resetStoreAction);
}

export function textUserOrYou(rootState: RootState, sig: SigUserAvatar)
{
  return isYou(rootState, sig) ? STR_YOU : textUser(sig);
}

export function getCallerEntUserId(rootState: RootState, entId: EntId)
{
  return rootState.cache.app.caller.entUserIdMap[entId];
}

export function getCallerEntUserIdGlobal(rootState: RootState)
{
  return getCallerEntUserId(rootState, ENT_ID_GLOBAL);
}

export function isYou(rootState: RootState, sig: SigUserAvatar): boolean
{
  return sig.entUserId === getCallerEntUserId(rootState, sig.entId);
}
