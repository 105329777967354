import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {clone} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldDynamic} from "../../../../api/meta/base/dto/DefnFieldDynamic";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueDynamic} from "../../../../api/meta/base/dto/FieldValueDynamic";
import {DynamicTypeRule} from "../../../../api/meta/base/StudioSetsFieldType";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {getDynamicValueFieldId} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldFactory from "../base/FieldFactory";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldDynamic(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnFieldDynamic,
})
{
  const defn = props.defn;

  const formCtx = useFormCtx();
  const hookFormCtx = useFormContext();
  const [fieldCompType, setFieldCompType] = useState<EnumDefnCompType>();

  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldValueId = getDynamicValueFieldId(fieldId);

  const fieldVariant = defnTheme.fieldVariant;

  const watch = hookFormCtx.watch(fieldValueId);
  const fieldDynamicArgs = formCtx.getFieldDynamicFieldArgs(fieldId);
  const fieldType = fieldDynamicArgs?.fieldType;
  const optionMap = fieldDynamicArgs?.optionMap;

  useEffect(() =>
  {
    if(fieldType && fieldType !== fieldCompType)
    {
      setFieldCompType(fieldType);
    }
  }, [fieldType]);

  useEffect(() =>
  {
    if(fieldCompType)
    {
      hookFormCtx.resetField(fieldId);
      hookFormCtx.resetField(fieldValueId);
    }

  }, [fieldCompType]);

  useEffect(() =>
  {
    switch(fieldCompType)
    {
      case "bool":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("bool", watch) ? "Yes" : "No",
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;
      case "date":
      case "dateTime":
      case "text":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("text", watch),
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;
      case "decimal":
      case "number":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("number", watch)?.toString(),
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;
      case "pickText":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("pickText", watch),
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;

      default:
        hookFormCtx.resetField(fieldId);
        break;
    }
  }, [watch]);

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);

        return (
          <FieldRawTemplate
            defn={
              {
                ...defn,
                pr: defn.pr ?? 0,
                pl: defn.pl ?? 0,
                pt: defn.pr ?? 0,
                pb: defn.pb ?? 0
              } as DefnFieldDynamic
            }
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <FieldRawDynamicField
                {...props}
                fieldId={fieldId}
                dynamicFieldType={fieldCompType}
                optionMap={optionMap}
              />
              {isError &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};

function FieldRawDynamicField(props: {
  fieldId: string,
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnFieldDynamic,
  dynamicFieldType?: EnumDefnCompType,
  optionMap?: DefnStudioMapOfDtoOption
})
{
  const defn = props.defn;
  const fieldId = getDynamicValueFieldId(props.fieldId);
  const defnForm = props.defnForm;
  const defnTheme = props.defnTheme;
  const optionMap = props.optionMap;
  let dynamicFieldType = props.dynamicFieldType;

  const defnComp = clone(defn);

  if(!dynamicFieldType || !DynamicTypeRule.includes(dynamicFieldType))
  {
    defnComp.disabled = true;
    dynamicFieldType = "text";
  }

  defnComp.type = dynamicFieldType;
  (defnComp as DefnFieldUi)._key = fieldId;
  (defnComp as DefnFieldUi).metaId = fieldId;
  defnForm.compMap[fieldId] = defnComp;

  if(dynamicFieldType === "pickText")
  {
    (defnComp as DefnFieldPickText).optionMap = optionMap;
  }

  return (
    <FieldFactory
      defnForm={defnForm}
      defnTheme={defnTheme}
      defnComp={defnComp}
    />
  );
}


