import {Property} from "csstype";
import {useMemo} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {FieldValues} from "react-hook-form";
import {DefnFieldFormListItem} from "../../../../api/meta/base/dto/DefnFieldFormListItem";
import {FieldValueAudio} from "../../../../api/meta/base/dto/FieldValueAudio";
import {FieldValueCamera} from "../../../../api/meta/base/dto/FieldValueCamera";
import {FieldValueImage} from "../../../../api/meta/base/dto/FieldValueImage";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {FieldValueVideo} from "../../../../api/meta/base/dto/FieldValueVideo";
import {FieldValueVoice} from "../../../../api/meta/base/dto/FieldValueVoice";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {STR_LIST_ITEM_MEDIA_PLACEHOLDER_SRC} from "../../../../base/plus/ConstantsPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {IBubbleRawMediaCarouselItem} from "../../../atom/bubble/raw/BubbleRawMediaCarousel";
import BubbleRawMediaCarouselFactory from "../../../atom/bubble/raw/BubbleRawMediaCarouselFactory";
import Carousel from "../../../atom/carousel/Carousel";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawLazyImage from "../../../atom/raw/RawLazyImage";
import {useFieldPathValueListItem} from "./FieldFormListItem";
import {getFieldIdPath} from "./FieldFormListItem";

const PLACEHOLDER_BORDER_RADIUS = 6;

export const listImageMinHeight = 120;

export function ListItemCarousel(props: {
  defnForm: DefnFormUi,
  defn: DefnFieldFormListItem
})
{
  const defn = props.defn;
  const defnForm = props.defnForm;
  const isCard = defn.isCard;
  const layout = defn.layout;
  const hookFormCtx = useFormContext();

  const showAsCard = defn.isCard;
  const imageCornerVar = layout?.imageCornerVar;
  const defnBorderRadius = isCard ? [6, 0] : [6];
  const showMediaPlaceholder = true;

  const mediaWidth = isCard
    ? (imageCornerVar === "circle"
      ? theme.common.gridImageMinHeight
      : undefined)
    : listImageMinHeight;
  const mediaHeight = isCard
    ? theme.common.gridImageMinHeight
    : listImageMinHeight;

  const fieldId = getFieldKey(defn);
  const getFieldIdPathValue = useFieldPathValueListItem();
  const listItemValueMap = getFieldIdPathValue(fieldId);

  const rowIdPath = getFieldIdPath(fieldId);
  const rowIdValueMap = rowIdPath.slice(0, rowIdPath.length - 2).reduce((previousValue, currentValue) =>
  {
    return previousValue?.[currentValue];
  }, hookFormCtx.getValues(rowIdPath[0]));

  const valueMap = useMemo(() => ({
    ...listItemValueMap,
    ...rowIdValueMap
  }), [listItemValueMap, rowIdValueMap]);

  const mediaList = useMemo(() =>
  {
    return getMedia(defnForm, valueMap, defn, mediaWidth, mediaHeight);
  }, [defn, defnForm, mediaHeight, mediaWidth, valueMap]);

  const borderRadius: Property.BorderRadius | undefined = useMemo(() =>
  {
    if(!imageCornerVar)
    {
      return undefined;
    }

    switch(imageCornerVar)
    {
      case "rectangle":
        return px(0);
      case "circle":
        return "100%";
      case "rounded":
      default:
        return px(PLACEHOLDER_BORDER_RADIUS);
    }
  }, [imageCornerVar]);

  if(!mediaList.length)
  {
    if(showMediaPlaceholder)
    {
      return <ShowMediaPlaceholder
        width={mediaWidth}
        height={mediaHeight}
        borderRadius={borderRadius}
      />;
    }
    return null;
  }

  if(mediaList.length === 1)
  {
    const media = mediaList[0];
    if(media.fieldValueImage || media.fieldValueVideo || media.fieldValueAudio
      || media.fieldValueHyperlink || media.fieldValueHtml)
    {
      return (
        <LayoutFlexCol
          width={"100%"}
          flexGrow={1}
        >
          <BubbleRawMediaCarouselFactory
            media={media}
            disableOnClick={true}
            borderRadius={borderRadius}
            {...!imageCornerVar && getBorderRadius(defnBorderRadius, showAsCard)}
          />
        </LayoutFlexCol>
      );
    }

    return <ShowMediaPlaceholder
      width={mediaWidth}
      height={mediaHeight}
      borderRadius={borderRadius}
    />;
  }

  return (
    <LayoutFlexCol
      width={"100%"}
      flexGrow={1}
    >
      <Carousel
        maxWidth={mediaWidth}
        height={mediaHeight}
        swipeAble={true}
        dotBgColor={"rgba(255, 255, 255, 0.4)"}
        borderRadius={borderRadius}
        {...!imageCornerVar && getBorderRadius(defnBorderRadius, showAsCard)}
      >
        {
          mediaList.map((value, index) =>
          {
            if(value.fieldValueImage || value.fieldValueVideo || value.fieldValueAudio
              || value.fieldValueHyperlink || value.fieldValueHtml)
            {
              return <React.Fragment key={index}>
                <BubbleRawMediaCarouselFactory
                  media={value}
                  disableOnClick={true}
                  borderRadius={borderRadius}
                  {...!imageCornerVar && getBorderRadius(defnBorderRadius, showAsCard)}
                />
              </React.Fragment>;
            }
            else
            {
              return <ShowMediaPlaceholder
                width={mediaWidth}
                height={mediaHeight}
                borderRadius={borderRadius}
              />;
            }
          })
        }
      </Carousel>
    </LayoutFlexCol>
  );
}

interface ICarouselBorderRadius
{
  borderRadius?: Property.BorderRadius,
  borderTopLeftRadius?: Property.BorderTopLeftRadius,
  borderTopRightRadius?: Property.BorderTopRightRadius,
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius,
  borderBottomRightRadius?: Property.BorderBottomRightRadius,
}

function getBorderRadius(borderRadius?: number[], showAsCard?: boolean): ICarouselBorderRadius | undefined
{
  if(showAsCard)
  {
    return {
      borderTopLeftRadius: (borderRadius?.length === 2 || borderRadius?.length === 4)
        ? px(borderRadius[0])
        : undefined,
      borderTopRightRadius: borderRadius?.length === 2
        ? px(borderRadius[0])
        : borderRadius?.length === 4
          ? px(borderRadius[1])
          : undefined,
      borderBottomRightRadius: borderRadius?.length === 2
        ? px(borderRadius[1])
        : borderRadius?.length === 4
          ? px(borderRadius[2])
          : undefined,
      borderBottomLeftRadius: borderRadius?.length === 2
        ? px(borderRadius[2])
        : borderRadius?.length === 4
          ? px(borderRadius[3])
          : undefined
    };
  }
  else if(borderRadius?.length === 1)
  {
    return {
      borderRadius: px(borderRadius[0])
    };
  }
}

export function getMedia(
  defnForm: DefnFormUi,
  values: FieldValues,
  defn: DefnFieldFormListItem,
  width?: number,
  height?: number
): IBubbleRawMediaCarouselItem[]
{
  const medias = [] as IBubbleRawMediaCarouselItem[];
  const layout = defn.layout;
  const mediaFieldIdSet = layout?.mediaFieldIdSet;
  const mediaVarSet = layout?.mediaVarSet;
  const validCompTypes = ["image", "video", "audio", "hyperlink", "html", "camera"] as EnumDefnCompType[];

  if(mediaFieldIdSet?.length)
  {
    mediaFieldIdSet.forEach(fieldId =>
    {
      const fieldIdPath = getFieldIdPath(fieldId).at(-1);
      const fieldValue = fieldIdPath ? values[fieldIdPath] : undefined;

      const compType = defnForm.compMap[fieldId]?.type;

      if(!validCompTypes.includes(compType))
      {
        return;
      }

      if(!fieldValue)
      {
        return;
      }

      const media = {
        width: width,
        height: height,
        type: compType
      } as IBubbleRawMediaCarouselItem;

      switch(compType)
      {
        case "image":
          media.type = "image";
          media.fieldValueImage = fieldValue as FieldValueImage;
          break;
        case "video":
          media.type = "video";
          media.fieldValueVideo = fieldValue as FieldValueVideo;
          break;
        case "camera":
          media.type = "image";
          media.fieldValueImage = fieldValue as FieldValueCamera;
          break;
        case "audio":
        case "voice":
          media.type = compType;
          media.fieldValueAudio = fieldValue as FieldValueAudio | FieldValueVoice;
          break;
        case "hyperlink":
          media.type = "hyperlink";
          media.fieldValueHyperlink = fieldValue as FieldValueText;
          break;
        case "html":
          media.type = "html";
          media.fieldValueHtml = (fieldValue as FieldValueText)?.value
            ? {
              value: [(fieldValue as FieldValueText)?.value]
            }
            : undefined;
          break;
      }

      medias.push(media);
    });
  }
  else if(mediaVarSet?.length)
  {
    mediaVarSet.forEach((mediaVar) =>
    {
      const media = {
        width: width,
        height: height
      } as IBubbleRawMediaCarouselItem;

      if(mediaVar.image)
      {
        media.type = "image";
        media.fieldValueImage = {
          value: mediaVar.image
        };
      }
      else if(mediaVar.html)
      {
        media.type = "html";
        media.fieldValueHtml = mediaVar.html;
      }
      else if(mediaVar.hyperlink?.value)
      {
        media.type = "hyperlink";
        media.fieldValueHyperlink = {
          value: mediaVar.hyperlink.value
        };
      }
      medias.push(media);
    });
  }

  return medias;
}

function ShowMediaPlaceholder(props: {
  width?: number,
  height?: number,
  borderRadius?: Property.BorderRadius
})
{
  return <LayoutFlexCol
    width={"100%"}
    flexGrow={1}
  >
    <RawLazyImage
      src={STR_LIST_ITEM_MEDIA_PLACEHOLDER_SRC}
      width={px(props.width)}
      height={px(props.height)}
      objectFit={"cover"}
      borderRadius={props.borderRadius}
    />
  </LayoutFlexCol>;
}
