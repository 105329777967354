import _ from "lodash";
import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {SigStudioDeployedArtifactList} from "../../../api/studio/studioDrawer/sig/SigStudioDeployedArtifactList";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {dispatchListItemProduction} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {hasEditLockPlugin} from "../../../base/plus/StudioPlus";
import {hasEditLockEnt} from "../../../base/plus/StudioPlus";
import {listSetIfExistSecondaryLine} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetVersion} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinderTwo} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigProductionItemAvatar = SigStudioEntAvatar | SigStudioPluginAvatar | undefined;

const subscriberId = "SrvcAdminPanelDrawerRecentList";

export default class SrvcAdminPanelDrawerRecentList extends ISrvc
{
  selectList(state: RootState)
  {
    return state.adminPanel.drawer.productionRecentList;
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.onBindSourceItem.bind(this),
      selectSourceItem2: this.selectEditLock.bind(this),
      onBindSourceItem2: this.onBindEditLock.bind(this)
    } as IListBinderTwo<TypeSigProductionItemAvatar, boolean>;
  }

  subscribe(artifactId: ArtifactId, unSubscribe?: boolean)
  {
    if(isEntId(artifactId) || isPluginId(artifactId))
    {
      if(isEntId(artifactId))
      {
        Srvc.app.pubsub.caller.entAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
      else if(isPluginId(artifactId))
      {
        Srvc.app.pubsub.caller.pluginAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
  }

  load(listName: string, filter?: EntId[])
  {
    RpcStudioDrawer.studioDeployedArtifactListGet(envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }

      const artifactList = envSig.sig;

      if(artifactList)
      {
        this.doLoad(listName, artifactList, filter);
      }
    });
  }

  //region private

  private doLoad(listName: string, sig: SigStudioDeployedArtifactList, filter?: EntId[])
  {
    const entIdSet = sig.entIdSet ?? [];
    const pluginBundleIdSet = sig.pluginBundleIdSet ?? [];

    if(!filter || filter.length === 0)
    {
      const listData = {
        itemIds: [...entIdSet, ...pluginBundleIdSet],
        itemsById: {}
      } as IListData;

      Promise
      .resolve(dispatchList(listName, listRefresh(listData)))
      .then(() =>
      {
        dispatchList(listName, listSetVersion(random()));
      });

    }
    else
    {
      const listData = {
        itemIds: [..._.intersection(entIdSet, filter), ..._.intersection(pluginBundleIdSet, filter)],
        itemsById: {}
      } as IListData;
      Promise
      .resolve(dispatchList(listName, listRefresh(listData)))
      .then(() =>
      {
        dispatchList(listName, listSetVersion(random()));
      });
    }
  }

  private selectSourceItem(state: RootState, itemId: TypeListItemId)
  {
    if(isEntId(itemId))
    {
      return state.cache.app.caller.entIdAdminAvatarMap[itemId];
    }
    else
    {
      return state.cache.app.caller.pluginIdAdminAvatarMap[itemId];
    }
  }

  private onBindSourceItem(listName: string, itemId: TypeListItemId, avatar?: TypeSigProductionItemAvatar): void
  {
    dispatchListItemProduction(listName, itemId, avatar, true);
  }

  private selectEditLock(state: RootState, itemId: TypeListItemId)
  {
    if(isEntId(itemId))
    {
      return hasEditLockEnt(state, itemId);
    }
    else if(isPluginId(itemId))
    {
      return hasEditLockPlugin(state, itemId);
    }
  }

  private onBindEditLock(listName: string, itemId: TypeListItemId, hasLock?: boolean): void
  {
    if(isEntId(itemId) || isPluginId(itemId))
    {
      dispatchList(listName, listSetIfExistSecondaryLine({
        itemId: itemId,
        icon4: hasLock ? "unlock" : undefined
      }));
    }
  }

  //endregion
}
