import {FormControl} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import {useCallback} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickReportRow} from "../../../../api/meta/base/dto/DefnFieldPickReportRow";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldPickReportRow(props: {
  defn: DefnFieldPickReportRow
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const fieldId = getFieldKey(defn);

  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueText | null;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <RealPickReportRow
                defn={defn}
                field={field}
                error={error}
              />
            </FormControl>
          </FieldRawTemplate>
        );

      }}
    />
  );
}

function RealPickReportRow(props: {
  defn: DefnFieldPickReportRow,
  field: ControllerRenderProps,
  error?: FieldError,
})
{
  const hookFormCtx = useFormContext();
  const formCtx = useFormCtx();

  const defn = props.defn;
  const field = props.field;
  const error = props.error;

  const fieldValue = field.value as FieldValueText;
  const onChange = field.onChange;

  const defnTheme = formCtx.getDefnTheme();
  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);

  const searchText = formCtx.getSearchText();
  const variant = defnTheme.fieldVariant;
  const fieldVariant = variant === "standard" ? "outlined" : variant;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const getOption = formCtx.getOnGetFieldOptions();

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldShowAsDropdown
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const showAsDropdown = getFieldShowAsDropdown() as boolean;

  const [optionMap, setOptionMap] = useState<DefnStudioMapOfDtoOption>();
  const [loading, setLoading] = useState<boolean>(false);

  const onChangePicker = useCallback((value?: string | null) =>
  {
    onChange && onChange(fnRawValueToFieldValue("text", value) as FieldValueText | null);
  }, [onChange]);

  const onChangeText = useCallback((value?: FieldValueText | null) =>
  {
    if(!value || !value.value)
    {
      Object.keys(defn.copyFieldMap ?? {}).forEach(fieldId =>
      {
        hookFormCtx.setValue(fieldId, null);
      });
    }

    onChange && onChange(value);

  }, [onChange]);

  const onClick = useCallback(() =>
  {
    const getOnClick = formCtx.getOnClick();
    getOnClick?.(fieldId, "field", fieldValue);

  }, [fieldValue]);

  const onOpen = useCallback(() =>
  {
    if(showAsDropdown && getOption)
    {
      setLoading(true);
      getOption(fieldId, (options) =>
      {
        if(options)
        {
          setOptionMap(options as DefnStudioMapOfDtoOption);
        }
        setLoading(false);
      });
    }

  }, [fieldId, getOption, showAsDropdown]);

  const onBlur = useCallback(() =>
  {
    field.onBlur();
    setLoading(false);

  }, []);

  useEffect(() =>
  {
    onOpen();
  }, []);

  if(!showAsDropdown)
  {
    return (
      <FieldRawTextField
        defn={defn}
        value={fieldValue}
        onBlur={field.onBlur}
        error={error}
        ref={field.ref}
        onChange={onChangeText}
      />
    );
  }

  return (
    <RawPickOneAutoComplete
      optionMap={optionMap}
      value={fieldValue?.value}
      onChange={onChangePicker}
      label={label}
      disabled={disabled}
      readOnly={readOnly}
      error={error}
      helperText={helperText}
      placeHolder={placeHolder}
      required={required}
      onClick={onClick}
      onBlur={onBlur}
      searchWords={searchText ? [searchText] : undefined}
      fieldVariant={fieldVariant}
      fieldSize={defnTheme.fieldSize}
      hideLabel={defn.hideLabel}
      name={field.name}
      fieldId={fieldId}
      loading={loading}
      disableSelected={true}
      onOpen={onOpen}
    />
  );
}
