import {useState} from "react";
import React from "react";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import {IDialogAtomRef} from "./DialogAtom";
import {IDialogAtomFooterActionCheckbox} from "./DialogAtom";
import DialogAtom from "./DialogAtom";
import DialogTypography from "./DialogTypography";
import {IDialogMsg} from "./TypesDialog";

export const OK = "OK";
export const Cancel = "Cancel";
export const ConfirmRemoveValueKey = "ConfirmRemove";

export default function DialogConfirm(props: {
  msg: IDialogMsg | string,
  cbRef?: IDialogAtomRef,
  onOk?: () => void,
  title?: string,
  onCancel?: () => void
  fullScreen?: boolean,
  rightFooterActions?: string[],
  confirmRemove?: boolean
  leftFooterCheckBoxActions?: IDialogAtomFooterActionCheckbox[],
  onClickFooterAction?: (action: string) => void,
})
{
  const rightFooterActions = props.rightFooterActions ?? [Cancel, OK];

  const [confirmRemove, setConfirmRemove] = useState<boolean>(false);

  return (
    <DialogAtom
      cbRef={props.cbRef}
      title={props.title ?? "Confirm"}
      fullScreen={props.fullScreen}
      contentWidth={600}
      contentHeight={300}
      content={
        <LayoutFlexCol
          width={"100%"}
          flexGrow={1}
        >
          <LayoutFlexCol
            width={"90%"}
            flexGrow={1}
          >
            <DialogTypography msg={props.msg} />
          </LayoutFlexCol>
        </LayoutFlexCol>
      }
      leftFooterCheckBoxActions={props.leftFooterCheckBoxActions}
      onClickFooterCheckBoxAction={(action, checked) =>
      {
        if(action.value === ConfirmRemoveValueKey)
        {
          setConfirmRemove(checked);
        }
      }}
      rightFooterActions={rightFooterActions}
      disableRightFooterActions={(!confirmRemove && props.confirmRemove) ? [OK] : []}
      onClickFooterAction={(reason) =>
      {
        if(props.onClickFooterAction)
        {
          props.onClickFooterAction(reason);
        }
        else if(reason === OK)
        {
          props.onOk && props.onOk();
        }
        else
        {
          props.onCancel && props.onCancel();
        }
      }}
    />
  );
}

