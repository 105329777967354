import {Box} from "@mui/material";
import {Link} from "@mui/material";
import {Typography} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import DialogAtom from "./DialogAtom";

const bottomPadding = 32;
const fontSize = 20;
const contentWidth = 500;

export default function DialogDriveDownload(props: {
  onOk?: () => void,
  title?: string,
  fullScreen?: boolean,
})
{
  return (
    <DialogAtom
      title={props.title}
      fullScreen={props.fullScreen}
      contentWidth={contentWidth}
      content={
        <Box
          paddingY={px(gapQuarter)}
          justifyContent={"center"}
        >
          <LinkTypography
            tittle={"For Windows"}
            linkText={"https://web.neome.ai/static/media/agent-win.exe"}
          />

          <LinkTypography
            tittle={"For Mac/Linux"}
            linkText={"https://web.neome.ai/static/media/agent.zip"}
          />
        </Box>
      }
    />
  );
}

function LinkTypography(props: {tittle: string, linkText: string})
{
  const tittle = props.tittle;
  const linkText = props.linkText;

  return (
    <Box pb={px(bottomPadding)}>
      <Typography fontSize={fontSize}>{tittle}</Typography>
      <Link
        target={"_blank"}
        variant={"body1"}
        href={linkText}
        sx={{cursor: "pointer"}}
        color={theme.common.color("primary")}
      > {linkText}
      </Link>
    </Box>
  );
}

