import ISrvc from "../base/ISrvc";
import SrvcCacheAdminPanel from "./adminPanel/SrvcCacheAdminPanel";
import SrvcCacheApp from "./app/SrvcCacheApp";
import SrvcCacheHome from "./home/SrvcCacheHome";
import SrvcCacheStore from "./store/SrvcCacheStore";
import SrvcCacheStudio from "./studio/SrvcCacheStudio";
import SrvcCacheTools from "./tools/SrvcCacheTools";

export default class SrvcCache extends ISrvc
{
  public readonly app = new SrvcCacheApp();
  public readonly home = new SrvcCacheHome();
  public readonly studio = new SrvcCacheStudio();
  public readonly store = new SrvcCacheStore();
  public readonly adminPanel = new SrvcCacheAdminPanel();
  public readonly tools = new SrvcCacheTools();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.app,
      this.home,
      this.studio,
      this.store,
      this.adminPanel,
      this.tools
    );
  }
}
