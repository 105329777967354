import {StudioEntActionMap} from "../../../api/meta/base/dto/StudioEntActionMap";
import {StudioEntPromptMap} from "../../../api/meta/base/dto/StudioEntPromptMap";
import {StudioEntRoleMap} from "../../../api/meta/base/dto/StudioEntRoleMap";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../base/types/list/TypesListAPSA";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";

export default class SrvcStudioEntPrompts extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entPromptList;
  }

  loadPromptList(
    listName: string,
    promptMap?: StudioEntPromptMap,
    actionMap?: StudioEntActionMap,
    roleMap?: StudioEntRoleMap,
    readOnly?: boolean)
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(!promptMap)
    {
      return;
    }

    promptMap.keys.forEach((itemId) =>
    {
      const prompt = promptMap.map[itemId];
      uiItemIds.push(itemId);

      let actionName: string;
      if(prompt.actionId && actionMap && actionMap.map[prompt.actionId])
      {
        actionName = toLabel(actionMap.map[prompt.actionId].details.name);
      }
      else
      {
        actionName = "No action";
      }

      const promptText: ILineSecondary = {
        text: prompt.promptText?.value ?? ""
      };

      const permissionRoleIdSet: ILineSecondary = {};
      if(prompt.permissionRoleIdSet && prompt.permissionRoleIdSet.length > 0)
      {
        permissionRoleIdSet.text = prompt.permissionRoleIdSet.map(roleId =>
        {
          return roleMap?.map[roleId]?.details?.name;
        }).join(", ");
      }
      else
      {
        permissionRoleIdSet.text = "No roles";
        permissionRoleIdSet.color = "textDisabled";
      }

      uiItemsById[itemId] = {
        type: "mpsl",
        hideMenu: readOnly,
        primary: {
          text: prompt.name,
          caption: {
            text: actionName,
            ignoreSelection: true
          }
        },
        secondaryList: [
          permissionRoleIdSet,
          promptText
        ],
        userField: {
          value: prompt
        }
      } as IListItemMPSL;

    });

    dispatchList(listName, listRefresh({
      itemIds: uiItemIds,
      itemsById: uiItemsById
    }));

  }
}
