import {useDeviceData} from "react-device-detect";
import {NavigateFunction} from "react-router-dom";
import {getPrefix} from "../../api/meta/base/SysId";
import {SysId} from "../../api/meta/base/SysId";
import {nextId} from "../../api/meta/base/SysId";
import {PREFIX_META_ID_LAYOUT_DRIVE_SHEET} from "../../api/meta/base/Types";
import {PREFIX_META_ID_MODULE} from "../../api/meta/base/Types";
import {PREFIX_PLUGIN_ID} from "../../api/meta/base/Types";
import {PREFIX_META_ID_USER_CONDITION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_PIPELINE_VAR} from "../../api/meta/base/Types";
import {PREFIX_META_ID_FIELD_DYNAMIC_RULE} from "../../api/meta/base/Types";
import {PREFIX_META_ID_FIELD_DYNAMIC_CONDITION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_VISIBILITY_RULE} from "../../api/meta/base/Types";
import {PREFIX_META_ID_VISIBILITY_CONDITION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_VISIBILITY_ACTION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_VAR} from "../../api/meta/base/Types";
import {PREFIX_META_ID_TRANSLATION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_TAB} from "../../api/meta/base/Types";
import {PREFIX_META_ID_SWIMLANE} from "../../api/meta/base/Types";
import {PREFIX_META_ID_STEP} from "../../api/meta/base/Types";
import {PREFIX_META_ID_SPREADSHEET} from "../../api/meta/base/Types";
import {PREFIX_META_ID_SECTION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_ROLE} from "../../api/meta/base/Types";
import {PREFIX_META_ID_REPORT} from "../../api/meta/base/Types";
import {PREFIX_META_ID_PROMPT} from "../../api/meta/base/Types";
import {PREFIX_META_ID_PLUGIN} from "../../api/meta/base/Types";
import {PREFIX_META_ID_PARTITION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_OPTION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_MAPPING} from "../../api/meta/base/Types";
import {PREFIX_META_ID_LAYOUT_GRID} from "../../api/meta/base/Types";
import {PREFIX_META_ID_LAYOUT_FORM} from "../../api/meta/base/Types";
import {PREFIX_META_ID_HYPERLINK} from "../../api/meta/base/Types";
import {PREFIX_META_ID_HEADER} from "../../api/meta/base/Types";
import {PREFIX_META_ID_GROUP} from "../../api/meta/base/Types";
import {PREFIX_META_ID_GRID} from "../../api/meta/base/Types";
import {PREFIX_META_ID_FUNC_ARG} from "../../api/meta/base/Types";
import {PREFIX_META_ID_FORMULA} from "../../api/meta/base/Types";
import {PREFIX_META_ID_FORM} from "../../api/meta/base/Types";
import {PREFIX_META_ID_FOOTER} from "../../api/meta/base/Types";
import {PREFIX_META_ID_FIELD} from "../../api/meta/base/Types";
import {PREFIX_META_ID_EVENT} from "../../api/meta/base/Types";
import {PREFIX_META_ID_DRIVE_SHEET} from "../../api/meta/base/Types";
import {PREFIX_META_ID_DEEPLINK} from "../../api/meta/base/Types";
import {PREFIX_META_ID_CONDITION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_CODE} from "../../api/meta/base/Types";
import {PREFIX_META_ID_CHART_Y_AXIS} from "../../api/meta/base/Types";
import {PREFIX_META_ID_CHART_ITEM} from "../../api/meta/base/Types";
import {PREFIX_META_ID_AUTOMATION} from "../../api/meta/base/Types";
import {PREFIX_META_ID_ACTION} from "../../api/meta/base/Types";
import {MetaId} from "../../api/meta/base/Types";
import {EnumDefnDocFileExt} from "../../api/meta/base/Types";
import {ArrayPaymentFields} from "../../srvc/app/payment/TypesPayment";
import {STR_TRY_SIGNIN} from "./ConstantsPlus";
import {STR_APP_NAME} from "./ConstantsPlus";

export function getWindowName()
{
  return window.name;
}

export function setWindowName(): void
{
  window.name = STR_APP_NAME;
}

export function clearWindowName(): void
{
  window.name = "";
}

export function getWindowTrySignIn()
{
  // @ts-ignore
  return window[STR_TRY_SIGNIN];
}

export function setWindowTrySignIn(flag?: boolean): void
{
  // @ts-ignore
  window[STR_TRY_SIGNIN] = flag;
}

export function useDeviceInfo(): string
{
  const {
    browser,
    os
  } = useDeviceData(window.navigator.userAgent);
  return `${browser.name} ${browser.version} (${os.name} ${os.version})`;
}

export function isSslEnabled(): boolean
{
  // TODO: discuss with Bhavesh before changing this method
  // return isProd() || process.env.REACT_APP_SERVER_SSL_ENABLED === "true";

  const localStorageServerSSLEnable = localStorage.getItem("REACT_APP_SERVER_SSL_ENABLED");
  const sslEnable = localStorageServerSSLEnable || process.env.REACT_APP_SERVER_SSL_ENABLED;
  if(sslEnable === undefined || sslEnable === null)
  {
    return isProd();
  }
  return sslEnable === "true";
}

export function getHttpProtocol(): string
{
  return isSslEnabled() ? "https" : "http";
}

export function isProd(): boolean
{
  return process.env.NODE_ENV === "production";
}

export function isProdEnvironment(): boolean
{
  return isProd() && process.env.REACT_APP_ENVIRONMENT === "production";
}

export function getFileType(file: File)
{
  // this will return image,video,pdf...
  return file.type.split("/")[0];
}

export function getFileExt(file: File)
{
  // this will return png,jpg,mp4 ...
  const ext = file.name?.split(".").pop();
  return ext
    ? ext as EnumDefnDocFileExt
    : file.type.split("/")[1] as EnumDefnDocFileExt;
}

export function getFileSizeInMB(file: File): number
{
  return Math.ceil(file.size / 1024 / 1024);
}

export const FN_NOOP = () =>
{
};

const PREFIX_GENERIC_ID = "genericId";
const PREFIX_TREE_NODE_ID = "treeNodeId";

export function nextGenericId()
{
  return nextId<SysId>(PREFIX_GENERIC_ID);
}

export function nextTreeNodeId()
{
  return nextId<SysId>(PREFIX_TREE_NODE_ID);
}

export function isGenericId(sysId?: SysId): boolean
{
  return sysId
    ? getPrefix(sysId) === PREFIX_GENERIC_ID
    : false;
}

export function navigateRoute(route: string, navigate: NavigateFunction)
{
  navigate(route);
}

// Utility function to change 'dl' to 'preview' in the URL only work in development environment;
export const changeRouteToPreview = (path: string): string =>
{
  const parts = path.split("/");
  if(parts[1] === "dl")
  {
    parts[1] = "preview";
  }
  return parts.join("/");
};

interface IExcludeUpdateMetaIdsObjectKeys
{
  updateCurrentMetaId?: true;
}

export function updateAllMetaIds(stringifyJson: string): string
{
  const excludeObjects = excludeUpdateMetaIdsObjectKeys;
  const obj = JSON.parse(stringifyJson);
  const oldMetaIdToNewMetaIdMap = {} as Record<MetaId, MetaId>;

  JSON.stringify(obj, (key, value) =>
  {
    if(excludeObjects[key])
    {
      if(value && excludeObjects[key]?.updateCurrentMetaId)
      {
        const metaId = Object.hasOwn(value, "metaId")
          ? value.metaId
          : undefined;

        if(typeof metaId === "string")
        {
          const newMetaId = getNewMetaId(metaId);

          if(newMetaId)
          {
            oldMetaIdToNewMetaIdMap[metaId] = newMetaId;
          }
        }
      }

      return undefined;
    }

    if(key === "metaId"
      && typeof value === "string"
      && !ArrayPaymentFields.includes(value))
    {
      const newMetaId = getNewMetaId(value);

      if(newMetaId)
      {
        oldMetaIdToNewMetaIdMap[value] = newMetaId;
      }
    }

    return value;
  });

  let newStringifyJson = JSON.stringify(obj);

  Object.entries(oldMetaIdToNewMetaIdMap).forEach(([oldMetaId, newMetaId]) =>
  {
    newStringifyJson = newStringifyJson.replaceAll(oldMetaId, newMetaId);
  });

  return newStringifyJson;
}

function getNewMetaId(metaId: MetaId)
{
  const prefix = getPrefix(metaId);
  if(prefix && allMetaIdPrefixes[prefix])
  {
    return nextId<MetaId>(prefix);
  }
}

const excludeUpdateMetaIdsObjectKeys = {
  "actionPermissionMap": {},
  "studioPlugin": {
    updateCurrentMetaId: true
  },
  "pluginApiIdKeys": {},
  "pluginApiIdMap": {},
  "pluginFormKeys": {},
  "pluginFormMap": {},
  "pluginResourceMap": {}
} as Record<string, IExcludeUpdateMetaIdsObjectKeys>;

const allMetaIdPrefixes = {
  [PREFIX_PLUGIN_ID]: true,
  [PREFIX_META_ID_ACTION]: true,
  [PREFIX_META_ID_AUTOMATION]: true,
  [PREFIX_META_ID_CHART_ITEM]: true,
  [PREFIX_META_ID_CHART_Y_AXIS]: true,
  [PREFIX_META_ID_CODE]: true,
  [PREFIX_META_ID_CONDITION]: true,
  [PREFIX_META_ID_DEEPLINK]: true,
  [PREFIX_META_ID_DRIVE_SHEET]: true,
  [PREFIX_META_ID_EVENT]: true,
  [PREFIX_META_ID_FIELD]: true,
  [PREFIX_META_ID_FIELD_DYNAMIC_CONDITION]: true,
  [PREFIX_META_ID_FIELD_DYNAMIC_RULE]: true,
  [PREFIX_META_ID_MODULE]: true,
  [PREFIX_META_ID_FOOTER]: true,
  [PREFIX_META_ID_FORM]: true,
  [PREFIX_META_ID_FORMULA]: true,
  [PREFIX_META_ID_FUNC_ARG]: true,
  [PREFIX_META_ID_GRID]: true,
  [PREFIX_META_ID_GROUP]: true,
  [PREFIX_META_ID_HEADER]: true,
  [PREFIX_META_ID_HYPERLINK]: true,
  [PREFIX_META_ID_LAYOUT_DRIVE_SHEET]: true,
  [PREFIX_META_ID_LAYOUT_FORM]: true,
  [PREFIX_META_ID_LAYOUT_GRID]: true,
  [PREFIX_META_ID_MAPPING]: true,
  [PREFIX_META_ID_OPTION]: true,
  [PREFIX_META_ID_PARTITION]: true,
  [PREFIX_META_ID_PLUGIN]: true,
  [PREFIX_META_ID_PIPELINE_VAR]: true,
  [PREFIX_META_ID_PROMPT]: true,
  [PREFIX_META_ID_REPORT]: true,
  [PREFIX_META_ID_ROLE]: true,
  [PREFIX_META_ID_SECTION]: true,
  [PREFIX_META_ID_SPREADSHEET]: true,
  [PREFIX_META_ID_STEP]: true,
  [PREFIX_META_ID_SWIMLANE]: true,
  [PREFIX_META_ID_TAB]: true,
  [PREFIX_META_ID_TRANSLATION]: true,
  [PREFIX_META_ID_USER_CONDITION]: true,
  [PREFIX_META_ID_VAR]: true,
  [PREFIX_META_ID_VISIBILITY_ACTION]: true,
  [PREFIX_META_ID_VISIBILITY_CONDITION]: true,
  [PREFIX_META_ID_VISIBILITY_RULE]: true
} as Record<MetaId, boolean>;

