import {Typography} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldSetOfText} from "../../../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldSetOfOptionId} from "../../../../api/meta/base/dto/FieldSetOfOptionId";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {EnumDefnThemePickMultiVariant} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {EnumDefnThemeSectionVariant} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import theme from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawPickManyAutoComplete from "../../../atom/raw/RawPickManyAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawCheckboxGroup from "../raw/FieldRawCheckboxGroup";
import FieldRawTemplate from "../raw/FieldRawTemplate";

type TypeOnChange = (value: FieldSetOfOptionId | null) => void;
export default function FieldSetOfText(props: {
  defn: DefnFieldSetOfText
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const isReport = defnTheme.formVariant === "report";

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);

  const label = getCompLabel(defn);
  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();

  const sectionVariant = formSection.sectionVariant;
  const variant = defnTheme.fieldVariant;
  const fieldVariant = variant === "standard" ? "outlined" : variant;

  const readOnly = formCtx.isReadonly() || !Boolean(defn.metaId);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;

  const helperText = getFieldHelperText();
  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
        const onChange: TypeOnChange = field.onChange;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={field.value}
            reportNode={isReport ? <ReportNode defn={defn} fieldValue={field.value} /> : undefined}
          >
            <FactoryFieldPickType
              pickVariant={defn.showAs}
              defn={defn}
              hideLabel={defn.hideLabel}
              fieldVariant={fieldVariant}
              sectionVariant={sectionVariant}
              fieldId={fieldId}
              optionMap={defn.optionMap}
              label={defn.hideLabel ? undefined : label}
              placeHolder={placeHolder}
              required={required}
              helperText={helperText}
              icon={icon}
              error={error}
              fieldSize={defnTheme.fieldSize}
              onClick={onClick}
              onChange={onChange}
              onBlur={field.onBlur}
              readOnly={readOnly}
              disabled={disabled}
              field={field}
            />
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function ReportNode(props: {
  defn: DefnField,
  fieldValue?: any,
})
{
  const defn = props.defn;
  const fieldValue = props.fieldValue as FieldSetOfOptionId | undefined;
  const optionKeys = (defn as DefnFieldSetOfText).optionMap?.keys.filter(key => fieldValue?.valueSet.includes(key));
  const texts = getFormFieldValueAsTextWithPrefixSuffix(defn, fieldValue)?.split(", ");

  return <LayoutFlexRow>
    {
      optionKeys?.map((optionKey, index) =>
      {
        const text = texts?.[index];
        const option = optionKey ? (defn as DefnFieldSetOfText).optionMap?.map[optionKey] : undefined;

        return <Typography
          component={"span"}
          whiteSpace={"pre"}
          variant={theme.common.reportFieldLabelVariant}
          color={theme.common.colorWithShade(option?.color?.value || "textSecondary", option?.color?.shade)}
        >
          {text + (optionKeys.length - 1 > index ? ", " : "")}
        </Typography>;
      })
    }
  </LayoutFlexRow>;
}

function FactoryFieldPickType(props: {
  pickVariant?: EnumDefnThemePickMultiVariant,
  defn: DefnFieldSetOfText,
  field: ControllerRenderProps<FieldValues, string>
  fieldId: MetaIdField,
  optionMap?: DefnStudioMapOfDtoOption,
  fieldVariant?: EnumDefnThemeFieldVariant;
  sectionVariant?: EnumDefnThemeSectionVariant;
  fieldSize?: EnumDefnThemeFieldSize;
  error?: FieldError,
  value?: MetaIdOption[],
  helperText?: string,
  required?: boolean,
  label?: string,
  placeHolder?: string,
  hideLabel?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  icon?: string,
  onClick?: () => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: TypeOnChange
})
{

  const formCtx = useFormCtx();

  const defn = props.defn;
  const field = props.field;
  const [optionMap, setOptionMap] = useState<DefnStudioMapOfDtoOption | undefined>(props.optionMap);
  const [loading, setLoading] = useState<boolean>(false);
  const value = field.value as FieldSetOfOptionId | undefined;
  const fieldValue: MetaIdOption[] = fieldValueToPickMany(value as FieldSetOfOptionId);
  const getOption = formCtx.getOnGetFieldOptions();

  const onChangePickMany = (value: MetaIdOption[] | null) =>
  {
    if(props.onChange && optionMap)
    {
      if(value === null)
      {
        props.onChange(null);
      }
      else
      {
        props.onChange(pickManyToFieldValue(optionMap, value));
      }
    }
  };

  const onClick = () =>
  {

    props.onClick && props.onClick();
  };
  const onOpen = () =>
  {
    if(defn.pluginApi)
    {
      setLoading(true);
      getOption && getOption(props.fieldId, options =>
      {
        if(options)
        {
          setOptionMap(options as DefnStudioMapOfDtoOption);
        }
        setLoading(false);
      });
    }
    else
    {
      setOptionMap(props.optionMap);
    }
  };
  useEffect(() =>
  {
    if(defn.pluginApi && !optionMap?.keys.length && value?.valueSet.length)
    {
      const options = {
        keys: [],
        map: {}
      } as DefnStudioMapOfDtoOption;
      value?.valueSet.forEach((optionId, index) =>
      {
        options.keys.push(optionId);
        options.map[optionId] = {
          metaId: optionId,
          value: value.displaySet?.[index] || "",
          disabled: true
        } as DefnDtoOption;
      });
      setOptionMap(options);
    }
    if(defn.pluginApi && props.pickVariant && props.pickVariant !== "dropdown")
    {
      onOpen();
    }
  }, []);

  useEffect(() =>
  {
    if(!defn.pluginApi)
    {
      setOptionMap(props.optionMap);
    }
  }, [props.optionMap]);

  switch(props.pickVariant)
  {
    case "checkboxVertical":
    case "checkboxHorizontal":
      return <FieldRawCheckboxGroup
        {...props}
        value={fieldValue}
      />;
    default:
      return <RawPickManyAutoComplete
        {...props}
        optionMap={optionMap}
        name={props.field.name}
        showChip={true}
        onChange={onChangePickMany}
        value={fieldValue}
        onClick={onClick}
        loading={loading}
        icon={props.icon}
        onOpen={onOpen}
      />;
  }
}

function fieldValueToPickMany(value?: FieldSetOfOptionId): MetaIdOption[]
{
  const optionIdSet = [] as MetaIdOption[];
  value?.valueSet?.forEach(optionId =>
  {
    optionIdSet.push(optionId);
  });

  return optionIdSet;
}

function pickManyToFieldValue(optionMap: DefnStudioMapOfDtoOption, value: MetaIdOption[]): FieldSetOfOptionId
{
  const displaySet = [] as string[];
  value?.forEach(optionId =>
  {
    displaySet.push(optionMap.map[optionId].value);
  });

  return {
    valueSet: value,
    displaySet
  };
}

