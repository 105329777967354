import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldParagraph} from "../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../../api/meta/base/dto/DefnFieldText";
import {DefnFieldTextSize} from "../../../api/meta/base/dto/DefnFieldTextSize";
import {DefnSection} from "../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildColor} from "../../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioMapOfDtoOption} from "../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFieldId} from "../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutGridId} from "../../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnStudioPickLayoutSpreadsheetId} from "../../../api/meta/base/dto/DefnStudioPickLayoutSpreadsheetId";
import {DefnStudioPickVarId} from "../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnTab} from "../../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutCard} from "../../../api/meta/base/dto/StudioDtoLayoutCard";
import {StudioDtoLayoutCardItem} from "../../../api/meta/base/dto/StudioDtoLayoutCardItem";
import {StudioDtoLayoutCardItemLine} from "../../../api/meta/base/dto/StudioDtoLayoutCardItemLine";
import {StudioDtoLayoutCardItemLineSegment} from "../../../api/meta/base/dto/StudioDtoLayoutCardItemLineSegment";
import {StudioMapOfSwimlane} from "../../../api/meta/base/dto/StudioMapOfSwimlane";
import {MediaFieldType} from "../../../api/meta/base/StudioSetsFieldType";
import {CardFilterCategorySet} from "../../../api/meta/base/StudioSetsFieldType";
import {CardLineTextField} from "../../../api/meta/base/StudioSetsFieldType";
import {TextSize} from "../../../api/meta/base/StudioSetsFieldType";
import {SortBy} from "../../../api/meta/base/StudioSetsFieldType";
import {Color} from "../../../api/meta/base/StudioSetsFieldType";
import {ToolTip} from "../../../api/meta/base/StudioSetsFieldType";
import {CardFilterAdvanceSet} from "../../../api/meta/base/StudioSetsFieldType";
import {GroupBy} from "../../../api/meta/base/StudioSetsFieldType";
import {MediaVarType} from "../../../api/meta/base/StudioSetsVarType";
import {CardLineTextVar} from "../../../api/meta/base/StudioSetsVarType";
import {EnumDefnFields} from "../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../api/meta/base/Types";
import {EnumDefnLayoutGridKind} from "../../../api/meta/base/Types";
import {EnumArrayDefnCompType} from "../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {getCombinedFieldId} from "../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../base/plus/JsPlus";
import {hasValues} from "../../../base/plus/JsPlus";
import {optionsToMapOfOption} from "../../../base/plus/JsPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {SPREADSHEETS_LAYOUT_SORT_BY_FIELDS} from "../../atom/assets/HelperTextStudio";
import {SPREADSHEETS_LAYOUT_ADVANCE_FILTER_FIELDS} from "../../atom/assets/HelperTextStudio";
import {SPREADSHEETS_LAYOUT_CATEGORY_FIELDS} from "../../atom/assets/HelperTextStudio";
import {SPREADSHEETS_LAYOUT_SHOW_SEARCH_BAR} from "../../atom/assets/HelperTextStudio";
import {fieldGap} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap3} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap1} from "../../form/builder/base/TypesFormBuilder";
import {fieldKeyName} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap2} from "../../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../form/builder/base/TypesFormBuilder";
import {compFilterLayoutMode} from "../../form/builder/base/TypesFormBuilder";
import {fieldTabDetails} from "../../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription} from "../../form/builder/base/TypesFormBuilder";
import {fieldKeyLabel} from "../../form/builder/base/TypesFormBuilder";
import {getSwimlaneCompMap} from "./DialogPlus";
import {fieldToDateTimeSection} from "./TypesLayoutBuilder";
import {fieldTimeToField} from "./TypesLayoutBuilder";
import {fieldDateToField} from "./TypesLayoutBuilder";
import {fieldToLabel} from "./TypesLayoutBuilder";
import {fieldFromDateTimeSection} from "./TypesLayoutBuilder";
import {fieldTimeFormField} from "./TypesLayoutBuilder";
import {fieldDateFormField} from "./TypesLayoutBuilder";
import {fieldFormLabel} from "./TypesLayoutBuilder";
import {fieldLayoutSortOrder} from "./TypesLayoutBuilder";
import {fieldLayoutRenderingMode} from "./TypesLayoutBuilder";
import {TypeLayoutItemLineSegmentConstants} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaVariableImageType} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaFieldImageType} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaConstantImageType} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaCornerTypeDisableConstant} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaCornerTypeDisableVariable} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaConstantsDisable} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaConstant} from "./TypesLayoutBuilder";
import {fieldLayoutItemTypeConstants} from "./TypesLayoutBuilder";
import {fieldShowComments} from "./TypesLayoutBuilder";
import {fieldShowSectionName} from "./TypesLayoutBuilder";
import {fieldTabKanban} from "./TypesLayoutBuilder";
import {fieldSwimlaneLabel} from "./TypesLayoutBuilder";
import {fieldKanbanField} from "./TypesLayoutBuilder";
import {fieldFontSize} from "./TypesLayoutBuilder";
import {fieldShowFooter} from "./TypesLayoutBuilder";
import {fieldShowFields} from "./TypesLayoutBuilder";
import {fieldTitleField} from "./TypesLayoutBuilder";
import {fieldShowFieldIdSet} from "./TypesLayoutBuilder";
import {fieldTabCalendar} from "./TypesLayoutBuilder";
import {fieldColorField} from "./TypesLayoutBuilder";
import {fieldLayoutGroupByField} from "./TypesLayoutBuilder";
import {TypeLayoutItemLineSegmentVariables} from "./TypesLayoutBuilder";
import {TypeLayoutItemLineSegmentFields} from "./TypesLayoutBuilder";
import {TypeLineNumber} from "./TypesLayoutBuilder";
import {allowToSwitchLayoutsLabel} from "./TypesLayoutBuilder";
import {fieldSpreadsheetLayoutSwitcherIdSet} from "./TypesLayoutBuilder";
import {fieldGridLayoutSwitcherIdSet} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaVariablesDisable} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaCornerTypeDisableField} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaFieldsDisable} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaVariable} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaField} from "./TypesLayoutBuilder";
import {getLayoutItemSectionId} from "./TypesLayoutBuilder";
import {getLayoutItemTabId} from "./TypesLayoutBuilder";
import {TypeLayoutItemLineSegment} from "./TypesLayoutBuilder";
import {TypeLayoutItemLineSegmentType} from "./TypesLayoutBuilder";
import {TypeLayoutItemLine} from "./TypesLayoutBuilder";
import {fieldLayoutDetailsSection} from "./TypesLayoutBuilder";
import {fieldLayoutDetailTooltipField} from "./TypesLayoutBuilder";
import {fieldLayoutDetailBgColorField} from "./TypesLayoutBuilder";
import {fieldLayoutFilterSection} from "./TypesLayoutBuilder";
import {fieldLayoutFilterLayoutMode} from "./TypesLayoutBuilder";
import {fieldLayoutFilterShowSearchBar} from "./TypesLayoutBuilder";
import {fieldLayoutFilterCatFields} from "./TypesLayoutBuilder";
import {fieldLayoutFilterAdvanceFilterFields} from "./TypesLayoutBuilder";
import {fieldLayoutSortByFields} from "./TypesLayoutBuilder";
import {fieldLayoutItem} from "./TypesLayoutBuilder";
import {fieldLayoutItemTypeFields} from "./TypesLayoutBuilder";
import {fieldLayoutItemTypeVariables} from "./TypesLayoutBuilder";
import {fieldLayoutItemLine} from "./TypesLayoutBuilder";
import {fieldLayoutItemMiddle} from "./TypesLayoutBuilder";
import {fieldLayoutItemCaption} from "./TypesLayoutBuilder";
import {fieldLayoutItemMedia} from "./TypesLayoutBuilder";
import {fieldLayoutItemMediaCornerTypeField} from "./TypesLayoutBuilder";

// region LayoutBuilder
const getLineName = (lineName: TypeLineNumber) =>
{
  switch(lineName)
  {
    case "firstLine":
      return "First";
    case "secondLine":
      return "Second";
    case "thirdLine":
      return "Third";
    case "fourthLine":
      return "Fourth";
    case "fifthLine":
      return "Fifth";

  }
};

const getLineSegmentKeyField = (lineSegment: TypeLayoutItemLineSegment): TypeLayoutItemLineSegmentFields =>
{

  switch(lineSegment)
  {
    case "texts":
      return "lineFieldIdSet";
    case "colors":
      return "colorFieldId";
    case "fonts":
      return "fontFieldId";
    case "labels":
      return "showLabelsFieldId";
  }
};

const getLineSegmentKeyVariable = (lineSegment: TypeLayoutItemLineSegment): TypeLayoutItemLineSegmentVariables =>
{

  switch(lineSegment)
  {
    case "texts":
      return "lineVarId";
    case "colors":
      return "colorVarId";
    case "fonts":
      return "fontVarId";
    case "labels":
      return "showLabelsVarId";
  }
};

const getLineSegmentKeyConstant = (lineSegment: TypeLayoutItemLineSegment): TypeLayoutItemLineSegmentConstants =>
{

  switch(lineSegment)
  {
    case "texts":
      return "line";
    case "colors":
      return "color";
    case "fonts":
      return "font";
    case "labels":
      return "showLabels";
  }
};

type ILayoutType = "gridLayout" | "spreadsheetLayout"

function getLayoutItemMediaSectionKey(
  lineSegment: TypeLayoutItemLineSegment,
  lineSegmentType: TypeLayoutItemLineSegmentType)
{
  const fieldDefnFormLayoutItemMedia = getCombinedFieldId([
    fieldLayoutItem,
    lineSegment,
    lineSegmentType,
    fieldLayoutItemMedia
  ]);

  return getLayoutItemSectionId(fieldDefnFormLayoutItemMedia);
}

function getLayoutItemSectionKey(lineNumber: TypeLineNumber, lineSegment: TypeLayoutItemLineSegment,
  lineSegmentType: TypeLayoutItemLineSegmentType)
{
  return getLayoutItemSectionId(getCombinedFieldId([
    fieldLayoutItem,
    lineSegment,
    lineSegmentType,
    lineNumber
  ]));
}

function getLayoutItemSectionKeys(
  lineSegment: TypeLayoutItemLineSegment,
  lineSegmentType: TypeLayoutItemLineSegmentType): MetaIdField[]
{
  return [
    getLayoutItemSectionKey("firstLine", lineSegment, lineSegmentType),
    getLayoutItemSectionKey("secondLine", lineSegment, lineSegmentType),
    getLayoutItemSectionKey("thirdLine", lineSegment, lineSegmentType),
    getLayoutItemSectionKey("fourthLine", lineSegment, lineSegmentType),
    getLayoutItemSectionKey("fifthLine", lineSegment, lineSegmentType)
  ];
}

function getLayoutItemKey(
  lineNumber: TypeLineNumber,
  itemLine: TypeLayoutItemLine,
  lineSegment: TypeLayoutItemLineSegment,
  lineSegmentType: TypeLayoutItemLineSegmentType)
{

  const lineSegmentKey = () =>
  {
    switch(lineSegmentType)
    {
      case "fields":
        return getLineSegmentKeyField(lineSegment);
      case "variables":
        return getLineSegmentKeyVariable(lineSegment);
      case "constants":
        return getLineSegmentKeyConstant(lineSegment);
    }
  };

  return getCombinedFieldId([
    fieldLayoutItem,
    lineNumber,
    itemLine,
    lineSegmentKey()
  ]);
}

function getLayoutItemKeys(
  lineNumber: TypeLineNumber,
  lineSegment: TypeLayoutItemLineSegment,
  lineSegmentType: TypeLayoutItemLineSegmentType)
{
  const sectionKey = getLayoutItemSectionKey(lineNumber, lineSegment, lineSegmentType);
  const textKey = getLayoutItemKey(lineNumber, fieldLayoutItemLine, lineSegment, lineSegmentType);
  const middleKey = getLayoutItemKey(lineNumber, fieldLayoutItemMiddle, lineSegment, lineSegmentType);
  const captionKey = getLayoutItemKey(lineNumber, fieldLayoutItemCaption, lineSegment, lineSegmentType);

  return {
    sectionKey,
    textKey,
    middleKey,
    captionKey
  };
}

function getFilterFieldTypeSet(lineSegment: TypeLayoutItemLineSegment)
{
  switch(lineSegment)
  {
    case "texts":
      return CardLineTextField;
    case "colors":
      return Color;
    case "fonts":
      return TextSize;
  }
}

function getVarKindSet(lineSegment: TypeLayoutItemLineSegment)
{
  switch(lineSegment)
  {
    case "texts":
      return CardLineTextVar;
    case "colors":
      return "color";
    case "fonts":
      return "textSize";
  }
}

function getLayoutItemLineConstants(
  lineNumber: TypeLineNumber,
  lineTextSpan: number,
  layoutKind: EnumDefnLayoutGridKind,
  lineSegment: TypeLayoutItemLineSegment,
  metaIdForm?: MetaIdForm)
{
  const tabItemTabName: TypeLayoutItemLineSegmentType = fieldLayoutItemTypeConstants;
  const lineName = getLineName(lineNumber);

  if(lineName === undefined)
  {
    return {} as Record<MetaIdField, DefnField>;
  }

  const {
    sectionKey,
    textKey,
    middleKey,
    captionKey
  } = getLayoutItemKeys(lineNumber, lineSegment, tabItemTabName);

  return {

    ...Boolean(lineSegment === "labels" && lineNumber !== "firstLine")
      ? {
        [textKey]: {
          type: "bool",
          metaId: textKey,
          name: textKey,
          label: "Show labels",
          showAsCheckboxVar: true,
          pl: lineTextSpan === 1 ? 0 : undefined
        } as DefnFieldSwitch
      }
      : lineSegment === "texts" ? {
        [textKey]: {
          type: "text",
          metaId: textKey,
          name: textKey,
          label: `${lineName} line`
        } as DefnFieldText
      } : lineSegment === "colors" ? {
        [textKey]: {
          type: "studioBuildColor",
          metaId: textKey,
          name: textKey,
          label: `${lineName} line`,
          allowShades: true,
          direction: "horizontal"
        } as DefnStudioBuildColor
      } : lineSegment === "fonts" ? {
        [textKey]: {
          type: "textSize",
          metaId: textKey,
          name: textKey,
          label: `${lineName} line`
        } as DefnFieldTextSize
      } : {
        [textKey]: {
          type: "text",
          metaId: textKey,
          name: textKey,
          label: `${lineName} line`,
          disabled: true
        } as DefnFieldText
      },

    ...lineSegment === "texts" ? {
      [middleKey]: {
        type: "text",
        metaId: middleKey,
        name: middleKey,
        label: `${lineName} middle `
      } as DefnFieldText
    } : lineSegment === "colors" ? {
      [middleKey]: {
        type: "studioBuildColor",
        metaId: middleKey,
        name: middleKey,
        label: `${lineName} middle `,
        allowShades: true,
        direction: "horizontal"
      } as DefnStudioBuildColor
    } : lineSegment === "fonts" ? {
      [middleKey]: {
        type: "textSize",
        metaId: middleKey,
        name: middleKey,
        label: `${lineName} middle`
      } as DefnFieldTextSize
    } : {
      [middleKey]: {
        type: "textSize",
        metaId: middleKey,
        name: middleKey,
        label: `${lineName} middle`,
        disabled: true
      } as DefnFieldTextSize
    },

    ...lineSegment === "texts" ? {
      [captionKey]: {
        type: "text",
        metaId: captionKey,
        name: captionKey,
        label: `${lineName} caption `
      } as DefnFieldText
    } : lineSegment === "colors" ? {
      [captionKey]: {
        type: "studioBuildColor",
        metaId: captionKey,
        name: captionKey,
        label: `${lineName} caption `,
        allowShades: true,
        direction: "horizontal"
      } as DefnStudioBuildColor
    } : lineSegment === "fonts" ? {
      [captionKey]: {
        type: "textSize",
        metaId: captionKey,
        name: captionKey,
        label: `${lineName} caption`
      } as DefnFieldTextSize
    } : {
      [captionKey]: {
        type: "textSize",
        metaId: captionKey,
        name: captionKey,
        label: `${lineName} caption`,
        disabled: true
      } as DefnFieldTextSize
    },

    [sectionKey]: {
      type: "section",
      metaId: sectionKey,
      sectionDirection: "horizontal",
      pl: layoutKind === "card" ? gapStd : 0,
      pr: gapStd,
      fieldSpanMap: {
        [textKey]: 1,
        [middleKey]: 1,
        [captionKey]: 1
      },
      fieldIdSet: [
        textKey,
        middleKey,
        captionKey
      ]
    } as DefnSection

  } as Record<MetaIdField, DefnField>;
}

function getLayoutItemLineVariables(
  lineNumber: TypeLineNumber,
  lineTextSpan: number,
  layoutKind: EnumDefnLayoutGridKind,
  lineSegment: TypeLayoutItemLineSegment,
  metaIdForm?: MetaIdForm)
{
  const tabItemTabName: TypeLayoutItemLineSegmentType = fieldLayoutItemTypeVariables;
  const lineName = getLineName(lineNumber);

  if(lineName === undefined)
  {
    return {} as Record<MetaIdField, DefnField>;
  }

  const {
    sectionKey,
    textKey,
    middleKey,
    captionKey
  } = getLayoutItemKeys(lineNumber, lineSegment, tabItemTabName);

  return {

    [textKey]: {
      type: "pickVarId",
      metaId: textKey,
      metaIdForm: metaIdForm,
      name: textKey,
      label: `${lineName} line `,
      disabled: lineSegment === "labels",
      varKindSet: getVarKindSet(lineSegment),
      pl: lineTextSpan === 1 ? 0 : undefined
    } as DefnStudioPickVarId,

    [middleKey]: {
      type: "pickVarId",
      metaId: middleKey,
      metaIdForm: metaIdForm,
      name: middleKey,
      label: `${lineName} middle `,
      disabled: lineSegment === "labels",
      varKindSet: getVarKindSet(lineSegment)
    } as DefnStudioPickVarId,

    [captionKey]: {
      type: "pickVarId",
      metaId: captionKey,
      metaIdForm: metaIdForm,
      name: captionKey,
      label: `${lineName} caption `,
      isMultiSelect: false,
      disabled: lineSegment === "labels",
      varKindSet: getVarKindSet(lineSegment)
    } as DefnStudioPickVarId,

    [sectionKey]: {
      type: "section",
      metaId: sectionKey,
      sectionDirection: "horizontal",
      pl: layoutKind === "card" ? gapStd : 0,
      pr: gapStd,
      fieldSpanMap: {
        [textKey]: 1,
        [middleKey]: 1,
        [captionKey]: 1
      },
      fieldIdSet: [
        textKey,
        middleKey,
        captionKey
      ]
    } as DefnSection

  } as Record<MetaIdField, DefnField>;
}

function getLayoutItemLineFields(
  lineNumber: TypeLineNumber,
  lineTextSpan: number,
  lineSegment: TypeLayoutItemLineSegment,
  layoutKind: EnumDefnLayoutGridKind,
  metaIdForm?: MetaIdForm,
  excludeFieldIdSet?: MetaIdField[],
  compositeIdSet?: MetaIdComposite[],
  isOverride?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption)
{
  const tabItemTabName: TypeLayoutItemLineSegmentType = fieldLayoutItemTypeFields;
  const lineName = getLineName(lineNumber);

  if(lineName === undefined)
  {
    return {} as Record<MetaIdField, DefnField>;
  }
  const {
    sectionKey,
    textKey,
    middleKey,
    captionKey
  } = getLayoutItemKeys(lineNumber, lineSegment, tabItemTabName);

  const NonEditableFields = [
    "info",
    "error",
    "image",
    "label",
    "audio",
    "camera",
    "location",
    "signature",
    "video",
    "voice",
    "pickGridRow",
    "pickReportRow",
    "pickUser",
    "button",
    "divider",
    "dynamic",
    "error",
    "html",
    "identifier",
    "propertyMap",
    "ref",
    "refReport",
    "refUser",
    "rowId",
    "hyperlinkRow",
    "scanCode",
    "userId",
    "showCode",
    "setOfUser",
    "setOfText"
  ];

  const filterFieldTypeSet = isOverride
    ? getFilterFieldTypeSet(lineSegment)?.filter(type => !NonEditableFields.includes(type))
    : getFilterFieldTypeSet(lineSegment);

  return {
    [textKey]: {
      type: (lineNumber !== "firstLine" && lineSegment === "texts")
        ? "studioSetOfFieldId"
        : "pickFieldId",
      metaId: textKey,
      formId: metaIdForm,
      name: textKey,
      label: `${lineName} line `,
      disabled: Boolean(lineSegment === "labels"),
      filterFieldTypeSet: (filterFieldTypeSet && isOverride && lineNumber !== "firstLine")
        ? filterFieldTypeSet.filter(fieldType => fieldType !== "counter" && fieldType !== "logCounter")
        : filterFieldTypeSet,
      compositeIdSet: compositeIdSet,
      excludeFieldIdSet: excludeFieldIdSet,
      pl: lineTextSpan === 1 ? 0 : undefined,
      showCompositeName: isOverride,
      includeOptionMap: includeOptionMap
    } as DefnStudioPickFieldId,

    [middleKey]: {
      type: "pickFieldId",
      metaId: middleKey,
      formId: metaIdForm,
      name: middleKey,
      label: `${lineName} middle `,
      disabled: lineSegment === "labels",
      filterFieldTypeSet: filterFieldTypeSet,
      compositeIdSet: compositeIdSet,
      excludeFieldIdSet: excludeFieldIdSet,
      showCompositeName: isOverride,
      includeOptionMap: includeOptionMap
    } as DefnStudioPickFieldId,

    [captionKey]: {
      type: "pickFieldId",
      metaId: captionKey,
      formId: metaIdForm,
      name: captionKey,
      label: `${lineName} caption `,
      disabled: lineSegment === "labels",
      filterFieldTypeSet: filterFieldTypeSet,
      compositeIdSet: compositeIdSet,
      excludeFieldIdSet: excludeFieldIdSet,
      showCompositeName: isOverride,
      includeOptionMap: includeOptionMap
    } as DefnStudioPickFieldId,

    [sectionKey]: {
      type: "section",
      metaId: sectionKey,
      sectionDirection: "horizontal",
      pl: layoutKind === "card" ? gapStd : 0,
      pr: gapStd,
      fieldSpanMap: {
        [textKey]: 1,
        [middleKey]: 1,
        [captionKey]: 1
      },
      fieldIdSet: [
        textKey,
        middleKey,
        captionKey
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>;
}

export function getCompMapSpreadSheetBrTabDetails(
  spreadsheetId: MetaIdSpreadsheet,
  metaIdForm?: MetaIdForm,
  excludeSpreadsheetLayoutIdSet?: MetaIdLayoutGrid[],
  excludeFieldIdSet?: MetaIdField[],
  compositeIdSet?: MetaIdComposite[],
  layoutType?: EnumDefnLayoutGridKind | EnumDefnLayoutGridKind
)
{
  const section2 = "section2";
  const includeOptionSet: DefnDtoOption[] = [];
  EnumArrayDefnFields.forEach(optionId =>
  {
    if(optionId === "$CreatedBy" || optionId === "$UpdatedBy")
    {
      includeOptionSet.push({
        value: optionId,
        metaId: optionId
      });
    }
  });

  let fieldIdSet: string[];

  switch(layoutType)
  {
    case "calendar":
      fieldIdSet = [
        fieldLayoutDetailTooltipField,
        fieldGap2,
        fieldSpreadsheetLayoutSwitcherIdSet
      ];
      break;
    case "kanban":
      fieldIdSet = [
        fieldLayoutDetailTooltipField,
        fieldLayoutDetailBgColorField,
        fieldGap2,
        fieldSpreadsheetLayoutSwitcherIdSet
      ];
      break;
    default:
      fieldIdSet = [
        fieldLayoutDetailTooltipField,
        fieldLayoutDetailBgColorField,
        fieldGap2,
        fieldLayoutGroupByField,
        fieldSpreadsheetLayoutSwitcherIdSet
      ];

  }

  return {
    ...getCommonDetailsTab(),
    [fieldLayoutDetailTooltipField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailTooltipField,
      name: fieldLayoutDetailTooltipField,
      label: "Tooltip field",
      filterFieldTypeSet: ToolTip,
      formId: metaIdForm,
      compositeIdSet: compositeIdSet,
      excludeFieldIdSet: excludeFieldIdSet
    } as DefnStudioPickFieldId,
    [fieldLayoutDetailBgColorField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailBgColorField,
      name: fieldLayoutDetailBgColorField,
      label: "Background color field",
      filterFieldTypeSet: Color,
      formId: metaIdForm,
      compositeIdSet: compositeIdSet,
      excludeFieldIdSet: excludeFieldIdSet
    } as DefnStudioPickFieldId,

    ...getFieldGap(fieldGap2, "thick"),

    [fieldLayoutGroupByField]: {
      type: "pickFieldId",
      metaId: fieldLayoutGroupByField,
      name: fieldLayoutGroupByField,
      label: "Group by field",
      filterFieldTypeSet: GroupBy,
      formId: metaIdForm,
      includeOptionMap: optionsToMapOfOption(includeOptionSet),
      compositeIdSet: compositeIdSet ? [compositeIdSet] : undefined
    } as DefnStudioPickFieldId,

    [fieldSpreadsheetLayoutSwitcherIdSet]: {
      type: "studioSetOfLayoutSpreadsheetId",
      metaId: fieldSpreadsheetLayoutSwitcherIdSet,
      name: fieldSpreadsheetLayoutSwitcherIdSet,
      label: allowToSwitchLayoutsLabel,
      spreadsheetId: spreadsheetId,
      excludeLayoutSpreadsheetIdSet: excludeSpreadsheetLayoutIdSet
    } as DefnStudioPickLayoutSpreadsheetId,

    [section2]: {
      type: "section",
      metaId: section2,
      fieldIdSet: fieldIdSet
    } as DefnSection,

    [fieldTabDetails]: {
      type: "section",
      metaId: fieldTabDetails,
      fieldIdSet: [
        commonLayoutDetailsSection,
        section2
      ]
    } as DefnSection

  } as Record<MetaIdField, DefnField>;
}

export function getCompMapSpreadSheetBrTabFilter(
  metaIdForm: MetaIdForm,
  queryableRefFields: MetaIdField[],
  queryableFields: MetaIdField[],
  compositeIdSet?: MetaIdComposite)
{
  const sortBySystemFieldIdSet = (["$CreatedOn", "$UpdatedOn"] as EnumDefnFields[]);

  return {
    [fieldLayoutFilterLayoutMode]: {
      type: "enumLayoutCardFilterKind",
      name: fieldLayoutFilterLayoutMode,
      metaId: compFilterLayoutMode,
      label: "Filter layout mode"
    } as DefnFieldPickEnum,
    [fieldLayoutFilterShowSearchBar]: {
      type: "bool",
      name: fieldLayoutFilterShowSearchBar,
      label: "Show search bar",
      metaId: fieldLayoutFilterShowSearchBar,
      showAsCheckboxVar: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_SHOW_SEARCH_BAR)
    } as DefnFieldSwitch,

    [fieldLayoutFilterCatFields]: {
      type: "studioSetOfFieldId",
      metaId: fieldLayoutFilterCatFields,
      name: fieldLayoutFilterCatFields,
      label: "Category fields",
      formId: metaIdForm,
      filterFieldTypeSet: CardFilterCategorySet,
      includeFieldIdSet: queryableRefFields,
      compositeIdSet: compositeIdSet,
      showCompositeName: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_CATEGORY_FIELDS)
    } as DefnStudioPickFieldId,

    [fieldLayoutFilterAdvanceFilterFields]: {
      type: "studioSetOfFieldId",
      metaId: fieldLayoutFilterAdvanceFilterFields,
      name: fieldLayoutFilterAdvanceFilterFields,
      label: "Advance filter fields",
      formId: metaIdForm,
      filterFieldTypeSet: CardFilterAdvanceSet,
      includeFieldIdSet: queryableFields,
      compositeIdSet: compositeIdSet,
      showCompositeName: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_ADVANCE_FILTER_FIELDS)
    } as DefnStudioPickFieldId,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldLayoutSortByFields]: {
      type: "studioSetOfFieldId",
      metaId: fieldLayoutSortByFields,
      name: fieldLayoutSortByFields,
      label: "Sort by fields",
      formId: metaIdForm,
      filterFieldTypeSet: SortBy,
      includeFieldIdSet: queryableFields,
      includeOptionMap: arrayToMapOfOption(sortBySystemFieldIdSet, true),
      compositeIdSet: compositeIdSet,
      showCompositeName: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_SORT_BY_FIELDS)
    } as DefnStudioPickFieldId,

    [fieldLayoutSortOrder]: {
      type: "enumSortOrder",
      metaId: fieldLayoutSortOrder,
      name: fieldLayoutSortOrder,
      label: "Sort order"
    } as DefnFieldPickEnum,

    [fieldLayoutFilterSection]: {
      type: "section",
      name: fieldLayoutFilterSection,
      metaId: fieldLayoutFilterSection,
      label: "filter",
      fieldIdSet: [
        fieldLayoutFilterLayoutMode,
        fieldLayoutFilterCatFields,
        fieldLayoutFilterAdvanceFilterFields,
        fieldLayoutFilterShowSearchBar,
        fieldGap1,
        fieldLayoutSortByFields,
        fieldLayoutSortOrder
      ]
    } as DefnSection
  };
}

export const commonLayoutDetailsSection = "commonLayoutDetailsSection";

export function getCommonDetailsTab()
{
  return {
    [fieldKeyName]: {
      type: "symbol",
      metaId: fieldKeyName,
      name: fieldKeyName,
      label: "Name",
      required: true
    } as DefnFieldEditable,

    [fieldKeyLabel]: {
      type: "text",
      metaId: fieldKeyLabel,
      name: fieldKeyLabel,
      label: "Label"
    } as DefnFieldEditable,

    [fieldKeyDescription]: {
      type: "paragraph",
      metaId: fieldKeyDescription,
      name: fieldKeyDescription,
      label: "Description",
      lineCountVar: 4
    } as DefnFieldParagraph,
    [commonLayoutDetailsSection]: {
      type: "section",
      metaId: commonLayoutDetailsSection,
      name: commonLayoutDetailsSection,
      label: "Details",
      fieldIdSet: [fieldKeyName, fieldKeyLabel, fieldKeyDescription]
    } as DefnSection
  };
}

export function getCompMapGridLayoutTabDetails(
  metaIdForm: MetaIdForm,
  layoutType: EnumDefnLayoutGridKind,
  metaIdGrid?: MetaIdGrid,
  excludeLayoutGridIdSet?: MetaIdLayoutGrid[]
)
{
  const section2 = getLayoutItemSectionId(getCombinedFieldId([
    fieldLayoutGroupByField,
    fieldLayoutDetailTooltipField,
    fieldLayoutDetailBgColorField,
    fieldGridLayoutSwitcherIdSet,
    ...layoutType === "list" ? [fieldLayoutRenderingMode] : []
  ]));

  const includeOptionSet: DefnDtoOption[] = [];
  EnumArrayDefnFields.forEach(optionId =>
  {
    if(optionId === "$CreatedBy" || optionId === "$UpdatedBy")
    {
      includeOptionSet.push({
        value: optionId,
        metaId: optionId
      });
    }
  });
  return {
    ...getCommonDetailsTab(),

    [fieldLayoutGroupByField]: {
      type: "pickFieldId",
      metaId: fieldLayoutGroupByField,
      name: fieldLayoutGroupByField,
      label: "Group by field",
      filterFieldTypeSet: GroupBy,
      formId: metaIdForm,
      includeOptionMap: optionsToMapOfOption(includeOptionSet),
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,
    [fieldGridLayoutSwitcherIdSet]: {
      type: "studioSetOfLayoutGridId",
      metaId: fieldGridLayoutSwitcherIdSet,
      name: fieldGridLayoutSwitcherIdSet,
      label: allowToSwitchLayoutsLabel,
      excludeLayoutGridIdSet: excludeLayoutGridIdSet,
      formId: metaIdForm
    } as DefnStudioPickLayoutGridId,
    [fieldLayoutDetailTooltipField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailTooltipField,
      name: fieldLayoutDetailTooltipField,
      label: "Tooltip field",
      filterFieldTypeSet: ToolTip,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      formId: metaIdForm
    } as DefnStudioPickFieldId,
    ...getFieldGap(fieldGap2, "thick"),
    [fieldLayoutDetailBgColorField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailBgColorField,
      name: fieldLayoutDetailBgColorField,
      label: "Background color field",
      filterFieldTypeSet: Color,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      formId: metaIdForm
    } as DefnStudioPickFieldId,
    [fieldLayoutRenderingMode]: {
      type: "enumGridRenderingMode",
      metaId: fieldLayoutRenderingMode,
      name: fieldLayoutRenderingMode,
      label: "Rendering Mode",
      pb: gapStd
    } as DefnFieldPickEnum,
    [section2]: {
      type: "section",
      name: section2,
      metaId: section2,
      fieldIdSet: [
        fieldLayoutDetailTooltipField,
        fieldLayoutDetailBgColorField,
        fieldGap2,
        fieldLayoutGroupByField,
        fieldGridLayoutSwitcherIdSet,
        ...layoutType === "list" ? [fieldLayoutRenderingMode] : []
      ]
    } as DefnSection,
    [fieldLayoutDetailsSection]: {
      type: "section",
      name: fieldLayoutDetailsSection,
      label: "Details",
      metaId: fieldLayoutDetailsSection,
      fieldIdSet: [commonLayoutDetailsSection, section2]
    } as DefnSection
  };
}

export function getLayoutItemLineSegmentCard(
  lineSegment: TypeLayoutItemLineSegment,
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeFieldIdSet?: MetaIdLayoutGrid[],
  layoutType?: ILayoutType,
  compositeIdSet?: MetaIdComposite[],
  isOverride?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption)
{
  const keySectionMediaField = getLayoutItemMediaSectionKey(lineSegment, fieldLayoutItemTypeFields);
  const keySectionMediaVar = getLayoutItemMediaSectionKey(lineSegment, fieldLayoutItemTypeVariables);
  const keySectionMediaConst = getLayoutItemMediaSectionKey(lineSegment, fieldLayoutItemTypeConstants);

  const ItemId = getCombinedFieldId([fieldLayoutItem, lineSegment]);
  const tabId = getLayoutItemTabId(ItemId);
  const keySectionFields = getCombinedFieldId([ItemId, fieldLayoutItemTypeFields]);
  const keySectionVariables = getCombinedFieldId([ItemId, fieldLayoutItemTypeVariables]);
  const keySectionConstants = getCombinedFieldId([ItemId, fieldLayoutItemTypeConstants]);
  const layoutKind = "card" as EnumDefnLayoutGridKind;

  const formCompositeIdSet = (layoutType === "gridLayout" && !isOverride)
    ? metaIdGrid
      ? [metaIdGrid]
      : undefined
    : compositeIdSet;

  const getLayoutItemMedia = (
    lineSegment: TypeLayoutItemLineSegment,
    lineSegmentType: TypeLayoutItemLineSegmentType,
    metaIdForm?: MetaIdForm,
    excludeFieldIdSet?: MetaIdField[]) =>
  {
    const sectionId = getLayoutItemMediaSectionKey(lineSegment, lineSegmentType);
    let imageId: string;
    let imageTypeId: string;
    if(lineSegmentType === "fields")
    {
      imageId = fieldLayoutItemMediaField;
      imageTypeId = fieldLayoutItemMediaFieldImageType;
      if(lineSegment !== "texts")
      {
        imageId = fieldLayoutItemMediaFieldsDisable;
        imageTypeId = fieldLayoutItemMediaCornerTypeDisableField;
      }
    }
    else if(lineSegmentType === "variables")
    {
      imageId = fieldLayoutItemMediaVariable;
      imageTypeId = fieldLayoutItemMediaVariableImageType;
      if(lineSegment !== "texts")
      {
        imageId = fieldLayoutItemMediaVariablesDisable;
        imageTypeId = fieldLayoutItemMediaCornerTypeDisableVariable;
      }
    }
    else
    {
      imageId = fieldLayoutItemMediaConstant;
      imageTypeId = fieldLayoutItemMediaConstantImageType;
      if(lineSegment !== "texts")
      {
        imageId = fieldLayoutItemMediaConstantsDisable;
        imageTypeId = fieldLayoutItemMediaCornerTypeDisableConstant;
      }
    }
    return {
      ...lineSegmentType === "fields"
        ? {
          [imageId]: {
            type: "studioSetOfFieldId",
            metaId: imageId,
            name: imageId,
            label: "Image",
            formId: metaIdForm,
            compositeIdSet: formCompositeIdSet,
            disabled: lineSegment !== "texts",
            filterFieldTypeSet: MediaFieldType,
            excludeFieldIdSet: excludeFieldIdSet,
            showCompositeName: isOverride
          } as DefnStudioPickFieldId
        }
        : lineSegmentType === "variables" ? {
          [imageId]: {
            type: "studioSetOfVarId",
            metaId: imageId,
            name: imageId,
            label: "Image",
            disabled: lineSegment !== "texts",
            varKindSet: MediaVarType
          } as DefnStudioPickVarId
        } : {
          [imageId]: {
            type: "studioSetOfVarId",
            metaId: imageId,
            name: imageId,
            label: "Image",
            disabled: true,
            varKindSet: MediaVarType
          } as DefnStudioPickVarId
        },

      ...lineSegmentType === "fields" ? {
        [imageTypeId]: {
          type: "pickVarId",
          name: imageTypeId,
          metaId: imageTypeId,
          label: "Image type",
          varKind: "imageCorner",
          disabled: true,
          pr: 0
        } as DefnStudioPickVarId
      } : lineSegmentType === "variables" ? {
        [imageTypeId]: {
          type: "pickVarId",
          name: imageTypeId,
          metaId: imageTypeId,
          label: "Image type",
          varKind: "imageCorner",
          disabled: lineSegment !== "texts",
          compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
          pr: 0
        } as DefnStudioPickVarId
      } : {
        [imageTypeId]: {
          type: "pickVarId",
          name: imageTypeId,
          metaId: imageTypeId,
          label: "Image type",
          varKind: "imageCorner",
          disabled: true,
          pr: 0
        } as DefnStudioPickVarId
      },

      [sectionId]: {
        type: "section",
        metaId: sectionId,
        name: sectionId,
        sectionDirection: "horizontal",
        pl: gapStd,
        pr: gapStd,
        fieldIdSet: [imageId, imageTypeId]
      } as DefnSection
    };
  };

  const getLayoutItemFields = (
    lineSegment: TypeLayoutItemLineSegment,
    metaIdForm?: MetaIdForm,
    excludeFieldIdSet?: MetaIdField[]) =>
  {
    return {
      ...getLayoutItemLineFields("firstLine",
        2,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("secondLine",
        2,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("thirdLine",
        2,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("fourthLine",
        2,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("fifthLine",
        2,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      )
    };
  };

  const getLayoutItemVariables = (
    lineSegment: TypeLayoutItemLineSegment,
    metaIdForm?: MetaIdForm) =>
  {
    return {
      ...getLayoutItemLineVariables("firstLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("secondLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("thirdLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("fourthLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("fifthLine", 2, layoutKind, lineSegment, metaIdForm)
    };
  };

  const getLayoutItemConstants = (
    lineSegment: TypeLayoutItemLineSegment,
    metaIdForm?: MetaIdForm) =>
  {
    return {
      ...getLayoutItemLineConstants("firstLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("secondLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("thirdLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("fourthLine", 2, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("fifthLine", 2, layoutKind, lineSegment, metaIdForm)
    };
  };

  return {
    ...getLayoutItemMedia(lineSegment, fieldLayoutItemTypeFields, metaIdForm, excludeFieldIdSet),
    ...getLayoutItemMedia(lineSegment, fieldLayoutItemTypeVariables, metaIdForm, excludeFieldIdSet),
    ...getLayoutItemMedia(lineSegment, fieldLayoutItemTypeConstants, metaIdForm, excludeFieldIdSet),
    ...getLayoutItemFields(lineSegment, metaIdForm, excludeFieldIdSet),
    ...getLayoutItemVariables(lineSegment, metaIdForm),
    ...getLayoutItemConstants(lineSegment, metaIdForm),

    [keySectionFields]: {
      type: "section",
      name: keySectionFields,
      metaId: keySectionFields,
      label: "Fields",
      fieldIdSet: [
        keySectionMediaField,
        ...getLayoutItemSectionKeys(lineSegment, fieldLayoutItemTypeFields)
      ]
    } as DefnSection,
    [keySectionVariables]: {
      type: "section",
      name: keySectionVariables,
      metaId: keySectionVariables,
      label: "Variables",
      fieldIdSet: [
        keySectionMediaVar,
        ...getLayoutItemSectionKeys(lineSegment, fieldLayoutItemTypeVariables)
      ]
    } as DefnSection,
    [keySectionConstants]: {
      type: "section",
      name: keySectionConstants,
      metaId: keySectionConstants,
      label: "Constants",
      fieldIdSet: [
        keySectionMediaConst,
        ...getLayoutItemSectionKeys(lineSegment, fieldLayoutItemTypeConstants)
      ]
    } as DefnSection,
    [tabId]: {
      type: "tab",
      name: tabId,
      metaId: tabId,
      showDivider: true,
      tabVariant: "standard",
      showSingleTab: true,
      label: lineSegment,
      tabIdSet: lineSegment === "labels" ? [
        keySectionConstants
      ] : [
        keySectionFields,
        keySectionVariables,
        keySectionConstants
      ]
    } as DefnTab
  } as Record<MetaIdField, DefnField>;
}

export function getLayoutItemLineSegmentList(
  lineSegment: TypeLayoutItemLineSegment,
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeFieldIdSet?: MetaIdField[],
  layoutType?: ILayoutType,
  compositeIdSet?: MetaIdComposite[],
  isOverride?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption)
{
  const metaItemId = getCombinedFieldId([fieldLayoutItem, lineSegment]);
  const tabId = getLayoutItemTabId(metaItemId);

  const mediaConstantSectionId = getLayoutItemMediaSectionKey(lineSegment, fieldLayoutItemTypeConstants);
  const mediaVariableSectionId = getLayoutItemMediaSectionKey(lineSegment, fieldLayoutItemTypeVariables);
  const mediaFieldSectionId = getLayoutItemMediaSectionKey(lineSegment, fieldLayoutItemTypeFields);

  const itemConstants = getCombinedFieldId([metaItemId, fieldLayoutItemTypeConstants]);
  const itemVariables = getCombinedFieldId([metaItemId, fieldLayoutItemTypeVariables]);
  const itemFields = getCombinedFieldId([metaItemId, fieldLayoutItemTypeFields]);

  const itemConstantsSection = getLayoutItemSectionId(itemConstants);
  const itemConstantsSectionSection = getLayoutItemSectionId(itemConstantsSection);

  const itemVariablesSection = getLayoutItemSectionId(itemVariables);
  const itemVariablesSectionSection = getLayoutItemSectionId(itemVariablesSection);

  const itemFieldsSection = getLayoutItemSectionId(itemFields);
  const itemFieldsSectionSection = getLayoutItemSectionId(itemFieldsSection);

  const layoutKind = "list" as EnumDefnLayoutGridKind;

  const formCompositeIdSet = (layoutType === "gridLayout" && !isOverride)
    ? metaIdGrid ? [metaIdGrid] : undefined
    : compositeIdSet;

  function getLayoutItemMedia(
    lineSegment: TypeLayoutItemLineSegment,
    lineSegmentType: TypeLayoutItemLineSegmentType,
    metaIdForm?: MetaIdForm,
    metaIdGrid?: MetaIdGrid
  )
  {
    const sectionId = getLayoutItemMediaSectionKey(lineSegment, lineSegmentType);
    let imageId: string;
    let imageTypeId: string;
    if(lineSegmentType === "fields")
    {
      imageId = fieldLayoutItemMediaField;
      imageTypeId = fieldLayoutItemMediaFieldImageType;
      if(lineSegment !== "texts")
      {
        imageId = fieldLayoutItemMediaFieldsDisable;
        imageTypeId = fieldLayoutItemMediaCornerTypeDisableField;
      }
    }
    else if(lineSegmentType === "variables")
    {
      imageId = fieldLayoutItemMediaVariable;
      imageTypeId = fieldLayoutItemMediaVariableImageType;
      if(lineSegment !== "texts")
      {
        imageId = fieldLayoutItemMediaVariablesDisable;
        imageTypeId = fieldLayoutItemMediaCornerTypeDisableVariable;
      }
    }
    else
    {
      imageId = fieldLayoutItemMediaConstant;
      imageTypeId = fieldLayoutItemMediaConstantImageType;
      if(lineSegment !== "texts")
      {
        imageId = fieldLayoutItemMediaConstantsDisable;
        imageTypeId = fieldLayoutItemMediaCornerTypeDisableConstant;
      }
    }

    return {
      ...lineSegmentType === "fields"
        ? {
          [imageId]: {
            type: "studioSetOfFieldId",
            metaId: imageId,
            name: imageId,
            label: "Image",
            formId: metaIdForm,
            compositeIdSet: formCompositeIdSet,
            disabled: lineSegment !== "texts",
            filterFieldTypeSet: MediaFieldType,
            excludeFieldIdSet: excludeFieldIdSet,
            pr: 0
          } as DefnStudioPickFieldId
        }
        : lineSegmentType === "variables" ? {
          [imageId]: {
            type: "studioSetOfVarId",
            metaId: imageId,
            name: imageId,
            label: "Image",
            metaIdForm: metaIdForm,
            disabled: lineSegment !== "texts",
            varKindSet: MediaVarType,
            compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
            pr: 0
          } as DefnStudioPickVarId
        } : {
          [imageId]: {
            type: "studioSetOfVarId",
            metaId: imageId,
            name: imageId,
            label: "Image",
            disabled: true,
            pr: 0
          } as DefnStudioPickVarId
        },

      ...lineSegmentType === "fields" ? {
        [imageTypeId]: {
          type: "pickVarId",
          name: imageTypeId,
          metaId: imageTypeId,
          label: "Image type",
          varKind: "imageCorner",
          disabled: true,
          pr: 0
        } as DefnStudioPickVarId
      } : lineSegmentType === "variables" ? {
        [imageTypeId]: {
          type: "pickVarId",
          name: imageTypeId,
          metaId: imageTypeId,
          label: "Image type",
          varKind: "imageCorner",
          disabled: lineSegment !== "texts",
          compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
          pr: 0
        } as DefnStudioPickVarId
      } : {
        [imageTypeId]: {
          type: "pickVarId",
          name: imageTypeId,
          metaId: imageTypeId,
          label: "Image type",
          varKind: "imageCorner",
          disabled: true,
          pr: 0
        } as DefnStudioPickVarId
      },

      [sectionId]: {
        type: "section",
        name: sectionId,
        metaId: sectionId,

        fieldIdSet: [imageId, imageTypeId]
      } as DefnSection
    };
  }

  function getLayoutItemConstants(
    lineSegment: TypeLayoutItemLineSegment,
    metaIdForm?: MetaIdForm)
  {
    return {
      ...getLayoutItemLineConstants("firstLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("secondLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("thirdLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("fourthLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineConstants("fifthLine", 1, layoutKind, lineSegment, metaIdForm)
    };
  }

  function getLayoutItemVariables(
    lineSegment: TypeLayoutItemLineSegment,
    metaIdForm?: MetaIdForm)
  {
    return {
      ...getLayoutItemLineVariables("firstLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("secondLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("thirdLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("fourthLine", 1, layoutKind, lineSegment, metaIdForm),
      ...getLayoutItemLineVariables("fifthLine", 1, layoutKind, lineSegment, metaIdForm)
    };
  }

  function getLayoutItemFields(
    lineSegment: TypeLayoutItemLineSegment,
    metaIdForm?: MetaIdForm)
  {
    return {
      ...getLayoutItemLineFields("firstLine",
        1,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("secondLine",
        1,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("thirdLine",
        1,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("fourthLine",
        1,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      ),
      ...getLayoutItemLineFields("fifthLine",
        1,
        lineSegment,
        layoutKind,
        metaIdForm,
        excludeFieldIdSet,
        formCompositeIdSet,
        isOverride,
        includeOptionMap
      )
    };
  }

  return {
    ...getLayoutItemMedia(lineSegment, fieldLayoutItemTypeConstants, metaIdForm, metaIdGrid),
    ...getLayoutItemMedia(lineSegment, fieldLayoutItemTypeFields, metaIdForm, metaIdGrid),
    ...getLayoutItemMedia(lineSegment, fieldLayoutItemTypeVariables, metaIdForm, metaIdGrid),
    ...getLayoutItemConstants(lineSegment, metaIdForm),
    ...getLayoutItemVariables(lineSegment, metaIdForm),
    ...getLayoutItemFields(lineSegment, metaIdForm),

    [itemConstantsSection]: {
      type: "section",
      name: itemConstantsSection,
      metaId: itemConstantsSection,
      label: "Constants",
      fieldIdSet: [
        ...getLayoutItemSectionKeys(lineSegment, fieldLayoutItemTypeConstants)
      ]
    } as DefnSection,

    [itemConstantsSectionSection]: {
      type: "section",
      name: itemConstantsSectionSection,
      metaId: itemConstantsSectionSection,
      label: "Constants",
      sectionDirection: "horizontal",
      fieldSpanMap: {
        [mediaConstantSectionId]: 1,
        [itemConstantsSection]: 3
      },
      fieldIdSet: [
        mediaConstantSectionId,
        itemConstantsSection
      ]
    } as DefnSection,

    [itemVariablesSection]: {
      type: "section",
      name: itemVariablesSection,
      metaId: itemVariablesSection,
      fieldIdSet: [
        ...getLayoutItemSectionKeys(lineSegment, fieldLayoutItemTypeVariables)
      ]
    } as DefnSection,

    [itemVariablesSectionSection]: {
      type: "section",
      name: itemVariablesSectionSection,
      metaId: itemVariablesSectionSection,
      label: "Variables",
      sectionDirection: "horizontal",
      fieldSpanMap: {
        [mediaVariableSectionId]: 1,
        [itemVariablesSection]: 3
      },
      fieldIdSet: [
        mediaVariableSectionId,
        itemVariablesSection
      ]
    } as DefnSection,

    [itemFieldsSection]: {
      type: "section",
      name: itemFieldsSection,
      metaId: itemFieldsSection,
      label: "Fields",
      fieldIdSet: [
        ...getLayoutItemSectionKeys(lineSegment, fieldLayoutItemTypeFields)
      ]
    } as DefnSection,

    [itemFieldsSectionSection]: {
      type: "section",
      name: itemFieldsSectionSection,
      metaId: itemFieldsSectionSection,
      label: "Fields",
      sectionDirection: "horizontal",
      fieldSpanMap: {
        [mediaFieldSectionId]: 1,
        [itemFieldsSection]: 3
      },
      fieldIdSet: [
        mediaFieldSectionId,
        itemFieldsSection
      ]
    } as DefnSection,

    [tabId]: {
      type: "tab",
      name: tabId,
      metaId: tabId,
      label: lineSegment,
      showDivider: true,
      tabVariant: "standard",
      showSingleTab: true,
      tabIdSet: lineSegment === "labels" ? [
        itemConstantsSectionSection
      ] : [
        itemFieldsSectionSection,
        itemVariablesSectionSection,
        itemConstantsSectionSection
      ]
    } as DefnTab
  } as Record<MetaIdField, DefnField>;
}

// endregion

function getLayoutKeys(lineNumber: TypeLineNumber, itemLine: TypeLayoutItemLine)
{
  const getLayoutItemKeyFields = (lineSegment: TypeLayoutItemLineSegment) => getLayoutItemKey(lineNumber,
    itemLine,
    lineSegment,
    "fields"
  );
  const getLayoutItemKeyVariables = (lineSegment: TypeLayoutItemLineSegment) => getLayoutItemKey(lineNumber,
    itemLine,
    lineSegment,
    "variables"
  );

  const getLayoutItemKeyConstants = (lineSegment: TypeLayoutItemLineSegment) => getLayoutItemKey(lineNumber,
    itemLine,
    lineSegment,
    "constants"
  );

  const textFieldId = getLayoutItemKeyFields("texts");
  const textVarId = getLayoutItemKeyVariables("texts");
  const text = getLayoutItemKeyConstants("texts");

  const colorFieldId = getLayoutItemKeyFields("colors");
  const colorVarId = getLayoutItemKeyVariables("colors");
  const color = getLayoutItemKeyConstants("colors");

  const fontFieldId = getLayoutItemKeyFields("fonts");
  const fontVarId = getLayoutItemKeyVariables("fonts");
  const font = getLayoutItemKeyConstants("fonts");

  const labelsFieldId = getLayoutItemKeyFields("labels");
  const labelsVarId = getLayoutItemKeyVariables("labels");
  const label = getLayoutItemKeyConstants("labels");

  return {
    textFieldId,
    textVarId,
    text,
    colorFieldId,
    colorVarId,
    color,
    fontFieldId,
    fontVarId,
    font,
    labelsFieldId,
    labelsVarId,
    label
  };
}

export function defnToDtoStudioDtoLayoutCard(values: FieldValues): StudioDtoLayoutCardItem
{
  return {
    firstLine: getLayoutCardItemLine(values, "firstLine"),
    secondLine: getLayoutCardItemLine(values, "secondLine"),
    thirdLine: getLayoutCardItemLine(values, "thirdLine"),
    fourthLine: getLayoutCardItemLine(values, "fourthLine"),
    fifthLine: getLayoutCardItemLine(values, "fifthLine"),
    mediaFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldLayoutItemMediaField]),
    mediaVarIdSet: fnFieldValueToRawValue("studioSetOfVarId", values[fieldLayoutItemMediaVariable]),
    imageCornerVarId: fnFieldValueToRawValue("pickVarId", values[fieldLayoutItemMediaCornerTypeField])
  } as StudioDtoLayoutCardItem;
}

function getLayoutCardItemLine(values: FieldValues, lineNumber: TypeLineNumber): StudioDtoLayoutCardItemLine | null
{
  const first = getLayoutCardItemLineSegment(values, lineNumber, "first");
  const middle = getLayoutCardItemLineSegment(values, lineNumber, "middle");
  const caption = getLayoutCardItemLineSegment(values, lineNumber, "caption");

  const hasFirstValues = hasValues(first);
  const hasSecondValues = hasValues(middle);
  const hasThirdValues = hasValues(caption);

  if(!hasFirstValues && !hasSecondValues && !hasThirdValues)
  {
    return null;
  }

  return {
    first: hasFirstValues ? first : null,
    middle: hasSecondValues ? middle : null,
    caption: hasThirdValues ? caption : null
  } as StudioDtoLayoutCardItemLine;
}

function getLayoutCardItemLineSegment(
  values: FieldValues,
  lineNumber: TypeLineNumber,
  itemLine: TypeLayoutItemLine): StudioDtoLayoutCardItemLineSegment
{
  const {
    textFieldId,
    textVarId,
    text,

    colorFieldId,
    colorVarId,
    color,

    fontFieldId,
    fontVarId,
    font,

    label

  } = getLayoutKeys(lineNumber, itemLine);

  const isShowLabelsField = itemLine === "first" && lineNumber !== "firstLine";

  const showLabel = values[label];

  return {
    lineFieldIdSet: (itemLine !== "first" || lineNumber === "firstLine")
      ? values[textFieldId] ? [values[textFieldId]] : undefined
      : values[textFieldId],
    lineVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[textVarId]),
    line: fnFieldValueToRawValue("text", values[text]),

    colorFieldId: fnFieldValueToRawValue("pickFieldId", values[colorFieldId]),
    colorVarId: fnFieldValueToRawValue("pickVarId", values[colorVarId]),
    color: fnFieldValueToRawValue("studioBuildColor", values[color]),

    textSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[fontFieldId]),
    textSizeVarId: fnFieldValueToRawValue("pickVarId", values[fontVarId]),
    textSize: fnFieldValueToRawValue("textSize", values[font]),

    showLabels: isShowLabelsField && showLabel?.value
      ? fnFieldValueToRawValue("bool", showLabel)
      : undefined
  } as StudioDtoLayoutCardItemLineSegment;
}

export function dtoToValueStudioDtoLayoutCard(dto?: StudioDtoLayoutCard): FieldValues
{
  const item = dto?.item;
  const firstLine = item?.firstLine;
  const secondLine = item?.secondLine;
  const thirdLine = item?.thirdLine;
  const fourthLine = item?.fourthLine;
  const fifthLine = item?.fifthLine;

  return {
    [fieldLayoutItemMediaField]: fnRawValueToFieldValue("studioSetOfFieldId", dto?.item?.mediaFieldIdSet),
    [fieldLayoutItemMediaVariable]: fnRawValueToFieldValue("studioSetOfVarId", dto?.item?.mediaVarIdSet),
    [fieldLayoutItemMediaCornerTypeField]: fnRawValueToFieldValue("pickFieldId", dto?.item?.imageCornerVarId),

    ...getLayoutItemSegment("firstLine", "first", firstLine?.first),
    ...getLayoutItemSegment("secondLine", "first", secondLine?.first),
    ...getLayoutItemSegment("thirdLine", "first", thirdLine?.first),
    ...getLayoutItemSegment("fourthLine", "first", fourthLine?.first),
    ...getLayoutItemSegment("fifthLine", "first", fifthLine?.first),

    ...getLayoutItemSegment("firstLine", "middle", firstLine?.middle),
    ...getLayoutItemSegment("secondLine", "middle", secondLine?.middle),
    ...getLayoutItemSegment("thirdLine", "middle", thirdLine?.middle),
    ...getLayoutItemSegment("fourthLine", "middle", fourthLine?.middle),
    ...getLayoutItemSegment("fifthLine", "middle", fifthLine?.middle),

    ...getLayoutItemSegment("firstLine", "caption", firstLine?.caption),
    ...getLayoutItemSegment("secondLine", "caption", secondLine?.caption),
    ...getLayoutItemSegment("thirdLine", "caption", thirdLine?.caption),
    ...getLayoutItemSegment("fourthLine", "caption", fourthLine?.caption),
    ...getLayoutItemSegment("fifthLine", "caption", fifthLine?.caption)
  };
}

function getLayoutItemSegment(
  lineNumber: TypeLineNumber,
  itemLine: TypeLayoutItemLine,
  dtoLineSegment?: StudioDtoLayoutCardItemLineSegment)
{
  const isShowLabelsField = itemLine === "first" && lineNumber !== "firstLine";

  const {
    textFieldId,
    textVarId,
    text,

    colorFieldId,
    colorVarId,
    color,

    fontFieldId,
    fontVarId,
    font,

    label
  } = getLayoutKeys(lineNumber, itemLine);

  return {
    [textFieldId]: (dtoLineSegment?.lineFieldIdSet && !isEmpty(dtoLineSegment?.lineFieldIdSet))
      ? (itemLine !== "first" || lineNumber === "firstLine")
        ? fnRawValueToFieldValue("studioSetOfFieldId", dtoLineSegment?.lineFieldIdSet[0])
        : fnRawValueToFieldValue("studioSetOfFieldId", dtoLineSegment?.lineFieldIdSet)
      : undefined,
    [textVarId]: fnRawValueToFieldValue("pickVarId", dtoLineSegment?.lineVarId?.argVarId),
    [text]: fnRawValueToFieldValue("text", dtoLineSegment?.line),

    [colorFieldId]: fnRawValueToFieldValue("pickFieldId", dtoLineSegment?.colorFieldId),
    [colorVarId]: fnRawValueToFieldValue("pickVarId", dtoLineSegment?.colorVarId),
    [color]: fnRawValueToFieldValue("pickVarId", dtoLineSegment?.color),

    [fontFieldId]: fnRawValueToFieldValue("pickFieldId", dtoLineSegment?.textSizeFieldId),
    [fontVarId]: fnRawValueToFieldValue("pickVarId", dtoLineSegment?.textSizeVarId),
    [font]: fnRawValueToFieldValue("textSize", dtoLineSegment?.textSize),

    [label]: isShowLabelsField ? fnRawValueToFieldValue("bool", dtoLineSegment?.showLabels) : undefined
  };
}

export function getCalendarTab(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  includeFieldIdSet?: string[],
  fromDatePickedFieldType?: EnumDefnCompType,
  toDatePickedFieldType?: EnumDefnCompType
)
{
  const filterFieldTypeSet: string[] = EnumArrayDefnCompType.filter(compType => compType !== "ref");

  const filterDateTimeTypeSet = () =>
  {
    if(fromDatePickedFieldType === undefined)
    {
      return ["date", "dateTime"];
    }
    else
    {
      return fromDatePickedFieldType;
    }
  };

  return {
    [fieldFormLabel]: {
      type: "label",
      label: "From date",
      metaId: fieldFormLabel,
      disabled: true
    } as DefnFieldLabel,

    [fieldDateFormField]: {
      type: "pickFieldId",
      metaId: fieldDateFormField,
      name: fieldDateFormField,
      label: "Date field",
      filterFieldTypeSet: ["date", "dateTime", "dateRange", "dateTimeRange"],
      formId: metaIdForm,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      required: true
    } as DefnStudioPickFieldId,

    [fieldTimeFormField]: {
      type: "pickFieldId",
      metaId: fieldTimeFormField,
      name: fieldTimeFormField,
      label: "Time field",
      filterFieldTypeSet: ["time"],
      formId: metaIdForm,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      disabled: (fromDatePickedFieldType === "dateTime") ||
        (fromDatePickedFieldType === "dateTimeRange") ||
        (fromDatePickedFieldType === "dateRange")
    } as DefnStudioPickFieldId,

    [fieldFromDateTimeSection]: {
      type: "section",
      metaId: fieldFromDateTimeSection,
      name: fieldFromDateTimeSection,
      flexGrow: false,
      sectionDirection: "horizontal",
      fieldIdSet: [
        fieldDateFormField,
        fieldTimeFormField
      ],
      pl: gapStd,
      pr: gapStd
    } as DefnSection,

    [fieldToLabel]: {
      type: "label",
      label: "To date",
      metaId: fieldToLabel,
      disabled: true
    } as DefnFieldLabel,

    [fieldDateToField]: {
      type: "pickFieldId",
      metaId: fieldDateToField,
      name: fieldDateToField,
      label: "Date field",
      filterFieldTypeSet: [filterDateTimeTypeSet()],
      formId: metaIdForm,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      disabled: (fromDatePickedFieldType === "dateRange") || (fromDatePickedFieldType === "dateTimeRange")
    } as DefnStudioPickFieldId,

    [fieldTimeToField]: {
      type: "pickFieldId",
      metaId: fieldTimeToField,
      name: fieldTimeToField,
      label: "Time field",
      filterFieldTypeSet: ["time"],
      formId: metaIdForm,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      disabled: (toDatePickedFieldType === "dateTime") ||
        (fromDatePickedFieldType === "dateRange") ||
        (fromDatePickedFieldType === "dateTimeRange")
    } as DefnStudioPickFieldId,

    [fieldToDateTimeSection]: {
      type: "section",
      sectionDirection: "horizontal",
      metaId: fieldToDateTimeSection,
      name: fieldToDateTimeSection,
      fieldIdSet: [
        fieldDateToField,
        fieldTimeToField
      ],
      flexGrow: false,
      pl: gapStd,
      pr: gapStd
    } as DefnSection,

    ...getFieldGap(fieldGap, "thick"),

    [fieldTitleField]: {
      type: "pickFieldId",
      metaId: fieldTitleField,
      name: fieldTitleField,
      label: "Title field",
      filterFieldTypeSet: ["text", "paragraph", "pickText"],
      formId: metaIdForm,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      required: true
    } as DefnStudioPickFieldId,

    [fieldShowFieldIdSet]: {
      type: "studioSetOfFieldId",
      required: true,
      metaId: fieldShowFieldIdSet,
      name: fieldShowFieldIdSet,
      label: "Show fields",
      showChip: true,
      formId: metaIdForm,
      filterFieldTypeSet: filterFieldTypeSet,
      includeFieldIdSet: includeFieldIdSet,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,

    [fieldColorField]: {
      type: "pickFieldId",
      metaId: fieldColorField,
      name: fieldColorField,
      label: "Color field",
      filterFieldTypeSet: Color,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      formId: metaIdForm
    } as DefnStudioPickFieldId,

    [fieldTabCalendar]: {
      type: "section",
      name: fieldTabCalendar,
      label: "Calendar",
      metaId: fieldTabCalendar,
      fieldIdSet: [
        fieldFormLabel,
        fieldFromDateTimeSection,
        fieldToLabel,
        fieldToDateTimeSection,
        fieldGap,
        fieldTitleField,
        fieldShowFieldIdSet,
        fieldColorField
      ]
    } as DefnSection
  };
}

export function getLayoutTabKanban(
  metaIdForm?: MetaIdForm,
  compositeIdSet?: MetaIdComposite[],
  swimlane?: StudioMapOfSwimlane,
  includeFieldIdSet?: MetaIdField[],
  queryableFields?: MetaIdField[],
  isSpreadsheetLayout?: boolean,
  showFooter?: boolean
)
{
  const isSwimLaneVisible = swimlane && !isEmpty(swimlane);
  const sortBySystemFieldIdSet = (["$CreatedOn", "$UpdatedOn"] as EnumDefnFields[]);

  const {
    swimlaneSectionKeys,
    compMap: swimlaneCompMap
  } = getSwimlaneCompMap(swimlane);

  return {
    [fieldShowFields]: {
      type: "studioSetOfFieldId",
      metaId: fieldShowFields,
      name: fieldShowFields,
      label: "Show fields",
      showChip: true,
      formId: metaIdForm,
      compositeIdSet: compositeIdSet,
      includeFieldIdSet: includeFieldIdSet
    } as DefnStudioPickFieldId,

    [fieldShowSectionName]: {
      type: "bool",
      metaId: fieldShowSectionName,
      name: fieldShowSectionName,
      label: "Show section name",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [fieldShowFooter]: {
      type: "bool",
      metaId: fieldShowFooter,
      name: fieldShowFooter,
      label: "Show footer",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [fieldShowComments]: {
      type: "bool",
      metaId: fieldShowComments,
      name: fieldShowComments,
      label: "Show comments count",
      showAsCheckboxVar: true,
      disabled: !showFooter
    } as DefnFieldSwitch,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldLayoutSortByFields]: {
      type: "studioSetOfFieldId",
      metaId: fieldLayoutSortByFields,
      name: fieldLayoutSortByFields,
      label: "Sort by fields",
      formId: metaIdForm,
      filterFieldTypeSet: SortBy,
      compositeIdSet: compositeIdSet,
      includeFieldIdSet: queryableFields,
      includeOptionMap: arrayToMapOfOption(sortBySystemFieldIdSet, true),
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_SORT_BY_FIELDS)
    } as DefnStudioPickFieldId,

    [fieldLayoutSortOrder]: {
      type: "enumSortOrder",
      metaId: fieldLayoutSortOrder,
      name: fieldLayoutSortOrder,
      label: "Sort order"
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap2, "thick"),

    [fieldFontSize]: {
      type: "textSize",
      metaId: fieldFontSize,
      name: fieldFontSize,
      label: "Font size"
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap3, "thick"),

    [fieldKanbanField]: {
      type: "pickFieldId",
      metaId: fieldKanbanField,
      name: fieldKanbanField,
      label: "Kanban field",
      formId: metaIdForm,
      compositeIdSet: compositeIdSet,
      filterFieldTypeSet: ["pickText"],
      required: true
    } as DefnStudioPickFieldId,

    ...isSwimLaneVisible && {
      [fieldSwimlaneLabel]: {
        type: "label",
        metaId: fieldSwimlaneLabel,
        name: fieldSwimlaneLabel,
        label: "Swimlane",
        disabled: true
      } as DefnFieldLabel
    },

    ...swimlaneCompMap,

    [fieldTabKanban]: {
      type: "section",
      metaId: fieldTabKanban,
      name: fieldTabKanban,
      fieldIdSet: isSpreadsheetLayout ? [
        fieldKanbanField,
        fieldShowFields,
        fieldShowSectionName,
        fieldShowFooter,
        fieldShowComments,
        fieldGap1,
        fieldLayoutSortByFields,
        fieldLayoutSortOrder,
        fieldGap2,
        fieldFontSize,
        ...isSwimLaneVisible
          ? [
            fieldGap3,
            fieldSwimlaneLabel,
            ...swimlaneSectionKeys
          ]
          : []
      ] : [
        fieldKanbanField,
        fieldShowFields,
        fieldShowFooter,
        fieldGap1,
        fieldLayoutSortByFields,
        fieldLayoutSortOrder,
        fieldGap2,
        fieldFontSize,
        ...isSwimLaneVisible
          ? [
            fieldGap3,
            fieldSwimlaneLabel,
            ...swimlaneSectionKeys
          ]
          : []
      ]

    } as DefnSection
  };
}
