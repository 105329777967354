import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {isPluginId} from "../../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../../api/meta/base/ApiPlus";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {StudioVarDocument} from "../../../../api/meta/base/dto/StudioVarDocument";
import {StudioVarImage} from "../../../../api/meta/base/dto/StudioVarImage";
import {ArtifactId} from "../../../../api/meta/base/Types";
import {MediaId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {selectCacheStudioEntValidationResult} from "../../../../base/plus/StudioPlus";
import {IAsidePropsStudio} from "../../../../base/types/TypesAside";
import {FormStore} from "../../../../base/types/TypesForm";
import {IFormRef} from "../../../../base/types/TypesForm";
import {IMainPropsStudio} from "../../../../base/types/TypesMain";
import {useAppSelector} from "../../../../nucleus/app/AppHooks";
import {useAppCtx} from "../../../../nucleus/ctx/CtxApp";
import {useStepStudioAsideDefaultConfig} from "../../../../nucleus/form/base/FormHooks";
import {VariableEditor} from "../../../../nucleus/varBldr/VariableEditor";
import {Srvc} from "../../../../srvc/Srvc";
import {RootState} from "../../../../Store";
import {isValidVariable} from "./UiStudioEntVariables";

export default function UiStudioEntVariable(props: {
  entId: EntId,
  onClickClose: () => void
})
{
  const appCtx = useAppCtx();
  const entId = props.entId;
  const closeAside = props.onClickClose;
  const cbRef = useMemo(() => ({} as IFormRef), []);

  const asideProps = appCtx.getAsideProps() as IAsidePropsStudio;
  const varId = asideProps.metaId;
  const readonly = asideProps.readOnly;
  const reset = asideProps.reset;

  const mainProps = appCtx.getMainProps() as IMainPropsStudio;
  const selectStudioEnt = mainProps.studioSelector.ent;
  const entFormStore = mainProps.studioSelector.formStore;

  const varMap = useAppSelector(state => selectStudioEnt(state)?.varMap);
  const variable = varMap && varId
    ? varMap.map[varId]
    : undefined;
  const validationResult = useAppSelector(state => selectCacheStudioEntValidationResult(state, entId));

  const getVariableAside = useArtifactStudioVariableEditor({
    artifactId: entId,
    readonly: readonly,
    reset: reset,
    cbRef: cbRef,
    entFormStore: entFormStore
  });

  useStepStudioAsideDefaultConfig("varMap", cbRef, variable, validationResult);

  return getVariableAside({
    onClickClose: closeAside,
    variable: variable
  });
}

export function useArtifactStudioVariableEditor(props: {
  artifactId: ArtifactId,
  cbRef?: IFormRef,
  readonly?: boolean,
  reset?: boolean,
  entFormStore: (state: RootState) => FormStore,
})
{
  const artifactId = props.artifactId;
  const readonly = props.readonly;
  const entFormStore = props.entFormStore;
  const reset = props.reset;
  const cbRef = useMemo(() => (props.cbRef || {} as IFormRef), [props.cbRef]);

  const isEnt = isEntId(artifactId);
  const isPlugin = isPluginId(artifactId);

  const cbAddVariable = useCallback((variable: StudioVar, uploadMedia?: boolean) =>
  {
    if(variable && isValidVariable(variable))
    {
      if(isEnt)
      {
        Srvc.studio.ent.variables.addVariable(artifactId, variable);
      }
      else if(isPlugin)
      {
        Srvc.studio.plugin.variables.addPluginVariable(artifactId, variable);
      }

      if(uploadMedia)
      {
        uploadMediaForVariable(artifactId, variable);
      }
    }
  }, [artifactId, isEnt, isPlugin]);

  const cbCopyVariable = useCallback((variable?: StudioVar) =>
  {
    if(variable?.metaId)
    {
      if(isEnt)
      {
        Srvc.studio.ent.variables.copyEntVariable(artifactId, variable.metaId);
      }
      else if(isPlugin)
      {
        Srvc.studio.plugin.variables.copyPluginVariable(artifactId, variable.metaId);
      }
    }
  }, [artifactId, isEnt, isPlugin]);

  return useCallback((props: {
    variable?: StudioVar,
    onClickClose?: () => void,
    submitOnClose?: boolean
  }) =>
  {
    const {
      variable,
      onClickClose,
      submitOnClose
    } = props;

    return <VariableEditor
      artifactId={artifactId}
      variable={variable}
      onClickClose={() =>
      {
        if(submitOnClose)
        {
          cbRef.remoteSubmit(true);
        }

        onClickClose && onClickClose();
      }}
      readonly={readonly}
      reset={reset}
      cbRef={cbRef}
      entFormStore={entFormStore}
      cbAddVariable={cbAddVariable}
      cbCopyVariable={() => cbCopyVariable(variable)}
    />;
  }, [
    artifactId,
    readonly,
    reset,
    cbRef,
    entFormStore,
    cbAddVariable,
    cbCopyVariable
  ]);
}

function uploadMediaForVariable(entId: EntId, variable: StudioVar)
{
  const mediaIdList = [] as MediaId[];
  switch(variable.kind)
  {
    case "image":
      const mediaIdImage = (variable as StudioVarImage).value?.value.mediaIdImage;
      const mediaIdBlurImage = (variable as StudioVarImage).value?.value.mediaIdBlurImage;
      if(mediaIdImage && mediaIdBlurImage)
      {
        mediaIdList.push(mediaIdImage);
        mediaIdList.push(mediaIdBlurImage);
      }
      break;
    case "document":
      const mediaIdDocument = (variable as StudioVarDocument).value?.value.mediaIdDocument;
      if(mediaIdDocument)
      {
        mediaIdList.push(mediaIdDocument);
      }
      break;
  }

  if(mediaIdList.length > 0)
  {
    Srvc.app.form.formViewer.uploadMediaIdList(entId, mediaIdList);
  }
}
