import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDate} from "../../../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioFieldDate} from "../../../../../api/meta/base/dto/StudioFieldDate";
import {TimeZoneKey} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {getDefaultDate} from "../../base/FieldBuilderPlus";
import {propKeyMinCustomDate} from "../../base/TypesFormBuilder";
import {propKeyMaxCustomDate} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueCustom} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyDisplayDateFormat} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldDate(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  showCustom?: boolean,
  showCustomMax?: boolean,
  showCustomMin?: boolean,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[],
  timeZone?: TimeZoneKey
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinFieldId,
    propKeyMaxFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinVar,
    propKeyMaxVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyDefaultValue,
    ...showCustom ? [propKeyDefaultValueCustom] : [],
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMin,
    ...showCustomMin ? [propKeyMinCustomDate] : [],
    propKeyMax,
    ...showCustomMax ? [propKeyMaxCustomDate] : [],
    fieldGap4,
    propKeyDisplayDateFormat
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "date",
      formId,
      "date",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      showCustom,
      sectionIdSetWithCurrentGridId,
      undefined,
      timeZone
    ),

    [propKeyDisplayDateFormat]: {
      type: "dateFormat",
      name: propKeyDisplayDateFormat,
      metaId: propKeyDisplayDateFormat,
      label: "Display date format"
    } as DefnFieldPickEnum,

    ...showCustomMax && {
      [propKeyMaxCustomDate]: {
        type: "date",
        metaId: propKeyMaxCustomDate,
        name: propKeyMaxCustomDate,
        label: "Custom max date",
        timeZone: timeZone
      } as DefnFieldDate
    },

    ...showCustomMin && {
      [propKeyMinCustomDate]: {
        type: "date",
        metaId: propKeyMinCustomDate,
        name: propKeyMinCustomDate,
        label: "Custom min date",
        timeZone: timeZone
      } as DefnFieldDate
    }
  };
}

export function defnValueToStudioFieldDate(values: FieldValues): StudioFieldDate
{

  return {
    type: "date",
    defaultValue: getDefaultDate(values, propKeyDefaultValue, propKeyDefaultValueCustom),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    min: getDefaultDate(values, propKeyMin, propKeyMinCustomDate),
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    max: getDefaultDate(values, propKeyMax, propKeyMaxCustomDate),
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    displayDateFormat: fnFieldValueToRawValue("dateFormat", values[propKeyDisplayDateFormat])
  } as StudioFieldDate;
}

export function studioFieldToDefnValueDate(studioField: StudioFieldDate): FieldValues
{
  const defaultValue = studioField.defaultValue;
  const isCustomValue = fnRawValueToFieldValue("date", defaultValue?.customValue);

  const defaultValueMin = studioField.min;
  const isCustomValueMin = fnRawValueToFieldValue("date", defaultValueMin?.customValue);

  const defaultValueMax = studioField.max;
  const isCustomValueMax = fnRawValueToFieldValue("date", defaultValueMax?.customValue);

  return {
    [propKeyDefaultValue]: isCustomValue ? "custom" : defaultValue?.value,
    [propKeyDefaultValueCustom]: isCustomValue,
    [propKeyMin]: isCustomValueMin ? "custom" : defaultValueMin?.value,
    [propKeyMinCustomDate]: isCustomValueMin,
    [propKeyMax]: isCustomValueMax ? "custom" : defaultValueMax?.value,
    [propKeyMaxCustomDate]: isCustomValueMax,
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyDisplayDateFormat]: fnRawValueToFieldValue("dateFormat", studioField.displayDateFormat)
  };
}


