import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdPartition} from "../../api/meta/base/ApiPlus";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioCodeEditor} from "../../api/meta/base/dto/DefnStudioCodeEditor";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {StudioDtoPartition} from "../../api/meta/base/dto/StudioDtoPartition";
import {MetaIdForm} from "../../api/meta/base/Types";
import {ALERT_DIALOG_CONTENT_WIDTH} from "../../base/plus/ConstantsPlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export default function DialogNewPartition(props: {
  formStore: FormStore,
  metaIdForm?: MetaIdForm,
  isFormReadOnly?: boolean,
  values?: StudioDtoPartition,
  onClickOk: (values: StudioDtoPartition) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const dto = props.values;
  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      formProps={{
        defnForm: getDefnForm(props.metaIdForm),
        onSubmit: values => props.onClickOk(valueToDto(values, dto)),
        store: props.formStore,
        initValues: dtoToValue(dto),
        cbRef: cbRef.current,
        formReadonly: props.isFormReadOnly
      }}
      title={`${dto ? "Update" : "New"} partition`}
      addMoreCheckBoxLabel={!dto
        ? "Add more partitions"
        : undefined}
      onClose={props.onClose}
      contentWidth={ALERT_DIALOG_CONTENT_WIDTH}
      contentHeight={ALERT_DIALOG_CONTENT_WIDTH}
    />
  );
}

const fieldName = "name";
const fieldAssignPartitionField = "assignPartitionFieldId";
const fieldFormula = "formula";

function getDefnForm(metaIdForm?: MetaIdForm)
{
  return createDefaultDefnFormStudio({
    [fieldName]: {
      type: "symbol",
      metaId: fieldName,
      name: fieldName,
      required: true,
      label: "Name"
    } as DefnFieldText,
    [fieldFormula]: {
      type: "studioCodeEditor",
      metaId: fieldFormula,
      name: fieldFormula,
      label: "Formula",
      language: "javascript",
      showExpandBtn: false,
      required: true,
      lineCount: 4
    } as DefnStudioCodeEditor,
    [fieldAssignPartitionField]: {
      type: "pickFieldId",
      metaId: fieldAssignPartitionField,
      name: fieldAssignPartitionField,
      label: "Field",
      required: true,
      formId: metaIdForm,
      showCompositeName: true,
      filterFieldTypeSet: ["text"]
    } as DefnStudioPickFieldId,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      name: defaultSectionKey,
      fieldIdSet: [fieldName, fieldAssignPartitionField, fieldFormula]
    } as DefnSection
  });
}

function dtoToValue(values?: StudioDtoPartition): FieldValues | undefined
{
  if(!values)
  {
    return undefined;
  }
  return {
    [fieldName]: fnRawValueToFieldValue("symbol", values.name),
    [fieldAssignPartitionField]: fnRawValueToFieldValue("pickFieldId", values.assignPartitionFieldId),
    [fieldFormula]: fnRawValueToFieldValue("studioCodeEditor", values.formula)
  };
}

function valueToDto(values: FieldValues, dtoFormula?: StudioDtoPartition)
{
  return {
    ...dtoFormula,
    metaId: dtoFormula?.metaId || nextMetaIdPartition(),
    assignPartitionFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldAssignPartitionField]),
    formula: fnFieldValueToRawValue("studioCodeEditor", values[fieldFormula]),
    name: fnFieldValueToRawValue("symbol", values[fieldName])
  } as StudioDtoPartition;
}
