import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldDateTimeRange} from "../../../../api/meta/base/dto/DefnFieldDateTimeRange";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueDateRange} from "../../../../api/meta/base/dto/FieldValueDateRange";
import {getBuildDateDefaultValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawDateTimeRange from "../raw/FieldRawDateTimeRange";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldDateTimeRange(props: {
  defn: DefnFieldDateTimeRange
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon,
    getFieldMinDateTime,
    getFieldMaxDateTime
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();

  const minDateTime = getFieldMinDateTime();
  const maxDateTime = getFieldMaxDateTime();

  const buildMinDateTime = minDateTime && getBuildDateDefaultValue(minDateTime,
    false,
    minDateTime.time
  );
  const buildMaxDateTime = maxDateTime && getBuildDateDefaultValue(maxDateTime,
    false,
    maxDateTime.time
  );

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueDateRange | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawDateTimeRange
                defn={defn}
                fieldValue={fieldValue}
                readOnly={readOnly}
                disabled={disabled}
                isError={isError}
                required={required}
                placeHolder={placeHolder}
                icon={icon}
                minDateTime={buildMinDateTime}
                maxDateTime={buildMaxDateTime}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />

              {
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message || helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );

      }}
    />
  );
}
