import {useTheme} from "@mui/material";
import {SimpleTreeView} from "@mui/x-tree-view";
import {TreeItem} from "@mui/x-tree-view/TreeItem";
import {isEqual} from "lodash";
import {useCallback} from "react";
import {useRef} from "react";
import React from "react";
import {SyntheticEvent} from "react";
import {ReactNode} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {searchHit} from "../../../base/plus/StringPlus";
import {TypeCbOnChangeTreeNodes} from "../../../base/types/TypesTree";
import {ITreeRef} from "../../../base/types/TypesTree";
import {TypeSelectedNodes} from "../../../base/types/TypesTree";
import {ITreeNode} from "../../../base/types/TypesTree";
import RawHighlighter from "./RawHighlighter";
import RawNothingHere from "./RawNothingHere";

export default function RawTree(props: {
  cbRef?: ITreeRef,
  rootNodes?: ITreeNode[],
  selectedNodes?: TypeSelectedNodes,
  searchStr?: string,
  defaultSelected?: string,
  defaultExpanded?: string[],
  showUiFlatTree?: boolean,
  showDividerBetweenNodes?: boolean,
  getLabel?: (node: ITreeNode, isFirst?: boolean, isLast?: boolean) => ReactNode
  onClick?: (nodeId: string) => void,
  onClickLeafNode?: (nodeId: string) => void,
  cbSelectedNode?: (nodeId: string) => void,
  onChange?: TypeCbOnChangeTreeNodes,
  onChangeExpand?: (expanded: string[]) => void
})
{
  const {
    rootNodes,
    onClick,
    onClickLeafNode,
    cbSelectedNode,
    defaultSelected,
    defaultExpanded,
    searchStr,
    getLabel,
    cbRef,
    onChange,
    onChangeExpand
  } = props;

  const rootNodesRef = useRef(rootNodes);

  if(!isEqual(rootNodesRef.current, rootNodes))
  {
    rootNodesRef.current = rootNodes;
  }

  const [visibleData, setVisibleData] = useState<ITreeNode[] | undefined>(rootNodes);
  const [expanded, setExpanded] = useState<string[]>(() => defaultExpanded ?? []);
  const [selected, setSelected] = useState(defaultSelected ?? "");

  const filterData = (search: string) =>
  {
    const searchStr = search?.trim();

    if(rootNodes)
    {
      let filtered = getFilteredNodes(rootNodes, searchStr);

      if(filtered && filtered.length > 0)
      {
        let expandedTemp: string[] = [];
        updateExpandedNodes(filtered, expandedTemp);
        setExpanded([...expandedTemp]);
      }
      setVisibleData(filtered);
    }
  };

  const nodeFind = (
    node: ITreeNode,
    nodes?: ITreeNode[],
    cb?: (index: number, nodes: ITreeNode[]) => void) =>
  {
    if(nodes)
    {
      for(let i = 0; i < nodes?.length; i++)
      {
        const currNode = nodes[i];

        if(currNode.id === node.id)
        {
          cb && cb(i, nodes);
          return;
        }
        else if(currNode.children)
        {
          nodeFind(node, currNode.children, cb);
        }
      }
    }
  };

  const nodeFindParent = (
    node: ITreeNode,
    nodes?: ITreeNode[],
    cb?: (parentNode: ITreeNode) => void,
    parentIndex?: number,
    parentNodes?: ITreeNode[]) =>
  {
    if(nodes)
    {
      for(let i = 0; i < nodes?.length; i++)
      {
        const currNode = nodes[i];

        if(currNode.id === node.id)
        {
          const currNodes = parentNodes ?? nodes;
          const index = parentIndex ?? i;

          cb && cb(currNodes[index]);
          return;
        }
        else if(currNode.children)
        {
          nodeFindParent(node, currNode.children, cb, i, nodes);
        }
      }
    }
  };

  const setValue = useCallback((rootNodes: ITreeNode[]) =>
  {
    onChange && onChange(rootNodes);
  }, []);

  const nodeAdd = useCallback((node: ITreeNode, parentNode?: ITreeNode) =>
  {
    if(parentNode)
    {
      nodeFind(parentNode, rootNodes, (index, nodes) =>
      {
        const children = nodes[index] && nodes[index].children;
        if(!children)
        {
          nodes[index].children = [node];
        }
        else if(!children.find(child => child.id === node.id))
        {
          children.push(node);
        }
        nodeExpand(parentNode);
        onChange && onChange(rootNodes);
      });
    }
    else
    {
      if(rootNodes && !rootNodes.find(child => child.id === node.id))
      {
        rootNodes.push(node);
        nodeExpand(node);
        onChange && onChange(rootNodes);
      }
    }
  }, [onChange, rootNodes]);

  const nodeUpdate = useCallback((newNode: ITreeNode, oldNode: ITreeNode) =>
  {
    nodeFind(oldNode, rootNodes, (index, nodes) =>
    {
      if(nodes[index])
      {
        nodes[index] = newNode;
      }
    });
    onChange && onChange(rootNodes);
  }, [onChange, rootNodes]);

  const nodeRemove = useCallback((node: ITreeNode) =>
  {
    nodeFind(node, rootNodes, (index, nodes) =>
    {
      nodes.splice(index, 1);
      onChange && onChange(rootNodes);
    });
  }, [onChange, rootNodes]);

  const nodeMoveUp = useCallback((node: ITreeNode) =>
  {
    nodeFind(node, rootNodes, (index, nodes) =>
    {
      const crrNode = nodes[index];
      nodes[index] = nodes[index - 1];
      nodes[index - 1] = crrNode;
      onChange && onChange(rootNodes);
    });
  }, [onChange, rootNodes]);

  const nodeMoveDown = useCallback((node: ITreeNode) =>
  {
    nodeFind(node, rootNodes, (index, nodes) =>
    {
      const crrNode = nodes[index];
      nodes[index] = nodes[index + 1];
      nodes[index + 1] = crrNode;
      onChange && onChange(rootNodes);
    });
  }, [onChange, rootNodes]);

  const nodeMoveTop = useCallback((node: ITreeNode) =>
  {
    nodeFind(node, rootNodes, (index, nodes) =>
    {
      const crrNode = nodes[index];
      nodes.splice(index, 1);
      nodes.unshift(crrNode);
      onChange && onChange(rootNodes);
    });
  }, [onChange, rootNodes]);

  const nodeMoveBottom = useCallback((node: ITreeNode) =>
  {
    nodeFind(node, rootNodes, (index, nodes) =>
    {
      const crrNode = nodes[index];
      nodes.splice(index, 1);
      nodes.push(crrNode);
      onChange && onChange(rootNodes);
    });
  }, [onChange, rootNodes]);

  const expandAll = () =>
  {
    if(rootNodes)
    {
      let expandedTemp: string[] = [];
      updateExpandedNodes(rootNodes, expandedTemp);
      setExpanded([...expandedTemp]);
    }
  };

  const collapseAll = () =>
  {
    setExpanded([]);
  };

  const nodeExpand = (node: ITreeNode) =>
  {
    if(node)
    {
      setExpanded(prevState => [...prevState, node.id]);
    }
  };

  const nodeCollapse = (node: ITreeNode) =>
  {
    if(node)
    {
      setExpanded(prevState => prevState.filter(id => id !== node.id));
    }
  };

  if(cbRef)
  {
    cbRef.nodeAdd = nodeAdd;
    cbRef.setValue = setValue;
    cbRef.nodeUpdate = nodeUpdate;
    cbRef.nodeRemove = nodeRemove;
    cbRef.nodeMoveUp = nodeMoveUp;
    cbRef.nodeMoveDown = nodeMoveDown;
    cbRef.nodeMoveTop = nodeMoveTop;
    cbRef.nodeMoveBottom = nodeMoveBottom;
    cbRef.expandAll = expandAll;
    cbRef.collapseAll = collapseAll;
    cbRef.nodeExpand = nodeExpand;
    cbRef.nodeCollapse = nodeCollapse;
    cbRef.nodeFind = nodeFind;
    cbRef.nodeFindParent = nodeFindParent;
  }

  useEffect(() =>
  {
    if(!searchStr || searchStr?.length === 0)
    {
      setVisibleData(rootNodes);
    }
    else
    {
      filterData(searchStr);
    }
  }, [searchStr, rootNodes]);

  useEffect(() =>
  {
    onChange && onChange(rootNodesRef.current);
  }, [rootNodesRef.current]);

  useEffect(() =>
  {
    onChangeExpand && onChangeExpand(expanded);
  }, [expanded]);

  if(visibleData?.length === 0)
  {
    return (
      <RawNothingHere />
    );
  }

  return (
    <SimpleTreeView
      defaultSelectedItems={defaultSelected}
      selectedItems={selected}
      expandedItems={expanded}
      multiSelect={false}
      onExpandedItemsChange={(_: SyntheticEvent, nodeIds: string[]) =>
      {
        setExpanded(nodeIds);
      }}
      onItemClick={(_: SyntheticEvent, nodeId: string) =>
      {
        cbSelectedNode && cbSelectedNode(nodeId);
        setSelected(nodeId);
      }}
      sx={{
        height: "100%",
        width: "100%",
        overflow: "auto"
      }}
    >
      <RenderTree
        children={visibleData}
        onClick={onClick}
        onClickLeafNode={onClickLeafNode}
        searchStr={searchStr}
        getLabel={getLabel}
        showUiFlatTree={props.showUiFlatTree}
      />
    </SimpleTreeView>
  );
}

function RenderTree(props: {
  children?: ITreeNode[],
  searchStr?: string,
  showUiFlatTree?: boolean,
  showDividerBetweenNodes?: boolean,
  getLabel?: (node: ITreeNode, isFirst?: boolean, isLast?: boolean) => ReactNode,
  onClick?: (nodeId: string) => void,
  onClickLeafNode?: (nodeId: string) => void,
  depth?: number
})
{
  const {
    children,
    onClick,
    onClickLeafNode,
    searchStr,
    getLabel,
    depth = 0
  } = props;
  const theme = useTheme();

  return (
    <>
      {
        children && children.map((node, index) =>
        {
          const isFirst = index === 0;
          const isLast = (children.length - 1) === index;
          return (
            <TreeItem
              key={node.id}
              itemId={node.id}
              sx={{
                "& .MuiTreeItem-content": {pr: 0},
                ...props.showUiFlatTree && {
                  "& .MuiTreeItem-group": {ml: 0}
                },
                bgcolor: node.bgcolor,
                borderColor: theme.common.bgcolorHover

              }}
              label={
                getLabel
                  ? getLabel(node, isFirst, isLast)
                  : <RawHighlighter
                    flexGrow={1}
                    variant={"subtitle1"}
                    value={node.name}
                    color={"textPrimary"}
                    {...searchStr && {searchWords: searchStr.split(" ")}} />
              }
              onClick={() =>
              {
                if(!node.children?.length)
                {
                  onClickLeafNode && onClickLeafNode(node.id);
                }
                onClick && onClick(node.id);
              }}
            >
              {
                Boolean(node.children && node.children?.length) &&
                <RenderTree
                  children={node.children}
                  onClick={onClick}
                  onClickLeafNode={onClickLeafNode}
                  searchStr={searchStr}
                  showUiFlatTree={props.showUiFlatTree}
                  showDividerBetweenNodes={props.showDividerBetweenNodes}
                  getLabel={getLabel}
                  depth={depth + 1}
                />
              }

            </TreeItem>
          );
        })
      }
    </>
  );
}

function defaultMatcher(node: ITreeNode, filterText: string)
{
  const searchWords = filterText.toLowerCase()
  .split(" ");
  return searchHit(node.name, searchWords);
}

function searchNode(
  node: ITreeNode,
  searchStr: string,
  matcher: (node: ITreeNode, filterText: string) => boolean): boolean
{
  return (
    matcher(node, searchStr) ||
    Boolean(node.children && node.children.find((child) => searchNode(child, searchStr, matcher)))
  );
}

function getFilteredNodes(nodes: ITreeNode[], searchStr: string)
{
  const filteredNodes: ITreeNode[] = [];
  nodes.forEach(node =>
  {
    const filteredNode = getFilteredNode(node, searchStr);
    filteredNode && filteredNodes.push(filteredNode);
  });
  return filteredNodes;
}

function getFilteredNode(
  node: ITreeNode,
  searchStr: string,
  matcher = defaultMatcher): ITreeNode | undefined
{
  if(matcher(node, searchStr) || !node.children)
  {
    return node;
  }

  const filteredParentNodes: ITreeNode[] = node.children.filter((child) => searchNode(child, searchStr, matcher));
  //removing unmatched children of node
  const filtered = filteredParentNodes.map((child) => getFilteredNode(child, searchStr, matcher));

  return filtered.length > 0 ? Object.assign({}, node, {children: filtered}) : undefined;
}

function updateExpandedNodes(nodes: ITreeNode[], result: string[])
{
  nodes.forEach(node =>
  {
    if(node.children)
    {
      result.push(node.id);
      updateExpandedNodes(node.children, result);
    }
  });
}
