import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import React from "react";
import {Noop} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldTime} from "../../../../api/meta/base/dto/DefnFieldTime";
import {AnyTime} from "../../../../api/meta/base/Types";
import {is12HourFormat} from "../../../../base/plus/DatePlus";
import {toTimeStringToDate} from "../../../../base/plus/DatePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {CLEAR} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {CLOSE} from "../../../../base/types/TypesIcon";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {getRawIcon} from "../../../atom/raw/RawIcon";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldTime(props: {
  defn: DefnFieldTime
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const {
    getFieldRequired,
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldIcon,
    getFieldShowSeconds
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const helperText = getFieldHelperText();
  const icon = getFieldIcon();
  const showSeconds = getFieldShowSeconds();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;

        const isError = Boolean(error);
        const fieldValue = field.value as AnyTime | undefined;

        const onChange = (value: string | undefined) =>
        {
          if(value)
          {
            field.onChange(value);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <RealFieldTime
                fieldValue={fieldValue}
                onChange={(value) =>
                {
                  onChange(value);
                }}
                defnTime={defn}
                isError={isError}
                error={error}
                onBlur={field.onBlur}
                onClick={onClick}
                required={required}
                placeHolder={placeHolder}
                showSeconds={showSeconds as boolean}
                icon={icon}
              />
              <FormHelperText
                error={isError}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1
                }}
              >
                {error?.message ? error.message : helperText}
              </FormHelperText>
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealFieldTime(props: {
  fieldValue?: string,
  onChange: (value?: AnyTime) => void,
  defnTime: DefnFieldTime,
  isError: boolean,
  error?: FieldError,
  onBlur?: Noop,
  required?: boolean,
  placeHolder?: string,
  showSeconds?: boolean
  onClick?: () => void,
  icon?: string,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defnTime;
  const fieldVariant = defnTheme.fieldVariant;
  const isError = props.isError;
  const onClick = props.onClick;
  const onBlur = props.onBlur;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const value = props.fieldValue
    ? toTimeStringToDate(props.fieldValue)
    : undefined;
  const label = getLabel(defn);
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const fieldId = getFieldKey(defn);
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const required = props.required;
  const placeHolder = props.placeHolder;
  const icon = props.icon;
  const showSeconds = props.showSeconds;
  const is12hrFormat = is12HourFormat(defn.displayDateFormat);
  const onChange = (date?: Date | null) =>
  {
    if(!date)
    {
      return;
    }

    const timeStr = date.toTimeString().split(" ")[0];
    props.onChange(timeStr);
  };

  return (
    <LayoutFlexRow
      width={"100%"}
      height={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
      alignItems={"start"}
      onClick={onClick}
    >
      <RawLocalizationProvider>
        <TimePicker
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          onChange={(value) => onChange(value)}
          value={value ?? null}
          label={label}
          views={showSeconds ? ["hours", "minutes", "seconds"] : ["hours", "minutes"]}
          ampm={is12hrFormat}
          timeSteps={{
            hours: 1,
            minutes: 1,
            seconds: 1
          }}
          closeOnSelect={true}
          readOnly={formCtx.isFieldReadonly(defn)}
          disabled={disabled}
          slots={{openPickerIcon: getRawIcon(icon ?? "AccessTime")}}
          slotProps={{
            textField: (params) => ({
              inputProps: {
                ...params.inputProps,
                onChange: () => undefined,
                "aria-readonly": true
              },

              fullWidth: true,
              type: "text",
              size: defnTheme.fieldSize,
              margin: defnTheme.fieldMargin,
              variant: fieldVariant,
              autoComplete: Boolean(defn.autoFill) ? "on" : "off",
              required: Boolean(required),
              onBlur: onBlur,
              error: isError,
              placeholder: placeHolder
            })
          }}
        />
      </RawLocalizationProvider>

      {!(readOnly || disabled) &&
        <LayoutFlexRow
          mt={px(gapQuarter)}
          ml={px(gapHalf)}
          mr={`-${gapHalf}px`}
        >
          <RawIconStrip
            toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
            iconStrip={[CLOSE]}
            onClick={(icon) =>
            {
              if(icon === CLOSE)
              {
                props.onChange(undefined);
              }
            }}
            iconStripDisable={(!value || readOnly || disabled) ? [CLOSE] : []}
          />
        </LayoutFlexRow>
      }
      <FieldRawRefButton
        defn={defn}
      />
    </LayoutFlexRow>
  );
}

