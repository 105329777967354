import {Property} from "csstype";
import {FocusEventHandler} from "react";
import {ReactNode} from "react";
import {forwardRef} from "react";
import React from "react";
import {RefCallBack} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {DefnStudioMapOfDtoOption} from "../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {MetaIdOption} from "../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../api/meta/base/Types";
import RawAutocomplete from "./RawAutocomplete";

type RawPickOneAutoCompleteProps = {
  width?: Property.Width,
  height?: Property.Height,
  inputFieldSize?: EnumDefnThemeFieldSize,
  fieldId: string,
  name: string,
  label?: string,
  optionMap?: DefnStudioMapOfDtoOption,
  value?: MetaIdOption,
  disableCloseOnSelect?: boolean,
  disableClearable?: boolean,
  disabled?: boolean,
  onChange?: (value: MetaIdOption | null) => void,
  fieldVariant?: EnumDefnThemeFieldVariant,
  fieldSize?: EnumDefnThemeFieldSize,
  error?: FieldError,
  helperText?: string,
  placeholder?: string,
  required?: boolean,
  icon?: string,
  autoFocus?: boolean,
  placeHolder?: string,
  hideLabel?: boolean,
  disableSelected?: boolean,
  loading?: boolean,
  readOnly?: boolean,
  onClick?: () => void,
  onOpen?: () => void,
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  endAdornment?: ReactNode,
  searchWords?: string[],
};

const RawPickOneAutoComplete = forwardRef<RefCallBack, RawPickOneAutoCompleteProps>((
  props: RawPickOneAutoCompleteProps,
  ref) =>
{
  return (
    <RawAutocomplete
      {...props}
      ref={ref}
      freeSolo={false}
      multiple={false}
      optionMap={props.optionMap}
      value={props.value}
      icon={props.icon}
      onChange={(data: MetaIdOption | MetaIdOption[] | null) =>
      {
        if(data && !Array.isArray(data))
        {
          props.onChange && props.onChange(data);
        }
        else
        {
          props.onChange && props.onChange(null);
        }
      }}
    />
  );
});

export default RawPickOneAutoComplete;
