import {FieldValueEntUserId} from "../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueLocation} from "../../api/meta/base/dto/FieldValueLocation";
import {GeoPoint} from "../../api/meta/base/Types";
import {Srvc} from "../../srvc/Srvc";
import {ILocationAddress} from "../types/TypesStudio";
import {ILatLng} from "../types/TypesStudio";
import {GOOGLE_MAP_API_KEY_PRODUCTION} from "./ConstantsPlus";

export const googleMapSearchUrl = "https://www.google.com/maps/search/?api=1&query=";

type CaptureValueType = "Time"
  | "Loc"
  | "User";

export interface ICaptureValue
{
  captureLocation?: FieldValueLocation;
  captureTime?: string;
  captureUser?: FieldValueEntUserId;
  icon?: string,
  type?: CaptureValueType
}

export interface GoogleMapAuthFailure extends Window
{
  gm_authFailure?: () => void;
}

export function getLatLngFromGeoPoint(value: string): ILatLng
{
  if(value)
  {
    const latLng = value.split(",");
    if(latLng.length === 2)
    {
      return {
        lat: parseFloat(latLng[0]),
        lng: parseFloat(latLng[1])
      };
    }
  }
  return {
    lat: 0,
    lng: 0
  };
}

export function getFormattedAddressFromApiResult(address_results: google.maps.GeocoderResult[]): ILocationAddress
{

  const geoPoint = {
    lat: address_results[0].geometry.location.lat(),
    lng: address_results[0].geometry.location.lng()
  } as ILatLng;

  let address = address_results[0].formatted_address;
  let city = "";
  let country = "";

  const address_components = address_results[0].address_components;

  address_components.forEach((component: google.maps.GeocoderAddressComponent) =>
  {
    const types = component.types;
    if(types.includes("locality"))
    {
      city = component.long_name;
    }
    if(types.includes("plus_code"))
    {
      const plus_code = component.long_name;
      address = address.replace(plus_code + ",", "");
    }
    else if(types.includes("country"))
    {
      country = component.long_name;
    }

  });

  return {
    address,
    city,
    country,
    geoPoint
  };
}

export const getGeoAddress = (
  latlng: ILatLng,
  cbSuccess?: (formatted_address: google.maps.GeocoderResult[], nearBySearchAddress?: string) => void,
  cbError?: (error: string) => void
) =>
{
  const geocoder = new google.maps.Geocoder();
  if(geocoder)
  {
    geocoder.geocode({"location": latlng}, function(results, status)
    {
      if(status === google.maps.GeocoderStatus.OK)
      {
        if(results)
        {
          cbSuccess && cbSuccess(results);
        }
      }
    });
  }
  else
  {
    Srvc.app.toast.showErrorToast(geocoder);
    cbError && cbError(geocoder);
  }
};

export const getLocation = (cbResult: (coords?: ILatLng) => void) =>
{
  if(navigator.geolocation)
  {
    navigator.geolocation.getCurrentPosition(
      (position) =>
      {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        } as ILatLng;
        cbResult(coords);
      },
      (_positionError) =>
      {
        cbResult();
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0
      }
    );
  }
};

export const browserLocationPermission = (cbResult: (result: boolean) => void) =>
{
  if(navigator.geolocation)
  {
    navigator.permissions.query({name: "geolocation"})
    .then((result) =>
    {
      switch(result?.state)
      {
        case "granted":
          cbResult(true);
          break;
        case "prompt":
          requestGeolocationPermission(permission =>
          {
            cbResult(permission);
          });
          break;
        case "denied":
          cbResult(false);
          break;

        default:
          cbResult(false);
      }
    });
  }
  else
  {
    cbResult(false);
  }
};
const requestGeolocationPermission = (cbResult: (permission: boolean) => void) =>
{
  if(navigator.geolocation)
  {
    navigator.geolocation.getCurrentPosition((_position) =>
      {
        cbResult(true);
      },
      (_positionError) =>
      {
        cbResult(false);
      },
      {
        timeout: 500,
        maximumAge: 0,
        enableHighAccuracy: true
      }
    );
  }
};

export function openSelectedGeoPoint(lat: number, lng: number)
{
  const mapUrl = `${googleMapSearchUrl}${lat},${lng}`;
  window.open(mapUrl);
}

export function toGeoPoint(lat: number, long: number): GeoPoint
{
  return `${lat},${long}`;
}

export function getGoogleMapApiKey()
{
  return GOOGLE_MAP_API_KEY_PRODUCTION;
  // return isProd()
  //   ? GOOGLE_MAP_API_KEY_PRODUCTION
  //   : GOOGLE_MAP_API_KEY_DEVELOPMENT;
}

export function prepareLocationThumbnail(latLng: ILatLng, globalGoogleMapApiKey: string): Promise<{file: Blob}>
{
  const latitude = latLng.lat;
  const longitude = latLng.lng;
  const url = `https://maps.googleapis.com/maps/api/staticmap?size=350x200&markers=color:red%7C${latitude},${longitude}&key=${globalGoogleMapApiKey}&path=${latitude},${longitude}`;

  return new Promise((resolve, reject) =>
  {
    fetch(url)
    .then(res => res.blob())
    .then(img =>
    {
      let file = new File([img], "jpg");
      resolve({file: file});
    }).catch(err =>
    {
      reject(err);
    });

  });
}
