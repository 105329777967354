import {Box} from "@mui/material";
import {Typography} from "@mui/material";
import {Property} from "csstype";
import {useMemo} from "react";
import React from "react";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldCamera} from "../../../../api/meta/base/dto/DefnFieldCamera";
import {DefnFieldImage} from "../../../../api/meta/base/dto/DefnFieldImage";
import {DefnFieldInfo} from "../../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldShowCode} from "../../../../api/meta/base/dto/DefnFieldShowCode";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../../base/plus/FieldValuePlus";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {fieldReportLeftLabel} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawLabel from "../../../atom/raw/RawLabel";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldBase from "./FieldBase";
import FieldRawKeyValuePair from "./FieldRawKeyValuePair";

export default function FieldRawTemplate(props: {
  defn: DefnField,
  fieldValue?: any,
  reportNode?: React.ReactNode
  children: React.ReactNode,
  flexGrow?: number
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldNode = props.children;
  const flexGrow = props.flexGrow;

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";

  const formSection = formSectionCtx.getParent();
  const isPropertyEditor = formSection.sectionVariant === "propertyEditor";

  if(isReport)
  {
    return (<Report {...props} />);
  }
  const padding = getDefnFieldPadding(defn);

  if(isPropertyEditor)
  {
    const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
    const defnLabel = {
      label: label
    } as DefnFieldLabel;

    return (
      <FieldBase
        fieldId={fieldId}
        flexGrow={flexGrow}
        {...padding}
      >
        <FieldRawKeyValuePair
          leftHeight={labelHeight}
          left={
            <RawLabel defn={defnLabel} value={label} />
          }
          right={fieldNode}
          flexGrow={flexGrow}
        />
      </FieldBase>
    );
  }

  return (
    <FieldBase
      fieldId={fieldId}
      flexGrow={flexGrow}
      {...padding}
    >
      {fieldNode}
    </FieldBase>
  );

}

function Report(props: {
  defn: DefnField,
  fieldValue?: any,
  reportNode?: React.ReactNode
})
{
  const defn = props.defn;
  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const form = formSectionCtx.getDefnForm();
  const fieldId = getFieldKey(defn);

  const padding = getDefnFieldPadding(defn);

  const isDefnShowLabel = useMemo(() =>
  {
    switch(defn.type)
    {
      case "camera":
      case "image":
      case "showCode":
      case  "info":
        return Boolean((defn as DefnFieldCamera | DefnFieldImage | DefnFieldShowCode | DefnFieldInfo).showLabel);
      default:
        return true;
    }
  }, [defn]);

  const maxWithForLeft = useMemo(() => getMaxWithForLeft(form, formSection), [form, formSection]);

  return (
    <FieldBase
      fieldId={fieldId}
      {...padding}
    >
      {isDefnShowLabel
        ? <Box
          id={fieldId}
          flexDirection={"row"}
          display={"flex"}
          sx={{
            display: "flex",
            overflow: "hidden",
            flexWrap: "nowrap",
            textOverflow: "ellipsis"
          }}
        >
          <RawHighlighter
            variant={theme.common.reportFieldLabelVariant}
            value={fieldReportLeftLabel(defn)}
            width={maxWithForLeft}
          />
          <ReportFactory {...props} />
        </Box>
        : <ReportFactory {...props} />
      }

    </FieldBase>
  );
}

function ReportFactory(props: {
  defn: DefnField,
  fieldValue?: any,
  reportNode?: React.ReactNode
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const reportNode = props.reportNode;

  const fieldId = getFieldKey(defn);
  const fieldHyperlinkRow = (defn as DefnFieldUi)._parentHyperlinkRowField;

  if(reportNode)
  {
    return reportNode;
  }

  const onClick = fieldHyperlinkRow
    ? () =>
    {
      const getOnClick = formCtx.getOnClick();
      getOnClick && getOnClick(fieldId, "hyperlinkRow", fieldValue);
    } : undefined;

  return (
    <Typography
      component={"span"}
      variant={theme.common.reportFieldLabelVariant}
      flexWrap={"nowrap"}
      textOverflow={"ellipsis"}
      color={fieldHyperlinkRow ? theme.palette.primary.main : "textSecondary"}
      sx={{textDecoration: fieldHyperlinkRow ? "underline" : "none"}}
      textAlign={"left"}
      onClick={onClick}
    >
      {getFormFieldValueAsTextWithPrefixSuffix(defn, fieldValue)}
    </Typography>
  );
}

function getMaxWithForLeft(defnForm?: DefnFormUi, formSection?: DefnSection): Property.Width | undefined
{
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if(context)
  {
    context.font = "15px Roboto";
  }
  let maxWidth = 0;
  formSection?.fieldIdSet?.forEach((fieldId) =>
  {
    const defn = defnForm?.compMap[fieldId] as DefnField;

    const label = !defn.hidden ? fieldReportLeftLabel(defn) : "";
    const width = context?.measureText(label)?.width || 0;
    maxWidth = Math.floor(Math.max(maxWidth, width));
  });
  return px(maxWidth);
}
