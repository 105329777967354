import {useTheme} from "@mui/material";
import React from "react";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {FormValue} from "../../../../../api/meta/base/dto/FormValue";
import {MediaId} from "../../../../../api/meta/base/Types";
import {px} from "../../../../../base/plus/StringPlus";
import {RootState} from "../../../../../Store";
import LayoutFlexRow from "../../../layout/LayoutFlexRow";
import {LayoutGap} from "../../../layout/LayoutGap";
import BubbleRawFormMediaCarousel from "./BubbleRawFormMediaCarousel";

export default function BubbleRawFormMedia(props: {
  defnForm?: DefnForm,
  formValue?: FormValue,
  maxWidth?: number,
  isFormWithMedia?: boolean,
  getMediaSrc?: (state: RootState, mediaId?: MediaId) => string | undefined,
})
{
  const defnForm = props.defnForm;
  if(defnForm !== undefined && props.isFormWithMedia)
  {
    return (
      <RealBubbleFormMedia
        defnForm={defnForm}
        {...props}
      />
    );
  }
  return null;
}

function RealBubbleFormMedia(props: {
  defnForm: DefnForm,
  formValue?: FormValue,
  maxWidth?: number,
  isFormWithMedia?: boolean,
  getMediaSrc?: (state: RootState, mediaId?: MediaId) => string | undefined
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;

  const defnForm = props.defnForm;
  const formValue = props.formValue;

  return (
    <LayoutFlexRow
      overflowX={"visible"}
      overflowY={"visible"}
      mb={px(theme.common.bubbleHeightLine)}
      onClick={(e => e.stopPropagation())}
    >
      <BubbleRawFormMediaCarousel
        defnForm={defnForm}
        valueMap={formValue?.valueMap ?? {}}
        maxWidth={props.maxWidth}
        getMediaSrc={props.getMediaSrc}
      />
      <LayoutGap height={px(gapHalf)} />
    </LayoutFlexRow>
  );
}
