import {EnumMessageType, EnumReceiptStatus} from "../../api/home/base/Types";

export const CLOSE: EnumIconStrip = "close";
export const CLEAR = "Clear";
export type EnumIconAvatar =
  | "group"
  | "channel"
  | "user"
  | "form"
  | "inbox"
  | "enterprise"
  | "post"
  | "poll"
  | "sheet"
  | "dashboard"
  | "notifications"
  | "help"
  | "document"
  | "image"
  | "camera"
  | "public"
  | "addPerson"
  | "insertLink"
  | "share"
  | "msgDelete"
  | "plus"
  | "minus"
  | "plugin"
  | "store"
  | "grid"
  | "template"
  | undefined;

export type EnumIconListItemPrimary1 =
  | "pin"

export type EnumIconListItemPrimary2 =
  | "pin";

export type EnumIconListItemSecondary1 =
  | "send"
  | EnumReceiptStatus;

export type EnumIconListItemSecondary2 =
  | "poll"
  | "report"
  | "approval"
  | EnumMessageType;

export type EnumIconListItemSecondary3 =
  | "pin"
  | "lock"
  | "unlock"
  | "queue";

export type EnumIconListItemSecondary4 =
  | "pin"
  | "lock"
  | "unlock"
  | "queue"
  | "admin"
  | "mute";

export type EnumIconInputCustom =
  | "addLocation"
  | "badge"
  | "architecture"
  | "map"
  | "grid"
  | "addPerson"
  | "forward"
  | "whatsApp"
  | "contentCopy"
  | "reset"
  | "expandMore"
  | "share"
  | "microsoft";

export type EnumIconRawSpeedDial =
  | "user"
  | "document"
  | "image"
  | "camera"
  | "group"
  | "form"
  | "location"
  | undefined

export type EnumIconStrip =
  | "attach"
  | "backIos"
  | "close"
  | "clear"
  | "copy"
  | "copyEntId"
  | "copyUserId"
  | "debug"
  | "delete"
  | "download"
  | "edit"
  | "error"
  | "filter"
  | "filterClear"
  | "forward"
  | "forwardIos"
  | "grid"
  | "handle"
  | "heart"
  | "help"
  | "hyperlink"
  | "list"
  | "loading"
  | "map"
  | "minus"
  | "more"
  | "moreHorizontal"
  | "msgDelete"
  | "miniMize"
  | "openInFull"
  | "place"
  | "plus"
  | "print"
  | "ringBell"
  | "save"
  | "scanCode"
  | "search"
  | "send"
  | "share"
  | "smile"
  | "star"
  | "table"
  | "unStar"
  | "unStarAll"
  | "paste"
  | "document"
  | "currentLocation"
  | "comment"
  | "cart"
  | "upload"
  | "expand"
  | "collapse"
  | "location"
  | "hashtag"
  | "email"
  | "mobileNumber"
  | "settings"
  | "symbol"
  | "prompt"
  | "lock"
  | "expandArrowUp"
  | "expandArrowDown"
  | "sortAsc"
  | "sortDesc"
  | "expandArrowRight"
  | "unlock"
  | "chat"
  | "terminal"
  | "visible"
  | "refresh"
  | "add"
  | "hideVisible"
  | "sortBy"

export type EnumDefnColoredIcon =
  | "WhatsAppColored"
  | "GoogleColored"
  | "MicroSoftColored"
  | "PasswordColored"
  | "QrCodeColored";
