import {isArray} from "lodash";
import {cloneDeep} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import {useCallback} from "react";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {StudioComposite} from "../../api/meta/base/dto/StudioComposite";
import {StudioEntPluginApi} from "../../api/meta/base/dto/StudioEntPluginApi";
import {StudioField} from "../../api/meta/base/dto/StudioField";
import {StudioFieldMap} from "../../api/meta/base/dto/StudioFieldMap";
import {StudioForm} from "../../api/meta/base/dto/StudioForm";
import {StudioFormMap} from "../../api/meta/base/dto/StudioFormMap";
import {StudioGrid} from "../../api/meta/base/dto/StudioGrid";
import {StudioModuleSelection} from "../../api/meta/base/dto/StudioModuleSelection";
import {StudioSection} from "../../api/meta/base/dto/StudioSection";
import {MetaId} from "../../api/meta/base/Types";
import {EnumDefnFields} from "../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../api/meta/base/Types";
import {EnumDefnForms} from "../../api/meta/base/Types";
import {PluginApiId} from "../../api/meta/base/Types";
import {EnumStudioCompType} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {MetaIdComp} from "../../api/meta/base/Types";
import {FormStore} from "../types/TypesForm";
import {loopDefnForm} from "./FormPlus";

type EnumCompositePickerType = "section" | "grid"

// loopStudioForm
export function loopStudioForm(
  studioForm: StudioForm,
  cb: (composite: StudioComposite, field: StudioField) => boolean | void): void
{
  const compositeMap = studioForm.compositeMap;
  const keys = compositeMap?.keys;
  const map = compositeMap?.map;
  if(!compositeMap || !keys || !map)
  {
    return;
  }

  for(let i = 0; i < keys.length; i++)
  {
    const key = keys[i];
    const comp = map[key];

    loopStudioFormFieldMap(comp?.fieldMap, (field) =>
    {
      if(cb(comp, field))
      {
        return true;
      }
    });
  }
}

function loopStudioFormFieldMap(fieldMap?: StudioFieldMap, cb?: (field: StudioField) => boolean | void)
{
  if(fieldMap)
  {
    for(let j = 0; j < fieldMap.keys?.length; j++)
    {
      const fieldId = fieldMap.keys[j];
      const field = fieldMap.map[fieldId];
      if(cb && cb(field))
      {
        return;
      }
    }
  }
}

// endregion

export function getPickCompositeOptions(
  studioForm: StudioForm,
  defnKindFormComposite?: EnumCompositePickerType): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];
  const compositeMap = studioForm.compositeMap;
  if(!compositeMap)
  {
    return options;
  }
  compositeMap.keys.forEach((key) =>
  {
    const studioComposite = compositeMap.map[key];
    const label = studioComposite.details.name;
    const optionId = (studioComposite as StudioSection | StudioGrid).metaId;
    if(studioComposite.type === "section" || studioComposite.type === "grid")
    {
      if(!defnKindFormComposite || studioComposite.type === defnKindFormComposite)
      {
        options.push({
          value: label || optionId,
          metaId: optionId
        });
      }
    }
  });
  return options;
}

export function getStudioFormParentMap(studioForm?: StudioForm): Record<MetaId, StudioComposite | undefined>
{
  const formParentMap = {} as Record<MetaId, StudioComposite | undefined>;
  if(studioForm)
  {
    const compositeMap = studioForm.compositeMap;
    if(!compositeMap)
    {
      return formParentMap;
    }
    compositeMap.keys.forEach((key) =>
    {
      const comp = compositeMap.map[key];
      comp.fieldMap?.keys.forEach((fieldMetaId) =>
      {
        formParentMap[fieldMetaId] = comp;
      });

    });
  }
  return formParentMap;
}

export function getDefnFormParentMap(defnForm?: DefnForm): Record<MetaId, DefnComp | undefined>
{
  const formParentMap = {} as Record<MetaId, DefnComp | undefined>;

  if(defnForm)
  {
    loopDefnForm(defnForm, (comp, field) =>
    {
      formParentMap[field.metaId] = comp;
    });
  }

  return formParentMap;
}

export function getStudioFormField(
  studioForm: StudioForm,
  fieldId: MetaIdField,
  parentCompId: MetaIdComp | MetaIdComposite)
{
  return studioForm.compositeMap.map[parentCompId]?.fieldMap?.map[fieldId];
}

export function findStudioFormField(
  studioForm: StudioForm,
  fieldId: MetaIdField)
{
  const keys = studioForm.compositeMap.keys;
  for(let i = 0; i < keys.length; i++)
  {
    const compositeId = keys[i];
    const field = getStudioFormField(studioForm, fieldId, compositeId);
    if(field)
    {
      return field;
    }
  }
}

export function filterStudioFormsByModules(
  studioFormMap: StudioFormMap,
  filterListByTag: (modules?: StudioModuleSelection) => boolean): StudioFormMap
{
  let cloneFormMap = cloneDeep(studioFormMap);
  cloneFormMap.keys = cloneFormMap.keys.filter((key) =>
  {
    const composite = cloneFormMap.map[key];
    if(filterListByTag(composite.details.modules))
    {
      return true;
    }
    else
    {
      delete cloneFormMap.map[key];
      return false;
    }
  });

  return cloneFormMap;
}

export function filterStudioFormByModules(
  studioForm: StudioForm,
  filterListByTag: (modules?: StudioModuleSelection) => boolean): StudioForm
{
  let compositeMap = cloneDeep(studioForm.compositeMap);
  compositeMap.keys = compositeMap.keys.filter((compositeId) =>
  {
    let comp = compositeMap.map[compositeId];

    comp.fieldMap = filterStudioFormFieldsByModules(cloneDeep(comp.fieldMap), filterListByTag);

    if(filterListByTag(comp.details.modules))
    {
      compositeMap.map[compositeId] = comp;
      return true;
    }
    else
    {
      delete compositeMap.map[compositeId];
    }
  });

  return {
    ...studioForm,
    compositeMap: compositeMap
  };
}

const filterStudioFormFieldsByModules = (
  fieldMap: StudioFieldMap,
  filterListByTag: (modules?: StudioModuleSelection) => boolean) =>
{
  const newFieldMap = {
    map: {},
    keys: []
  } as StudioFieldMap;

  fieldMap.keys.forEach((fieldId) =>
  {
    const field = fieldMap.map[fieldId];
    if(filterListByTag(field?.details?.modules))
    {
      newFieldMap.map[fieldId] = field;
      newFieldMap.keys.push(fieldId);
    }
  });
  return newFieldMap;
};

export function getStudioFieldType(
  fieldId: MetaIdField,
  studioForm?: StudioForm,
  defnForm?: DefnForm): EnumDefnCompType | undefined
{
  let fieldType = undefined;

  if(studioForm)
  {
    loopStudioForm(studioForm, (_, field) =>
    {
      if(fieldId === field.metaId)
      {
        fieldType = field.type;
      }
    });
  }

  if(defnForm)
  {
    fieldType = defnForm.compMap[fieldId].type;
  }

  return fieldType;
}

export function getExcludePluginApiIdSetByFieldType(
  pluginApiMap: Record<PluginApiId, StudioEntPluginApi>,
  fieldType?: EnumStudioCompType
): PluginApiId[] | undefined
{
  const excludePluginApiIdSet: PluginApiId[] = [];

  Object.keys(pluginApiMap).forEach((pluginApi) =>
  {
    const studioEntPluginApi = pluginApiMap[pluginApi];

    const outputFormId = studioEntPluginApi?.outputFormId;

    if(!outputFormId)
    {
      return undefined;
    }

    if(fieldType === "pickText" || fieldType === "setOfText")
    {
      const formPickText = "$FormMapOfOptions" as EnumDefnForms;
      if(outputFormId === formPickText)
      {
        return;
      }
      else
      {
        const pluginApiId = studioEntPluginApi.pluginApiId;
        excludePluginApiIdSet.push(pluginApiId);
      }
    }
    else if(fieldType === "pickTree")
    {
      const formPickTree = "$FormPickTree" as EnumDefnForms;
      if(outputFormId === formPickTree)
      {
        return;
      }
      else
      {
        const pluginApiId = studioEntPluginApi.pluginApiId;
        excludePluginApiIdSet.push(pluginApiId);
      }
    }
    else if(fieldType === "pickUser" || fieldType === "setOfUser")
    {
      const formPickUser = "$FormSetOfUser" as EnumDefnForms;
      if(outputFormId === formPickUser)
      {
        return;
      }
      else
      {
        const pluginApiId = studioEntPluginApi.pluginApiId;
        excludePluginApiIdSet.push(pluginApiId);
      }
    }
  });

  return excludePluginApiIdSet;

}

export function getSystemFieldComp(fieldId: EnumDefnFields)
{
  return {
    metaId: fieldId,
    name: fieldId,
    label: fieldId.slice(1),
    type: getSystemFieldType(fieldId as EnumDefnFields)
  } as DefnField;
}

export function isSystemField(fieldId: string): fieldId is EnumDefnFields
{
  return EnumArrayDefnFields.includes(fieldId as EnumDefnFields);
}

export function getSystemFieldType(field: EnumDefnFields): EnumStudioCompType
{
  switch(field)
  {
    case "$RowId":
    case "$ParentRowId":
      return "rowId";
    case "$CreatedBy":
    case "$UpdatedBy":
      return "pickUser";
    case "$CreatedOn":
    case "$UpdatedOn":
      return "dateTime";
    case "$RowOrder":
      return "number";
  }
}

export function getSystemFieldTypeSet(field: EnumDefnFields): EnumStudioCompType[]
{
  switch(field)
  {
    case "$RowId":
    case "$ParentRowId":
      return ["rowId"];
    case "$CreatedBy":
    case "$UpdatedBy":
      return ["pickUser", "userId"];
    case "$CreatedOn":
    case "$UpdatedOn":
      return ["dateTime"];
    case "$RowOrder":
      return ["number"];
  }
}

// region trash
export interface TrashItem
{
  metaId: MetaId,
  name: string
}

type TypeTrashItem =
  | "actionMap"
  | "compositeMap"
  | "contentMap"
  | "fieldMap"
  | "formMap"
  | "groupMap"
  | "layoutGridMap"
  | "layoutSpreadsheetMap"
  | "reportMap"
  | "roleMap"
  | "spreadsheetMap"
  | "varMap"
  | "moduleMap"
  | "deepLinkMap"
  | "automationMap"

interface TypeItem
{
  metaId: string,
  details: {
    name: string
  }
}

function getMetaIdAndName<T extends TypeItem>(item?: T): TrashItem | undefined
{
  if(item?.metaId && item.details.name)
  {
    return {
      name: item.details.name,
      metaId: item.metaId
    };
  }
}

function getDefnItemTrash(formStore: FormStore, type: TypeTrashItem, itemId?: MetaId)
{
  const trash = formStore.trash;
  if(!trash || !itemId)
  {
    return;
  }

  switch(type)
  {
    case "moduleMap":
    {
      const item = trash.moduleMap
        ? trash.moduleMap[itemId]
        : undefined;

      if(item)
      {
        return getMetaIdAndName({
          metaId: itemId,
          details: {
            name: item
          }
        });
      }
      return undefined;
    }
    case "actionMap":
    {
      const item = trash.actionMap
        ? trash.actionMap[itemId]
        : undefined;

      return getMetaIdAndName(item);
    }
    case "compositeMap":
    {
      const item = trash.compositeMap
        ? trash.compositeMap[itemId]
        : undefined;

      if(item)
      {
        return getMetaIdAndName({
          ...item,
          metaId: itemId
        });
      }
    }
      break;
    case "contentMap":
    {
      const item = trash.contentMap
        ? trash.contentMap[itemId]
        : undefined;

      if(item)
      {
        return getMetaIdAndName({
          metaId: itemId,
          details: {
            name: `${item.name}`
          }
        });
      }
    }
      break;
    case "fieldMap":
    {
      const item = trash.fieldMap
        ? trash.fieldMap[itemId]
        : undefined;

      return getMetaIdAndName(item);
    }
    case "formMap":
    {
      const item = trash.formMap
        ? trash.formMap[itemId]
        : undefined;

      return getMetaIdAndName(item);
    }
    case "groupMap":
    {
      const item = trash.groupMap
        ? trash.groupMap[itemId]
        : undefined;
      return getMetaIdAndName(item);
    }
    case "layoutGridMap":
    {
      const item = trash.layoutGridMap
        ? trash.layoutGridMap[itemId]
        : undefined;

      if(item)
      {
        return getMetaIdAndName({
          metaId: itemId,
          details: {
            name: item.name
          }
        });
      }
    }
      break;
    case "layoutSpreadsheetMap":
    {
      const item = trash.layoutGridMap
        ? trash.layoutGridMap[itemId]
        : undefined;

      if(item)
      {
        return getMetaIdAndName({
          metaId: itemId,
          details: {
            name: item.name
          }
        });
      }
    }
      break;
    case "reportMap":
    {
      const item = trash.reportMap
        ? trash.reportMap[itemId]
        : undefined;
      return getMetaIdAndName(item);
    }
    case "roleMap":
    {
      const item = trash.roleMap
        ? trash.roleMap[itemId]
        : undefined;
      return getMetaIdAndName(item);
    }
    case "spreadsheetMap":
    {
      const item = trash.spreadsheetMap
        ? trash.spreadsheetMap[itemId]
        : undefined;
      return getMetaIdAndName(item);
    }
    case "varMap":
    {
      const item = trash.varMap
        ? trash.varMap[itemId]
        : undefined;
      return getMetaIdAndName(item);
    }
  }
}

function fnInsertTrashItemInOptions(
  type: TypeTrashItem,
  itemIds: MetaId[],
  options: DefnDtoOption[],
  formStore?: FormStore)
{
  if(formStore)
  {
    itemIds.forEach(itemId =>
    {
      const item = getDefnItemTrash(formStore, type, itemId);
      if(item)
      {
        if(!options.find(option => option.metaId === item.metaId))
        {
          options.push({
            value: item.name,
            metaId: item.metaId,
            isRemoved: true
          });
        }
      }
    });
  }
  return options;
}

export function useInsertTrashOptions(props: {
  type: TypeTrashItem,
  fieldValue: MetaId | MetaId[] | undefined,
  originalOptions?: DefnDtoOption[],
  formStore?: FormStore
})
{
  const type = props.type;
  const fieldValue = props.fieldValue;
  const formStore = props.formStore;
  const originalOptions = props.originalOptions;

  const getTrashOptions = useCallback(() =>
  {
    const _options: DefnDtoOption[] = [];

    if(fieldValue)
    {
      const metaIds = isArray(fieldValue) ? fieldValue : [fieldValue];
      return fnInsertTrashItemInOptions(type, metaIds, _options, formStore);
    }
    return [];
  }, [fieldValue, formStore, type]);

  const [trashOptions, setTrashOptions] = useState<DefnDtoOption[]>(getTrashOptions());
  const [options, setOptions] = useState<DefnDtoOption[]>(originalOptions || []);
  const [combineOptions, setCombineOptions] = useState([...options, ...trashOptions]);

  useEffect(() =>
  {
    setTrashOptions(getTrashOptions());
  }, [getTrashOptions]);

  useEffect(() =>
  {
    setCombineOptions([...options, ...trashOptions]);
  }, [options, trashOptions]);

  useEffect(() =>
  {
    setOptions(originalOptions || []);
  }, [originalOptions]);

  return [combineOptions] as const;
}
