import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDate} from "../../../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnStudioBuildDate} from "../../../../../api/meta/base/dto/DefnStudioBuildDate";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldDateRange} from "../../../../../api/meta/base/dto/StudioFieldDateRange";
import {TimeZoneKey} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {getDefaultDate} from "../../base/FieldBuilderPlus";
import {propKeyMinCustomDateRange} from "../../base/TypesFormBuilder";
import {propKeyMaxCustomDateRange} from "../../base/TypesFormBuilder";
import {fieldGap} from "../../base/TypesFormBuilder";
import {getFieldGap} from "../../base/TypesFormBuilder";
import {propKeyToDefaultDateRangeCustom} from "../../base/TypesFormBuilder";
import {propKeyFromDefaultDateRangeCustom} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyToDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyFromDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyToDefault} from "../../base/TypesFormBuilder";
import {propKeyFromDefault} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyToDefaultVarId} from "../../base/TypesFormBuilder";
import {propKeyFromDefaultVarId} from "../../base/TypesFormBuilder";
import {propKeyDisplayDateFormat} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

const defaultValueLabel = "defaultValueLabel";

export function getDefnFieldDateRange(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  showCustomDateFrom?: boolean,
  showCustomDateTo?: boolean,
  showCustomMax?: boolean,
  showCustomMin?: boolean,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[],
  timeZone?: TimeZoneKey
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    defaultValueLabel,
    propKeyFromDefaultFieldId,
    fieldGap,
    propKeyToDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinFieldId,
    propKeyMaxFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    defaultValueLabel,
    propKeyFromDefaultVarId,
    fieldGap,
    propKeyToDefaultVarId,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinVar,
    propKeyMaxVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    defaultValueLabel,
    propKeyFromDefault,
    ...showCustomDateFrom ? [propKeyFromDefaultDateRangeCustom] : [],
    fieldGap,
    propKeyToDefault,
    ...showCustomDateTo ? [propKeyToDefaultDateRangeCustom] : [],
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMin,
    ...showCustomMin ? [propKeyMinCustomDateRange] : [],
    propKeyMax,
    ...showCustomMax ? [propKeyMaxCustomDateRange] : [],
    fieldGap4,
    propKeyDisplayDateFormat
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "dateRange",
      formId,
      "date",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId,
      undefined,
      timeZone
    ),

    [defaultValueLabel]: {
      type: "label",
      metaId: defaultValueLabel,
      name: defaultValueLabel,
      label: "Default value",
      disabled: true
    } as DefnFieldLabel,

    ...getFieldGap(fieldGap),

    [propKeyFromDefault]: {
      type: "studioBuildDate",
      metaId: propKeyFromDefault,
      name: propKeyFromDefault,
      label: "Form"
    } as DefnStudioBuildDate,

    ...showCustomDateFrom && {
      [propKeyFromDefaultDateRangeCustom]: {
        type: "date",
        metaId: propKeyFromDefaultDateRangeCustom,
        name: propKeyFromDefaultDateRangeCustom,
        label: "From custom date",
        timeZone: timeZone
      } as DefnFieldDate
    },

    [propKeyFromDefaultVarId]: {
      type: "pickVarId",
      name: propKeyFromDefaultVarId,
      metaId: propKeyFromDefaultVarId,
      label: "Form",
      varKind: "date",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyFromDefaultFieldId]: {
      type: "pickFieldId",
      metaId: propKeyFromDefaultFieldId,
      name: propKeyFromDefaultFieldId,
      label: "From",
      formId: formId,
      filterFieldTypeSet: ["date"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyToDefault]: {
      type: "studioBuildDate",
      metaId: propKeyToDefault,
      name: propKeyToDefault,
      label: "To",
      timeZone: timeZone
    } as DefnFieldDate,

    ...showCustomDateTo && {
      [propKeyToDefaultDateRangeCustom]: {
        type: "date",
        metaId: propKeyToDefaultDateRangeCustom,
        name: propKeyToDefaultDateRangeCustom,
        label: "To custom date",
        timeZone: timeZone
      } as DefnFieldDate
    },

    ...showCustomMax && {
      [propKeyMaxCustomDateRange]: {
        type: "date",
        metaId: propKeyMaxCustomDateRange,
        name: propKeyMaxCustomDateRange,
        label: "Custom max date",
        timeZone: timeZone
      } as DefnFieldDate
    },

    ...showCustomMin && {
      [propKeyMinCustomDateRange]: {
        type: "date",
        metaId: propKeyMinCustomDateRange,
        name: propKeyMinCustomDateRange,
        label: "Custom min date",
        timeZone: timeZone
      } as DefnFieldDate
    },

    [propKeyToDefaultVarId]: {
      type: "pickVarId",
      name: propKeyToDefaultVarId,
      metaId: propKeyToDefaultVarId,
      label: "To",
      varKind: "date",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyToDefaultFieldId]: {
      type: "pickFieldId",
      metaId: propKeyToDefaultFieldId,
      name: propKeyToDefaultFieldId,
      label: "To",
      formId: formId,
      filterFieldTypeSet: ["date"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyDisplayDateFormat]: {
      type: "dateFormat",
      name: propKeyDisplayDateFormat,
      metaId: propKeyDisplayDateFormat,
      label: "Display date format"
    } as DefnFieldPickEnum
  };
}

export function defnValueToStudioFieldDateRange(values: FieldValues): StudioFieldDateRange
{
  return {
    type: "dateRange",
    fromDefault: getDefaultDate(values, propKeyFromDefault, propKeyFromDefaultDateRangeCustom),
    fromDefaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyFromDefaultVarId]),
    fromDefaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyFromDefaultFieldId]),
    toDefault: getDefaultDate(values, propKeyToDefault, propKeyToDefaultDateRangeCustom),
    toDefaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyToDefaultVarId]),
    toDefaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyToDefaultFieldId]),
    min: getDefaultDate(values, propKeyMin, propKeyMinCustomDateRange),
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    max: getDefaultDate(values, propKeyMax, propKeyMaxCustomDateRange),
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    displayDateFormat: fnFieldValueToRawValue("dateFormat", values[propKeyDisplayDateFormat])
  } as StudioFieldDateRange;
}

export function studioFieldToDefnValueDateRange(studioField: StudioFieldDateRange)
{

  const defaultValueFrom = studioField.fromDefault;
  const isCustomValueFrom = fnRawValueToFieldValue("date", defaultValueFrom?.customValue);

  const defaultValueTo = studioField.toDefault;
  const isCustomValueTo = fnRawValueToFieldValue("date", defaultValueTo?.customValue);

  const minValue = studioField.min;
  const isCustomMinValue = fnRawValueToFieldValue("date", minValue?.customValue);

  const maxValue = studioField.max;
  const isCustomMaxValue = fnRawValueToFieldValue("date", maxValue?.customValue);

  return {
    [propKeyFromDefault]: isCustomValueFrom ? "custom" : defaultValueFrom?.value,
    [propKeyFromDefaultDateRangeCustom]: isCustomValueFrom,
    [propKeyToDefault]: isCustomValueTo ? "custom" : defaultValueTo?.value,
    [propKeyToDefaultDateRangeCustom]: isCustomValueTo,
    [propKeyFromDefaultVarId]: fnRawValueToFieldValue("pickVarId", studioField.fromDefaultVarId),
    [propKeyFromDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.fromDefaultFieldId),
    [propKeyToDefaultVarId]: fnRawValueToFieldValue("pickVarId", studioField.toDefaultVarId),
    [propKeyToDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.toDefaultFieldId),
    [propKeyMin]: isCustomMinValue ? "custom" : minValue?.value,
    [propKeyMinCustomDateRange]: isCustomMinValue,
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMax]: isCustomMaxValue ? "custom" : maxValue?.value,
    [propKeyMaxCustomDateRange]: isCustomMaxValue,
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyDisplayDateFormat]: fnRawValueToFieldValue("dateFormat", studioField.displayDateFormat)
  };
}
