import {Typography} from "@mui/material";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {DtoMessagePayloadImage} from "../../../../api/home/base/dto/DtoMessagePayloadImage";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {MediaId} from "../../../../api/meta/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../base/plus/ConstantsPlus";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {TypeChatImageMode} from "../../../../base/types/TypesChat";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import {RootState} from "../../../../Store";
import {useAppSelector} from "../../../app/AppHooks";
import {usePageCtx} from "../../../ctx/CtxPage";
import ImageViewDialog from "../../avatar/ImageViewDialog";
import IconBubbleCaption from "../../icon/IconBubbleCaption";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import RawImage from "../../raw/RawImage";
import RawLazyImage from "../../raw/RawLazyImage";
import RawLinerGradient from "../../raw/RawLinerGradient";
import BubbleRawCaption from "../raw/BubbleRawCaption";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawLinkText from "../raw/BubbleRawLinkText";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleShell from "./BubbleShell";

export default function BubbleImage(props: {
  payload: DtoMessagePayloadImage,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  uiImageMode?: TypeChatImageMode,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  header?: IBubbleHeader,
  isStar?: boolean,
  isMsgForwardable?: boolean
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  onClickImage?: () => void,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  onClickSendMessageToUser?: (entUserId: EntUserId, menuAnchor: Element) => void,
  getMediaSrc?: (state: RootState, mediaId?: MediaId) => string | undefined,
})
{
  const theme = useTheme();
  const pageCtx = usePageCtx();
  const onClickCaption = props.cbOnClickFooterCaption;
  const getMediaSrc = props.getMediaSrc;
  const payload = props.payload;
  const footerOverlay = Boolean(!(payload.text.trim().length > 0)); // show footer overlay if no image caption
  const captionIcon = props.receiptStatus;
  const header = props.header;
  const uiImageMode = props.uiImageMode ?? "original";
  const imgWidth = theme.common.calcBubbleFixedWidth(props.maxWidth);
  const bubbleSize = theme.common.calcBubbleSize(payload?.width as number, payload?.height as number, imgWidth);
  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));
  const mentionMap = payload.mentionMap;
  const onClickSendMessageToUser = props.onClickSendMessageToUser;

  const rootState = useAppSelector(state => state);
  const mediaSrcImage = getMediaSrc && getMediaSrc(rootState, payload.mediaIdImage);
  const mediaSrcBlurImage = getMediaSrc && getMediaSrc(rootState, payload.mediaIdBlurImage);

  const image = {
    ...payload,
    src: mediaSrcImage,
    srcBlur: mediaSrcBlurImage,
    width: bubbleSize.width,
    height: bubbleSize.height
  };

  const onClickMessageToUser = (mentionUser: string, menuAnchor: Element) =>
  {
    if(mentionMap)
    {
      const entUserId = mentionMap[mentionUser];
      onClickSendMessageToUser && onClickSendMessageToUser(entUserId, menuAnchor);
    }
  };

  const onClickImage = () =>
  {
    pageCtx.showDialog(
      <ImageViewDialog
        src={image.src}
        srcBlur={image.srcBlur}
        bgColor={image.primaryColor}
        width={payload.width}
        height={payload.height}
        objectFit={"cover"}
        onClose={() => pageCtx.showDialog(undefined)}
      />);
  };

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={props.maxWidth}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleBlinking={props.isBubbleBlinking}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      {
        header !== undefined &&
        <BubbleRawHeader
          header={header}
          onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
        />
      }

      {
        props.replyPayload &&
        <BubbleRawReplyPreview
          replyPayload={props.replyPayload}
          replyInfo={props.replyInfo}
          onClickPreview={props.cbOnClickReplyPreview}
        />
      }

      <Box
        position={"relative"}
        width={px(image.width)}
      >
        {
          uiImageMode === "original" &&
          <RawLazyImage
            borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
            src={image.src}
            srcBlur={image.srcBlur}
            height={image.height ? px((image.height)) : "auto"}
            width={image.width ? px((image.width)) : "100%"}
            primaryColor={image.primaryColor}
            onClick={onClickImage}
            objectFit={"cover"}
          />
        }
        {
          (uiImageMode === "color" || uiImageMode === "blur") &&
          <RawImage
            borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
            src={uiImageMode === "blur" ? image.srcBlur : undefined}
            height={image.height ? px((image.height)) : "auto"}
            width={image.width ? px((image.width)) : "100%"}
            bgColor={image.primaryColor}
            onClick={onClickImage}
            objectFit={"cover"}
          />
        }
        {
          footerOverlay &&
          <RawLinerGradient
            type={"bottom"}
          >
            <LayoutFlexRow
              justifyContent={"end"}
              width={"100%"}
            >
              <BubbleRawCaption
                isStar={props.isStar}
                textColor={"textInverse"}
                textRight={captionTime}
                icon={captionIcon}
                onClickCaption={onClickCaption}
              />
            </LayoutFlexRow>
          </RawLinerGradient>
        }
      </Box>
      {
        !footerOverlay && (
          <BubbleRawLinkText
            onClickSendMessageToUser={onClickMessageToUser}
            value={payload.text}
            maxCharLimit={300}
            maxWidth={image.width}
            mentionMap={mentionMap}
            fakeSpace={
              <>
                {Boolean(props.isStar) &&
                  <IconBubbleCaption
                    value={"star"}
                    color={"dark"}
                  />
                }
                <Typography
                  ml={px(theme.common.gapQuarter)}
                  variant={"caption"}
                >
                  {captionTime}
                </Typography>
                <IconBubbleCaption
                  value={captionIcon}
                  color={"dark"}
                />
              </>
            }
            caption={
              <BubbleRawCaption
                isStar={Boolean(props.isStar)}
                textRight={captionTime}
                icon={captionIcon}
                iconColor={"dark"}
                onClickCaption={onClickCaption}
              />
            }
          />
        )}
    </BubbleShell>
  );
}
