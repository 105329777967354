import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {useMemo} from "react";
import {DefnFieldPaymentStatus} from "../../../../../../api/meta/base/dto/DefnFieldPaymentStatus";
import {EnumDefnThemeColor} from "../../../../../../api/meta/base/Types";
import {EnumDefnPaymentStatus} from "../../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../../api/meta/base/Types";
import {getDefnDtoColorToCssColor} from "../../../../../../base/plus/FormPlus";
import theme from "../../../../../../base/plus/ThemePlus";
import RawHighlighter from "../../../../raw/RawHighlighter";

export function BubbleFieldPaymentStatus(props: {
  field: DefnFieldPaymentStatus,
  text: string,
  variant: Variant,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const field = props.field;
  const variant = props.variant;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;

  const pickPaymentStatusColor = useMemo(() =>
  {
    const value = valueMap?.[fieldId] as EnumDefnPaymentStatus | undefined;
    if(value === "failed")
    {
      return getDefnDtoColorToCssColor({
        value: theme.common.paymentStatusOptionFailed as EnumDefnThemeColor,
        shade: "s500"
      });
    }
    else if(value === "paid")
    {
      return getDefnDtoColorToCssColor({
        value: theme.common.paymentStatusOptionPaid as EnumDefnThemeColor,
        shade: "s500"
      });
    }
    else if(value === "pending")
    {
      return getDefnDtoColorToCssColor({
        value: theme.common.paymentStatusOptionPending as EnumDefnThemeColor,
        shade: "s500"
      });
    }
  }, [field, valueMap?.[fieldId]]);

  return <RawHighlighter
    variant={variant}
    value={props.text}
    color={pickPaymentStatusColor || "textSecondary"}
    searchWords={props.searchWords}
    isUserSelect={true}
    flex={1}
  />;
}
