import {DtoDebuggerLogEntry} from "../../../api/ent/base/dto/DtoDebuggerLogEntry";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";

export default class SrvcAdminPanelLog extends ISrvc
{
  selectList(state: RootState)
  {
    return state.adminPanel.main.formList;
  }

  loadReportFormList(
    entId: EntId,
    listName: string,
    readOnly: boolean,
    logEntry?: DtoDebuggerLogEntry)
  {
    if(!logEntry)
    {
      return;
    }

    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    const inputForm = logEntry.inputForm;
    const outputForm = logEntry.outputForm;
    if(inputForm)
    {
      let formId = inputForm.metaId;
      uiItemIds.push(formId);
      uiItemsById[formId] = {
        type: "p",
        primary: {
          text: inputForm.name
        },
        hideMenu: readOnly
      } as IListItemAPSA;
    }

    if(outputForm)
    {
      let formId = outputForm.metaId;
      uiItemIds.push(formId);
      uiItemsById[formId] = {
        type: "p",
        primary: {
          text: outputForm.name
        },
        hideMenu: readOnly
      } as IListItemAPSA;
    }

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }
}
