import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {SigUserAvatar} from "../../../../../../api/home/drawer/sig/SigUserAvatar";
import {DefnField} from "../../../../../../api/meta/base/dto/DefnField";
import {DefnFieldSetOfUser} from "../../../../../../api/meta/base/dto/DefnFieldSetOfUser";
import {FieldValueEntUserId} from "../../../../../../api/meta/base/dto/FieldValueEntUserId";
import {EntUserId} from "../../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../../api/meta/base/Types";
import {RootState} from "../../../../../../Store";
import RawHighlighter from "../../../../raw/RawHighlighter";
import {BubbleFieldColor} from "./BubbleFieldColor";
import {BubbleFieldError} from "./BubbleFieldError";
import {BubbleFieldParagraphAndInfo} from "./BubbleFieldParagraphAndInfo";
import {BubbleFieldPaymentStatus} from "./BubbleFieldPaymentStatus";
import {BubbleFieldPickText} from "./BubbleFieldPickText";
import {BubbleFieldSetOfText} from "./BubbleFieldSetOfText";
import BubbleFieldSetOfUser from "./BubbleFieldSetOfUser";
import BubbleFieldUser from "./BubbleFieldUser";

export function BubbleFieldFactory(props: {
  field: DefnField,
  text: string,
  variant: Variant,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const field = props.field;
  const text = props.text;
  const valueMap = props.valueMap;
  const variant = props.variant;
  const fieldId = field.metaId;

  switch(field.type)
  {
    case "info":
    case "paragraph":
      return <BubbleFieldParagraphAndInfo {...props} text={text}
        variant={variant}
      />;
    case "paymentStatus":
      return <BubbleFieldPaymentStatus {...props} text={text}
        variant={variant}
      />;
    case "pickText":
      return <BubbleFieldPickText {...props} text={text}
        variant={variant}
      />;
    case "setOfText":
      return <BubbleFieldSetOfText {...props} variant={variant} />;
    case "setOfUser":
      return <BubbleFieldSetOfUser{...props} variant={variant}
        field={field as DefnFieldSetOfUser}
      />;
    case "pickUser":
    case "userId":
    case "refUser":
      return <BubbleFieldUser{...props} variant={variant}
        fieldValue={valueMap?.[fieldId] as FieldValueEntUserId}
      />;
    case "error":
      return <BubbleFieldError {...props} text={text}
        variant={variant}
      />;
    case "color":
      return <BubbleFieldColor {...props} text={text}
        variant={variant}
      />;
    default:
      return (
        <RawHighlighter
          variant={variant}
          value={text}
          color={"textSecondary"}
          searchWords={props.searchWords}
          isUserSelect={true}
          flex={1}
        />
      );
  }
}

