import {ListAltRounded} from "@mui/icons-material";
import {PersonalVideoRounded} from "@mui/icons-material";
import {AbcRounded} from "@mui/icons-material";
import {SmsFailedRounded} from "@mui/icons-material";
import {Diversity1Rounded} from "@mui/icons-material";
import {GroupRemoveRounded} from "@mui/icons-material";
import {GroupAddRounded} from "@mui/icons-material";
import {GroupRounded} from "@mui/icons-material";
import {HttpRounded} from "@mui/icons-material";
import {MicRounded} from "@mui/icons-material";
import {ApprovalRounded} from "@mui/icons-material";
import {ReportRounded} from "@mui/icons-material";
import {PollRounded} from "@mui/icons-material";
import {DynamicFormRounded} from "@mui/icons-material";
import {DescriptionRounded} from "@mui/icons-material";
import {ImageRounded} from "@mui/icons-material";
import {AudiotrackRounded} from "@mui/icons-material";
import {PersonRounded} from "@mui/icons-material";
import {LocationOnRounded} from "@mui/icons-material";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import React from "react";
import {textIconSx} from "../../../base/plus/ThemePlus";
import {EnumIconListItemSecondary2} from "../../../base/types/TypesIcon";

export default function IconListItemSecondary2(props: {
  value?: EnumIconListItemSecondary2
})
{
  const icon = props.value;
  if(!icon)
  {
    return null;
  }

  const sx = textIconSx();
  switch(icon)
  {
    case "audio":
      return <AudiotrackRounded sx={sx} />;
    case "camera":
      return <CameraAltRoundedIcon sx={sx} />;
    case "document":
      return <DescriptionRounded sx={sx} />;
    case "spreadsheetRow":
      return <DynamicFormRounded sx={sx} />;
    case "spreadsheetRowDeleted":
      return <SmsFailedRounded sx={sx} />;
    case "group":
      return <Diversity1Rounded sx={sx} />;
    case "groupAboutChange":
    case "groupAvatarChange":
    case "groupCreate":
    case "groupNameChange":
      return <GroupRounded sx={sx} />;
    case "groupJoinWithInvite":
    case "groupMemberAdd":
      return <GroupAddRounded sx={sx} />;
    case "groupMemberRemove":
    case "groupExit":
      return <GroupRemoveRounded sx={sx} />;
    case "image":
      return <ImageRounded sx={sx} />;
    case "linkText":
      return <HttpRounded sx={sx} />;
    case "location":
      return <LocationOnRounded sx={sx} />;
    case "messageDeleted":
      return <SmsFailedRounded sx={sx} />;
    case "spreadsheetPartition":
      return <ListAltRounded sx={sx} />;
    case "text":
      return <AbcRounded sx={sx} />;
    case "user":
      return <PersonRounded sx={sx} />;
    case "video":
      return <PersonalVideoRounded sx={sx} />;
    case "voice":
      return <MicRounded sx={sx} />;

    // extra
    case "poll":
      return <PollRounded sx={sx} />;
    case "report":
      return <ReportRounded sx={sx} />;
    case "approval":
      return <ApprovalRounded sx={sx} />;
  }

  return <p>Icon missing ${icon}</p>;
}
