import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getListItemStudio} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {listClearItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {listClearPickItemIds} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinder} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {ActionUpdateStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {clearStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {updateStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigAvatarEnt = SigStudioEntAvatar | undefined | SigStudioPluginAvatar;
export type FnAcceptFilter = (filter?: EntId[]) => void;

const subscriberId = "UiStudioDrawerFilter";

export default class SrvcStudioDrawerFilter extends ISrvc
{
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSigAvatarEnt.bind(this),
      onBindSourceItem1: this.onBindSigAvatarEnt.bind(this)
    } as IListBinder<TypeSigAvatarEnt>;
  }

  load(
    listName: string,
    entIdSet: EntId[],
    pluginIdSet: PluginBundleId[])
  {
    const itemIds = [...entIdSet, ...pluginIdSet];
    const itemsById = {} as IListItemsById;
    const rootState = store.getState();
    const entIdAdminAvatarMap = rootState.cache.app.caller.entIdAdminAvatarMap;
    const pluginIdAdminAvatarMap = rootState.cache.app.caller.pluginIdAdminAvatarMap;

    Promise.all(itemIds.map(itemId =>
    {
      return new Promise<void>((resolve) =>
      {
        if(isEntId(itemId))
        {
          if(entIdAdminAvatarMap[itemId])
          {
            itemsById[itemId] = getListItemStudio(itemId, entIdAdminAvatarMap[itemId]) as IListItem;
            resolve();
          }
          else
          {
            Srvc.cache.app.caller.wsocEntAvatarAdminGet(
              itemId,
              (sig) =>
              {
                itemsById[itemId] = getListItemStudio(itemId, sig) as IListItem;
                resolve();
              },
              resolve
            );
          }
        }
        else if(isPluginId(itemId))
        {
          if(pluginIdAdminAvatarMap[itemId])
          {
            itemsById[itemId] = getListItemStudio(itemId, pluginIdAdminAvatarMap[itemId]) as IListItem;
            resolve();
          }
          else
          {
            Srvc.cache.app.caller.wsocPluginAvatarAdminGet(
              itemId,
              (sig) =>
              {
                itemsById[itemId] = getListItemStudio(itemId, sig) as IListItem;
                resolve();
              },
              resolve
            );
          }
        }
        else
        {
          resolve();
        }
      });
    })).then(() =>
    {
      const listData = {
        itemIds: itemIds,
        itemsById: itemsById,
        version: random()
      } as IListData;

      dispatchList(listName, listRefresh(listData));
    });
  }

  setFilter(listName: string, itemId: string, pickValue: boolean)
  {
    dispatchList(listName, listSetSelectedItemId(undefined));

    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    store.dispatch(updateStudioFilter({
      callerIdHash: callerIdHash,
      artifactId: itemId,
      pickValue: pickValue
    } as ActionUpdateStudioFilter));
  }

  clearFilter(listName: string)
  {
    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    Promise
    .resolve(dispatchList(listName, listClearPickItemIds()))
    .then(() =>
    {
      store.dispatch(clearStudioFilter(callerIdHash));
    });
  }

  filter(fn: FnAcceptFilter)
  {
    fn(store.getState().cache.studio.filter.artifactIdSet);
  }

  subscribe(artifactId: ArtifactId, unSubscribe?: boolean)
  {
    if(isEntId(artifactId) || isPluginId(artifactId))
    {
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
  }

  subscribeBulk(artifactIdSet: ArtifactId[], unSubscribe?: boolean)
  {
    artifactIdSet.forEach(artifactId =>
    {
      if(isEntId(artifactId))
      {
        Srvc.app.pubsub.caller.entAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
      else if(isPluginId(artifactId))
      {
        Srvc.app.pubsub.caller.pluginAvatarAdmin(subscriberId, artifactId, unSubscribe);
      }
    });
  }

  private selectSigAvatarEnt(state: RootState, itemId: TypeListItemId): TypeSigAvatarEnt
  {
    if(isEntId(itemId))
    {
      return state.cache.app.caller.entIdAdminAvatarMap[itemId];
    }
    else
    {
      return state.cache.app.caller.pluginIdAdminAvatarMap[itemId];
    }
  }

  private onBindSigAvatarEnt(
    listName: string,
    itemId: TypeListItemId,
    avatar?: TypeSigAvatarEnt): void
  {
    const rootState = store.getState();

    const listItemStudio = getListItemStudio(itemId, avatar, false, true);

    if(listItemStudio)
    {
      dispatchList(listName, listSetItem({
        itemId: itemId,
        newItem: listItemStudio
      }));
    }
    else
    {
      dispatchList(listName, listClearItem(itemId));
    }

    const filter = rootState.cache.studio.filter.artifactIdSet;

    dispatchList(listName, listSetPick({
      itemId: itemId,
      pickValue: Boolean(filter && filter.includes(itemId))
    }));
  }

}
