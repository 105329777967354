import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import _ from "lodash";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {ArtifactId} from "../../../api/meta/base/Types";
import LocalFilterPickMany from "../../../base/local/LocalFilterPickMany";
import {removeItem} from "../../../base/plus/JsPlus";
import {putItem} from "../../../base/plus/JsPlus";

const localFilter = new LocalFilterPickMany((callerIdHash) => `production_filter_${callerIdHash}`);

export type ActionLoadAdminPanelFilter = {
  callerIdHash: string,
  entIdAdminSet: EntId[],
  entIdNotAdminSet: EntId[],
  entIdPluginSet: PluginBundleId[]
};

export type ActionUpdateAdminPanelFilter = {callerIdHash: string, artifactId: ArtifactId, pickValue: boolean};

export interface IAdminPanelFilterState
{
  artifactIdSet: ArtifactId[];
}

export const sliceCacheAdminPanelFilter = createSlice({
  name: "productionFilter",
  initialState: {
    artifactIdSet: [] as EntId[]
  } as IAdminPanelFilterState,
  reducers: {
    loadAdminPanelFilter: (state, action: PayloadAction<ActionLoadAdminPanelFilter>) =>
    {
      const payload = action.payload;
      const callerIdHash = payload.callerIdHash;

      let entIdArray = localFilter.get(callerIdHash);
      state.artifactIdSet = entIdArray.length > 0
        ? _.intersection(entIdArray, [...payload.entIdNotAdminSet, ...payload.entIdPluginSet])
        : [];
    },
    updateAdminPanelFilter: (state, action: PayloadAction<ActionUpdateAdminPanelFilter>) =>
    {
      const payload = action.payload;

      let artifactIdSet = state.artifactIdSet;
      const callerIdHash = payload.callerIdHash;
      const artifactId = payload.artifactId;
      const pickValue = payload.pickValue;
      if(pickValue)
      {
        if(!artifactIdSet)
        {
          artifactIdSet = [] as EntId[];
          state.artifactIdSet = artifactIdSet;
        }
        putItem(artifactIdSet, artifactId);
        localFilter.ensure(callerIdHash, artifactId);
      }
      else
      {
        if(artifactIdSet)
        {
          removeItem(artifactIdSet, artifactId);
          if(artifactIdSet.length === 0)
          {
            state.artifactIdSet = [];
            localFilter.clear(callerIdHash);
          }
          else
          {
            localFilter.remove(callerIdHash, artifactId);
          }
        }
      }
    },
    clearAdminPanelFilter: (state, action: PayloadAction<string>) =>
    {
      const callerIdHash = action.payload;
      state.artifactIdSet = [];
      localFilter.clear(callerIdHash);
    }
  }
});

export const {
  loadAdminPanelFilter,
  updateAdminPanelFilter,
  clearAdminPanelFilter
} = sliceCacheAdminPanelFilter.actions;
