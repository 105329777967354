import ISrvc from "../../../base/ISrvc";
import {dispatchEnt} from "../../studio/SrvcCacheStudio";
import {updateSettings} from "../SliceCacheAdminPanel";
import {ActionUpdateEntDeployLock} from "../TypeCacheAdminPanel";

export default class SrvcCacheAdminPanelManageSettings extends ISrvc
{
  updateSettings(payload: ActionUpdateEntDeployLock)
  {
    dispatchEnt(payload.entId, updateSettings(payload));
  }
}
