import {useMemo} from "react";
import React from "react";
import {DefnFieldUserId} from "../../../../api/meta/base/dto/DefnFieldUserId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import FieldRawUser from "./FieldRawUser";

export default function FieldUserId(props: {
  defn: DefnFieldUserId
})
{
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const newDefn = useMemo(() =>
  {
    const isRefChild = Boolean((defn as DefnFieldUi)._parentRefId);

    return {
      ...defn,
      disabled: isRefChild ? defn.disabled : true
    } as DefnFieldUserId;
  }, [defn, fieldId]);

  return (
    <FieldRawUser
      defn={newDefn}
    />
  );
}
