import {isEmpty} from "lodash";
import {MsgEntUserIdNoVersion} from "../../../../api/core/base/msg/MsgEntUserIdNoVersion";
import {MsgVersion} from "../../../../api/core/base/msg/MsgVersion";
import {StudioEntRole} from "../../../../api/meta/base/dto/StudioEntRole";
import {SystemRoleSet} from "../../../../api/meta/base/StudioSetsFieldType";
import {EntUserId} from "../../../../api/meta/base/Types";
import {MetaIdRole} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {getErrorMessage} from "../../../../api/nucleus/base/Protocol";
import {DtoEntUser} from "../../../../api/studio/base/dto/DtoEntUser";
import {DtoEntUserImport} from "../../../../api/studio/base/dto/DtoEntUserImport";
import {MsgEntUserAdd} from "../../../../api/studio/studioMain/msg/MsgEntUserAdd";
import {MsgEntUserBulkImport} from "../../../../api/studio/studioMain/msg/MsgEntUserBulkImport";
import {MsgEntUserDeeplinkGet} from "../../../../api/studio/studioMain/msg/MsgEntUserDeeplinkGet";
import {MsgEntUserInvite} from "../../../../api/studio/studioMain/msg/MsgEntUserInvite";
import {MsgEntUserUpdate} from "../../../../api/studio/studioMain/msg/MsgEntUserUpdate";
import {RpcStudioMain} from "../../../../api/studio/studioMain/RpcStudioMain";
import ISrvc from "../../../../base/ISrvc";
import {TypeAdminPanelUserFilterType} from "../../../../base/plus/AdminPanelPlus";
import {TypeAdminPanelUserSortType} from "../../../../base/plus/AdminPanelPlus";
import {STR_INVALID_DATA_SHEET} from "../../../../base/plus/ConstantsPlus";
import {STR_USERS_IMPORTED_SUCCESSFULLY} from "../../../../base/plus/ConstantsPlus";
import {STR_YOU} from "../../../../base/plus/ConstantsPlus";
import {STR_LINK_COPY_TO_CLIPBOARD} from "../../../../base/plus/ConstantsPlus";
import {formatCaptionDateTime} from "../../../../base/plus/DatePlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {ILineCaption} from "../../../../base/types/TypesGlobal";
import {CbSuccess} from "../../../../base/types/TypesGlobal";
import {RootState} from "../../../../Store";
import {Srvc} from "../../../Srvc";

export default class SrvcAdminPanelUsers extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entUserList;
  }

  //region rpc

  rpcEntUserListGet(entId: EntId, version?: string)
  {
    const msg = {
      version: version
    } as MsgVersion;

    RpcStudioMain.entUserListGet(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;

      if(sig)
      {
        Srvc.cache.adminPanel.users.setEntUserList(entId, sig);
      }
    });
  }

  rpcEntUserAdd(entId: EntId, msg: MsgEntUserAdd, cbSuccess?: CbSuccess)
  {
    RpcStudioMain.entUserAdd(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcEntUserUpdate(entId: EntId, msg: MsgEntUserUpdate, cbSuccess?: CbSuccess)
  {
    RpcStudioMain.entUserUpdate(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      cbSuccess && cbSuccess();
    });
  }

  rpcEntUserRemove(entId: EntId, msg: MsgEntUserIdNoVersion)
  {
    RpcStudioMain.entUserRemove(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  rpcEntUserDeactivate(entId: EntId, msg: MsgEntUserIdNoVersion)
  {
    RpcStudioMain.entUserDeactivate(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  rpcEntUserActivate(entId: EntId, msg: MsgEntUserIdNoVersion)
  {
    RpcStudioMain.entUserActivate(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  rpcEntUserInvite(entId: EntId, msg: MsgEntUserInvite, cbSuccess?: CbSuccess)
  {
    RpcStudioMain.entUserInvite(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcUserInviteDeeplinkGet(entId: EntId, entUserId?: EntUserId)
  {
    const msg = {
      entUserId: entUserId
    } as MsgEntUserDeeplinkGet;

    if(typeof ClipboardItem && navigator.clipboard.write !== undefined)
    {
      const clipboardItem = new ClipboardItem({
        "text/plain": new Promise((resolve, reject) =>
        {

          RpcStudioMain.entUserInviteDeeplinkGet(entId, msg, envSig =>
          {
            if(envSig.error)
            {
              const errorMsg = getErrorMessage(envSig.error);
              errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
              reject();
              return;
            }
            else if(envSig.sig)
            {
              const text = envSig.sig?.url;
              text && resolve(new Blob([text], {type: "text/plain"}));
              Srvc.app.toast.showSuccessToast(STR_LINK_COPY_TO_CLIPBOARD);
            }
          });
        })
      });
      copyToClipboard("", clipboardItem);
    }
    else
    {
      RpcStudioMain.entUserInviteDeeplinkGet(entId, msg, envSig =>
      {
        if(envSig.error)
        {
          const errorMsg = getErrorMessage(envSig.error);
          errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
          return;
        }
        else if(envSig.sig)
        {
          const text = envSig.sig?.url;
          copyToClipboard(text);
          Srvc.app.toast.showSuccessToast(STR_LINK_COPY_TO_CLIPBOARD);
        }
      });
    }
  }

  resetEntMainAddressBook(entId: EntId)
  {
    Srvc.cache.adminPanel.users.removeEntUsersList(entId);
  }

  rpcEntUserBulkImport(entId: EntId, users: DtoEntUserImport[])
  {
    const msg = {
      users: users
    } as MsgEntUserBulkImport;

    RpcStudioMain.entUserBulkImport(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else if(!envSig.sig?.errorMap || isEmpty(envSig.sig?.errorMap))
      {
        Srvc.app.toast.showSuccessToast(STR_USERS_IMPORTED_SUCCESSFULLY);
      }
      else
      {
        Srvc.app.toast.showErrorToast(STR_INVALID_DATA_SHEET);
      }
    });
  }

  //region end

  setUserFilter(entId: EntId, filterType: TypeAdminPanelUserFilterType)
  {
    Srvc.cache.adminPanel.users.setUserFilter(entId, filterType);
  }

  //region end

  loadUserList(
    listName: string,
    contactList: DtoEntUser[],
    roleSet?: StudioEntRole[],
    readOnly?: boolean,
    userFilter?: TypeAdminPanelUserFilterType,
    userSort?: TypeAdminPanelUserSortType
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    const sortedContacts = [...contactList].sort((a, b) =>
    {
      switch(userSort)
      {
        case "ascByName":
          return a.nickName.localeCompare(b.nickName);
        case "descByName":
          return b.nickName.localeCompare(a.nickName);
        case "ascByDate":
          return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
        case "descByDate":
          return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
        default:
          return 0;
      }
    });

    sortedContacts.forEach(contact =>
    {
      const userFilterCondition: Record<TypeAdminPanelUserFilterType, boolean> = {
        "ShowActive": contact.active, // only active contacts
        "ShowDeactivated": !contact.active, // only deActive contacts
        "ShowInviteOnly": contact.handle !== undefined && !contact.isMember, // not join enterprise contacts
        "All": true // all contacts for "All" filter
      };

      if(userFilterCondition[userFilter || "All"])
      {
        const itemId = contact.entUserId;
        uiItemIds.push(itemId);

        const roleNameList: string[] = this.getRoleNameList(roleSet, contact.roleIdSet);
        const lastOnline = contact.online
          ? "Online"
          : contact.lastOnline
            ? `Last active: ${formatCaptionDateTime(new Date(contact.lastOnline), true)}`
            : contact.lastInviteSentOn
              ? `Last invite sent on: ${formatCaptionDateTime(new Date(contact.lastInviteSentOn), true)}`
              : undefined;

        uiItemsById[itemId] = {
          type: "mpsl",
          userField: {
            value: contact
          },
          infoSpot: !contact.active ? "warning" : undefined,
          primary: {
            text: contact.nickName,
            caption: {
              text: contact.handle,
              ignoreSelection: true
            }
          },
          secondaryList: [
            {
              ...roleNameList.length > 0 ? {
                text: roleNameList.join(", ")
              } : {
                text: "No roles",
                color: "textDisabled"
              },
              caption: this.getUserSecondaryCaption(contact, readOnly)
            },
            {
              text: contact.managerId
                ? this.getManagerName(contactList, contact.managerId)
                : "No manager",
              color: contact.managerId ? "textSecondary" : "textDisabled",
              icon2: "group",
              caption: {
                type: "text",
                text: lastOnline,
                color: contact.online ? "successLight" : "textDisabled",
                ignoreSelection: true
              }
            }
          ],
          hideMenu: readOnly
        } as IListItemMPSL;
      }
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  //region private

  private getUserSecondaryCaption(contact: DtoEntUser, readOnly?: boolean)
  {
    let secondaryCaption = {} as ILineCaption;

    let secondaryCaptionText: string | undefined;
    if(contact.isCurrentUser)
    {
      secondaryCaptionText = STR_YOU;
    }
    else if(contact.handle !== undefined && !contact.isMember)
    {
      secondaryCaptionText = "INVITE";
    }

    secondaryCaption.text = secondaryCaptionText;
    secondaryCaption.color = readOnly ? "textDisabled" : "successLight";

    return secondaryCaption;
  }

  private getRoleNameList(roleSet?: StudioEntRole[], metaIdRoleList?: MetaIdRole[])
  {
    const roleNameList: string[] = [];

    if(roleSet && metaIdRoleList)
    {
      metaIdRoleList.forEach(roleId =>
      {
        const role = roleSet.find(role => role.metaId === roleId);
        if(role)
        {
          const roleName = role.details.name;
          roleNameList.push(roleName);
        }
        else if(SystemRoleSet.includes(roleId))
        {
          roleNameList.push(roleId);
        }
      });
    }

    return roleNameList;
  }

  private getManagerName(contactList: DtoEntUser[], handle: string)
  {
    const manager = contactList.find(contact => contact.entUserId === handle);

    if(manager)
    {
      return manager.nickName;
    }
    else
    {
      return undefined;
    }
  }

  //region end
}
