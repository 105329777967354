import {useMemo} from "react";
import React from "react";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {EntUserId} from "../../../api/meta/base/Types";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {IListItemSsRowBubble} from "../../../base/types/list/TypeListSsRowBubble";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {useAppSelector} from "../../app/AppHooks";
import BubbleRawForm from "../../atom/bubble/raw/bubbleForm/BubbleRawForm";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import ListItemShellChat from "../impl/ListItemShellChat";
import {CbOnClickListItem} from "../List";

export function ListItemSsRowBubble(props: {
  itemId: TypeListItemId,
  listItem: IListItemSsRowBubble,
  selectList: SelectList,
  itemMaxWidth: number,
  onClickListItem?: CbOnClickListItem,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
  cbOnClickFooterCaption?: CbMenuAnchor,
})
{
  const selectList = props.selectList;
  const itemId = props.itemId;
  const searchWords = useAppSelector(state => selectList(state).searchWords);
  const listItem = props.listItem;
  const onClickListItem = props.onClickListItem;
  const sig = listItem.sig;

  const cbOnClickGridHyperlink = useMemo(() => listItem.sig.showGridAsHyperlink
    ? (menuAnchor: Element, gridId: MetaIdGrid) =>
      onClickListItem && onClickListItem(menuAnchor, itemId, listItem, "gridHyperlink", undefined, undefined, gridId)
    : undefined, [listItem.sig.showGridAsHyperlink]);

  return (
    <ListItemShellChat
      itemId={props.itemId}
      listItem={props.listItem as IListItemChat}
      selectList={props.selectList}
      maxWidth={props.itemMaxWidth}
      onClickListItem={props.onClickListItem}
    >
      <LayoutFlexCol
        width={"100%"}
        height={"100%"}
        pt={px(gapHalf + gapQuarter)}
        pb={px(gapHalf + gapQuarter)}
      >
        <BubbleRawForm
          formValue={listItem?.formValue}
          replyInfo={sig.replyInfo}
          receiptStatus={sig.receiptStatus}
          isCallerSender={Boolean(sig.isCallerSender)}
          titleColor={sig.formBubbleTitleColor}
          creationTime={sig.creationTime || ""}
          maxWidth={props.itemMaxWidth}
          header={sig.header}
          showGridAsHyperlink={sig.showGridAsHyperlink}
          searchWords={searchWords}
          isStar={sig.isStar}
          defnForm={sig.defnForm}
          showComments={sig.isCommentable}
          isFormWithMedia={sig.isFormWithMedia}
          cbOnClickFooterCaption={props.cbOnClickFooterCaption}
          isInvisibleSpreadsheetRow={sig.isInvisibleSpreadsheetRow}
          sigSpreadsheetRowExpiry={sig.sigSpreadsheetRowExpiry}
          canExpire={sig.canExpire}
          chatPatternVar={sig.chatPatternVar}
          selectUserAvatar={props.selectUserAvatar}
          bgColorShell={sig?.bgColorShell}
          cbOnClickGridHyperlink={cbOnClickGridHyperlink}
          hideFooter={listItem?.hideFooter}
          disableMenuHover={listItem?.ignoreSelection}
        />
      </LayoutFlexCol>
    </ListItemShellChat>
  );
}
