export const ABOUT_TIME_ZONE_DATE_FORMAT = "Read only when there are spreadsheets";
export const VAR_SET_OF_TEXT_KEY = "Key is required for rules and plugin";
export const VAR_SEQUENCE_VALUE = "Starts from value";
export const VAR_TEXT_MAX_LENGTH = "Max length: 1024 characters";
export const DEFAULT_PERMISSION_WRITE = "Default permission is '\"Write\"'";
export const FORMS_CONTENT_LAYOUT_EDITOR = "Only tabs and stack layouts are allowed";
export const FORMS_MAX_SIZE = "Provide size in MB";
export const FORMS_CONTENT_LAYOUT_EDITOR_SHOW_STEPPER = "Only applied to deeplink";
export const FORMS_GRID_SHOW_ALL_ROWS = "The grid will be expanded with all the elements of selected field";
export const FORMS_GRID_INDEX_FIELD_NAME_VARIABLE = "To show the index column, provide the name";
export const FORMS_PICK_REPORT_ROW_COPY_FIELDS = "This field is enabled when \\\"Show as dropdown\\\" is false";
export const FIELD_HANDLE = "+ countryCode nationalNumber or email";
export const FORMS_FIELD_ONLY_LIST_CARD_LAYOUT = "Only list and card layouts are allowed";
export const FORMS_FIELD_DIVIDER_KIND = "Default is thin";
export const FORMS_REF_OVERLAY_SPREADSHEET_LAYOUT = "Note: Add fields from this form to spreadsheet layout";
export const FORMS_REF_REPORT_OVERLAY_GRID_LAYOUT = "Note: Add fields from this form to grid layout";
export const SPREADSHEETS_LAYOUT_SHOW_SEARCH_BAR = "Note: Search bar is only available in 'List' layout mode";
export const SPREADSHEETS_LAYOUT_CATEGORY_FIELDS = "Note: Only queryable ref fields are allowed in category field";
export const SPREADSHEETS_LAYOUT_ADVANCE_FILTER_FIELDS = "Note: Only queryable fields but no ref fields are allowed in advance filter";
export const SPREADSHEETS_LAYOUT_SORT_BY_FIELDS = "Note: Only queryable none ref fields are allowed";
export const SPREADSHEETS_LAYOUT_TABLE_COLUMN_SIZE = "Options: AutoSize, Flex, FlexMin_??px, FlexMin_??em, ??px and ??em.\nLast size will be applied to all the remaining columns";
export const SPREADSHEETS_LAYOUT_TABLE_COLUMN_ALIGNMENTS = "Options: Start, End and Center.\nLast alignment will be applied to all the remaining columns";
export const SPREADSHEETS_LAYOUT_TABLE_ROW_PER_PAGE = "Maximum 100,000 rows fetched per table";
export const SPREADSHEETS_LAYOUT_XY_CHART_Y_AXIS_FIELD = "Y axis field defaults to hit count";
export const AUTOMATIONS_INPUT_PIPELINE_VAR = "It will be used as input form in condition variable";
export const AUTOMATIONS_SPREADSHEET_CONDITION_VAR = "Grid fields cannot be used in condition";
export const AUTOMATIONS_ON_FIRE_CONDITION_VAR = "In condition only constants, context and variables can be used";
export const AUTOMATIONS_SPREADSHEET_ERROR_FIELD = "Reason for the error in English";
export const AUTOMATIONS_ERROR_RETRY_DURATION_VAR = "Duration between every retries";
export const AUTOMATIONS_MESSAGE_SEND_GROUPS = "Send message to groups";
export const DEEPLINKS_PERMISSION_CREATION_ROLES = "Roles permitted to create this deeplink";
export const DEEPLINKS_PERMISSION_VISIBILITY_CONSTRAINT = "Constraint on the created deeplink";
export const PROMPTS_SETTINGS_FIELD_SEPARATOR_SET = "Apart from \",\" you can also set any string for the separator.\nUse \"${sep}\" to utilize that separator in the prompt";
export const DEPLOY_ADMIN_FOR_SINGLETON_PLUGINS = "It's required when you are using singleton plugins";
export const PLUGIN_RESOURCES_PACKAGE_NAME_VAR = "Package name is required to download the code";
export const PLUGIN_RESOURCE_DOWNLOAD_CODE_BUTTON = "You can download the code only when a version is in the draft mode";

