import {RemoveCircleOutlineRounded} from "@mui/icons-material";
import {AddCircleOutlineRounded} from "@mui/icons-material";
import {TextField} from "@mui/material";
import {Slide} from "@mui/material";
import {Box} from "@mui/material";
import {IconButton} from "@mui/material";
import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {useEffect} from "react";
import {useRef} from "react";
import React from "react";
import {useCallback} from "react";
import {useState} from "react";
import {px} from "../../../base/plus/StringPlus";
import theme from "../../../base/plus/ThemePlus";
import LayoutFlexRow from "../layout/LayoutFlexRow";

type EnumCounterIcon = "add" | "subtract";
type EnumCounterPosition = "left" | "right";
const OnlyNumberRegex = /^[0-9\b]+$/;
const fontSize = 20;
const characterWidth = 14;
const defaultButtonSize = 30;
const maxCharacter = 10000000;

export default function RawCounter(props: {
  position?: EnumCounterPosition,
  step?: number,
  value?: number,
  maxVar?: number,
  minVar?: number,
  buttonSize?: StandardCSSProperties["fontSize"],
  disableAnimation?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  onChange?: (e: Element, count: number) => void;
  onClick?: () => void
})
{
  const value = props.value;
  const maxVar = props.maxVar || maxCharacter;
  const minVar = props.minVar;
  const buttonSize = props.buttonSize;
  const disableAnimation = props.disableAnimation;
  const readOnly = props.readOnly;
  const disabled = props.disabled;
  const onChange = props.onChange;
  const position = props.position;
  const step = props.step ?? 1;

  const theme = useTheme();
  const gapMargin = px(theme.common.gapHalf);
  const ref = useRef(null);

  const [counter, setCounter] = useState<number | undefined>(value);
  const [textFieldWidth, setTextFieldWidth] = useState(counter?.toString().length || 0);

  const onClickSubtract = useCallback(() =>
  {
    if(counter === undefined || counter - step < (minVar || 0))
    {
      return;
    }

    if(!Boolean(minVar))
    {
      setCounter(counter - step);
    }
    else if(minVar && counter > minVar)
    {
      setCounter(counter - step);
    }
  }, [counter, minVar, step]);

  const onCLickAdd = useCallback(() =>
  {
    if(counter === undefined)
    {
      setCounter(step);
      return;
    }
    else if(maxVar === undefined || counter < maxVar)
    {
      setCounter(Number(counter) + step);
    }
  }, [counter, maxVar, step]);

  const getPosition = (position: EnumCounterPosition | undefined) =>
  {
    switch(position)
    {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "flex-end";
    }
  };

  useEffect(() =>
  {
    if(value === undefined || value < 0)
    {
      setCounter(minVar);
    }
  }, [minVar, value]);

  useEffect(() =>
  {
    if(counter !== undefined && (counter >= 0 || counter <= 0))
    {
      setTextFieldWidth(counter.toString().length * characterWidth);
      ref.current && onChange && onChange(ref.current, counter);
    }

  }, [counter]);

  useEffect(() =>
  {
    if(value === undefined || isNaN(value))
    {
      setCounter(undefined);
    }
  }, [value]);

  useEffect(() =>
  {
    if(value !== undefined && !isNaN(value))
    {
      setCounter(value);
    }
  }, [value]);

  return (
    <Box
      display={"flex"}
      justifyContent={getPosition(position)}
      alignItems={"center"}
      width={"100%"}
      ref={ref}
      onClick={props.onClick}
    >
      <Slide
        direction={position === "right" ? "left" : "right"}
        in={Boolean(counter && counter > 0)}
        appear={!disableAnimation}
        mountOnEnter
        unmountOnExit
        aria-readonly={readOnly}
        aria-disabled={true}
        container={ref.current}
      >
        {
          <Box
            display={"flex"}
          >
            <LayoutFlexRow mr={gapMargin}>
              <IconButton
                sx={buttonStyle(buttonSize)}
                onClick={(e) =>
                {
                  e.stopPropagation();
                  onClickSubtract();
                }}
                disabled={Boolean(minVar && counter && counter <= minVar)
                  || readOnly
                  || disabled}
              >
                <CounterIcon value={"subtract"} />
              </IconButton>
            </LayoutFlexRow>

            <LayoutFlexRow>
              <TextField
                inputProps={{
                  inputMode: "numeric",
                  max: maxVar,
                  min: minVar
                }}
                sx={textFieldStyle(textFieldWidth)}
                value={counter ?? 0}
                onChange={(event) =>
                {
                  if(event.target.value === "" || OnlyNumberRegex.test(event.target.value))
                  {
                    let value = Number(event.target.value);
                    if(minVar !== undefined && value <= (minVar || 0))
                    {
                      value = minVar;
                    }
                    if(maxVar !== undefined && value >= maxVar)
                    {
                      value = maxVar;
                    }
                    setCounter(value);
                  }
                }}
                InputProps={{
                  readOnly: readOnly
                }}
                disabled={disabled}
              />
            </LayoutFlexRow>
          </Box>
        }
      </Slide>

      <LayoutFlexRow ml={gapMargin}>
        <IconButton
          sx={buttonStyle(buttonSize)}
          onClick={(e) =>
          {
            e.stopPropagation();
            onCLickAdd();
          }}
          disabled={Boolean(maxVar && counter && counter >= maxVar)
            || readOnly
            || disabled}
        >
          <CounterIcon value={"add"} />
        </IconButton>
      </LayoutFlexRow>

    </Box>
  );
}

function textFieldStyle(textFieldWidth: number)
{
  return {
    border: "none",
    outline: "none",
    padding: 0,
    "& button:-webkit-outer-spin-button": {
      appearance: "none",
      margin: 0
    },
    "& fieldset": {border: "none"},
    input: {
      maxWidth: px(100),
      width: px(textFieldWidth),
      padding: 0,
      textAlign: "center",
      color: theme.palette.info.main,
      fontSize: px(fontSize)
    }
  };
}

function buttonStyle(buttonSize: StandardCSSProperties["fontSize"])
{
  return {
    color: theme.palette.primary.light,
    "& .MuiButtonBase-root": {
      background: "none"
    },
    "& .MuiSvgIcon-root": {
      fontSize: buttonSize || defaultButtonSize
    },
    padding: 0
  };
}

function CounterIcon(props: {
  value: EnumCounterIcon
})
{
  const value = props.value;

  if(value === undefined)
  {
    return null;
  }

  switch(value)
  {
    case "add":
      return <AddCircleOutlineRounded />;
    case "subtract":
      return <RemoveCircleOutlineRounded />;
  }
}
