import {FormHelperText} from "@mui/material";
import {TextField} from "@mui/material";
import {Link} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldMobileNumber} from "../../../../api/meta/base/dto/DefnFieldMobileNumber";
import {FieldValueMobile} from "../../../../api/meta/base/dto/FieldValueMobile";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToOptions} from "../../../../base/plus/JsPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {timeZoneToCountryCode} from "../../../atom/assets/TimeZoneToCountyCode";
import IconStrip from "../../../atom/icon/IconStrip";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

const COUNTRY_CODE_WIDTH = 128;

export default function FieldMobileNumber(props: {
  defn: DefnFieldMobileNumber,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueMobile | undefined;

        const mobileNumber = getFormFieldValueAsTextWithPrefixSuffix(defn, fieldValue);

        const reportNode = <Link
          href={`tel:${mobileNumber}`}
          component={"a"}
          underline={"none"}
          variant={"caption"}
          sx={{
            color: "textSecondary"
          }}
        >
          {mobileNumber}
        </Link>;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            reportNode={reportNode}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <RawMobileNumber
                defn={defn}
                field={field}
                error={error}
                isError={isError}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};

function RawMobileNumber(props: {
  defn: DefnFieldMobileNumber,
  field: ControllerRenderProps<FieldValues, string>,
  error?: FieldError,
  isError?: boolean
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const field = props.field;
  const fieldValue = field.value as FieldValueMobile | undefined;
  const onChange = props.field.onChange;
  const fieldId = getFieldKey(defn);
  const error = props.error;
  const isError = props.isError;
  const fieldVariant = defnTheme.fieldVariant;
  const fieldSize = defnTheme.fieldSize ?? "medium";
  const PhoneNumber = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const PNF = require("google-libphonenumber").PhoneNumberFormat;

  const {
    getFieldPlaceHolder,
    getFieldRequired,
    getFieldHelperText,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const icon = getFieldIcon();
  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const getOnClick = formCtx.getOnClick();
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const readOnly = formCtx.isFieldReadonly(defn);

  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const countryCodes = [...new Set(Object.values(timeZoneToCountryCode))];

  const options = arrayToOptions(countryCodes);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryCode = timeZoneToCountryCode[userTimeZone];

  function extractCountryCodeAndNumber(value?: FieldValueMobile)
  {
    const phoneNumber = value?.value;
    if(!phoneNumber)
    {
      return null;
    }

    const countryCodes = new Set(Object.values(timeZoneToCountryCode));

    const isCountryCode = (code: string) => countryCodes.has(code);

    if(isCountryCode(phoneNumber))
    {
      return {
        countryCode: phoneNumber,
        mobileNumber: undefined
      };
    }

    if(phoneNumber.startsWith("+"))
    {
      for(let i = 2; i <= 4; i++)
      {
        const potentialCode = phoneNumber.slice(0, i);
        if(isCountryCode(potentialCode))
        {
          return {
            countryCode: potentialCode,
            mobileNumber: phoneNumber.slice(i) || undefined
          };
        }
      }
    }

    try
    {
      const number = PhoneNumber.parse(phoneNumber);
      const countryCode = "+" + number.getCountryCode();
      const nationalNumber = number.getNationalNumber().toString();

      return {
        countryCode: countryCode,
        mobileNumber: nationalNumber || undefined
      };
    }
    catch(error)
    {
      return {
        countryCode: undefined,
        mobileNumber: phoneNumber.replace(/\D/g, "")
      };
    }
  }

  function combineCodes(countryCode?: string | null, mobileNumber?: string)
  {
    if(!(countryCode && mobileNumber) || !mobileNumber || !mobileNumber)
    {
      return undefined;
    }

    return countryCode + mobileNumber;
  }

  return (
    <>
      <LayoutFlexRow
        width={"100%"}
        flexGrow={1}
        justifyContent={"space-between"}
        overflowX={"visible"}
        overflowY={"visible"}
      >
        <LayoutFlexRow
          width={px(COUNTRY_CODE_WIDTH)}
          overflowX={"visible"}
          overflowY={"visible"}
          pr={px(gapHalf)}
        >
          <RawPickOneAutoComplete
            optionMap={optionsToMapOfOption(options)}
            label={"Country code"}
            required={required}
            placeHolder={placeHolder}
            value={extractCountryCodeAndNumber(fieldValue)?.countryCode ?? countryCode}
            disabled={Boolean(disabled)}
            readOnly={readOnly}
            onClick={onClick}
            error={error
              ? {
                ...error,
                message: undefined
              }
              : undefined
            }
            onChange={(code) =>
            {
              if(fieldValue?.value && extractCountryCodeAndNumber(fieldValue)?.mobileNumber)
              {
                onChange({
                  value: combineCodes(
                    code,
                    extractCountryCodeAndNumber(fieldValue)?.mobileNumber
                  )
                } as FieldValueMobile);
              }
              else
              {
                onChange(code ? {
                  value: code
                } as FieldValueMobile : null);
              }
            }}
            onBlur={field.onBlur}
            fieldVariant={fieldVariant}
            fieldSize={fieldSize}
            name={field.name}
            fieldId={fieldId}
          />
        </LayoutFlexRow>

        <TextField
          value={extractCountryCodeAndNumber(fieldValue)?.mobileNumber ?? ""}
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {display: "none"},
            width: "100%",
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          required={required}
          placeholder={placeHolder}
          onClick={onClick}
          InputProps={{
            readOnly: readOnly,
            endAdornment: icon ? (
                <RawIconButton
                  name={icon}
                  icon={icon}
                />) :
              <IconStrip value={"mobileNumber"} />
          }}
          ref={field.ref}
          inputRef={field.ref}
          type={"number"}
          label={defn.label}
          size={fieldSize}
          margin={defnTheme.fieldMargin}
          variant={fieldVariant}
          autoComplete={Boolean(defn.autoFill) ? "on" : "off"}
          autoFocus={Boolean(defn.autoFocus)}
          disabled={disabled}
          name={fieldId}
          onBlur={field.onBlur}
          onChange={(event) =>
          {
            if(extractCountryCodeAndNumber(fieldValue)?.countryCode)
            {
              onChange({
                value: combineCodes(extractCountryCodeAndNumber(fieldValue)?.countryCode, event.target.value)
              } as FieldValueMobile);
            }
            else
            {
              onChange({
                value: combineCodes(countryCode, event.target.value)
              } as FieldValueMobile);
            }
          }}
          onKeyDown={(event) =>
          {
            if(event.key === "Enter")
            {
              onClick?.();
            }
            if(event.key === "e" || event.key === "E" || event.key === ".")
            {
              event.preventDefault();
            }
          }}
          error={Boolean(error)}
        />
        <FieldRawRefButton
          defn={defn}
        />
      </LayoutFlexRow>

      {(Boolean(error) || helperText) &&
        <FormHelperText
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexGrow: 1
          }}
        >
          {error?.message ? error.message : helperText}
        </FormHelperText>
      }
    </>
  );
}


