import {ENT_ID_GLOBAL} from "../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../api/meta/base/Types";
import {MetaId} from "../../api/meta/base/Types";

export type TypeDrawerHeaderIconStrip =
  | "debug"
  | "star"
  | "filter"
  | "more"
  | "help";

//region drawer overlays

export type TypeDrawerOverlayHome =
  | "Profile"
  | "New chat"
  | "New group"
  | "Starred messages"
  | "Filter"
  | "Address book"
  | "Add new contact"
  | "Settings"
  | "Subscriptions";

export type TypeDrawerOverlayStudio =
  | "Profile"
  | "New enterprise"
  | "New plugin"
  | "Filter"
  | "Find usages"
  | "Store"
  | "New template"

export type TypeDrawerOverlayDeeplink =
  | "print"
  | "grid";

export type TypeDrawerOverlayEntStr =
  | "Profile"
  | "Filter";

export type TypeDrawerOverlayApiBr =
  | "Profile"
  | "Filter";

export type TypeDrawerOverlayCompBr =
  | "Profile";

//endregion

//region union types

export type TypeDrawerOverlay =
  | TypeDrawerOverlayHome
  | TypeDrawerOverlayStudio
  | TypeDrawerOverlayEntStr
  | TypeDrawerOverlayApiBr
  | TypeDrawerOverlayCompBr
  | TypeDrawerOverlayDeeplink;

export type TypeDrawerHeaderMenu =
  | TypeDrawerOverlay
  | "Home"
  | "Studio"
  | "Api browser"
  | "UI logs"
  | "Terminal"
  | "Admin panel"
  | "Tools"
  | "Sign out"
  | undefined; // shown as separator

//endregion

export interface IDrawerOverlayProps
{
}

//region home asides

export interface IDrawerContactHome
{
  handle?: string,
  nickName?: string
}

export interface IDrawerOverlayPropsHome extends IDrawerOverlayProps
{
  type: TypeDrawerOverlayHome;
}

export interface IDrawerOverlayPropsHomeAddressBook extends IDrawerOverlayPropsHome
{
  type: TypeDrawerOverlayHome;
  contact?: IDrawerContactHome;
}

export interface IDrawerOverlayPropsStudio extends IDrawerOverlayProps
{
  type: TypeDrawerOverlayStudio;
}

export interface IDrawerOverlayPropsFindUsages extends IDrawerOverlayPropsStudio
{
  artifactId: ArtifactId;
  findUsageMetaId: MetaId;
  findUsageName: string;
}

export interface IDrawerOverlayPropsEntStr extends IDrawerOverlayProps
{
  type: TypeDrawerOverlayEntStr;
}

export interface IDrawerOverlayPropsApiBr extends IDrawerOverlayProps
{
  type: TypeDrawerOverlayApiBr;
}

export interface IDrawerOverlayPropsCompBr extends IDrawerOverlayProps
{
  type: TypeDrawerOverlayCompBr;
}

export interface IDrawerOverlayPropsHeaderMenu extends IDrawerOverlayProps
{
  type: TypeDrawerHeaderMenu;
}

export const myWorldItemId = ENT_ID_GLOBAL;
