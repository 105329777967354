import {LinkRounded} from "@mui/icons-material";
import {Link} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldHyperlink} from "../../../../api/meta/base/dto/DefnFieldHyperlink";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {getValidURL} from "../../../../base/plus/UrlPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {BorderLabel} from "../../../atom/raw/BorderLabel";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldHyperlink(props: {
  defn: DefnFieldHyperlink
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const label = getCompLabel(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const isRef = formCtx.getRefChildParentId(fieldId);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueText | undefined;
        const linkUrl = getFormFieldValueAsTextWithPrefixSuffix(defn, fieldValue?.value);
        const cbOnclick = () =>
        {
          const value = fieldValue?.value;
          if(value && value.startsWith("https://") && !isError)
          {
            window.open(value);
          }
          else if(value && !isError)
          {
            window.open("https://" + value);
          }
        };

        const reportNode = <Link
          href={linkUrl && getValidURL(linkUrl)}
          underline={"none"}
          variant={"caption"}
          sx={{
            color: "textSecondary"
          }}
        >
          {linkUrl}
        </Link>;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            reportNode={reportNode}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              {
                (disabled && !isRef && fieldValue) ?
                  <BorderLabel
                    label={label}
                    labelColor={theme.common.color("textDisabled")}
                  >
                    <LayoutFlexRow
                      width={"100%"}
                      justifyContent={"space-between"}
                    >
                      <Link
                        href={fieldValue?.value && getValidURL(fieldValue?.value)}
                        variant={"body1"}
                        target={"_blank"}
                        sx={{
                          paddingLeft: px(14),
                          color: "textSecondary"
                        }}
                      >
                        {fieldValue?.value}
                      </Link>

                      <LinkRounded
                        sx={{
                          width: px(gapStd + gapHalf),
                          height: "inherit",
                          alignSelf: "center",
                          cursor: "pointer",
                          marginRight: px(14),
                          "&:hover": {
                            color: theme.common.color("infoLight")
                          }
                        }}
                        onClick={cbOnclick}
                      />
                    </LayoutFlexRow>
                  </BorderLabel>
                  :
                  <FieldRawTextField
                    {...props}
                    value={fieldValue}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    ref={field.ref}
                    error={error}
                    endAdornment={<LinkRounded
                      sx={{
                        width: px(gapStd + gapHalf),
                        height: "inherit",
                        alignSelf: "center",
                        cursor: "pointer",
                        "&:hover": {
                          color: isRef ? undefined : theme.common.color("infoLight")
                        }
                      }}
                      onClick={(event) =>
                      {
                        if(!isRef)
                        {
                          event.stopPropagation();
                          cbOnclick();
                        }
                      }}
                    />}
                  />
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}


