import {useCallback} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdMapping} from "../../api/meta/base/ApiPlus";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnGrid} from "../../api/meta/base/dto/DefnGrid";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioBuildMapping} from "../../api/meta/base/dto/DefnStudioBuildMapping";
import {DefnStudioPickPluginFieldId} from "../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {StudioDtoArgValueField} from "../../api/meta/base/dto/StudioDtoArgValueField";
import {StudioDtoMappingField} from "../../api/meta/base/dto/StudioDtoMappingField";
import {StudioDtoMappingFieldMapBase} from "../../api/meta/base/dto/StudioDtoMappingFieldMapBase";
import {StudioField} from "../../api/meta/base/dto/StudioField";
import {StudioSection} from "../../api/meta/base/dto/StudioSection";
import {ToMappingFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {EnumStudioCompType} from "../../api/meta/base/Types";
import {MetaIdMapping} from "../../api/meta/base/Types";
import {MetaIdPlugin} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {getFieldStudioBuildArgBinderKey} from "../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {loopDefnForm} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {getStudioArrayKey} from "../../base/plus/StringPlus";
import {getDefnFormParentMap} from "../../base/plus/StudioFormPlus";
import {getStudioFormParentMap} from "../../base/plus/StudioFormPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldMappingToKey} from "../form/viewer/base/FieldVarMappingPlus";
import {fieldMappingFromKey} from "../form/viewer/base/FieldVarMappingPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldMapper = "fieldMapper";

const contentWidth = 1200;
const contentHeight = 700;

export default function DialogNewFieldMapping(props: {
  fromFormId?: MetaIdForm,
  toFormId: MetaIdForm,
  fromCompositeId?: MetaIdField,
  toCompositeId?: MetaIdField,
  fromPluginId?: MetaIdPlugin;
  toPluginId?: MetaIdPlugin;
  formStore?: FormStore,
  values?: StudioDtoMappingFieldMapBase,
  readOnly?: boolean,
  onClickOk: (values: StudioDtoMappingFieldMapBase) => void,
  onClose?: () => void,
})
{
  const values = props.values;
  const formStore = props.formStore;
  const onClickSubmit = props.onClickOk;
  const fromCompositeId = props.fromCompositeId;
  const toCompositeId = props.toCompositeId;
  const fromPluginId = props.fromPluginId;
  const toPluginId = props.toPluginId;
  const fromFormId = props.fromFormId;
  const toFormId = props.toFormId;
  const readOnly = props.readOnly;

  const formRef = {} as IFormRef;

  const defnForm = getDefnForm(
    toFormId,
    fromFormId,
    fromPluginId,
    toPluginId,
    fromCompositeId ? [fromCompositeId] : undefined,
    toCompositeId ? [toCompositeId] : undefined
  );

  const valueToDto = (values: FieldValues) =>
  {
    const mapValues = values[fieldMapper] as FieldValues[] | undefined;
    const mapping: StudioDtoMappingFieldMapBase = {
      keys: [],
      map: {}
    };

    if(mapValues)
    {
      mapValues.forEach(valueMap =>
      {
        const metaId: MetaIdMapping = nextMetaIdMapping();

        const from = fnFieldValueToRawValueArgBinder(valueMap[fieldMappingFromKey]);

        const to = fnFieldValueToRawValue("pickFieldId", valueMap[fieldMappingToKey]) as MetaIdField | undefined;

        if(from && to)
        {
          mapping.keys.push(metaId);

          mapping.map[metaId] = {
            from: from,
            to: to,
            metaId: metaId
          } as StudioDtoMappingField;
        }
      });
    }
    onClickSubmit(mapping);

  };

  const dtoToValue = useCallback((dto?: StudioDtoMappingFieldMapBase) =>
  {
    const fieldValue = [] as StudioDtoMappingField[];
    const fromFieldNameToMetaIdMap = {} as Record<string, string>;
    const fromFieldTypeToMetaIdSetMap = {} as Record<EnumStudioCompType, MetaIdField[]>;
    const form = fromFormId
      ? formStore?.formMap?.map[fromFormId]
      : undefined;

    const fromPluginForm = fromPluginId && fromFormId
      ? formStore?.pluginMap?.map[fromPluginId].pluginFormMap[fromFormId]
      : undefined;

    const toPluginForm = toPluginId && toFormId
      ? formStore?.pluginMap?.map[toPluginId].pluginFormMap[toFormId]
      : undefined;

    const validToFieldType = {} as Record<EnumStudioCompType | EnumDefnCompType, boolean>;

    ToMappingFieldType.forEach((field) =>
    {
      validToFieldType[field as EnumStudioCompType | EnumDefnCompType] = true;
    });

    const to = toFormId
      ? formStore?.formMap?.map[toFormId]
      : undefined;

    if(form)
    {
      if(fromCompositeId)
      {
        const fieldMap = form.compositeMap.map[fromCompositeId].fieldMap;
        fieldMap.keys.forEach((key) =>
        {
          const field = fieldMap.map[key];
          if(field.type)
          {
            fromFieldNameToMetaIdMap[field.details.name] = field.metaId;
            fromFieldTypeToMetaIdSetMap[field.type] = [
              ...(fromFieldTypeToMetaIdSetMap[field.type] || []),
              field.metaId
            ];
          }
        });
      }
      else
      {
        loopStudioForm(form, (composite, field) =>
        {
          if(composite.type === "section")
          {
            if(field.type)
            {
              fromFieldNameToMetaIdMap[field.details.name] = field.metaId;
              fromFieldTypeToMetaIdSetMap[field.type] = [
                ...(fromFieldTypeToMetaIdSetMap[field.type] || []),
                field.metaId
              ];
            }
          }
        });
      }
    }
    else if(fromPluginForm)
    {
      loopDefnForm(fromPluginForm, (composite, field) =>
      {
        if(fromCompositeId)
        {
          if(composite.type === "grid" && (composite as DefnGrid).metaId === fromCompositeId)
          {
            fromFieldNameToMetaIdMap[field.name] = field.metaId;
            fromFieldTypeToMetaIdSetMap[field.type as EnumStudioCompType] = [
              ...(fromFieldTypeToMetaIdSetMap[field.type as EnumStudioCompType] || []),
              field.metaId
            ];
          }
        }
        else
        {
          if(composite.type === "section")
          {
            fromFieldNameToMetaIdMap[field.name] = field.metaId;
            fromFieldTypeToMetaIdSetMap[field.type as EnumStudioCompType] = [
              ...(fromFieldTypeToMetaIdSetMap[field.type as EnumStudioCompType] || []),
              field.metaId
            ];
          }
        }

      });
    }

    if(to)
    {
      if(toCompositeId)
      {
        const fieldMap = to.compositeMap.map[toCompositeId].fieldMap;
        fieldMap.keys.forEach((key) =>
        {
          const field = fieldMap.map[key];
          if(field.type && validToFieldType[field.type])
          {
            const item = processToField(field, fromFieldNameToMetaIdMap, dto);
            fieldValue.push(item);
          }
        });
      }
      else
      {
        loopStudioForm(to, (composite, field) =>
        {
          if(composite.type === "section" && field.type && validToFieldType[field.type])
          {
            const item = processToField(field, fromFieldNameToMetaIdMap, dto);
            fieldValue.push(item);
          }
        });
      }
    }
    else if(toPluginForm)
    {
      loopDefnForm(toPluginForm, (composite, field) =>
      {
        if(toCompositeId)
        {
          if(composite.type === "grid"
            && validToFieldType[field.type]
            && (composite as DefnGrid).metaId === toCompositeId)
          {
            const item = processToField(field, fromFieldNameToMetaIdMap, dto);
            fieldValue.push(item);
          }
        }
        else
        {
          if(composite.type === "section" && validToFieldType[field.type as EnumStudioCompType])
          {
            const item = processToField(field, fromFieldNameToMetaIdMap, dto);
            fieldValue.push(item);
          }
        }
      });
    }

    fieldValue.sort((fieldOne: StudioDtoMappingField, fieldTwo: StudioDtoMappingField) =>
    {
      if(fieldOne.from && !fieldTwo.from)
      {
        return -1;
      }
      if(!fieldOne.from && fieldTwo.from)
      {
        return 1;
      }
      return 0;
    });

    return {
      [fieldMapper]: fieldValue
    } as FieldValues;

  }, [formStore?.formMap?.map, values]);

  function processToField(
    field: StudioField | DefnField,
    fromFieldNameToMetaIdMap: Record<string, string>,
    dto?: StudioDtoMappingFieldMapBase)
  {
    const mappingId = nextMetaIdMapping();
    const form = fromFormId
      ? formStore?.formMap?.map[fromFormId]
      : undefined;
    const pluginForm = fromPluginId && fromFormId
      ? formStore?.pluginMap?.map[fromPluginId].pluginFormMap[fromFormId]
      : undefined;
    const parentMap = form ? getStudioFormParentMap(form) : pluginForm ? getDefnFormParentMap(pluginForm) : undefined;

    let _from: StudioDtoMappingField["from"] | undefined = undefined;
    const fromFieldId = fromFieldNameToMetaIdMap[(field as DefnField)?.name || (field as StudioField).details.name];
    const valueItemId = dto?.keys.find((key) => dto?.map[key].to === field.metaId);

    const valueItem = valueItemId ? dto?.map[valueItemId] as StudioDtoMappingField | undefined : undefined;

    if(valueItem && dto?.keys?.length)
    {
      _from = {
        kind: valueItem.from.kind,
        value: valueItem.from.value,
        argName: valueItem.from.argName
      };
    }
    else if(fromFieldId && !dto?.keys?.length && parentMap)
    {
      const fromCompId = (parentMap[fromFieldId] as StudioSection).metaId;

      _from = {
        kind: "field",
        argName: "from",
        value: {
          fieldId: fromFieldId,
          compositeId: fromCompId
        } as StudioDtoArgValueField
      } as StudioDtoMappingField["from"];
    }

    return {
      to: field.metaId,
      ..._from && {
        ...fnRawValueToFieldValueArgBinder(fieldMappingFromKey, _from)
      },
      name: mappingId,
      metaId: mappingId
    } as StudioDtoMappingField;
  }

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} field mapping`}
      formProps={{
        cbRef: formRef,
        defnForm: defnForm,
        onSubmit: (values) => valueToDto(values),
        store: formStore,
        initValues: dtoToValue(values),
        formReadonly: readOnly
      }}
      onClose={props.onClose}
      contentWidth={contentWidth}
      contentHeight={contentHeight}
      doNotCloseActions={["clear"]}
      onClickFooterAction={(action) =>
      {
        if(action === "clear")
        {
          const values = formRef.getValues && formRef.getValues();
          const fieldMapperValue = values?.[fieldMapper] as StudioDtoMappingField[] | undefined;

          fieldMapperValue?.forEach((value, index) =>
          {
            if(value.to)
            {
              const key = getStudioArrayKey(fieldMapper, index, fieldMappingFromKey);
              const valueKey = getFieldStudioBuildArgBinderKey(key);
              formRef.setValue(`${key}.kind`, null);
              formRef.setValue(`${valueKey}.kind`, null);
              formRef.setValue(key, null);
              // formRef.setValue(valueKey, null);
            }
          });
        }
      }}
      leftFooterActions={["clear"]}
    />
  );
}

function getDefnForm(
  toFormId: MetaIdForm,
  fromFormId?: MetaIdForm,
  fromPluginId?: MetaIdPlugin,
  toPluginId?: MetaIdPlugin,
  fromCompositeIdSet?: MetaIdField[],
  toCompositeIdSet?: MetaIdField[])
{
  return createDefaultDefnFormStudio({

    [fieldMapper]: {
      type: "studioBuildMapping",
      from: {
        formId: fromFormId,
        pluginId: fromPluginId,
        compositeIdSet: fromCompositeIdSet
      } as DefnStudioBuildArgBinder,
      to: {
        formId: toFormId,
        pluginId: toPluginId,
        compositeIdSet: toCompositeIdSet
      } as DefnStudioPickPluginFieldId,
      metaId: fieldMapper,
      name: fieldMapper,
      pl: 0,
      pr: 0
    } as DefnStudioBuildMapping,

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: [fieldMapper]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}

