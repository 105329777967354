import {useState} from "react";
import {useRef} from "react";
import React, {useCallback, useEffect, useMemo} from "react";
import {FieldValues} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSetOfRole} from "../../../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildActionPermission} from "../../../../api/meta/base/dto/DefnStudioBuildActionPermission";
import {DefnStudioBuildPermissionMatrix} from "../../../../api/meta/base/dto/DefnStudioBuildPermissionMatrix";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {ChatLabelFieldType} from "../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {MetaIdAction} from "../../../../api/meta/base/Types";
import {ArtifactId, MetaIdField, MetaIdForm} from "../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getValidFormMenuActionIdSet} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {loopStudioForm} from "../../../../base/plus/StudioFormPlus";
import theme from "../../../../base/plus/ThemePlus";
import {IAsidePropsStudioFormEdit} from "../../../../base/types/TypesAside";
import {FormClickVariant, IFormRef} from "../../../../base/types/TypesForm";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {fieldMenuActionPermission} from "../../../../routes/studio/ent/forms/UiStudioEntForm";
import {DEFAULT_PERMISSION_WRITE} from "../../../atom/assets/HelperTextStudio";
import {DividerHorizontal} from "../../../atom/layout/DividerHorizontal";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import PaneFooter from "../../../atom/pane/PaneFooter";
import PaneOverlayStack from "../../../atom/pane/PaneOverlayStack";
import {IPaneOverlayStackRef} from "../../../atom/pane/PaneOverlayStack";
import PaneSide from "../../../atom/pane/PaneSide";
import {useAppCtx} from "../../../ctx/CtxApp";
import {useStepStudioAsideDefaultConfig} from "../../base/FormHooks";
import Form from "../../viewer/Form";
import {propKeyPermissionDefault} from "../base/FormBuilderPlus";
import {fieldPermissionMatrixMap} from "../base/FormBuilderPlus";
import {permissionMatrixDtoToValue} from "../base/FormBuilderPlus";
import {permissionMatrixValueToDto} from "../base/FormBuilderPlus";
import useCopyPasteStudioFormItem from "../base/StudioFormCopyPaste";
import {fieldGap3} from "../base/TypesFormBuilder";
import {fieldTabDetails} from "../base/TypesFormBuilder";
import {fieldGap2} from "../base/TypesFormBuilder";
import {fieldTabPermissions} from "../base/TypesFormBuilder";
import {getStudioDetailsToDto} from "../base/TypesFormBuilder";
import {getStudioDetailsToValue} from "../base/TypesFormBuilder";
import {fieldTabProperties} from "../base/TypesFormBuilder";
import {
  ASIDE_FOOTER_BUTTON_KIND,
  fieldGap1,
  fieldKeyLabel,
  fieldKeyName,
  getFieldGap,
  getStudioDetailsCompMap
} from "../base/TypesFormBuilder";

export function FormBuilderEditForm(props: {
  artifactId: ArtifactId,
  onClickClose: () => void,
  isPluginForm?: boolean
})
{
  const closeAside = props.onClickClose;
  const isPluginForm = props.isPluginForm;

  const appCtx = useAppCtx();

  const asideProps = appCtx.getAsideProps() as IAsidePropsStudioFormEdit;
  const formStore = asideProps.formStore;
  const formMap = formStore.formMap;
  const validationResult = formStore.validationResult;
  const metaIdForm = asideProps?.metaId;
  const readonly = asideProps.readOnly;
  const reset = asideProps.reset;
  const selectListMap = asideProps.selectListMap;

  const onSubmitForm = asideProps.cbOnFormSubmit;
  const cbShowVar = asideProps.cbOnShowVar;
  const cbOnEditVar = asideProps.cbOnEditVar;
  const cbOnAddNewVar = asideProps.cbOnAddNewVar;

  const cbOverlayRef = useMemo(() => ({} as IPaneOverlayStackRef), []);
  const cbRef = useMemo(() => ({} as IFormRef), []);
  const form = useMemo(() => metaIdForm
    ? formMap?.map[metaIdForm]
    : undefined, [metaIdForm, formMap?.map]);

  const refForm = useRef<StudioForm | undefined>(form);

  const [labelHelperTextName, setLabelHelperTextName] = useState<string | undefined>();
  const [showLabelHelperTextName, setShowLabelHelperTextName] = useState<boolean>();
  const [sectionIdSet, setSectionIdSet] = useState<MetaIdField[]>();

  const {
    copyStudioForm,
    pasteStudioForm
  } = useCopyPasteStudioFormItem();

  const includeActionIdSet = useMemo(() => formStore
    ? getValidFormMenuActionIdSet(formStore, form?.metaId)
    : undefined, [form?.metaId, formStore]);

  // region set, reset and submit

  const dtoToValues = (form: StudioForm) =>
  {
    return {
      ...getStudioDetailsToValue(form.details),
      [chatLabelFieldId]: fnRawValueToFieldValue("pickFieldId", form.chatLabelFieldId),
      [chatLabelPatternVarId]: fnRawValueToFieldValue("studioVarIdTextEditor", form.chatLabelPatternVarId),
      [fieldTabVariant]: fnRawValueToFieldValue("enumThemeTabVariant", form.tabVariant),
      [fieldCalculateFormulaMode]: fnRawValueToFieldValue("enumCalculateFormulaMode", form.calculateFormulaMode),
      [chatPatternVarId]: fnRawValueToFieldValue("studioVarIdTextEditor", form.chatPatternVarId),
      [chatBubbleFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", form.chatBubbleFieldIdSet),
      [allowToPrintForm]: fnRawValueToFieldValue("bool", form.allowToPrintForm),
      [fieldCommentRoles]: fnRawValueToFieldValue("setOfRole", form.commentRoleSet),
      [fieldCommentReadOnlyRoleSet]: fnRawValueToFieldValue("setOfRole", form.commentReadOnlyRoleSet),
      [fieldMenuActionPermission]: fnFieldValueToRawValue("studioBuildActionPermission", form.actionPermissionMap),
      ...permissionMatrixDtoToValue(form.permissionMatrix)
    };
  };

  const setValues = useCallback((form: StudioForm) =>
  {
    cbRef.remoteReset(dtoToValues(form));
  }, [cbRef]);

  const resetValues = useCallback(() =>
  {
    cbRef.checkSubmit();
  }, [cbRef]);

  const onSubmit = useCallback((values: FieldValues) =>
  {
    if(form)
    {
      const msg = {
        ...refForm.current,
        details: getStudioDetailsToDto(values, refForm.current?.details),
        chatLabelFieldId: fnFieldValueToRawValue("pickFieldId", values[chatLabelFieldId]),
        chatLabelPatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[chatLabelPatternVarId]),
        tabVariant: fnFieldValueToRawValue("enumThemeTabVariant", values[fieldTabVariant]),
        calculateFormulaMode: fnFieldValueToRawValue("enumCalculateFormulaMode", values[fieldCalculateFormulaMode]),
        chatPatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[chatPatternVarId]),
        chatBubbleFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[chatBubbleFieldIdSet]),
        allowToPrintForm: fnFieldValueToRawValue("bool", values[allowToPrintForm]),
        commentRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldCommentRoles]),
        commentReadOnlyRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldCommentReadOnlyRoleSet]),
        actionPermissionMap: fnFieldValueToRawValue("studioBuildActionPermission", values[fieldMenuActionPermission]),
        permissionMatrix: {
          ...permissionMatrixValueToDto(values)
        }
      } as StudioForm;

      onSubmitForm && onSubmitForm(msg);

      resetValues();
    }

  }, [form, onSubmitForm]);

  // endregion

  //region copy & paste

  const onClickIconStrip = useCallback((iconStrip: EnumIconStrip) =>
  {
    if(iconStrip === "copy" && form)
    {
      copyStudioForm(form);
    }
    else if(iconStrip === "paste")
    {
      pasteStudioForm(form =>
      {
        refForm.current = {
          ...form,
          metaId: metaIdForm as MetaIdForm
        };
        setValues(form);
      });
    }
  }, [form, pasteStudioForm, setValues]);

  //endregion

  const onClickFormItem = useCallback((_: MetaIdField, action: FormClickVariant, value?: any) =>
  {
    if(action === "varAddNew")
    {
      cbOnAddNewVar && cbOnAddNewVar(value as StudioVar);
    }
    else if(action === "varShow")
    {
      cbShowVar && cbShowVar(value as MetaIdVar);
    }
    else if(action === "varEdit" && value && cbOnEditVar)
    {
      cbOverlayRef.showOverlay(cbOnEditVar(value as StudioVar, cbOverlayRef.closeOverlay));
    }
  }, [cbOnEditVar, cbOnAddNewVar, cbShowVar, cbOverlayRef, cbRef]);

  useEffect(() =>
  {
    setLabelHelperTextName(refForm.current?.details.name);
    setShowLabelHelperTextName(!refForm.current?.details?.label);
  }, [refForm.current]);

  useEffect(() =>
  {
    if(form)
    {
      const sectionIdSet = [] as MetaIdField[];
      loopStudioForm(form, (composite) =>
      {
        if(composite.type === "section")
        {
          sectionIdSet.push((composite as StudioSection).metaId);
        }
      });
      setSectionIdSet(sectionIdSet);
    }
  }, [form]);

  useEffect(() =>
  {
    if(!reset)
    {
      refForm.current = form;
      if(form)
      {
        setValues(form);
      }
    }
  }, [metaIdForm, reset]);

  useStepStudioAsideDefaultConfig("formMap", cbRef, form, validationResult);

  return (
    <PaneOverlayStack
      width={appCtx.getAsideWidth()}
      cbRef={cbOverlayRef}
    >
      <PaneSide
        primaryText={"Update form"}
        iconStrip={["copy", "paste"]}
        iconStripDisable={readonly ? ["copy", "paste"] : []}
        toolTipMap={{
          paste: "Paste form",
          copy: "Copy form"
        } as Record<EnumIconStrip, string>}
        onClickIconStrip={onClickIconStrip}
        onClickAction={closeAside}
      >
        <LayoutFlexRow
          flexGrow={1}
          height={0}
          overflowY={"auto"}
          overflowX={"auto"}
          bgcolor={theme.common.bgcolorContent}
        >
          <Form
            store={formStore}
            cbRef={cbRef}
            defnForm={getDefnFormBuilderEditForm(isPluginForm,
              metaIdForm,
              labelHelperTextName,
              showLabelHelperTextName,
              includeActionIdSet,
              sectionIdSet
            )}
            onSubmit={onSubmit}
            formReadonly={readonly}
            onClickFormItem={onClickFormItem}
            initValues={form ? dtoToValues(form) : undefined}
            onWatch={(key, value) =>
            {
              if(key === fieldKeyLabel)
              {
                if(fnFieldValueToRawValue("text", value))
                {
                  setShowLabelHelperTextName(false);
                }
                else
                {
                  setShowLabelHelperTextName(true);
                }
              }
              if(key === fieldKeyName && fnFieldValueToRawValue("symbol", value))
              {
                setLabelHelperTextName(toLabel(fnFieldValueToRawValue("symbol", value) as string));
              }
            }}
            selectListMap={selectListMap}
          />
        </LayoutFlexRow>

        <DividerHorizontal />

        <PaneFooter
          footerButtonSet={[{icon: "apply"}]}
          onIconButtonClick={(btnName) =>
          {
            if(btnName === ASIDE_FOOTER_BUTTON_KIND)
            {
              closeAside();
            }
          }}
        />
      </PaneSide>
    </PaneOverlayStack>
  );
}

const chatLabelFieldId = "chatLabelFieldId";
const fieldTabVariant = "tabVariant";
const chatPatternVarId = "chatPatternVarId";
const chatLabelPatternVarId = "chatLabelPatternVarId";
const chatBubbleFieldIdSet = "chatBubbleFieldIdSet";
const allowToPrintForm = "allowToPrintForm";
const fieldMenuActionsSection = "fieldMenuActionsSection";
const fieldCommentRoles = "commentRoleSet";
const fieldCommentReadOnlyRoleSet = "commentReadOnlyRoleSet";
const fieldPermissionSection = "fieldPermissionSection";
const fieldLabelFieldLabel = "labelFieldLabel";
const fieldLabelDescriptionLabel = "labelDescriptionLabel";
const fieldLabelOr1 = "labelOr1";
const fieldLabelOr2 = "labelOr2";
const fieldCalculateFormulaMode = "executeFormula";
const fieldFormulaConfigLabel = "formulaConfigLabel";

export function getDefnFormBuilderEditForm(
  isPluginForm?: boolean,
  metaIdForm?: MetaIdForm,
  labelHelperTextName?: string,
  showLabelHelperTextName?: boolean,
  includeActionIdSet?: MetaIdAction[],
  sectionIdSet?: MetaIdField[]
)
{
  if(isPluginForm)
  {
    const fieldDefaultProperties = getStudioDetailsCompMap(labelHelperTextName, showLabelHelperTextName);

    return createDefaultDefnFormStudio({
      ...fieldDefaultProperties,

      [defaultSectionKey]: {
        type: "section",
        fieldIdSet: Object.keys(fieldDefaultProperties),
        metaId: defaultSectionKey
      } as DefnSection
    } as Record<MetaIdField, DefnField>);
  }
  else
  {
    const fieldFormPropertiesSection = getStudioFormPropertiesSection(metaIdForm, sectionIdSet);

    const fieldFormMenuActionsSection = getStudioFormMenuActionsSection(includeActionIdSet);

    const fieldFormPermissionSection = getStudioFormPermissionSection();

    return createDefaultDefnFormStudio({
      ...getStudioDetailsCompMap(labelHelperTextName, showLabelHelperTextName),

      ...fieldFormPropertiesSection,

      ...fieldFormMenuActionsSection,

      ...fieldFormPermissionSection,

      [fieldTabDetails]: {
        type: "section",
        name: fieldTabDetails,
        metaId: fieldTabDetails,
        label: "Details",
        fieldIdSet: Object.keys(getStudioDetailsCompMap())
      } as DefnSection,

      [defaultSectionKey]: {
        type: "tab",
        name: defaultSectionKey,
        metaId: defaultSectionKey,
        tabIdSet: [
          fieldTabDetails,
          fieldTabProperties,
          fieldMenuActionsSection,
          fieldPermissionSection
        ]
      } as DefnTab
    } as Record<MetaIdField, DefnField>);
  }
}

export function getStudioFormPropertiesSection(
  metaIdForm?: MetaIdForm,
  sectionIdSet?: MetaIdField[])
{
  return {

    [fieldTabVariant]: {
      type: "enumThemeTabVariant",
      label: "Tab variant",
      metaId: fieldTabVariant
    } as DefnFieldEditable,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldLabelFieldLabel]: {
      type: "label",
      metaId: fieldLabelFieldLabel,
      name: fieldLabelFieldLabel,
      label: "Chat bubble title",
      disabled: true
    } as DefnFieldLabel,

    [chatLabelFieldId]: {
      type: "pickFieldId",
      label: "Field",
      filterFieldTypeSet: ChatLabelFieldType,
      formId: metaIdForm,
      showCompositeName: true,
      metaId: chatLabelFieldId
    } as DefnStudioPickFieldId,

    [fieldLabelOr1]: {
      type: "label",
      metaId: fieldLabelOr1,
      name: fieldLabelOr1,
      label: "OR",
      disabled: true,
      justifyText: "center"
    } as DefnFieldLabel,

    [chatLabelPatternVarId]: {
      type: "studioVarIdTextEditor",
      label: "Pattern variable",
      argBinderFormId: metaIdForm,
      metaId: chatLabelPatternVarId,
      showAsEdit: true,
      name: chatLabelPatternVarId,
      filterVarKindSet: ["text"]
    } as DefnStudioVarIdTextEditor,

    ...getFieldGap(fieldGap2, "thick"),

    [fieldLabelDescriptionLabel]: {
      type: "label",
      metaId: fieldLabelDescriptionLabel,
      name: fieldLabelDescriptionLabel,
      label: "Chat bubble description",
      disabled: true
    } as DefnFieldLabel,

    [chatBubbleFieldIdSet]: {
      type: "studioSetOfFieldId",
      metaId: chatBubbleFieldIdSet,
      name: chatBubbleFieldIdSet,
      label: "Fields",
      formId: metaIdForm,
      compositeIdSet: sectionIdSet ?? []
    } as DefnStudioPickFieldId,

    [fieldLabelOr2]: {
      type: "label",
      metaId: fieldLabelOr2,
      name: fieldLabelOr2,
      label: "OR",
      disabled: true,
      justifyText: "center"
    } as DefnFieldLabel,

    [chatPatternVarId]: {
      type: "studioVarIdTextEditor",
      label: "Pattern variable",
      argBinderFormId: metaIdForm,
      metaId: chatPatternVarId,
      showAsEdit: true,
      name: chatPatternVarId,
      filterVarKindSet: ["paragraph"]
    } as DefnStudioVarIdTextEditor,

    ...getFieldGap(fieldGap3, "thick"),

    [fieldFormulaConfigLabel]: {
      type: "label",
      metaId: fieldFormulaConfigLabel,
      name: fieldFormulaConfigLabel,
      label: "Calculate formula configuration",
      disabled: true
    } as DefnFieldLabel,

    [fieldCalculateFormulaMode]: {
      type: "enumCalculateFormulaMode",
      label: "Calculate Formula Mode",
      metaId: fieldTabVariant
    } as DefnFieldEditable,

    [fieldTabProperties]: {
      type: "section",
      name: fieldTabProperties,
      metaId: fieldTabProperties,
      fieldIdSet: [
        fieldTabVariant,
        fieldGap1,
        fieldLabelFieldLabel,
        chatLabelFieldId,
        fieldLabelOr1,
        chatLabelPatternVarId,
        fieldGap2,
        fieldLabelDescriptionLabel,
        chatBubbleFieldIdSet,
        fieldLabelOr2,
        chatPatternVarId,
        fieldGap3,
        fieldFormulaConfigLabel,
        fieldCalculateFormulaMode
      ]

    } as DefnSection
  };
}

function getStudioFormMenuActionsSection(includeActionIdSet?: MetaIdAction[])
{
  return {
    [fieldMenuActionPermission]: {
      type: "studioBuildActionPermission",
      metaId: fieldMenuActionPermission,
      name: fieldMenuActionPermission,
      includeActionIdSet: includeActionIdSet,
      allowGrouping: true,
      allowShowMessageTooltip: true
    } as DefnStudioBuildActionPermission,

    [fieldMenuActionsSection]: {
      type: "section",
      name: fieldMenuActionsSection,
      metaId: fieldMenuActionsSection,
      label: "Menu",
      fieldIdSet: [fieldMenuActionPermission]
    } as DefnSection
  };
}

function getStudioFormPermissionSection()
{
  return {
    [propKeyPermissionDefault]: {
      type: "enumPermission",
      placeHolderVar: {value: ["Select"]},
      metaId: propKeyPermissionDefault,
      helperTextVar: stringToDefnDtoText(DEFAULT_PERMISSION_WRITE)
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap1, "thick"),
    ...getFieldGap(fieldGap2, "thick"),

    [fieldPermissionMatrixMap]: {
      type: "studioBuildPermissionMatrix",
      metaId: fieldPermissionMatrixMap
    } as DefnStudioBuildPermissionMatrix,

    [fieldTabPermissions]: {
      type: "section",
      name: "Permission",
      label: "Permission",
      fieldIdSet: [
        fieldCommentRoles,
        fieldCommentReadOnlyRoleSet,
        allowToPrintForm,
        fieldGap1,
        propKeyPermissionDefault,
        fieldGap2,
        fieldPermissionMatrixMap
      ],
      metaId: fieldTabPermissions
    } as DefnSection,

    [fieldCommentRoles]: {
      type: "setOfRole",
      name: fieldCommentRoles,
      label: "Comment roles",
      metaId: fieldCommentRoles,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,
    [fieldCommentReadOnlyRoleSet]: {
      type: "setOfRole",
      name: fieldCommentReadOnlyRoleSet,
      label: "Comment read only roles",
      metaId: fieldCommentReadOnlyRoleSet,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,
    [allowToPrintForm]: {
      type: "bool",
      metaId: allowToPrintForm,
      name: allowToPrintForm,
      label: "Allow to print form",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [fieldPermissionSection]: {
      type: "section",
      label: "Permission",
      fieldIdSet: [
        fieldTabPermissions
      ],
      metaId: fieldPermissionSection
    } as DefnSection
  };
}

