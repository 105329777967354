import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getListItemStudio} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {listClearItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetVersion} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {listClearPickItemIds} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinder} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {clearAdminPanelFilter} from "../../../cache/adminPanel/filter/SliceCacheAdminPanelFilter";
import {updateAdminPanelFilter} from "../../../cache/adminPanel/filter/SliceCacheAdminPanelFilter";
import {ActionUpdateStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigAvatarEnt = SigStudioEntAvatar | undefined | SigStudioPluginAvatar;
export type FnAcceptFilter = (filter?: EntId[]) => void;

export default class SrvcAdminPanelDrawerFilter extends ISrvc
{
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSigAvatarEnt.bind(this),
      onBindSourceItem1: this.onBindSigAvatarEnt.bind(this)
    } as IListBinder<TypeSigAvatarEnt>;
  }

  load(listName: string)
  {
    RpcStudioDrawer.studioDeployedArtifactListGet(envSig =>
    {
      const entIdSet = envSig.sig?.entIdSet || [];
      const pluginBundleIdSet = envSig.sig?.pluginBundleIdSet || [];

      const listData = this.createListData(entIdSet, pluginBundleIdSet);

      Promise
      .resolve(dispatchList(listName, listRefresh(listData)))
      .then(() =>
      {
        dispatchList(listName, listSetVersion(random()));
      });
    });
  }

  setFilter(listName: string, itemId: string, pickValue: boolean)
  {
    store.dispatch((dispatch, getState) =>
    {
      dispatchList(listName, listSetSelectedItemId(undefined));

      const callerIdHash = getState().cache.app.caller.callerUserIdHash as string;
      dispatch(updateAdminPanelFilter({
        callerIdHash: callerIdHash,
        artifactId: itemId,
        pickValue: pickValue
      } as ActionUpdateStudioFilter));
    });
  }

  clearFilter(listName: string)
  {
    store.dispatch((dispatch, getState) =>
    {
      const callerIdHash = getState().cache.app.caller.callerUserIdHash as string;
      Promise
      .resolve(dispatchList(listName, listClearPickItemIds()))
      .then(() =>
      {
        dispatch(clearAdminPanelFilter(callerIdHash));
      });
    });
  }

  subscribe(subscriberId: TypeSubscriberId, artifactId: ArtifactId, unSubscribe?: boolean)
  {
    if(isEntId(artifactId))
    {
      Srvc.app.pubsub.caller.entAvatarAdmin(subscriberId, artifactId, unSubscribe);
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
    else if(isPluginId(artifactId))
    {
      Srvc.app.pubsub.caller.pluginAvatarAdmin(subscriberId, artifactId, unSubscribe);
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
  }

  protected createListData(srcEntIdAdminSet: EntId[], srcPluginIdAdminSet: PluginBundleId[]): IListData
  {
    const rootState = store.getState();
    const entIdAdminAvatarMap = rootState.cache.app.caller.entIdAdminAvatarMap;
    const pluginIdAdminAvatarMap = rootState.cache.app.caller.pluginIdAdminAvatarMap;

    const itemIds = [...srcEntIdAdminSet, ...srcPluginIdAdminSet];
    const itemsById = {} as IListItemsById;

    itemIds.forEach(itemId =>
    {
      const avatar = isEntId(itemId)
        ? entIdAdminAvatarMap[itemId]
        : isPluginId(itemId)
          ? pluginIdAdminAvatarMap[itemId]
          : undefined;

      if(avatar)
      {
        itemsById[itemId] = getListItemStudio(
          itemId,
          avatar,
          false,
          true
        ) as IListItem;
      }
    });

    return {
      itemIds: itemIds,
      itemsById: itemsById
    } as IListData;
  }

  private selectSigAvatarEnt(state: RootState, itemId: TypeListItemId): TypeSigAvatarEnt
  {
    if(isEntId(itemId))
    {
      return state.cache.app.caller.entIdAdminAvatarMap[itemId];
    }
    else if(isPluginId(itemId))
    {
      return state.cache.app.caller.pluginIdAdminAvatarMap[itemId];
    }
  }

  private onBindSigAvatarEnt(
    listName: string,
    itemId: TypeListItemId,
    avatar?: TypeSigAvatarEnt): void
  {
    if(avatar)
    {
      dispatchList(listName, listSetItem({
        itemId: itemId,
        newItem: getListItemStudio(
          itemId,
          avatar,
          false,
          true
        ) as IListItem
      }));
    }
    else
    {
      dispatchList(listName, listClearItem(itemId));
    }

    const rootState = store.getState();
    const filter = rootState.cache.adminPanel.filter.artifactIdSet;

    dispatchList(listName, listSetPick({
      itemId: itemId,
      pickValue: Boolean(filter && filter.includes(itemId))
    }));
  }

}
