import {CameraAltRounded} from "@mui/icons-material";
import {ImageRounded} from "@mui/icons-material";
import {TextField} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {Typography} from "@mui/material";
import {CircularProgress} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";
import {Property} from "csstype";
import React from "react";
import {useState} from "react";
import {Controller} from "react-hook-form";
import {nextMediaIdImage} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldCamera} from "../../../../api/meta/base/dto/DefnFieldCamera";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {FieldDtoImage} from "../../../../api/meta/base/dto/FieldDtoImage";
import {FieldValueCamera} from "../../../../api/meta/base/dto/FieldValueCamera";
import {FieldValueImage} from "../../../../api/meta/base/dto/FieldValueImage";
import {FORM_IMAGE_MAX_MB} from "../../../../base/plus/ConstantsPlus";
import {FORM_IMAGE_SIZE_MAX} from "../../../../base/plus/ConstantsPlus";
import {isoDateTimeNow} from "../../../../base/plus/DatePlus";
import {getCaptureUser} from "../../../../base/plus/FormPlus";
import {defnTheme} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getPrimaryColorAndBlurredImg} from "../../../../base/plus/MediaPlus";
import {compressImage} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import {FN_NOOP} from "../../../../base/plus/SysPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {CLEAR} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {CLOSE} from "../../../../base/types/TypesIcon";
import {Srvc} from "../../../../srvc/Srvc";
import AvatarViewDialog from "../../../atom/avatar/AvatarViewDialog";
import DialogCamera from "../../../atom/dialog/DialogCamera";
import {IDialogMsg} from "../../../atom/dialog/TypesDialog";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLazyImage from "../../../atom/raw/RawLazyImage";
import {useAppCtx} from "../../../ctx/CtxApp";
import {usePageCtx} from "../../../ctx/CtxPage";
import {useFieldCaptureLocation} from "../../base/FormHooks";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import IFormCtx from "../base/CtxForm";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import RawCaptureExtraProperties from "../raw/RawCaptureExtraProperties";

const fieldCameraPreviewWidth = "150px";
const fieldCameraPreviewHeight = "150px";

export default function FieldCamera(props: {
  defn: DefnFieldCamera
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();

  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon,
    getFieldShowLabel,
    getFieldShowSize,
    getFieldShowPreview
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const label = getCompLabel(defn);

  const isEditable = formCtx.isFieldReadonly(defn) || formCtx.isFieldDisable(defn as DefnFieldEditable);

  const helperText = getFieldHelperText();
  const showLabel = getFieldShowLabel();
  const showSize = getFieldShowSize();
  const placeHolder = getFieldPlaceHolder();
  const required = getFieldRequired();
  const icon = getFieldIcon();
  const showPreview = getFieldShowPreview();

  const defnLabel = {
    label: required ? label + " *" : label
  } as DefnFieldLabel;

  const {
    cbCaptureValue,
    loading: captureLocationLoading
  } = useFieldCaptureLocation();
  const [captureLocationError, setCaptureLocationError] = useState("");

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueCamera | undefined;
        const showCapturedAsHelperText = defn.showCapturedValuesOnAside;

        const onChange = (value?: FieldValueCamera) =>
        {
          if(fieldValue)
          {
            MediaStore.removeMedia(fieldValue.value.mediaIdImage);
            MediaStore.removeMedia(fieldValue.value.mediaIdBlurImage);
          }

          if(value)
          {
            const imageValue = value.value;

            const image = {
              fileName: imageValue?.fileName,
              height: imageValue?.height,
              width: imageValue?.width,
              size: imageValue?.size,
              mediaIdImage: imageValue?.mediaIdImage,
              mediaIdBlurImage: imageValue?.mediaIdBlurImage,
              primaryColor: imageValue?.primaryColor
            } as FieldDtoImage;

            const newValue: FieldValueCamera = {
              value: image,
              captureLocation: fieldValue?.captureLocation,
              ...defn.captureUser && {captureUser: getCaptureUser(formCtx.getApiCtx()?.getCallerEntUserAvatar())},
              ...defn.captureTime && {captureTime: isoDateTimeNow()}
            };

            field.onChange(newValue);

            if(newValue.value && defn.captureLocation)
            {
              cbCaptureValue((captureLocation) =>
              {
                newValue.captureLocation = captureLocation;
                field.onChange(newValue);
                setCaptureLocationError("");
              }, errorMsg =>
              {
                if(errorMsg)
                {
                  field.onChange({
                    ...newValue,
                    captureLocation: undefined
                  });
                }
                setCaptureLocationError(errorMsg);
              });
            }
          }
          else
          {
            field.onChange(null);
          }
        };

        const reportNode = fieldValue
          ? <ImagePreview
            image={fieldValue}
            isEditable={isEditable}
            borderRadius={px(0)}
            onDelete={FN_NOOP}
            selectBoxStyle={{
              border: "none",
              width: fieldCameraPreviewWidth,
              height: fieldCameraPreviewHeight
            }}
            formCtx={formCtx}
            defnCamera={defn}
          />
          : null;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            reportNode={reportNode}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                width={"100%"}
                overflowX={"visible"}
                overflowY={"visible"}
              >
                {showLabel && <FieldLabel defn={defnLabel} />}
                <FieldRawRefButton defn={defn} />
              </LayoutFlexRow>
              <RealFieldCamera
                {...props}
                placeHolder={placeHolder}
                icon={icon as string}
                showSize={showSize as boolean}
                fieldValue={fieldValue}
                onChange={onChange}
                error={error?.message}
                showPreview={showPreview as boolean}
              />
              {
                <RawCaptureExtraProperties
                  captureTime={fieldValue?.captureTime}
                  captureUser={fieldValue?.captureUser}
                  captureLocation={fieldValue?.captureLocation}
                  captureLoading={captureLocationLoading}
                  captureError={captureLocationError}
                  showCapturedAsHelperText={showCapturedAsHelperText}
                />
              }
              {(isError || helperText || showSize) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1,
                    color: (isError || error?.message)
                      ? theme.common.color("errorLight")
                      : undefined
                  }}
                >
                  {error?.message ? error.message :
                    helperText ? helperText : showSize ? fieldValue?.value.size + " MB" : undefined}
                </FormHelperText>
              }

            </FormControl>

          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealFieldCamera(props: {
  defn: DefnFieldCamera,
  fieldValue?: FieldValueCamera,
  placeHolder?: string,
  showSize?: boolean,
  icon?: string,
  onChange: (value?: FieldValueCamera) => void,
  required?: boolean,
  error?: string,
  showPreview?: boolean,
})
{
  const defn = props.defn;
  const onChange = props.onChange;
  const fieldValue = props.fieldValue;
  const placeHolder = props.placeHolder;
  const icon = props.icon;
  const required = props.required;
  const error = props.error;
  const showPreview = props.showPreview;

  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();
  const appCtx = useAppCtx();

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const showSize = props.showSize;
  const isMobile = appCtx.isMobile();

  const [loading, setLoading] = useState(false);

  const isFieldEditable = formCtx.isFieldReadonly(defn);

  const onClickCapture = () =>
  {
    if(!formCtx.isFieldReadonly(defn))
    {
      pageCtx.showDialog(
        <DialogCamera
          isMobile={isMobile}
          fullScreen={isMobile}
          onClose={() => pageCtx.showDialog(undefined)}
          switchCam={true}
          onCaptureFile={(file) =>
          {
            setLoading(true);
            compressImage(
              file,
              FORM_IMAGE_MAX_MB,
              FORM_IMAGE_SIZE_MAX,
              (compressedFile) =>
              {
                const error = compressedFile.error;
                const fileName = `Captured-image.${getFileExt(file)}`;

                if(error)
                {
                  const errorMsg: IDialogMsg = {
                    text: error,
                    color: "error"
                  };
                  Srvc.app.toast.showErrorToast("Media Error: " + errorMsg);
                }
                if(compressedFile.file)
                {
                  const mediaIdImageId = nextMediaIdImage(getFileExt(compressedFile.file));
                  getPrimaryColorAndBlurredImg(compressedFile.file, (primaryColor, blurredImage) =>
                  {
                    if(primaryColor && blurredImage)
                    {
                      const mediaIdBlurImageId = nextMediaIdImage(getFileExt(blurredImage));
                      if(mediaIdBlurImageId && primaryColor)
                      {
                        compressedFile.file && MediaStore.setBlobToUrl(mediaIdImageId, compressedFile.file);
                        blurredImage && MediaStore.setBlobToUrl(mediaIdBlurImageId, blurredImage);

                        onChange({
                          value: {
                            fileName: compressedFile.file?.name ?? fileName,
                            height: compressedFile?.size?.height,
                            width: compressedFile?.size?.width,
                            size: compressedFile.file?.size,
                            mediaIdImage: mediaIdImageId,
                            mediaIdBlurImage: mediaIdBlurImageId,
                            primaryColor: primaryColor,
                            date: new Date()
                          }
                        } as FieldValueCamera);
                        setLoading(false);
                      }
                    }
                  });
                }
              }
            );
            pageCtx.showDialog(undefined);
          }}
        />);
    }
  };

  const onDeleteImage = () =>
  {
    if(!formCtx.isReadonly())
    {
      onChange(undefined);
    }
  };

  const isEditable = isFieldEditable;

  const tempMediaUrl = fieldValue?.value.mediaIdImage
    ? MediaStore.getMedia(fieldValue.value.mediaIdImage)
    : undefined;

  const imgUrl = tempMediaUrl || formCtx.getApiCtx()?.getMediaSrc(fieldValue?.value.mediaIdImage);

  const fieldId = getFieldKey(defn);
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);
  const label = getCompLabel(defn);

  return (
    <LayoutFlexCol
      justifyContent={"start"}
      alignItems={"start"}
      overflowY={"visible"}
      overflowX={"visible"}
      width={"100%"}
      flexGrow={1}
    >
      <LayoutFlexRow
        overflowY={"visible"}
        overflowX={"visible"}
        width={"100%"}
      >
        <TextField
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          fullWidth
          type={"text"}
          size={defnTheme.fieldSize}
          margin={defnTheme.fieldMargin}
          variant={defnTheme.fieldVariant}
          autoComplete={Boolean(defn.autoFill) ? "on" : "off"}
          autoFocus={Boolean(defn.autoFocus)}
          disabled={disabled}
          hiddenLabel={Boolean(defn.label)}
          label={label}
          name={fieldId}
          placeholder={placeHolder}
          InputProps={{
            readOnly: true,
            endAdornment: icon
              ? <RawIconButton
                name={icon}
                icon={icon}
              />
              : <CameraAltRounded color={"action"} />
          }}
          required={required}
          error={Boolean(error)}
          value={fieldValue ? fieldValue.value?.fileName : ""}
          onClick={onClickCapture}
          onKeyDown={(event) =>
          {
            if(event.key === "Enter")
            {
              onClickCapture();
            }
          }}
        />

        <LayoutFlexRow
          ml={px(gapHalf)}
          mr={`-${gapHalf}px`}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <RawIconStrip
            iconStrip={["visible"]}
            onClick={(icon) =>
            {
              if(icon === "visible" && imgUrl)
              {
                pageCtx.showDialog(<AvatarViewDialog
                  imageSrc={imgUrl}
                  onClose={() => pageCtx.showDialog(undefined)}
                />);
              }
            }}
            iconStripDisable={(!imgUrl) ? ["visible"] : []}
          />
        </LayoutFlexRow>
        {
          !(readOnly || disabled) &&
          <LayoutFlexRow
            ml={px(gapHalf)}
            mr={`-${gapHalf}px`}
            overflowY={"visible"}
            overflowX={"visible"}
          >
            <RawIconStrip
              toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
              iconStrip={[CLOSE]}
              onClick={(icon) =>
              {
                if(icon === CLOSE)
                {
                  onChange(undefined);
                }
              }}
              iconStripDisable={(!imgUrl || readOnly || disabled) ? [CLOSE] : []}
            />
          </LayoutFlexRow>
        }
        <FieldRawRefButton defn={defn} />
      </LayoutFlexRow>

      {
        showPreview &&
        <UploadImage
          loading={loading}
          image={fieldValue}
          onClickCapture={onClickCapture}
          onDelete={onDeleteImage}
          isEditable={!isEditable}
          formCtx={formCtx}
          defnCamera={defn}
          readOnly={readOnly}
          disabled={disabled}
          placeHolder={placeHolder}
          icon={icon}
          fieldId={getFieldKey(defn)}
        />
      }
      {
        (showSize && fieldValue) &&
        <ImageData image={fieldValue} />
      }
    </LayoutFlexCol>
  );
}

function UploadImage(props: {
  loading?: boolean,
  image?: FieldValueCamera,
  onClickCapture: () => void,
  onDelete: () => void,
  fieldId: string,
  isEditable: boolean,
  formCtx: IFormCtx,
  defnCamera: DefnFieldCamera,
  readOnly?: boolean,
  disabled?: boolean,
  placeHolder?: string
  icon?: string,
})
{
  const {
    image,
    loading,
    onDelete,
    onClickCapture,
    isEditable,
    formCtx,
    defnCamera,
    readOnly,
    disabled,
    placeHolder,
    icon,
    fieldId
  } = props;

  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";

  const getOnClick = formCtx.getOnClick();

  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const _isEditable = isEditable && !(readOnly || disabled || isReport);

  const selectBoxStyle = {
    aspectRatio: 1 / 1,
    borderStyle: "dashed",
    borderColor: theme.palette.grey[300],
    borderRadius: px(gapHalf),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  };

  return (
    <Box
      sx={{
        width: fieldCameraPreviewWidth,
        height: fieldCameraPreviewHeight,
        aspectRatio: 1 / 1,
        padding: px(3),
        justifyContent: "start",
        marginTop: px(gapQuarter)
      }}
      onClick={onClick}
    >
      {
        loading
          ? <Box
            width={"100%"}
            height={"100%"}
            sx={selectBoxStyle}
          >
            <CircularProgress />
          </Box>
          : image
            ? <ImagePreview
              image={image}
              isEditable={_isEditable}
              onDelete={onDelete}
              formCtx={formCtx}
              defnCamera={defnCamera}
            />
            : <ImagePicker
              isEditable={_isEditable}
              selectBoxStyle={selectBoxStyle}
              onClickCapture={onClickCapture}
              placeHolder={placeHolder}
              icon={icon}
            />
      }

    </Box>
  );
}

function ImagePreview(props: {
  image: FieldValueCamera,
  isEditable: boolean,
  onDelete: () => void,
  formCtx: IFormCtx,
  defnCamera: DefnFieldCamera,
  selectBoxStyle?: SxProps,
  borderRadius?: Property.BorderRadius
})
{
  const formCtx = useFormCtx();

  const image = props.image;
  const tempMediaUrl = MediaStore.getMedia(image.value.mediaIdImage);
  const imgUrl = tempMediaUrl || formCtx.getApiCtx()?.getMediaSrc(image.value.mediaIdImage);
  const blurImgUrl = formCtx.getApiCtx()?.getMediaSrc(image.value.mediaIdBlurImage);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={props.selectBoxStyle}
    >
      <RawLazyImage
        src={imgUrl}
        srcBlur={blurImgUrl}
        primaryColor={image.value.primaryColor}
        width={"100%"}
        height={"100%"}
        objectFit={"cover"}
        borderRadius={props.borderRadius ? props.borderRadius : px(gapHalf)}
      />
    </Box>
  );
}

function ImagePicker(props: {
  onClickCapture: () => void,
  isEditable: boolean,
  selectBoxStyle: SxProps,
  placeHolder?: string
  icon?: string,
})
{
  const onClickCapture = props.onClickCapture;
  const isEditable = props.isEditable;
  const selectBoxStyle = props.selectBoxStyle;
  const placeHolder = props.placeHolder;
  const icon = props.icon;

  const theme = useTheme();

  return (isEditable
      ?
      <Box
        width={"100%"}
        height={"100%"}
        sx={selectBoxStyle}
        onClick={onClickCapture}
      >
        <LayoutFlexCol
          width={"100%"}
        >
          <RawIconButton
            name={!icon ? "CameraAlt" : icon}
            icon={!icon ? "CameraAlt" : icon}
          />
          <Typography
            variant={"body2"}
            sx={{
              width: "100%",
              color: theme.palette.grey[300],
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center"
            }}
          >
            {!placeHolder ? "Capture image" : placeHolder}
          </Typography>
        </LayoutFlexCol>
      </Box>
      : <Box
        width={"100%"}
        height={"100%"}
        sx={{
          aspectRatio: 1 / 1,
          borderStyle: "hidden",
          bgcolor: theme.palette.grey[100],
          borderRadius: px(gapStd),
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ImageRounded
          sx={{
            width: theme.common.sizeAvatar,
            color: theme.palette.text.secondary
          }}
        />
      </Box>
  );
}

function ImageData(props: {
  image: FieldValueImage,
})
{
  const image = props.image;

  return (
    <FieldLabel
      defn={{
        label: `Width: ${image?.value?.width}, Height: ${image?.value?.height}`,
        textSizeVar: "caption"
      } as DefnFieldLabel}
    />
  );
}
