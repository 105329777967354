import {CalendarPickerView} from "@mui/lab";
import {Box} from "@mui/material";
import {DateValidationError} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers";
import {PickerChangeHandlerContext} from "@mui/x-date-pickers/models";
import {useCallback} from "react";
import {ChangeEvent} from "react";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {Noop} from "react-hook-form";
import {DefnBuildDate} from "../../../../api/meta/base/dto/DefnBuildDate";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldDateRange} from "../../../../api/meta/base/dto/DefnFieldDateRange";
import {FieldValueDateRange} from "../../../../api/meta/base/dto/FieldValueDateRange";
import {formatDateToISO} from "../../../../base/plus/DatePlus";
import {getLocalDateFormat} from "../../../../base/plus/DatePlus";
import {extractDateFormat} from "../../../../base/plus/DatePlus";
import {getBuildDateDefaultValue} from "../../../../base/plus/FieldValuePlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {CLEAR} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {CLOSE} from "../../../../base/types/TypesIcon";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import {getRawIcon} from "../../../atom/raw/RawIcon";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import IFormCtx from "../base/CtxForm";
import FieldRawRefButton from "./FieldRawRefButton";

const dateFormat = getLocalDateFormat();

export default function FieldRawDateRange(props: {
  defn: DefnFieldDateRange,
  onChange: (value?: FieldValueDateRange | null) => void,
  fieldValue?: FieldValueDateRange,
  views?: CalendarPickerView[],
  defnTheme: DefnDtoFormTheme,
  formCtx: IFormCtx,
  fieldId: string,
  inputFormat?: string,
  autoFocus?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  isError?: boolean,
  required?: boolean,
  icon?: string,
  minDate?: DefnBuildDate,
  maxDate?: DefnBuildDate,
  placeholder?: string,
  onBlur?: Noop,
  onClick?: () => void,
})
{
  const {
    defn,
    formCtx,
    fieldId,
    views,
    autoFocus,
    readOnly,
    disabled,
    onChange,
    fieldValue,
    defnTheme,
    isError,
    required,
    icon,
    placeholder,
    minDate,
    maxDate,
    onBlur,
    onClick
  } = props;

  const [startDate, setStartDate] = useState<string | undefined>();

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const dateOnly = defn?.displayDateFormat
    ? extractDateFormat(defn.displayDateFormat)
    : extractDateFormat(dateFormat);

  const buildMinDate = minDate ? getBuildDateDefaultValue(minDate) : undefined;
  const buildMaxDate = maxDate ? getBuildDateDefaultValue(maxDate) : undefined;

  const _minDate = buildMinDate ? new Date(buildMinDate) : undefined;
  const _maxDate = buildMaxDate ? new Date(buildMaxDate) : undefined;

  const fromValue = fieldValue?.from
    ? new Date(fieldValue.from)
    : null;
  const toValue = fieldValue?.to
    ? new Date(fieldValue.to)
    : null;

  const onChangeFrom = useCallback((date?: string) =>
  {
    if(date)
    {
      onChange({
        from: date,
        to: fieldValue?.to
      });
    }
    else
    {
      onChange(null);
    }

    setStartDate(date);

  }, [fieldValue?.to]);

  const onChangeTo = useCallback((date?: string) =>
  {
    if(fieldValue?.from)
    {
      onChange({
        from: fieldValue.from,
        to: date
      });
    }
    else
    {
      onChange(null);
    }

  }, [fieldValue?.from]);

  useEffect(() =>
  {
    if(fieldValue?.from)
    {
      setStartDate(fieldValue.from);
    }

  }, []);

  return (
    <LayoutFlexCol
      width={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
      onClick={() =>
      {
        onClick && onClick();
      }}
    >
      <RawLocalizationProvider>
        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DatePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            format={dateOnly}
            minDate={_minDate}
            maxDate={_maxDate}
            label={"Date From"}
            value={fromValue}
            views={["year", "month", "day"]}
            autoFocus={autoFocus}
            readOnly={readOnly}
            disabled={disabled}
            onClose={onBlur}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            slotProps={{
              textField: {
                placeholder: placeholder,
                fullWidth: true,
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: defnTheme.fieldVariant,
                autoComplete: Boolean(defn.autoFill) ? "on" : "off",
                required: required,
                onBlur: onBlur,
                error: isError,
                value: fromValue,
                //@ts-ignore
                onChange: (
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                  context: PickerChangeHandlerContext<DateValidationError>) =>
                {
                  const eventValue = event as unknown as string;

                  if(context.validationError !== "invalidDate")
                  {
                    const dateValue = new Date(eventValue);

                    onChangeFrom(formatDateToISO(dateValue));
                  }
                }
              }
            }}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const date = new Date(event);
                const from = formatDateToISO(date);

                onChangeFrom(from);
              }
            }}
          />

          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
                iconStrip={[CLOSE]}
                onClick={(icon) =>
                {
                  if(icon === CLOSE)
                  {
                    onChangeFrom(undefined);
                  }
                }}
                iconStripDisable={(!fieldValue?.from || readOnly || disabled) ? [CLOSE] : []}
              />
            </LayoutFlexRow>
          }
          <FieldRawRefButton
            defn={defn}
          />
        </LayoutFlexRow>
        <LayoutGap height={px(gapStd)} />
        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DatePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            value={toValue}
            label={"Date To"}
            views={views}
            format={dateOnly}
            autoFocus={autoFocus}
            readOnly={readOnly}
            disabled={disabled || !startDate}
            minDate={startDate ? new Date(startDate) : undefined}
            maxDate={_maxDate}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const date = new Date(event);

                onChangeTo(formatDateToISO(date));
              }
            }}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            slotProps={{
              textField: {
                fullWidth: true,
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: defnTheme.fieldVariant,
                autoComplete: Boolean(defn.autoFill) ? "on" : "off",
                required: required,
                onBlur: onBlur,
                onClick: onClick,
                error: isError,
                value: toValue,
                //@ts-ignore
                onChange: (
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                  context: PickerChangeHandlerContext<DateValidationError>) =>
                {
                  const eventValue = event as unknown as string;

                  if(context.validationError !== "invalidDate")
                  {
                    const dateValue = new Date(eventValue);

                    onChangeTo(formatDateToISO(dateValue));
                  }
                }
              }
            }}
          />
          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
                iconStrip={[CLOSE]}
                onClick={(icon) =>
                {
                  if(icon === CLOSE)
                  {
                    onChangeTo(undefined);
                  }
                }}
                iconStripDisable={(!fieldValue?.to || readOnly || disabled) ? [CLOSE] : []}
              />
            </LayoutFlexRow>
          }
          <Box sx={{visibility: "hidden"}}>
            <FieldRawRefButton
              defn={defn}
            />
          </Box>
        </LayoutFlexRow>
      </RawLocalizationProvider>

    </LayoutFlexCol>
  );
}

