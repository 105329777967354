import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDate} from "../../../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldTime} from "../../../../../api/meta/base/dto/DefnFieldTime";
import {StudioFieldDateTime} from "../../../../../api/meta/base/dto/StudioFieldDateTime";
import {TimeZoneKey} from "../../../../../api/meta/base/Types";
import {EnumDefnDate} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {getDateTimeValue} from "../../base/FieldBuilderPlus";
import {propKeyMaxCustomTime} from "../../base/TypesFormBuilder";
import {propKeyMinCustomTime} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueTime} from "../../base/TypesFormBuilder";
import {propKeyMinCustomDateTime} from "../../base/TypesFormBuilder";
import {propKeyMaxCustomDateTime} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueDateTime} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyDisplayDateFormat} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldDateTime(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  showCustomDate?: boolean,
  defaultValue?: EnumDefnDate,
  showCustomMax?: boolean,
  showCustomMin?: boolean,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[],
  timeZone?: TimeZoneKey
)
{
  const isDefaultCreatedOrUpdated = defaultValue === "createdOn" || defaultValue === "updatedOn";

  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinFieldId,
    propKeyMaxFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinVar,
    propKeyMaxVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyDefaultValue,
    ...showCustomDate ? [propKeyDefaultValueDateTime] :
      isDefaultCreatedOrUpdated ? [] : [propKeyDefaultValueTime],
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMin,
    ...showCustomMin ? [propKeyMinCustomDateTime] : [propKeyMinCustomTime],
    propKeyMax,
    ...showCustomMax ? [propKeyMaxCustomDateTime] : [propKeyMaxCustomTime],
    fieldGap4,
    propKeyDisplayDateFormat
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "dateTime",
      formId,
      "dateTime",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      showCustomDate,
      sectionIdSetWithCurrentGridId,
      undefined,
      timeZone
    ),

    [propKeyDisplayDateFormat]: {
      type: "dateFormat",
      name: propKeyDisplayDateFormat,
      metaId: propKeyDisplayDateFormat,
      label: "Display date format"
    } as DefnFieldPickEnum,

    ...!showCustomDate && {
      [propKeyDefaultValueTime]: {
        type: "time",
        metaId: propKeyDefaultValueTime,
        name: propKeyDefaultValueTime,
        label: "Custom time"
      } as DefnFieldTime
    },

    ...showCustomMax && {
      [propKeyMaxCustomDateTime]: {
        type: "dateTime",
        metaId: propKeyMaxCustomDateTime,
        name: propKeyMaxCustomDateTime,
        label: "Custom max date time",
        timeZone: timeZone
      } as DefnFieldDate
    },

    ...!showCustomMax && {
      [propKeyMaxCustomTime]: {
        type: "time",
        metaId: propKeyMaxCustomTime,
        name: propKeyMaxCustomTime,
        label: "Custom max time"
      } as DefnFieldTime
    },

    ...showCustomMin && {
      [propKeyMinCustomDateTime]: {
        type: "dateTime",
        metaId: propKeyMinCustomDateTime,
        name: propKeyMinCustomDateTime,
        label: "Custom min date time",
        timeZone: timeZone
      } as DefnFieldDate
    },

    ...!showCustomMin && {
      [propKeyMinCustomTime]: {
        type: "time",
        metaId: propKeyMinCustomTime,
        name: propKeyMinCustomTime,
        label: "Custom min time"
      } as DefnFieldTime
    }
  };
}

export function defnValueToStudioFieldDateTime(values: FieldValues): StudioFieldDateTime
{

  return {
    type: "dateTime",
    defaultValue: getDateTimeValue(values, propKeyDefaultValue, propKeyDefaultValueDateTime, propKeyDefaultValueTime),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    min: getDateTimeValue(values, propKeyMin, propKeyMinCustomDateTime, propKeyMinCustomTime),
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    max: getDateTimeValue(values, propKeyMax, propKeyMaxCustomDateTime, propKeyMaxCustomTime),
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    displayDateFormat: fnFieldValueToRawValue("dateFormat", values[propKeyDisplayDateFormat])
  } as StudioFieldDateTime;

}

export function studioFieldToDefnValueDateTime(studioField: StudioFieldDateTime)
{

  const defaultValue = studioField.defaultValue;
  const customValue = fnRawValueToFieldValue("date", defaultValue?.customValue);
  const time = fnRawValueToFieldValue("time", defaultValue?.time);

  const minValue = studioField.min;
  const customValueMin = fnRawValueToFieldValue("date", minValue?.customValue);
  const timeMin = fnRawValueToFieldValue("time", minValue?.time);

  const maxValue = studioField.max;
  const customValueMax = fnRawValueToFieldValue("date", maxValue?.customValue);
  const timeMax = fnRawValueToFieldValue("time", maxValue?.time);

  return {
    [propKeyDefaultValue]: customValue ? "custom" : defaultValue?.value,
    [propKeyDefaultValueDateTime]: customValue,
    [propKeyDefaultValueTime]: time,
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMin]: customValueMin ? "custom" : minValue?.value,
    [propKeyMinCustomDateTime]: customValueMin,
    [propKeyMinCustomTime]: timeMin,
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMax]: customValueMax ? "custom" : maxValue?.value,
    [propKeyMaxCustomDateTime]: customValueMax,
    [propKeyMaxCustomTime]: timeMax,
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyDisplayDateFormat]: fnRawValueToFieldValue("dateFormat", studioField.displayDateFormat)
  };
}

