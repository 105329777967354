import {isEmpty} from "lodash";
import {toUpper} from "lodash";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {StudioPluginApiMap} from "../../../api/meta/base/dto/StudioPluginApiMap";
import {PluginId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {formatDateToISO} from "../../../base/plus/DatePlus";
import {formatDate} from "../../../base/plus/DatePlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioPluginApis extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.plugin.pluginApiList;
  }

  loadPluginApiList(
    pluginBundleId: PluginBundleId,
    pluginId: PluginId,
    listName: string,
    pluginApiMap?: StudioPluginApiMap,
    readOnly?: boolean)
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    if(!pluginApiMap)
    {
      return;
    }

    pluginApiMap.keys.forEach((itemId) =>
    {
      const pluginApi = pluginApiMap.map[itemId];
      const apiModules: StudioModuleSelection | undefined = pluginApi.modules;

      if(Srvc.studio.plugin.filterListByTag(pluginBundleId, pluginId, apiModules))
      {
        uiItemIds.push(itemId);
      }

      uiItemsById[itemId] = {
        type: "ps",
        primary: {
          text: pluginApi.name,
          caption: {
            text: pluginApi.creationDate
              ? formatDate(formatDateToISO(new Date(pluginApi.creationDate)), "local")
              : formatDate(formatDateToISO(new Date()), "local"),
            ignoreSelection: true
          }
        },
        secondary: {
          text: !isEmpty(pluginApi.description)
            ? pluginApi.description
            : "No description",
          caption: {
            text: toUpper(pluginApi.apiType),
            ignoreSelection: true
          },
          icon3: pluginApi.guaranteedInvocation ? "queue" : undefined,
          color: "textDisabled"
        },
        hideMenu: readOnly === true,
        ignoreSelectionCaption: true,
        userField: {
          apiType: pluginApi.apiType
        }
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }
}
