import {Link} from "@mui/material";
import {Typography} from "@mui/material";
import {TypographyVariant} from "@mui/material";
import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {NEOME_PLAY_STORE_URL} from "../../../base/plus/ConstantsPlus";
import {NEOME_APP_STORE_URL} from "../../../base/plus/ConstantsPlus";
import {PRIVACY_POLICY_URL} from "../../../base/plus/ConstantsPlus";
import {TERMS_OF_SERVICE_URL} from "../../../base/plus/ConstantsPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {ISize} from "../../../base/types/TypesGlobal";
import {useWinSize} from "../../../nucleus/app/AppHooks";
import appStore from "../../../nucleus/atom/assets/app-store-button.svg";
import neomeDrive from "../../../nucleus/atom/assets/neome-drive-download-button.svg";
import GooglePlay from "../../../nucleus/atom/assets/play-store-button.svg";
import DialogDriveDownload from "../../../nucleus/atom/dialog/DialogDriveDownload";
import LayoutFlexCol from "../../../nucleus/atom/layout/LayoutFlexCol";
import LogoMainAuth from "../../../nucleus/atom/logo/LogoMainAuth";
import {usePageCtx} from "../../../nucleus/ctx/CtxPage";
import {useAuthCtx} from "../ctx/CtxAuth";
import AuthCtxProvider from "../ctx/CtxAuth";
import {ICtxAuth} from "../ctx/ICtxAuth";

const preferredAuthFormSize = 560;
const minTabletHeight = 520;
const minBannerSideWidth = 1110;

export default function UiTemplateAuth(props: {
  children: React.ReactNode,
  showBottomText?: boolean
})
{
  const theme = useTheme();
  const winSize = useWinSize();
  const gapStd = theme.common.gapStd;
  const winWidth = winSize.width;
  const winHeight = winSize.height;
  const bannerSide = winWidth >= (minBannerSideWidth);
  const isTablet = winHeight >= (minTabletHeight);

  const authCtx = {} as ICtxAuth;
  authCtx.getGapStd = () => gapStd;

  return (
    <AuthCtxProvider authCtx={authCtx}>
      {
        bannerSide
          ? <BannerAuthFormSideBySide
            showFooter={isTablet && props.showBottomText}
            winSize={winSize}
          >
            {props.children}
          </BannerAuthFormSideBySide>
          : <BannerAuthFormTopAndBottom
            showBottomText={props.showBottomText}
            winSize={winSize}
          >
            {props.children}
          </BannerAuthFormTopAndBottom>
      }
    </AuthCtxProvider>
  );
}

function BannerAuthFormSideBySide(props: {
  children: React.ReactNode,
  winSize: ISize
  showFooter?: boolean,
})
{
  const authCtx = useAuthCtx();
  const winSize = props.winSize;
  const winHeight = winSize.height;
  const winWidth = winSize.width;

  const pageScrollHeight = 200;
  const gapStd = authCtx.getGapStd();

  authCtx.getAuthFormWidth = () => preferredAuthFormSize;
  authCtx.isAuthFormShadow = () => true;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "row",
        alignItems: "center",
        bgcolor: theme.palette.background.paper,
        minHeight: pageScrollHeight,
        height: {height: winHeight < pageScrollHeight ? "auto" : winHeight},
        width: winWidth
      }}
    >
      <Box
        flexGrow={1}
        flex={1}
        height={"100%"}
        padding={px(gapStd)}
      >
        <LogoMainAuth
          winWidth={winWidth}
          borderRadius={gapStd}
        />
      </Box>

      <LayoutFlexCol
        justifyContent={"center"}
        alignItems={"center"}
        flex={1}
        height={"100%"}
        pr={px(gapStd)}
      >
        <LayoutFlexCol
          flex={1}
          height={"100%"}
          width={"100%"}
        >
          {props.children}
        </LayoutFlexCol>
        {props.showFooter && <FooterCTA />}
      </LayoutFlexCol>
    </Box>
  );
}

function BannerAuthFormTopAndBottom(props: {
  children: React.ReactNode,
  winSize: ISize,
  showBottomText?: boolean
})
{
  const authCtx = useAuthCtx();
  const winSize = props.winSize;

  const gapStd = theme.common.gapStd;
  const winWidth = winSize.width;
  const winHeight = winSize.height;

  const authFormWidth = winWidth < preferredAuthFormSize ? winWidth : preferredAuthFormSize;

  authCtx.getAuthFormWidth = () => authFormWidth;
  authCtx.isAuthFormShadow = () => winWidth >= preferredAuthFormSize + 2 * gapStd;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: theme.palette.background.paper,
        height: winHeight,
        width: "100%",
        overflow: "auto"
      }}
    >
      <LogoMainAuth
        winWidth={winWidth}
      />

      <LayoutFlexCol
        pt={px(gapQuarter)}
        width={"100%"}
        flexGrow={1}
        justifyContent={"space-between"}
      >
        {props.children}
        {props.showBottomText && <TermsAndPrivacyNotice variant={"caption"} />}
      </LayoutFlexCol>
    </Box>
  );
}

function FooterCTA()
{
  const pageCtx = usePageCtx();

  const onClickDrive = () =>
  {
    pageCtx.showDialog(
      <DialogDriveDownload
        title={"Download neome drive"}
      />);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: px(6),
          pb: px(gapQuarter)
        }}
      >
        <AppDownloadButton
          icon={GooglePlay}
          link={NEOME_PLAY_STORE_URL}
        />
        <AppDownloadButton
          icon={appStore}
          link={NEOME_APP_STORE_URL}
        />
        <AppDownloadButton
          icon={neomeDrive}
          onClick={onClickDrive}
        />
      </Box>
      <TermsAndPrivacyNotice variant={"caption"} />
    </Box>
  );
}

function AppDownloadButton(props: {icon: string, link?: string, onClick?: () => void})
{
  const icon = props.icon;
  const link = props.link;
  const onClick = props.onClick;

  return (
    <Box
      onClick={onClick}
      sx={{width: px(168)}}
    >
      <Link
        target={"_blank"}
        href={link}
      >
        <img
          style={{cursor: "pointer"}}
          src={icon}
          alt={"icon-button"}
        />
      </Link>
    </Box>
  );
}

function TermsAndPrivacyNotice(props: {variant?: TypographyVariant})
{
  const variant = props.variant;

  return (
    <Box
      pb={px(gapStd)}
      pr={px(gapStd)}
      pl={px(gapStd)}
    >
      <Typography
        textAlign={"center"}
        variant={variant}
        color={theme.palette.text.disabled}
      >
        By signing in or creating your account, you agree to our
        <Typography
          textAlign={"center"}
          variant={variant}
          display={"inline"}
          color={theme.palette.text.disabled}
        > </Typography>
        <Link
          target={"_blank"}
          variant={variant}
          href={TERMS_OF_SERVICE_URL}
          sx={{cursor: "pointer"}}
          color={theme.palette.text.disabled}
        >Terms of service
        </Link>
        <Typography
          textAlign={"center"}
          variant={variant}
          display={"inline"}
          color={theme.palette.text.disabled}
        > & </Typography>
        <Link
          target={"_blank"}
          variant={variant}
          href={PRIVACY_POLICY_URL}
          sx={{cursor: "pointer"}}
          color={theme.palette.text.disabled}
        >
          Privacy policy
        </Link>
        <Typography
          textAlign={"center"}
          variant={variant}
          display={"inline"}
          color={theme.palette.text.disabled}
        >.</Typography>
      </Typography>
    </Box>
  );
}
