import axios from "axios";
import {cloneDeep} from "lodash";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {DtoEntActionReport} from "../../../api/ent/base/dto/DtoEntActionReport";
import {MsgSpreadsheetRowCommentCountGet} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowCommentCountGet";
import {MsgSpreadsheetRowRemove} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowRemove";
import {RpcEntMain} from "../../../api/ent/entMain/RpcEntMain";
import {WsocEntMain} from "../../../api/ent/entMain/WsocEntMain";
import {DtoGroupMemberKey} from "../../../api/home/base/dto/DtoGroupMemberKey";
import {DtoMessagePayloadAudio} from "../../../api/home/base/dto/DtoMessagePayloadAudio";
import {DtoMessagePayloadDocument} from "../../../api/home/base/dto/DtoMessagePayloadDocument";
import {DtoMessagePayloadImage} from "../../../api/home/base/dto/DtoMessagePayloadImage";
import {DtoMessagePayloadReport} from "../../../api/home/base/dto/DtoMessagePayloadReport";
import {DtoMessagePayloadSpreadsheetPartition} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetPartition";
import {DtoMessagePayloadSpreadsheetRow} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {DtoMessagePayloadVideo} from "../../../api/home/base/dto/DtoMessagePayloadVideo";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {MsgMessageListOffset} from "../../../api/home/main/msg/MsgMessageListOffset";
import {MsgOffset} from "../../../api/home/main/msg/MsgOffset";
import {SigMessageList} from "../../../api/home/main/sig/SigMessageList";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {WsocMain} from "../../../api/home/main/WsocMain";
import {SigTopicMessageNew} from "../../../api/home/session/sig/SigTopicMessageNew";
import {SigTopicMessageProps} from "../../../api/home/session/sig/SigTopicMessageProps";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {isNonGlobalEntId} from "../../../api/meta/base/ApiPlus";
import {isRowId} from "../../../api/meta/base/ApiPlus";
import {isEntUserId} from "../../../api/meta/base/ApiPlus";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {MediaId} from "../../../api/meta/base/Types";
import {SpreadsheetPartitionId} from "../../../api/meta/base/Types";
import {EntUserId} from "../../../api/meta/base/Types";
import {MessageId} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {SigDone} from "../../../api/nucleus/base/sig/SigDone";
import ISrvcChat from "../../../base/ISrvcChat";
import {resolveChatLabelPatternVar} from "../../../base/plus/ArgBinderPlus";
import {resolveChatPatternVar} from "../../../base/plus/ArgBinderPlus";
import {getMsgPayloadSpreadsheetId} from "../../../base/plus/BubblePlus";
import {isBubbleFormValueFieldMediaType} from "../../../base/plus/BubblePlus";
import {appendChat} from "../../../base/plus/ChatPlus";
import {loadChat} from "../../../base/plus/ChatPlus";
import {dispatchChat} from "../../../base/plus/ChatPlus";
import {STR_YOU} from "../../../base/plus/ConstantsPlus";
import {STR_REMOVE_FOR_EVERYONE} from "../../../base/plus/ConstantsPlus";
import {STR_REMOVE_FOR_ME} from "../../../base/plus/ConstantsPlus";
import {CHAT_ITEM_PAGE_SIZE_NEW_MSG} from "../../../base/plus/ConstantsPlus";
import {getFormNameColor} from "../../../base/plus/FormPlus";
import {filterForm} from "../../../base/plus/FormPlus";
import {getMediaSrc} from "../../../base/plus/MediaPlus";
import {chatReset} from "../../../base/slices/chat/SliceChat";
import {chatSetAppendingNewMsg} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetFormBubbleTitleColor} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetCallerEntMediaId} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatAppendMsg} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetCallerEntUserId} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistMsgReply} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistMessage} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistIsCanExpire} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistIsMsgForwardable} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistSpreadsheetRowComment} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistChatPatternVar} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistIsVisibleSpreadsheetRow} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistSigSpreadsheetRowExpiry} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistIsFormWithMedia} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatHandleSigTopicFormRemove} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistIsCommentable} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistInitiatorUserAvatarName} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistTargetUserAvatarName} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistSpreadsheetRow} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistHeader} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistMarkAsRead} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatHandleSigTopicMessageRemoveForMe} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistIsStar} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatHandleSigTopicMessageProps} from "../../../base/slices/chat/SliceChatSharedActions";
import {chatSetIfExistDefnForm} from "../../../base/slices/chat/SliceChatSharedActions";
import {IBubbleMessage} from "../../../base/types/TypesBubble";
import {isMsgTypeCenterWithTargetMember} from "../../../base/types/TypesChat";
import {isMsgTypeCenter} from "../../../base/types/TypesChat";
import {SelectChat} from "../../../base/types/TypesChat";
import {TypeChatItemId} from "../../../base/types/TypesChat";
import {IChatItemSig} from "../../../base/types/TypesChat";
import {IChatBinder} from "../../../base/types/TypesChat";
import {toComboId} from "../../../base/types/TypesComboId";
import {CbProgress} from "../../../base/types/TypesGlobal";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {logError} from "../../../base/util/AppLog";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {selectCallerEntAction} from "../../../routes/app/formViewer/FormViewerPlus";
import {IParamMessage} from "../../../routes/home/ctx/ICtxHome";
import {getCallerEntUserId} from "../../../Store";
import {textUserOrYou} from "../../../Store";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";
import {isCommentableForm} from "../form/SrvcForm";

export default abstract class SrvcChat extends ISrvcChat
{
  subscriberId = "SrvcChat";
  private newMsgQueue: NewMessageQueue;

  protected constructor(readonly selectChat: SelectChat)
  {
    super();
    this.newMsgQueue = new NewMessageQueue(selectChat);
  }

  subscribe(subscriberId: TypeSubscriberId, entId: EntId, chatId: ChatId, unSubscribe?: boolean)
  {
    if(isNonGlobalEntId(entId))
    {
      const callerEnt = selectCallerEnt(store.getState(), entId);
      if(!callerEnt)
      {
        Srvc.app.pubsub.caller.subCallerEnt(entId);
      }
    }
    Srvc.app.pubsub.msg.messageNew(subscriberId, entId, chatId, unSubscribe);
    Srvc.app.pubsub.msg.messageProps(subscriberId, entId, chatId, unSubscribe);
    Srvc.app.pubsub.msg.messageClearChat(subscriberId, entId, chatId, unSubscribe);
    this.newMsgQueue.init();
  }

  subscribeItem(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    chatId: ChatId,
    chatItem: IChatItemSig,
    unSubscribe?: boolean)
  {
    this.subscribeBubbleMessage(subscriberId, entId, chatId, chatItem.sig, unSubscribe);

    if(!chatItem.sig.isCallerSender && (isGroupId(chatId) || isRowId(chatId)))
    {
      const entUserId = toComboId(entId, chatItem.sig.senderId);
      Srvc.app.pubsub.homeAvatar(subscriberId, entUserId, unSubscribe);
    }
    this.subscribeChatItemReactionUser(subscriberId, entId, chatItem, unSubscribe);
    this.subscribeChatItemReplySenderUser(subscriberId, entId, chatItem, unSubscribe);
    this.subscribeChatItemCenterMsgUser(subscriberId, entId, chatItem, unSubscribe);
  }

  getChatBinder()
  {
    return {
      selectDefnForm: this.selectDefnForm.bind(this),
      bindDefnForm: this.onBindDefnForm.bind(this),

      selectIsStarMsg: this.selectIsStarMsg.bind(this),
      bindIsStarMsg: this.onBindIsStarMsg.bind(this),

      selectMsgMarkAsRead: this.selectMsgMarkAsRead.bind(this),
      bindMsgMarkAsRead: this.onBindMsgMarkAsRead.bind(this),

      selectSenderAvatar: this.selectSenderAvatar.bind(this),
      bindSenderAvatar: this.onBindSenderAvatar.bind(this),

      selectCenterInitiatorUserAvatar: this.selectCenterInitiatorUserAvatar.bind(this),
      bindCenterInitiatorUserAvatar: this.onBindCenterInitiatorUserAvatar.bind(this),

      selectCenterTargetUserAvatar: this.selectCenterTargetUserAvatar.bind(this),
      bindCenterTargetUserAvatar: this.onBindCenterTargetUserAvatar.bind(this),

      selectCanShowComments: this.selectCanShowComments.bind(this),
      bindCanShowComments: this.onBindCanShowComments.bind(this),

      selectChatPattern: this.selectChatPattern.bind(this),
      bindChatPattern: this.onBindChatPattern.bind(this),

      selectIsMsgForwardable: this.selectIsMsgForwardable.bind(this),
      bindIsMsgForwardable: this.onBindIsExistingMsgForwardable.bind(this),

      selectCanExpire: this.selectCanExpiry.bind(this),
      bindIsCanExpire: this.onBindCanExpiry.bind(this),

      selectReply: this.selectReply.bind(this),
      bindReply: this.onBindReply.bind(this),

      selectUserAvatar: this.selectUserAvatar.bind(this),

      getMediaSrc: this.getMediaSrc.bind(this)

    } as IChatBinder;
  }

  handleSigTopicMessage(rootState: RootState, sig: SigTopic): void
  {
    const chat = this.selectChat(rootState);
    if(chat.loadedVersion === undefined || chat.sigEntId === undefined || chat.sigChatId === undefined)
    {
      return;
    }
    const chatName = chat.chatName;
    const entId = sig.artifactId as EntId;
    const messageId = sig.aboutId as MessageId;
    const chatItemId = chat.displayMsgIdMap[messageId];
    const chatItem = chat.displayItemMap[chatItemId] as IChatItemSig | undefined;
    if(chatItem?.sig)
    {
      this.wsocMessageGet(chat.sigEntId as EntId, chat.sigChatId as ChatId, chatItem.sig.messageOffset, (sigMessage) =>
      {
        if(sigMessage === undefined)
        {
          if(chat.sigEntId)
          {
            this.subscribeChatItemReactionUser(this.subscriberId, entId, chatItem, true);
          }
          dispatchChat(chatName, chatHandleSigTopicMessageRemoveForMe({messageId}));
        }
        else if(sigMessage.payload.messageType)
        {
          if(chat.sigEntId)
          {
            this.subscribeChatItemReactionUser(this.subscriberId, entId, chatItem);
          }
          dispatchChat(chatName, chatSetIfExistMessage(sigMessage));
        }
      });
    }
  }

  handleSigTopicMessageProps(rootState: RootState, sig: SigTopicMessageProps): void
  {
    const chat = this.selectChat(rootState);
    if(sig.aboutId !== chat.sigChatId)
    {
      return;
    }
    const chatName = chat.chatName;
    dispatchChat(chatName, chatHandleSigTopicMessageProps(sig));
  }

  handleSigTopicMessageNew(rootState: RootState, sig: SigTopicMessageNew): void
  {
    const chat = this.selectChat(rootState);
    if(sig.aboutId !== chat.sigChatId)
    {
      return;
    }
    const pageSize = Math.floor(CHAT_ITEM_PAGE_SIZE_NEW_MSG);

    const displayItemCount = chat.displayItemCount;
    if(displayItemCount && displayItemCount > 0)
    {
      this.newMsgQueue.handleSigTopicMessageNew(sig);
    }
    else
    {
      loadChat(this.selectChat, chat.sigEntId as ChatId, chat.sigChatId as ChatId, pageSize);
    }
  }

  handleSigTopicFormResult(rootState: RootState, sig: SigTopic): void
  {
    const chat = this.selectChat(rootState);
    const chatName = chat.chatName;
    const entId = chat.sigEntId;
    const rowId = sig.aboutId as RowId;
    const itemIds = chat.displayRowIdMap[rowId];
    const sigEntUserId = chat.sigEntUserId;

    itemIds?.forEach((itemId) =>
    {
      const chatItem = (chat.displayItemMap[itemId] as IChatItemSig);

      if(chatItem && entId)
      {
        const payload = chatItem.sig.payload;
        const spreadsheetId = getMsgPayloadSpreadsheetId(payload);
        const version = chatItem.sig.sigSpreadsheetRow?.version;

        if(spreadsheetId)
        {
          Srvc.app.spreadsheet.wsocSpreadsheetRowGet(entId, rowId, spreadsheetId, version,
            (sig) =>
            {
              const formValue = sig.formValue;
              const valueMap = formValue?.valueMap;
              const defnForm = chatItem.sig.defnForm || this.getDefnFormFromMsgPayload(rootState, entId, payload);

              if(defnForm && valueMap)
              {
                Srvc.app.form.formViewer.subscribeFieldEntUser(this.subscriberId, entId, defnForm, valueMap);
              }

              dispatchChat(chatName, chatSetFormBubbleTitleColor({
                itemId: itemId,
                formBubbleTitleColor: getFormNameColor(
                  sigEntUserId,
                  formValue?.updatedBy,
                  sig.updatedKeySet
                )
              }));

              dispatchChat(chatName, chatSetIfExistSpreadsheetRow({
                sigSpreadsheetRow: sig,
                itemId: itemId
              }));

              if(chatItem.sig.isInvisibleSpreadsheetRow)
              {
                dispatchChat(chatName, chatSetIfExistIsVisibleSpreadsheetRow({
                  itemId: itemId,
                  isInvisibleSpreadsheetRow: false
                }));
              }
            },
            (sigError) =>
            {
              const errorNotFound = sigError.validationErrors?.find((error) => error.errorCode === "notFound");
              const errorNotAccessible =
                sigError.validationErrors?.find((error) => error.errorCode === "notAccessible");
              if(errorNotAccessible)
              {
                dispatchChat(chatName, chatSetIfExistIsVisibleSpreadsheetRow({
                  itemId: itemId,
                  isInvisibleSpreadsheetRow: true
                }));
              }
              else if(errorNotFound && payload?.messageType !== "spreadsheetPartition")
              {
                dispatchChat(chatName, chatHandleSigTopicFormRemove(chatItem.sig.messageId));
              }
            }
          );
        }
      }
    });
  }

  handleSigTopicFormComment(rootState: RootState, sig: SigTopic): void
  {
    const chat = this.selectChat(rootState);
    const chatName = chat.chatName;
    const entId = chat.sigEntId;
    const rowId = sig.aboutId as RowId;
    const itemIds = chat.displayRowIdMap[rowId];
    itemIds?.forEach((itemId) =>
    {
      const chatItem = (chat.displayItemMap[itemId] as IChatItemSig);
      let version = chatItem.sig.sigSpreadsheetRow?.rowCommentCount?.version;
      if(chatItem.sig.payload.messageType === "report")
      {
        version = chatItem.sig.reportRowCommentCount?.version;
      }
      if(chatItem && entId)
      {
        this.wsocSpreadsheetRowCommentCountGet(chatName, itemId, entId, rowId, version);
      }
    });
  }

  handleSigTopicMessageClearChat(rootState: RootState, sig: SigTopic)
  {
    const chat = this.selectChat(rootState);

    if(chat.loadedVersion === undefined || sig.aboutId !== chat.sigChatId)
    {
      return;
    }
    dispatchChat(chat.chatName, chatReset());
  }

  handleSigTopicSpreadsheetRowDurationExpiry(rootState: RootState, sig: SigTopic)
  {
    const chat = this.selectChat(rootState);
    const chatName = chat.chatName;
    const entId = chat.sigEntId;
    const spreadsheetPartitionId = sig.aboutId as SpreadsheetPartitionId;
    const itemIds = chat.displayPartitionIdMap[spreadsheetPartitionId];
    if(itemIds?.length)
    {
      const firstItemId = itemIds[0];
      const chatItem = (chat.displayItemMap[firstItemId] as IChatItemSig);
      const payload = chatItem.sig.payload;
      const spreadsheetId = getMsgPayloadSpreadsheetId(payload);
      if(entId && spreadsheetId && spreadsheetPartitionId)
      {
        Srvc.app.spreadsheet.wsocSpreadsheetRowExpiryGet(entId,
          spreadsheetPartitionId,
          spreadsheetId,
          undefined,
          (envSignal) =>
          {
            if(envSignal)
            {
              itemIds?.forEach((itemId) =>
              {
                dispatchChat(chatName, chatSetIfExistSigSpreadsheetRowExpiry({
                  itemId: itemId,
                  sigSpreadsheetRowExpiry: envSignal
                }));
              });
            }
          }
        );
      }
    }
  }

  getMessageRemoveDialogActions(
    isGroupAdmin: boolean,
    chatItems: IParamMessage[])
  {
    let isAllSenderCaller = true;
    let isAnyDeletedMsg = false;
    let isCommentMsg = false;
    let canRemoveSpreadsheetRow = false;
    let canRemoveAllSpreadsheetRow = true;
    const removeChatMsgMap = {} as Record<string, (chatItem: IParamMessage, rowDelete: boolean) => Promise<void>>;

    if(chatItems.length >= 0)
    {
      const rootState = store.getState();
      chatItems.forEach((chatItem) =>
      {
        const messageType = chatItem.message.payload.messageType;
        if(isRowId(chatItem.chatId))
        {
          isCommentMsg = true;
        }
        if(!chatItem.message.isCallerSender)
        {
          isAllSenderCaller = false;
        }
        if(messageType === "messageDeleted")
        {
          isAnyDeletedMsg = true; // if any message is already deleted (for everyone)
        }
        if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition")
        {
          const spreadsheetId = getMsgPayloadSpreadsheetId(chatItem.message.payload);
          const spreadsheetRow = (chatItem.message.payload as DtoMessagePayloadSpreadsheetRow | DtoMessagePayloadSpreadsheetPartition)?.spreadsheetRow;
          const canRemove = spreadsheetId
            ? Srvc.app.spreadsheet.canRemove(rootState,
              chatItem.entId,
              spreadsheetId,
              spreadsheetRow?.formValue?.createdBy
            )
            : false;
          if(canRemove)
          {
            canRemoveSpreadsheetRow = true;
          }
          else
          {
            canRemoveAllSpreadsheetRow = false;
          }
        }
      });
      if(isAnyDeletedMsg || (!isAllSenderCaller && !isGroupAdmin))
      {
        removeChatMsgMap[STR_REMOVE_FOR_ME] = (chatItems, rowDelete) =>
        {
          if(rowDelete)
          {
            this.rpcSpreadsheetRowRemove(chatItems);
          }
          return this.wsocMessageRemoveForMe(chatItems);
        };
      }
      else
      {
        removeChatMsgMap[STR_REMOVE_FOR_ME] = (chatItems, rowDelete) =>
        {
          if(rowDelete)
          {
            this.rpcSpreadsheetRowRemove(chatItems);
          }
          return this.wsocMessageRemoveForMe(chatItems);
        };

        removeChatMsgMap[STR_REMOVE_FOR_EVERYONE] = (chatItems, rowDelete) =>
        {
          if(rowDelete)
          {
            this.rpcSpreadsheetRowRemove(chatItems);
          }
          return this.wsocMessageRemoveForEveryone(chatItems);
        };
      }
      if(isCommentMsg)
      {
        delete removeChatMsgMap[STR_REMOVE_FOR_ME];
      }
    }

    return {
      removeChatMsgMap,
      canRemoveSpreadsheetRow,
      canRemoveAllSpreadsheetRow
    };
  }

  downloadMedia(entId: EntId, message: IBubbleMessage, cbSuccess?: CbSuccess, onError?: CbProgress)
  {
    const getDownloadUrlAndFileName = (sig: IBubbleMessage) =>
    {
      const payload = sig.payload;
      switch(sig.payload.messageType)
      {
        case "document":
          const documentPayload = payload as DtoMessagePayloadDocument;
          return {
            src: getMediaSrc(documentPayload.mediaIdDocument, entId),
            mediaFileName: documentPayload.fileName
          };
        case "image":
        case "camera":
          const imagePayload = payload as DtoMessagePayloadImage;
          return {
            src: getMediaSrc(imagePayload.mediaIdImage, entId),
            mediaFileName: imagePayload.mediaIdImage
          };
        case "video":
          const videoPayload = payload as DtoMessagePayloadVideo;
          return {
            src: getMediaSrc(videoPayload.mediaIdVideo, entId),
            mediaFileName: videoPayload.fileName
          };
        case "audio":
        case "voice":
          const audioPayload = payload as DtoMessagePayloadAudio;
          return {
            src: getMediaSrc(audioPayload.mediaIdAudio, entId),
            mediaFileName: audioPayload.text
          };
        default:
          return undefined;
      }
    };

    const downloadUrlAndFileName = getDownloadUrlAndFileName(message);

    if(downloadUrlAndFileName)
    {
      const mediaFileName = downloadUrlAndFileName.mediaFileName;
      const src = downloadUrlAndFileName.src as string;
      axios.get(
        src, {
          responseType: "blob"
        }
      ).then((response) =>
      {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(new Blob([response.data]));
        link.setAttribute("download", mediaFileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        cbSuccess && cbSuccess();
      }).catch((err) =>
      {
        logError("downloadMedia error", err);
        Srvc.app.toast.showErrorToast("downloading failed");
        onError && onError();
      });
    }
  }

  setChatCallerEntUserId(entId: EntId, remove?: boolean)
  {
    const rootState = store.getState();
    const chat = this.selectChat(rootState);
    const chatName = chat.chatName;
    const entUserId = rootState.cache.app.caller.entUserIdMap[entId];
    const entUserAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];

    if(remove)
    {
      dispatchChat(chatName, chatSetCallerEntUserId(undefined));
      dispatchChat(chatName, chatSetCallerEntMediaId(undefined));
    }
    if(entUserId)
    {
      dispatchChat(chatName, chatSetCallerEntUserId(entUserId));
      dispatchChat(chatName, chatSetCallerEntMediaId(entUserAvatar?.avatarId));
    }
  }

  protected onWsocAuth()
  {
    const rootState = store.getState();
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const chatId = chat.sigChatId;
    const loadedVersion = chat.loadedVersion;
    if(entId && chatId && loadedVersion)
    {
      appendChat(this.selectChat, loadedVersion, CHAT_ITEM_PAGE_SIZE_NEW_MSG, true);
    }
  }

  //region private

  private subscribeChatItemReactionUser(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    chatItem: IChatItemSig,
    unSubscribe?: boolean)
  {
    if(chatItem.sig.reactionMap)
    {
      const reactionMap = chatItem.sig.reactionMap;
      const entUserIdSet = Object.keys(reactionMap);
      entUserIdSet.forEach((_entUserId) =>
      {
        const entUserId = toComboId(entId, _entUserId);
        const comboSubscribeId = toComboId(subscriberId, chatItem.sig.messageId);

        Srvc.app.pubsub.homeAvatar(comboSubscribeId, entUserId, unSubscribe);// for bubble reaction preview
      });
    }
  }

  private subscribeChatItemCenterMsgUser(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    chatItem: IChatItemSig,
    unSubscribe?: boolean)
  {
    const payload = chatItem.sig.payload;
    const messageType = payload.messageType;
    if(isMsgTypeCenter(messageType))
    {
      const entUser = this.getInitiatorMemberEntUser(payload);
      if(entUser?.entUserId)
      {
        Srvc.app.pubsub.homeAvatar(subscriberId, toComboId(entId, entUser.entUserId), unSubscribe);
      }
      if(isMsgTypeCenterWithTargetMember(messageType))
      {
        const entUser = this.getTargetMemberEntUser(payload);
        if(entUser?.entUserId)
        {
          Srvc.app.pubsub.homeAvatar(subscriberId, toComboId(entId, entUser.entUserId), unSubscribe);
        }
      }
    }
  }

  private subscribeChatItemReplySenderUser(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    chatItem: IChatItemSig,
    unSubscribe?: boolean)
  {
    const replyPayload = chatItem.sig.replyPayload;
    if(!chatItem.sig.isCallerSender && replyPayload?.senderId)
    {
      const senderId = replyPayload?.senderId;
      const entUserId = toComboId(entId, senderId);
      Srvc.app.pubsub.homeAvatar(subscriberId, entUserId, unSubscribe);
    }
  }

  private wsocSpreadsheetRowCommentCountGet(
    chatName: string,
    itemId: TypeChatItemId,
    entId: EntId,
    rowId: RowId,
    version?: string)
  {

    const msg: MsgSpreadsheetRowCommentCountGet = {
      rowId: rowId,
      version: version
    };

    WsocEntMain.spreadsheetRowCommentCountGet(entId, msg, (envSig) =>
    {
      if(envSig.sig)
      {
        dispatchChat(chatName, chatSetIfExistSpreadsheetRowComment({
          sigSpreadsheetRowComment: envSig.sig,
          itemId: itemId
        }));
      }
    });
  }

  private wsocMessageMarkRead(
    entId: EntId,
    chatId: ChatId,
    messageOffset: number,
    cbSuccess?: (envSig: EnvSignal<SigDone>) => void)
  {
    const msg = {
      chatId: chatId,
      offset: messageOffset
    } as MsgOffset;

    WsocMain.messageMarkRead(entId, msg, envSig => cbSuccess && cbSuccess(envSig));
  }

  //region binder

  private selectDefnForm(rootState: RootState, itemId: TypeChatItemId): DefnForm | undefined
  {
    const item = this.selectChat(rootState).displayItemMap[itemId] as IChatItemSig;
    const entId = this.selectChat(rootState).sigEntId;
    const payload = item.sig.payload;
    if(entId === undefined || payload === undefined)
    {
      return undefined;
    }

    return this.getDefnFormFromMsgPayload(rootState, entId, payload);
  }

  private onBindDefnForm(chatName: string, itemId: TypeChatItemId, defnForm?: DefnForm): void
  {
    if(defnForm)
    {
      const rootState = store.getState();
      const item = this.selectChat(rootState).displayItemMap[itemId] as IChatItemSig;
      const entId = this.selectChat(rootState).sigEntId;
      if(entId === undefined)
      {
        return undefined;
      }

      const callerEnt = selectCallerEnt(rootState, entId);
      const messageType = item.sig.payload.messageType;
      let formValue: FormValue | FormValueRaw | undefined = undefined;
      if(messageType === "spreadsheetRow")
      {
        const payload = (item.sig.payload as DtoMessagePayloadSpreadsheetRow);
        formValue = payload.spreadsheetRow?.formValue;
      }
      else if(messageType === "report")
      {
        const payload = (item.sig.payload as DtoMessagePayloadReport);
        formValue = payload.formValueRaw;
      }
      else if(messageType === "spreadsheetPartition")
      {
        const payload = (item.sig.payload as DtoMessagePayloadSpreadsheetPartition);
        formValue = payload.spreadsheetRow?.formValue;
      }
      const filteredForm = filterForm(defnForm,
        callerEnt?.roleIdSet || [],
        callerEnt,
        formValue,
        undefined,
        (_, comp) =>
        {
          if(comp && isBubbleFormValueFieldMediaType(comp) && formValue?.valueMap && formValue.valueMap[comp?.metaId])
          {
            dispatchChat(chatName, chatSetIfExistIsFormWithMedia({
              itemId: itemId,
              isFormWithMedia: true
            }));
          }
        }
      );

      dispatchChat(chatName, chatSetIfExistDefnForm({
        defnForm: filteredForm,
        itemId: itemId
      }));
    }
  }

  private selectIsStarMsg(rootState: RootState, itemId: TypeChatItemId): boolean
  {
    const item = this.selectChat(rootState).displayItemMap[itemId] as IChatItemSig;
    const messageId = item.sig.messageId;
    return messageId
      ? Boolean(rootState.cache.app.starMsg.starMsgMap[messageId])
      : false;
  }

  private onBindIsStarMsg(chatName: string, itemId: TypeChatItemId, isStar: boolean): void
  {
    dispatchChat(chatName, chatSetIfExistIsStar({
      itemId: itemId,
      isStar: isStar
    }));
  }

  private selectMsgMarkAsRead(rootState: RootState, itemId: TypeChatItemId): IChatItemSig | undefined
  {
    return this.selectChat(rootState).displayItemMap[itemId] as IChatItemSig;
  }

  private onBindMsgMarkAsRead(chatName: string, itemId: TypeChatItemId, chatItem?: IChatItemSig): void
  {
    const rootState = store.getState();
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const chatId = chat.sigChatId;
    if(chatItem
      && !chatItem?.sig?.isMarkAsRead
      && entId
      && chatId)
    {
      // don't wait for response directly mark msg as read
      dispatchChat(chatName, chatSetIfExistMarkAsRead(itemId));
      this.wsocMessageMarkRead(entId, chatId, chatItem.sig.messageOffset);
    }
  }

  private selectSenderAvatar(rootState: RootState, itemId: TypeChatItemId): SigUserAvatar | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const chatId = chat.sigChatId;
    if(!entId || isEntUserId(chatId))
    {
      return undefined;
    }
    else
    {
      const senderId = (chat.displayItemMap[itemId] as IChatItemSig).sig.senderId;
      const entUserId = toComboId(entId, senderId);
      return rootState.cache.app.user.userAvatarMap[entUserId];
    }
  }

  private onBindSenderAvatar(chatName: string, itemId: TypeChatItemId, avatar?: SigUserAvatar): void
  {
    if(avatar)
    {
      const rootState = store.getState();
      const chatId = this.selectChat(rootState).sigChatId;
      if(!isEntUserId(chatId))
      {
        dispatchChat(chatName, chatSetIfExistHeader({
          itemId: itemId,
          header: {
            headerTextLeft1: textUserOrYou(rootState, avatar as SigUserAvatar),
            color: avatar.userColor
          }
        }));
      }
    }
  }

  private selectCenterInitiatorUserAvatar(rootState: RootState, itemId: TypeChatItemId): SigUserAvatar | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;
    const payload = item.sig.payload;
    const messageType = payload.messageType;
    if(isMsgTypeCenter(messageType) && !isMsgTypeCenterWithTargetMember(messageType))
    {
      const entUserId = this.getInitiatorMemberEntUser(payload)?.entUserId;
      if(entId && entUserId)
      {
        return rootState.cache.app.user.userAvatarMap[toComboId(entId, entUserId)];
      }
    }
    return undefined;
  }

  private onBindCenterInitiatorUserAvatar(chatName: string, itemId: TypeChatItemId, avatar?: SigUserAvatar): void
  {
    const rootState = store.getState();
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;

    if(item)
    {
      const payload = item.sig.payload;
      const entUser = this.getInitiatorMemberEntUser(payload);

      if(entId && entUser)
      {
        if(!avatar)
        {
          dispatchChat(chatName, chatSetIfExistInitiatorUserAvatarName({
            itemId: itemId,
            initiatorMemberName: this.isYou(rootState, entId, entUser)
          }));
          return;
        }

        dispatchChat(chatName, chatSetIfExistInitiatorUserAvatarName({
          itemId: itemId,
          initiatorMemberName: textUserOrYou(rootState, avatar as SigUserAvatar)
        }));
      }
    }
  }

  private selectCenterTargetUserAvatar(rootState: RootState, itemId: TypeChatItemId): SigUserAvatar | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;
    const payload = item.sig.payload;
    const messageType = payload.messageType;

    if(isMsgTypeCenterWithTargetMember(messageType))
    {
      const entUserId = this.getTargetMemberEntUser(payload)?.entUserId;
      if(entId && entUserId)
      {
        return rootState.cache.app.user.userAvatarMap[toComboId(entId, entUserId)];
      }
    }
    return undefined;
  }

  private onBindCenterTargetUserAvatar(chatName: string, itemId: TypeChatItemId, avatar?: SigUserAvatar): void
  {
    const rootState = store.getState();
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;
    if(item)
    {
      const payload = item.sig.payload;
      const entUser = this.getTargetMemberEntUser(payload);

      if(entId && entUser)
      {
        if(!avatar)
        {

          dispatchChat(chatName, chatSetIfExistTargetUserAvatarName({
            itemId: itemId,
            targetMemberName: this.isYou(rootState, entId, entUser)
          }));

          return;
        }

        dispatchChat(chatName, chatSetIfExistTargetUserAvatarName({
          itemId: itemId,
          targetMemberName: textUserOrYou(rootState, avatar as SigUserAvatar)
        }));
      }
    }
  }

  private isYou(rootState: RootState, entId: EntId, entUser: DtoGroupMemberKey)
  {
    return entUser.entUserId === getCallerEntUserId(rootState, entId)
      ? STR_YOU
      : (entUser.name ?? entUser.handle);
  }

  private selectCanShowComments(rootState: RootState, itemId: TypeChatItemId): boolean
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;
    const senderId = item.sig.senderId;
    const messageType = item.sig.payload.messageType;

    if(entId !== undefined)
    {
      if(messageType === "spreadsheetRow")
      {
        const formId = (item.sig.payload as DtoMessagePayloadSpreadsheetRow).formId;
        return Boolean(isCommentableForm(rootState, entId, formId, senderId));
      }
      else if(messageType === "report")
      {
        const actionId = (item.sig.payload as DtoMessagePayloadReport).actionId;
        const action = actionId ? selectCallerEntAction(rootState, entId, actionId) as DtoEntActionReport : undefined;
        const formId = action?.inputFormId;
        return Boolean(isCommentableForm(rootState, entId, formId, senderId));
      }
    }
    return false;
  }

  private onBindCanShowComments(chatName: string, itemId: TypeChatItemId, isCommentAble?: boolean): void
  {
    dispatchChat(chatName, chatSetIfExistIsCommentable({
      itemId: itemId,
      isCommentAble: isCommentAble
    }));
  }

  private selectChatPattern(rootState: RootState, itemId: TypeChatItemId): SigSpreadsheetRow | undefined
  {
    return this.selectChatSigSpreadsheetRow(rootState, itemId);
  }

  private onBindChatPattern(chatName: string, itemId: TypeChatItemId, sigSpreadsheetRow?: SigSpreadsheetRow): void
  {
    const rootState = store.getState();
    const entId = this.selectChat(rootState).sigEntId;
    const item = this.selectChat(rootState).displayItemMap[itemId] as IChatItemSig;
    if(entId)
    {
      const callerEnt = selectCallerEnt(rootState, entId);
      const defnForm = item.sig.defnForm;
      if(defnForm)
      {
        const defnFormClone = cloneDeep(defnForm);
        const formValue = sigSpreadsheetRow?.formValue;

        const chatPatternVar = resolveChatPatternVar(defnFormClone,
          callerEnt,
          formValue
        );
        const chatLabelPatternVar = resolveChatLabelPatternVar(defnFormClone,
          callerEnt,
          formValue
        );

        dispatchChat(chatName, chatSetIfExistChatPatternVar({
          chatPatternVar: chatPatternVar,
          chatLabelPatternVar: chatLabelPatternVar,
          itemId: itemId
        }));
      }
    }
  }

  private selectIsMsgForwardable(rootState: RootState, itemId: TypeChatItemId): boolean | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;

    if(item && entId)
    {
      const messageType = item.sig.payload.messageType;

      if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition" || messageType === "report")
      {
        const spreadsheetId = getMsgPayloadSpreadsheetId(item.sig.payload);
        return spreadsheetId
          ? Srvc.app.spreadsheet.canForward(rootState, entId, spreadsheetId, item.sig.senderId)
          : false;
      }
      if(messageType === "image" || messageType === "video"
        || messageType === "camera" || messageType === "location"
        || messageType === "linkText" || messageType === "user"
        || messageType === "group" || messageType === "voice"
        || messageType === "document" || messageType === "audio")
      {
        return true;
      }
    }
  }

  private onBindIsExistingMsgForwardable(chatName: string, itemId: TypeChatItemId, isMsgForwardable: boolean): void
  {
    dispatchChat(chatName, chatSetIfExistIsMsgForwardable({
      itemId: itemId,
      isMsgForwardable: isMsgForwardable
    }));
  }

  private selectCanExpiry(rootState: RootState, itemId: TypeChatItemId): boolean | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;
    if(item && entId)
    {
      const messageType = item.sig.payload.messageType;

      if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition" || messageType === "report")
      {
        const spreadsheetId = getMsgPayloadSpreadsheetId(item.sig.payload);
        return spreadsheetId
          ? selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.canExpire
          : undefined;
      }
    }
  }

  private onBindCanExpiry(chatName: string, itemId: TypeChatItemId, canExpire: boolean): void
  {
    dispatchChat(chatName, chatSetIfExistIsCanExpire({
      itemId: itemId,
      canExpire: canExpire
    }));
  }

  private selectReply(rootState: RootState, itemId: TypeChatItemId): SigUserAvatar | undefined
  {
    const chat = this.selectChat(rootState);
    const item = chat.displayItemMap[itemId] as IChatItemSig;
    const entId = chat.sigEntId;
    const replyPayload = item.sig.replyPayload;
    const senderId = replyPayload?.senderId;
    if(entId && senderId)
    {
      const entUserId = toComboId(entId, senderId);
      return rootState.cache.app.user.userAvatarMap[entUserId];
    }
  }

  private onBindReply(chatName: string, itemId: TypeChatItemId, sender?: SigUserAvatar): void
  {
    if(!sender)
    {
      return;
    }

    const rootState = store.getState();
    const senderName = textUserOrYou(rootState, sender);
    const senderColor = sender.userColor;

    dispatchChat(chatName, chatSetIfExistMsgReply({
      itemId: itemId,
      replyInfo: {
        senderName: senderName,
        senderColor: senderColor
      }
    }));
  }

  private selectUserAvatar(
    rootState: RootState,
    entUserId: EntUserId): SigUserAvatar | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    if(entId)
    {
      const comboEntUserId = toComboId(entId, entUserId);
      return rootState.cache.app.user.userAvatarMap[comboEntUserId];
    }
  }

  private getMediaSrc(rootState: RootState, mediaId?: MediaId): string | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    return getMediaSrc(mediaId, entId);
  }

  private selectChatSigSpreadsheetRow(rootState: RootState, itemId: TypeChatItemId): SigSpreadsheetRow | undefined
  {
    const chat = this.selectChat(rootState);
    const entId = chat.sigEntId;
    const item = chat.displayItemMap[itemId] as IChatItemSig;
    const messageType = item.sig.payload.messageType;

    if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition" || messageType === "report")
    {
      if(entId !== undefined)
      {
        return item.sig.sigSpreadsheetRow;
      }
    }
    return undefined;
  }

  //endregion

  private wsocMessageRemoveForMe(chatItem: IParamMessage)
  {
    return new Promise<void>((resolve) =>
    {
      const msg: MsgOffset = {
        chatId: chatItem.chatId,
        offset: chatItem.message.messageOffset
      };
      WsocMain.messageRemoveForMe(chatItem.entId, msg, () => resolve());
    });
  }

  private wsocMessageRemoveForEveryone(chatItem: IParamMessage)
  {
    return new Promise<void>((resolve) =>
    {
      const msg: MsgOffset = {
        chatId: chatItem.chatId,
        offset: chatItem.message.messageOffset
      };
      WsocMain.messageRemoveForEveryone(chatItem.entId, msg, () => resolve());
    });
  }

  private rpcSpreadsheetRowRemove(chatItem: IParamMessage)
  {
    return new Promise<void>((resolve) =>
    {
      const spreadsheetRowPayload = chatItem.message.payload as DtoMessagePayloadSpreadsheetRow;
      const msg: MsgSpreadsheetRowRemove = {
        formId: spreadsheetRowPayload.formId,
        spreadsheetId: spreadsheetRowPayload.spreadsheetId,
        rowId: spreadsheetRowPayload.rowId
      };

      RpcEntMain.spreadsheetRowRemove(chatItem.entId, msg, envSig =>
      {
        if(!Srvc.app.toast.showErrorToast(envSig))
        {
          resolve();
        }
      });
    });
  }

  private wsocMessageListNext(entId: EntId, chatId: ChatId, offset: number, cb: (msgList: SigMessageList) => void)
  {
    const msg = {
      chatId: chatId,
      offset: offset,
      pageSize: 10
    } as MsgMessageListOffset;
    WsocMain.messageListNext(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        cb(sig);
      }
    });
  }

  //endregion
}

class NewMessageQueue
{
  private signalCount = 0;
  private processing = false;
  private throttleTailFlag = false;
  private busyTimeout: NodeJS.Timeout | undefined;
  private readonly busyDelay = 3000;
  private processingTimeout: NodeJS.Timeout | undefined;
  private readonly processingDelay = 30000;

  constructor(readonly selectChat: SelectChat)
  {
  }

  handleSigTopicMessageNew(sig: SigTopicMessageNew): void
  {
    this.signalCount++;
    if(this.busyTimeout)
    {
      clearTimeout(this.busyTimeout);
      this.busyTimeout = undefined;
    }
    if(this.processing)
    {
      this.throttleTailFlag = true;
      const chatName = this.selectChat(store.getState()).chatName;
      dispatchChat(chatName, chatSetAppendingNewMsg(true));
      this.busyTimeout = setTimeout(() =>
      {
        dispatchChat(chatName, chatSetAppendingNewMsg(false));
      }, this.busyDelay);
      return;
    }
    this.processing = true;
    this.load(sig.artifactId as EntId, sig.aboutId as ChatId);
    this.processingTimeout = setTimeout(() =>
    {
      this.processing = false;
      this.processingTimeout = undefined;
    }, this.processingDelay);
  }

  init()
  {
    this.processing = false;
    this.throttleTailFlag = false;
    this.signalCount = 0;
  }

  private load(entId: EntId, chatId: ChatId)
  {
    const chat = this.selectChat(store.getState());
    const size = (this.signalCount + 4);
    this.signalCount = 0;

    if(this.processingTimeout)
    {
      clearTimeout(this.processingTimeout);
    }

    if(chatId !== chat.sigChatId || chat.topOffset === 0)
    {
      this.processing = false;
      this.throttleTailFlag = false;
      return;
    }
    this.wsocMessageListNext(chat.sigEntId as EntId, chat.sigChatId as ChatId, chat.topOffset, size, msgList =>
    {
      const chat = this.selectChat(store.getState());
      Promise.resolve(dispatchChat(chat.chatName, chatAppendMsg({
        sig: msgList,
        loadedVersion: chat.loadedVersion || ""
      })))
      .then(() =>
      {
        if(this.throttleTailFlag)
        {
          this.throttleTailFlag = false;
          this.load(entId, chatId);
        }
        else
        {
          this.processing = false;
        }
      });
    });
  }

  private wsocMessageListNext(
    entId: EntId,
    chatId: ChatId,
    offset: number,
    pageSize: number,
    cb: (msgList: SigMessageList) => void)
  {
    const msg = {
      chatId: chatId,
      offset: offset,
      pageSize: pageSize
    } as MsgMessageListOffset;
    WsocMain.messageListNext(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        cb(sig);
      }
      else
      {
        this.processing = false;
      }
    });
  }

}
