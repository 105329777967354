import {LOG_WTF} from "../../../base/util/AppLog";
import {LOG_SEND} from "../../../base/util/AppLog";
import {LOG_RECV} from "../../../base/util/AppLog";
import {RootState} from "../../../Store";

export const Request = "Request";
export const Response = "Response";

export function fnCanStoreLog(rootState: RootState): boolean
{
  const appLogs = localStorage.getItem("appLog") || sessionStorage.getItem("appLog");
  if(appLogs === "true")
  {
    return true;
  }
  if(appLogs === "false")
  {
    return false;
  }
  const callerHandle = rootState.cache.app.caller.callerHandle;
  if(!callerHandle)
  {
    return false;
  }
  return callerHandle.includes("@neomenta.com") || callerHandle.includes("@neoxl.com");
}

export function parseLog(log: string): ILogMetaData
{
  const split = log.split("|");
  const time = split[0].slice(2);
  const type = split[1].trim();
  const cls = split[2].trim();
  const message = split[3];
  const color = split.at(-1);
  const msgStart = message?.slice(1, 3).toString();
  const isRecv = msgStart === LOG_RECV;
  const isSend = msgStart === LOG_SEND;
  const isWtf = msgStart === LOG_WTF;

  return {
    time,
    type,
    cls,
    message,
    color,
    isRecv,
    isSend,
    isWtf
  };
}

export interface ILogMetaData
{
  time?: string;
  type?: string;
  cls?: string;
  message?: string;
  color?: string;
  isRecv?: boolean;
  isSend?: boolean;
  isWtf?: boolean;
}


