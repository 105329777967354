import {startOfDay} from "date-fns";
import {startOfWeek} from "date-fns";
import {parseISO} from "date-fns";
import {set} from "date-fns";
import {getDay} from "date-fns";
import {parse} from "date-fns";
import {format} from "date-fns";
import {enIN} from "date-fns/locale";
import {enUS} from "date-fns/locale";
import {isEmpty} from "lodash";
import {useState} from "react";
import {useCallback} from "react";
import {useEffect} from "react";
import {Formats} from "react-big-calendar";
import {dateFnsLocalizer} from "react-big-calendar";
import {View} from "react-big-calendar";
import {Action} from "redux";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnLayoutCalendar} from "../../api/meta/base/dto/DefnLayoutCalendar";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {FieldValueColor} from "../../api/meta/base/dto/FieldValueColor";
import {FieldValueDate} from "../../api/meta/base/dto/FieldValueDate";
import {FieldValueDateRange} from "../../api/meta/base/dto/FieldValueDateRange";
import {FormValue} from "../../api/meta/base/dto/FormValue";
import {FormValueRaw} from "../../api/meta/base/dto/FormValueRaw";
import {AnyTime} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdComp} from "../../api/meta/base/Types";
import {IHeaderProps} from "../../nucleus/calendar/base/calendarModule/CalendarHeader";
import {store} from "../../Store";
import {RootState} from "../../Store";
import {fnCreateCalendarSlice} from "../slices/calendar/SliceCalendar";
import {getCalendarNameFake} from "../slices/calendar/SliceCalendar";
import {IListItemSsRowBubble} from "../types/list/TypeListSsRowBubble";
import {ICalendarEvent} from "../types/TypeCalendar";
import {TypeCalendarItemId} from "../types/TypeCalendar";
import {ICalendar} from "../types/TypeCalendar";
import {isFormValueAndFormFieldIsMediaType} from "./BubblePlus";
import {getFormFieldValueAsTextWithPrefixSuffix} from "./FieldValuePlus";
import {defaultSectionKey} from "./FormPlus";
import {createDefaultDefnFormStudio} from "./FormPlus";
import {px} from "./StringPlus";
import {CssBackgroundColor} from "./ThemePlus";
import theme from "./ThemePlus";

//region render calendar

export const minCalendarShellHeaderHeight = 20;
export const calendarShellBorderColor = theme.common.borderColor;
export const calendarShellItemBgColor = theme.common.bgcolorPrompt;
export const calendarShellTodayDateColor = theme.common.color("errorLight");
export const calendarShellTodayDateTextColor = theme.common.color("white");

export const nowDate = new Date();

//endregion

//region Calendar slice
export function dispatchCalendar(calendar: ICalendar | string, action: Action<string>)
{
  action.type = action.type.replace(getCalendarNameFake(), typeof calendar === "string"
    ? calendar
    : calendar.calendarName);
  return store.dispatch(action);
}

export type SelectCalendar = (state: RootState) => ICalendar;

export function createCalendarSlice(name: string)
{
  if(name === getCalendarNameFake())
  {
    throw new Error(`Calendar name, ${name}, not allowed`);
  }
  else
  {
    return fnCreateCalendarSlice(name);
  }
}

//endregion

export function getCalendarBubbleDefnForm(defnForm: DefnForm, showFieldIdSet?: MetaIdField[]): DefnForm | undefined
{
  if(!defnForm)
  {
    return undefined;
  }
  const newCompMap = {} as Record<MetaIdComp, DefnComp>;
  const compMap = defnForm.compMap;
  const defaultSection = "defaultCalendarSection";

  showFieldIdSet?.forEach((fieldId) =>
  {
    newCompMap[fieldId] = compMap[fieldId];
  });

  return createDefaultDefnFormStudio({
    ...newCompMap,
    [defaultSection]: {
      type: "section",
      metaId: defaultSection,
      fieldIdSet: Object.keys(newCompMap)
    } as DefnSection,

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabIdSet: [defaultSection]
    } as DefnTab
  });

}

export function createCalendarBubble(
  defnForm?: DefnForm,
  formValue?: FormValue,
  bgColorShell?: CssBackgroundColor
): IListItemSsRowBubble
{
  const isFormWithMedia = defnForm && formValue?.valueMap
    ? isFormValueAndFormFieldIsMediaType(defnForm, formValue.valueMap)
    : false;

  return {
    type: "ssRowBubble",
    sig: {
      isCallerSender: false,
      defnForm: defnForm,
      isFormWithMedia: isFormWithMedia,
      bgColorShell: bgColorShell
    },
    formValue: formValue,
    hideFooter: true,
    hideMenu: true,
    ignoreSelection: true
  } as IListItemSsRowBubble;
}

export function useCalendarToolBarHandler(props: {
  toolbar?: IHeaderProps
})
{
  const toolbar = props.toolbar;
  const view = toolbar?.view;

  // State to manage the current month and year
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate);

  const calculateNewDate = (date: Date, view?: View, isForward?: boolean) =>
  {
    const newDate = new Date(date);
    const direction = isForward ? 1 : -1;

    switch(view)
    {
      case "month":
        newDate.setMonth(newDate.getMonth() + direction);
        break;
      case "week":
        newDate.setDate(newDate.getDate() + (7 * direction));
        break;
      case "day":
        newDate.setDate(newDate.getDate() + direction);
        break;
    }

    return newDate;
  };

  const updateDateAndNotify = (newDate: Date) =>
  {
    setCurrentDate(newDate);
    setSelectedMonth(newDate);
    setSelectedYear(newDate.getFullYear());
    toolbar?.onNavigate("DATE", newDate);
  };

  const goToBack = () =>
  {
    const newDate = calculateNewDate(currentDate, view, false);
    updateDateAndNotify(newDate);
  };

  const goToNext = () =>
  {
    const newDate = calculateNewDate(currentDate, view, true);
    updateDateAndNotify(newDate);
  };

  const goToToday = () =>
  {
    let now = new Date();
    setCurrentDate(now);
    setSelectedMonth(now);
    setSelectedYear(now.getFullYear());
    toolbar?.onNavigate("TODAY", now);
  };

  const changeView = (view: View) =>
  {
    toolbar?.onView(view);
  };

  const onChangeMonth = (newDate: any) =>
  {
    const updatedMonthDate = new Date(currentDate);
    updatedMonthDate.setMonth(newDate.getMonth());
    setCurrentDate(updatedMonthDate);
    setSelectedMonth(updatedMonthDate);
    toolbar?.onNavigate("DATE", newDate);
  };

  const onChangeYear = (newDate: any) =>
  {
    const updatedYearDate = new Date(currentDate);
    updatedYearDate.setFullYear(newDate.getFullYear());
    setCurrentDate(updatedYearDate);
    setSelectedYear(newDate.getFullYear());
    toolbar?.onNavigate("DATE", updatedYearDate);
  };

  return {
    goToBack: goToBack,
    goToNext: goToNext,
    goToToday: goToToday,
    changeView: changeView,
    onChangeMonth: onChangeMonth,
    onChangeYear: onChangeYear,
    selectedMonth: selectedMonth,
    selectedYear: selectedYear,
    currentDate: currentDate
  };

}

export function useCalendarProperty()
{
  const [timeZone, setTimeZone] = useState<string>();

  useEffect(() =>
  {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  }, []);

  const views: View[] = ["month", "week", "day"];
  const locales = {
    "en-US": enUS,
    "en-IN": enIN
  };

  const getStartOfWeek = useCallback((date: Date) =>
  {
    if(timeZone)
    {
      return startOfWeek(date, {weekStartsOn: 1});
    }
  }, [timeZone]);

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: getStartOfWeek,
    getDay,
    locales
  });

  const formats: Formats = {
    dateFormat: "dd",
    dayFormat: "EEE dd",
    dayHeaderFormat: "dd-MMM",
    weekdayFormat: "EEE",
    eventTimeRangeFormat: () =>
    {
      return "";
    }
  };

  const eventPropGetter = () =>
  {
    const style = {
      backgroundColor: "transparent",
      border: "none",
      color: "inherit",
      minHeight: px(24)
    };
    return {
      style
    };
  };

  const dayPropGetter = (_date: Date, _resourceId?: number | string) =>
  {
    const style = {
      backgroundColor: "transparent"
    };
    return {style};
  };

  return {
    views: views,
    localizer: localizer,
    formats: formats,
    eventPropGetter: eventPropGetter,
    dayPropGetter: dayPropGetter
  };
}

export function useCalendarData(props: {
  defnForm?: DefnForm
  values?: Record<TypeCalendarItemId, FormValueRaw>,
  layout?: DefnLayoutCalendar;
})
{

  const layout = props.layout;
  const defnForm = props.defnForm;
  const values = props.values;

  const eventData = [] as ICalendarEvent[];

  if(!layout || !defnForm || !values)
  {
    return {
      eventData: eventData
    };
  }

  // layout assigned field id
  const colorFieldId = layout?.colorFieldId;
  const toolTipFieldId = layout?.toolTipFieldId;
  const titleFieldId = layout?.titleFieldId;

  Object.entries(values).forEach(([rowId, formValue]) =>
  {
    const valueMap = formValue?.valueMap;
    const titleValue = valueMap && titleFieldId
      ? getFormFieldValueAsTextWithPrefixSuffix(defnForm?.compMap[titleFieldId], valueMap[titleFieldId])
      : "";

    const colorValue = valueMap && colorFieldId
      ? (valueMap[colorFieldId] as FieldValueColor)?.value
      : undefined;
    const tooltipValue = valueMap && toolTipFieldId
      ? getFormFieldValueAsTextWithPrefixSuffix(defnForm?.compMap[toolTipFieldId], valueMap[toolTipFieldId])
      : undefined;

    const {
      start,
      end
    } = getEventDateRange(formValue, layout, defnForm);

    if(start && end)
    {

      //Big calendar data
      const event: ICalendarEvent = {
        itemId: rowId,
        start: start,
        end: end,
        title: titleValue,
        bgColor: colorValue,
        tooltip: tooltipValue
      };

      //Big calendar data

      eventData.push(event);
    }

  });

  return {
    eventData: eventData
  };
}

interface EventDateRange
{
  start?: Date;
  end?: Date;
}

function getEventDateRange(
  formValue: FormValueRaw,
  layout?: DefnLayoutCalendar,
  defnForm?: DefnForm
): EventDateRange
{
  const valueMap = formValue?.valueMap;
  const compMap = defnForm?.compMap;
  const fromDateFieldId = layout?.fromDateFieldId;
  const fromTimeFieldId = layout?.fromTimeFieldId;
  const toDateFieldId = layout?.toDateFieldId;
  const toTimeFieldId = layout?.toTimeFieldId;

  const formDateField = compMap && fromDateFieldId ? compMap[fromDateFieldId] : undefined;
  const formDateFieldType = formDateField ? formDateField.type : undefined;

  const fromDateValue = valueMap && fromDateFieldId ? valueMap[fromDateFieldId] : undefined;
  const toDateValue = valueMap && toDateFieldId ? valueMap[toDateFieldId] : undefined;
  const fromTimeValue = valueMap && fromTimeFieldId ? valueMap[fromTimeFieldId] : undefined;
  const toTimeValue = valueMap && toTimeFieldId ? valueMap[toTimeFieldId] : undefined;

  switch(formDateFieldType)
  {
    case "date":
      return getRangeForDateFieldType(fromDateValue, toDateValue, fromTimeValue, toTimeValue);
    case "dateTime":
      return getRangeForDateTimeFieldType(fromDateValue, toDateValue);
    case "dateRange":
      return getRangeForDateRangeFieldType(fromDateValue);
    case "dateTimeRange":
      return getRangeForDateTimeRangeFieldType(fromDateValue);
    default:
      return {
        start: undefined,
        end: undefined
      };
  }
}

function getRangeForDateFieldType(
  fromDateValue?: FieldValueDate,
  toDateValue?: FieldValueDate,
  fromTimeValue?: AnyTime,
  toTimeValue?: AnyTime
): EventDateRange
{
  if(isEmpty(fromDateValue))
  {
    return {
      start: undefined,
      end: undefined
    };
  }

  let startDate = startOfDay(parseISO(fromDateValue.value));
  let endDate = toDateValue?.value ? startOfDay(parseISO(toDateValue.value)) : startDate;

  //Add time component if time value are undefined
  if(fromTimeValue)
  {
    const [hours, minutes, seconds] = fromTimeValue.split(":").map(Number);
    startDate = set(startDate,
      {
        hours,
        minutes,
        seconds
      }
    );
  }

  if(toTimeValue)
  {
    const [hours, minutes, seconds] = toTimeValue.split(":").map(Number);
    endDate = set(endDate,
      {
        hours,
        minutes,
        seconds
      }
    );
  }

  return {
    start: startDate,
    end: endDate
  };
}

function getRangeForDateTimeFieldType(
  fromDateValue?: FieldValueDate,
  toDateValue?: FieldValueDate
)
{
  if(isEmpty(fromDateValue))
  {
    return {
      start: undefined,
      end: undefined
    };
  }

  let startDate = parseISO(fromDateValue.value);
  let endDate = toDateValue?.value ? parseISO(toDateValue.value) : startDate;

  return {
    start: startDate,
    end: endDate
  };
}

function getRangeForDateRangeFieldType(
  fromDateValue?: FieldValueDateRange
)
{
  if(!fromDateValue?.from)
  {
    return {
      start: undefined,
      end: undefined
    };
  }

  let startDate = startOfDay(parseISO(fromDateValue.from));
  let endDate = fromDateValue?.to ? startOfDay(parseISO(fromDateValue.to)) : startDate;

  return {
    start: startDate,
    end: endDate
  };
}

function getRangeForDateTimeRangeFieldType(
  fromDateValue?: FieldValueDateRange
)
{
  if(!fromDateValue?.from)
  {
    return {
      start: undefined,
      end: undefined
    };
  }

  let startDate = startOfDay(parseISO(fromDateValue.from));
  let endDate = fromDateValue?.to ? startOfDay(parseISO(fromDateValue.to)) : startDate;

  return {
    start: startDate,
    end: endDate
  };
}


