import {SigTopic} from "../../../../api/core/session/sig/SigTopic";
import {DtoGroupMemberKey} from "../../../../api/home/base/dto/DtoGroupMemberKey";
import {DtoMessagePayload} from "../../../../api/home/base/dto/DtoMessagePayload";
import {DtoMessagePayloadAudio} from "../../../../api/home/base/dto/DtoMessagePayloadAudio";
import {DtoMessagePayloadDocument} from "../../../../api/home/base/dto/DtoMessagePayloadDocument";
import {DtoMessagePayloadGroupAboutChange} from "../../../../api/home/base/dto/DtoMessagePayloadGroupAboutChange";
import {DtoMessagePayloadGroupAvatarChange} from "../../../../api/home/base/dto/DtoMessagePayloadGroupAvatarChange";
import {DtoMessagePayloadGroupCreate} from "../../../../api/home/base/dto/DtoMessagePayloadGroupCreate";
import {DtoMessagePayloadGroupExit} from "../../../../api/home/base/dto/DtoMessagePayloadGroupExit";
import {DtoMessagePayloadGroupJoinWithInvite} from "../../../../api/home/base/dto/DtoMessagePayloadGroupJoinWithInvite";
import {DtoMessagePayloadGroupMemberAdd} from "../../../../api/home/base/dto/DtoMessagePayloadGroupMemberAdd";
import {DtoMessagePayloadGroupMemberRemove} from "../../../../api/home/base/dto/DtoMessagePayloadGroupMemberRemove";
import {DtoMessagePayloadGroupNameChange} from "../../../../api/home/base/dto/DtoMessagePayloadGroupNameChange";
import {DtoMessagePayloadLinkText} from "../../../../api/home/base/dto/DtoMessagePayloadLinkText";
import {DtoMessagePayloadLocation} from "../../../../api/home/base/dto/DtoMessagePayloadLocation";
import {DtoMessagePayloadReport} from "../../../../api/home/base/dto/DtoMessagePayloadReport";
import {DtoMessagePayloadSpreadsheetRow} from "../../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {DtoMessagePayloadText} from "../../../../api/home/base/dto/DtoMessagePayloadText";
import {DtoMessagePayloadUser} from "../../../../api/home/base/dto/DtoMessagePayloadUser";
import {DtoMessagePayloadVideo} from "../../../../api/home/base/dto/DtoMessagePayloadVideo";
import {DtoMessagePayloadVoice} from "../../../../api/home/base/dto/DtoMessagePayloadVoice";
import {MsgLastMessageGet} from "../../../../api/home/drawer/msg/MsgLastMessageGet";
import {WsocDrawer} from "../../../../api/home/drawer/WsocDrawer";
import {MsgOffset} from "../../../../api/home/main/msg/MsgOffset";
import {SigLastMessage} from "../../../../api/home/main/sig/SigLastMessage";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {WsocMain} from "../../../../api/home/main/WsocMain";
import {SigTopicMessageProps} from "../../../../api/home/session/sig/SigTopicMessageProps";
import {isRowId} from "../../../../api/meta/base/ApiPlus";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {MessageId} from "../../../../api/meta/base/Types";
import {ChatId, EntId} from "../../../../api/meta/base/Types";
import {isNotFoundSignal} from "../../../../api/nucleus/base/Protocol";
import ISrvc from "../../../../base/ISrvc";
import {resolveForm} from "../../../../base/plus/ArgBinderPlus";
import {resolveChatLabelPatternVar} from "../../../../base/plus/ArgBinderPlus";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getMsgPayloadRowId} from "../../../../base/plus/BubblePlus";
import {getMsgPayloadSpreadsheetId} from "../../../../base/plus/BubblePlus";
import {STR_ROW_DELETED} from "../../../../base/plus/ConstantsPlus";
import {random} from "../../../../base/plus/StringPlus";
import {FN_NOOP} from "../../../../base/plus/SysPlus";
import {TypeChatFormResultError} from "../../../../base/types/TypesChat";
import {comboToAboutId} from "../../../../base/types/TypesComboId";
import {TypeComboIdChat} from "../../../../base/types/TypesComboId";
import {toComboId} from "../../../../base/types/TypesComboId";
import {selectCacheRow} from "../../../../srvc/app/spreadsheet/SrvcSpreadsheet";
import {Srvc} from "../../../../srvc/Srvc";
import {textUserOrYou} from "../../../../Store";
import {RootState} from "../../../../Store";
import {store} from "../../../../Store";
import {selectCallerEnt} from "../../../app/callerEnt/SrvcCacheCallerEnt";
import {selectCallerEntForm} from "../../../app/callerEnt/SrvcCacheCallerEnt";
import {removeLastMessage} from "./SliceCacheHomeDrawerMsgLast";
import {setLastMessage} from "./SliceCacheHomeDrawerMsgLast";
import {ILastMessage} from "./TypesCacheHomeDrawerMsgLast";

export default class SrvcCacheHomeDrawerMsgLast extends ISrvc
{
  private readonly subscriberId = "SrvcCacheHomeDrawerMsgLast";

  private rowIdToEntChatIdMap = new Map<RowId, TypeComboIdChat>();
  private msgIdToEntChatIdMap = new Map<MessageId, TypeComboIdChat>();

  wsocLastMessageGet(entId: EntId, chatId: ChatId)
  {
    const msg: MsgLastMessageGet = {
      chatId: chatId
    };

    const entChatId = toComboId(entId, chatId) as TypeComboIdChat;
    const lastMessage = store.getState().cache.home.drawer.msgLast.lastMessageMap[entChatId];
    if(lastMessage)
    {
      msg.version = lastMessage.version;
    }

    WsocDrawer.lastMessageGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        this.acceptLastMessage(entChatId, sig);
      }
      else
      {
        if(isNotFoundSignal(envSig))
        {
          this.removeLastMessage(entId, chatId);
        }
      }
    });
  }

  unsubscribe(entId: EntId, chatId: ChatId)
  {
    const rootState = store.getState();
    const entChatId = toComboId(entId, chatId);
    const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
    if(lastMessage)
    {
      const senderId = lastMessage.senderId;
      if(senderId)
      {
        Srvc.app.pubsub.user.userAvatar(this.getSubscriberId(lastMessage.messageId), entId, senderId, true);
      }
      this.removeLastMessage(entId, chatId);
    }
  }

  handleSigTopicMessageProps(sig: SigTopicMessageProps)
  {
    const entId = sig.artifactId as EntId;
    const chatId = sig.aboutId as ChatId;
    const msgId = sig.messageId as MessageId;
    if(isRowId(chatId))
    {
      return;
    }
    const rootState = store.getState();
    const entChatId = toComboId(entId, chatId);
    const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
    if(lastMessage?.messageId === msgId)
    {
      this.wsocLastMessageGet(entId, chatId);
    }
  }

  handleSigTopicMessage(sig: SigTopic)
  {
    const entId = sig.artifactId as EntId;
    const msgId = sig.aboutId as MessageId;

    const entChatId = this.msgIdToEntChatIdMap.get(msgId);
    const chatId = entChatId ? comboToAboutId(entChatId) : undefined;
    if(chatId && !isRowId(chatId))
    {
      this.wsocLastMessageGet(entId, chatId);
    }
  }

  handleFormResult(entId: EntId,
    sig?: SigSpreadsheetRow,
    error?: TypeChatFormResultError): void
  {
    if(error?.removed)
    {
      const entChatId = this.rowIdToEntChatIdMap.get(error.rowId);
      if(entChatId)
      {
        const rootState = store.getState();
        const lastMsg = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
        if(lastMsg)
        {
          this.setLastMessage(this.convertToRowDeleted(lastMsg));
        }
      }
    }
    else if(sig?.formValue?.rowId)
    {
      const entChatId = this.rowIdToEntChatIdMap.get(sig.formValue.rowId);
      if(entChatId)
      {
        const rootState = store.getState();
        const lastMsg = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
        if(lastMsg)
        {
          this.setLastMessage(lastMsg);
        }
      }
    }
  }

  convertMsgPayloadToText(entId: EntId, dto: DtoMessagePayload, rootState?: RootState): string | undefined
  {
    const getEntUserName = (user: DtoGroupMemberKey) => this.calcLastMsgSummaryUser(entId, user, rootState);
    switch(dto.messageType)
    {
      case "audio":
        return (dto as DtoMessagePayloadAudio).text;
      case "camera":
        return "Image";
      case "document":
        return (dto as DtoMessagePayloadDocument).fileName;
      case "spreadsheetRow":
        const payloadSsRow = dto as DtoMessagePayloadSpreadsheetRow;
        return this.calcLastMsgSummarySsRow(entId, payloadSsRow.formId, payloadSsRow, rootState);
      case "spreadsheetPartition":
        return "Spreadsheet";
      case "spreadsheetRowDeleted":
        return STR_ROW_DELETED;
      case "group":
        return "Group";
      case "groupAboutChange":
        const groupAboutChangeDto = dto as DtoMessagePayloadGroupAboutChange;
        return `${getEntUserName(groupAboutChangeDto.initiatorMember)} changed the group description`;
      case "groupAvatarChange":
        const groupAvatarChangeDto = dto as DtoMessagePayloadGroupAvatarChange;
        return `${getEntUserName(groupAvatarChangeDto.initiatorMember)} changed the group's avatar`;
      case "groupCreate":
        const groupCreateDto = dto as DtoMessagePayloadGroupCreate;
        return `${getEntUserName(groupCreateDto.initiatorMember)} created this group`;
      case "groupExit":
        const groupExitDto = dto as DtoMessagePayloadGroupExit;
        return `${getEntUserName(groupExitDto.initiatorMember)} left this group`;
      case "groupJoinWithInvite":
        const groupJoinWithInviteDto = dto as DtoMessagePayloadGroupJoinWithInvite;
        return `${getEntUserName(groupJoinWithInviteDto.initiatorMember)} joined using this group's invite link`;
      case "groupMemberAdd":
        const groupMemberAddDto = dto as DtoMessagePayloadGroupMemberAdd;
        return `${getEntUserName(groupMemberAddDto.initiatorMember)} added ${getEntUserName(groupMemberAddDto.targetMember)}`;
      case "groupMemberRemove":
        const groupMemberRemoveDto = dto as DtoMessagePayloadGroupMemberRemove;
        return `${getEntUserName(groupMemberRemoveDto.initiatorMember)} added ${getEntUserName(groupMemberRemoveDto.targetMember)}`;
      case "groupNameChange":
        const groupNameChange = dto as DtoMessagePayloadGroupNameChange;
        return `${getEntUserName(groupNameChange.initiatorMember)} changed the name to "${groupNameChange.newSubject}"`;
      case "image":
        return "Image";
      case "linkText":
        return (dto as DtoMessagePayloadLinkText).text.substring(0, 100);
      case "location":
        return (dto as DtoMessagePayloadLocation).text;
      case "messageDeleted":
        return "Deleted";
      case "report":
        return (dto as DtoMessagePayloadReport).reportName ?? (dto as DtoMessagePayloadReport).reportLabel ?? "";
      case "text":
        return (dto as DtoMessagePayloadText).text.substring(0, 100);
      case "user":
        return (dto as DtoMessagePayloadUser).nickName;
      case "video":
        return (dto as DtoMessagePayloadVideo).fileName;
      case "voice":
        return (dto as DtoMessagePayloadVoice).text;

      default:
        return undefined;
    }
  }

  private acceptLastMessage(entChatId: TypeComboIdChat, sig: SigLastMessage): void
  {
    const prevSig = store.getState().cache.home.drawer.msgLast.lastMessageMap[entChatId] as ILastMessage | undefined;

    const prevSenderId = prevSig ? prevSig.senderId : undefined;
    const senderId = sig.senderId;
    const prevMsgId = prevSig?.messageId;
    if(prevSenderId !== senderId)
    {
      if(prevSenderId && prevMsgId)
      {
        Srvc.app.pubsub.user.userAvatar(this.getSubscriberId(prevMsgId), sig.entId, prevSenderId, true);
      }

      if(senderId)
      {
        Srvc.app.pubsub.user.userAvatar(this.getSubscriberId(sig.messageId), sig.entId, senderId);
      }
    }
    if(prevMsgId && prevMsgId !== sig.messageId)
    {
      Srvc.app.pubsub.msg.message(this.getSubscriberId(prevMsgId), sig.entId, prevMsgId, true);
      // this.ensureLastMsgSSRow(this.getSubscriberId(prevMsgId), sig.entId, sig.chatId, prevSig, true);
    }
    if(!prevMsgId || prevMsgId !== sig.messageId)
    {
      Srvc.app.pubsub.msg.message(this.getSubscriberId(sig.messageId), sig.entId, sig.messageId);
      // this.ensureLastMsgSSRow(this.getSubscriberId(sig.messageId), sig.entId, sig.chatId, sig);
    }

    // if(!(sig.messagePayload.messageType === "spreadsheetRow"
    //   || sig.messagePayload.messageType === "spreadsheetPartition"))
    // {
    this.setLastMessage(sig);
    // }

    if(!sig.receiptStatus)
    {
      if(!prevSig || prevSig.messageId !== sig.messageId)
      {
        // mark message as received
        this.wsocMessageMarkReceived(sig.entId, sig.chatId, sig.messageOffset);
      }
    }
  }

  private setLastMessage(sigLastMsg: ILastMessage)
  {
    const entChatId = toComboId(sigLastMsg.entId, sigLastMsg.chatId);
    this.msgIdToEntChatIdMap.set(sigLastMsg.messageId, entChatId);
    store.dispatch(setLastMessage({
      ...sigLastMsg,
      localVersion: random()
    } as ILastMessage));
  }

  private removeLastMessage(entId: EntId, chatId: ChatId)
  {
    const entChatId = toComboId(entId, chatId);
    const lastMessage = store.getState().cache.home.drawer.msgLast.lastMessageMap[entChatId];
    const messageId = lastMessage?.messageId;
    if(lastMessage && messageId)
    {
      this.msgIdToEntChatIdMap.delete(messageId);
      const rowId = getMsgPayloadRowId(lastMessage.messagePayload);
      if(rowId)
      {
        this.rowIdToEntChatIdMap.delete(rowId);
      }
    }
    store.dispatch(removeLastMessage({
      entId,
      chatId
    }));
  }

  private wsocMessageMarkReceived(entId: EntId, chatId: ChatId, messageOffset: number)
  {
    const msg = {
      chatId: chatId,
      offset: messageOffset
    } as MsgOffset;

    WsocMain.messageMarkReceived(entId, msg, FN_NOOP);
  }

  private getSubscriberId(messageId: MessageId)
  {
    return this.subscriberId + "#" + messageId;
  }

  private ensureLastMsgSSRow(subscriberId: string,
    entId: EntId,
    chatId: ChatId,
    sig: ILastMessage,
    unsubscribe?: boolean)
  {
    const lastMsg = sig;
    if(lastMsg?.messagePayload.messageType === "spreadsheetRow"
      || lastMsg?.messagePayload.messageType === "spreadsheetPartition")
    {
      const spreadsheetId = getMsgPayloadSpreadsheetId(lastMsg.messagePayload);
      const rowId = getMsgPayloadRowId(lastMsg.messagePayload);
      if(spreadsheetId && rowId)
      {
        Srvc.cache.app.spreadsheet.ssRow.setRowIdToSsIdMap(entId, rowId, spreadsheetId);
        if(!unsubscribe)
        {
          this.rowIdToEntChatIdMap.set(rowId, toComboId(entId, chatId));
          Srvc.cache.app.spreadsheet.ssRow.setCacheSpreadsheetRow(entId,
            rowId,
            () => this.setLastMessage(lastMsg),
            (error) =>
            {
              if(isNotFoundSignal(error))
              {
                this.setLastMessage(this.convertToRowDeleted(lastMsg));
              }
            }
          );
        }

        Srvc.app.pubsub.msg.formResult(subscriberId, entId, rowId, unsubscribe);
      }
      else if(lastMsg.messagePayload.messageType === "spreadsheetPartition")
      {
        this.setLastMessage(lastMsg);
      }
    }
  }

  private convertToRowDeleted(lastMsg: ILastMessage)
  {
    return {
      ...lastMsg,
      messagePayload: {
        messageType: "spreadsheetRowDeleted"
      }
    } as ILastMessage;
  }

  private calcLastMsgSummaryUser(entId: EntId, user: DtoGroupMemberKey, rootState?: RootState)
  {
    if(rootState)
    {
      const comboId = toComboId(entId, user.entUserId);
      const entUser = rootState.cache.app.user.userAvatarMap[comboId];
      if(entUser)
      {
        return textUserOrYou(rootState, entUser);
      }
      else
      {
        Srvc.cache.app.user.wsocUserAvatarGet(entId, user.entUserId);
      }
    }
    return user.name;
  }

  private calcLastMsgSummarySsRow(entId: EntId,
    formId: MetaIdForm,
    payload?: DtoMessagePayloadSpreadsheetRow,
    rootState?: RootState)
  {
    if(rootState)
    {
      const form = selectCallerEntForm(rootState, entId, formId);

      if(payload?.rowId && form?.chatLabelPatternVar)
      {
        const row = selectCacheRow(rootState, entId, payload.rowId);
        const callerEnt = selectCallerEnt(rootState, entId);
        if(row)
        {
          const chatLabelPatternVar = resolveChatLabelPatternVar(callerEnt
            ? resolveForm(callerEnt, form, row.formValue)
            : form, callerEnt, row?.formValue);

          return defnDtoTextToString(chatLabelPatternVar) || form?.name;
        }
      }
      return form?.name;
    }
    return "Form";
  }

}
