import {Link} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEmail} from "../../../../api/meta/base/dto/DefnFieldEmail";
import {FieldValueEmail} from "../../../../api/meta/base/dto/FieldValueEmail";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import IconStrip from "../../../atom/icon/IconStrip";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldEmail(props: {
  defn: DefnFieldEmail,
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as FieldValueEmail | undefined;

        const emailValue = getFormFieldValueAsTextWithPrefixSuffix(defn, fieldValue);

        const reportNode = <Link
          href={`mailto:${emailValue}`}
          component={"a"}
          underline={"none"}
          variant={"caption"}
          sx={{
            color: "textSecondary"
          }}
        >
          {emailValue}
        </Link>;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            reportNode={reportNode}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawTextField
                {...props}
                value={fieldValue}
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                error={error}
                endAdornment={<IconStrip value={"email"} />}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
