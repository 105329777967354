import {ArtifactId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {SelectList} from "../../../base/plus/ListPlus";

export default class SrvcAdminPanelDrawerSearch extends ISrvc
{
  constructor(readonly selectList: SelectList)
  {
    super();
  }

  rpcSearch(query: string, searchId: string, artifactIdSet: ArtifactId[])
  {

  }
}
