import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldDateRange} from "../../../../api/meta/base/dto/DefnFieldDateRange";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueDateRange} from "../../../../api/meta/base/dto/FieldValueDateRange";
import {getLocalDateFormat} from "../../../../base/plus/DatePlus";
import {extractDateFormat} from "../../../../base/plus/DatePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawDateRange from "../raw/FieldRawDateRange";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldDateRange(props: {
  defn: DefnFieldDateRange
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon,
    getFieldMinDate,
    getFieldMaxDate
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const dateFormat = extractDateFormat(getLocalDateFormat());
  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const icon = getFieldIcon();
  const placeholder = getFieldPlaceHolder();
  const minDate = getFieldMinDate();
  const maxDate = getFieldMaxDate();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;

        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueDateRange;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawDateRange
                defn={defn}
                isError={isError}
                onBlur={field.onBlur}
                onClick={onClick}
                defnTheme={defnTheme}
                fieldValue={fieldValue}
                onChange={field.onChange}
                views={["year", "month", "day"]}
                inputFormat={dateFormat}
                autoFocus={Boolean(defn.autoFocus)}
                readOnly={readOnly}
                disabled={disabled}
                formCtx={formCtx}
                fieldId={fieldId}
                required={required}
                placeholder={placeholder}
                icon={icon}
                minDate={minDate}
                maxDate={maxDate}
              />
              {
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message || helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}


