import {EntId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {MsgNeoQLResult} from "../../../api/studio/studioMain/msg/MsgNeoQLResult";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import ISrvc from "../../../base/ISrvc";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import {setEntQueryCurrentQuery} from "../SliceCacheTools";
import {setEntQueryResult} from "../SliceCacheTools";

export default class SrvcCacheToolsQuery extends ISrvc
{
  rpcEntNeoQLResultGet(entId: EntId, msg: MsgNeoQLResult, cbSuccess?: CbSuccess)
  {
    RpcStudioMain.neoQLResultGet(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;
      store.dispatch(setEntQueryResult({
        entId: entId,
        logTree: sig?.formValueLogTree,
        defnOutputForm: sig?.defnForm,
        outputFormValue: sig?.formValue
      }));

      store.dispatch(setEntQueryCurrentQuery({
        entId: entId,
        currentQuery: msg.neoQL,
        saveToCache: true
      }));
      cbSuccess && cbSuccess();
    });
  }
}
