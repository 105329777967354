import ISrvc from "../../base/ISrvc";
import {store} from "../../Store";
import SrvcCacheAdminPanelAdmins from "./admins/SrvcCacheAdminPanelAdmins";
import SrvcCacheAdminPanelManageSettings from "./manage/SrvcCacheAdminPanelManageSettings";
import {setSort} from "./SliceCacheAdminPanel";
import {setCurrentTab} from "./SliceCacheAdminPanel";
import {ActionSortAdminPanelList} from "./TypeCacheAdminPanel";
import {ActionSetAdminPanelCurrentTab} from "./TypeCacheAdminPanel";
import SrvcCacheAdminPanelUsers from "./users/SrvcCacheAdminPanelUsers";

export default class SrvcCacheAdminPanel extends ISrvc
{
  public readonly users = new SrvcCacheAdminPanelUsers();
  public readonly admins = new SrvcCacheAdminPanelAdmins();
  public readonly settings = new SrvcCacheAdminPanelManageSettings();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.users,
      this.admins,
      this.settings
    );
  }

  setCurrentTab(payload: ActionSetAdminPanelCurrentTab)
  {
    store.dispatch(setCurrentTab(payload));
  }

  setSort(payload: ActionSortAdminPanelList)
  {
    store.dispatch(setSort({
      artifactId: payload.artifactId,
      tab: payload.tab,
      sortType: payload.sortType
    }));
  }
}
