import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {isString} from "lodash";
import {useCallback} from "react";
import {useEffect} from "react";
import React from "react";
import {useMemo} from "react";
import {useState} from "react";
import {DtoFieldFilter} from "../../api/ent/base/dto/DtoFieldFilter";
import {SpreadsheetFilterValue} from "../../api/ent/base/dto/SpreadsheetFilterValue";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnLayoutCard} from "../../api/meta/base/dto/DefnLayoutCard";
import {DefnLayoutGrid} from "../../api/meta/base/dto/DefnLayoutGrid";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {EnumDefnLayoutCardFilterKind} from "../../api/meta/base/Types";
import {IMenuProps} from "../atom/raw/RawMenu";
import {usePageCtx} from "../ctx/CtxPage";
import {getCompLabel} from "../form/viewer/base/FormViewerPlus";
import {useSsBrCtx} from "./base/CtxSsBr";
import CtxSsBrProvider from "./base/CtxSsBr";
import {IActionSSBrPayloadSearchState} from "./base/ICtxSsBr";
import {IActionSSBrPayloadFilterState} from "./base/ICtxSsBr";
import {ISsBrFooterPayload} from "./base/ICtxSsBr";
import useSsBrRefSetter from "./base/SsBtRefSetter";
import SsBrFilters from "./SsBrFilters";
import SsBrFooter from "./SsBrFooter";
import SsBrMain from "./SsBrMain";
import SsBrSearch from "./SsBrSearch";
import TemplateSsBr from "./TemplateSsBr";

export interface ISsBrRef
{
  setFilters: (filters: DtoFieldFilter[]) => void,
  setFooterPayload: (payload: ISsBrFooterPayload) => void,
  setFilterState: (payload: IActionSSBrPayloadFilterState) => void
  setSearchState: (payload: IActionSSBrPayloadSearchState) => void
}

interface ISpreadSheetBrProps
{
  filters?: DtoFieldFilter[],
  showFilters?: boolean,
  spreadSheetBrContent: React.ReactNode
  cbRef?: ISsBrRef,
  defnFormRef: DefnForm,
  filterViewType?: EnumDefnLayoutCardFilterKind,
  showSearchBar?: boolean
  hideFooter: boolean,
  // layout
  currentLayout?: DefnLayoutGrid,
  allowToSwitchLayoutMap?: Record<MetaIdLayoutGrid, DefnLayoutGrid>,
  onClickSubmit?: () => void,
  cbOnClickShowSelected?: (showSelectedItems: boolean) => void,
  onFilterUpdate?: (
    selectedFilters?: SpreadsheetFilterValue[],
    searchStr?: string,
    sortByFieldId?: MetaIdField) => void,
  onSwitchLayout?: (layoutId: MetaIdLayoutGrid) => void
}

export default function SsBr(props: ISpreadSheetBrProps)
{
  const sortByFieldId = (props.currentLayout as DefnLayoutCard | undefined)?.filter?.sortByFieldIdSet?.at(0);

  return (
    <CtxSsBrProvider
      filters={props.filters}
      sortByFieldId={sortByFieldId}
    >
      <RealSsBr {...props} />
    </CtxSsBrProvider>
  );
}

function RealSsBr(props: ISpreadSheetBrProps)
{
  const theme = useTheme();
  const pageCtx = usePageCtx();

  const filterViewType = props.filterViewType || "tree";
  const onFilterUpdate = props.onFilterUpdate;
  const hideFooter = props.hideFooter;
  const allowToSwitchLayoutMap = props.allowToSwitchLayoutMap;
  const currentLayout = props.currentLayout as DefnLayoutCard | undefined;
  const defnFormRef = props.defnFormRef;

  const [firstBoot, setFirstBoot] = useState(true);
  const [searchStr, setSearchStr] = useState<string>();

  const spreadSheetBrCtx = useSsBrCtx();
  const filters = spreadSheetBrCtx.getFilters();

  const sortByFieldId = useMemo(() => spreadSheetBrCtx.getSortByFieldId(), [spreadSheetBrCtx]);
  const selectedFilters = useMemo(() => spreadSheetBrCtx.getSelectedFilters(), [spreadSheetBrCtx]);

  const onClickSortByField = useCallback((menuAnchor: Element) =>
  {
    if(!currentLayout?.filter?.sortByFieldIdSet?.length)
    {
      return;
    }

    const menuProps = {} as IMenuProps;
    currentLayout?.filter?.sortByFieldIdSet?.forEach(sortByField =>
    {
      const field = (defnFormRef.compMap[sortByField] as DefnField | undefined);
      if(field)
      {
        menuProps[sortByField] = {
          label: getCompLabel(field),
          selected: sortByFieldId === sortByField,
          onClick: () =>
          {
            const _sortByFieldId = sortByField === sortByFieldId ? undefined : sortByField;
            props.onFilterUpdate && props.onFilterUpdate(
              selectedFilters?.length ? selectedFilters : undefined,
              searchStr || undefined,
              _sortByFieldId
            );
            spreadSheetBrCtx.setSortByFieldId(_sortByFieldId
              ? {sortByFieldId: _sortByFieldId}
              : undefined);
          }
        };
      }
    });

    pageCtx.showMenu(menuAnchor, menuProps);
  }, [currentLayout?.filter?.sortByFieldIdSet, defnFormRef, searchStr, selectedFilters, sortByFieldId]);

  useEffect(() =>
  {
    if(!firstBoot && onFilterUpdate && selectedFilters)
    {
      onFilterUpdate(
        selectedFilters.length ? selectedFilters : undefined,
        searchStr || undefined
      );
      spreadSheetBrCtx.setFilterState({state: true});
    }
  }, [selectedFilters]);

  useEffect(() =>
  {
    if(!firstBoot && onFilterUpdate && isString(searchStr))
    {
      onFilterUpdate(
        selectedFilters?.length ? selectedFilters : undefined,
        searchStr || undefined
      );
      spreadSheetBrCtx.setSearchState({search: true});
    }
  }, [searchStr]);

  useEffect(() =>
  {
    if(!firstBoot && onFilterUpdate)
    {
      onFilterUpdate(
        selectedFilters?.length ? selectedFilters : undefined,
        searchStr || undefined,
        sortByFieldId
      );
    }
  }, [sortByFieldId]);

  useEffect(() =>
  {
    setFirstBoot(false);
  }, []);

  useSsBrRefSetter({
    cbRef: props.cbRef
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      zIndex={1}
      width={"100%"}
      height={"inherit"}
      flexGrow={1}
      bgcolor={theme.common.bgcolorSidePane}
    >
      <TemplateSsBr
        search={<SsBrSearch
          allowToSwitchLayoutMap={allowToSwitchLayoutMap}
          onSearch={(str) => setSearchStr(str)}
          showSearchBar={props.showSearchBar}
          onSwitchLayout={props.onSwitchLayout}
          onSortByFieldId={onClickSortByField}
          showSorting={Boolean(currentLayout?.filter?.sortByFieldIdSet?.length)}
        />}
        filters={(filters && filters.length >= 1)
          ? <SsBrFilters
            defnFormRef={props.defnFormRef}
            filterViewType={filterViewType}
            cbOnClickShowSelected={props.cbOnClickShowSelected}
          />
          : undefined}

        main={<SsBrMain
          spreadSheetBrContent={props.spreadSheetBrContent}
          defnFormRef={defnFormRef}
          onFilterUpdate={onFilterUpdate}
        />}

        footer={!hideFooter
          ? <SsBrFooter
            onClickSubmit={props.onClickSubmit}
          />
          : undefined}
      />
    </Box>
  );
}
