import {stringify} from "qs";
import {useEffect} from "react";
import {useState} from "react";
import {useMemo} from "react";
import React from "react";
import {useCallback} from "react";
import {FieldErrors} from "react-hook-form";
import {isFieldId} from "../../../../api/meta/base/ApiPlus";
import {isGridId} from "../../../../api/meta/base/ApiPlus";
import {nextMetaIdMapping} from "../../../../api/meta/base/ApiPlus";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnStudioMapOfMapping} from "../../../../api/meta/base/dto/DefnStudioMapOfMapping";
import {StudioBuildArgBinder} from "../../../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioDtoArgValueField} from "../../../../api/meta/base/dto/StudioDtoArgValueField";
import {StudioDtoMappingField} from "../../../../api/meta/base/dto/StudioDtoMappingField";
import {StudioDtoMappingFieldMapBase} from "../../../../api/meta/base/dto/StudioDtoMappingFieldMapBase";
import {StudioDtoMappingGrid} from "../../../../api/meta/base/dto/StudioDtoMappingGrid";
import {StudioDtoMappingGridMap} from "../../../../api/meta/base/dto/StudioDtoMappingGridMap";
import {StudioEntPluginMap} from "../../../../api/meta/base/dto/StudioEntPluginMap";
import {StudioField} from "../../../../api/meta/base/dto/StudioField";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {EnumDefnFields} from "../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {MetaIdComp} from "../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnResolveArgBinderValue} from "../../../../base/plus/ArgBinderPlus";
import {getCombinedFieldId} from "../../../../base/plus/FormPlus";
import {getListItemHeightAPSA} from "../../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {getSystemFieldTypeSet} from "../../../../base/plus/StudioFormPlus";
import {isSystemField} from "../../../../base/plus/StudioFormPlus";
import {findStudioFormField} from "../../../../base/plus/StudioFormPlus";
import {getStudioFormField} from "../../../../base/plus/StudioFormPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {getEmptyKeysAndMap} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import theme from "../../../../base/plus/ThemePlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {ILinePrimary} from "../../../../base/types/TypesGlobal";
import {ITreeNode} from "../../../../base/types/TypesTree";
import {ITreeRef} from "../../../../base/types/TypesTree";
import {isSystemForm} from "../../../../routes/studio/ent/deploy/plugins/StudioEntDeployPluginPlus";
import {BoxP} from "../../../atom/box/BoxP";
import {BoxShell} from "../../../atom/box/BoxShell";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {usePageCtx} from "../../../ctx/CtxPage";
import ICtxPage from "../../../ctx/ICtxPage";
import {IGridMapping} from "../../../dialog/DialogGridMapping";
import DialogGridMapping from "../../../dialog/DialogGridMapping";
import DialogNewFieldMapping from "../../../dialog/DialogNewFieldMapping";
import {useFormCtx} from "./CtxForm";

interface IUseMappingVarFunctionsProps
{
  fromFormId?: MetaIdForm;
  fromGridId?: MetaIdGrid;
  fromPluginId?: MetaIdPlugin;
  toFormId: MetaIdForm;
  toGridId?: MetaIdGrid;
  toPluginId?: MetaIdPlugin;
}

export interface INodeUserFieldMappingVar
{
  studioDtoMappingComposite?: StudioDtoMappingGrid;
  studioDtoMappingField?: StudioDtoMappingField;
  type?: "composite" | "field";
}

export interface IMappingVarFunctions
{
  isStudioForm: boolean;
  isDefnForm: boolean;
  form: StudioForm | DefnForm | undefined;
  grid: StudioGrid | DefnGrid | undefined;
  getField: (fieldId: MetaIdField, parentCompId?: MetaIdComp | MetaIdComposite) => StudioField | DefnField | undefined;
  getCompositeName: (compId: MetaIdComp | MetaIdComposite) => string | undefined;
  getFieldName: (fieldId: MetaIdField, parentCompId?: MetaIdComp | MetaIdComposite) => string | undefined;
  pluginId?: MetaIdPlugin;
}

const INVALID_MAPPING = "Invalid mapping";
export const fieldMappingFromKey = "from";
export const fieldMappingToKey = "to";

export function getIODefnFormsFromPluginApi(
  importMap: StudioEntPluginMap,
  pluginId?: MetaIdPlugin): DefnForm[] | undefined
{
  if(!pluginId)
  {
    return undefined;
  }

  const formList = [] as DefnForm[];
  if(pluginId)
  {
    const plugin = importMap?.map[pluginId];
    if(plugin)
    {
      const pluginFormMap = plugin.pluginFormMap;
      formList.push(...Object.values(pluginFormMap));
    }
  }

  return formList;
}

export function useMappingVarFunctions(
  type: "from" | "to",
  defn: IUseMappingVarFunctionsProps,
  formStore?: FormStore): IMappingVarFunctions
{
  const importMap = formStore?.pluginMap;
  const formId = type === "from" ? defn.fromFormId : defn.toFormId;
  const gridId = type === "from" ? defn.fromGridId : defn.toGridId;
  const pluginId = type === "from" ? defn.fromPluginId : defn.toPluginId;
  const isStudioForm = !pluginId;
  const isDefnForm = !isStudioForm;
  const [defnForm, setDefnForm] = useState<DefnForm>();
  const formMap = useMemo(() => formStore?.formMap?.map, [formStore?.formMap?.map]);
  const sysFormMap = useMemo(() => formStore?.sysFormMap?.map, [formStore?.sysFormMap?.map]);

  const studioForm = useMemo(() =>
  {
    if(!formId || !isStudioForm || !formMap)
    {
      return undefined;
    }

    if(isSystemForm(formId) && sysFormMap)
    {
      return sysFormMap[formId];
    }

    return formMap[formId] as StudioForm;

  }, [formMap, formId, isStudioForm, sysFormMap]);

  const getComposite = useCallback((gridId: MetaIdField): StudioComposite | DefnComp | undefined =>
  {
    if(isStudioForm && studioForm)
    {
      return studioForm.compositeMap.map[gridId] as StudioComposite;
    }
    else if(isDefnForm && defnForm)
    {
      return defnForm.compMap[gridId] as DefnComp;
    }
  }, [isStudioForm, studioForm, isDefnForm, defnForm]);

  const grid = useMemo(() =>
  {
    if(!formId || !gridId)
    {
      return undefined;
    }
    if(!isGridId(gridId))
    {
      return undefined;
    }

    return getComposite(gridId) as StudioGrid | DefnGrid | undefined;
  }, [getComposite, gridId]);

  const getField = useCallback((
    fieldId: MetaIdField,
    parentCompId?: MetaIdComp | MetaIdComposite): StudioField | DefnField | undefined =>
  {
    if(isSystemField(fieldId))
    {
      return {
        metaId: fieldId,
        name: fieldId,
        details: {
          name: fieldId,
          label: fieldId
        },
        type: getSystemFieldTypeSet(fieldId as EnumDefnFields)[0]
      } as DefnField | StudioField;
    }
    if(!isFieldId(fieldId))
    {
      return undefined;
    }
    if(isStudioForm && studioForm)
    {
      return parentCompId
        ? getStudioFormField(studioForm, fieldId, parentCompId)
        : findStudioFormField(studioForm, fieldId);
    }
    else if(isDefnForm && defnForm)
    {
      return defnForm.compMap[fieldId] as DefnField;
    }
  }, [isStudioForm, studioForm, isDefnForm, defnForm]);

  const getCompositeName = useCallback((compId: MetaIdComp | MetaIdComposite) =>
  {
    const comp = getComposite(compId);
    if(comp?.type === "grid")
    {
      if(isStudioForm)
      {
        return (comp as StudioComposite)?.details.name;
      }
      else if(isDefnForm)
      {
        return (comp as DefnComp)?.name;
      }
    }
  }, [getComposite, isStudioForm, isDefnForm]);

  const getFieldName = useCallback((fieldId: MetaIdField, parentCompId?: MetaIdComp | MetaIdComposite) =>
  {
    const field = getField(fieldId, parentCompId);

    if(isStudioForm)
    {
      return (field as StudioField)?.details.name;
    }
    else
    {
      return (field as DefnField)?.name;
    }

  }, [getField, isStudioForm]);

  useEffect(() =>
  {
    if(!(importMap && isDefnForm))
    {
      setDefnForm(undefined);
      return;
    }
    const defnForms = getIODefnFormsFromPluginApi(importMap, pluginId);
    if(defnForms)
    {
      setDefnForm(defnForms.find(form => form.metaId === formId));
    }

  }, [pluginId, formId, importMap, isDefnForm]);

  return {
    isStudioForm: isStudioForm,
    isDefnForm: isDefnForm,
    form: studioForm || defnForm,
    grid: grid,
    getField: getField,
    getCompositeName: getCompositeName,
    getFieldName: getFieldName,
    pluginId: pluginId
  };
}

export function useCompositesMappingFunctions(props: {
  defn: DefnStudioMapOfMapping,
  cbRefTree: ITreeRef,
  getMappingVarFunctions: (type: "from" | "to") => IMappingVarFunctions,
  isFormReadOnly?: boolean
})
{
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();

  const defn = props.defn;
  const cbRefTree = props.cbRefTree;
  const isFormReadOnly = props.isFormReadOnly;
  const mappingVarFunctions = props.getMappingVarFunctions;

  const formStore = formCtx.getStore();
  const fromFormId = defn.fromFormId;
  const toFormId = defn.toFormId;
  const fromPluginId = defn.fromPluginId;
  const toPluginId = defn.toPluginId;

  const {isDefnForm: isFromDefnForm} = mappingVarFunctions("from");
  const {isDefnForm: isToDefnForm} = mappingVarFunctions("to");

  const addMappingComp = useCallback(() =>
  {
    formStore && pageCtx.showDialog(
      <DialogGridMapping
        formStore={formStore}
        fromFormId={fromFormId}
        toFormId={toFormId}
        fromPluginId={fromPluginId}
        toPluginId={toPluginId}
        onClickOk={(values) =>
        {
          const dto = {
            fromGridId: values.fromGridId,
            toGridId: values.toGridId,
            fieldMappingMap: getEmptyKeysAndMap(),
            metaId: nextMetaIdMapping(),
            fromGridKey: values.fromGridKey,
            toGridKey: values.toGridKey,
            insertVariant: values.insertVariant,
            updateVariant: values.updateVariant,
            removeVariant: values.removeVariant,
            emptyFieldVariant: values.emptyFieldVariant
          } as StudioDtoMappingGrid;

          cbRefTree.nodeAdd(createNodeCompositeMapping(dto));
        }}
        readOnly={isFormReadOnly}
      />
    );
  }, [formStore, pageCtx, fromFormId, toFormId, cbRefTree, isFromDefnForm, isToDefnForm]);

  const editMappingComp = useCallback((node: ITreeNode) =>
  {
    const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
    const studioDtoMappingComposite = userField?.studioDtoMappingComposite;
    const fromGridId = studioDtoMappingComposite?.fromGridId;
    const toGridId = studioDtoMappingComposite?.toGridId;

    const values = {
      fromGridId: fromGridId,
      toGridId: toGridId,
      fromGridKey: studioDtoMappingComposite?.fromGridKey,
      toGridKey: studioDtoMappingComposite?.toGridKey,
      insertVariant: studioDtoMappingComposite?.insertVariant,
      updateVariant: studioDtoMappingComposite?.updateVariant,
      removeVariant: studioDtoMappingComposite?.removeVariant,
      emptyFieldVariant: studioDtoMappingComposite?.emptyFieldVariant
    } as IGridMapping;

    formStore && pageCtx.showDialog(
      <DialogGridMapping
        formStore={formStore}
        fromFormId={fromFormId}
        toFormId={toFormId}
        fromPluginId={fromPluginId}
        toPluginId={toPluginId}
        values={(fromGridId || toGridId)
          ? values
          : undefined}
        onClickOk={(values) =>
        {
          const dto = {
            ...studioDtoMappingComposite,
            fromGridId: values.fromGridId,
            toGridId: values.toGridId,
            fromGridKey: values.fromGridKey,
            toGridKey: values.toGridKey,
            insertVariant: values.insertVariant,
            updateVariant: values.updateVariant,
            removeVariant: values.removeVariant,
            emptyFieldVariant: values.emptyFieldVariant
          } as StudioDtoMappingGrid;

          const newNode = createNodeCompositeMapping(dto);
          cbRefTree.nodeUpdate(newNode, node);
        }}
        readOnly={isFormReadOnly}
      />
    );

  }, [formStore, pageCtx, fromFormId, toFormId, cbRefTree, isFromDefnForm, isToDefnForm]);

  const pasteMappingComp = useCallback(() =>
  {
    navigator.clipboard.readText().then((str) =>
    {

      const node = JSON.parse(str);
      const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
      const studioDtoMappingComposite = userField?.studioDtoMappingComposite;

      cbRefTree.nodeAdd(createNodeCompositeMapping(studioDtoMappingComposite));

    }).catch(() =>
    {
      pageCtx.showErrorToast(INVALID_MAPPING);
    });
  }, []);

  return {
    addMappingComp,
    editMappingComp,
    pasteMappingComp
  };
}

export function useFieldsMappingFunctions(props: {
  defn: DefnStudioMapOfMapping,
  cbRefTree: ITreeRef,
  getMappingVarFunctions: (type: "from" | "to") => IMappingVarFunctions,
  isFormReadOnly?: boolean
})
{
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();

  const defn = props.defn;
  const cbRefTree = props.cbRefTree;
  const mappingVarFunctions = props.getMappingVarFunctions;
  const isFormReadOnly = props.isFormReadOnly;

  const formStore = formCtx.getStore();
  const fromFormId = defn.fromFormId;
  const toFormId = defn.toFormId;
  const fromPluginId = defn.fromPluginId;
  const toPluginId = defn.toPluginId;

  const setMappingField = useCallback((
    values?: StudioDtoMappingFieldMapBase,
    node?: ITreeNode,
    fromCompositeId?: MetaIdField,
    toCompositeId?: MetaIdField) =>
  {
    const userField = node?.userField && node.userField["sig"] as INodeUserFieldMappingVar;
    const parentDto = userField?.studioDtoMappingComposite;

    if(formStore && toFormId)
    {
      pageCtx.showDialog(
        <DialogNewFieldMapping
          formStore={formStore}
          fromFormId={fromFormId}
          toFormId={toFormId}
          fromCompositeId={fromCompositeId}
          values={values}
          toCompositeId={toCompositeId}
          fromPluginId={fromPluginId}
          toPluginId={toPluginId}
          onClickOk={(values) =>
          {
            const children = [] as ITreeNode[];
            values.keys.forEach(value =>
            {
              const form = values.map[value].from;
              const to = values.map[value].to;

              const dto = {
                from: form,
                to: to,
                primary: false,
                metaId: value
              } as StudioDtoMappingField;

              const newChildNode = createNodeFieldMapping(dto, parentDto);
              if(newChildNode)
              {
                children.push(newChildNode);
              }
            });
            if(node)
            {
              const newNode = {
                ...node,
                children: children
              } as ITreeNode;

              if(fromCompositeId && toCompositeId)
              {
                cbRefTree.nodeUpdate(newNode, node);
              }
              else
              {
                cbRefTree.setValue(children);
              }
            }
            else
            {
              cbRefTree.setValue(children);
            }
          }}
          readOnly={isFormReadOnly}
        />
      );
    }
  }, [formStore, fromFormId, toFormId, pageCtx, cbRefTree, mappingVarFunctions]);

  const pasteMappingField = useCallback((parentNode?: ITreeNode) =>
  {
    navigator.clipboard.readText()
    .then(str =>
    {
      const node = JSON.parse(str) as ITreeNode;

      const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
      const studioDtoMappingComposite = userField?.studioDtoMappingComposite;
      const studioDtoMappingField = userField?.studioDtoMappingField;

      if(studioDtoMappingField)
      {
        const newChildNode = createNodeFieldMapping(studioDtoMappingField, studioDtoMappingComposite);
        newChildNode && cbRefTree.nodeAdd(newChildNode, parentNode);
      }

    })
    .catch(err =>
    {
      pageCtx.showErrorToast(INVALID_MAPPING);
    });

  }, [cbRefTree.nodeAdd]);

  return {
    setMappingField,
    pasteMappingField
  };
}

function getNodeId(from?: StudioBuildArgBinder | MetaIdField, to?: MetaIdField)
{
  if(typeof from === "string")
  {
    return getCombinedFieldId([from || "", to || ""]);
  }
  else
  {
    let filteredFrom = from
      ? stringify(from, {
        sort: (a, b) => a.localeCompare(b)
      })
      : "";

    return getCombinedFieldId([filteredFrom, to || ""]);
  }
}

export function createNodeCompositeMapping(
  dto: StudioDtoMappingGrid): ITreeNode
{
  const fromId = dto.fromGridId;
  const toId = dto.toGridId;
  const nodeId = getNodeId(fromId, toId);
  return {
    id: nodeId,
    name: dto.metaId,
    userField: {
      sig: {
        studioDtoMappingComposite: dto,
        type: "composite"
      } as INodeUserFieldMappingVar
    },
    children: convertFieldMapToRootNodes(dto.fieldMappingMap, dto)

  };
}

export function createNodeFieldMapping(
  dto: StudioDtoMappingField,
  parentDto?: StudioDtoMappingGrid): ITreeNode | undefined
{
  const nodeId = dto.metaId;

  return {
    id: nodeId,
    name: dto.metaId,
    bgcolor: dto.primary ? theme.common.bgcolorMappingIsPrimary : undefined,
    userField: {
      sig: {
        studioDtoMappingComposite: parentDto,
        studioDtoMappingField: dto,
        type: "field"
      } as INodeUserFieldMappingVar
    },
    children: []
  };
}

export function convertRootNodesToMapping(rootNodes: ITreeNode[]): StudioDtoMappingGridMap
{
  const _mapping = getEmptyKeysAndMap() as StudioDtoMappingGridMap;
  rootNodes.forEach(node =>
  {
    const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
    if(userField)
    {
      if(userField.type === "composite")
      {
        const studioDtoMappingComposite = {
          ...userField.studioDtoMappingComposite
        } as StudioDtoMappingGrid;

        if(node.children)
        {
          studioDtoMappingComposite.fieldMappingMap = convertRootNodesField(node.children);
        }
        if(userField.studioDtoMappingComposite?.metaId)
        {
          _mapping.map[userField.studioDtoMappingComposite?.metaId] = studioDtoMappingComposite;
          _mapping.keys?.push(userField.studioDtoMappingComposite?.metaId);
        }
      }
    }

  });
  return _mapping;
}

export function convertRootNodesField(rootNodes: ITreeNode[])
{
  const fieldMap = getEmptyKeysAndMap() as StudioDtoMappingFieldMapBase;
  rootNodes.forEach(node =>
  {
    const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
    if(userField)
    {
      if(userField.type === "field")
      {
        const studioDtoMappingField = userField.studioDtoMappingField;
        if(studioDtoMappingField)
        {
          const mappingId = studioDtoMappingField.metaId;
          fieldMap.keys.push(mappingId);
          fieldMap.map[mappingId] = studioDtoMappingField;
        }
      }
    }

  });

  return fieldMap;
}

export function convertMappingToRootNodes(compositeMap?: StudioDtoMappingGridMap, errors?: FieldErrors): ITreeNode[]
{
  const rootNodes = [] as ITreeNode[];
  if(compositeMap)
  {
    insertNodesSectionAndGrid(compositeMap, rootNodes, errors);
  }
  return rootNodes;
}

function insertNodesSectionAndGrid(
  compositeMap: StudioDtoMappingGridMap,
  rootNodes: ITreeNode[],
  errors?: FieldErrors)
{
  compositeMap?.keys?.forEach(key =>
  {
    const dto = compositeMap?.map[key];
    const fieldMap = dto?.fieldMappingMap;
    if(dto)
    {
      const node = createNodeCompositeMapping(dto);
      node.infoSpot = errors?.[dto.metaId] ? "error" : undefined;

      const isExist = rootNodes.find(x => x.id === node.id);
      if(!isExist)
      {
        node.children = convertFieldMapToRootNodes(fieldMap, dto, errors);
        rootNodes.push(node);
      }
    }
  });
}

export function convertFieldMapToRootNodes(
  fieldMap?: StudioDtoMappingFieldMapBase,
  parentDto?: StudioDtoMappingGrid,
  errors?: FieldErrors)
{
  const rootNodes = [] as ITreeNode[];
  fieldMap?.keys?.forEach(key =>
  {
    const fieldDto = fieldMap?.map[key];
    const nodeField = createNodeFieldMapping(fieldDto, parentDto);
    const isExist = rootNodes.find(x => x.id === nodeField?.id);

    if(!isExist)
    {
      if(nodeField)
      {
        nodeField.infoSpot = errors?.[fieldDto?.metaId] ? "error" : undefined;
      }
      nodeField && rootNodes.push(nodeField);
    }
  });
  return rootNodes;
}

export function getFromAndToMappingText(
  node: ITreeNode,
  getMappingVarFunctions: (type: "from" | "to") => IMappingVarFunctions,
  formStore?: FormStore)
{
  const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
  let fromText: string | undefined;
  let toText: string | undefined;

  const {
    getCompositeName: getFromCompName,
    getFieldName: getFromFieldName
  } = getMappingVarFunctions("from");

  const {
    getCompositeName: getToCompName,
    getFieldName: getToFieldName,
    form: toForm,
    pluginId: toPluginId
  } = getMappingVarFunctions("to");
  const fromCompositeId = userField?.studioDtoMappingComposite?.fromGridId;
  const toCompositeId = userField?.studioDtoMappingComposite?.toGridId;

  if(userField?.type === "field")
  {
    const from = userField.studioDtoMappingField?.from;
    const to = userField.studioDtoMappingField?.to;
    const fnResolver = formStore
      ? fnUseStudioResolver(formStore)
      : undefined;

    const toFormId = toForm?.metaId;

    const toFieldName = to
      ? getToFieldName(to, toCompositeId)
      : "";

    if(from)
    {
      if(from.kind === "field")
      {
        const fieldId = (from.value as StudioDtoArgValueField | undefined)?.fieldId;
        fromText = fieldId && getFromFieldName(fieldId)
          ? "field:" + getFromFieldName(fieldId)
          : "";
      }
      else
      {
        fromText = fnResolver
          ? fnResolveArgBinderValue(fnResolver, from, toFormId, undefined, toPluginId)
          : "";
      }
    }

    toText = toFieldName;
  }
  else
  {
    fromText = fromCompositeId
      ? getFromCompName(fromCompositeId)
      : "";

    toText = toCompositeId
      ? getToCompName(toCompositeId)
      : "";
  }
  return {
    fromText: fromText || "",
    toText: toText || ""
  };
}

export function fnGetTreeNodeLabel(
  isFormReadOnly: boolean,
  pageCtx: ICtxPage,
  mappingVarFunctions: (type: ("from" | "to")) => IMappingVarFunctions,
  cbRefTree: ITreeRef,
  cbOnEdit: (node: ITreeNode) => void,
  cbOnAddField: (node: ITreeNode) => void,
  cbOnPasteField: (node: ITreeNode) => void,
  formStore?: FormStore,
  errors?: FieldErrors
)
{
  return (node: ITreeNode, isFirst?: boolean, isLast?: boolean) =>
  {
    const {
      fromText: primaryText,
      toText: primaryCaptionText
    } = getFromAndToMappingText(node, mappingVarFunctions, formStore);

    const disabled = Boolean(!primaryText || !primaryCaptionText);
    const treeNodeHeight = getListItemHeightAPSA("p");

    const primary: ILinePrimary = {
      text: primaryText,
      color: disabled ? "textDisabled" : undefined,
      middle: {
        type: "text",
        text: ">",
        variant: "subtitle1",
        color: disabled ? "textDisabled" : "textPrimary"
      },
      caption: {
        type: "text",
        variant: "subtitle1",
        color: disabled ? "textDisabled" : "textPrimary",
        text: primaryCaptionText?.slice(0, 10) + ((primaryCaptionText || "").length > 10 ? "..." : ""),
        ignoreSelection: true
      }
    };

    return (
      <BoxShell
        pl={0}
        height={treeNodeHeight}
        infoSpot={node.infoSpot}
        ignoreSelection={true}
        hideMenu={isFormReadOnly}
        onClick={() => cbOnEdit(node)}
        onClickMenu={(menuAnchor) =>
          fnCbOnClickMenu(cbRefTree, cbOnEdit, cbOnAddField, cbOnPasteField, pageCtx)
          (menuAnchor, node, Boolean(isFirst), Boolean(isLast))
        }
        onClickInfoSpot={(menuAnchor) =>
        {
          const errorMsg = errors?.[node.name]?.message as string;
          if(errorMsg)
          {
            pageCtx.showPopover(menuAnchor, {
              msg: errorMsg,
              bgcolor: theme.common.bgcolorError
            });
          }
        }
        }
      >
        <BoxP
          primary={primary}
          flexGrow={1}
        />
      </BoxShell>
    );
  };
}

function fnCbOnClickMenu(
  cbRefTree: ITreeRef,
  cbOnEdit: (node: ITreeNode) => void,
  cbOnAddField: (node: ITreeNode) => void,
  cbOnPasteField: (node: ITreeNode) => void,
  pageCtx: ICtxPage)
{
  return (
    menuAnchor: Element,
    node: ITreeNode,
    isFirst: boolean,
    isLast: boolean) =>
  {
    const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
    const type = userField?.type;

    let menuProps: IMenuProps = {
      "Edit": {
        onClick: () => cbOnEdit(node)
      },
      "Remove": {
        onClick: () => cbRefTree.nodeRemove(node)
      },
      "gap1": undefined,
      "Copy": {
        onClick: () => copyToClipboard(updateAllMetaIds(JSON.stringify(node)))
      },
      "Paste": {
        onClick: () =>
        {

          cbOnPasteField(node);

        },
        disabled: type !== "composite"
      },
      "gap2": undefined,
      "Move up": {
        onClick: () => cbRefTree.nodeMoveUp(node),
        disabled: isFirst
      },
      "Move down": {
        onClick: () => cbRefTree.nodeMoveDown(node),
        disabled: isLast
      },
      "Move top": {
        onClick: () => cbRefTree.nodeMoveTop(node),
        disabled: isFirst
      },
      "Move bottom": {
        onClick: () => cbRefTree.nodeMoveBottom(node),
        disabled: isLast
      },
      ...(type === "composite") && {
        "gap3": undefined,
        "Add field mapping": {
          onClick: () => cbOnAddField(node),
          disabled: Boolean(!userField?.studioDtoMappingComposite?.fromGridId
            || !userField?.studioDtoMappingComposite.toGridId)
        }
      }
    };
    pageCtx.showMenu(menuAnchor, menuProps);
  };
}

export const mappingFieldTypeValidationMap = {
  text: new Set(["text", "paragraph", "identifier"]),
  identifier: new Set(["text"]),
  pickText: new Set(["pickText", "text"]),
  pickTree: new Set(["pickTree", "text"]),
  pickReportRow: new Set(["pickReportRow", "text"]),
  rowId: new Set(["rowId", "hyperlinkRow"]),
  hyperlinkRow: new Set(["hyperlinkRow", "rowId"]),
  location: new Set(["location", "geoPoint"]),
  pickUser: new Set(["pickUser", "userId"]),
  userId: new Set(["userId", "pickUser"]),
  dateTime: new Set(["dateTime", "date"]),
  date: new Set(["date", "dateTime"]),
  dateRange: new Set(["dateRange", "dateTimeRange"]),
  dateTimeRange: new Set(["dateTimeRange", "dateRange"]),
  handle: new Set(["handle", "email", "mobileNumber"]),
  counter: new Set(["counter", "number"]),
  number: new Set(["number", "counter"]),
  logCounter: new Set(["logNumber", "number"]),
  logNumber: new Set(["logNumber", "number"]),
  logDecimal: new Set(["logDecimal", "decimal"]),
  showCode: new Set(["showCode", "text", "identifier", "hyperlink", "number"]),
  $RowId: new Set(["rowId", "hyperlinkRow"]),
  $CreatedBy: new Set(["pickUser", "userId"]),
  $UpdatedBy: new Set(["pickUser", "userId"]),
  $CreatedOn: new Set(["dateTime"]),
  $UpdatedOn: new Set(["dateTime"]),
  $RowOrder: new Set(["number"])
} as Record<EnumDefnCompType | EnumDefnFields, Set<EnumDefnCompType>>;

export function getFieldTypes(fieldType?: EnumDefnCompType): EnumDefnCompType[] | undefined
{
  if(fieldType)
  {
    if(mappingFieldTypeValidationMap[fieldType])
    {
      return [...mappingFieldTypeValidationMap[fieldType]];
    }
    return [fieldType];
  }
}
