import ISrvc from "../../../base/ISrvc";
import SrvcAdminPanelAutomation from "../../tools/automation/SrvcAdminPanelAutomation";
import SrvcAdminPanelLog from "../../tools/log/SrvcAdminPanelLog";
import SrvcAdminPanelManage from "./manage/SrvcAdminPanelManage";

export class SrvcAdminPanelMain extends ISrvc
{
  public readonly automation = new SrvcAdminPanelAutomation();
  public readonly log = new SrvcAdminPanelLog();
  public readonly manage = new SrvcAdminPanelManage();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.automation,
      this.log,
      this.manage
    );
  }
}
