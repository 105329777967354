import {IDebugServiceReport} from "../base/ISrvc";
import ISrvc from "../base/ISrvc";
import ISrvcChat from "../base/ISrvcChat";
import SrvcCache from "../cache/SrvcCache";
import SrvcAdminPanel from "./adminPanel/SrvcAdminPanel";
import SrvcApiBr from "./apiBr/SrvcApiBr";
import SrvcApp from "./app/SrvcApp";
import SrvcDeepLink from "./deeplink/SrvcDeepLink";
import SrvcHome from "./home/SrvcHome";
import SrvcStore from "./store/SrvcStore";
import SrvcStudio from "./studio/SrvcStudio";
import SrvcTerminal from "./terminal/SrvcTerminal";

export class RootSrvc extends ISrvc
{
  public readonly apiBr = new SrvcApiBr();
  public readonly app = new SrvcApp();
  public readonly deeplink = new SrvcDeepLink();
  public readonly home = new SrvcHome();
  public readonly studio = new SrvcStudio();
  public readonly store = new SrvcStore();
  public readonly terminal = new SrvcTerminal();
  public readonly adminPanel = new SrvcAdminPanel();
  public readonly cache = new SrvcCache();

  private srvcChatArray: ISrvcChat[] | undefined = undefined;

  constructor()
  {
    super();

    this.setSrvcArray(
      this.apiBr,
      this.app,
      this.deeplink,
      this.home,
      this.studio,
      this.store,
      this.terminal,
      this.adminPanel,
      this.cache
    );
  }

  init()
  {
    Srvc.app.widget.initWidget();
    this.doInit();

    // no need to clear interval
    setInterval(() =>
    {
      let now = new Date();
      this.doTick(now);
    }, 1000);
  }

  debug(report: IDebugServiceReport): void
  {
    super.doDebug(report);
  }

  public onWsocAuth()
  {
    super.onWsocAuth();
  }

  public onWsocClose()
  {
    super.onWsocClose();
  }

  signOut(): void
  {
    super.doSignOut();
  }

  getSrvcChatArray(): ISrvcChat[]
  {
    if(this.srvcChatArray === undefined)
    {
      this.srvcChatArray = [] as ISrvcChat[];
      this.collectSrvcsChat(this.srvcChatArray);
    }
    return this.srvcChatArray;
  }

}

export const Srvc = new RootSrvc();
