import {useTheme} from "@mui/material";
import {isEmpty} from "lodash";
import {useMemo} from "react";
import React from "react";
import {DefnFieldHtml} from "../../../../../api/meta/base/dto/DefnFieldHtml";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {FieldValueAudio} from "../../../../../api/meta/base/dto/FieldValueAudio";
import {FieldValueCamera} from "../../../../../api/meta/base/dto/FieldValueCamera";
import {FieldValueImage} from "../../../../../api/meta/base/dto/FieldValueImage";
import {FieldValueText} from "../../../../../api/meta/base/dto/FieldValueText";
import {FieldValueVideo} from "../../../../../api/meta/base/dto/FieldValueVideo";
import {FieldValueVoice} from "../../../../../api/meta/base/dto/FieldValueVoice";
import {MediaId} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {loopDefnForm} from "../../../../../base/plus/FormPlus";
import {validYouTubeUrl} from "../../../../../base/plus/UrlPlus";
import {RootState} from "../../../../../Store";
import {useAppSelector} from "../../../../app/AppHooks";
import BubbleRawMediaCarousel from "../BubbleRawMediaCarousel";
import {IBubbleRawMediaCarouselItem} from "../BubbleRawMediaCarousel";

export default function BubbleRawFormMediaCarousel(props: {
  defnForm: DefnForm,
  valueMap: Record<MetaIdField, any>,
  maxWidth?: number,
  getMediaSrc?: (state: RootState, mediaId?: MediaId) => string | undefined
})
{
  const theme = useTheme();
  const defnForm = props.defnForm;
  const valueMap = props.valueMap;
  const maxWidth = props.maxWidth;
  const bubbleVariantFixedSize = maxWidth
    ? Math.min(maxWidth, theme.common.bubbleVariantFixedWidth)
    : theme.common.bubbleVariantFixedWidth;

  const stateData = useAppSelector(state => state);

  const mediaList = useMemo<IBubbleRawMediaCarouselItem[]>(() =>
      getFormValueMapMedia(
        defnForm,
        valueMap,
        bubbleVariantFixedSize,
        stateData,
        props.getMediaSrc
      ),
    [defnForm, valueMap, bubbleVariantFixedSize, stateData, props.getMediaSrc]
  );

  if(mediaList.length > 0)
  {
    return (
      <BubbleRawMediaCarousel
        infiniteLoop={false}
        mediaList={mediaList}
        swipeAble={true}
        maxWidth={bubbleVariantFixedSize}
      />
    );
  }
  return null;
}

function getFormValueMapMedia(
  defnForm: DefnForm,
  valueMap: Record<string, any>,
  bubbleVariantFixedSize: number,
  state: RootState,
  getMediaSrc?: (state: RootState, mediaId?: MediaId) => string | undefined)
{
  const mediaList = [] as IBubbleRawMediaCarouselItem[];
  if(valueMap)
  {
    loopDefnForm(defnForm, (_, field) =>
      {
        if(field)
        {
          const fieldType = field.type;
          const fieldId = field.metaId;

          if(fieldType === "image" || fieldType === "camera")
          {
            const fieldValueImage = valueMap[fieldId] as FieldValueImage | FieldValueCamera;
            if(!isEmpty(fieldValueImage))
            {
              const mediaSrcImage = getMediaSrc?.(state, fieldValueImage?.value.mediaIdImage);
              const mediaSrcBlurImage = getMediaSrc?.(state, fieldValueImage?.value.mediaIdBlurImage);

              const image = {
                fieldValueImage: fieldValueImage,
                width: bubbleVariantFixedSize,
                height: bubbleVariantFixedSize,
                actualWidth: fieldValueImage.value.width,
                actualHeight: fieldValueImage.value.height,
                type: fieldType,
                mediaSrcImage: mediaSrcImage,
                mediaSrcBlurImage: mediaSrcBlurImage
              } as IBubbleRawMediaCarouselItem;
              mediaList.push(image);
            }
          }
          else if(fieldType === "audio" || fieldType === "voice")
          {
            const fieldValueAudio = valueMap[fieldId] as FieldValueAudio | FieldValueVoice;
            if(!isEmpty(fieldValueAudio))
            {
              const mediaSrcAudio = getMediaSrc?.(state, fieldValueAudio.mediaIdAudio);

              const media = {
                fieldValueAudio: fieldValueAudio,
                width: bubbleVariantFixedSize,
                height: bubbleVariantFixedSize,
                type: fieldType,
                mediaSrcAudio: mediaSrcAudio
              } as IBubbleRawMediaCarouselItem;
              mediaList.push(media);
            }
          }
          else if(fieldType === "video")
          {
            const fieldValueVideo = valueMap[fieldId] as FieldValueVideo;
            if(!isEmpty(fieldValueVideo))
            {
              const mediaSrcVideo = getMediaSrc?.(state, fieldValueVideo.mediaIdVideo);
              const mediaSrcImage = getMediaSrc?.(state, fieldValueVideo.mediaIdImage);
              const mediaSrcBlurImage = getMediaSrc?.(state, fieldValueVideo.mediaIdBlurImage);

              const media = {
                fieldValueVideo: fieldValueVideo,
                width: bubbleVariantFixedSize,
                height: bubbleVariantFixedSize,
                type: fieldType,
                mediaSrcVideo: mediaSrcVideo,
                mediaSrcImage: mediaSrcImage,
                mediaSrcBlurImage: mediaSrcBlurImage
              } as IBubbleRawMediaCarouselItem;
              mediaList.push(media);
            }
          }
          else if(fieldType === "html")
          {
            const fieldValueHtml = (field as DefnFieldHtml).defaultVar
              ? (field as DefnFieldHtml).defaultVar
              : (field as DefnFieldHtml).placeHolderVar
                ? (field as DefnFieldHtml).placeHolderVar
                : undefined;

            if(!isEmpty(fieldValueHtml?.value))
            {
              const media = {
                fieldValueHtml: fieldValueHtml,
                width: bubbleVariantFixedSize,
                height: bubbleVariantFixedSize,
                type: "html"
              } as IBubbleRawMediaCarouselItem;
              mediaList.push(media);
            }
          }
          else if(fieldType === "hyperlink")
          {
            const fieldValueHyperlink = valueMap[fieldId] as FieldValueText;

            if(!isEmpty(fieldValueHyperlink))
            {
              const media = {
                fieldValueHyperlink: fieldValueHyperlink,
                type: fieldType,
                width: bubbleVariantFixedSize,
                height: bubbleVariantFixedSize,
                validHyperlink: validYouTubeUrl(fieldValueHyperlink.value)
              } as IBubbleRawMediaCarouselItem;

              if(media.validHyperlink)
              {
                mediaList.push(media);
              }
            }
          }
        }
      },
      {
        onlyField: true,
        excludeGridItem: true
      }
    );
  }
  return mediaList;
}
