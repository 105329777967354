import {StudioPluginDev} from "../../../api/meta/base/dto/StudioPluginDev";
import {StudioPluginJar} from "../../../api/meta/base/dto/StudioPluginJar";
import {StudioPluginResourceMap} from "../../../api/meta/base/dto/StudioPluginResourceMap";
import {StudioPluginRpc} from "../../../api/meta/base/dto/StudioPluginRpc";
import {MediaIdDocument} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EnumDefnPluginResources} from "../../../api/meta/base/Types";
import {EnvError} from "../../../api/nucleus/base/dto/EnvError";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {WsocStudioMain} from "../../../api/studio/studioMain/WsocStudioMain";
import ISrvc from "../../../base/ISrvc";
import {formatDateToISO} from "../../../base/plus/DatePlus";
import {formatDate} from "../../../base/plus/DatePlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListData} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioPluginResources extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.plugin.pluginResourceList;
  }

  wsocPluginSourceCodeDownload(
    pluginBundleId: PluginBundleId,
    cbSuccess: (documentMediaId: MediaIdDocument) => void,
    cbError?: (error: EnvError) => void)
  {
    WsocStudioMain.pluginSourceCodeDownload(pluginBundleId, (envSig) =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        cbError && cbError(envSig.error);
        return;
      }

      envSig.sig && cbSuccess(envSig.sig.documentMediaId);
    });
  }

  loadResourceList(
    listName: string,
    pluginResourceMap?: StudioPluginResourceMap,
    readOnly?: boolean)
  {

    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    pluginResourceMap?.jar && this.doLoadItemJar(uiItemIds, uiItemsById, pluginResourceMap.jar, readOnly);
    pluginResourceMap?.rpc && this.doLoadItemRpc(uiItemIds, uiItemsById, pluginResourceMap.rpc, readOnly);
    pluginResourceMap?.dev && this.doLoadItemDev(uiItemIds, uiItemsById, pluginResourceMap.dev, readOnly);

    const listData = {
      itemIds: uiItemIds,
      itemsById: uiItemsById

    } as IListData;

    dispatchList(listName, listRefresh(listData));
  }

  private doLoadItemJar(
    uiItemIds: TypeListItemId[],
    uiItemsById: IListItemsById,
    jar: StudioPluginJar,
    readOnly?: boolean)
  {

    const itemId: EnumDefnPluginResources = "jar";
    uiItemIds.push(itemId);

    uiItemsById[itemId] = {
      type: "p",
      primary: {
        text: toLabel(itemId),
        caption: {
          text: jar.lastUpdateTime
            ? formatDate(formatDateToISO(new Date(jar.lastUpdateTime)), "local")
            : formatDate(formatDateToISO(new Date()), "local"),
          ignoreSelection: true
        }
      },
      hideMenu: readOnly === true,
      userField: {
        resourceType: itemId
      }
    } as IListItemAPSA;
  }

  private doLoadItemRpc(
    uiItemIds: TypeListItemId[],
    uiItemsById: IListItemsById,
    rpc: StudioPluginRpc,
    readOnly?: boolean)
  {

    const itemId: EnumDefnPluginResources = "rpc";
    uiItemIds.push(itemId);

    uiItemsById[itemId] = {
      type: "p",
      primary: {
        text: "RPC",
        caption: {
          text: rpc.lastUpdateTime
            ? formatDate(formatDateToISO(new Date(rpc.lastUpdateTime)), "local")
            : formatDate(formatDateToISO(new Date()), "local"),
          ignoreSelection: true
        }
      },
      hideMenu: readOnly === true,
      userField: {
        resourceType: itemId
      }
    } as IListItemAPSA;
  }

  private doLoadItemDev(
    uiItemIds: TypeListItemId[],
    uiItemsById: IListItemsById,
    dev: StudioPluginDev,
    readOnly?: boolean)
  {

    const itemId: EnumDefnPluginResources = "dev";
    uiItemIds.push(itemId);

    uiItemsById[itemId] = {
      type: "p",
      primary: {
        text: toLabel(itemId),
        caption: {
          text: dev.lastUpdateTime
            ? formatDate(formatDateToISO(new Date(dev.lastUpdateTime)), "local")
            : formatDate(formatDateToISO(new Date()), "local"),
          ignoreSelection: true
        }
      },
      hideMenu: readOnly === true,
      userField: {
        resourceType: itemId
      }
    } as IListItemAPSA;
  }

}

