import {useTheme} from "@mui/material";
import React from "react";
import {useMemo} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../api/meta/base/dto/DefnGrid";
import {DefnLayoutGridTable} from "../../../api/meta/base/dto/DefnLayoutGridTable";
import {FieldValueGrid} from "../../../api/meta/base/dto/FieldValueGrid";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {MetaIdLayoutGrid} from "../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {random} from "../../../base/plus/StringPlus";
import {px} from "../../../base/plus/StringPlus";
import {IGridBinderAll} from "../../../base/types/TypeDataGrid";
import {IDataGridData} from "../../../base/types/TypeDataGrid";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../atom/layout/LayoutFlexRow";
import RawLabel from "../../atom/raw/RawLabel";
import {getCompLabel} from "../../form/viewer/base/FormViewerPlus";
import {DataGrid} from "../DataGrid";

export default function DataGridMasterDetailGrid<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  defnForm: DefnForm;
  gridId: MetaIdGrid,
  layoutId: MetaIdLayoutGrid;
  value: FieldValueGrid,
  valueMap: FieldValues,
  gridBinder?: IGridBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  searchWords?: string[]
})
{
  const theme = useTheme();
  const defnForm = props.defnForm;
  const gridId = props.gridId;
  const valueMap = props.valueMap;
  const selectUserAvatar = props.gridBinder?.selectUserAvatar;
  const fieldGrid = defnForm.compMap[gridId] as DefnGrid | undefined;
  const layout = fieldGrid?.layoutGridMap?.map[props.layoutId] as DefnLayoutGridTable;
  const value = props.value;
  const searchWords = props.searchWords;
  const label = fieldGrid ? getCompLabel(fieldGrid) : undefined;
  const labelKey = "label-" + label;
  const indexLabel = layout.indexColumnName ?? "";
  const showIndex = Boolean(indexLabel.length);

  const footerValueMap = useMemo(() =>
  {
    return layout?.footer?.keys.reduce((acc, key) =>
    {
      const footer = layout.footer?.map[key];
      if(footer?.displayFieldId)
      {
        acc[footer.displayFieldId] = valueMap[footer.displayFieldId];
      }
      return acc;
    }, {} as Record<string, any>);
  }, [valueMap, defnForm]);

  const initRows = useMemo(() =>
  {
    return {
      keys: value.keys,
      map: value.map as Record<string, FormValueRaw>,
      version: random()
    };
  }, [value.keys, value.map]);

  const initValues = useMemo(() =>
  {
    if(layout && value)
    {
      return {
        defnForm: defnForm,
        layout: layout,
        showColIndex: showIndex,
        colIndexName: indexLabel,
        footer: footerValueMap,
        searchWords: searchWords,
        borderTop: theme.common.reportBorder,
        borderBottom: theme.common.reportBorder,
        borderLeft: theme.common.reportBorder,
        borderRight: theme.common.reportBorder,
        version: random()
      } as IDataGridData;
    }
  }, [defnForm, indexLabel, layout, showIndex, value]);

  const gridBinder = useMemo(() =>
  {
    return {
      selectUserAvatar: selectUserAvatar
    } as IGridBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>;
  }, [selectUserAvatar]);

  if(!initValues)
  {
    return null;
  }
  return (
    <LayoutFlexCol
      height={px(250)}
      alignItems={"start"}
    >
      <LayoutFlexRow
        pb={px(theme.common.gapHalf)}
      >
        <RawLabel
          defn={{
            type: "label",
            metaId: labelKey,
            name: label || "",
            label: label,
            boldVar: true,
            textSizeVar: "body2",
            colorVar: {
              value: "textPrimary"
            }
          }}
          value={label}
          bold={true}
        />
      </LayoutFlexRow>
      <LayoutFlexRow
        flexGrow={1}
        width={"100%"}
      >
        <DataGrid
          initRows={initRows}
          initValues={initValues}
          gridBinder={gridBinder}
        />
      </LayoutFlexRow>
    </LayoutFlexCol>
  );
}
