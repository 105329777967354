// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgDeviceGet} from './msg/MsgDeviceGet';
import {MsgFCMToken} from './msg/MsgFCMToken';
import {MsgQrCodeVerify} from '../otp/msg/MsgQrCodeVerify';
import {MsgVersion} from '../base/msg/MsgVersion';
import {ServiceName} from '../../meta/base/Types';
import {SigCaller} from './sig/SigCaller';
import {SigCallerDevice} from './sig/SigCallerDevice';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocUser
{
  static readonly SN: ServiceName = "user";

  static callerDeviceGet(msg: MsgDeviceGet, sigAcceptor: ISigAcceptor<SigCallerDevice>): void
  {
    wsocCall<SigCallerDevice>(ENT_ID_GLOBAL, WsocUser.SN, "callerDeviceGet")
      .get(msg, sigAcceptor);
  }

  static callerGet(msg: MsgVersion, sigAcceptor: ISigAcceptor<SigCaller>): void
  {
    wsocCall<SigCaller>(ENT_ID_GLOBAL, WsocUser.SN, "callerGet")
      .get(msg, sigAcceptor);
  }

  static fCMTokenUpdate(msg: MsgFCMToken, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocUser.SN, "fCMTokenUpdate")
      .put(msg, sigAcceptor);
  }

  static qRCodeJoin(msg: MsgQrCodeVerify, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocUser.SN, "qRCodeJoin")
      .post(msg, sigAcceptor);
  }
}