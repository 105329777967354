import {FormControl} from "@mui/material";
import React from "react";
import Barcode from "react-barcode";
import {Controller} from "react-hook-form";
import QRCode from "react-qr-code";
import {DefnFieldShowCode} from "../../../../api/meta/base/dto/DefnFieldShowCode";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldBase from "../raw/FieldBase";
import FieldRawTemplate from "../raw/FieldRawTemplate";

const COLOR_BG = "#ffffff";
const COLOR_FG = "#000000";
const SIZE_QR = 100;
const BARCODE_FORMAT = "CODE128";
const BARCODE_CHARACTER_LIMIT = 22;

export default function FieldShowCode(props: {
  defn: DefnFieldShowCode
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const defnTheme = formCtx.getDefnTheme();
  const control = formCtx.control();
  const codeType = defn.codeType;
  const isQrcode = codeType === "qrCode";
  const fieldId = getFieldKey(defn);
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isSectionVariant = sectionVariant === "propertyEditor";
  const fieldVariant = defnTheme.fieldVariant;
  const isReport = defnTheme.formVariant === "report";
  const showLabel = defn.showLabel;
  const isBarCodeVisible = defnDtoTextToString(defn.defaultVar).length <= BARCODE_CHARACTER_LIMIT;
  const padding = getDefnFieldPadding(defn);

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as FieldValueText | undefined;

        if(isReport && showLabel)
        {
          return (
            <FieldRawTemplate
              defn={defn}
              reportNode={<RawShowCode
                isQrcode={isQrcode}
                fieldValue={fieldValue}
                isBarCodeVisible={isBarCodeVisible}
              />}
              children={null}
            />
          );
        }

        return (
          <FieldBase fieldId={fieldId} {...padding}>
            {(!isReport && showLabel) && <FieldLabel defn={defn} />}
            <FormControl
              fullWidth
              style={{
                objectFit: "contain",
                overflow: "hidden"
              }}
            >
              <RawShowCode
                isQrcode={isQrcode}
                fieldValue={fieldValue}
                isBarCodeVisible={isBarCodeVisible}
              />
            </FormControl>
          </FieldBase>
        );
      }}
    />
  );
}

function RawShowCode(props: {
  fieldValue?: FieldValueText | null,
  isQrcode?: boolean,
  isBarCodeVisible: boolean,
})
{
  const isQrcode = props.isQrcode;
  const fieldValue = props.fieldValue;
  const isBarCodeVisible = props.isBarCodeVisible;

  if(!fieldValue || !fieldValue?.value)
  {
    return null;
  }

  return (
    <LayoutFlexCol
      maxWidth={"200px"}
      alignItems={"flex-start"}
      overflowX={"visible"}
      overflowY={"visible"}
    >
      {
        isQrcode
          ? <QRCode
            value={fieldValue.value}
            size={SIZE_QR}
            bgColor={COLOR_BG}
            fgColor={COLOR_FG}
            level={"H"}
            style={{margin: 0}}
          />
          : isBarCodeVisible
            ? <Barcode
              format={BARCODE_FORMAT}
              width={1}
              height={40}
              value={fieldValue.value}
              displayValue={false}
              margin={0}
            />
            : null
      }
    </LayoutFlexCol>
  );
}
