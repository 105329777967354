import styled from "@emotion/styled";
import {CancelRounded} from "@mui/icons-material";
import {CircularProgress} from "@mui/material";
import {IconButton} from "@mui/material";
import {Chip} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {Popper} from "@mui/material";
import {FormControl} from "@mui/material";
import {createFilterOptions} from "@mui/material";
import {useTheme} from "@mui/material";
import {TextField} from "@mui/material";
import {Box} from "@mui/system";
import {Property} from "csstype";
import {isEmpty} from "lodash";
import {isArray} from "lodash";
import {useState} from "react";
import {useMemo} from "react";
import {forwardRef, ReactNode, useCallback, useEffect} from "react";
import {FocusEventHandler} from "react";
import {RefCallBack} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {DefnStudioMapOfDtoOption} from "../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {MetaIdOption} from "../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../api/meta/base/Types";
import {px} from "../../../base/plus/StringPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import {useFormCtx} from "../../form/viewer/base/CtxForm";
import {DividerHorizontal} from "../layout/DividerHorizontal";
import RawHighlighter from "./RawHighlighter";
import RawIcon from "./RawIcon";

interface RawAutocompleteProps
{
  defn?: DefnFieldEditable,
  width?: Property.Width,
  height?: Property.Height,
  inputFieldSize?: EnumDefnThemeFieldSize,

  fieldId: string,
  name: string,
  label?: string,
  optionMap?: DefnStudioMapOfDtoOption,
  value?: MetaIdOption | MetaIdOption[],

  freeSolo?: boolean,
  multiple?: boolean,
  disableCloseOnSelect?: boolean,
  disableClearable?: boolean,
  disabled?: boolean,
  onChange?: (value: MetaIdOption | MetaIdOption[] | null) => void,
  fieldVariant?: EnumDefnThemeFieldVariant;
  fieldSize?: EnumDefnThemeFieldSize;
  error?: FieldError,
  helperText?: string,
  required?: boolean,
  icon?: string,
  placeHolder?: string,
  hideLabel?: boolean,
  autoFocus?: boolean,
  readOnly?: boolean,
  onOpen?: () => void,
  onClick?: () => void,
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  endAdornment?: ReactNode,
  loading?: boolean,
  searchWords?: string[],
  disableSelected?: boolean,
  hideChips?: boolean,
}

const RawAutocomplete = forwardRef<RefCallBack, RawAutocompleteProps>((props: RawAutocompleteProps, ref) =>
{
  const inputFieldSize = props.inputFieldSize;
  const label = props.label;
  const name = props.name;
  const fieldId = props.fieldId;
  const freeSolo = props.freeSolo;
  const multiple = props.multiple;
  const value = props.value;
  const onOpen = props.onOpen;
  const onChange = props.onChange;
  const optionMap = props.optionMap;
  const onClick = props.onClick;
  const fieldSize = props.fieldSize;
  const fieldVariant = props.fieldVariant;
  const error = props.error;
  const helperText = props.helperText;
  const hideLabel = props.hideLabel;
  const required = props.required;
  const onBlur = props.onBlur;
  const endAdornment = props.endAdornment;
  const searchWords = props.searchWords;
  const defn = props.defn;
  const icon = props.icon;
  const loading = props.loading;
  const disableSelected = props.disableSelected;
  const hideChips = props.hideChips;

  const theme = useTheme();
  const formCtx = useFormCtx();

  const filter = createFilterOptions<MetaIdOption>();
  const isError = Boolean(error);
  const placeHolder = props.placeHolder;
  const autoFocus = props.autoFocus ?? false;
  const bgColorError = theme.common.colorWithShade("red", "s200");
  const StyledPopper = styled(Popper)`
    .MuiPaper-root
    {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      transform-origin: top center;
      transition: transform 0.5s, opacity 0.5s;
    }`;

  const readOnly = (defn && formCtx.isFieldReadonly(defn)) || props.readOnly;
  const disabled = (defn && formCtx.isFieldDisable(defn as DefnFieldEditable)) || defn?.disabled || props.disabled;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const filteredValue = useMemo(() => !isArray(value)
    ? value || null
    : value, [value]);

  const getShrinkValue = useCallback((value?: MetaIdOption | MetaIdOption[] | null) =>
  {
    if(hideChips)
    {
      return false;
    }

    if(value && isArray(value))
    {
      return value.length > 0;
    }
    else
    {
      return Boolean(value);
    }

  }, [hideChips]);

  const [shrink, setShrink] = useState<boolean>(getShrinkValue(filteredValue));
  const [focused, setFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const [isDraggingOutside, setIsDraggingOutside] = useState<boolean>(false);

  const handleShrink = useCallback((value: boolean) =>
  {
    if(hideChips)
    {
      setShrink(false);
    }
    else
    {
      setShrink(value);
    }

  }, [hideChips]);

  const handleOpen = useCallback(() =>
  {
    setIsOpen(true);
    onOpen && onOpen();
    onClick?.();

  }, [onClick, onOpen]);

  const handleDragStart = useCallback((e: React.DragEvent<HTMLDivElement>, index: number) =>
  {
    setIsOpen(false);
    setDraggedIndex(index);

    e.dataTransfer.effectAllowed = "move";

  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>, index: number) =>
  {
    e.preventDefault(); // Necessary to allow dropping
    setDragOverIndex(index);
    setIsDraggingOutside(false);

  }, []);

  const handleDragEnd = useCallback(() =>
  {
    setDraggedIndex(null);
    setDragOverIndex(null);
    setIsDraggingOutside(false);

  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) =>
  {
    const relatedTarget = e.relatedTarget;

    if(!(relatedTarget instanceof Node))
    {
      setIsDraggingOutside(true);
      return;
    }

    const isLeavingContainer = !e.currentTarget.contains(relatedTarget);
    if(isLeavingContainer)
    {
      setIsDraggingOutside(true);
    }

  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>, droppedIndex: number) =>
  {
    e.preventDefault();

    if(draggedIndex === null)
    {
      return;
    }

    if(isDraggingOutside || draggedIndex === droppedIndex || draggedIndex === (droppedIndex - 1))
    {
      handleDragEnd();
      return;
    }

    if(filteredValue === null || isArray(filteredValue))
    {
      const newValue = [...(filteredValue ?? [])];
      const [removed] = newValue.splice(draggedIndex, 1);
      const newIndex = draggedIndex > droppedIndex
        ? droppedIndex
        : droppedIndex - 1;
      newValue.splice(newIndex, 0, removed);
      onChange?.(newValue);
      handleDragEnd();
    }

  }, [handleDragEnd, isDraggingOutside, onChange, filteredValue, draggedIndex]);

  useEffect(() =>
  {
    handleShrink(getShrinkValue(filteredValue));
  }, [filteredValue]);

  return (
    <FormControl
      fullWidth
      variant={fieldVariant}
      error={isError}
      focused={focused}
    >
      <Autocomplete
        id={fieldId}
        ref={ref}
        onInputChange={(_, value) =>
        {
          if(value && !(value.charAt(0) === " "))
          {
            handleShrink(true);
          }
          else
          {
            handleShrink(false);
          }
        }}
        // @ts-ignore
        placeholder={placeHolder}
        freeSolo={freeSolo}
        multiple={multiple}
        value={filteredValue ?? null}
        options={optionMap?.keys ?? []}
        getOptionLabel={(option) =>
        {
          return optionMap?.map[option as MetaIdOption]?.value ?? "";
        }}
        isOptionEqualToValue={(option, value) =>
        {
          if(!optionMap)
          {
            return false;
          }

          return value === option
            && optionMap.map[value]?.value === optionMap.map[option]?.value;
        }}
        disabled={disabled}
        disableListWrap
        disableCloseOnSelect={multiple}
        disablePortal={false}
        readOnly={readOnly}
        open={isOpen}
        onOpen={handleOpen}
        onClose={() => setIsOpen(false)}
        onKeyDown={(event) =>
        {
          if(event.key === "Enter")
          {
            event.preventDefault();
            setIsOpen(true);
          }
        }}
        onChange={(_, value) =>
        {
          onChange && onChange(value as MetaIdOption | MetaIdOption[] || null);
        }}
        filterOptions={(freeSolo && multiple)
          ? (options, params) =>
          {
            const filtered = filter(options as MetaIdOption[], params);
            const {inputValue} = params;
            const isExisting = options.some((option) => inputValue === optionMap?.map[(option as MetaIdOption)].value);

            if(inputValue !== "" && !isExisting)
            {
              filtered.push(inputValue);
            }

            return filtered;
          }
          : undefined
        }
        size={fieldSize ?? "medium"}
        classes={{
          option: theme.palette.secondary.main
        }}
        renderOption={(props, optionId) =>
        {
          const option = optionMap?.map[optionId];

          const value = (option?.value ?? "").toString();
          if(option && (option.metaId || value))
          {
            const isRemoved = Boolean(option?.isRemoved);
            return value.includes("\n")
              ? (
                <li
                  {...props}
                  aria-selected={disableSelected ? false : props["aria-selected"]}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    color: isRemoved
                      ? bgColorError
                      : theme.common.colorWithShade(option.color?.value as TypeTextColor, option.color?.shade || "s500")
                  }}
                >
                  {renderBoxes(value)}
                </li>
              )
              : (
                <li
                  {...props}
                  aria-selected={disableSelected ? false : props["aria-selected"]}
                  style={{
                    color: isRemoved
                      ? bgColorError
                      : theme.common.colorWithShade(option.color?.value as TypeTextColor, option.color?.shade || "s500")
                  }}
                >
                  {value}
                </li>
              );
          }
          else
          {
            return <DividerHorizontal />;
          }
        }}
        popupIcon={icon ? <RawIcon icon={icon} /> : undefined}
        onClick={onClick}
        onFocus={(event) =>
        {
          if(event.type === "focus")
          {
            handleShrink(true);
          }
          //@ts-ignore //todo: Sohan : implement onClick without using onFocus
          // onClick && onClick(event);
        }}
        onBlur={(event) =>
        {
          if(event.type === "blur" && isEmpty(filteredValue))
          {
            handleShrink(false);
          }
        }}
        PopperComponent={(props) => (
          <StyledPopper {...props} />
        )}
        renderTags={(value, getTagProps) =>
        {
          if(!optionMap)
          {
            return;
          }

          return (
            value.map((optionId, index) =>
            {
              const option = optionMap.map[optionId];

              if(!option)
              {
                return null;
              }

              const labelTextColor = theme.common.colorWithShade(option?.color?.value as TypeTextColor,
                option?.color?.shade || "s500"
              ) ?? "black";
              const isDragging = index === draggedIndex;
              const isDropTarget = index === dragOverIndex;

              return (!hideChips
                  ? <Box
                    sx={{
                      marginLeft: isDropTarget ? px(gapStd) : 0
                    }}
                  >
                    <Chip
                      {...getTagProps({index})}
                      key={option?.metaId}
                      label={option.value || "Not found"}
                      size={"small"}
                      draggable={true}
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOver(e, index)}
                      onDragEnd={handleDragEnd}
                      onDrop={(e) => handleDrop(e, index)}
                      onDragLeave={handleDragLeave}
                      onClick={event =>
                      {
                        event.stopPropagation();
                      }}
                      sx={{
                        height: px(theme.common.chipHeight),
                        color: !Boolean(option?.isRemoved)
                          ? labelTextColor
                          : theme.common.bgcolorDefault,
                        borderRadius: px(gapQuarter),
                        bgcolor: Boolean(option?.isRemoved)
                          ? bgColorError
                          : undefined,

                        "& .MuiSvgIcon-root": {
                          height: px(theme.common.chipCrossButtonSize),
                          fontSize: px(theme.common.chipCrossButtonSize)
                        },

                        "&:hover": {
                          bgcolor: isDragging
                            ? theme.common.bgcolorSelection
                            : Boolean(option?.isRemoved)
                              ? bgColorError
                              : undefined
                        },

                        transition: "transform 0.2s ease-in-out"
                      }}
                      clickable
                      disabled={props.disabled}
                      deleteIcon={
                        <IconButton
                          disabled={readOnly}
                          sx={{
                            display: readOnly ? "none" : "flex",
                            padding: 0
                          }}
                          onClick={event =>
                          {
                            event.stopPropagation();
                          }}
                        >
                          <CancelRounded
                            onMouseDown={(event) =>
                            {
                              event.stopPropagation();
                              setFocused(false);
                            }}
                          />
                        </IconButton>
                      }
                      onDelete={(e) =>
                      {
                        e.preventDefault();
                        e.stopPropagation();

                        const arr: MetaIdOption[] = [];
                        value?.forEach(val =>
                        {
                          if(val !== option.metaId)
                          {
                            arr.push(val);
                          }
                        });

                        onChange && onChange(arr);
                      }}
                    />
                  </Box>

                  : null
              );
            })

          );
        }}
        renderInput={(params) =>
        {
          const selectedOption = (filteredValue && !isArray(filteredValue))
            ? optionMap?.map[filteredValue]
            : undefined;
          const selectedOptionColor = selectedOption ? selectedOption.color : null;
          return (
            <TextField
              {...params}
              sx={{
                margin: 0,
                "& .MuiAutocomplete-popupIndicator": {transform: icon ? "none" : undefined},
                "& input": {
                  color: selectedOption?.isRemoved
                    ? bgColorError
                    : (selectedOptionColor
                      ? theme.common.colorWithShade(selectedOptionColor.value as TypeTextColor,
                        selectedOptionColor.shade || "s500"
                      )
                      : "black")
                },
                ...borderColor && {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: borderColor
                    }
                  }
                }
              }}
              variant={fieldVariant}
              id={fieldId}
              name={name}
              required={required}
              disabled={disabled}
              autoFocus={autoFocus}
              label={!hideLabel
                ? (searchWords?.length
                  ? <RawHighlighter
                    searchWords={searchWords}
                    value={label}
                    variant={"body1"}
                    flexGrow={1}
                    color={theme.palette.text.secondary}
                  />
                  : label)
                : undefined
              }
              margin={"normal"}
              inputRef={ref}
              size={inputFieldSize ?? "small"}
              error={isError}
              onBlur={onBlur}
              placeholder={placeHolder}
              onKeyDown={(event) =>
              {
                if(event.key === "Enter")
                {
                  event.preventDefault();
                  setIsOpen(true);
                  onClick?.();
                }
              }}
              FormHelperTextProps={{
                sx: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1
                }
              }}
              helperText={error?.message || helperText}
              InputProps={{
                ...params.InputProps,
                readOnly: readOnly,
                onClick: onClick,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress
                      color="inherit"
                      size={20}
                    /> : null}
                    {endAdornment}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
              InputLabelProps={{
                shrink: shrink,
                sx: {
                  display: "flex"
                }
              }}

            />
          );
        }}
        loading={loading}
      />
    </FormControl>
  );
});

const renderBoxes = (value: string) =>
{
  const lines = value.split("\n");

  return (
    <>
      {lines.map((line, index) => (
        <Box key={index}>
          {line}
        </Box>
      ))}
    </>
  );
};

export default RawAutocomplete;
