import {RpcEntMain} from "../../../api/ent/entMain/RpcEntMain";
import {newGuid} from "../../../api/meta/base/NanoId";
import {EntId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import ISrvc from "../../../base/ISrvc";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import {setDebuggerList} from "../SliceCacheTools";
import {IDebuggerLogEntry} from "../TypeCacheTools";

export default class SrvcCacheToolsLog extends ISrvc
{
  rpcEntDebuggerLogGet(entId: EntId, cbSuccess?: CbSuccess)
  {
    RpcEntMain.debuggerLogsGet(entId, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;
      if(sig?.logList)
      {
        const newLogList: IDebuggerLogEntry[] = [];
        sig.logList.forEach(log =>
        {
          newLogList.push({
            ...log,
            id: newGuid()
          });
        });
        store.dispatch(setDebuggerList({
          entId: entId,
          logList: newLogList ?? []
        }));
      }

      cbSuccess && cbSuccess();
    });
  }
}
