import {useTheme} from "@mui/material";
import {useEffect} from "react";
import React from "react";
import {EntUserId} from "../../../api/meta/base/Types";
import {MessageTypeStar} from "../../../api/nucleus/base/Protocol";
import {getBubbleHeader} from "../../../base/plus/ChatPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {listChatSetIfExistIsVisibleSpreadsheetRow} from "../../../base/slices/list/SliceListChatAction";
import {IListBinderAll} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {IBubbleHeader} from "../../../base/types/TypesBubble";
import {RootState} from "../../../Store";
import {useAppSelector} from "../../app/AppHooks";
import ListItemChatAudio from "../chat/ListItemChatAudio";
import ListItemChatDocument from "../chat/ListItemChatDocument";
import ListItemChatForm from "../chat/ListItemChatForm";
import ListItemChatFormDeleted from "../chat/ListItemChatFormDeleted";
import ListItemChatGroup from "../chat/ListItemChatGroup";
import ListItemChatImage from "../chat/ListItemChatImage";
import ListItemChatLinkText from "../chat/ListItemChatLinkText";
import ListItemChatLocation from "../chat/ListItemChatLocation";
import ListItemChatReport from "../chat/ListItemChatReport";
import ListItemChatSpreadsheet from "../chat/ListItemChatSpreadsheet";
import ListItemChatText from "../chat/ListItemChatText";
import ListItemChatUser from "../chat/ListItemChatUser";
import ListItemChatVideo from "../chat/ListItemChatVideo";
import {CbOnClickListItem} from "../List";
import {ListItemSsRowBubble} from "../ssRowbubble/ListItemSsRowBubble";

export default function ListItemChat<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  itemType: MessageTypeStar,
  itemId: TypeListItemId,
  itemWidth: number,
  selectList: SelectList,
  isScrolling: boolean,
  onClickListItem?: CbOnClickListItem,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>,
})
{
  const itemId = props.itemId;
  const listItem = useAppSelector(state => props.selectList(state).itemsById[itemId]) as IListItemChat | undefined;

  if(listItem === undefined)
  {
    return <p>loading</p>;
  }
  else
  {
    return <RealListItemChat listItem={listItem} {...props} />;
  }
}

function RealListItemChat<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  itemType: MessageTypeStar,
  itemId: TypeListItemId,
  listItem: IListItemChat,
  itemWidth: number,
  selectList: SelectList,
  isScrolling: boolean,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>,
  onClickListItem?: CbOnClickListItem,
})
{
  const itemId = props.itemId;
  const listItem = props.listItem;
  const theme = useTheme();
  const onClickListItem = props.onClickListItem;
  const selectUserAvatar = props.listBinder?.selectUserAvatar;
  const pickType = useAppSelector(state => props.selectList(state).pickType);
  const listName = useAppSelector(state => props.selectList(state).listName);
  const gapStd = theme.common.gapStd;
  const pickMode = pickType === "pickMany";
  const sigSpreadsheetRowExpiry = listItem?.sig?.sigSpreadsheetRowExpiry;

  const childProps = {
    itemId: itemId,
    listItem: listItem,
    itemMaxWidth: pickMode ? props.itemWidth - gapStd : props.itemWidth,
    selectList: props.selectList,
    headerText: listItem?.sig?.header && getBubbleHeader(listItem?.sig?.header as IBubbleHeader),
    cbOnClickFooterCaption: listItem.sig?.isCallerSender
      ? (menuAnchor: Element) => onClickListItem && onClickListItem(menuAnchor, itemId, listItem, "chatFooterCaption")
      : undefined,
    onClickListItem: onClickListItem,
    selectUserAvatar: selectUserAvatar
      ? (state: RootState, entUserId: EntUserId) => selectUserAvatar(state, itemId, entUserId)
      : undefined
  };

  useEffect(() =>
  {
    if(sigSpreadsheetRowExpiry?.remainingInvisibleProgressPercentage === 0)
    {
      dispatchList(listName, listChatSetIfExistIsVisibleSpreadsheetRow({
        itemId: itemId,
        isInvisibleSpreadsheetRow: sigSpreadsheetRowExpiry?.showTimer
      }));
    }
  }, [listName, sigSpreadsheetRowExpiry?.remainingInvisibleProgressPercentage, itemId]);

  switch(props.itemType)
  {
    case "audio":
    case "voice":
      return <MemoizedChatItemAudio {...childProps} />;
    case "document":
      return <MemoizedChatItemDocument {...childProps} />;
    case "spreadsheetRow":
      return <MemoizedChatItemForm {...childProps} />;
    case "spreadsheetPartition":
      return <MemoizedChatItemSpreadsheet {...childProps} />;
    case "spreadsheetRowDeleted":
      return <MemoizedChatItemFormDeleted {...childProps} />;
    case "group":
      return <MemoizedChatItemGroup {...childProps} />;
    case "camera":
    case "image":
      return <MemoizedChatItemImage {...childProps} />;
    case "linkText":
      return <MemoizedChatItemLinkText {...childProps} />;
    case "location":
      return <MemoizedChatItemLocation {...childProps} />;
    case "report":
      return <MemoizedChatItemReport {...childProps} />;
    case "text":
      return <MemoizedChatItemText {...childProps} />;
    case "user":
      return <MemoizedChatItemUser {...childProps} />;
    case "video":
      return <MemoizedChatItemVideo {...childProps} />;
    case "ssRowBubble":
      return <MemoizedListItemSsRowbubble{...childProps} />;
  }
}

const MemoizedChatItemAudio = React.memo(ListItemChatAudio);
const MemoizedChatItemDocument = React.memo(ListItemChatDocument);
const MemoizedChatItemForm = React.memo(ListItemChatForm);
const MemoizedChatItemSpreadsheet = React.memo(ListItemChatSpreadsheet);
const MemoizedChatItemGroup = React.memo(ListItemChatGroup);
const MemoizedChatItemImage = React.memo(ListItemChatImage);
const MemoizedChatItemLinkText = React.memo(ListItemChatLinkText);
const MemoizedChatItemLocation = React.memo(ListItemChatLocation);
const MemoizedChatItemReport = React.memo(ListItemChatReport);
const MemoizedChatItemText = React.memo(ListItemChatText);
const MemoizedChatItemUser = React.memo(ListItemChatUser);
const MemoizedChatItemVideo = React.memo(ListItemChatVideo);
const MemoizedChatItemFormDeleted = React.memo(ListItemChatFormDeleted);
const MemoizedListItemSsRowbubble = React.memo(ListItemSsRowBubble);
