import {AudioFileRounded} from "@mui/icons-material";
import {FormHelperText} from "@mui/material";
import {TextField} from "@mui/material";
import {FormControl} from "@mui/material";
import {useMemo} from "react";
import {useCallback} from "react";
import React from "react";
import {Accept} from "react-dropzone";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {nextMediaIdAudio} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldAudio} from "../../../../api/meta/base/dto/DefnFieldAudio";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueAudio} from "../../../../api/meta/base/dto/FieldValueAudio";
import {EnumDefnAudioFormat} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {VALID_FILE_TYPES} from "../../../../base/plus/MediaPlus";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getDurationFromMs} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {CLEAR} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {CLOSE} from "../../../../base/types/TypesIcon";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFileSelectorCtx} from "../../../atom/raw/RawFileSelector";
import RawFileSelector from "../../../atom/raw/RawFileSelector";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import ICtxPage from "../../../ctx/ICtxPage";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import RawFieldAudioBar from "./RawFieldAudioBar";

const COLOR_PROGRESS = "#3e3c3c";
const COLOR_WAVE = "#e3e3e3";
const COLOR_CURSOR = "black";

const defaultAudioFormat = [
  "ogg",
  "wav",
  "mp3"
] as EnumDefnAudioFormat[];

export default function FieldAudio(props: {
  defn: DefnFieldAudio
})
{
  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon,
    getFieldMaxSize
  } = useFieldPropertiesResolver(defn);

  const label = getCompLabel(defn);
  const fieldId = getFieldKey(defn);
  const formCtx = useFormCtx();

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;

  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const helperText = getFieldHelperText();
  const maxSize = getFieldMaxSize();
  const icon = getFieldIcon();

  const audioFormatType = useMemo(() => defn.audioFormatType || defaultAudioFormat, [defn.audioFormatType]);

  const accept: Accept = useMemo(() =>
  {
    let validAccept = {} as Accept;

    if(audioFormatType && audioFormatType.length)
    {
      Object.entries(VALID_FILE_TYPES).forEach(([key, validFileTypes]) =>
      {
        validFileTypes.forEach(validFileType =>
        {
          const fileType = validFileType.split(".")[1] as EnumDefnAudioFormat;

          if(audioFormatType.includes(fileType))
          {
            validAccept[key] = [
              ...validAccept[key] ?? [],
              validFileType
            ];
          }
        });
      });
    }

    return validAccept;

  }, [audioFormatType]);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched || Boolean(error);
        const fieldValue = field.value as FieldValueAudio | undefined;

        const onChange = (value: FieldValueAudio | undefined) =>
        {
          if(fieldValue)
          {
            MediaStore.removeMedia(fieldValue.mediaIdAudio);
          }
          if(value)
          {
            field.onChange(value);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <RawAudio
                fieldId={fieldId}
                defn={defn}
                fieldValue={field.value}
                onChange={onChange}
                defnTheme={defnTheme}
                disabled={disabled}
                label={label}
                readOnly={readOnly}
                error={error}
                required={required}
                placeHolder={placeHolder}
                helperText={helperText}
                maxSize={maxSize as number}
                icon={icon}
                accept={accept}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawAudio(props: {
  fieldId: MetaIdField,
  defn: DefnFieldAudio,
  defnTheme: DefnDtoFormTheme,
  accept?: Accept,
  fieldValue?: FieldValueAudio,
  disabled?: boolean,
  label?: string,
  readOnly?: boolean,
  error?: FieldError,
  required?: boolean,
  placeHolder?: string,
  helperText?: string,
  maxSize?: number,
  icon?: string
  onChange: (value?: FieldValueAudio) => void
})
{
  return (
    <RawFileSelector
      multiple={true}
    >
      <RealRawAudio {...props} />
    </RawFileSelector>
  );
}

function RealRawAudio(props: {
  fieldId: MetaIdField,
  defn: DefnFieldAudio,
  fieldValue?: FieldValueAudio,
  accept?: Accept,
  defnTheme: DefnDtoFormTheme,
  disabled?: boolean,
  label?: string,
  readOnly?: boolean,
  error?: FieldError,
  required?: boolean,
  placeHolder?: string,
  helperText?: string,
  icon?: string,
  maxSize?: number,
  onChange: (value?: FieldValueAudio) => void
})
{
  const {
    fieldId,
    defn,
    fieldValue,
    defnTheme,
    disabled,
    label,
    readOnly,
    accept,
    error,
    required,
    placeHolder,
    helperText,
    icon,
    onChange
  } = props;

  const fileSelectorCtx = useFileSelectorCtx();
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();
  const fileName = fieldValue?.fileName;
  const maxFileUploadAlert = `number of uploaded should be 1`;
  const durationMs = parseFloat(getDurationFromMs(fieldValue?.durationMs ?? 0));

  const getOnClick = formCtx.getOnClick();

  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;

  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);
  const audioSrc = useMemo(() =>
  {
    const tempMediaSrc = fieldValue?.mediaIdAudio ? MediaStore.getMedia(fieldValue.mediaIdAudio) : undefined;
    return tempMediaSrc || formCtx.getApiCtx()?.getMediaSrc(fieldValue?.mediaIdAudio);
  }, [fieldValue?.mediaIdAudio]);

  const uploadFile = useCallback((_pageCtx: ICtxPage, file: File) =>
  {
    const mediaIdAudio = nextMediaIdAudio(getFileExt(file));
    MediaStore.setBlobToUrl(mediaIdAudio, file);

    onChange({
      fileName: file?.name,
      fileSize: file?.size,
      mediaIdAudio: mediaIdAudio
    } as FieldValueAudio);
  }, [onChange]);

  const onClickUpload = useCallback(() =>
  {
    if(!formCtx.isFieldReadonly(defn))
    {
      fileSelectorCtx.showFileDialog({
        accept: accept,
        onFileSelect: (files) =>
        {
          if(files.length > 1)
          {
            pageCtx.showErrorToast(maxFileUploadAlert);
            return;
          }
          const selectedFile = files[0];
          selectedFile && uploadFile(pageCtx, selectedFile);
        }
      });
    }
  }, [accept, defn, fileSelectorCtx, formCtx, maxFileUploadAlert, pageCtx, uploadFile]);

  return (
    <LayoutFlexRow
      width={"100%"}
      alignItems={"start"}
      overflowX={"visible"}
      overflowY={"visible"}
    >
      <LayoutFlexCol
        flexGrow={1}
        overflowX={"visible"}
        overflowY={"visible"}
        alignItems={"start"}
      >
        <TextField
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          fullWidth
          type={"text"}
          aria-readonly={true}
          size={defnTheme.fieldSize}
          margin={defnTheme.fieldMargin}
          variant={defnTheme.fieldVariant}
          error={Boolean(error?.message)}
          autoComplete={defn.autoFill ? "on" : "off"}
          autoFocus={defn.autoFocus}
          disabled={disabled}
          hiddenLabel={defn.hideLabel}
          label={!defn.hideLabel ? label : undefined}
          name={fieldId}
          placeholder={placeHolder}
          InputProps={{
            readOnly: readOnly,
            endAdornment: icon ? <RawIconButton
              name={icon}
              icon={icon}
            /> : <AudioFileRounded color={"action"} />,
            disableUnderline: true
          }}
          required={required}
          onClick={() =>
          {
            onClick && onClick();
            onClickUpload();
          }}
          value={fileName ?? ""}
          onKeyDown={(event) =>
          {
            if(event.key === "Enter")
            {
              onClick && onClick();
              onClickUpload();
            }
          }
          }
        />

        {
          (audioSrc) &&
          <RawFieldAudioBar
            showDuration={true}
            src={audioSrc}
            durationMs={durationMs}
            scrollable={true}
            cursorColor={COLOR_CURSOR}
            progressColor={COLOR_PROGRESS}
            waveColor={COLOR_WAVE}
          />
        }

        <FormHelperText
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          tabIndex={-1}
        >
          {error?.message ? error.message : helperText}
        </FormHelperText>
      </LayoutFlexCol>

      <LayoutFlexRow
        ml={px(gapHalf)}
        mr={`-${gapHalf}px`}
      >
        <RawIconStrip
          toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
          iconStrip={[CLOSE]}
          onClick={(icon) =>
          {
            if(icon === CLOSE)
            {
              onChange(undefined);
            }
          }}
          iconStripDisable={(!fieldValue || readOnly || disabled) ? [CLOSE] : []}
        />
      </LayoutFlexRow>
      <FieldRawRefButton
        defn={defn}
      />
    </LayoutFlexRow>
  );
}
