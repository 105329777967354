import {useTheme} from "@mui/material";
import {Menu} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import {PopoverOrigin} from "@mui/material/Popover/Popover";
import {useRef} from "react";
import React from "react";
import {useState} from "react";
import {DividerHorizontal} from "../layout/DividerHorizontal";

export interface IMenuRef
{
  closeMenu?: () => void;
}

export type TypeMenuClick = (menuName: string) => void;

export interface IMenuItem
{
  label?: string,
  onClick: TypeMenuClick,
  icon?: React.ReactNode,
  selected?: boolean,
  disabled?: boolean
}

export interface ILineSeparator
{

}

export interface IMenuProps
{
  [menuName: string]: IMenuItem | ILineSeparator | undefined;
}

export default function RawMenu(props: {
  cbRef?: IMenuRef,
  menuAnchor: Element,
  onMenuGone: () => void,
  menuProps: IMenuProps,
  anchorOrigin?: PopoverOrigin
})
{
  const menuProps = props.menuProps;
  const menuList = Object.keys(menuProps);
  const [open, setOpen] = useState(true);

  const onMenuGone = props.onMenuGone;
  const PopoverOrigin = props.anchorOrigin;

  function closeMenu()
  {
    setOpen(false);
    onMenuGone && onMenuGone();
  }

  if(props.cbRef)
  {
    props.cbRef.closeMenu = closeMenu;
  }

  return (
    <Menu
      disableAutoFocusItem={true}
      disableAutoFocus={true}
      disableRestoreFocus={true}
      disableEnforceFocus={true}
      anchorEl={props.menuAnchor}
      open={open}
      onClose={() => closeMenu()}
      {...PopoverOrigin && {
        anchorOrigin: PopoverOrigin
      }}
    >
      {
        menuList.map(menuName =>
        {
          const fn = menuProps[menuName];
          if(fn)
          {
            if(typeof fn === "function")
            {
              return <RawMenuItem
                closeMenu={closeMenu}
                key={menuName}
                menuName={menuName}
                onClick={fn as TypeMenuClick}
              />;
            }
            else
            {
              const menuItem = fn as IMenuItem;
              return <RawMenuItem
                key={menuName}
                icon={menuItem.icon}
                menuName={menuItem.label ?? menuName}
                disabled={menuItem.disabled}
                selected={menuItem.selected}
                onClick={menuItem.onClick}
                closeMenu={closeMenu}
              />;
            }
          }
          else
          {
            return <DividerHorizontal key={menuName} />;
          }
        })
      }
    </Menu>
  );
}

function RawMenuItem(props: {
  icon?: React.ReactNode,
  menuName: string,
  disabled?: boolean,
  selected?: boolean,
  onClick: (menuName: string) => void,
  closeMenu: () => void,
})
{
  const theme = useTheme();
  const menuName = props.menuName;
  const onClick = props.onClick;
  const closeMenu = props.closeMenu;
  const icon = props.icon;
  const disabled = props.disabled;
  const selected = props.selected;
  const onClickTimeout = useRef<NodeJS.Timeout>(); // prevent double click

  const cbOnClick = (e: React.MouseEvent<HTMLLIElement>) =>
  {
    e.stopPropagation();
    e.preventDefault();
    if(onClickTimeout.current)
    {
      clearTimeout(onClickTimeout.current);
    }
    setTimeout(() =>
    {
      closeMenu();
    }, theme.common.durationTransition);

    onClickTimeout.current = setTimeout(() =>
    {
      closeMenu();
      onClick(menuName);
    }, theme.common.durationTransition);
  };

  return (
    <MenuItem
      key={menuName}
      disabled={disabled}
      onClick={cbOnClick}
      selected={selected}
    >
      {
        icon &&
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      }

      {menuName}
    </MenuItem>
  );
}
