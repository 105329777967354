import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {isNonGlobalEntId} from "../../../api/meta/base/ApiPlus";
import {GroupId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {createListItemIdSkeleton} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getListItemGroup} from "../../../base/plus/SrvcPlus";
import {getListItemEnt} from "../../../base/plus/SrvcPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {listClearItem} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinder} from "../../../base/types/list/TypesList";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {comboToAboutId} from "../../../base/types/TypesComboId";
import {TypeComboIdGroup} from "../../../base/types/TypesComboId";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {isChatNotificationMuted} from "./SrvcHomeDrawerRecentList";

export type TypeSigAvatarGroupAndEnt = SigGroupAvatar | SigEntAvatarUser | undefined;

export const newGroupItemId = createListItemIdSkeleton("newGroup");
export const newEnterpriseItemId = createListItemIdSkeleton("newEnterprise");
export const groupHeaderItemId = createListItemIdSkeleton("groupHeader");
export const entHeaderItemId = createListItemIdSkeleton("entHeader");

export function isReservedItemId(itemId: TypeListItemId): boolean
{
  return itemId === newGroupItemId
    || itemId === newEnterpriseItemId
    || itemId === groupHeaderItemId
    || itemId === entHeaderItemId;
}

export default class SrvcHomeDrawerSubscriptions extends ISrvc
{
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.onApplySourceItem.bind(this)
    } as IListBinder<TypeSigAvatarGroupAndEnt>;
  }

  load(
    listName: string,
    srcCallerComboGroupIdSet: GroupId[],
    srcEntIdAdminSet: EntId[])
  {
    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;

    this.doLoadGroups(uiItemsById, uiGroupsById, srcCallerComboGroupIdSet);
    this.doLoadEnts(uiItemsById, uiGroupsById, srcEntIdAdminSet);

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById
    } as IListData));
  }

  //region private

  private selectSourceItem(state: RootState, itemId: TypeListItemId): TypeSigAvatarGroupAndEnt
  {
    if(isReservedItemId(itemId))
    {
      return undefined;
    }
    else if(isNonGlobalEntId(itemId))
    {
      return state.cache.app.caller.entIdUserAvatarMap[itemId as EntId];
    }
    else
    {
      return state.cache.app.group.groupAvatarMap[itemId as TypeComboIdGroup];
    }
  }

  private onApplySourceItem(listName: string, itemId: TypeListItemId, avatar?: TypeSigAvatarGroupAndEnt): void
  {
    if(isReservedItemId(itemId))
    {
      return;
    }

    if(avatar)
    {
      if(isNonGlobalEntId(itemId))
      {
        dispatchList(listName, listSetItem({
          itemId: itemId,
          newItem: getListItemEnt(
            avatar as SigEntAvatarUser,
            true,
            undefined,
            isEntNotificationMuted(itemId)
          ) as IListItem
        }));
      }
      else
      {
        dispatchList(listName, listSetItem({
          itemId: itemId,
          newItem: getListItemGroup(
            avatar as SigGroupAvatar,
            false,
            undefined,
            isChatNotificationMuted(ENT_ID_GLOBAL, comboToAboutId(itemId))
          ) as IListItemAPSA
        }));
      }
    }
    else
    {
      dispatchList(listName, listClearItem(itemId));
    }
  }

  private doLoadGroups(uiItemsById: IListItemsById, uiGroupsById: IListGroupsById, srcCallerComboGroupIdSet: GroupId[])
  {
    uiItemsById[groupHeaderItemId] = {
      type: "listGroup",
      header: {
        text: "Groups",
        mt: gapHalf,
        mb: gapHalf,
        ml: gapStd
      },
      hideMenu: true,
      ignoreSelection: true
    } as IListItem;

    uiItemsById[newGroupItemId] = {
      type: "aps",
      hideMenu: true,
      avatarLeft: {
        icon: "group",
        bgcolor: theme.common.color("primary"),
        color: theme.common.color("textInverse")
      },
      primary: {
        text: "New group"
      },
      secondary: {
        text: "Create new group"
      }
    } as IListItem;

    uiGroupsById[groupHeaderItemId] = {
      itemIds: [newGroupItemId, ...srcCallerComboGroupIdSet]
    };
  }

  private doLoadEnts(uiItemsById: IListItemsById, uiGroupsById: IListGroupsById, srcEntIdAdminSet: EntId[])
  {
    const rootState = store.getState();
    const entIdUserAvatarMap = rootState.cache.app.caller.entIdUserAvatarMap;
    const groupAvatarMap = rootState.cache.app.group.groupAvatarMap;

    uiItemsById[entHeaderItemId] = {
      type: "listGroup",
      header: {
        text: "Enterprises",
        mt: gapHalf,
        mb: gapHalf,
        ml: gapStd
      },
      hideMenu: true,
      ignoreSelection: true
    } as IListItemGroup;

    uiItemsById[newEnterpriseItemId] = {
      type: "aps",
      hideMenu: true,
      avatarLeft: {
        icon: "enterprise",
        bgcolor: theme.common.color("primary"),
        color: theme.common.color("textInverse")
      },
      primary: {
        text: "New enterprise"
      },
      secondary: {
        text: "Create new enterprise"
      }
    } as IListItem;

    srcEntIdAdminSet.forEach(entId =>
    {
      if(!isReservedItemId(entId))
      {
        if(isNonGlobalEntId(entId))
        {
          const avatar = entIdUserAvatarMap[entId];

          uiItemsById[entId] = getListItemEnt(
            avatar,
            true,
            undefined,
            isEntNotificationMuted(entId)
          ) as IListItem;
        }
        else
        {
          const avatar = groupAvatarMap[entId];

          uiItemsById[entId] = getListItemGroup(
            avatar as SigGroupAvatar,
            false,
            undefined,
            isChatNotificationMuted(ENT_ID_GLOBAL, comboToAboutId(entId))
          ) as IListItemAPSA;
        }
      }
    });

    uiGroupsById[entHeaderItemId] = {
      itemIds: [newEnterpriseItemId, ...srcEntIdAdminSet]
    };
  }

  //endregion
}

export function isEntNotificationMuted(entId: EntId)
{
  const rootState = store.getState();
  const chatNotificationSettingMap = rootState.cache.app.caller.chatNotificationSettingMap;
  const entChatNotificationSetting = chatNotificationSettingMap && chatNotificationSettingMap[entId];
  const notificationSetting = entChatNotificationSetting?.entNotificationSetting;

  return Boolean(notificationSetting?.mute);
}
