import {useMemo} from "react";
import {useCallback} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {isGridId} from "../../api/meta/base/ApiPlus";
import {isSectionId} from "../../api/meta/base/ApiPlus";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioPickActionId} from "../../api/meta/base/dto/DefnStudioPickActionId";
import {DefnStudioPickCompId} from "../../api/meta/base/dto/DefnStudioPickCompId";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickGroupId} from "../../api/meta/base/dto/DefnStudioPickGroupId";
import {DefnStudioPickVarId} from "../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioBuildArgBinder} from "../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioDtoArgValueConstant} from "../../api/meta/base/dto/StudioDtoArgValueConstant";
import {StudioDtoArgValueDerived} from "../../api/meta/base/dto/StudioDtoArgValueDerived";
import {StudioDtoArgValueField} from "../../api/meta/base/dto/StudioDtoArgValueField";
import {StudioVisibilityAction} from "../../api/meta/base/dto/StudioVisibilityAction";
import {EnumDefnKindAction} from "../../api/meta/base/Types";
import {MetaIdAction} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {EnumDefnVisibilityActionOn} from "../../api/meta/base/Types";
import {EnumDefnVisibilityAction} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {getValidFormMenuActionIdSet} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {fnUseStudioResolver} from "../../base/plus/StudioPlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {fieldGap1} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldName = "name";
const fieldKeyVisibilityAction = "visibilityAction";
const fieldKeyVisibilityActionFieldId = "compId";
const fieldKeyVisibilityActionCompId = "visibilityActionCompId";
const fieldKeyVisibilityActionOn = "visibilityActionOn";
const fieldKeyVisibilitySource = "source";
const fieldKeyAction = "action";
const fieldMappingVarId = "mappingVarId";
const fieldGroupIdSet = "groupIdSet";

const contentHeight = 480;

export default function DialogNewFormVisibilityAction(props: {
  formStore?: FormStore,
  formId?: MetaIdForm,
  onClickOk: (value: StudioVisibilityAction) => void,
  values?: StudioVisibilityAction,
  isFormReadOnly?: boolean,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const onClickSubmit = props.onClickOk;
  const values = props.values;
  const onClose = props.onClose;
  const formId = props.formId;
  const formStore = props.formStore;
  const formMap = formStore?.formMap;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);
  const actionMap = formStore?.actionMap;

  const getFormField = formStore ? fnUseStudioResolver(formStore).getFormField : undefined;

  const form = useMemo(() => formId
    ? formMap?.map[formId]
    : undefined, [formId, formMap?.map]);

  const includeActionIdSet = useMemo(() => formStore
    ? getValidFormMenuActionIdSet(formStore, form?.metaId)
    : undefined, [form?.metaId, formStore]);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const [visibilityActionKind, setVisibilityActionKind] =
    useState<EnumDefnVisibilityAction | undefined>(values?.visibilityAction);

  const [visibilityActionOn, setVisibilityActionOn] =
    useState<EnumDefnVisibilityActionOn | undefined>(values?.visibilityActionOn ?? "field");

  const [srcFieldType, setSrcFieldType] = useState<EnumDefnCompType>();
  const [actionKind, setActionKind] = useState<EnumDefnKindAction>();

  const onWatch = useCallback((key: string, value: any) =>
  {
    if(key === fieldKeyVisibilityActionOn)
    {
      setVisibilityActionOn(value || undefined);
    }
    else if(key === fieldKeyVisibilityAction)
    {
      const actionKind = fnFieldValueToRawValue("enumVisibilityAction", value) as EnumDefnVisibilityAction;

      if(actionKind !== visibilityActionKind &&
        (actionKind === "setValue"
          || actionKind === "click"
          || actionKind === "executeAction"))
      {
        cbRef.current.setValues && cbRef.current.setValues({
          [fieldKeyVisibilityActionFieldId]: null,
          [fieldKeyVisibilitySource]: null
        });
      }

      setVisibilityActionKind(actionKind);
    }
    else if(key === fieldKeyVisibilitySource)
    {
      const argValue = fnFieldValueToRawValueArgBinder(value) as StudioBuildArgBinder | undefined;
      const srcFieldId = argValue
        ? argValue.kind === "field"
          ? (argValue.value as StudioDtoArgValueField).fieldId
          : argValue.kind === "derived"
            ? (argValue.value as StudioDtoArgValueDerived).derivedFieldId
            : undefined
        : undefined;
      const srcFieldType = (srcFieldId && formId && getFormField)
        ? getFormField(formId, srcFieldId)?.field?.type
        : argValue && argValue.kind === "constant"
          ? (argValue.value as StudioDtoArgValueConstant).type
          : undefined;

      setSrcFieldType(srcFieldType);
    }
    if(key === fieldKeyAction)
    {
      const action = value
        ? actionMap?.map[value]
        : undefined;

      setActionKind(action?.kind);

      if(action?.kind !== "rowInsert")
      {
        if(action?.kind !== "report")
        {
          cbRef.current.setValue && cbRef.current.setValue(fieldGroupIdSet, null);
        }
        cbRef.current.setValue && cbRef.current.setValue(fieldMappingVarId, null);
      }
    }

  }, [actionMap?.map, formId, getFormField]);

  useEffect(() =>
  {
    if(isFieldCompDisable(visibilityActionKind))
    {
      cbRef.current.resetField && cbRef.current.resetField(fieldKeyVisibilityActionCompId);
    }
  }, [visibilityActionKind]);

  useEffect(() =>
  {
    if(values)
    {
      const fieldValues = dtoToValue(values);
      fieldValues && Object.entries(fieldValues).forEach(([key, value]) =>
      {
        onWatch(key, value);
      });
    }
  }, [values]);

  return (
    <DialogDefnForm
      contentHeight={contentHeight}
      title={`${values ? "Update" : "New"} form visibility action`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(
          formId,
          visibilityActionKind,
          visibilityActionOn,
          srcFieldType,
          includeActionIdSet,
          actionKind
        ),
        initValues: dtoToValue(values),
        formReadonly: props.isFormReadOnly,
        onSubmit: values => onClickSubmit(valueToDto(
          values,
          visibilityActionOn,
          visibilityActionKind
        )),
        store: formStore,
        onWatch: onWatch
      }}
      addMoreCheckBoxLabel={!values
        ? "Add more form visibility action"
        : undefined}
      onClose={onClose}
    />
  );
}

function valueToDto(
  values: FieldValues,
  visibilityActionKindOn?: EnumDefnVisibilityActionOn,
  visibilityActionKind?: EnumDefnVisibilityAction
)
{
  return {
    visibilityAction: fnFieldValueToRawValue("enumVisibilityAction", values[fieldKeyVisibilityAction]),
    visibilityActionOn: fnFieldValueToRawValue("enumVisibilityActionOn", values[fieldKeyVisibilityActionOn]),
    actionId: fnFieldValueToRawValue("pickActionId", values[fieldKeyAction]),
    mappingVarId: fnFieldValueToRawValue("pickVarId", values[fieldMappingVarId]),
    name: fnFieldValueToRawValue("symbol", values[fieldName]),
    ...visibilityActionKindOn === "field"
    && {
      compId: fnFieldValueToRawValue("pickFieldId", values[fieldKeyVisibilityActionFieldId])
    },
    ...visibilityActionKindOn === "component"
    && {
      compId: fnFieldValueToRawValue("pickCompId", values[fieldKeyVisibilityActionCompId])
    },
    groupIdSet: fnFieldValueToRawValue("studioSetOfGroupId", values[fieldGroupIdSet]),
    ...visibilityActionKind === "setValue" && {
      source: fnFieldValueToRawValueArgBinder(values[fieldKeyVisibilitySource])
    } as StudioVisibilityAction
  } as StudioVisibilityAction;
}

function dtoToValue(dto?: StudioVisibilityAction)
{
  if(!dto)
  {
    return {
      [fieldKeyVisibilityActionOn]: fnRawValueToFieldValue("enumVisibilityActionOn", "field")
    };
  }

  if(dto)
  {
    const isCompId = isSectionId(dto.compId) || isGridId(dto.compId);
    const visibilityAction = fnRawValueToFieldValue("enumVisibilityAction",
      dto.visibilityAction
    ) as EnumDefnVisibilityAction | undefined;

    return {
      [fieldName]: fnRawValueToFieldValue("symbol", dto.name),
      [fieldKeyVisibilityAction]: visibilityAction,
      [fieldKeyVisibilityActionOn]: fnRawValueToFieldValue("enumVisibilityActionOn", dto.visibilityActionOn ?? "field"),
      [fieldKeyAction]: fnRawValueToFieldValue("pickActionId", dto.actionId),
      [fieldMappingVarId]: fnRawValueToFieldValue("pickVarId", dto?.mappingVarId),
      ...isCompId
        ? {
          [fieldKeyVisibilityActionCompId]: fnRawValueToFieldValue("pickCompId", dto.compId)
        }
        : {
          [fieldKeyVisibilityActionFieldId]: fnRawValueToFieldValue("pickFieldId", dto.compId)
        },
      [fieldGroupIdSet]: fnRawValueToFieldValue("studioSetOfGroupId", dto?.groupIdSet),
      ...visibilityAction === "setValue" && {
        ...fnRawValueToFieldValueArgBinder(fieldKeyVisibilitySource, dto.source)
      }
    };
  }
}

function getDefnForm(
  formId?: MetaIdForm,
  visibilityActionKind?: EnumDefnVisibilityAction,
  visibilityActionOn?: EnumDefnVisibilityActionOn,
  srcFieldType?: EnumDefnCompType,
  includeActionIdSet?: MetaIdAction[],
  actionKind?: EnumDefnKindAction
)
{
  const defnVisibilityActionMap = getDefnVisibilityActionMap(
    formId,
    visibilityActionKind,
    visibilityActionOn,
    srcFieldType,
    includeActionIdSet,
    actionKind
  );

  return createDefaultDefnFormStudio({

    [fieldName]: {
      type: "symbol",
      name: fieldName,
      label: "Name",
      metaId: fieldName,
      required: true
    } as DefnFieldText,

    [fieldKeyVisibilityAction]: {
      type: "enumVisibilityAction",
      name: fieldKeyVisibilityAction,
      label: "Visibility action",
      metaId: fieldKeyVisibilityAction,
      required: true
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap1, "thick"),

    ...defnVisibilityActionMap,

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: [
        fieldName,
        fieldKeyVisibilityAction,
        fieldGap1,
        ...Object.keys(defnVisibilityActionMap)
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}

function getDefnVisibilityActionMap(
  formId?: MetaIdForm,
  visibilityActionKind?: EnumDefnVisibilityAction,
  visibilityActionOn?: EnumDefnVisibilityActionOn,
  srcFieldType?: EnumDefnCompType,
  includeActionIdSet?: MetaIdAction[],
  actionKind?: EnumDefnKindAction
)
{
  const isRowInsertOrReport = actionKind === "rowInsert" || actionKind === "report";

  return {

    ...(visibilityActionKind !== "executeAction") && {
      [fieldKeyVisibilityActionOn]: {
        type: "enumVisibilityActionOn",
        name: fieldKeyVisibilityActionOn,
        label: "Visibility action on",
        metaId: fieldKeyVisibilityActionOn,
        filterOptionSet: filterVisibilityActionOnOptionSet(visibilityActionKind)
      } as DefnFieldPickEnum
    },

    ...(visibilityActionKind !== "executeAction" && visibilityActionOn === "component") && {
      [fieldKeyVisibilityActionCompId]: {
        type: "pickCompId",
        name: fieldKeyVisibilityActionCompId,
        metaId: fieldKeyVisibilityActionCompId,
        label: "Section or grid",
        formId: formId,
        required: true,
        disabled: isFieldCompDisable(visibilityActionKind)
      } as DefnStudioPickCompId
    },

    ...visibilityActionKind === "setValue" && {
      [fieldKeyVisibilitySource]: {
        type: "studioBuildArgBinder",
        metaId: fieldKeyVisibilitySource,
        name: fieldKeyVisibilitySource,
        label: "Source field",
        formId: formId,
        derivedFormId: formId,
        required: true
      } as DefnStudioBuildArgBinder
    },

    ...(visibilityActionKind !== "executeAction" && visibilityActionOn === "field") && {
      [fieldKeyVisibilityActionFieldId]: {
        type: "pickFieldId",
        name: fieldKeyVisibilityActionFieldId,
        label: visibilityActionKind === "setValue"
          ? "Target field"
          : "Field",
        metaId: fieldKeyVisibilityActionFieldId,
        formId: formId,
        required: true,
        filterFieldTypeSet: visibilityActionKind === "click"
          ? ["button"]
          : srcFieldType
            ? [srcFieldType]
            : undefined,
        showCompositeName: true
      } as DefnStudioPickFieldId
    },

    ...visibilityActionKind === "executeAction" && {
      [fieldKeyAction]: {
        type: "pickActionId",
        name: fieldKeyAction,
        metaId: fieldKeyAction,
        required: true,
        filterActionKindSet: ["report", "spreadsheetEditor", "rowInsert"],
        includeActionIdSet: includeActionIdSet
      } as DefnStudioPickActionId
    },

    ...actionKind && {
      [fieldMappingVarId]: {
        type: "pickVarId",
        metaId: fieldMappingVarId,
        name: fieldMappingVarId,
        label: "Mapping variable",
        varKind: "mapping"
      } as DefnStudioPickVarId
    },

    ...isRowInsertOrReport && {
      [fieldGroupIdSet]: {
        type: "studioSetOfGroupId",
        metaId: fieldGroupIdSet,
        name: fieldGroupIdSet,
        label: "Groups"
      } as DefnStudioPickGroupId
    }
  };
}

function filterVisibilityActionOnOptionSet(visibilityActionKind?: EnumDefnVisibilityAction): EnumDefnVisibilityActionOn[] | undefined
{
  switch(visibilityActionKind)
  {
    case "clear":
    case "setValue":
    case "highlight":
    case "blink":
    case "shake":
      return ["field"];
    case "click":
      return ["field", "sendButton"];
    case "invisible":
    case "disable":
    case "enable":
    case "executeAction":
    case "hidden":
    case "visible":
      return undefined;
  }
}

function isFieldCompDisable(visibilityActionKind?: EnumDefnVisibilityAction)
{
  return !(visibilityActionKind === "visible"
    || visibilityActionKind === "hidden"
    || visibilityActionKind === "invisible"
    || visibilityActionKind === "disable"
    || visibilityActionKind === "enable"
    || visibilityActionKind === "clear");
}
