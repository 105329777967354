import {useTheme} from "@mui/material";
import {isEmpty} from "lodash";
import {useEffect} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {DefnLayoutGrid} from "../../api/meta/base/dto/DefnLayoutGrid";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {getLabel} from "../../base/plus/StringPlus";
import {px} from "../../base/plus/StringPlus";
import {gapHalf} from "../../base/plus/ThemePlus";
import {EnumIconStrip} from "../../base/types/TypesIcon";
import {useDeviceType} from "../app/AppHooks";
import {DividerHorizontal} from "../atom/layout/DividerHorizontal";
import LayoutFlexRow from "../atom/layout/LayoutFlexRow";
import RawIconStrip from "../atom/raw/RawIconStrip";
import {IMenuProps} from "../atom/raw/RawMenu";
import {IRawSearchRef} from "../atom/raw/RawSearch";
import {SEARCH_BOX_HEIGHT} from "../atom/raw/RawSearch";
import RawSearch from "../atom/raw/RawSearch";
import {usePageCtx} from "../ctx/CtxPage";
import {useSsBrCtx} from "./base/CtxSsBr";

export default function SsBrSearch(props: {
  onSearch: (str: string) => void,
  allowToSwitchLayoutMap?: Record<MetaIdLayoutGrid, DefnLayoutGrid>,
  showSearchBar?: boolean,
  onSwitchLayout?: (layoutId: MetaIdLayoutGrid) => void,
  showSorting?: boolean,
  onSortByFieldId?: (menuAnchor: Element) => void
})
{
  const searchRef = {} as IRawSearchRef;
  const spreadSheetBrCtx = useSsBrCtx();
  const deviceType = useDeviceType();
  const pageCtx = usePageCtx();
  const theme = useTheme();

  const allowToSwitchLayoutMap = props.allowToSwitchLayoutMap;
  const onSwitchLayout = props.onSwitchLayout;
  const onSortByFieldId = props.onSortByFieldId;
  const showSorting = props.showSorting;

  const isFilters = useMemo(() => Boolean(spreadSheetBrCtx.getFilters()?.length), [spreadSheetBrCtx]);
  const searchState = useMemo(() => spreadSheetBrCtx.getSearchState(), [spreadSheetBrCtx]);
  const isFilterOverlayOpen = useMemo(() => spreadSheetBrCtx.getFilterOverlay(), [spreadSheetBrCtx]);

  const iconStrip: EnumIconStrip[] = useMemo(() =>
  {
    const icons = [] as EnumIconStrip[];

    if(showSorting)
    {
      icons.push("sortBy");
    }

    if(deviceType === "mobile" && isFilters)
    {
      if(!isFilterOverlayOpen)
      {
        icons.push("filter");
      }
      else
      {
        icons.push("filterClear");
      }
    }

    if(!isEmpty(allowToSwitchLayoutMap))
    {
      icons.push("more");
    }

    return icons;
  }, [deviceType, isFilters, allowToSwitchLayoutMap, isFilterOverlayOpen, showSorting]);

  const fnChangeLayout = useCallback((layout: DefnLayoutGrid) =>
  {
    const layoutId = layout.metaId;
    onSwitchLayout && onSwitchLayout(layoutId);
  }, [onSwitchLayout]);

  const onClickLayout = useCallback((menuAnchor: Element) =>
  {
    if(isEmpty(allowToSwitchLayoutMap))
    {
      return;
    }

    const menuProps: IMenuProps = {};
    const allowToSwitchLayoutIdSet = Object.values(allowToSwitchLayoutMap) as DefnLayoutGrid[];
    allowToSwitchLayoutIdSet.forEach(layout =>
    {
      if(layout && (layout.kind === "list" || layout.kind === "card"))
      {
        const layoutLabel = getLabel(layout);
        menuProps[layoutLabel] = {
          onClick: () => fnChangeLayout(layout)
        };
      }
    });

    if(Object.keys(menuProps).length)
    {
      pageCtx.showMenu(menuAnchor, menuProps);
    }
  }, [allowToSwitchLayoutMap, fnChangeLayout, pageCtx]);

  useEffect(() =>
  {
    searchRef.setShowProgress && searchRef.setShowProgress(searchState);
  }, [searchState]);

  if(!props.showSearchBar && isEmpty(allowToSwitchLayoutMap))
  {
    return null;
  }

  return (
    <>
      <LayoutFlexRow
        width={"100%"}
        bgcolor={theme.common.bgcolorSidePane}
        height={px(SEARCH_BOX_HEIGHT)}
      >
        <LayoutFlexRow flexGrow={1}>
          {
            props.showSearchBar &&
            <RawSearch
              cbRef={searchRef}
              placeHolder={"Search"}
              onSearch={props.onSearch}
            />
          }
        </LayoutFlexRow>

        {
          (!isEmpty(allowToSwitchLayoutMap) || showSorting) &&
          <LayoutFlexRow
            height={"100%"}
            mr={iconStrip.length ? px(gapHalf) : undefined}
          >
            <RawIconStrip
              iconStrip={iconStrip}
              toolTipMap={{
                more: "Switch layout"
              } as Record<EnumIconStrip, string>}
              onClick={(icon, menuAnchor) =>
              {
                switch(icon)
                {
                  case "sortBy":
                    onSortByFieldId && onSortByFieldId(menuAnchor);
                    break;
                  case "more":
                    onClickLayout(menuAnchor);
                    break;
                  case "filter":
                    spreadSheetBrCtx.setFilterOverlay({filterOverlay: true});
                    break;
                  case "filterClear":
                    spreadSheetBrCtx.setFilterOverlay({filterOverlay: false});
                    break;
                }
              }}
            />
          </LayoutFlexRow>
        }
      </LayoutFlexRow>
      <DividerHorizontal />
    </>
  );
}
