import {useTheme} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import axios from "axios";
import {useState} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldValueDocument} from "../../../../api/meta/base/dto/FieldValueDocument";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import {logError} from "../../../../base/util/AppLog";
import {usePageCtx} from "../../../ctx/CtxPage";
import GridCellDefault from "./GridCellDefault";

export default function GridCellDocument(props: ICellRendererParams<IDataGridCell>)
{
  const theme = useTheme();
  const pageCtx = usePageCtx();
  const [downloadStatus, setDownloadStatus] = useState<boolean>(false);

  const onClick = useCallback(() =>
  {
    if(downloadStatus)
    {
      pageCtx.showInfoToast("Document is getting ready...");
      return;
    }

    setDownloadStatus(true);
    pageCtx.showInfoToast("Document is getting ready...");
    const colId = props.colDef?.colId;
    const fieldValueDocument = colId
      ? (props.data?.valueMap[colId] as FieldValueDocument | undefined)
      : undefined;
    const src = getMediaSrc(fieldValueDocument?.value.mediaIdDocument);
    if(src)
    {
      axios.get(src, {
        responseType: "blob"
      })
      .then((response) =>
      {
        pageCtx.showSuccessToast("Document generated successfully");
        setDownloadStatus(false);
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((err) =>
      {
        setDownloadStatus(false);
        logError("downloadMedia error", err);
        pageCtx.showErrorToast("Document cannot be generated");
      });
    }
  }, [props.colDef?.colId, props.data?.valueMap, downloadStatus]);

  return <GridCellDefault
    {...props}
    color={theme.palette.primary.main}
    onClick={onClick}
  />;
}
