import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoColor} from "../../../../api/meta/base/dto/DefnDtoColor";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {EnumArrayDefnPaymentStatus} from "../../../../api/meta/base/Types";
import {EnumDefnPaymentStatus} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPaymentStatus(props: {
  defn: DefnFieldEditable
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;

  const fieldId = getFieldKey(defn);

  const mapOfOption = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  const paymentOptionColorMap = {
    failed: {value: theme.common.paymentStatusOptionFailed, shade: "s500"},
    paid: {value: theme.common.paymentStatusOptionPaid, shade: "s500"},
    pending: {value: theme.common.paymentStatusOptionPending, shade: "s500"}
  } as Record<EnumDefnPaymentStatus, DefnDtoColor>;

  (EnumArrayDefnPaymentStatus as EnumDefnPaymentStatus[]).forEach(option =>
  {
    const value = toLabel(option);

    mapOfOption.keys.push(option);
    mapOfOption.map[option] = {
      metaId: option,
      value: value,
      color: paymentOptionColorMap[option]
    };
  });

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => (
        <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={props.defn}
          optionMap={mapOfOption}
        />
      )}
    />
  );
}
