import {cloneDeep} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {SigUserAvatar} from "../../../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnLayoutCard} from "../../../../api/meta/base/dto/DefnLayoutCard";
import {FieldSetOfEntUserId} from "../../../../api/meta/base/dto/FieldSetOfEntUserId";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {EntUserId} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../../base/plus/FieldValuePlus";
import {isUserDefnField} from "../../../../base/plus/FormPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {textUser} from "../../../../base/plus/SrvcPlus";
import {random} from "../../../../base/plus/StringPlus";
import {listClearPickItemIds} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetPickType} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetSearch} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetLayoutType} from "../../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListBinderThree} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {toComboId} from "../../../../base/types/TypesComboId";
import {TypeComboIdUser} from "../../../../base/types/TypesComboId";
import {ICacheSsEditorRow} from "../../../../cache/app/spreadsheet/ssEditor/TypesCacheSpreadsheetEditor";
import {loopLayoutItemLine} from "../../../../nucleus/form/viewer/base/FormViewerPlus";
import {getListItemLayout} from "../../../../nucleus/form/viewer/base/FormViewerPlus";
import {fnGetLayoutItemListOrCard} from "../../../../routes/app/refBr/base/UiRefBrPlus";
import {store} from "../../../../Store";
import {RootState} from "../../../../Store";
import {Srvc} from "../../../Srvc";
import {selectCacheRow} from "../SrvcSpreadsheet";
import {TypeUserFieldSsEditorItem} from "./SrvcSsEditor";
import {TypeUserFieldSsEditor} from "./SrvcSsEditor";

export interface TypeUserFieldSsEditorList extends TypeUserFieldSsEditor
{
  layout?: DefnLayoutCard,
  showMenu?: boolean,
  fieldUserIds?: MetaIdField[] // valueMap user fields
}

export default class SrvcSsEditorList extends ISrvc
{
  subscriberId = "SrvcSsEditorList";

  constructor(readonly selectList: SelectList)
  {
    super();
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSsRow.bind(this),
      onBindSourceItem1: this.onBindSsRow.bind(this),

      selectSourceItem2: this.selectSsRowId.bind(this),
      onBindSourceItem2: this.onBindSsRowId.bind(this),

      selectSourceItem3: this.selectSsUserAvatarMap.bind(this),
      onBindSourceItem3: this.onBindSsUserAvatarMap.bind(this)

    } as IListBinderThree<
      SigSpreadsheetRow,
      ICacheSsEditorRow,
      Record<TypeComboIdUser, SigUserAvatar> | undefined>;
  }

  load(
    listName: string,
    rowIdList: RowId[],
    entId: EntId,
    spreadsheetId?: MetaIdSpreadsheet,
    layout?: DefnLayoutCard,
    showMenu?: boolean,
    defnForm?: DefnForm,
    searchText?: string,
    isSelectionEnable?: boolean
  )
  {
    const searchWords = [] as string[];
    const itemsIds = rowIdList as RowId[];
    const itemsById = {} as IListItemsById;

    const layoutItem = fnGetLayoutItemListOrCard(layout);
    const fieldUserIds: MetaIdField[] = [];
    if(layoutItem)
    {
      loopLayoutItemLine(layoutItem, (line) =>
      {
        line.segment.lineFieldIdSet?.forEach(metaIdField =>
        {
          const comp = defnForm?.compMap[metaIdField] as DefnField;
          if(comp)
          {
            if((["pickUser", "setOfUser", "userId", "refUser"] as EnumDefnCompType[]).includes(comp.type))
            {
              fieldUserIds.push(metaIdField);
            }
          }
        });
      });
    }

    if(layoutItem)
    {
      const rootState = store.getState();
      const list = this.selectList(rootState);
      searchWords.push(...(list.searchWords || []));
      rowIdList.forEach(rowId =>
      {
        const source = selectCacheRow(rootState, entId, rowId);

        itemsById[rowId] = this.loadListItem(rootState, source);
      });
    }

    dispatchList(listName, listRefresh({
      itemIds: itemsIds,
      itemsById: itemsById,
      version: random(),
      searchWords: searchWords,
      userField: {
        defnForm: defnForm,
        entId: entId,
        showMenu: showMenu,
        layout: layout,
        spreadsheetId: spreadsheetId,
        fieldUserIds: fieldUserIds
      } as TypeUserFieldSsEditorList
    }));

    dispatchList(listName, listSetLayoutType({
      listLayoutType: layout?.kind === "card" ? "card" : "list"
    }));

    dispatchList(listName, listSetSearch(searchText));

    if(isSelectionEnable)
    {
      dispatchList(listName, listSetPickType("pickMany"));
    }
  }

  pickAllOrClearListItem(
    listName: string,
    rowIdList: RowId[],
    unSelect?: boolean
  )
  {
    if(unSelect)
    {
      rowIdList.forEach(rowId =>
      {
        dispatchList(listName,
          listSetPick({
            itemId: rowId,
            pickValue: true
          })
        );
      });
    }
    else
    {
      dispatchList(listName, listClearPickItemIds());
    }
  }

  private loadListItem(
    rootState: RootState,
    sigSpreadsheetRow?: SigSpreadsheetRow): IListItemMPSL
  {
    const userField = this.selectUserField(rootState);
    const layout = userField?.layout as DefnLayoutCard;
    const compMap = userField?.defnForm?.compMap;
    const showMenu = userField?.showMenu as boolean;
    const tooltipFieldId = layout?.toolTipFieldId;
    const entId = userField?.entId;
    const spreadsheetId = userField?.spreadsheetId;

    const hideMenu = !showMenu ||
      ((entId && spreadsheetId)
        ? Srvc.app.spreadsheet.SsEditor.hideMenu(rootState,
          entId,
          spreadsheetId,
          sigSpreadsheetRow?.formValue?.createdBy
        )
        : undefined);

    const tooltipField = tooltipFieldId && compMap
      ? compMap[tooltipFieldId] as DefnFieldEditable
      : undefined;

    const valueMap = sigSpreadsheetRow?.formValue?.valueMap;
    const tooltipValue = tooltipField && valueMap
      ? getFormFieldValueAsTextWithPrefixSuffix(tooltipField, valueMap[tooltipField.metaId])
      : undefined;

    const itemMPSL = compMap
      ? getListItemLayout(compMap, layout, sigSpreadsheetRow?.formValue, entId)
      : undefined;

    return {
      ...itemMPSL,
      tooltip: tooltipValue,
      userField: {
        sigSpreadsheetRow: sigSpreadsheetRow
      } as TypeUserFieldSsEditorItem,
      hideMenu: hideMenu
    } as IListItemMPSL;
  }

  private insertAvatarInFormField(
    rootState: RootState,
    entId: EntId,
    defnForm: DefnForm,
    fieldUserIds: MetaIdField[],
    valueMap?: FieldValues)
  {
    fieldUserIds.forEach(metaIdField =>
    {
      const field = defnForm?.compMap[metaIdField] as DefnField;
      if(field)
      {
        this.insertEntUserNameInFormField(rootState, entId, field, valueMap);
      }
    });
  }

  private insertEntUserNameInFormField(rootState: RootState, entId: EntId, field: DefnField, valueMap?: FieldValues)
  {
    if(valueMap && isUserDefnField(field.type))
    {
      switch(field.type)
      {
        case "pickUser":
        case "userId":
        case "refUser":
          const fieldValueUser = valueMap?.[field.metaId] as FieldValueEntUserId | undefined;
          const userId = fieldValueUser?.value;
          if(userId)
          {
            const avatar = this.selectUserAvatar(rootState, entId as EntId, userId);
            if(avatar)
            {
              valueMap[field.metaId] = {
                value: userId,
                displayField: textUser(avatar)
              } as FieldValueEntUserId;
            }
            else
            {
              Srvc.app.spreadsheet.subscribeSsUser(this.subscriberId, entId, userId);
            }
          }
          break;
        case "setOfUser":
          const fieldValueSetOfUser = valueMap?.[field.metaId] as FieldSetOfEntUserId | undefined;
          if(fieldValueSetOfUser?.valueSet)
          {
            valueMap[field.metaId] = {
              ...fieldValueSetOfUser,
              displaySet: fieldValueSetOfUser.valueSet.map(userId =>
              {
                const avatar = this.selectUserAvatar(rootState, entId as EntId, userId);
                if(avatar)
                {
                  return textUser(avatar);
                }
                else
                {
                  Srvc.app.spreadsheet.subscribeSsUser(this.subscriberId, entId, userId);
                  return "";
                }
              })
            } as FieldSetOfEntUserId;
          }
          break;
      }
    }
  }

  private selectUserAvatar(rootState: RootState, entId: EntId, entUserId: EntUserId): SigUserAvatar | undefined
  {
    const comboUserId = toComboId(entId, entUserId);
    return rootState.cache.app.user.userAvatarMap[comboUserId];
  }

  private selectSsUserAvatarMap(
    rootState: RootState,
    _: TypeListItemId): Record<TypeComboIdUser, SigUserAvatar> | undefined
  {
    return rootState.cache.app.user.userAvatarMap;
  }

  private onBindSsUserAvatarMap(
    listName: string,
    rowId: TypeListItemId,
    avatarMap?: Record<TypeComboIdUser, SigUserAvatar>): void
  {
    if(avatarMap)
    {
      const rootState = store.getState();
      const sigSpreadsheetRow = this.selectItemUserField(rootState, rowId)?.sigSpreadsheetRow;
      const cloneSource = cloneDeep(sigSpreadsheetRow);

      this.onBindSsRow(listName, rowId, cloneSource);
    }
  }

  private selectSsRow(rootState: RootState, rowId: TypeListItemId)
  {
    const entId = this.selectUserField(rootState)?.entId;
    if(entId)
    {
      return selectCacheRow(rootState, entId, rowId);
    }
  }

  private onBindSsRow(listName: string, rowId: TypeListItemId, sigSpreadsheetRow?: SigSpreadsheetRow)
  {
    const rootState = store.getState();
    const expired = this.selectSsRowId(rootState, rowId);
    if(expired?.error)
    {
      return;
    }

    const entId = this.selectUserField(rootState)?.entId;
    const defnForm = this.selectUserField(rootState)?.defnForm;
    const fieldUserIds = this.selectUserField(rootState)?.fieldUserIds;
    const cloneSource = cloneDeep(sigSpreadsheetRow);
    if(entId && defnForm && fieldUserIds)
    {
      this.insertAvatarInFormField(rootState, entId, defnForm, fieldUserIds, cloneSource?.formValue?.valueMap);
    }

    const listItem = this.loadListItem(rootState, cloneSource);
    if(listItem)
    {
      dispatchList(listName, listSetItem({
        itemId: rowId,
        newItem: listItem
      }));
    }
  }

  private selectSsRowId(
    rootState: RootState,
    rowId: TypeListItemId): ICacheSsEditorRow | undefined
  {
    const spreadsheetId = this.selectUserField(rootState)?.spreadsheetId;
    if(spreadsheetId)
    {
      return rootState.cache.app.spreadsheet.ssEditor.ssStateMap[spreadsheetId]?.rowIdMap[rowId];
    }
  }

  private onBindSsRowId(listName: string, rowId: TypeListItemId, source?: ICacheSsEditorRow)
  {
    const rootState = store.getState();
    const userField = this.selectUserField(rootState);
    const entId = userField?.entId;
    const spreadsheetId = userField?.spreadsheetId;
    const sigSpreadsheetRow = this.selectItemUserField(rootState, rowId)?.sigSpreadsheetRow;
    const showMenu = userField?.showMenu;
    const hideMenu = !showMenu ||
      (entId && spreadsheetId
        ? Srvc.app.spreadsheet.SsEditor.hideMenu(rootState,
          entId,
          spreadsheetId,
          sigSpreadsheetRow?.formValue?.createdBy,
          Boolean(source?.error)
        )
        : undefined);

    if(source?.error)
    {
      const newItem = {
        type: "p",
        primary: {
          text: source.error,
          color: "textDisabled"
        },
        userField: {
          expired: true
        } as TypeUserFieldSsEditorItem,
        hideMenu: hideMenu
      } as IListItemMPSL;
      dispatchList(listName, listSetItem({
        itemId: rowId,
        newItem: newItem
      }));
    }
  }

  private selectUserField(rootState: RootState): TypeUserFieldSsEditorList | undefined
  {
    return this.selectList(rootState)?.userField as TypeUserFieldSsEditorList;
  }

  private selectItemUserField(
    rootState: RootState,
    itemId: TypeListItemId): TypeUserFieldSsEditorItem | undefined
  {
    return this.selectList(rootState).itemsById[itemId]?.userField as TypeUserFieldSsEditorItem;
  }
}
