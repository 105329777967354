import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {createListItemIdSkeleton} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getListItemEnt} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {listSetIfExistSecondaryLineBadge} from "../../../base/slices/list/SliceListAPSAActions";
import {listClearItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetVersion} from "../../../base/slices/list/SliceListSharedActions";
import {listClearPickItemIds} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListBinderTwo} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {createListItemGapHalf} from "../../../base/types/list/TypesListGap";
import {myWorldItemId} from "../../../base/types/TypesDrawer";
import {clearHomeFilter} from "../../../cache/home/drawer/filter/SliceCacheHomeDrawerFilter";
import {updateHomeFilter} from "../../../cache/home/drawer/filter/SliceCacheHomeDrawerFilter";
import {store} from "../../../Store";
import {RootState} from "../../../Store";

const gapItemId = createListItemIdSkeleton("gapItemId");
type TypeSigAvatarEnt = SigEntAvatarUser | undefined;
export type FnAcceptFilter = (filter?: EntId[]) => void;

export default class SrvcHomeDrawerFilter extends ISrvc
{
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSigAvatarEnt.bind(this),
      onBindSourceItem1: this.onBindSigAvatarEnt.bind(this),

      selectSourceItem2: this.selectBadge.bind(this),
      onBindSourceItem2: this.onBindBadge.bind(this)
    } as IListBinderTwo<TypeSigAvatarEnt, number>;
  }

  load(listName: string, srcEntIdSet: EntId[])
  {
    const listData = this.createListData(srcEntIdSet);

    Promise
    .resolve(dispatchList(listName, listRefresh(listData)))
    .then(() =>
    {
      this.onPostLoad(listName, store.getState());
      dispatchList(listName, listSetVersion(random()));
    });
  }

  setFilter(listName: string, itemId: string, pickValue: boolean)
  {
    dispatchList(listName, listSetSelectedItemId(undefined));

    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    store.dispatch(updateHomeFilter({
      callerIdHash: callerIdHash,
      entId: itemId,
      pickValue: pickValue
    }));
  }

  clearFilter(listName: string)
  {
    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    Promise
    .resolve(dispatchList(listName, listClearPickItemIds()))
    .then(() =>
    {
      store.dispatch(clearHomeFilter(callerIdHash));
    });
  }

  isReservedItemId(itemId: TypeListItemId): boolean
  {
    return itemId === myWorldItemId || itemId === gapItemId;
  }

  filter(fn: FnAcceptFilter)
  {
    fn(store.getState().cache.home.drawer.filter.entIdSet);
  }

  protected createListData(srcEntIdNotAdminSet: EntId[]): IListData
  {
    const rootState = store.getState();
    const entIdUserAvatarMap = rootState.cache.app.caller.entIdUserAvatarMap;

    const itemIds = [myWorldItemId, gapItemId, ...srcEntIdNotAdminSet];
    const itemsById = {} as IListItemsById;

    itemIds.forEach(itemId =>
    {
      if(itemId === myWorldItemId)
      {
        itemsById[myWorldItemId] = {
          type: "aps",
          avatarLeft: {
            icon: "public"
          },
          primary: {
            text: "My World"
          },
          secondary: {
            text: "All personal communications"
          },
          hideMenu: true
        } as IListItem;
      }
      else if(itemId === gapItemId)
      {
        itemsById[gapItemId] = createListItemGapHalf();
      }
      else
      {
        itemsById[itemId] = getListItemEnt(
          entIdUserAvatarMap[itemId],
          false,
          true
        ) as IListItem;
      }
    });

    return {
      itemIds: itemIds,
      itemsById: itemsById
    } as IListData;
  }

  protected onPostLoad(listName: string, state: RootState): void
  {
    const filter = state.cache.home.drawer.filter.entIdSet;
    if(filter && filter.includes(myWorldItemId))
    {
      dispatchList(listName, listSetPick({
        itemId: myWorldItemId,
        pickValue: true
      }));
    }
  }

  private selectSigAvatarEnt(state: RootState, entId: TypeListItemId): TypeSigAvatarEnt
  {
    if(this.isReservedItemId(entId))
    {
      return undefined;
    }
    else
    {
      return state.cache.app.caller.entIdUserAvatarMap[entId];
    }
  }

  private onBindSigAvatarEnt(
    listName: string,
    entId: TypeListItemId,
    avatar?: TypeSigAvatarEnt): void
  {
    if(!this.isReservedItemId(entId))
    {
      const badge = store.getState().cache.app.badge.aggBadgeMap[entId];

      if(avatar)
      {
        const listItemEnt = getListItemEnt(
          avatar as SigEntAvatarUser,
          false,
          true
        ) as IListItem;

        dispatchList(listName, listSetItem({
          itemId: entId,
          newItem: listItemEnt
        }));

        Promise.resolve().then(() =>
        {
          dispatchList(listName,
            listSetIfExistSecondaryLineBadge({
              itemId: entId,
              value: badge,
              color: "successLight"
            })
          );
        });
      }
      else
      {
        dispatchList(listName, listClearItem(entId));
      }

      const filter = store.getState().cache.home.drawer.filter.entIdSet;

      dispatchList(listName, listSetPick({
        itemId: entId,
        pickValue: Boolean(filter && filter.includes(entId))
      }));
    }
  }

  private selectBadge(state: RootState, entId: TypeListItemId): number
  {
    return state.cache.app.badge.aggBadgeMap[entId];
  }

  private onBindBadge(listName: string, entId: TypeListItemId, badge?: number): void
  {
    if(!this.isReservedItemId(entId))
    {
      dispatchList(listName, listSetIfExistSecondaryLineBadge({
        itemId: entId,
        value: badge,
        color: "successLight"
      }));
    }
    else if(entId === myWorldItemId)
    {
      dispatchList(listName, listSetIfExistSecondaryLineBadge({
        itemId: entId,
        value: badge,
        color: "successLight"
      }));
    }
  }
}
