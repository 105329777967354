import {Typography} from "@mui/material";
import React from "react";
import {AnyString} from "../../../../api/meta/base/AnyString";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueLocation} from "../../../../api/meta/base/dto/FieldValueLocation";
import {EnumDefnCaptureValueKind} from "../../../../api/meta/base/Types";
import {STR_CAPTURING_LOCATION} from "../../../../base/plus/ConstantsPlus";
import {STR_UPDATING_LOCATION} from "../../../../base/plus/ConstantsPlus";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {ICaptureValue} from "../../../../base/plus/LocationPlus";
import {openSelectedGeoPoint} from "../../../../base/plus/LocationPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawTooltip from "../../../atom/raw/RawTooltip";

export default function RawCaptureExtraProperties(props: {
  captureTime?: string;
  captureUser?: FieldValueEntUserId;
  captureLocation?: FieldValueLocation;
  captureLoading?: boolean;
  captureError?: string;
  showCapturedAsHelperText?: EnumDefnCaptureValueKind[]
})
{
  const showCapturedAsHelperText = props.showCapturedAsHelperText;
  const captureUser = props.captureUser;
  const captureTime = props.captureTime;
  const captureLocation = props.captureLocation;
  const captureLoading = props.captureLoading;
  const captureError = props.captureError;
  const showCaptureLocation = showCapturedAsHelperText?.includes("captureLocation");

  return (
    <LayoutFlexCol
      pt={px(gapQuarter)}
      pb={px(gapQuarter)}
    >
      {
        !showCaptureLocation && (captureLoading || captureError) && (
          <LayoutFlexRow
            key={"Loc"}
            width={"100%"}
            justifyContent={"flex-start"}
          >
            <RawHighlighter
              width={50}
              variant={"caption"}
              value={"Loc: "}
              breakWords={true}
            />
            <PropertyValue
              captureLoading={captureLoading}
              captureError={captureError}
            />
          </LayoutFlexRow>
        )
      }

      {
        showCapturedAsHelperText?.map(value =>
        {
          const key = value === "captureUser"
            ? "User" : value === "captureTime"
              ? "Time"
              : value === "captureLocation"
                ? "Loc"
                : undefined;

          if(!key)
          {
            return null;
          }
          if(value === "captureUser" && !captureUser)
          {
            return null;
          }
          if(value === "captureTime" && !captureTime)
          {
            return null;
          }
          if(value === "captureLocation" && !captureLocation && !captureLoading && !captureError)
          {
            return null;
          }

          return (
            <LayoutFlexRow
              key={key}
              width={"100%"}
              justifyContent={"flex-start"}
            >
              <RawHighlighter
                width={50}
                variant={"caption"}
                value={key + ": "}
                breakWords={true}
              />

              <PropertyValue
                {...value === "captureUser" && {captureUser: captureUser}}
                {...value === "captureTime" && {captureTime: captureTime}}
                {...value === "captureLocation" && {
                  captureLocation: captureLocation,
                  captureLoading: captureLoading,
                  captureError: captureError
                }}
              />
            </LayoutFlexRow>
          );
        })}
    </LayoutFlexCol>
  );
}

function PropertyValue(props: {
  captureLocation?: FieldValueLocation,
  captureTime?: string,
  captureUser?: FieldValueEntUserId,
  captureLoading?: boolean,
  captureError?: string,
  systemCaptureValue?: ICaptureValue,
})
{
  const captureLoading = props.captureLoading;
  const captureError = props.captureError;
  const captureTime = props.captureTime;
  const captureUser = props.captureUser;
  const captureLocation = props.captureLocation;

  if(captureLocation || captureLoading || captureError)
  {
    return (
      <RealLocation {...props} />
    );
  }

  if(captureTime)
  {
    return (
      <RawHighlighter
        ml={gapQuarter}
        variant={"caption"}
        value={formatCaptionOnlyTime(new Date(captureTime))}
        breakWords={true}
        maxNumberOfLine={1}
      />
    );
  }

  if(captureUser)
  {
    return (
      <RawHighlighter
        ml={gapQuarter}
        variant={"caption"}
        value={captureUser?.displayField}
        breakWords={true}
        maxNumberOfLine={1}
      />
    );
  }
}

function RealLocation(props: {
  captureLocation?: FieldValueLocation,
  captureLoading?: boolean,
  captureError?: string,
})
{
  const {captureLocation, captureLoading, captureError} = props;

  const onClickIcon = (latLng: AnyString) =>
  {
    const lat = parseFloat(latLng?.split(",")[0]);
    const lng = parseFloat(latLng?.split(",")[1]);

    if(lat && lng)
    {
      openSelectedGeoPoint(lat, lng);
    }
  };

  return (
    captureLocation?.value.address ?
      <RawTooltip
        title={{
          primary: captureLocation?.value?.address ?? "",
          secondary: ""
        }}
      >
        <Typography
          onClick={() =>
          {
            const geoPoint = captureLocation?.value.geoPoint;
            if(geoPoint)
            {
              onClickIcon && onClickIcon(geoPoint);
            }
          }}
          variant={"caption"}
          style={{
            WebkitLineClamp: 1,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            paddingLeft: px(gapQuarter),
            width: "auto",
            flex: 1,
            textOverflow: "ellipsis",
            cursor: "pointer"
          }}
        >
          {captureLocation?.value?.address ?? ""}
        </Typography>
      </RawTooltip>
      :
      <Typography
        variant={"caption"}
        style={{
          paddingLeft: px(gapQuarter)
        }}
      >
        {captureLoading
          ? captureLocation?.value.geoPoint ? STR_UPDATING_LOCATION : STR_CAPTURING_LOCATION
          : captureError
            ? captureError
            : captureLocation?.value.address}
      </Typography>

  );
}
