import {keyframes} from "@mui/material";
import {SxProps} from "@mui/system";
import {format} from "date-fns";
import {PhoneNumberFormat} from "google-libphonenumber";
import {PhoneNumberUtil} from "google-libphonenumber";
import {cloneDeep} from "lodash";
import {isDate} from "lodash";
import {isEmpty} from "lodash";
import {ObjectSchema} from "yup";
import * as Yup from "yup";
import {DtoMessagePayloadReport} from "../../api/home/base/dto/DtoMessagePayloadReport";
import {SigUserAvatar} from "../../api/home/drawer/sig/SigUserAvatar";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnDtoColor} from "../../api/meta/base/dto/DefnDtoColor";
import {DefnDtoFormTheme} from "../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnDtoPermissionMatrix} from "../../api/meta/base/dto/DefnDtoPermissionMatrix";
import {DefnDtoText} from "../../api/meta/base/dto/DefnDtoText";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldChipSetDate} from "../../api/meta/base/dto/DefnFieldChipSetDate";
import {DefnFieldChipSetDateTime} from "../../api/meta/base/dto/DefnFieldChipSetDateTime";
import {DefnFieldChipSetTime} from "../../api/meta/base/dto/DefnFieldChipSetTime";
import {DefnFieldDate} from "../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldDateRange} from "../../api/meta/base/dto/DefnFieldDateRange";
import {DefnFieldDateTime} from "../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldDateTimeRange} from "../../api/meta/base/dto/DefnFieldDateTimeRange";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldFormList} from "../../api/meta/base/dto/DefnFieldFormList";
import {DefnFieldHyperlinkRow} from "../../api/meta/base/dto/DefnFieldHyperlinkRow";
import {DefnFieldInfo} from "../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLogCounter} from "../../api/meta/base/dto/DefnFieldLogCounter";
import {DefnFieldLogDecimal} from "../../api/meta/base/dto/DefnFieldLogDecimal";
import {DefnFieldLogNumber} from "../../api/meta/base/dto/DefnFieldLogNumber";
import {DefnFieldParagraph} from "../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickRole} from "../../api/meta/base/dto/DefnFieldPickRole";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldRef} from "../../api/meta/base/dto/DefnFieldRef";
import {DefnFieldRefUser} from "../../api/meta/base/dto/DefnFieldRefUser";
import {DefnFieldSetOfRole} from "../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldTime} from "../../api/meta/base/dto/DefnFieldTime";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../api/meta/base/dto/DefnGrid";
import {DefnLayoutFormEditor} from "../../api/meta/base/dto/DefnLayoutFormEditor";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {DefnWizard} from "../../api/meta/base/dto/DefnWizard";
import {FieldDtoTree} from "../../api/meta/base/dto/FieldDtoTree";
import {FieldDtoTreeNode} from "../../api/meta/base/dto/FieldDtoTreeNode";
import {FieldSetOfOptionId} from "../../api/meta/base/dto/FieldSetOfOptionId";
import {FieldValueEntUserId} from "../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueGeoPoint} from "../../api/meta/base/dto/FieldValueGeoPoint";
import {FieldValueLocation} from "../../api/meta/base/dto/FieldValueLocation";
import {FieldValueOptionId} from "../../api/meta/base/dto/FieldValueOptionId";
import {FormValue} from "../../api/meta/base/dto/FormValue";
import {StudioComp} from "../../api/meta/base/dto/StudioComp";
import {StudioEntActionRowInsert} from "../../api/meta/base/dto/StudioEntActionRowInsert";
import {StudioVarMap} from "../../api/meta/base/dto/StudioVarMap";
import {StudioVarPlacement} from "../../api/meta/base/dto/StudioVarPlacement";
import {MetaIdGroup} from "../../api/meta/base/Types";
import {EnumDefnRoles} from "../../api/meta/base/Types";
import {MetaId} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {RowId} from "../../api/meta/base/Types";
import {EntUserId} from "../../api/meta/base/Types";
import {MetaIdRole} from "../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {MetaIdAction} from "../../api/meta/base/Types";
import {MetaIdOption} from "../../api/meta/base/Types";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {AnyTime} from "../../api/meta/base/Types";
import {MetaIdComp} from "../../api/meta/base/Types";
import {EnumDefnThemeButtonSize} from "../../api/meta/base/Types";
import {EnumDefnThemeButtonVariant} from "../../api/meta/base/Types";
import {EnumDefnPermission} from "../../api/meta/base/Types";
import {EnumDefnThemeFieldMargin} from "../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {EnumDefnThemeColor} from "../../api/meta/base/Types";
import {ICallerEnt} from "../../cache/app/callerEnt/TypesCacheCallerEnt";
import {getCompLabel} from "../../nucleus/form/viewer/base/FormViewerPlus";
import {comboToAboutId} from "../types/TypesComboId";
import {toComboId} from "../types/TypesComboId";
import {TypeDefnFieldRef} from "../types/TypesForm";
import {FormStore} from "../types/TypesForm";
import {IDefnFormVisibilityOptions} from "../types/TypesForm";
import {DefnFormUi} from "../types/TypesForm";
import {DefnFieldUi} from "../types/TypesForm";
import {TypeTextColor} from "../types/TypesGlobal";
import {defnDtoTextToString} from "./ArgBinderPlus";
import {resolveForm} from "./ArgBinderPlus";
import {extractTimeFormat} from "./DatePlus";
import {toTimeStringToDate} from "./DatePlus";
import {formatCaptionOnlyTime} from "./DatePlus";
import {getFormFieldValueAsTextWithPrefixSuffix} from "./FieldValuePlus";
import {calcSchemaField} from "./FormYupPlus";
import {insertSystemRoleIds} from "./SrvcPlus";
import {matchRoles} from "./SrvcPlus";
import {findSubStrBetweenPrefixAndSuffix} from "./StringPlus";
import {px} from "./StringPlus";
import {hasValue} from "./StringPlus";
import {searchHit} from "./StringPlus";
import {toLabel} from "./StringPlus";
import {CssColor} from "./ThemePlus";
import theme from "./ThemePlus";
import {gapHalf} from "./ThemePlus";

const defaultPermission: EnumDefnPermission = "write";
export const selfRole: EnumDefnRoles = "$Self";
export const publicRole: EnumDefnRoles = "$Public";

export interface ILoopDefnFormOptions
{
  excludeGridItem?: boolean;
  excludeSection?: boolean;
  onlyField?: boolean;
}

export interface IExecuteAction
{
  actionId: MetaIdAction;
  groupIdSet?: MetaIdGroup[];
  mappingVarId?: MetaIdVar;
}

export const defnTheme = {
  fieldVariant: "outlined" as EnumDefnThemeFieldVariant,
  fieldMargin: "none" as EnumDefnThemeFieldMargin,
  fieldSize: "small" as EnumDefnThemeFieldSize,
  themeColor: "primary" as EnumDefnThemeColor,
  buttonSize: "medium" as EnumDefnThemeButtonSize,
  buttonVariant: "contained" as EnumDefnThemeButtonVariant,
  colSpacing: 2 as number,
  rowSpacing: 2 as number
};

export function fieldReportLeftLabel(defnField: DefnField): string
{
  const label = getCompLabel(defnField);

  return label + ":";
}

export function isFormSearchHit(
  defnForm?: DefnForm,
  valueMap?: Record<MetaIdField, any>,
  searchWordsLowercase?: string[])
{
  if(!valueMap)
  {
    return false;
  }

  if(defnForm?.compMap)
  {
    let isHit = false;
    loopDefnForm(defnForm, (_, comp) =>
      {
        const metaId = comp?.metaId;
        const value = (valueMap && metaId) ? valueMap[metaId] : undefined;
        const fieldValue = getFormFieldValueAsTextWithPrefixSuffix(comp, value);

        if(!comp?.hidden && !comp?.invisible && searchHit(fieldValue, searchWordsLowercase))
        {
          isHit = true;
          return true;
        }
      },
      {
        onlyField: true
      }
    );
    return isHit;
  }
}

//region accessors
export function getFormName(
  defnForm?: DefnForm,
  formValue?: FormValue,
  chatLabelPatternVar?: DefnDtoText)
{
  const formName = hasValue(defnForm?.name) ? toLabel(defnForm?.name ?? "") : undefined;
  const label = hasValue(defnForm?.label) ? defnForm?.label : undefined;

  let chatLabel: string | undefined;
  const chatLabelFieldId = defnForm?.chatLabelFieldId;

  if(chatLabelFieldId)
  {
    const field = defnForm?.compMap?.[chatLabelFieldId] as DefnField;
    chatLabel = getFormFieldValueAsTextWithPrefixSuffix(field, formValue?.valueMap?.[chatLabelFieldId]);
  }
  else if(chatLabelPatternVar && chatLabelPatternVar.value?.length)
  {
    chatLabel = defnDtoTextToString(chatLabelPatternVar);
  }

  return chatLabel ?? label ?? formName;
}

export function getFormNameColor(
  callerUserId?: EntUserId,
  updatedBy?: EntUserId,
  updatedKeySet?: MetaIdComp[]): TypeTextColor
{
  if((callerUserId !== updatedBy) && updatedKeySet == undefined)
  {
    return "success";
  }

  return "textPrimary";
}

export function getReportName(payload: DtoMessagePayloadReport)
{
  return hasValue(payload.reportLabel) ? payload.reportLabel : toLabel(payload.reportName ?? "");
}

export function getDisplaySection(defnForm: DefnFormUi): DefnSection
{
  const displaySectionKey = prepare(defnForm).displayCompositeId as MetaIdField;
  const displaySection = defnForm.compMap ? defnForm.compMap[displaySectionKey] as DefnSection : undefined;
  if(displaySection === undefined)
  {
    throw new Error(`Section not defined for display section key, ${displaySectionKey}`);
  }
  return displaySection;
}

export function getComp(defnForm: DefnFormUi, compKey: MetaIdField): DefnFieldUi
{
  const _defnForm = prepare(defnForm);
  if(!_defnForm.compMap[compKey])
  {
    throw new Error(`Comp key, ${compKey}, not found`);
  }
  return _defnForm.compMap[compKey] as DefnFieldUi;
}

export function getFieldKey(defnFieldUi: DefnFieldUi): MetaIdField
{
  const _key = defnFieldUi._key;
  if(_key === undefined)
  {
    throw new Error(`Field key not defined on field, ${JSON.stringify(defnFieldUi)}`);
  }
  return _key;
}

export function getFieldType(defnFieldUi: DefnFieldUi): EnumDefnCompType
{
  return defnFieldUi.type;
}

export function getCompKeyArray(defnSection: DefnSection): MetaIdField[]
{
  const fieldArray = defnSection.fieldIdSet;
  if(fieldArray === undefined)
  {
    throw new Error(`Comp key array not defined on section`);
  }
  return fieldArray;
}

export function getTheme(defnForm: DefnFormUi): DefnDtoFormTheme
{
  return prepare(defnForm)._defnTheme as DefnDtoFormTheme;
}

export function getSchema(defnForm: DefnFormUi): Yup.AnyObjectSchema
{
  return prepare(defnForm)._schema as Yup.AnyObjectSchema;
}

//endregion

export const defaultSectionKey = "defaultSectionKey" as MetaIdField;

export function createDefaultDefnForm(compMap: Record<MetaIdField, DefnComp>): DefnFormUi
{
  return {
    compMap: compMap,
    displayCompositeId: defaultSectionKey,
    theme: {
      fieldSize: "medium",
      buttonSize: "large",
      colSpacing: 2,
      rowSpacing: 2
    } as DefnDtoFormTheme
  } as DefnFormUi;
}

export function createDefaultDefnFormStudio(
  compMap?: Record<MetaIdField, DefnComp>,
  _defaultSectionKey?: MetaIdField): DefnFormUi
{
  return {
    compMap: compMap,
    displayCompositeId: _defaultSectionKey ?? defaultSectionKey,
    theme: {
      fieldSize: "small",
      buttonSize: "medium",
      fieldVariant: "outlined",
      colSpacing: 2,
      rowSpacing: 2
    } as DefnDtoFormTheme
  } as DefnFormUi;
}

//region init
export function prepare(defnForm: DefnFormUi): DefnFormUi
{
  let _init = defnForm._init;

  if(!_init)
  {
    calcTheme(defnForm);
    let displaySectionKeys = calcCompArray(defnForm);
    calcDisplaySectionKey(defnForm, displaySectionKeys);
    calcSchema(defnForm);
    defnForm._init = true;
  }

  return defnForm;
}

export function filterFormWithLayout(defnForm: DefnForm)
{
  const displayCompositeId = defnForm.displayCompositeId;
  const defnTab = defnForm.compMap[displayCompositeId] as DefnTab;
  const contentMap = defnForm.layoutMap;
  const asideDefaultLayoutId = contentMap?.asideDefaultLayoutId;
  const layout = asideDefaultLayoutId
    ? contentMap?.map[asideDefaultLayoutId]
    : undefined;
  const type = layout?.type;

  if(layout && type === "editor")
  {
    const tabIdSet = [] as MetaIdComp[];
    const compMap = {} as Record<MetaIdField, DefnComp>;
    const renderingMode = (layout as DefnLayoutFormEditor).editorLayoutRenderingMode;
    const metaIdComp = renderingMode === "tabs"
      ? displayCompositeId
      : renderingMode === "stack"
        ? layout?.metaId
        : undefined;

    insertLayout(defnForm, compMap, tabIdSet, layout as DefnLayoutFormEditor, metaIdComp);

    if(renderingMode !== "tabs")
    {
      compMap[displayCompositeId] = {
        ...defnTab,
        tabIdSet: tabIdSet
      } as DefnTab;
    }

    defnForm.compMap = {
      ...defnForm.compMap,
      ...compMap
    };
  }
}

function insertLayout(
  defnForm: DefnForm,
  compMap: Record<MetaIdField, DefnComp>,
  compositeIdSet: MetaIdComp[],
  layout: DefnLayoutFormEditor,
  displayCompositeId?: MetaIdComp)
{
  const editorLayoutRenderingMode = layout.editorLayoutRenderingMode;
  if(editorLayoutRenderingMode)
  {
    switch(editorLayoutRenderingMode)
    {
      case "tabs":
        insertLayoutTabs(defnForm, compMap, compositeIdSet, layout as DefnLayoutFormEditor, displayCompositeId);
        break;
      case "stack":
        insertLayoutStack(defnForm, compMap, compositeIdSet, layout as DefnLayoutFormEditor, displayCompositeId);
        break;
      case "wizard":
        insertLayoutWizard(defnForm, compMap, compositeIdSet, layout as DefnLayoutFormEditor);
        break;
    }
  }
}

function insertLayoutCommon(
  metaIdComp: MetaIdComp,
  childCompIdSet: MetaIdComp[],
  defnForm: DefnForm,
  compMap: Record<MetaIdField, DefnComp>,
  compositeIdSet: MetaIdComp[],
  layout: DefnLayoutFormEditor)
{
  if(layout.compositeIdSet?.length)
  {
    layout.compositeIdSet.forEach(compositeId =>
    {
      let showLabel;
      let label;

      const defnComp = defnForm.compMap[compositeId];
      const editorLayoutRenderingMode = layout.editorLayoutRenderingMode;

      if(editorLayoutRenderingMode === "stack")
      {
        const hideLabelCompositeIdSet = layout.hideLabelCompositeIdSet;

        showLabel = Boolean(!hideLabelCompositeIdSet || !hideLabelCompositeIdSet.includes(compositeId));
        label = getCompLabel(defnComp);
      }

      childCompIdSet.push(compositeId);

      compMap[compositeId] = {
        ...defnComp,
        ...showLabel && {
          propertyEditorLabel: label
        } as (DefnSection | DefnGrid)
      };
    });
  }
  else if(layout.formEditorLayoutIdSet?.length)
  {
    layout.formEditorLayoutIdSet.forEach(layoutId =>
    {
      const contentMap = defnForm.layoutMap;
      const childLayout = contentMap?.map[layoutId];
      if(childLayout?.type === "editor")
      {
        insertLayout(defnForm, compMap, childCompIdSet, childLayout as DefnLayoutFormEditor);
      }
    });
  }
  compositeIdSet.push(metaIdComp);
}

function insertLayoutTabs(
  defnForm: DefnForm,
  compMap: Record<MetaIdField, DefnComp>,
  compositeIdSet: MetaIdComp[],
  layout: DefnLayoutFormEditor,
  displayCompositeId?: MetaIdComp)
{
  const tabId = displayCompositeId || layout.metaId;
  const compIdSet = [] as MetaIdComp[];

  insertLayoutCommon(tabId, compIdSet, defnForm, compMap, compositeIdSet, layout);

  compMap[tabId] = {
    ...displayCompositeId ? defnForm.compMap[displayCompositeId] : {},
    type: "tab",
    metaId: tabId,
    name: tabId,
    tabIdSet: compIdSet,
    label: layout.label || layout.name
  } as DefnTab;
}

function insertLayoutStack(
  defnForm: DefnForm,
  compMap: Record<MetaIdField, DefnComp>,
  compositeIdSet: MetaIdComp[],
  layout: DefnLayoutFormEditor,
  displayCompositeId?: MetaIdComp)
{
  const stackId = displayCompositeId || layout.metaId;
  const compIdSet = [] as MetaIdComp[];

  insertLayoutCommon(stackId, compIdSet, defnForm, compMap, compositeIdSet, layout);

  compMap[stackId] = {
    type: "section",
    metaId: stackId,
    name: stackId,
    fieldIdSet: compIdSet,
    label: layout.label || layout.name
  } as DefnSection;
}

function insertLayoutWizard(
  defnForm: DefnForm,
  compMap: Record<MetaIdField, DefnComp>,
  compositeIdSet: MetaIdComp[],
  layout: DefnLayoutFormEditor)
{
  const wizardId = layout.metaId;
  const compIdSet = [] as MetaIdComp[];

  insertLayoutCommon(wizardId, compIdSet, defnForm, compMap, compositeIdSet, layout);

  compMap[wizardId] = {
    type: "wizard",
    metaId: wizardId,
    name: wizardId,
    compositeIdSet: compIdSet
  } as DefnWizard;
}

function calcTheme(defnForm: DefnFormUi)
{
  if(defnForm.theme === undefined)
  {
    defnForm._defnTheme = defnTheme;
  }
  else
  {
    defnForm._defnTheme = {...defnTheme, ...defnForm.theme};
  }
}

function calcCompArray(defnForm: DefnFormUi)
{
  let displaySectionKeys = [] as MetaIdField[];
  let _compArray = [] as DefnFieldUi[];
  let compMap = defnForm.compMap ?? {};

  Object
  .keys(compMap)
  .forEach(compKey =>
  {
    const defnComp = compMap[compKey] as DefnFieldUi;

    if(Boolean(defnComp._key))
    {
      throw new Error("Key is already used. Do not reuse object references.");
    }

    if(defnComp.type === "ref"
      || defnComp.type === "refReport"
      || defnComp.type === "pickGridRow"
      || defnComp.type === "pickReportRow"
      || defnComp.type === "refUser")
    {
      const copyFieldMap = defnComp.type === "refUser"
        ? (defnComp as DefnFieldRefUser)?.copyFieldVarMap
        : (defnComp as TypeDefnFieldRef)?.copyFieldMap;

      if(copyFieldMap)
      {
        Object.keys(copyFieldMap).forEach(childFieldId =>
        {
          const defnComp = compMap[childFieldId] as DefnFieldUi;
          defnComp._parentRefId = compKey;
        });
      }
    }
    if(defnComp.type === "hyperlinkRow")
    {
      const hyperlinkFieldIdSet = (defnComp as DefnFieldHyperlinkRow)?.hyperlinkFieldIdSet;

      if(hyperlinkFieldIdSet)
      {
        hyperlinkFieldIdSet.map(childFieldId =>
        {
          const defnComp = compMap[childFieldId] as DefnFieldUi;
          defnComp._parentHyperlinkRowField = compKey;
        });
      }
    }

    defnComp._key = compKey;

    if(defnComp.name === undefined)
    {
      defnComp.name = compKey;
    }

    // for composites label is not mandatory
    if(defnComp.type === "section"
      || defnComp.type === "tab"
      || defnComp.type === "wizard")
    {
      displaySectionKeys.push(compKey);

      const defnSection = defnComp as DefnSection;
      const fieldIdSet = defnComp.type === "section"
        ? (defnComp as DefnSection).fieldIdSet
        : defnComp.type === "wizard"
          ? (defnComp as DefnWizard).compositeIdSet
          : (defnComp as DefnTab).tabIdSet;

      if(!fieldIdSet)
      {
        throw defnComp.type === "section"
          ? new Error(`Section, ${compKey}, should have fieldIdSet ${JSON.stringify(defnComp)}`)
          : new Error(`Tab, ${compKey}, should have tabIdSet ${JSON.stringify(defnComp)}`);
      }

      fieldIdSet.forEach(childCompKey =>
      {
        const childComp = compMap[childCompKey] as DefnFieldUi;
        if(childComp === undefined)
        {
          throw new Error(`In Section, ${compKey}, component, ${childCompKey}, is not found`);
        }

        let parentMap = childComp._parenMap;
        if(!parentMap)
        {
          parentMap = {} as Record<MetaIdField, DefnSection>;
          childComp._parenMap = parentMap;
        }

        const currParentComp = parentMap[compKey];
        if(currParentComp !== undefined)
        {
          throw new Error(`Component, ${childCompKey}, has pre-registered parent section, ${compKey}`);
        }

        parentMap[compKey] = defnSection;

        _compArray.push(childComp);
      });
    }

    const defnLabeled = defnComp as DefnField;
    if(defnLabeled.label === undefined
      && defnComp.type !== "info"
      && defnComp.type !== "label")
    {
      defnLabeled.label = toLabel(defnComp.name);
    }

    _compArray.push(defnComp);
  });

  defnForm._compArray = _compArray;

  return displaySectionKeys;
}

function calcDisplaySectionKey(defnForm: DefnFormUi, displaySectionKeys: MetaIdField[])
{
  if(defnForm.displayCompositeId === undefined)
  {
    if(displaySectionKeys.length === 1)
    {
      defnForm.displayCompositeId = displaySectionKeys.at(0) as MetaIdField;
    }
  }
  else
  {
    if(!defnForm.compMap)
    {
      throw new Error("compMap is undefined");
    }
    const sectionType = defnForm.compMap[defnForm.displayCompositeId].type;
    if(sectionType !== "section"
      && sectionType !== "tab"
      && sectionType !== "formList"
      && sectionType !== "wizard")
    {
      throw new Error("Display section key is required");
    }
  }
}

function calcSchema(defnForm: DefnFormUi)
{
  if(defnForm.compMap === undefined || isEmpty(defnForm.compMap))
  {
    throw new Error("CompMap is required");
  }

  let objectSchema = {} as Record<string, ObjectSchema<any>>;
  loopDefnForm(defnForm, (_, comp) =>
  {
    const fieldSchema = calcSchemaField(defnForm, comp);
    if(fieldSchema)
    {
      objectSchema[getFieldKey(comp)] = fieldSchema;
    }
  }, {
    excludeGridItem: true
  });

  defnForm._schema = Yup.object().shape(objectSchema);
}

//endregion

export function getValidFormFieldValue(value?: string | Date | number)
{
  if(typeof value === "number")
  {
    return value;
  }
  else if(typeof value === "string")
  {
    return value.length > 0 ? value.trim() : undefined;
  }
  else if(!isDate(value) && typeof value === "object" && isEmpty(value))
  {
    return null;
  }
  else
  {
    return value;
  }
}

export function getGeoPointAsString(geoPoint?: FieldValueGeoPoint)
{
  if(!geoPoint || !geoPoint.value)
  {
    return "";
  }

  const geoPointArray = geoPoint.value.split(",");
  const latitude = parseFloat(geoPointArray[0]);
  const longitude = parseFloat(geoPointArray[1]);

  return `${latitude}, ${longitude}`;
}

export function getLocationAsString(location?: FieldValueLocation)
{
  if(!location)
  {
    return "";
  }

  const locationValue = location.value;

  let locationString = "";
  if(!locationValue)
  {
    return locationString;
  }

  if(locationValue.address)
  {
    locationString = locationValue.address;
  }
  if(locationValue.city)
  {
    locationString = locationString + (locationString ? ", " : "") + locationValue.city;
  }
  if(locationValue.country)
  {
    locationString = locationString + (locationString ? ", " : "") + locationValue.country;
  }
  return locationString;
}

export function getDefnForReport(defnForm: DefnForm): DefnFormUi
{
  const copyDefnForm = cloneDeep(defnForm);

  const compMap = copyDefnForm.compMap;
  if(compMap)
  {
    const defnTab = compMap[copyDefnForm.displayCompositeId];
    if(defnTab)
    {
      defnTab.type = "section";
      (defnTab as DefnSection).fieldIdSet = (defnTab as DefnTab).tabIdSet;
      (defnTab as DefnSection).sectionDirection = "vertical";
    }
  }

  copyDefnForm["theme"] = {
    formVariant: "report",
    fieldSize: "small",
    buttonSize: "medium",
    colSpacing: 1,
    rowSpacing: 1
  } as DefnDtoFormTheme;

  return copyDefnForm as DefnFormUi;
}

export function getTimeAsString(
  time: AnyTime,
  timeFormat?: string,
  showSeconds?: boolean)
{
  const dateTime = toTimeStringToDate(time);
  if(dateTime)
  {
    if(timeFormat)
    {
      return format(dateTime, extractTimeFormat(timeFormat, showSeconds as boolean));
    }
    else
    {
      return formatCaptionOnlyTime(dateTime, showSeconds);
    }
  }
  else
  {
    return time;
  }
}

// noinspection JSUnusedGlobalSymbols
export function getPickManyAsLabel(pickValue: DefnFieldPickText, pickValueValue: string[])
{
  const labelArray = pickValueValue.map((value) =>
  {
    return pickValue.optionMap?.map[value]?.value;
  });
  return labelArray.join(",");
}

export function getLabelFromOption(options: DefnDtoOption[], value?: FieldValueOptionId)
{
  const option = options.find(_option => _option.metaId === value?.optionId);
  return option?.value || value?.value;
}

export function getLabelFromOptionTree(sourceVar?: FieldDtoTree, value?: string): string | undefined
{
  if(!sourceVar || isEmpty(sourceVar))
  {
    return undefined;
  }

  const findPath = (key: string, node: FieldDtoTreeNode, targetValue: string): string[] =>
  {
    if(node.value && (key === targetValue))
    {
      return [node.value];
    }

    let path: string[] = [];

    if(node.keys && node.keys.length > 0)
    {
      node.keys.forEach((key) =>
      {
        const childNode = node.map[key];
        const pathFromChild = findPath(key, childNode, targetValue);

        if(pathFromChild.length > 0)
        {
          path = node.value ? [node.value, ...pathFromChild] : [""];
        }
      });
    }

    return path;
  };

  if(!value)
  {
    return undefined;
  }

  let path: string[] = [];

  sourceVar.keys.forEach((key) =>
  {
    const rootNode = sourceVar.map[key];
    const currentPath = findPath(key, rootNode, value);

    if(currentPath.length > 0)
    {
      path = currentPath;
    }
  });

  return path.length > 0 ? path.join(" > ") : undefined;
}

export function getLabelsFromOptions(options: DefnDtoOption[], value: FieldSetOfOptionId)
{
  const labelMap = {} as Record<MetaIdOption, string>;
  options.forEach(option => labelMap[option.metaId] = option.value);
  return value.valueSet.map((optionId, index) => labelMap[optionId] || value.displaySet?.[index]).filter(Boolean);
}

export function formatDecimalDigitsAfterPeriod(value?: number, numberOfDigitsAfterPeriod?: number): number | undefined
{
  return (numberOfDigitsAfterPeriod !== undefined && value !== undefined)
    ? parseFloat(value.toFixed(numberOfDigitsAfterPeriod))
    : value;
}

// region filterForm

export function insertManagerialRelationship(
  managerialRelationshipMap: Record<MetaIdRole, EntUserId[]>,
  senderId: EntUserId,
  roleIdSet: MetaIdRole[])
{
  Object.entries(managerialRelationshipMap).forEach(([role, userIds]) =>
  {
    if(userIds.includes(senderId))
    {
      roleIdSet.push(role);
    }
  });
}

function getPermission(
  callerRole: MetaIdRole,
  permissionMatrix?: DefnDtoPermissionMatrix
): EnumDefnPermission | undefined
{
  if(permissionMatrix)
  {
    if(permissionMatrix.map?.[callerRole])
    {
      return permissionMatrix.map[callerRole];
    }
    else if(callerRole !== selfRole && permissionMatrix.defaultPermission)
    {
      return permissionMatrix.defaultPermission;
    }
  }
}

function getHigherPriorityPermission(rolePermissions: EnumDefnPermission[]): EnumDefnPermission
{
  const rolePermissionsMap =
    rolePermissions.reduce((acc, permission) =>
    {
      acc[permission] = true;
      return acc;
    }, {} as Record<MetaIdField, boolean>);

  if(rolePermissionsMap["write"])
  {
    return "write";
  }
  else if(rolePermissionsMap["writeOnce"])
  {
    return "writeOnce";
  }
  else if(rolePermissionsMap["writeOnInsert"])
  {
    return "writeOnInsert";
  }
  else if(rolePermissionsMap["read"])
  {
    return "read";
  }
  else if(rolePermissionsMap["invisible"])
  {
    return "invisible";
  }
  else if(rolePermissionsMap["hide"])
  {
    return "hide";
  }

  return defaultPermission;
}

export function getResolvedPermissionMatrix(
  form: DefnFormUi,
  parent: DefnComp,
  field: DefnField,
  callerRoleIdSet?: MetaIdRole[]): EnumDefnPermission
{
  const permissions = [] as EnumDefnPermission[];

  if(!callerRoleIdSet)
  {
    return defaultPermission;
  }

  callerRoleIdSet.forEach(roleId =>
  {
    const fieldPermission = getPermission(roleId, field.permissionMatrix);
    const parentPermission = getPermission(roleId, parent.permissionMatrix);
    const formPermission = getPermission(roleId, form.permissionMatrix);

    if(fieldPermission)
    {
      permissions.push(fieldPermission);
    }
    else if(parentPermission)
    {
      permissions.push(parentPermission);
    }
    else if(formPermission)
    {
      permissions.push(formPermission);
    }
    else if(roleId !== selfRole)
    {
      permissions.push(defaultPermission);
    }
  });

  return getHigherPriorityPermission(permissions);
}

function resolveLogField(
  field: DefnField,
  callerEnt?: ICallerEnt,
  callerRoleIdSet?: MetaIdRole[]
)
{
  const fieldType = field.type;
  if(fieldType === "logNumber"
    || fieldType === "logCounter"
    || fieldType === "logDecimal")
  {
    const compLog = field as DefnFieldLogNumber | DefnFieldLogCounter | DefnFieldLogDecimal;
    const logReadRoleSet = compLog.logReadRoleSet;

    if(logReadRoleSet && callerRoleIdSet)
    {
      compLog.hideInfo = !Boolean(matchRoles(callerRoleIdSet, logReadRoleSet));
    }

    if(!callerEnt)
    {
      compLog.hideInfo = true;
    }
  }
}

function applyPermissionMatrix(
  permission: EnumDefnPermission,
  field: DefnFieldEditable,
  parent: DefnComp,
  formValue?: FormValue,
  isInEditMode?: boolean)
{
  const fieldId = field.metaId;
  const valueMap = formValue?.valueMap;

  switch(permission)
  {
    case "invisible":
      field.invisible = true;
      break;
    case "hide":
      field.hidden = true;
      break;
    case "read":
      field.disabled = true;
      break;
    case "writeOnce":
      if(isInEditMode && valueMap && !isEmpty(valueMap[fieldId]))
      {
        field.disabled = true;
      }
      break;
    case "writeOnInsert":
      if(isInEditMode)
      {
        field.disabled = true;
      }
      break;
  }

  if(!field.disabled && parent.disabled)
  {
    parent.disabled = false;
  }

  if(!field.invisible && parent.invisible)
  {
    parent.invisible = false;
  }

  if(!field.hidden && parent.hidden)
  {
    parent.hidden = false;
  }

}

export function filterForm(
  form: DefnFormUi,
  roles: string[],
  callerEnt?: ICallerEnt,
  formValue?: FormValue,
  isInEditMode?: boolean,
  loopCallback?: (parent: DefnComp, comp: (DefnSection | DefnGrid | DefnTab | DefnField)) => void)
{
  const cloneForm = cloneDeep(form);
  const senderId = formValue?.createdBy;
  const roleIdSet = [...roles];

  if(!cloneForm.compMap)
  {
    return cloneForm;
  }
  if(callerEnt && senderId)
  {
    insertSystemRoleIds(callerEnt, senderId, roleIdSet);
  }
  else if(!isInEditMode)
  {
    roleIdSet.push(selfRole);
  }

  const compMapPermissionMap = {} as Record<MetaId, boolean>;

  loopDefnForm(cloneForm, (parent, comp) =>
  {
    const resolvedPermission = getResolvedPermissionMatrix(cloneForm, parent, comp, roleIdSet);
    applyPermissionMatrix(resolvedPermission, comp as DefnFieldEditable, parent, formValue, isInEditMode);
    resolveLogField(comp, callerEnt, roleIdSet);

    const compParent = parent as DefnSection | DefnGrid;

    const fieldType = comp.type;

    if(((fieldType === "info" && (comp as DefnFieldInfo).flexGrow)
        || (fieldType === "paragraph" && (comp as DefnFieldParagraph).flexHeight))
      && compParent.type === "section")
    {
      (compParent as DefnSection).flexGrow = true;
    }

    if(fieldType === "section"
      || fieldType === "grid"
      || fieldType === "tab"
      || fieldType === "wizard")
    {
      compMapPermissionMap[comp.metaId] = true;
    }
    else
    {
      if(!comp.hidden && !comp.disabled)
      {
        compMapPermissionMap[compParent.metaId] = false;
      }
    }

    if(callerEnt)
    {
      if(fieldType === "date"
        || fieldType === "dateTime"
        || fieldType === "dateRange"
        || fieldType === "dateTimeRange"
        || fieldType === "chipSetDateTime"
        || fieldType === "chipSetDate"
      )
      {
        const compDateTime = comp as
          | DefnFieldDate
          | DefnFieldDateTime
          | DefnFieldDateRange
          | DefnFieldDateTimeRange
          | DefnFieldChipSetDateTime
          | DefnFieldChipSetDate;

        if(!compDateTime.displayDateFormat && callerEnt.displayDateFormat)
        {
          compDateTime.displayDateFormat = callerEnt.displayDateFormat;
        }

        if(!compDateTime.timeZone && callerEnt.timeZone)
        {
          compDateTime.timeZone = callerEnt.timeZone;
        }
      }
      else if(fieldType === "time" || fieldType === "chipSetTime")
      {
        const timeComp = comp as
          | DefnFieldChipSetTime
          | DefnFieldTime;

        if(!timeComp.displayDateFormat && callerEnt.displayDateFormat)
        {
          timeComp.displayDateFormat = callerEnt.displayDateFormat;
        }
      }
    }

    if(!callerEnt)
    {
      if(fieldType === "ref")
      {
        (comp as DefnFieldRef).canCreateRefRecord = false;
        (comp as DefnFieldRef).showRefreshOnFieldIdSet = undefined;
        (comp as DefnFieldRef).refreshOn = undefined;
      }
    }

    if(fieldType === "pickRole" || fieldType === "setOfRole")
    {
      (comp as DefnFieldPickRole | DefnFieldSetOfRole).callerRoleMap = generatePickRoleOptions(callerEnt);
    }

    loopCallback?.(parent, comp);
  });

  const formDefaultPermission: EnumDefnPermission = Object.values(compMapPermissionMap).every(permission => permission)
    ? "read"
    : "write";

  cloneForm.permissionMatrix = {
    ...cloneForm.permissionMatrix,
    defaultPermission: formDefaultPermission
  };

  if(callerEnt)
  {
    return resolveForm(callerEnt, cloneForm, formValue);
  }

  return cloneForm;
}

// endregion

function generatePickRoleOptions(callerEnt?: ICallerEnt)
{
  if(!callerEnt)
  {
    return undefined;
  }

  const options = {
    keys: [],
    map: {}
  } as DefnStudioMapOfDtoOption;

  if(callerEnt)
  {
    callerEnt.roleIdSet.forEach(roleId =>
    {
      const role = callerEnt.roleMap[roleId];
      options.keys.push(roleId);
      options.map[roleId] = {
        metaId: roleId,
        value: role.label || role.name
      };
    });
  }

  return options;
}

export function getDefnFieldLabel(field: DefnComp)
{
  return field.label
    ? field.label
    : toLabel(field.name as string);
}

export function createRadioOptions(options: string[], optionMap: Record<string, string>): DefnStudioMapOfDtoOption
{
  const mapOfOption = {
    keys: options,
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  options.forEach(option =>
  {
    mapOfOption.map[option] = {
      metaId: option,
      value: optionMap[option]
    };
  });

  return mapOfOption;
}

function calcIsLastFieldInDisplay(
  metaIdField: MetaIdField,
  section: DefnComp,
  compMap: Record<MetaIdField, DefnComp>): boolean
{
  const fieldArray = (section as DefnSection)?.fieldIdSet ?? (section as DefnTab)?.tabIdSet;
  if(fieldArray)
  {
    const lastFieldId = fieldArray.at(-1);
    if(lastFieldId)
    {
      const lastField = compMap[lastFieldId] as DefnComp;
      const type = lastField.type;
      if(lastFieldId === metaIdField)
      {
        return true;
      }
      else
      {
        if(type === "section")
        {
          return calcIsLastFieldInDisplay(metaIdField, lastField, compMap);
        }
        else if(type === "tab")
        {
          (lastField as DefnTab).tabIdSet?.forEach(fieldId => calcIsLastFieldInDisplay(metaIdField,
            compMap[fieldId],
            compMap
          ));
        }
      }
    }
  }
  return false;
}

export function isLastFieldInDisplay(metaIdField: MetaIdField, defnForm: DefnFormUi): boolean
{
  const displaySectionKey = defnForm.displayCompositeId;
  const compMap = defnForm.compMap;
  if(compMap)
  {
    const section = compMap[displaySectionKey];
    const fieldArray = (section as DefnSection)?.fieldIdSet ?? (section as DefnTab)?.tabIdSet;
    if(fieldArray)
    {
      if(section.type === "tab")
      {
        return fieldArray.some(fieldId => calcIsLastFieldInDisplay(metaIdField,
          compMap[fieldId] as DefnComp,
          compMap
        ));
      }
      else
      {
        return calcIsLastFieldInDisplay(metaIdField, section as DefnComp, compMap);
      }
    }
  }
  return false;
}

export function loopDefnForm(
  defnForm: DefnForm,
  callback: (parent: DefnComp, comp: DefnSection | DefnGrid | DefnTab | DefnField) => boolean | void,
  option?: ILoopDefnFormOptions)
{
  const displayCompositeId = defnForm.displayCompositeId;
  const onlyField = option?.onlyField;
  const excludeGridItem = option?.excludeGridItem;
  const excludeSection = option?.excludeSection;
  const compMap = defnForm.compMap;

  const getComp = (
    compId: MetaIdField,
    parentComp: DefnComp,
    compMap: Record<MetaIdComp, DefnComp>,
    callback: (parent: DefnComp, comp: DefnSection | DefnGrid | DefnTab | DefnField | DefnWizard) => boolean | void) =>
  {
    const comp = compMap && compMap[compId] as DefnSection | DefnGrid | DefnTab | DefnField | DefnWizard;
    if(comp)
    {
      if(comp.metaId)
      {
        if(!onlyField ||
          (comp.type !== "section" &&
            comp.type !== "grid" &&
            comp.type !== "tab" &&
            comp.type !== "wizard"))
        {
          const parentMetaId = (parentComp as DefnSection | DefnGrid | DefnTab | DefnWizard).metaId;
          if(callback(compMap[parentMetaId] || comp, comp))
          {
            return false; // return false will break the loop
          }
        }
      }

      if(comp.type === "tab")
      {
        const tabIdSet = (comp as DefnTab).tabIdSet;
        // using every instead of forEach to break the loop
        tabIdSet?.every(tabId => getComp(tabId, comp, compMap, callback));
      }
      else if(comp.type === "section" && !excludeSection)
      {
        (comp as DefnSection).fieldIdSet?.every(fieldId => getComp(fieldId, comp, compMap, callback));
      }
      else if(comp.type === "wizard")
      {
        (comp as DefnWizard).compositeIdSet?.every(fieldId => getComp(fieldId, comp, compMap, callback));
      }
      else if(comp.type === "formList")
      {
        const displayCompositeId = (comp as DefnFieldFormList).displayItemId;
        if(displayCompositeId)
        {
          getComp(displayCompositeId, comp, compMap, callback);
        }
      }
      else if(comp.type === "grid" && !excludeGridItem)
      {
        (comp as DefnGrid).fieldIdSet?.every(fieldId => getComp(fieldId, comp, compMap, callback));
      }
    }
    return true;
  };

  if(compMap)
  {
    const comp = compMap[displayCompositeId];
    getComp(displayCompositeId, comp, compMap, callback);
  }
}

export function setDefnFieldTabForJump(
  _parenMap: Record<MetaIdField, DefnSection>,
  childKey?: MetaIdField,
  refSetValue?: (parentKey: MetaIdField, childKey: MetaIdField) => void)
{

  const [parentKey, parentField] = _parenMap
    ? Object.entries(_parenMap)[0]
    : [undefined, undefined];

  const fieldType = (parentField as DefnFieldUi)?.type;
  const fieldParenMap = (parentField as DefnFieldUi)?._parenMap;

  if(fieldType === "section" && fieldParenMap)
  {
    setDefnFieldTabForJump(fieldParenMap, parentKey, refSetValue);
  }
  else if(fieldType === "tab")
  {
    if(refSetValue && childKey)
    {
      refSetValue(parentKey as MetaIdField, childKey);
    }
    if(fieldParenMap)
    {
      setDefnFieldTabForJump(fieldParenMap, parentKey, refSetValue);
    }
  }
}

export function getAnimationSxForVisibilityRules(visibilityOption?: IDefnFormVisibilityOptions)
{
  const visibilityOptionsSx: SxProps = {
    animation: ""
  };

  const shakeKeyframes = {
    "0%, 100%": {
      transform: "translateX(0)"
    },
    "10%, 30%, 50%, 70%, 90%": {
      transform: `translateX(${px(-gapHalf)})`
    },
    "20%, 40%, 60%, 80%": {
      transform: `translateX(${px(gapHalf)})`
    }
  };
  const shakeAnimation = keyframes`${shakeKeyframes}`;

  const blinkKeyFrames = {
    "0%": {
      opacity: 1
    },
    "50%": {
      opacity: 0
    },
    "100%": {
      opacity: 1
    }
  };
  const blinkAnimation = keyframes`${blinkKeyFrames}`;

  const highlightKeyFrames = {
    "0%": {
      backgroundColor: theme.common.bgcolorHighlight
    },
    "100%": {
      backgroundColor: theme.common.bgcolorHighlight
    }
  };
  const highlightAnimation = keyframes`${highlightKeyFrames}`;

  if(visibilityOption)
  {
    const animation: string[] = [];
    const duration = "0.75s";
    const times = 1;

    if(visibilityOption.blinkDurationMs && visibilityOption.blinkDurationMs > 0)
    {
      animation.push(`${blinkAnimation} ${duration} linear ${times}`);
    }
    if(visibilityOption.shakeDurationMs && visibilityOption.shakeDurationMs > 0)
    {
      animation.push(`${shakeAnimation} ${duration} linear ${times}`);
    }
    if(visibilityOption.highlightDurationMs && visibilityOption.highlightDurationMs > 0)
    {
      animation.push(`${highlightAnimation} ${duration} linear ${times}`);
    }
    if(visibilityOption.invisible !== undefined)
    {
      if(visibilityOption.invisible)
      {
        visibilityOptionsSx.visibility = "hidden";
      }
      else
      {
        visibilityOptionsSx.visibility = "visible";
      }
    }
    if(visibilityOption.hidden)
    {
      visibilityOptionsSx.display = "none";
    }
    if(animation.length > 0)
    {
      visibilityOptionsSx.animation = animation.join(", ");
    }
  }

  return visibilityOptionsSx;
}

export function getValidFormMenuActionIdSet(formStore: FormStore, formId?: MetaIdForm)
{
  const actionMap = formStore.actionMap;

  const actionIdSet = [] as MetaIdAction[];

  if(actionMap && actionMap.keys.length > 0)
  {
    actionMap.keys.forEach(metaIdAction =>
    {
      const action = actionMap.map[metaIdAction];

      switch(action.kind)
      {
        case "report":
          actionIdSet.push(metaIdAction);
          break;

        case "rowInsert":
        {
          const actionSpreadsheetInsert = action as StudioEntActionRowInsert;
          const spreadsheetId = actionSpreadsheetInsert?.spreadsheetId;
          const spreadsheet = spreadsheetId
            ? formStore.spreadsheetMap?.map[spreadsheetId]
            : undefined;

          if(!(spreadsheet?.partitionMap && spreadsheet?.partitionMap.keys?.length))
          {
            actionIdSet.push(metaIdAction);
          }
        }
          break;

        case "spreadsheetEditor":
          actionIdSet.push(metaIdAction);
          break;
      }
    });
  }

  return actionIdSet;
}

export function getFormSectionCompositeId(formStore?: FormStore, metaIdForm?: MetaIdForm): MetaIdComposite[] | undefined
{
  const compMap = metaIdForm
    ? formStore?.formMap?.map[metaIdForm]?.compositeMap
    : undefined;

  let compositeIdSet: MetaIdComposite[] = [];

  if(compMap && compMap?.map)
  {
    compMap.keys.forEach((item) =>
    {
      const composite = compMap.map[item];
      if(composite.type === "section")
      {
        compositeIdSet.push(item);
      }
    });
  }

  return compositeIdSet;
}

export function getDefnDtoColorToCssColor(color?: DefnDtoColor): CssColor | undefined
{
  const colorVal = color?.value;
  const shade = color?.shade;

  return colorVal ? theme.common.colorWithShade(colorVal, shade) as CssColor : undefined;
}

export function getExcludeVarIdSet(
  filterOptionSet: EnumDefnPlacement[],
  varMap?: StudioVarMap): MetaIdVar[]
{
  const varIdSet = [] as MetaIdVar[];

  varMap?.keys.forEach((varId) =>
  {
    const variable = varMap.map[varId];
    if(variable.kind === "placement")
    {
      const placement = variable as StudioVarPlacement;
      const varValue = placement.value;
      if(varValue && !filterOptionSet.includes(varValue))
      {
        varIdSet.push(varId);
      }
    }
  });

  return varIdSet;
}

export function getCombinedFieldId(fieldId: string[])
{
  return fieldId.join("/");
}

export function getDefnFieldPadding(defnField: DefnField)
{
  return {
    pt: px(defnField.pt),
    pb: px(defnField.pb),
    pl: px(defnField.pl),
    pr: px(defnField.pr)
  };
}

export function toFieldFormListItemsKey(key: string)
{
  if(key)
  {
    const index = key.indexOf("[");
    if(index >= 0)
    {
      const fieldFormListId = key.substring(0, index);
      const dto = findSubStrBetweenPrefixAndSuffix(key, "[", "]");
      if(dto && dto.length === 4)
      {
        return {
          fieldFormListId,
          rowId: dto[1],
          compId: dto[3]
        };
      }
    }

  }
}

export function dtoToFieldFormListItemsKey(
  defnFieldArray: DefnFieldFormList,
  rowId: RowId,
  fieldId: MetaIdField)
{
  return `${defnFieldArray.metaId}[map][${rowId}][valueMap][${fieldId}]`;
}

const dynamicFieldValueId = "dynamicValueId";

export function getDynamicValueFieldId(fieldId: string)
{
  return toComboId(fieldId, dynamicFieldValueId);
}

export function isDynamicValueFieldId(fieldId: string)
{
  return comboToAboutId(fieldId) === dynamicFieldValueId;
}

export function isNumericField(type: EnumDefnCompType)
{
  return [
    "number",
    "decimal",
    "counter",
    "logNumber",
    "logDecimal",
    "logCounter"
  ].includes(type);
}

export function isUserDefnField(type: EnumDefnCompType)
{
  return (["userId", "refUser", "pickUser", "setOfUser"] as EnumDefnCompType[]).includes(type);
}

export function getFieldName(fieldId: MetaIdField, form: MetaIdForm, formStore: FormStore): string
{
  const formMap = formStore.formMap?.map;
  const compositeMap = formMap?.[form]?.compositeMap;

  const compositeKey = compositeMap?.keys.find(key =>
  {
    const fieldMap = compositeMap?.map[key].fieldMap;
    return fieldMap?.keys.includes(fieldId);
  });

  if(compositeKey)
  {
    const fieldMap = compositeMap?.map[compositeKey].fieldMap;
    const field = fieldMap?.map[fieldId] as StudioComp;
    return field.details.name;
  }

  return "";
}

export function formatMobileNumber(phoneNumber: string): string | undefined
{
  const phoneUtil = PhoneNumberUtil.getInstance();

  try
  {
    // Try to parse the number as-is, assuming it includes the country code
    const number = phoneUtil.parse(phoneNumber);

    if(phoneUtil.isValidNumber(number))
    {
      return phoneUtil.format(number, PhoneNumberFormat.E164);
    }
  }
  catch(error)
  {
    return phoneNumber;
  }
}

export function getCaptureUser(userAvatar?: SigUserAvatar): FieldValueEntUserId | undefined
{
  if(userAvatar)
  {
    return {
      value: userAvatar.entUserId,
      displayField: userAvatar.nickName
    };
  }
}
