import {StudioEnt} from "../../api/meta/base/dto/StudioEnt";
import {StoreItemId} from "../../api/meta/base/Types";
import {MsgStudioEntMerge} from "../../api/store/store/msg/MsgStudioEntMerge";
import {RpcStore} from "../../api/store/store/RpcStore";
import ISrvc from "../../base/ISrvc";
import {updateEnt} from "../../cache/studio/ent/SliceCacheStudioEnt";
import {store} from "../../Store";
import {Srvc} from "../Srvc";
import SrvcStudioApp from "./app/SrvcStudioApp";
import {SrvcStudioDrawer} from "./drawer/SrvcStudioDrawer";
import SrvcStudioEnt from "./ent/SrvcStudioEnt";
import SrvcStudioPlugin from "./plugin/SrvcStudioPlugin";

export default class SrvcStudio extends ISrvc
{
  // TODO Neel/Aditya move this in app package
  public readonly drawer = new SrvcStudioDrawer(state => state.studio.app.studioArtifactPicker);
  public readonly ent = new SrvcStudioEnt();
  public readonly plugin = new SrvcStudioPlugin();
  public readonly app = new SrvcStudioApp();

  handleMergeFromStore = (storeItemId: StoreItemId[], studioEnt: StudioEnt) =>
  {
    Srvc.studio.studioEntMerge(
      studioEnt,
      storeItemId,
      (_studioEnt) =>
      {
        Srvc.app.toast.showSuccessToast("Enterprise successfully merged");
        store.dispatch(updateEnt({
          ent: _studioEnt,
          version: ""
        }));
      }
    );
  };

  constructor()
  {
    super();

    this.setSrvcArray(
      this.drawer,
      this.ent,
      this.plugin
    );
  }

  studioEntMerge(
    studioEnt: StudioEnt,
    storeItemIdSet: StoreItemId[],
    cbSuccess?: (studioEnt: StudioEnt) => void)
  {
    const msg: MsgStudioEntMerge = {
      studioEnt: studioEnt,
      storeItemIdSet: storeItemIdSet
    };

    RpcStore.storeItemEntMerge(msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }
      else
      {
        cbSuccess && envSig.sig?.studioEnt && cbSuccess(envSig.sig?.studioEnt);
      }
    });
  }
}
