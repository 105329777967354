import {useEffect} from "react";
import {useState} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {StudioMapOfFuncArg} from "../../../api/meta/base/dto/StudioMapOfFuncArg";
import {TimeZoneKey} from "../../../api/meta/base/Types";
import {EnumDefnDate} from "../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../../api/meta/base/Types";
import {EnumDefnUserSettingOptions} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {IFormRef} from "../../../base/types/TypesForm";
import {fieldSetOfUsersFormId} from "../../form/builder/base/TypesFormBuilder";
import {fieldKeyName} from "../../form/builder/base/TypesFormBuilder";
import {CbOnClickForm} from "../../form/viewer/base/CtxForm";
import {fieldMapOfTextSourceFormId} from "../builder/VariableBuilderMapOfText";
import {fieldFieldMap} from "../builder/VariableBuilderMapping";
import {fieldGridMap} from "../builder/VariableBuilderMapping";
import {fnVariableBuilderMapping} from "../builder/VariableBuilderMapping";
import {getCompDefaultVariableValue} from "../VariableBuilder";

// variable -> types

export const compVariableDeployType = "deploy";

//variable
export const fieldVariableValue = "value/value"; // to set validation error on value field

export const fieldVariableDefaultProperty = "defaultProperty";

// variable -> function
export const fieldVariableFunctionInputList = "inputFuncArgMap";

// variable -> mapping
export const fieldMappingFromLabel = "fieldMappingFromLabel";
export const fieldMappingToLabel = "fieldMappingToLabel";
export const fieldMappingFromType = "fieldMappingFromType";
export const fieldMappingToType = "fieldMappingToType";
export const fieldMappingCopyGridKeysLabel = "copyGridKeysLabel";
export const fieldGridMappingVariantInsert = "insertVariant";
export const fieldGridMappingVariantUpdate = "updateVariant";
export const fieldGridMappingVariantRemove = "removeVariant";
export const fieldGridMappingVariantEmpty = "emptyFieldVariant";
export const fieldMappingCopyFieldKeysLabel = "copyFieldKeysLabel";
export const fieldFieldMappingVariantInsert = "insert";
export const fieldFieldMappingVariantUpdate = "update";
export const fieldFieldMappingVariantEmpty = "empty";

export const fieldVariableMappingFromFormId = "fromFormId";
export const fieldVariableMappingFromGridId = "fromGridId";
export const fieldVariableMappingFromGridKey = "fromGridKey";
export const fieldVariableMappingFromKey = "fromKey";
export const fieldVariableMappingFromPluginId = "fromPluginId";

export const fieldVariableMappingToFormId = "toFormId";
export const fieldVariableMappingToGridId = "toGridId";
export const fieldVariableMappingToGridKey = "toGridKey";
export const fieldVariableMappingToKey = "toKey";
export const fieldVariableMappingToPluginId = "toPluginId";

// variable -> condition
export const fieldVariableConditionTree = "node";
export const fieldVariableConditionSourceType = "sourceType";
export const fieldVariableConditionSourcePluginId = "fromSourcePluginId";
export const fieldVariableConditionSourceFormId = "fromSourceFormId";
export const fieldVariableConditionSourceGridId = "fromSourceGridId";
export const fieldVariableConditionInputFormId = "inputFormId";
export const fieldVariableLabel = "fieldVariableLabel";

//variable -> time
export const fieldValueTimeEnum = "fieldValueTime";

//variable -> image
export const fieldVariableValueImage = "valueImage";

//variable -> user setting
export const fieldVariableUserSettingValueKind = "kind";

// VariableValueTab

export type TypeFromToType = "regular" | "plugin";

export function useVariableValueInput(
  variableType?: EnumStudioVarKind,
  cbFormRef?: IFormRef,
  varId?: MetaIdVar,
  showHyperlinkValueOnly?: boolean,
  filterOptionSet?: string[],
  dateTimeFormat?: string,
  timeZone?: TimeZoneKey
)
{
  const [dateVariable, setDateVariable] = useState<EnumDefnDate>("now");
  const [variableDateType, setVariableDateType] = useState<EnumDefnDate>("now");
  const [variableTimeType, setVariableTimeType] = useState<string>("now");
  const [variableName, setVariableName] = useState("");
  const [variableFunctionInputList, setVariableFunctionInputList] = useState<StudioMapOfFuncArg>({
    map: {},
    keys: []
  });
  const [userVariableType, setUserVariableType] = useState<EnumDefnUserSettingOptions>("text");

  const [variableConditionSourceType, setVariableConditionSourceType] = useState<TypeFromToType>(cbFormRef?.getValue
    && cbFormRef?.getValue(fieldVariableConditionSourceType));
  const [variableConditionTreeSourceForm, setVariableConditionTreeSourceForm] = useState<MetaIdForm>("");
  const [variableConditionTreeInputForm, setVariableConditionTreeInputForm] = useState<MetaIdForm>("");
  const [variableConditionTreeSourceGrid, setVariableConditionTreeSourceGrid] = useState<MetaIdField>("");
  const [variableMapOfTextFormId, setVariableMapOfTextFormId] = useState<MetaIdForm | undefined>();
  const [variableConditionSourcePluginId, setVariableConditionSourcePluginId] = useState<MetaIdPlugin | undefined>();
  const [setOfUsersFormId, setSetOfUsersFormId] = useState<MetaIdForm>("");

  const variableFunctionNameAndParams = useMemo(() => getVariableFunctionParams(
    variableName, variableFunctionInputList), [variableName, variableFunctionInputList]);

  const {
    onWatch: onWatchMapping,
    getCompDefaultVariableValueMapping,
    onFormClick
  } = useVariableValueMapping(variableType, cbFormRef, varId);

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(!variableType)
    {
      return;
    }
    onWatchMapping(key, value);
    if(key === fieldVariableValue)
    {
      switch(variableType)
      {
        case "date":
          setDateVariable(value);
          break;
        case "dateTime":
          setVariableDateType(value);
          break;
        case "time":
          setVariableTimeType(fnFieldValueToRawValue("pickText", value) as string);
      }
    }

    switch(key)
    {
      case fieldKeyName:
        setVariableName(fnFieldValueToRawValue("symbol", value) as string);
        break;
      case fieldVariableFunctionInputList:
        setVariableFunctionInputList(value);
        break;
      case fieldVariableUserSettingValueKind:
        setUserVariableType(value);
        break;
      case fieldVariableConditionSourceFormId:
        setVariableConditionTreeSourceForm(value);
        break;
      case fieldVariableConditionSourceGridId:
        setVariableConditionTreeSourceGrid(value);
        break;
      case fieldVariableConditionInputFormId:
        setVariableConditionTreeInputForm(value);
        break;
      case fieldMapOfTextSourceFormId:
        setVariableMapOfTextFormId(value);
        break;
      case fieldVariableConditionSourceType :
        setVariableConditionSourceType(fnFieldValueToRawValue("pickText", value) as TypeFromToType);
        break;
      case fieldVariableConditionSourcePluginId :
        setVariableConditionSourcePluginId(fnFieldValueToRawValue("pickImportPluginId", value) as MetaIdPlugin);
        break;
      case fieldSetOfUsersFormId:
        setSetOfUsersFormId(value);
        break;
    }

  }, [variableType, onWatchMapping]);

  const variablePropertyTab = useMemo(() =>
    variableType
      ? variableType === "mapping"
        ? getCompDefaultVariableValueMapping()
        : getCompDefaultVariableValue(variableType,
          variableFunctionNameAndParams,
          dateVariable,
          variableDateType,
          userVariableType,
          variableConditionSourceType,
          variableConditionTreeSourceForm,
          variableConditionTreeSourceGrid,
          variableConditionTreeInputForm,
          varId,
          showHyperlinkValueOnly,
          variableMapOfTextFormId,
          variableConditionSourcePluginId,
          undefined,
          dateTimeFormat,
          variableTimeType,
          setOfUsersFormId,
          timeZone
        )
      : {}, [
    getCompDefaultVariableValueMapping,
    variableType,
    variableFunctionNameAndParams,
    dateVariable,
    variableDateType,
    userVariableType,
    variableConditionSourceType,
    variableConditionTreeSourceForm,
    variableConditionTreeSourceGrid,
    variableConditionTreeInputForm,
    varId,
    variableMapOfTextFormId,
    variableConditionSourcePluginId,
    filterOptionSet,
    setOfUsersFormId,
    variableTimeType,
    timeZone
  ]);

  return {
    variablePropertyTab: variablePropertyTab,
    onWatch: onWatch,
    onFormClick: onFormClick
  };
}

function useVariableValueMapping(variableType?: EnumStudioVarKind, cbFormRef?: IFormRef, varId?: MetaIdVar)
{
  // from
  const [mappingFromType, setMappingFromType] = useState<TypeFromToType>(
    cbFormRef?.getValue && cbFormRef?.getValue(fieldMappingFromType));
  const [mappingFromPlugin, setMappingFromPlugin] = useState<MetaIdPlugin>();
  const [mappingFromForm, setMappingFromForm] = useState<MetaIdForm>();
  const [mappingFromGrid, setMappingFromGrid] = useState<MetaIdField>();
  //to
  const [mappingToType, setMappingToType] = useState<TypeFromToType>(
    cbFormRef?.getValue && cbFormRef?.getValue(fieldMappingToType));
  const [mappingToPlugin, setMappingToPlugin] = useState<MetaIdPlugin>();
  const [mappingToForm, setMappingToForm] = useState<MetaIdForm>();
  const [mappingToGrid, setMappingToGrid] = useState<MetaIdField>();

  const [isLoaded, setIsLoaded] = useState(false);

  const getCompDefaultVariableValueMapping = useCallback(() =>
  {
    const {getVarComp} = fnVariableBuilderMapping(fieldVariableValue);

    return getVarComp(
      mappingFromType,
      mappingFromPlugin,
      mappingFromForm,
      mappingFromGrid,
      mappingToType,
      mappingToPlugin,
      mappingToForm,
      mappingToGrid
    );
  }, [
    mappingFromType, mappingFromPlugin, mappingFromForm, mappingFromGrid,
    mappingToType, mappingToPlugin, mappingToForm, mappingToGrid
  ]);

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    switch(key)
    {
      case fieldMappingFromType:
        setMappingFromType(fnFieldValueToRawValue("pickText", value) as TypeFromToType);
        break;
      case fieldMappingToType:
        setMappingToType(fnFieldValueToRawValue("pickText", value) as TypeFromToType);
        break;
      case fieldVariableMappingFromFormId:
        const fromFormId = fnFieldValueToRawValue("pickFormId", value) as MetaIdForm;
        setMappingFromForm(fromFormId);

        if(isLoaded)
        {
          cbFormRef?.setValue(fieldGridMap, null);
          cbFormRef?.setValue(fieldFieldMap, null);
          cbFormRef?.setValue(fieldVariableMappingFromKey, null);
          cbFormRef?.setValue(fieldVariableMappingFromGridKey, null);
          cbFormRef?.setValue(fieldVariableMappingFromGridId, null);
        }
        break;
      case fieldVariableMappingToFormId:
        const toFormId = fnFieldValueToRawValue("pickFormId", value) as MetaIdForm;
        setMappingToForm(toFormId);
        if(!toFormId)
        {
          cbFormRef?.setValue(fieldVariableMappingToGridId, null);
        }

        if(isLoaded)
        {
          cbFormRef?.setValue(fieldGridMap, null);
          cbFormRef?.setValue(fieldFieldMap, null);
          cbFormRef?.setValue(fieldVariableMappingToKey, null);
          cbFormRef?.setValue(fieldVariableMappingToGridKey, null);
          cbFormRef?.setValue(fieldVariableMappingToGridId, null);
        }
        break;
      case fieldVariableMappingFromGridId:
        setMappingFromGrid(fnFieldValueToRawValue("pickGridId", value) as MetaIdGrid);

        if(isLoaded)
        {
          cbFormRef?.setValue(fieldGridMap, null);
          cbFormRef?.setValue(fieldFieldMap, null);
          cbFormRef?.setValue(fieldVariableMappingFromGridKey, null);
        }
        break;
      case fieldVariableMappingToGridId:
        setMappingToGrid(fnFieldValueToRawValue("pickGridId", value) as MetaIdGrid);

        if(isLoaded)
        {
          cbFormRef?.setValue(fieldGridMap, null);
          cbFormRef?.setValue(fieldFieldMap, null);
          cbFormRef?.setValue(fieldVariableMappingToGridKey, null);
        }
        break;
      case fieldVariableMappingFromPluginId:
        setMappingFromPlugin(fnFieldValueToRawValue("pickImportPluginId", value) as MetaIdPlugin);
        break;
      case fieldVariableMappingToPluginId:
        setMappingToPlugin(fnFieldValueToRawValue("pickImportPluginId", value) as MetaIdPlugin);
        break;
    }
  }, [variableType, mappingToType, mappingFromType, isLoaded]);

  const onFormClick = useCallback<CbOnClickForm>((metaId, variant, value) =>
  {
    if(variant === "field")
    {
      const optionValue = fnFieldValueToRawValue("pickText", value);
      setIsLoaded(true);

      switch(metaId)
      {
        // region mapping
        case fieldMappingFromType:
          if(optionValue === "regular")
          {
            cbFormRef?.setValue(fieldVariableMappingFromFormId, null);
            cbFormRef?.setValue(fieldVariableMappingFromGridId, null);
          }
          else if(optionValue === "plugin")
          {
            cbFormRef?.setValue(fieldVariableMappingFromPluginId, null);
            cbFormRef?.setValue(fieldVariableMappingFromFormId, null);
            cbFormRef?.setValue(fieldVariableMappingFromGridId, null);
          }
          break;
        case fieldMappingToType:
          if(optionValue === "regular")
          {
            cbFormRef?.setValue(fieldVariableMappingToFormId, null);
            cbFormRef?.setValue(fieldVariableMappingToGridId, null);
          }
          else if(optionValue === "plugin")
          {
            cbFormRef?.setValue(fieldVariableMappingToPluginId, null);
            cbFormRef?.setValue(fieldVariableMappingToFormId, null);
            cbFormRef?.setValue(fieldVariableMappingToGridId, null);
          }
          break;
        //endregion

        // region mapping
        case fieldVariableConditionSourceType:
          if(optionValue === "regular")
          {
            cbFormRef?.setValue(fieldVariableConditionSourcePluginId, null);
            cbFormRef?.setValue(fieldVariableConditionSourceFormId, null);
            cbFormRef?.setValue(fieldVariableConditionSourceGridId, null);
            cbFormRef?.setValue(fieldVariableConditionTree, null);
          }
          else if(optionValue === "plugin")
          {
            cbFormRef?.setValue(fieldVariableConditionSourceFormId, null);
            cbFormRef?.setValue(fieldVariableConditionSourceGridId, null);
            cbFormRef?.setValue(fieldVariableConditionTree, null);
          }
          break;
        //endregion
      }
    }
  }, []);

  useEffect(() =>
  {
    setIsLoaded(false);
  }, [varId]);

  return {
    getCompDefaultVariableValueMapping: getCompDefaultVariableValueMapping,
    onWatch: onWatch,
    onFormClick: onFormClick
  };
}

function getVariableFunctionParams(variableName: string, studioMapOfFuncArg?: StudioMapOfFuncArg)
{
  let str = "function ";
  str += variableName;
  str += "(";
  studioMapOfFuncArg?.keys?.forEach((key, index) =>
  {
    const input = studioMapOfFuncArg.map[key];
    const keyLength = studioMapOfFuncArg.keys?.length ?? 0;
    str += `${input.name}${!input.required ? "?" : ""}: ${input.funcArgKind}${index
    !== keyLength - 1 ? ", " : ""}`;
  });
  str += ")";

  return str;
}

export function getVariableSelectListMap(variableType?: EnumStudioVarKind): Record<MetaIdField, SelectList>
{
  if(!variableType)
  {
    return {};
  }

  const valueKey = fieldVariableValue;//  getCombinedString([fieldVariableValue, variableType]);

  return {
    ...variableType === "setOfText" && {
      [valueKey]: state => state.studio.app.variableSetOfTextList
    },
    ...variableType === "setOfDate" && {
      [valueKey]: state => state.studio.app.variableCalendarList
    },
    ...variableType === "function" && {
      [fieldVariableFunctionInputList]: state => state.studio.app.variableFunctionInputList
    },
    ...variableType === "setOfNumber" && {
      [valueKey]: state => state.studio.app.variableSetOfNumberList
    },
    ...variableType === "mapOfText" && {
      [valueKey]: state => state.studio.app.variableMapOfText
    }
  } as Record<MetaIdField, SelectList>;
}
