import {Divider} from "@mui/material";
import {Typography} from "@mui/material";
import {Box} from "@mui/material";
import {Stack} from "@mui/material";
import {cloneDeep} from "lodash";
import {useMemo} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {useFieldArray} from "react-hook-form";
import AutoSizer from "react-virtualized-auto-sizer";
import {Virtuoso} from "react-virtuoso";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioBuildMapping} from "../../../../api/meta/base/dto/DefnStudioBuildMapping";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickPluginFieldId} from "../../../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {StudioDtoMappingField} from "../../../../api/meta/base/dto/StudioDtoMappingField";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {loopDefnForm} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getStudioArrayKey} from "../../../../base/plus/StringPlus";
import {loopStudioForm} from "../../../../base/plus/StudioFormPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import IFormSectionCtx from "../base/CtxFormSection";
import FieldFactory from "../base/FieldFactory";
import {getFieldTypes} from "../base/FieldVarMappingPlus";
import {fieldMappingToKey} from "../base/FieldVarMappingPlus";
import {fieldMappingFromKey} from "../base/FieldVarMappingPlus";
import FieldBase from "../raw/FieldBase";

const fieldMainSection = "mainSection";
const fieldLabel = "label";

export function FieldStudioBuildMapping(props: {
  defn: DefnStudioBuildMapping,
  defnTheme: DefnDtoFormTheme,
  defnForm: DefnFormUi,
})
{
  const formCtx = useFormCtx();
  const hookFormCtx = useFormContext();

  const defn = props.defn;
  const defnTheme = props.defnTheme;
  const defnForm = props.defnForm;

  const fieldId = getFieldKey(defn);
  const formSectionCtx = useRef({} as IFormSectionCtx);
  const formStore = formCtx.getStore();

  const control = formCtx.control();
  formSectionCtx.current.getParent = () => defn;
  formSectionCtx.current.getDefnForm = () => defnForm;
  formSectionCtx.current.getDefn = (key) => defnForm.compMap[key] as DefnFieldUi;

  const fnResolver = useMemo(() => formStore
    ? fnUseStudioResolver(formStore)
    : undefined, [formStore]);

  const fromFormId = defn.from?.formId;
  const toFormId = defn.to?.formId;
  const fromPluginId = defn.from?.pluginId;
  const toPluginId = defn.to?.pluginId;

  const fromForm = fromFormId
    ? formStore?.formMap?.map[fromFormId]
    : undefined;

  const toForm = toFormId
    ? formStore?.formMap?.map[toFormId]
    : undefined;

  const toGridId = defn.to?.compositeIdSet?.length
    ? defn.to.compositeIdSet[0]
    : undefined;

  const fromGridId = defn.from?.compositeIdSet?.length
    ? defn.from.compositeIdSet[0]
    : undefined;

  const fromPluginForm = fromPluginId && fromFormId
    ? formStore?.pluginMap?.map[fromPluginId].pluginFormMap[fromFormId]
    : undefined;

  const toPluginForm = toPluginId && toFormId
    ? formStore?.pluginMap?.map[toPluginId].pluginFormMap[toFormId]
    : undefined;

  const fromGridName = (fromGridId && fromFormId) ? fnResolver?.getGridName(fromFormId, fromGridId) : undefined;
  const toGridName = (toGridId && toFormId) ? fnResolver?.getGridName(toFormId, toGridId) : undefined;
  const fromFormName = fromFormId ? fnResolver?.getFormName(fromFormId) : undefined;
  const toFormName = toFormId ? fnResolver?.getFormName(toFormId) : undefined;
  const pluginFromFormName = (fromPluginId && fromFormId)
    ? fnResolver?.getPluginFormName(fromPluginId, fromFormId)
    : undefined;
  const pluginFromToName = (toPluginId && toFormId)
    ? fnResolver?.getPluginFormName(toPluginId, toFormId)
    : undefined;
  const pluginToGridFormName = (toFormId && toPluginId && toGridId)
    ? fnResolver?.getPluginGridName(toPluginId, toFormId, toGridId)
    : undefined;
  const pluginFromGridName = (fromGridId && fromFormId && fromPluginId) ?
    fnResolver?.getPluginGridName(fromPluginId, fromFormId, fromGridId) : undefined;
  const headerFrom = fromGridName ? fromFormName + "." + fromGridName : fromFormName;
  const headerTo = toGridName ? toFormName + "." + toGridName : toFormName;
  const headerPluginFrom = pluginFromGridName ? pluginFromFormName + "." + pluginFromGridName : pluginFromFormName;
  const headerPluginTo = pluginToGridFormName ? pluginFromToName + "." + pluginToGridFormName : pluginFromToName;

  const form = headerFrom ?? headerPluginFrom;
  const to = headerTo ?? headerPluginTo;

  const fieldIdFormFieldTypeMap = {} as Record<MetaIdField, EnumDefnCompType>;

  if(fromForm)
  {
    loopStudioForm(fromForm, (_, field) =>
    {
      if(field.metaId && field.type)
      {
        fieldIdFormFieldTypeMap[field.metaId] = field.type;
      }
    });
  }
  else if(fromPluginForm)
  {
    loopDefnForm(fromPluginForm, (_, field) =>
    {
      if(field.metaId && field.type)
      {
        fieldIdFormFieldTypeMap[field.metaId] = field.type;
      }
    });
  }

  if(toForm)
  {
    loopStudioForm(toForm, (_, field) =>
    {
      if(field.metaId && field.type)
      {
        fieldIdFormFieldTypeMap[field.metaId] = field.type;
      }
    });
  }
  else if(toPluginForm)
  {
    loopDefnForm(toPluginForm, (_, field) =>
    {
      if(field.metaId && field.type)
      {
        fieldIdFormFieldTypeMap[field.metaId] = field.type;
      }
    });
  }

  const {
    fields
  } = useFieldArray({
    control,
    name: fieldId
  });

  const [defaultFieldValueSet] = useState(hookFormCtx.getValues && hookFormCtx.getValues()[fieldId]);

  const [fieldValueSet, setFieldValueSet] = useState<StudioDtoMappingField[] | undefined>(defaultFieldValueSet);

  useEffect(() =>
  {
    let subscription = hookFormCtx.watch((values) =>
    {
      setFieldValueSet(cloneDeep(values[fieldId]));
    });

    return () =>
    {
      if(subscription !== undefined)
      {
        subscription.unsubscribe();
      }
    };
  }, []);

  const padding = {
    pl: defn.pl,
    pr: defn.pr,
    pb: defn.pb,
    pt: defn.pt
  };

  return (
    <FieldBase
      fieldId={fieldId}
      width={"100%"}
      flexGrow={1}
      {...padding}
    >
      <AutoSizer>
        {({
          height,
          width
        }) =>
        {
          return (
            <Virtuoso
              totalCount={fields.length}
              style={{
                height: height,
                width: width
              }}
              itemContent={(index) =>
              {
                if(index === 0)
                {
                  return (
                    <>
                      <Stack
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-around"}
                        pb={px(gapHalf)}
                      >
                        <Box
                          flex={4}
                          component={"span"}
                        >
                          <Typography sx={{pl: px(gapStd), pb: px(gapQuarter)}}>{form}</Typography>
                        </Box>

                        <Box
                          flex={1.5}
                          component={"span"}
                        >
                          <Typography sx={{pr: px(gapStd), pb: px(gapQuarter)}}>{to}</Typography>
                        </Box>
                      </Stack>

                      <Divider
                        sx={{
                          width: "100%",
                          borderWidth: px(gapQuarter),
                          borderColor: theme.common.bgcolorDefault
                        }}
                      />

                      <RealFieldMapping
                        fields={fields}
                        index={index}
                        defnForm={defnForm}
                        defnTheme={defnTheme}
                        defn={defn}
                        fieldIdFormFieldTypeMap={fieldIdFormFieldTypeMap}
                        fieldValueSet={fieldValueSet}
                      />
                    </>
                  );
                }
                else
                {
                  return (
                    <RealFieldMapping
                      fields={fields}
                      index={index}
                      defnForm={defnForm}
                      defnTheme={defnTheme}
                      defn={defn}
                      fieldIdFormFieldTypeMap={fieldIdFormFieldTypeMap}
                      fieldValueSet={fieldValueSet}
                    />
                  );
                }

              }}
            />

          );
        }}
      </AutoSizer>

    </FieldBase>
  );
}

function RealFieldMapping(
  props: {
    defn: DefnStudioBuildMapping,
    fields: Record<"id", string>[],
    index: number,
    defnForm: DefnFormUi,
    defnTheme: DefnDtoFormTheme,
    fieldIdFormFieldTypeMap: Record<string, EnumDefnCompType>,
    fieldValueSet?: StudioDtoMappingField[]
  }
)
{
  const {
    fields,
    fieldIdFormFieldTypeMap,
    defnForm,
    defn,
    defnTheme,
    index,
    fieldValueSet
  } = props;

  const fieldValue = fieldValueSet?.[index];

  const to = fieldValue?.to;
  let filterFieldTypeSet = undefined as EnumDefnCompType[] | undefined;
  const fieldId = getFieldKey(defn);

  if(to)
  {
    filterFieldTypeSet = getFieldTypes(fieldIdFormFieldTypeMap[to]);
  }

  const fromMetaId = getStudioArrayKey(fieldId, index, fieldMappingFromKey);
  const labelMetaId = getStudioArrayKey(fieldId, index, fieldLabel);
  const toMetaId = getStudioArrayKey(fieldId, index, fieldMappingToKey);

  const _defnForm = getDefnForm(defn, filterFieldTypeSet);

  const defnFrom = _defnForm.compMap[fieldMappingFromKey] as DefnFieldUi;
  const defnLabel = _defnForm.compMap[fieldLabel] as DefnFieldUi;
  const defnTo = _defnForm.compMap[fieldMappingToKey] as DefnFieldUi;

  defnFrom.metaId = fromMetaId;
  defnFrom.name = fromMetaId;
  defnFrom._key = fromMetaId;

  defnLabel.metaId = labelMetaId;
  defnLabel.name = labelMetaId;
  defnLabel._key = labelMetaId;

  defnTo.metaId = toMetaId;
  defnTo.name = toMetaId;
  defnTo._key = toMetaId;

  return (
    <Stack
      key={fields[index].id}
      id={fields[index].id}
      direction={"row"}
      paddingBottom={1}
      paddingTop={2}
    >
      <Box
        flex={4}
        component={"span"}
      >
        <FieldFactory
          key={defnFrom.metaId}
          defnTheme={defnTheme}
          defnForm={defnForm}
          defnComp={defnFrom}
        />
      </Box>

      <Box
        flex={1}
        component={"span"}
      >
        <FieldFactory
          key={defnLabel.metaId}
          defnTheme={defnTheme}
          defnForm={defnForm}
          defnComp={defnLabel}
        />
      </Box>

      <Box
        flex={2}
        component={"span"}
      >
        <FieldFactory
          key={defnTo.metaId}
          defnTheme={defnTheme}
          defnForm={defnForm}
          defnComp={defnTo}
        />
      </Box>
    </Stack>
  );
}

const getDefnForm = (
  defn: DefnStudioBuildMapping,
  fromSelectedFieldType?: EnumDefnCompType[]) =>
{
  const from = defn.from;
  const to = defn.to;

  const fromPluginId = from?.pluginId;
  const fromFormId = from?.formId;
  const fromCompositeIdSet = from?.compositeIdSet;

  const toPluginId = to?.pluginId;
  const toCompositeIdSet = to?.compositeIdSet;
  const toFormId = to?.formId;

  return createDefaultDefnFormStudio({
    ...(fromPluginId !== undefined || toPluginId !== undefined)
      ? {
        [fieldMappingFromKey]: {
          type: "studioBuildArgBinder",
          name: fieldMappingFromKey,
          metaId: fieldMappingFromKey,
          label: "From",
          filterFieldTypeSet: fromSelectedFieldType,
          filterDerivedFieldTypeSet: fromSelectedFieldType,
          compositeIdSet: fromCompositeIdSet,
          derivedCompositeIdSet: toCompositeIdSet,
          formId: fromFormId,
          derivedFormId: toFormId,
          pluginId: fromPluginId,
          derivedPluginId: toPluginId,
          includeOptionMap: arrayToMapOfOption(EnumArrayDefnFields, true),
          filterKindSet: !fromFormId ? ["field"] : undefined,
          filterConstantFieldTypeSet: fromSelectedFieldType
        } as DefnStudioBuildArgBinder
      }
      : {
        [fieldMappingFromKey]: {
          type: "studioBuildArgBinder",
          name: fieldMappingFromKey,
          metaId: fieldMappingFromKey,
          label: "From",
          filterFieldTypeSet: fromSelectedFieldType,
          filterDerivedFieldTypeSet: fromSelectedFieldType,
          compositeIdSet: fromCompositeIdSet,
          derivedCompositeIdSet: toCompositeIdSet,
          formId: fromFormId,
          derivedFormId: toFormId,
          includeOptionMap: arrayToMapOfOption(EnumArrayDefnFields, true),
          filterKindSet: !fromFormId ? ["field"] : undefined,
          filterConstantFieldTypeSet: fromSelectedFieldType
        } as DefnStudioBuildArgBinder
      },

    ...toPluginId !== undefined
      ? {
        [fieldMappingToKey]: {
          type: "pickPluginFieldId",
          name: fieldMappingToKey,
          metaId: fieldMappingToKey,
          label: "To",
          compositeIdSet: toCompositeIdSet,
          formId: toFormId,
          pluginId: toPluginId,
          showCompositeName: true,
          disabled: true
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldMappingToKey]: {
          type: "pickFieldId",
          name: fieldMappingToKey,
          metaId: fieldMappingToKey,
          label: "To",
          compositeIdSet: toCompositeIdSet,
          formId: toFormId,
          showCompositeName: true,
          disabled: true
        } as DefnStudioPickFieldId
      },

    [fieldLabel]: {
      type: "label",
      name: fieldLabel,
      metaId: fieldLabel,
      label: "->",
      textSize: "h5",
      bold: false,
      justifyText: "center"
    } as DefnFieldLabel,

    [fieldMainSection]: {
      type: "section",
      name: fieldMainSection,
      metaId: fieldMainSection,
      sectionDirection: "horizontal",
      fieldSpanMap: {
        [fieldMappingFromKey]: 4,
        [fieldLabel]: 1,
        [fieldMappingToKey]: 2
      } as Record<MetaIdField, number>,
      fieldIdSet: [fieldMappingFromKey, fieldLabel, fieldMappingToKey]
    } as DefnSection,

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: [fieldMainSection]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
};


