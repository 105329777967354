import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {SigSpreadsheetRowExpiry} from "../../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {DtoMessagePayloadSpreadsheetRow} from "../../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {SigUserAvatar} from "../../../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {DefnDtoParagraph} from "../../../../api/meta/base/dto/DefnDtoParagraph";
import {DefnDtoText} from "../../../../api/meta/base/dto/DefnDtoText";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {MediaId} from "../../../../api/meta/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFormName} from "../../../../base/plus/FormPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import {RootState} from "../../../../Store";
import BubbleRawForm from "../raw/bubbleForm/BubbleRawForm";

export default function BubbleForm(props: {
  //data
  receiptStatus?: EnumReceiptStatus,
  payload?: DtoMessagePayloadSpreadsheetRow,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  titleColor?: TypeTextColor;
  creationTime: string,
  searchWords?: string[],
  sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry
  defnForm?: DefnForm,
  sigSpreadsheetRow?: SigSpreadsheetRow,
  chatPatternVar?: DefnDtoParagraph;
  chatLabelPatternVar?: DefnDtoText;
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined,
  getMediaSrc?: (state: RootState, mediaId?: MediaId) => string | undefined,

  // ui
  maxWidth?: number,
  variant?: Variant;
  header?: IBubbleHeader,
  showGridAsHyperlink?: boolean,
  isStar?: boolean,
  isBubbleBlinking?: boolean,
  isSelected?: boolean,
  isFormWithMedia?: boolean,
  showComments?: boolean,
  hideTitle?: boolean,
  hideSectionName?: boolean,
  hideFooter?: boolean,
  isInvisibleSpreadsheetRow?: boolean,
  canExpire?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  showShadow?: boolean,
  shakeFieldIdMap?: Record<MetaIdField, number>;
  blinkFieldIdMap?: Record<MetaIdField, number>;
  hiddenFieldIdMap?: Record<MetaIdField, boolean>;
  highlightFieldIdMap?: Record<MetaIdField, number>;

  // callbacks
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickFooterComments?: CbMenuAnchor,
  cbOnClickFooter?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
})
{

  return (
    <BubbleRawForm
      {...props}
      formValue={props.sigSpreadsheetRow?.formValue}
      rowCommentCount={props.sigSpreadsheetRow?.rowCommentCount}
      updatedKeySet={props.sigSpreadsheetRow?.updatedKeySet}
      title={getFormName(props.defnForm, props.sigSpreadsheetRow?.formValue, props.chatLabelPatternVar)}
      titleColor={props.titleColor}
    />
  );
}

