import {CircularProgress} from "@mui/material";
import {Property} from "csstype";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {EnumStoreItemArtifact} from "../../../api/meta/base/Types";
import {EnumStoreLabel} from "../../../api/meta/base/Types";
import {StoreItemId} from "../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../base/plus/ConstantsPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {listReset} from "../../../base/slices/list/SliceList";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPickType} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSearch} from "../../../base/slices/list/SliceListSharedActions";
import {IListItem} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {useAppSelector} from "../../../nucleus/app/AppHooks";
import LayoutFlexCol from "../../../nucleus/atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../nucleus/atom/layout/LayoutFlexRow";
import {IPaneOverlayStackRef} from "../../../nucleus/atom/pane/PaneOverlayStack";
import PaneOverlayStack from "../../../nucleus/atom/pane/PaneOverlayStack";
import RawIconStrip from "../../../nucleus/atom/raw/RawIconStrip";
import RawNothingHere from "../../../nucleus/atom/raw/RawNothingHere";
import RawSearch from "../../../nucleus/atom/raw/RawSearch";
import {useAppCtx} from "../../../nucleus/ctx/CtxApp";
import {usePageCtx} from "../../../nucleus/ctx/CtxPage";
import {List} from "../../../nucleus/list/List";
import {ListClickVariant} from "../../../nucleus/list/List";
import {Srvc} from "../../../srvc/Srvc";

export default function UiStoreItemPicker(props: {
  allowListPickMany?: boolean,
  defaultStoreItemIdSet?: StoreItemId[],
  defaultCategoryKindSet?: EnumStoreLabel[],
  searchPlaceholder?: string,
  bgcolor?: Property.BackgroundColor,
  showBorder?: boolean
  pl?: Property.PaddingLeft,
  pr?: Property.PaddingRight,
  artifactKind?: EnumStoreItemArtifact,
  showStoreItemPicker?: boolean,
  isWizard?: boolean,
  cbOnChangeCategoryKindSet?: (categoryKindSet: EnumStoreLabel[]) => void,
  cbOnClickStoreItem?: (storeItemId: StoreItemId, pickValue?: boolean) => void,
})
{
  const pageCtx = usePageCtx();
  const appCtx = useAppCtx();

  const allowListPickMany = props.allowListPickMany;
  const defaultStoreItemIdSet = props.defaultStoreItemIdSet;
  const defaultCategoryKindSet = props.defaultCategoryKindSet;
  const cbOnClickStoreItem = props.cbOnClickStoreItem;
  const cbOnChangeCategoryKindSet = props.cbOnChangeCategoryKindSet;
  const showBorder = props.showBorder;
  const showStoreItemPicker = props.showStoreItemPicker;
  const isWizard = props.isWizard;
  const pl = props.pl;
  const pr = props.pr;
  const bgcolor = props.bgcolor ?? theme.common.bgcolorContent;
  const artifactKind: EnumStoreItemArtifact = props.artifactKind ?? "ent";

  const selectFilterList = Srvc.store.drawer.selectStoreFilterList;
  const selectItemList = Srvc.store.drawer.selectStoreList;
  const srvcRecentList = Srvc.store.drawer.recentList;

  const storeItemListName = useAppSelector(state => selectItemList(state).listName);
  const storeItemVersion = useAppSelector(state => selectItemList(state).version);
  const storeItemIdAdminSet = useAppSelector(state => state.cache.app.caller.storeItemIdAdminSet);

  const [pickFilterItemIds, setPickFilterItemIds] = useState<Record<TypeListItemId, boolean>>();

  const listBinder = useMemo(() => srvcRecentList.getStoreItemPickerListBinder(), [storeItemVersion]);
  const stackCbRef = {} as IPaneOverlayStackRef;

  const onClickStoreItem = useCallback((
    itemId: TypeListItemId,
    item: IListItem,
    pickValue?: boolean,
    selected?: boolean) =>
  {
    if(!selected)
    {
      appCtx.setMainProps(undefined);
      return;
    }

    cbOnClickStoreItem && cbOnClickStoreItem(itemId as StoreItemId, pickValue);

  }, [cbOnClickStoreItem, allowListPickMany]);

  const onItemSubscribe = useCallback((itemId: StoreItemId) =>
  {
    srvcRecentList.subscribe(itemId);
  }, [srvcRecentList]);

  const onItemUnsubscribe = useCallback((itemId: StoreItemId) =>
  {
    srvcRecentList.subscribe(itemId, true);
  }, [srvcRecentList]);

  const cbOnClick = useCallback((
    menuAnchor: Element,
    itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant,
    pickValue?: boolean,
    selected?: boolean) =>
  {
    switch(variant)
    {
      case "listItem":
        !allowListPickMany && pageCtx.showDialog(undefined);
        onClickStoreItem(itemId, item, pickValue, selected);
        break;
    }
  }, [onClickStoreItem]);

  useEffect(() =>
  {
    srvcRecentList.rpcStoreItemListGetForPicker(
      storeItemListName,
      selectItemList,
      artifactKind,
      defaultCategoryKindSet
        ? defaultCategoryKindSet
        : pickFilterItemIds
          ? Object.keys(pickFilterItemIds) as EnumStoreLabel[]
          : undefined
    );
  }, [
    storeItemListName,
    srvcRecentList,
    storeItemIdAdminSet,
    pickFilterItemIds,
    defaultCategoryKindSet
  ]);

  useEffect(() =>
  {
    return () =>
    {
      dispatchList(storeItemListName, listReset());
    };

  }, [storeItemListName]);

  useEffect(() =>
  {
    if(pickFilterItemIds)
    {
      cbOnChangeCategoryKindSet && cbOnChangeCategoryKindSet(Object.keys(pickFilterItemIds) as EnumStoreLabel[]);
    }

  }, [pickFilterItemIds]);

  useEffect(() =>
  {
    if(allowListPickMany)
    {
      dispatchList(storeItemListName, listSetPickType("pickMany"));
    }

  }, [allowListPickMany, storeItemListName, storeItemVersion]);

  useEffect(() =>
  {
    if(defaultStoreItemIdSet)
    {
      defaultStoreItemIdSet.forEach(storeItemId =>
      {
        dispatchList(storeItemListName, listSetPick({
          itemId: storeItemId,
          pickValue: true
        }));
      });
    }

  }, [storeItemListName, defaultStoreItemIdSet, storeItemVersion]);

  return (
    <PaneOverlayStack
      width={400}
      cbRef={stackCbRef}
      transition={"back"}
    >
      <LayoutFlexCol
        height={"100%"}
        width={"100%"}
        bgcolor={bgcolor}
      >
        <LayoutFlexRow
          width={"100%"}
        >
          <LayoutFlexCol
            flexGrow={1}
          >
            <RawSearch
              bgColor={"transparent"}
              placeHolder={props.searchPlaceholder ?? "Search templates"}
              onSearchBindListName={storeItemListName}
            />
          </LayoutFlexCol>

          <LayoutFlexCol
            alignItems={"start"}
            mr={px(gapStd)}
          >
            <RawIconStrip
              iconStrip={["filter"]}
              onClick={(iconName) =>
              {
                if(iconName === "filter")
                {
                  stackCbRef.showOverlay(
                    <RealFilterList
                      selectFilterList={selectFilterList}
                      onClose={(pickItemIds) =>
                      {
                        setPickFilterItemIds(pickItemIds);
                        stackCbRef.closeOverlay(true);
                      }}
                      bgcolor={props.bgcolor}
                      showBorder={showBorder}
                      pl={pl}
                      pr={pr}
                      defaultCategoryKindSet={defaultCategoryKindSet}
                    />
                  );
                }
              }}
              iconStripDisable={(!showStoreItemPicker && isWizard) ? ["filter"] : undefined}
            />
          </LayoutFlexCol>
        </LayoutFlexRow>

        <LayoutFlexCol
          width={"100%"}
          flexGrow={1}
          pl={pl}
          pr={pr}
        >
          <LayoutFlexCol
            width={"100%"}
            height={"100%"}
            bgcolor={theme.common.bgcolorContent}
            border={showBorder
              ? `1px solid ${theme.common.borderColor}`
              : undefined}
          >
            {!storeItemVersion
              ? <CircularProgress />
              : isWizard
                ? showStoreItemPicker
                  ? <List
                    selectList={selectItemList}
                    emptyComp={STR_NOTHING_HERE}
                    emptySearch={STR_NOTHING_HERE}
                    listBinder={listBinder}
                    onClickListItem={cbOnClick}
                    onItemSubscribe={onItemSubscribe}
                    onItemUnsubscribe={onItemUnsubscribe}
                  />
                  : <RawNothingHere
                    helperTextData={{
                      title: "Pick template labels first"
                    }}
                  />
                : <List
                  selectList={selectItemList}
                  emptyComp={STR_NOTHING_HERE}
                  emptySearch={STR_NOTHING_HERE}
                  listBinder={listBinder}
                  onClickListItem={cbOnClick}
                  onItemSubscribe={onItemSubscribe}
                  onItemUnsubscribe={onItemUnsubscribe}
                />
            }
          </LayoutFlexCol>
        </LayoutFlexCol>

      </LayoutFlexCol>
    </PaneOverlayStack>
  );
}

function RealFilterList(props: {
  selectFilterList: SelectList,
  onClose: (pickItemIds?: Record<TypeListItemId, boolean>) => void,
  bgcolor?: Property.BackgroundColor,
  showBorder?: boolean,
  pl?: Property.PaddingLeft,
  pr?: Property.PaddingRight,
  defaultCategoryKindSet?: EnumStoreLabel[],
})
{
  const srvcFilter = Srvc.store.drawer.filter;

  const selectFilterList = props.selectFilterList;
  const defaultCategoryKindSet = props.defaultCategoryKindSet;

  const filterListName = useAppSelector(state => selectFilterList(state).listName);
  const pickItemIds = useAppSelector(state => selectFilterList(state).pickItemIds);
  const filterVersion = useAppSelector(state => selectFilterList(state).version);

  const isFilterIconDisable = !Boolean(pickItemIds && Object.keys(pickItemIds).length > 0);

  const bgcolor = props.bgcolor ?? theme.common.bgcolorContent;

  const [filterSet, setFilterSet] = useState<EnumStoreLabel[]>([]);

  useEffect(() =>
  {
    Srvc.store.storeItem.rpcStoreFilterListGet(filterVersion, (env) =>
    {
      setFilterSet(env.storeFilterSet);
    });

  }, []);

  useEffect(() =>
  {
    if(filterSet.length > 0)
    {
      srvcFilter.loadPicker(filterListName, filterSet as EnumStoreLabel[]);
    }

    return () =>
    {
      dispatchList(filterListName, listReset());
    };

  }, [filterListName, filterSet, srvcFilter]);

  useEffect(() =>
  {
    if(defaultCategoryKindSet)
    {
      defaultCategoryKindSet.forEach(categoryKind =>
      {
        dispatchList(filterListName, listSetPick({
          itemId: categoryKind,
          pickValue: true
        }));
      });
    }

  }, [filterListName, defaultCategoryKindSet, filterSet, filterVersion]);

  return (
    <LayoutFlexCol
      height={"100%"}
      width={"100%"}
      bgcolor={bgcolor}
    >
      <LayoutFlexRow
        width={"100%"}
      >
        <LayoutFlexCol
          alignItems={"start"}
          ml={px(gapStd)}
        >
          <RawIconStrip
            iconStrip={["backIos"]}
            onClick={(iconName) =>
            {
              if(iconName === "backIos")
              {
                dispatchList(filterListName, listSetSearch(undefined));
                props.onClose(pickItemIds);
              }
            }}
          />
        </LayoutFlexCol>

        <LayoutFlexRow
          flexGrow={1}
        >
          <RawSearch
            bgColor={"transparent"}
            placeHolder={"Search labels"}
            onSearchBindListName={filterListName}
          />
        </LayoutFlexRow>

        <LayoutFlexCol
          alignItems={"start"}
          mr={px(gapStd)}
        >
          <RawIconStrip
            iconStrip={["filterClear"]}
            onClick={(iconName) =>
            {
              if(iconName === "filterClear")
              {
                srvcFilter.clearFilter(filterListName);
              }
            }}
            iconStripDisable={isFilterIconDisable ? ["filterClear"] : []}
          />
        </LayoutFlexCol>
      </LayoutFlexRow>

      <LayoutFlexCol
        width={"100%"}
        flexGrow={1}
        pl={props.pl}
        pr={props.pr}
      >
        <LayoutFlexCol
          width={"100%"}
          height={"100%"}
          bgcolor={theme.common.bgcolorContent}
          border={props.showBorder
            ? `1px solid ${theme.common.borderColor}`
            : undefined}
        >
          {!filterVersion
            ? <CircularProgress />
            : <List
              selectList={selectFilterList}
              emptyComp={STR_NOTHING_HERE}
              emptySearch={STR_NOTHING_HERE}
            />
          }
        </LayoutFlexCol>
      </LayoutFlexCol>
    </LayoutFlexCol>
  );
}

