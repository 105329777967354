import {cloneDeep} from "lodash";
import {useMemo} from "react";
import React from "react";
import {useState} from "react";
import {useCallback} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {SigReportFieldData} from "../../../api/ent/entAside/sig/SigReportFieldData";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {isGridId} from "../../../api/meta/base/ApiPlus";
import {DefnFieldPickReportRow} from "../../../api/meta/base/dto/DefnFieldPickReportRow";
import {DefnFieldRefReport} from "../../../api/meta/base/dto/DefnFieldRefReport";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnLayoutGrid} from "../../../api/meta/base/dto/DefnLayoutGrid";
import {FieldDtoGridRow} from "../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueGrid} from "../../../api/meta/base/dto/FieldValueGrid";
import {FieldValueText} from "../../../api/meta/base/dto/FieldValueText";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {MetaIdComposite} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {getFormFieldValueAsTextWithPrefixSuffix} from "../../../base/plus/FieldValuePlus";
import {publicRole} from "../../../base/plus/FormPlus";
import {filterForm} from "../../../base/plus/FormPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {isProd} from "../../../base/plus/SysPlus";
import {listSetSearch} from "../../../base/slices/list/SliceListSharedActions";
import {listSetShowPicksOnly} from "../../../base/slices/list/SliceListSharedActions";
import {IFormRef} from "../../../base/types/TypesForm";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {useAppSelector} from "../../../nucleus/app/AppHooks";
import helperTextData from "../../../nucleus/atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../../../nucleus/atom/raw/RawNothingHere";
import {FieldValueFormList} from "../../../nucleus/form/viewer/uiOnly/FieldFormList";
import SsBr from "../../../nucleus/ssBr/SsBr";
import {ISsBrRef} from "../../../nucleus/ssBr/SsBr";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import UiRefBrForm from "./base/UiRefBrForm";

type TypeReportRefField = DefnFieldRefReport | DefnFieldPickReportRow | undefined;
export default function UiReportBr(props: {
  entId: EntId,
  defnForm: DefnForm,
  refFieldId: MetaIdField;
  compositeId: MetaIdComposite;
  isMultiPick: boolean;
  enableSelection?: boolean,
  formValue?: FormValue,        // for single select
  gridValue?: FieldValueGrid,     // for multi select
  gridRow?: FieldDtoGridRow,
  cbOnSubmit?: (value: FieldValues) => void,
})
{
  const srvc = Srvc.app.form.reportBr;
  const formRef = useRef<IFormRef>({} as IFormRef);
  const ssBrRef = useRef({} as ISsBrRef);
  const selectList = srvc.selectList;

  const entId = props.entId;
  const defnForm = props.defnForm;
  const refFieldId = props.refFieldId;
  const [formValue] = useState(cloneDeep(props.formValue));
  const [gridValue] = useState(cloneDeep(props.gridValue));
  const compositeId = props.compositeId;
  const enableSelection = props.enableSelection;
  const isMultiPick = props.isMultiPick;
  const isGrid = isGridId(compositeId);
  const gridRow = isGrid ? props.gridRow : undefined;
  const fieldFormList = srvc.fnGetRefBrListFieldName();
  const formId = defnForm.metaId;
  const [displayForm, setDisplayForm] = useState<DefnForm>();
  const [sourceDefnForm, setSourceDefnForm] = useState<DefnForm>();
  const [initValues, setInitValues] = useState<FieldValues>();
  const [loading, setLoading] = useState(true);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [submitEnable, setSubmitEnable] = useState(false);

  const listName = useAppSelector(state => selectList(state).listName);
  const refField = useMemo(() => defnForm?.compMap[refFieldId] as TypeReportRefField, []);

  const layout = useMemo(() =>
  {
    if(refField?.type === "pickReportRow")
    {
      return (refField as DefnFieldPickReportRow).reportOutputFormGridLayout;
    }
    else if(refField?.type === "refReport")
    {
      return (refField as DefnFieldRefReport).layoutGrid;
    }
  }, [refField]);

  const overlayLayout = useMemo(() =>
  {
    if(refField?.type === "refReport")
    {
      return (refField as DefnFieldRefReport).overlayLayoutGrid;
    }
  }, [refField]);

  const showSelectedItems = useCallback((showSelectedItems: boolean) =>
  {
    dispatchList(listName, listSetShowPicksOnly(showSelectedItems));
  }, [listName]);

  const cbResult = useCallback((sig?: SigReportFieldData) =>
  {
    const rootState = store.getState();
    const callerEnt = selectCallerEnt(rootState, entId);
    const sourceForm = sig?.reportOutputForm
      ? filterForm(sig?.reportOutputForm, callerEnt?.roleIdSet || [publicRole], callerEnt)
      : undefined;

    setSourceDefnForm(sourceForm);

    if(sig?.reportOutputFormValue)
    {
      const gridId = getReportRefGridId(refField);

      const gridValue = gridId
        ? sig.reportOutputFormValue.valueMap[gridId] as FieldValueGrid
        : undefined;

      if(layout && defnForm && sourceForm)
      {
        const displayForm = srvc.fnGetDisplayForm(
          sourceForm,
          defnForm,
          layout,
          overlayLayout,
          isMultiPick,
          enableSelection
        );
        setDisplayForm(displayForm);
      }

      if(layout && defnForm && sourceForm && gridValue?.keys)
      {
        const rowList = gridValue?.keys.map((rowId) => ({
          formValue: gridValue.map[rowId]
        } as SigSpreadsheetRow));

        const userAvatarMap = rootState.cache.app.user.userAvatarMap;

        const value = srvc.calcFieldValues(
          defnForm,
          layout,
          sourceForm,
          overlayLayout,
          rowList,
          callerEnt
            ? srvc.insertEntUserNameInFormField(entId, sourceForm, userAvatarMap, callerEnt)
            : undefined
        );

        const initValues = {
          [fieldFormList as string]: value
        };
        setInitValues(initValues);
      }

    }
    setLoading(false);
  }, [refField, defnForm, entId, fieldFormList, isMultiPick, layout, overlayLayout, srvc, enableSelection]);

  const cbOnSubmit = (value: FieldValues, fieldDtoGridRow?: FieldDtoGridRow) =>
  {
    if(refField?.type === "pickReportRow")
    {
      const gridDisplayFieldId = (refField as DefnFieldPickReportRow).gridDisplayFieldId;
      if(!isMultiPick && fieldDtoGridRow)
      {
        const valueMap = fieldDtoGridRow?.valueMap;
        const comp = gridDisplayFieldId
          ? sourceDefnForm?.compMap[gridDisplayFieldId]
          : undefined;

        const val = valueMap && gridDisplayFieldId
          ? valueMap[gridDisplayFieldId]
          : undefined;

        value[refField.metaId] = {
          value: getFormFieldValueAsTextWithPrefixSuffix(comp, val)
        } as FieldValueText;

        props.cbOnSubmit && props.cbOnSubmit(value);
      }
    }
    else
    {
      props.cbOnSubmit && props.cbOnSubmit(value);
    }
  };

  useEffect(() =>
  {
    const gridId = isGrid ? compositeId : undefined;
    Srvc.cache.app.callerEnt.rpcEntUserAvatarListGet(entId, () =>
    {
      srvc.rpcEntReportFieldDataGet({
        entId: entId,
        formId: formId,
        refReportFieldId: refFieldId,
        inputFormValue: srvc.fnInsertGridRowInInputFormValue(formValue, gridId, gridRow),
        inputFormCompositeId: compositeId,
        inputFormGridRowId: gridRow?.rowId,
        cbSuccess: envSig => cbResult(envSig.sig)
      });
    });

  }, [cbResult, compositeId, entId, formId, formValue, gridRow, isGrid, refFieldId, srvc]);

  useEffect(() =>
  {
    const value = initValues?.[fieldFormList] as FieldValueFormList | undefined;

    ssBrRef.current.setFooterPayload &&
    ssBrRef.current.setFooterPayload({
      totalItems: value?.keys.length || 0,
      selectedItems: selectedItemsCount,
      submitEnable: submitEnable
    });

  }, [selectedItemsCount, initValues?.[fieldFormList], submitEnable]);

  useEffect(() =>
  {
    const formValue = !isMultiPick ? ((gridRow as FormValue) || props.formValue) : undefined;
    const keyFieldIdSet = refField?.type === "refReport" ? (refField as DefnFieldRefReport).keyFieldIdSet : undefined;
    const _gridValue = keyFieldIdSet?.length ? gridValue : undefined;

    srvc.onMount(refField?.copyFieldMap, _gridValue, keyFieldIdSet, formValue);

    return () => srvc.onUnMount();
  }, [refField]);

  if(loading)
  {
    return <RawNothingHere helperTextData={helperTextData.loadingData} />;
  }
  if(!layout)
  {
    return <RawNothingHere helperTextData={helperTextData.noDefaultLayoutFound} />;
  }

  if(!displayForm || !initValues || !sourceDefnForm)
  {
    if(isProd())
    {
      return <RawNothingHere helperTextData={helperTextData.nothingToShow} />;
    }
    return <RawNothingHere helperTextData={{title: "DisplayForm not found"}} />;
  }

  return (
    <SsBr
      showSearchBar={true}
      onFilterUpdate={(_, searchStr) =>
      {
        dispatchList(listName, listSetSearch(searchStr));
        setTimeout(() =>
        {
          ssBrRef.current.setFilterState?.({state: false});
          ssBrRef.current.setSearchState?.({search: false});
        }, 100);
      }}
      spreadSheetBrContent={
        <UiRefBrForm
          srvcRefBr={srvc}
          entId={entId}
          formId={formId}
          isMultiPick={isMultiPick}
          cbRefForm={formRef.current}
          compositeId={compositeId}
          cbOnSubmit={cbOnSubmit}
          layout={layout}
          overlayLayout={overlayLayout}
          displayForm={displayForm}
          defnForm={defnForm}
          sourceDefnForm={sourceDefnForm}
          selectList={selectList.bind(srvc)}
          initValues={initValues}
          cbOnSubmitEnable={setSubmitEnable}
          cbOnSelectedItemsCountChange={setSelectedItemsCount}
          fieldValueGrid={gridValue}
        />
      }
      cbRef={ssBrRef.current}
      defnFormRef={sourceDefnForm}
      currentLayout={layout as DefnLayoutGrid}
      hideFooter={!isMultiPick}
      onClickSubmit={() => formRef.current.remoteSubmit(true)}
      cbOnClickShowSelected={showSelectedItems}
    />
  );
}

function getReportRefGridId(field?: DefnFieldRefReport | DefnFieldPickReportRow)
{
  if(field?.type === "pickReportRow")
  {
    return (field as DefnFieldPickReportRow).reportOutputFormGridId;
  }
  if(field?.type === "refReport")
  {
    return (field as DefnFieldRefReport).gridId;
  }
  return undefined;
}
