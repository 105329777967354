import {isEqual} from "lodash";
import {useEffect} from "react";
import React, {useCallback, useMemo, useState} from "react";
import {Controller} from "react-hook-form";
import {FieldErrors} from "react-hook-form";
import {DefnStudioMapOfMapping} from "../../../../api/meta/base/dto/DefnStudioMapOfMapping";
import {StudioDtoMappingFieldMapBase} from "../../../../api/meta/base/dto/StudioDtoMappingFieldMapBase";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {ITreeNode, ITreeRef} from "../../../../base/types/TypesTree";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import RawTree from "../../../atom/raw/RawTree";
import {usePageCtx} from "../../../ctx/CtxPage";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {fnGetTreeNodeLabel} from "../base/FieldVarMappingPlus";
import {convertFieldMapToRootNodes} from "../base/FieldVarMappingPlus";
import {convertRootNodesField} from "../base/FieldVarMappingPlus";
import {IMappingVarFunctions} from "../base/FieldVarMappingPlus";
import {useMappingVarFunctions} from "../base/FieldVarMappingPlus";
import {useFieldsMappingFunctions} from "../base/FieldVarMappingPlus";
import {INodeUserFieldMappingVar} from "../base/FieldVarMappingPlus";
import FieldRawTreeShell from "../raw/FieldRawTreeShell";

export default function FieldStudioFieldMappingTree(props: {
  defn: DefnStudioMapOfMapping
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;

  const isFormReadOnly = formCtx.isReadonly();
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const formStore = formCtx.getStore();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        formState: {errors}
      }) =>
      {
        const fieldValue = field.value;
        const onChange = field.onChange;
        const _errors = errors?.["mapping"] ? errors : undefined;
        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          return <></>;
        }
        return (
          <FieldRawFieldMappingTree
            defn={defn}
            isFormReadOnly={isFormReadOnly}
            showAddBtn={true}
            formStore={formStore}
            fieldValue={fieldValue}
            onChange={onChange}
            errors={_errors}
          />
        );
      }}
    />
  );
}

function FieldRawFieldMappingTree(props: {
  defn: DefnStudioMapOfMapping,
  fieldValue?: StudioDtoMappingFieldMapBase,
  showAddBtn?: boolean,
  isFormReadOnly?: boolean,
  formStore?: FormStore,
  errors?: FieldErrors,
  onChange: (value: StudioDtoMappingFieldMapBase) => void,
})
{
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();
  const cbRefTree = useMemo(() => ({} as ITreeRef), []);

  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const errors = props.errors;
  const showAddBtn = props.showAddBtn;
  const isFormReadOnly = Boolean(props.isFormReadOnly);

  const formStore = formCtx.getStore();
  const toFormId = defn.toFormId;
  const fromGridId = defn.fromGridId;
  const toGridId = defn.toGridId;

  const [rootNodes, setRootNodes] = useState<ITreeNode[]>(convertFieldMapToRootNodes(fieldValue, undefined, errors));

  const mappingVarFromFunctions = useMappingVarFunctions("from", defn, formStore);
  const mappingVarToFunctions = useMappingVarFunctions("to", defn, formStore);

  const toForm = mappingVarToFunctions.form;

  const mappingVarFunctions = useCallback((type: "from" | "to"): IMappingVarFunctions =>
  {
    return type === "from"
      ? mappingVarFromFunctions
      : mappingVarToFunctions;
  }, [mappingVarFromFunctions, mappingVarToFunctions]);

  const onChange = (rootNodes: ITreeNode[]) => props.onChange(convertRootNodesField(rootNodes));

  const {
    setMappingField,
    pasteMappingField
  } = useFieldsMappingFunctions({
    defn: defn,
    getMappingVarFunctions: mappingVarFunctions,
    cbRefTree: cbRefTree,
    isFormReadOnly: isFormReadOnly
  });

  const cbOnAddField = useCallback((node: ITreeNode) =>
  {
    const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;

    if(userField)
    {
      const fromCompId = userField.studioDtoMappingComposite?.fromGridId;
      const toCompId = userField.studioDtoMappingComposite?.toGridId;

      if(toForm && toCompId)
      {
        setMappingField(fieldValue, node, fromCompId, toCompId);
      }
    }
  }, [toForm, setMappingField, fieldValue]);

  const cbOnEdit = useCallback((node: ITreeNode) =>
  {
    const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;

    if(userField)
    {
      const type = userField.type;
      if(toForm)
      {
        if(type === "field")
        {
          setMappingField(fieldValue, undefined, fromGridId, toGridId);
        }
      }
    }
  }, [fieldValue, fromGridId, setMappingField, toForm, toGridId]);

  useEffect(() =>
  {
    const newTree = convertFieldMapToRootNodes(fieldValue, undefined, errors);

    if(!isEqual(rootNodes, newTree))
    {
      setRootNodes(newTree);
    }

  }, [mappingVarFunctions, fieldValue]);

  return (
    <>
      <FieldRawTreeShell
        rawTree={
          <RawTree
            showUiFlatTree={true}
            cbRef={cbRefTree}
            rootNodes={rootNodes}
            onChange={(rootNodes) => rootNodes && onChange(rootNodes)}
            getLabel={fnGetTreeNodeLabel(
              isFormReadOnly,
              pageCtx,
              mappingVarFunctions,
              cbRefTree,
              cbOnEdit,
              cbOnAddField,
              pasteMappingField,
              formStore,
              errors
            )}
          />}
        buttonStrip={
          showAddBtn &&
          <RawButtonStrip
            onClick={(iconName) =>
            {
              if(iconName === "add" && toForm)
              {
                setMappingField(fieldValue, undefined, fromGridId, toGridId);
              }
              if(iconName === "paste")
              {
                pasteMappingField();
              }
            }}
            iconButtonList={["paste", "add"]}
            iconButtonDisable={(isFormReadOnly || !toFormId)
              ? ["paste", "add"]
              : undefined
            }
            toolTipMap={{
              paste: "Paste mapping",
              add: "Add mapping"
            } as Record<EnumIconButton, string>}
            labelMap={(fromGridId || toGridId) ? undefined : {
              paste: "PASTE",
              add: "ADD..."
            } as Record<EnumIconButton, string>}
          />
        }
        label={"Mapping"}
      />
    </>
  );
}
