import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldAudio} from "../../../../api/meta/base/dto/DefnFieldAudio";
import {DefnFieldAvtar} from "../../../../api/meta/base/dto/DefnFieldAvtar";
import {DefnFieldButton} from "../../../../api/meta/base/dto/DefnFieldButton";
import {DefnFieldCamera} from "../../../../api/meta/base/dto/DefnFieldCamera";
import {DefnFieldChipSet} from "../../../../api/meta/base/dto/DefnFieldChipSet";
import {DefnFieldChipSetDate} from "../../../../api/meta/base/dto/DefnFieldChipSetDate";
import {DefnFieldChipSetDay} from "../../../../api/meta/base/dto/DefnFieldChipSetDay";
import {DefnFieldChipSetTime} from "../../../../api/meta/base/dto/DefnFieldChipSetTime";
import {DefnFieldCounter} from "../../../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldDate} from "../../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldDateRange} from "../../../../api/meta/base/dto/DefnFieldDateRange";
import {DefnFieldDateTime} from "../../../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldDateTimeRange} from "../../../../api/meta/base/dto/DefnFieldDateTimeRange";
import {DefnFieldDecimal} from "../../../../api/meta/base/dto/DefnFieldDecimal";
import {DefnFieldDivider} from "../../../../api/meta/base/dto/DefnFieldDivider";
import {DefnFieldDocument} from "../../../../api/meta/base/dto/DefnFieldDocument";
import {DefnFieldDuration} from "../../../../api/meta/base/dto/DefnFieldDuration";
import {DefnFieldDynamic} from "../../../../api/meta/base/dto/DefnFieldDynamic";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldEmail} from "../../../../api/meta/base/dto/DefnFieldEmail";
import {DefnFieldError} from "../../../../api/meta/base/dto/DefnFieldError";
import {DefnFieldFormList} from "../../../../api/meta/base/dto/DefnFieldFormList";
import {DefnFieldFormListItem} from "../../../../api/meta/base/dto/DefnFieldFormListItem";
import {DefnFieldGeoPoint} from "../../../../api/meta/base/dto/DefnFieldGeoPoint";
import {DefnFieldHandle} from "../../../../api/meta/base/dto/DefnFieldHandle";
import {DefnFieldHtml} from "../../../../api/meta/base/dto/DefnFieldHtml";
import {DefnFieldHyperlinkRow} from "../../../../api/meta/base/dto/DefnFieldHyperlinkRow";
import {DefnFieldIdentifier} from "../../../../api/meta/base/dto/DefnFieldIdentifier";
import {DefnFieldImage} from "../../../../api/meta/base/dto/DefnFieldImage";
import {DefnFieldInfo} from "../../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldLineStroke} from "../../../../api/meta/base/dto/DefnFieldLineStroke";
import {DefnFieldLocation} from "../../../../api/meta/base/dto/DefnFieldLocation";
import {DefnFieldLogCounter} from "../../../../api/meta/base/dto/DefnFieldLogCounter";
import {DefnFieldLogDecimal} from "../../../../api/meta/base/dto/DefnFieldLogDecimal";
import {DefnFieldLogNumber} from "../../../../api/meta/base/dto/DefnFieldLogNumber";
import {DefnFieldMessageType} from "../../../../api/meta/base/dto/DefnFieldMessageType";
import {DefnFieldMobileNumber} from "../../../../api/meta/base/dto/DefnFieldMobileNumber";
import {DefnFieldMonth} from "../../../../api/meta/base/dto/DefnFieldMonth";
import {DefnFieldNumber} from "../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldOtp} from "../../../../api/meta/base/dto/DefnFieldOtp";
import {DefnFieldParagraph} from "../../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPassword} from "../../../../api/meta/base/dto/DefnFieldPassword";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickGridRow} from "../../../../api/meta/base/dto/DefnFieldPickGridRow";
import {DefnFieldPickOption} from "../../../../api/meta/base/dto/DefnFieldPickOption";
import {DefnFieldPickReportRow} from "../../../../api/meta/base/dto/DefnFieldPickReportRow";
import {DefnFieldPickRole} from "../../../../api/meta/base/dto/DefnFieldPickRole";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldPickTree} from "../../../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldPickUser} from "../../../../api/meta/base/dto/DefnFieldPickUser";
import {DefnFieldPinShape} from "../../../../api/meta/base/dto/DefnFieldPinShape";
import {DefnFieldPropertyMap} from "../../../../api/meta/base/dto/DefnFieldPropertyMap";
import {DefnFieldQuarter} from "../../../../api/meta/base/dto/DefnFieldQuarter";
import {DefnFieldRating} from "../../../../api/meta/base/dto/DefnFieldRating";
import {DefnFieldRef} from "../../../../api/meta/base/dto/DefnFieldRef";
import {DefnFieldRefReport} from "../../../../api/meta/base/dto/DefnFieldRefReport";
import {DefnFieldRefUser} from "../../../../api/meta/base/dto/DefnFieldRefUser";
import {DefnFieldScanCode} from "../../../../api/meta/base/dto/DefnFieldScanCode";
import {DefnFieldSetOfRole} from "../../../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSetOfText} from "../../../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSetOfUser} from "../../../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnFieldShowCode} from "../../../../api/meta/base/dto/DefnFieldShowCode";
import {DefnFieldSignature} from "../../../../api/meta/base/dto/DefnFieldSignature";
import {DefnFieldSlider} from "../../../../api/meta/base/dto/DefnFieldSlider";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {DefnFieldTextSize} from "../../../../api/meta/base/dto/DefnFieldTextSize";
import {DefnFieldTime} from "../../../../api/meta/base/dto/DefnFieldTime";
import {DefnFieldUserId} from "../../../../api/meta/base/dto/DefnFieldUserId";
import {DefnFieldVideo} from "../../../../api/meta/base/dto/DefnFieldVideo";
import {DefnFieldVoice} from "../../../../api/meta/base/dto/DefnFieldVoice";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildActionPermission} from "../../../../api/meta/base/dto/DefnStudioBuildActionPermission";
import {DefnStudioBuildArgBinder} from "../../../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioBuildColor} from "../../../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioBuildDate} from "../../../../api/meta/base/dto/DefnStudioBuildDate";
import {DefnStudioBuildDateTime} from "../../../../api/meta/base/dto/DefnStudioBuildDateTime";
import {DefnStudioBuildMapping} from "../../../../api/meta/base/dto/DefnStudioBuildMapping";
import {DefnStudioBuildPermissionMatrix} from "../../../../api/meta/base/dto/DefnStudioBuildPermissionMatrix";
import {DefnStudioBuildPropertyMap} from "../../../../api/meta/base/dto/DefnStudioBuildPropertyMap";
import {DefnStudioBuildTree} from "../../../../api/meta/base/dto/DefnStudioBuildTree";
import {DefnStudioCodeEditor} from "../../../../api/meta/base/dto/DefnStudioCodeEditor";
import {DefnStudioCompArray} from "../../../../api/meta/base/dto/DefnStudioCompArray";
import {DefnStudioMapOfArgBinder} from "../../../../api/meta/base/dto/DefnStudioMapOfArgBinder";
import {DefnStudioMapOfCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfCondition";
import {DefnStudioMapOfDynamicCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfDynamicCondition";
import {DefnStudioMapOfDynamicRule} from "../../../../api/meta/base/dto/DefnStudioMapOfDynamicRule";
import {DefnStudioMapOfFormula} from "../../../../api/meta/base/dto/DefnStudioMapOfFormula";
import {
  DefnStudioMapOfForwardGroupPermission
} from "../../../../api/meta/base/dto/DefnStudioMapOfForwardGroupPermission";
import {DefnStudioMapOfForwardRolePermission} from "../../../../api/meta/base/dto/DefnStudioMapOfForwardRolePermission";
import {DefnStudioMapOfFuncArg} from "../../../../api/meta/base/dto/DefnStudioMapOfFuncArg";
import {DefnStudioMapOfJarFile} from "../../../../api/meta/base/dto/DefnStudioMapOfJarFile";
import {DefnStudioMapOfLayoutDriveSheet} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutDriveSheet";
import {DefnStudioMapOfLayoutGrid} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutGrid";
import {DefnStudioMapOfLayoutSpreadsheet} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutSpreadsheet";
import {DefnStudioMapOfMapping} from "../../../../api/meta/base/dto/DefnStudioMapOfMapping";
import {DefnStudioMapOfPartition} from "../../../../api/meta/base/dto/DefnStudioMapOfPartition";
import {DefnStudioMapOfPipelineVariable} from "../../../../api/meta/base/dto/DefnStudioMapOfPipelineVariable";
import {DefnStudioMapOfUserCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfUserCondition";
import {DefnStudioMapOfVisibilityAction} from "../../../../api/meta/base/dto/DefnStudioMapOfVisibilityAction";
import {DefnStudioMapOfVisibilityCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfVisibilityCondition";
import {DefnStudioPickActionId} from "../../../../api/meta/base/dto/DefnStudioPickActionId";
import {DefnStudioPickCompId} from "../../../../api/meta/base/dto/DefnStudioPickCompId";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickFormId} from "../../../../api/meta/base/dto/DefnStudioPickFormId";
import {DefnStudioPickGridId} from "../../../../api/meta/base/dto/DefnStudioPickGridId";
import {DefnStudioPickGroupId} from "../../../../api/meta/base/dto/DefnStudioPickGroupId";
import {DefnStudioPickImportPluginApiId} from "../../../../api/meta/base/dto/DefnStudioPickImportPluginApiId";
import {DefnStudioPickImportPluginId} from "../../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {DefnStudioPickLayoutFormContentId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutFormContentId";
import {DefnStudioPickLayoutGridId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnStudioPickLayoutSpreadsheetId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutSpreadsheetId";
import {DefnStudioPickPipelineVarId} from "../../../../api/meta/base/dto/DefnStudioPickPipelineVarId";
import {DefnStudioPickPluginBundleId} from "../../../../api/meta/base/dto/DefnStudioPickPluginBundleId";
import {DefnStudioPickPluginCompId} from "../../../../api/meta/base/dto/DefnStudioPickPluginCompId";
import {DefnStudioPickPluginFieldId} from "../../../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {DefnStudioPickPluginFormId} from "../../../../api/meta/base/dto/DefnStudioPickPluginFormId";
import {DefnStudioPickPluginId} from "../../../../api/meta/base/dto/DefnStudioPickPluginId";
import {DefnStudioPickReportId} from "../../../../api/meta/base/dto/DefnStudioPickReportId";
import {DefnStudioPickSectionId} from "../../../../api/meta/base/dto/DefnStudioPickSectionId";
import {DefnStudioPickSpreadsheetId} from "../../../../api/meta/base/dto/DefnStudioPickSpreadsheetId";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnStudioSetOfAdminDoNotOption} from "../../../../api/meta/base/dto/DefnStudioSetOfAdminDoNotOption";
import {DefnStudioSetOfFieldRefId} from "../../../../api/meta/base/dto/DefnStudioSetOfFieldRefId";
import {DefnStudioSetOfModule} from "../../../../api/meta/base/dto/DefnStudioSetOfModule";
import {DefnStudioSetOfNumber} from "../../../../api/meta/base/dto/DefnStudioSetOfNumber";
import {DefnStudioSetOfRowAuditTrail} from "../../../../api/meta/base/dto/DefnStudioSetOfRowAuditTrail";
import {DefnStudioVarIdParagraphEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdParagraphEditor";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {DefnWizard} from "../../../../api/meta/base/dto/DefnWizard";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import FieldAudio from "../advanced/FieldAudio";
import FieldCamera from "../advanced/FieldCamera";
import FieldChipSet from "../advanced/FieldChipSet";
import FieldColor from "../advanced/FieldColor";
import FieldCounter from "../advanced/FieldCounter";
import FieldDateRange from "../advanced/FieldDateRange";
import FieldDateTime from "../advanced/FieldDateTime";
import FieldDateTimeRange from "../advanced/FieldDateTimeRange";
import FieldDuration from "../advanced/FieldDuration";
import FieldEmail from "../advanced/FieldEmail";
import FieldGeoPoint from "../advanced/FieldGeoPoint";
import FieldHandle from "../advanced/FieldHandle";
import FieldHyperlink from "../advanced/FieldHyperlink";
import FieldLocation from "../advanced/FieldLocation";
import FieldLogCounter from "../advanced/FieldLogCounter";
import FieldMobileNumber from "../advanced/FieldMobileNumber";
import FieldRating from "../advanced/FieldRating";
import FieldScanCode from "../advanced/FieldScanCode";
import FieldSignature from "../advanced/FieldSignature";
import FieldSlider from "../advanced/FieldSlider";
import FieldTime from "../advanced/FieldTime";
import FieldVideo from "../advanced/FieldVideo";
import FieldVoice from "../advanced/FieldVoice";
import FieldDate from "../basic/FieldDate";
import FieldDecimal from "../basic/FieldDecimal";
import {FieldImage} from "../basic/FieldImage";
import FieldLabel from "../basic/FieldLabel";
import FieldLogDecimal from "../basic/FieldLogDecimal";
import FieldLogNumber from "../basic/FieldLogNumber";
import FieldNumber from "../basic/FieldNumber";
import FieldParagraph from "../basic/FieldParagraph";
import FieldSwitch from "../basic/FieldSwitch";
import FieldText from "../basic/FieldText";
import FieldGrid from "../composite/FieldGrid";
import FieldSection from "../composite/FieldSection";
import FieldTab from "../composite/FieldTab";
import FieldWizard from "../composite/FieldWizard";
import FieldPickEnum from "../enum/FieldPickEnum";
import FieldPickEnumConditionOperator from "../enum/FieldPickEnumConditionOperator";
import FieldPickEnumSyncMode from "../enum/FieldPickEnumSyncMode";
import FieldPickOption from "../enum/FieldPickOption";
import FieldDateSet from "../picker/FieldDateSet";
import FieldDateTimeSet from "../picker/FieldDateTimeSet";
import FieldDaySet from "../picker/FieldDaySet";
import FieldDeviceSizeSet from "../picker/FieldDeviceSizeSet";
import FieldDeviceTypeSet from "../picker/FieldDeviceTypeSet";
import FieldEnumPaymentMethodKind from "../picker/FieldEnumPaymentMethodKind";
import FieldMessageKind from "../picker/FieldMessageKind";
import FieldPaymentStatus from "../picker/FieldPaymnetStatus";
import FieldPickCurrency from "../picker/FieldPickCurrency";
import FieldPickDateTimeFormat from "../picker/FieldPickDateTimeFormat";
import FieldPickEnumSet from "../picker/FieldPickEnumSet";
import FieldPickGridRow from "../picker/FieldPickGridRow";
import FieldPickIcon from "../picker/FieldPickIcon";
import FieldPickLanguage from "../picker/FieldPickLanguage";
import FieldPickLineStroke from "../picker/FieldPickLineStroke";
import FieldPickMonth from "../picker/FieldPickMonth";
import FieldPickPinShape from "../picker/FieldPickPinShape";
import FieldPickQuarter from "../picker/FieldPickQuarter";
import FieldPickReportRow from "../picker/FieldPickReportRow";
import FieldPickRole from "../picker/FieldPickRole";
import FieldPickSetOfRole from "../picker/FieldPickSetOfRole";
import FieldPickText from "../picker/FieldPickText";
import FieldPickTimeZone from "../picker/FieldPickTimeZone";
import FieldPickTree from "../picker/FieldPickTree";
import FieldPickUser from "../picker/FieldPickUser";
import FieldRefUser from "../picker/FieldRefUser";
import FieldSetOfLanguage from "../picker/FieldSetOfLanguage";
import FieldSetOfUser from "../picker/FieldSetOfUser";
import FieldTextSize from "../picker/FieldTextSize";
import FieldTimeSet from "../picker/FieldTimeSet";
import FieldButton from "../pro/FieldButton";
import FieldDivider from "../pro/FieldDivider";
import FieldDocument from "../pro/FieldDocument";
import FieldDynamic from "../pro/FieldDynamic";
import FieldError from "../pro/FieldError";
import FieldHtml from "../pro/FieldHtml";
import FieldHyperlinkRow from "../pro/FieldHyperlinkRow";
import FieldIdentifier from "../pro/FieldIdentifier";
import FieldInfo from "../pro/FieldInfo";
import FieldOtp from "../pro/FieldOtp";
import FieldRef from "../pro/FieldRef";
import FieldRefReport from "../pro/FieldRefReport";
import FieldRowId from "../pro/FieldRowId";
import FieldShowCode from "../pro/FieldShowCode";
import FieldSymbol from "../pro/FieldSymbol";
import FieldUserId from "../pro/FieldUserId";
import FieldBase from "../raw/FieldBase";
import FieldPropertyMap from "../studio/FieldPropertyMap";
import FieldSetOfText from "../studio/FieldSetOfText";
import FieldStudioArray from "../studio/FieldStudioArray";
import FieldStudioBuildAllModule from "../studio/FieldStudioBuildAllModule";
import FieldStudioBuildArgBinder from "../studio/FieldStudioBuildArgBinder";
import FieldStudioBuildColor from "../studio/FieldStudioBuildColor";
import FieldStudioBuildDate from "../studio/FieldStudioBuildDate";
import FieldStudioBuildDateTime from "../studio/FieldStudioBuildDateTime";
import {FieldStudioBuildMapping} from "../studio/FieldStudioBuildMapping";
import FieldStudioBuildPermissionMatrix from "../studio/FieldStudioBuildPermissionMatrix";
import FieldStudioBuildPropertyMap from "../studio/FieldStudioBuildPropertyMap";
import FieldStudioBuildTree from "../studio/FieldStudioBuildTree";
import FieldStudioCodeEditor from "../studio/FieldStudioCodeEditor";
import FieldStudioCompositeId from "../studio/FieldStudioCompositeId";
import FieldStudioFieldMappingTree from "../studio/FieldStudioFieldMappingTree";
import FieldStudioGridMappingTree from "../studio/FieldStudioGridMappingTree";
import FieldStudioMapOfActionPermission from "../studio/FieldStudioMapOfActionPermission";
import FieldStudioMapOfArgBinder from "../studio/FieldStudioMapOfArgBinder";
import FieldStudioMapOfCondition from "../studio/FieldStudioMapOfCondition";
import FieldStudioMapOfDynamicCondition from "../studio/FieldStudioMapOfDynamicCondition";
import FieldStudioMapOfDynamicRule from "../studio/FieldStudioMapOfDynamicRule";
import FieldStudioMapOfFormula from "../studio/FieldStudioMapOfFormula";
import FieldStudioMapOfForwardGroupPermission from "../studio/FieldStudioMapOfForwardGroupPermission";
import FieldStudioMapOfForwardRolePermission from "../studio/FieldStudioMapOfForwardRolePermission";
import FieldStudioMapOfFuncArg from "../studio/FieldStudioMapOfFuncArg";
import FieldStudioMapOfJarFile from "../studio/FieldStudioMapOfJarFile";
import FieldStudioMapOfLayoutDriveSpreadsheet from "../studio/FieldStudioMapOfLayoutDriveSpreadsheet";
import FieldStudioMapOfLayoutGrid from "../studio/FieldStudioMapOfLayoutGrid";
import FieldStudioMapOfLayoutSpreadsheet from "../studio/FieldStudioMapOfLayoutSpreadsheet";
import FieldStudioMapOfPartition from "../studio/FieldStudioMapOfPartition";
import FieldStudioMapOfPipelineVariable from "../studio/FieldStudioMapOfPipelineVariable";
import FieldStudioMapOfText from "../studio/FieldStudioMapOfText";
import FieldStudioMapOfUserCondition from "../studio/FieldStudioMapOfUser";
import FieldStudioMapOfVisibilityAction from "../studio/FieldStudioMapOfVisibilityAction";
import FieldStudioMapOfVisibilityCondition from "../studio/FieldStudioMapOfVisibilityCondition";
import FieldStudioPickActionId from "../studio/FieldStudioPickActionId";
import FieldStudioPickDeeplinkId from "../studio/FieldStudioPickDeeplinkId";
import FieldStudioPickFieldId from "../studio/FieldStudioPickFieldId";
import FieldStudioPickFormId from "../studio/FieldStudioPickFormId";
import FieldStudioPickGridId from "../studio/FieldStudioPickGridId";
import FieldStudioPickGroupId from "../studio/FieldStudioPickGroupId";
import FieldStudioPickImportPluginApiId from "../studio/FieldStudioPickImportPluginApiId";
import FieldStudioPickImportPluginId from "../studio/FieldStudioPickImportPluginId";
import FieldStudioPickLayoutFormContentId from "../studio/FieldStudioPickLayoutFormContentId";
import FieldStudioPickLayoutGridId from "../studio/FieldStudioPickLayoutGridId";
import FieldStudioPickLayoutSpreadsheetId from "../studio/FieldStudioPickLayoutSpreadsheetId";
import FieldStudioPickPaymentProviderId from "../studio/FieldStudioPickPaymentProviderId";
import FieldStudioPickPipelineVarId from "../studio/FieldStudioPickPipelineVarId";
import FieldStudioPickPluginCompId from "../studio/FieldStudioPickPluginCompId";
import FieldStudioPickPluginFieldId from "../studio/FieldStudioPickPluginFieldId";
import FieldStudioPickPluginFormId from "../studio/FieldStudioPickPluginFormId";
import FieldStudioPickPluginId from "../studio/FieldStudioPickPluginId";
import FieldStudioPickPluginVersionId from "../studio/FieldStudioPickPluginVersionId";
import FieldStudioPickReportId from "../studio/FieldStudioPickReportId";
import FieldStudioPickSpreadsheetId from "../studio/FieldStudioPickSpreadsheetId";
import FieldStudioPickVarId from "../studio/FieldStudioPickVarId";
import FieldStudioSectionId from "../studio/FieldStudioSectionId";
import FieldStudioSetOfActionId from "../studio/FieldStudioSetOfActionId";
import FieldStudioSetOfAdminDoNotOption from "../studio/FieldStudioSetOfAdminDoNotOption";
import FieldStudioSetOfCompId from "../studio/FieldStudioSetOfCompId";
import FieldStudioSetOfFieldId from "../studio/FieldStudioSetOfFieldId";
import FieldStudioSetOfFieldRefId from "../studio/FieldStudioSetOfFieldRefId";
import FieldStudioSetOfFormId from "../studio/FieldStudioSetOfFormId";
import FieldStudioSetOfGridId from "../studio/FieldStudioSetOfGridId";
import FieldStudioSetOfGroupId from "../studio/FieldStudioSetOfGroupId";
import FieldStudioSetOfLayoutFormContentId from "../studio/FieldStudioSetOfLayoutFormContentId";
import FieldStudioSetOfLayoutGridId from "../studio/FieldStudioSetOfLayoutGridId";
import FieldStudioSetOfLayoutSpreadsheetId from "../studio/FieldStudioSetOfLayoutSpreadsheetId";
import FieldStudioSetOfModule from "../studio/FieldStudioSetOfModule";
import FieldStudioSetOfNumber from "../studio/FieldStudioSetOfNumber";
import FieldStudioSetOfReportId from "../studio/FieldStudioSetOfReportId";
import FieldStudioSetOfRowAuditTrail from "../studio/FieldStudioSetOfRowAuditTrail";
import FieldStudioSetOfSectionId from "../studio/FieldStudioSetOfSectionId";
import FieldStudioSetOfSpreadsheetId from "../studio/FieldStudioSetOfSpreadsheetId";
import FieldStudioSetOfVarId from "../studio/FieldStudioSetOfVarId";
import FieldStudioVarIdParagraph from "../studio/FieldStudioVarIdParagraph";
import FieldStudioVarIdTextEditor from "../studio/FieldStudioVarIdTextEditor";
import FieldAvtar from "../uiOnly/FieldAvtar";
import FieldFormList from "../uiOnly/FieldFormList";
import FieldFormListItem from "../uiOnly/FieldFormListItem";
import FieldPassword from "../uiOnly/FieldPassword";

export default function FieldFactory<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defnComp: DefnFieldUi,
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
})
{
  const defnForm = props.defnForm;
  const defnTheme = props.defnTheme;
  const defnComp = props.defnComp;
  const key = getFieldKey(defnComp);

  switch(defnComp.type)
  {
    //region advanced

    case "audio":
      return <FieldAudio
        key={key}
        defn={defnComp as DefnFieldAudio}
      />;

    case "camera":
      return <FieldCamera
        key={key}
        defn={defnComp as DefnFieldCamera}
      />;

    case "chipSet":
      return <FieldChipSet
        key={key}
        defn={defnComp as DefnFieldChipSet}
      />;

    case "hyperlink":
      return <FieldHyperlink
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "color":
      return <FieldColor
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "counter":
      return <FieldCounter
        key={key}
        defn={defnComp as DefnFieldCounter}
      />;

    case "logCounter":
      return <FieldLogCounter
        key={key}
        defn={defnComp as DefnFieldLogCounter}
      />;

    case "dateRange":
      return <FieldDateRange
        key={key}
        defn={defnComp as DefnFieldDateRange}
      />;

    case "dateTime":
      return <FieldDateTime
        key={key}
        defn={defnComp as DefnFieldDateTime}
      />;

    case "dateTimeRange":
      return <FieldDateTimeRange
        key={key}
        defn={defnComp as DefnFieldDateTimeRange}
      />;

    case "duration":
      return <FieldDuration
        key={key}
        defn={defnComp as DefnFieldDuration}
      />;

    case "email":
      return <FieldEmail
        key={key}
        defn={defnComp as DefnFieldEmail}
      />;

    case "handle":
      return <FieldHandle
        key={key}
        defn={defnComp as DefnFieldHandle}
      />;

    case "location":
      return <FieldLocation
        key={key}
        defn={defnComp as DefnFieldLocation}
      />;

    case "geoPoint":
      return <FieldGeoPoint
        key={key}
        defn={defnComp as DefnFieldGeoPoint}
      />;

    case "mobileNumber":
      return <FieldMobileNumber
        key={key}
        defn={defnComp as DefnFieldMobileNumber}
      />;

    case "rating":
      return <FieldRating
        key={key}
        defn={defnComp as DefnFieldRating}
      />;

    case "signature":
      return <FieldSignature
        key={key}
        defn={defnComp as DefnFieldSignature}
      />;

    case "slider":
      return <FieldSlider
        key={key}
        defn={defnComp as DefnFieldSlider}
      />;

    case "time":
      return <FieldTime
        key={key}
        defn={defnComp as DefnFieldTime}
      />;

    case "video":
      return <FieldVideo
        key={key}
        defn={defnComp as DefnFieldVideo}
      />;

    case "voice":
      return <FieldVoice
        key={key}
        defn={defnComp as DefnFieldVoice}
      />;
    //endregion

    //region basic

    case "bool":
      return <FieldSwitch
        key={key}
        defn={defnComp as DefnFieldSwitch}
      />;

    case "date":
      return <FieldDate
        key={key}
        defn={defnComp as DefnFieldDate}
      />;

    case "decimal":
      return <FieldDecimal
        key={key}
        defn={defnComp as DefnFieldDecimal}
      />;

    case "logDecimal":
      return <FieldLogDecimal
        key={key}
        defn={defnComp as DefnFieldLogDecimal}
      />;

    case "image":
      return <FieldImage
        key={key}
        defn={defnComp as DefnFieldImage}
      />;

    case "label":
      return (
        <FieldBase fieldId={key}>
          <FieldLabel
            key={key}
            defn={defnComp as DefnFieldLabel}
          />
        </FieldBase>
      );

    case "number":
      return <FieldNumber
        key={key}
        defn={defnComp as DefnFieldNumber}
      />;

    case "logNumber":
      return <FieldLogNumber
        key={key}
        defn={defnComp as DefnFieldLogNumber}
      />;

    case "paragraph":
      return <FieldParagraph
        key={key}
        defn={defnComp as DefnFieldParagraph}
      />;

    case "text":
      return <FieldText
        key={key}
        defn={defnComp as DefnFieldText}
      />;

    case "dynamic":
      return <FieldDynamic
        key={key}
        defn={defnComp as DefnFieldDynamic}
        defnForm={defnForm}
        defnTheme={defnTheme}
      />;

    //endregion

    //region composite

    case "grid":
      return <FieldGrid
        key={key}
        defn={defnComp as DefnGrid}
        defnForm={defnForm}
      />;

    case "section":
      return <FieldSection
        key={key}
        defn={defnComp as DefnSection}
        defnForm={defnForm}
        defnTheme={defnTheme}
        listBinderMap={props.listBinderMap}
      />;

    case "tab":
      return <FieldTab
        key={key}
        defn={defnComp as DefnTab}
        defnForm={defnForm}
        defnTheme={defnTheme}
        listBinderMap={props.listBinderMap}
      />;

    case "wizard":
      return <FieldWizard
        key={key}
        defn={defnComp as DefnWizard}
        defnForm={defnForm}
        defnTheme={defnTheme}
        listBinderMap={props.listBinderMap}
      />;

    //endregion

    //region enum

    case "enumArgBinder":
    case "enumArgBinderContext":
    case "enumAudioFormat":
    case "enumAutomationSource":
    case "enumLogOperationKind":
    case "enumCodeEditorLanguage":
    case "enumConjunction":
    case "enumDataPartitionPeriod":
    case "enumDate":
    case "enumDay":
    case "enumDeployVar":
    case "enumDeviceSize":
    case "enumDeviceType":
    case "enumDocFileExt":
    case "enumDurationUnit":
    case "enumEntLockBehavior":
    case "enumEntLockReason":
    case "enumDeeplinkExpiry":
    case "enumFuncArgs":
    case "enumKindAction":
    case "enumKindDeeplink":
    case "enumDeeplinkConstraints":
    case "enumKindAutomation":
    case "enumKindButton":
    case "enumKindFormComposite":
    case "enumKindHyperlink":
    case "enumKindImport":
    case "enumKindRating":
    case "enumKindReport":
    case "enumKindScheduledEvent":
    case "enumKindSpreadsheetEvent":
    case "enumKindAutomationStep":
    case "enumKindTranslation":
    case "enumLayoutCardFilterKind":
    case "enumLayoutGridKind":
    case "enumMapPinShape":
    case "enumPaymentMethod":
    case "enumPaymentPlan":
    case "enumPermission":
    case "enumPluginApiMethod":
    case "enumPluginResources":
    case "enumPluginSecurityAccess":
    case "enumDriveSheetLayoutFor":
    case "enumDriveSheetFieldLayoutOn":
    case "enumTextStyle":
    case "enumPromptAction":
    case "enumRowAuditTrail":
    case "enumRenderingKind":
    case "enumSetupKind":
    case "enumThemeButtonSize":
    case "enumThemeButtonVariant":
    case "enumThemeColor":
    case "enumThemeColorShade":
    case "enumThemeDirection":
    case "enumThemeDividerThickness":
    case "enumThemeFieldMargin":
    case "enumThemeFieldSize":
    case "enumThemeFieldVariant":
    case "enumThemeFormVariant":
    case "enumThemeImageCorner":
    case "enumThemePickVariant":
    case "enumPlacement":
    case "enumThemeSectionVariant":
    case "enumThemeStroke":
    case "enumThemeTabVariant":
    case "enumCalculateFormulaMode":
    case "enumUserSettingOptions":
    case "enumUserSettingValue":
    case "enumVideoFormat":
    case "enumVisibilityAction":
    case "enumVisibilityOperator":
    case "enumDynamicOperator":
    case "enumAdminDoNotOptionPlugin":
    case "enumDriveStatus":
    case "enumFields":
    case "enumRoles":
    case "enumThemePickMultiVariant":
    case "enumMonth":
    case "enumQuarter":
    case "enumFrequencyKind":
    case "enumPosition":
    case "enumDateOccurrence":
    case "enumInsertVariant":
    case "enumUpdateVariant":
    case "enumRemoveVariant":
    case "enumEmptyFieldVariant":
    case "enumTerminateSetting":
    case "enumTableLayoutStyle":
    case "enumUserProperty":
    case "enumRowProperty":
    case "enumStoreItem":
    case "enumCaptureMode":
    case "enumVisibilityActionOn":
    case "enumLockOperation":
    case "enumTargetType":
    case "enumEditorLayoutRenderingMode":
    case "enumRefreshOn":
    case "enumSetOfUserKind":
    case "enumUserContext":
    case "enumGridRenderingMode":
    case "enumSortOrder":
    case "enumPluginMode":
      return <FieldPickEnum
        type={defnComp.type}
        key={key}
        defn={defnComp as DefnFieldPickEnum}
      />;

    case "enumSyncMode":
      return <FieldPickEnumSyncMode
        key={key}
        defn={defnComp as DefnFieldPickEnum}
      />;

    case "enumConditionOperator":
      return <FieldPickEnumConditionOperator
        key={key}
        defn={defnComp as DefnFieldPickEnum}
      />;
    //endregion

    case "pickOption":
      return <FieldPickOption
        key={key}
        defn={defnComp as DefnFieldPickOption}
      />;

    //region picker

    case "currency":
      return <FieldPickCurrency
        key={key}
        defn={defnComp as DefnFieldPickEnum}
      />;

    case "textSize":
      return <FieldTextSize
        key={key}
        defn={defnComp as DefnFieldTextSize}
      />;

    case "pinShape":
      return <FieldPickPinShape
        key={key}
        defn={defnComp as DefnFieldPinShape}
      />;

    case "month":
      return <FieldPickMonth
        key={key}
        defn={defnComp as DefnFieldMonth}
      />;

    case "messageKind":
      return <FieldMessageKind
        key={key}
        defn={defnComp as DefnFieldMessageType}
      />;

    case "quarter":
      return <FieldPickQuarter
        key={key}
        defn={defnComp as DefnFieldQuarter}
      />;

    case "lineStroke":
      return <FieldPickLineStroke
        key={key}
        defn={defnComp as DefnFieldLineStroke}
      />;

    case "icon":
      return <FieldPickIcon
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "language":
      return <FieldPickLanguage
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "pickText":
      return <FieldPickText
        key={key}
        defn={defnComp as DefnFieldPickText}
      />;

    case "timeZone":
      return <FieldPickTimeZone
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "dateFormat":
      return <FieldPickDateTimeFormat
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "paymentStatus":
      return <FieldPaymentStatus
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "pickTree":
      return <FieldPickTree
        key={key}
        defn={defnComp as DefnFieldPickTree}
      />;

    case "pickUser":
      return <FieldPickUser
        key={key}
        defn={defnComp as DefnFieldPickUser}
      />;

    case "pickRole":
      return <FieldPickRole
        key={key}
        defn={defnComp as DefnFieldPickRole}
      />;

    case "refUser":
      return <FieldRefUser
        key={key}
        defn={defnComp as DefnFieldRefUser}
      />;

    case "userId":
      return <FieldUserId
        key={key}
        defn={defnComp as DefnFieldUserId}
      />;

    case "pickGridRow":
      return <FieldPickGridRow
        key={key}
        defn={defnComp as DefnFieldPickGridRow}
      />;

    case "pickReportRow":
      return <FieldPickReportRow
        key={key}
        defn={defnComp as DefnFieldPickReportRow}
      />;

    case "chipSetDate":
      return <FieldDateSet
        key={key}
        defn={defnComp as DefnFieldChipSetDate}
      />;

    case "chipSetDateTime":
      return <FieldDateTimeSet
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "chipSetDay":
      return <FieldDaySet
        key={key}
        defn={defnComp as DefnFieldChipSetDay}
      />;

    case "chipSetDeviceSize":
      return <FieldDeviceSizeSet
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "chipSetDeviceType":
      return <FieldDeviceTypeSet
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "enumPaymentMethodKind":
      return <FieldEnumPaymentMethodKind
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "enumCaptureValueKind":
    case "studioSetOfStoreItemCategory":
    case "studioSetOfPluginSecurityAccess":
    case "studioSetOfDocFileExt":
    case "studioSetOfMonth":
    case "studioSetOfBorder":
    case "studioSetOfDataExportKind":
      return <FieldPickEnumSet
        type={defnComp.type}
        key={key}
        defn={defnComp as DefnFieldPickEnum}
      />;

    case "setOfUser":
      return <FieldSetOfUser
        key={key}
        defn={defnComp as DefnFieldSetOfUser}
      />;

    case "setOfRole":
      return <FieldPickSetOfRole
        key={key}
        defn={defnComp as DefnFieldSetOfRole}
      />;

    case "setOfLanguageKeys":
      return <FieldSetOfLanguage
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "setOfText":
      return <FieldSetOfText
        key={key}
        defn={defnComp as DefnFieldSetOfText}
      />;

    case "chipSetTime":
      return <FieldTimeSet
        key={key}
        defn={defnComp as DefnFieldChipSetTime}
      />;

    //endregion

    //region pro

    case "rowId":
      return <FieldRowId
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "symbol":
      return <FieldSymbol
        key={key}
        defn={defnComp as DefnFieldText}
      />;

    case "button":
      return <FieldButton
        key={key}
        defn={defnComp as DefnFieldButton}
        defnForm={defnForm}
      />;

    case "divider":
      return <FieldDivider
        key={key}
        defn={defnComp as DefnFieldDivider}
      />;

    case "document":
      return <FieldDocument
        key={key}
        defn={defnComp as DefnFieldDocument}
      />;

    case "html":
      return <FieldHtml
        key={key}
        defn={defnComp as DefnFieldHtml}
      />;

    case "identifier":
      return <FieldIdentifier
        key={key}
        defn={defnComp as DefnFieldIdentifier}
      />;

    case "info":
      return <FieldInfo
        key={key}
        defn={defnComp as DefnFieldInfo}
      />;

    case "formListItem":
      return <FieldFormListItem
        key={key}
        defnForm={defnForm as DefnFormUi}
        defn={defnComp as DefnFieldFormListItem}
        defnTheme={defnTheme}
      />;

    case "ref":
      return <FieldRef
        key={key}
        defn={defnComp as DefnFieldRef}
      />;

    case "refReport":
      return <FieldRefReport
        key={key}
        defn={defnComp as DefnFieldRefReport}
      />;

    case "scanCode":
      return <FieldScanCode
        key={key}
        defn={defnComp as DefnFieldScanCode}
      />;

    case "showCode":
      return <FieldShowCode
        key={key}
        defn={defnComp as DefnFieldShowCode}
      />;

    case "error":
      return <FieldError
        key={key}
        defn={defnComp as DefnFieldError}
      />;

    case "hyperlinkRow":
      return <FieldHyperlinkRow
        key={key}
        defn={defnComp as DefnFieldHyperlinkRow}
      />;
    //endregion

    //region studio

    case "pickActionId":
      return <FieldStudioPickActionId
        key={key}
        defn={defnComp as DefnStudioPickActionId}
      />;

    case "pickFormId":
      return <FieldStudioPickFormId
        key={key}
        defn={defnComp as DefnStudioPickFormId}
      />;

    case "pickDeeplinkId":
      return <FieldStudioPickDeeplinkId
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "pickPluginFormId":
      return <FieldStudioPickPluginFormId
        key={key}
        defn={defnComp as DefnStudioPickPluginFormId}
      />;

    case "pickCompId":
      return <FieldStudioCompositeId
        key={key}
        defn={defnComp as DefnStudioPickCompId}
      />;

    case "pickPluginCompId":
      return <FieldStudioPickPluginCompId
        key={key}
        defn={defnComp as DefnStudioPickPluginCompId}
      />;

    case "pickGridId":
      return <FieldStudioPickGridId
        key={key}
        defn={defnComp as DefnStudioPickGridId}
      />;

    case "pickSectionId":
      return <FieldStudioSectionId
        key={key}
        defn={defnComp as DefnStudioPickSectionId}
      />;

    case "pickFieldId":
      return <FieldStudioPickFieldId
        key={key}
        defn={defnComp as DefnStudioPickFieldId}
      />;
    case "pickPluginFieldId":
      return <FieldStudioPickPluginFieldId
        key={key}
        defn={defnComp as DefnStudioPickPluginFieldId}
      />;

    case "pickLayoutFormContentId":
      return <FieldStudioPickLayoutFormContentId
        key={key}
        defn={defnComp as DefnStudioPickLayoutFormContentId}
      />;
    case "pickLayoutGridId":
      return <FieldStudioPickLayoutGridId
        key={key}
        defn={defnComp as DefnStudioPickLayoutGridId}
      />;
    case "pickLayoutSpreadsheetId":
      return <FieldStudioPickLayoutSpreadsheetId
        key={key}
        defn={defnComp as DefnStudioPickLayoutSpreadsheetId}
      />;
    case "pickDeployPaymentProviderId":
      return <FieldStudioPickPaymentProviderId
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;
    case "pickImportPluginApiId":
      return <FieldStudioPickImportPluginApiId
        key={key}
        defn={defnComp as DefnStudioPickImportPluginApiId}
      />;

    case "pickImportPluginId":
      return <FieldStudioPickImportPluginId
        key={key}
        defn={defnComp as DefnStudioPickImportPluginId}
      />;

    case "pickPluginBundleId":
      return <FieldStudioPickPluginId
        key={key}
        defn={defnComp as DefnStudioPickPluginBundleId}
      />;

    case "pickPluginId":
      return <FieldStudioPickPluginVersionId
        key={key}
        defn={defnComp as DefnStudioPickPluginId}
      />;

    case "pickReportId":
      return <FieldStudioPickReportId
        key={key}
        defn={defnComp as DefnStudioPickReportId}
      />;

    case "pickGroupId":
      return <FieldStudioPickGroupId
        key={key}
        defn={defnComp as DefnStudioPickGroupId}
      />;

    case "pickSpreadsheetId":
      return <FieldStudioPickSpreadsheetId
        key={key}
        defn={defnComp as DefnStudioPickSpreadsheetId}
      />;

    case "propertyMap":
      return <FieldPropertyMap
        key={key}
        defn={defnComp as DefnFieldPropertyMap}
      />;

    case "studioBuildAllModules":
      return <FieldStudioBuildAllModule
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "studioBuildArgBinder":
      return <FieldStudioBuildArgBinder
        key={key}
        defn={defnComp as DefnStudioBuildArgBinder}
        defnTheme={defnTheme}
      />;

    case "studioBuildMapping":
      return <FieldStudioBuildMapping
        key={key}
        defn={defnComp as DefnStudioBuildMapping}
        defnTheme={defnTheme}
        defnForm={defnForm}
      />;

    case "studioBuildColor":
      return <FieldStudioBuildColor
        key={key}
        defn={defnComp as DefnStudioBuildColor}
      />;

    case "studioBuildDate":
      return <FieldStudioBuildDate
        key={key}
        defn={defnComp as DefnStudioBuildDate}
      />;

    case "studioBuildDateTime":
      return <FieldStudioBuildDateTime
        key={key}
        defn={defnComp as DefnStudioBuildDateTime}
      />;

    case "studioBuildPermissionMatrix":
      return <FieldStudioBuildPermissionMatrix
        key={key}
        defn={defnComp as DefnStudioBuildPermissionMatrix}
      />;

    case "studioBuildTree":
      return <FieldStudioBuildTree
        key={key}
        defn={defnComp as DefnStudioBuildTree}
      />;

    case "studioBuildUserSetting":
      //TODO:
      return <div />;

    case "studioCodeEditor":
      return <FieldStudioCodeEditor
        key={key}
        defn={defnComp as DefnStudioCodeEditor}
      />;

    case "studioBuildActionPermission":
      return <FieldStudioMapOfActionPermission
        key={key}
        defn={defnComp as DefnStudioBuildActionPermission}
      />;

    case "studioMapOfArgBinder":
      return <FieldStudioMapOfArgBinder
        key={key}
        defn={defnComp as DefnStudioMapOfArgBinder}
      />;

    case "studioBuildPropertyMap":
      return <FieldStudioBuildPropertyMap
        key={key}
        defn={defnComp as DefnStudioBuildPropertyMap}
      />;

    case "studioMapOfPipelineVariable":
      return <FieldStudioMapOfPipelineVariable
        key={key}
        defn={defnComp as DefnStudioMapOfPipelineVariable}
      />;

    case "studioMapOfForwardRolePermission":
      return <FieldStudioMapOfForwardRolePermission
        key={key}
        defn={defnComp as DefnStudioMapOfForwardRolePermission}
      />;

    case "studioMapOfForwardGroupPermission":
      return <FieldStudioMapOfForwardGroupPermission
        key={key}
        defn={defnComp as DefnStudioMapOfForwardGroupPermission}
      />;

    case "studioMapOfCondition":
      return <FieldStudioMapOfCondition
        key={key}
        defn={defnComp as DefnStudioMapOfCondition}
      />;

    case "studioMapOfFormula":
      return <FieldStudioMapOfFormula
        key={key}
        defn={defnComp as DefnStudioMapOfFormula}
      />;

    case "studioMapOfFuncArg":
      return <FieldStudioMapOfFuncArg
        defn={defnComp as DefnStudioMapOfFuncArg}
      />;

    case "studioMapOfJarFile":
      return <FieldStudioMapOfJarFile
        key={key}
        defn={defnComp as DefnStudioMapOfJarFile}
      />;

    case "studioMapOfLayoutSpreadsheet":
      return <FieldStudioMapOfLayoutSpreadsheet
        key={key}
        defn={defnComp as DefnStudioMapOfLayoutSpreadsheet}
      />;

    case "studioMapOfLayoutDriveSpreadsheet":
      return <FieldStudioMapOfLayoutDriveSpreadsheet
        key={key}
        defn={defnComp as DefnStudioMapOfLayoutDriveSheet}
      />;

    case "studioMapOfLayoutGrid":
      return <FieldStudioMapOfLayoutGrid
        key={key}
        defn={defnComp as DefnStudioMapOfLayoutGrid}
      />;

    case "studioFieldMappingTree":
      return <FieldStudioFieldMappingTree
        key={key}
        defn={defnComp as DefnStudioMapOfMapping}
      />;

    case "studioGridMappingTree":
      return <FieldStudioGridMappingTree
        key={key}
        defn={defnComp as DefnStudioMapOfMapping}
      />;

    case "studioMapOfText":
      return <FieldStudioMapOfText
        key={key}
        defn={defnComp as DefnFieldEditable}
      />;

    case "studioMapOfVisibilityAction":
      return <FieldStudioMapOfVisibilityAction
        key={key}
        defn={defnComp as DefnStudioMapOfVisibilityAction}
      />;

    case "studioMapOfVisibilityCondition":
      return <FieldStudioMapOfVisibilityCondition
        key={key}
        defn={defnComp as DefnStudioMapOfVisibilityCondition}
      />;

    case "studioMapOfUserCondition":
      return <FieldStudioMapOfUserCondition
        key={key}
        defn={defnComp as DefnStudioMapOfUserCondition}
      />;

    case "studioMapOfDynamicCondition":
      return <FieldStudioMapOfDynamicCondition
        key={key}
        defn={defnComp as DefnStudioMapOfDynamicCondition}
      />;

    case "studioMapOfDynamicRule":
      return <FieldStudioMapOfDynamicRule
        key={key}
        defn={defnComp as DefnStudioMapOfDynamicRule}
      />;

    case "studioMapOfPartition":
      return <FieldStudioMapOfPartition
        key={key}
        defn={defnComp as DefnStudioMapOfPartition}
      />;

    case "studioSetOfActionId":
      return <FieldStudioSetOfActionId
        key={key}
        defn={defnComp as DefnStudioPickActionId}
      />;

    case "studioSetOfAdminDoNotOption":
      return <FieldStudioSetOfAdminDoNotOption
        key={key}
        defn={defnComp as DefnStudioSetOfAdminDoNotOption}
      />;

    case "studioSetOfFieldId":
      return <FieldStudioSetOfFieldId
        key={key}
        defn={defnComp as DefnStudioPickFieldId}
      />;

    case "studioSetOfCompId":
      return <FieldStudioSetOfCompId
        key={key}
        defn={defnComp as DefnStudioPickCompId}
      />;

    case "studioSetOfReportId":
      return <FieldStudioSetOfReportId
        key={key}
        defn={defnComp as DefnStudioPickReportId}
      />;

    case "studioSetOfFieldRefId":
      return <FieldStudioSetOfFieldRefId
        key={key}
        defn={defnComp as DefnStudioSetOfFieldRefId}
      />;

    case "studioSetOfModule":
      return <FieldStudioSetOfModule
        key={key}
        defn={defnComp as DefnStudioSetOfModule}
      />;

    case "studioSetOfFormId":
      return <FieldStudioSetOfFormId
        key={key}
        defn={defnComp as DefnStudioPickFormId}
      />;

    case "studioSetOfGridId":
      return <FieldStudioSetOfGridId
        key={key}
        defn={defnComp as DefnStudioPickGridId}
      />;

    case "studioSetOfLayoutGridId":
      return <FieldStudioSetOfLayoutGridId
        key={key}
        defn={defnComp as DefnStudioPickLayoutGridId}
      />;

    case "studioSetOfLayoutFormContentId":
      return <FieldStudioSetOfLayoutFormContentId
        key={key}
        defn={defnComp as DefnStudioPickLayoutFormContentId}
      />;

    case "studioSetOfLayoutSpreadsheetId":
      return <FieldStudioSetOfLayoutSpreadsheetId
        key={key}
        defn={defnComp as DefnStudioPickLayoutSpreadsheetId}
      />;

    case "studioSetOfGroupId":
      return <FieldStudioSetOfGroupId
        key={key}
        defn={defnComp as DefnStudioPickGroupId}
      />;

    case "studioSetOfNumber":
      return <FieldStudioSetOfNumber
        key={key}
        defn={defnComp as DefnStudioSetOfNumber}
      />;

    case "studioSetOfRowAuditTrail":
      return <FieldStudioSetOfRowAuditTrail
        key={key}
        defn={defnComp as DefnStudioSetOfRowAuditTrail}
      />;

    case "studioSetOfSectionId":
      return <FieldStudioSetOfSectionId
        key={key}
        defn={defnComp as DefnStudioPickSectionId}
      />;

    case "studioSetOfSpreadsheetId":
      return <FieldStudioSetOfSpreadsheetId
        key={key}
        defn={defnComp as DefnStudioPickSpreadsheetId}
      />;

    case "studioSetOfVarId":
      return <FieldStudioSetOfVarId
        key={key}
        defn={defnComp as DefnStudioPickVarId}
      />;

    case "pickVarId":
      return <FieldStudioPickVarId
        key={key}
        defn={defnComp as DefnStudioPickVarId}
      />;

    case "pickPipelineVarId":
      return <FieldStudioPickPipelineVarId
        key={key}
        defn={defnComp as DefnStudioPickPipelineVarId}
      />;

    case "studioVarIdTextEditor":
      return <FieldStudioVarIdTextEditor
        key={key}
        defn={defnComp as DefnStudioVarIdTextEditor}
      />;

    case "studioVarIdParagraphEditor":
      return <FieldStudioVarIdParagraph
        key={key}
        defn={defnComp as DefnStudioVarIdParagraphEditor}
      />;

    case "studioCompArray":
      return <FieldStudioArray
        defnForm={defnForm}
        defnTheme={defnTheme}
        defn={defnComp as DefnStudioCompArray}
      />;

    //endregion

    //region uiOnly

    case "avtar":
      return <FieldAvtar
        key={key}
        defn={defnComp as DefnFieldAvtar}
      />;
    case "password":
      return <FieldPassword
        key={key}
        defn={defnComp as DefnFieldPassword}
      />;
    case "formList":
      return <FieldFormList
        key={key}
        defnForm={defnForm}
        defn={defnComp as DefnFieldFormList}
        listBinderMap={props.listBinderMap}
      />;
    case "otp":
      return <FieldOtp
        key={key}
        defn={defnComp as DefnFieldOtp}
      />;
    //endregion

    default:
      return null;
  }
}
