import ISrvc from "../../../base/ISrvc";
import SrvcAdminPanelDrawerFilter from "./SrvcAdminPanelDrawerFilter";
import SrvcAdminPanelDrawerRecentList from "./SrvcAdminPanelDrawerRecentList";
import SrvcAdminPanelDrawerSearch from "./SrvcAdminPanelDrawerSearch";

export class SrvcAdminPanelDrawer extends ISrvc
{
  public readonly recentList = new SrvcAdminPanelDrawerRecentList();
  public readonly search = new SrvcAdminPanelDrawerSearch(state => state.adminPanel.drawer.productionSearch);
  public readonly filter = new SrvcAdminPanelDrawerFilter();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.recentList,
      this.search,
      this.filter
    );
  }
}
