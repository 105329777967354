import {Box} from "@mui/material";
import {SxProps} from "@mui/system/styleFunctionSx";
import {useState} from "react";
import React from "react";
import {useRef} from "react";
import {DefnDtoFormTheme} from "../../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnSection} from "../../../../../api/meta/base/dto/DefnSection";
import {EnumDefnPlacement} from "../../../../../api/meta/base/Types";
import {EnumDefnThemeDirection} from "../../../../../api/meta/base/Types";
import {getDefnDtoColorToCssColor} from "../../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../../base/plus/FormPlus";
import {px} from "../../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../../base/plus/ThemePlus";
import theme from "../../../../../base/plus/ThemePlus";
import {DefnFieldUi} from "../../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../../base/types/TypesForm";
import RawLabel from "../../../../atom/raw/RawLabel";
import {ctxFormSection} from "../../base/CtxFormSection";
import IFormSectionCtx from "../../base/CtxFormSection";
import FieldFactory from "../../base/FieldFactory";
import {getCompLabel} from "../../base/FormViewerPlus";

export default function FieldSectionReport(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnSection,
})
{
  const defnForm = props.defnForm;
  const defnTheme = props.defnTheme;
  const defn = props.defn as DefnSection;
  const label = getCompLabel(defn);

  const formSectionCtx = useRef({} as IFormSectionCtx);
  formSectionCtx.current.getParent = () => defn;
  formSectionCtx.current.getDefnForm = () => defnForm;
  formSectionCtx.current.getDefn = (key) => defnForm.compMap[key] as DefnFieldUi;

  const sizeDivider = theme.common.sizeDivider;
  const fieldId = getFieldKey(defn);
  const sectionDirection: EnumDefnThemeDirection = defn.sectionDirection ?? "vertical";
  const layout = defn.reportLayout;
  const textColorVar = layout?.textColorVar;
  const isHidden = defn?.hidden || defn?.invisible;
  const showLabel = defn.propertyEditorLabel && !isHidden;

  const [justifyContent] = useState(getJustifyContent(layout?.justifyContent));
  const [alignItems] = useState(fnAlignItemsSx(layout?.alignItems));
  const [backgroundColor] = useState(getDefnDtoColorToCssColor(layout?.backgroundColorVar));

  const borderTop = `${px(layout?.borderTop ? sizeDivider : 0)}`;
  const borderBottom = `${px(layout?.borderBottom ? sizeDivider : 0)}`;
  const borderLeft = `${px(layout?.borderLeft ? sizeDivider : 0)}`;
  const borderRight = `${px(layout?.borderRight ? sizeDivider : 0)}`;

  const pt = px(layout?.pt || 0);
  const pb = px(layout?.pb || 0);
  const pl = px(layout?.pl || 0);
  const pr = px(layout?.pr || 0);

  const commonSxProps = {
    display: isHidden ? "none" : "flex",
    height: layout?.height,
    width: layout?.width,
    maxWidth: layout?.maxWidth,
    maxHeight: layout?.maxHeight,
    minHeight: layout?.minHeight,
    minWidth: layout?.minWidth,
    flex: layout?.flex,
    flexGrow: layout?.flexGrow,
    overflow: layout?.overflow,
    overflowX: layout?.overflowX,
    overflowY: layout?.overflowY
  } as SxProps;

  const labelDefn = showLabel
    ? {
      metaId: `${fieldId}-label`,
      name: `${fieldId}-label`,
      textSize: "body2",
      bold: true,
      colorVar: {
        value: "textPrimary"
      }
    } as DefnFieldLabel
    : undefined;

  return (
    <ctxFormSection.Provider value={formSectionCtx.current}>

      <Box
        sx={{
          ...commonSxProps,
          flexDirection: "column",
          borderColor: theme.common.borderColor,
          borderStyle: "solid",
          borderWidth: `${borderTop} ${borderRight} ${borderBottom} ${borderLeft}`,
          padding: `${pt} ${pr} ${pb} ${pl}`,
          backgroundColor: backgroundColor
        }}
      >
        {
          labelDefn &&
          (
            <Box component={"span"} px={px(gapHalf)} py={px(gapQuarter)}>
              <RawLabel defn={labelDefn} value={defn.propertyEditorLabel} bold={true} textSize={"body2"} />
            </Box>
          )
        }
        <Box
          itemID={`section-${label}`}
          id={fieldId}
          sx={{
            ...commonSxProps,
            ...textColorVar?.value && {
              "& .MuiTypography-root": {
                color: theme.common.colorWithShade(textColorVar?.value, textColorVar?.shade)
              }
            },
            justifyContent: justifyContent,
            alignItems: alignItems,
            flexDirection: sectionDirection === "horizontal" ? "row" : "column"
          }}
        >
          {
            defn.fieldIdSet?.map((fieldId) =>
            {
              const defn = defnForm.compMap[fieldId] as DefnFieldUi;
              return <FieldFactory defnForm={defnForm} defnTheme={defnTheme} defnComp={defn} />;
            })
          }
        </Box>
      </Box>

    </ctxFormSection.Provider>
  );
}

function getJustifyContent(justifyContent?: EnumDefnPlacement)
{
  if(!justifyContent)
  {
    return "start";
  }
  switch(justifyContent)
  {
    case "center":
    case "end":
    case "start":
      return justifyContent;
    case "spaceBetween":
      return "space-between";
    default:
      return justifyContent;
  }
}

function fnAlignItemsSx(justifyContent?: EnumDefnPlacement)
{
  switch(justifyContent)
  {
    case "start":
      return "flex-start";
    case "spaceBetween":
      return "stretch";
    case "center":
      return justifyContent;
    case "end":
      return "flex-end";
  }
}
