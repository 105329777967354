import {useState} from "react";
import {useEffect} from "react";
import {useMemo} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldChipSetDeviceSize} from "../../api/meta/base/dto/DefnFieldChipSetDeviceSize";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldSetOfRole} from "../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickActionId} from "../../api/meta/base/dto/DefnStudioPickActionId";
import {DefnStudioPickGroupId} from "../../api/meta/base/dto/DefnStudioPickGroupId";
import {DefnStudioPickVarId} from "../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioDtoActionPermission} from "../../api/meta/base/dto/StudioDtoActionPermission";
import {EnumDefnKindAction} from "../../api/meta/base/Types";
import {MetaIdRole} from "../../api/meta/base/Types";
import {MetaIdAction} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldGap1} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldAction = "action";
const fieldTitleRoleSet = "titleRoleIdSet";
const fieldAllowedRoleIdSet = "allowedRoleIdSet";
const fieldNotAllowedRoleIdSet = "notAllowedRoleIdSet";
const fieldMenuGroup = "menuGroup";
const fieldDeviceSizeSet = "deviceSizeSet";
const fieldShowMessageTooltip = "showMessageTooltip";
const fieldMappingVarId = "mappingVarId";
const fieldGroupIdSet = "groupIdSet";

const HEIGHT_CONTENT = 600;
const WIDTH_CONTENT = 500;

export default function DialogAssignAction(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  values?: StudioDtoActionPermission,
  onClickOk: (values: StudioDtoActionPermission) => void,
  allowGrouping?: boolean,
  includeActionIdSet?: MetaIdAction[],
  excludeActionIdSet?: MetaIdAction[],
  onClose?: () => void,
  allowSystemRoles?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption,
  allowShowMessageTooltip?: boolean,
  isGroupAction?: boolean
})
{
  const values = props.values;
  const allowGrouping = props.allowGrouping;
  const includeActionIdSet = props.includeActionIdSet;
  const allowSystemRoles = props.allowSystemRoles;
  const includeOptionMap = props.includeOptionMap;
  const formStore = props.formStore;
  const allowShowMessageTooltip = props.allowShowMessageTooltip;
  const isGroupAction = props.isGroupAction;

  const actionMap = formStore?.actionMap;
  const cbRef = useMemo(() => ({} as IFormRef), []);

  const [addedActions, setAddedActions] = useState<MetaIdAction[]>(props.excludeActionIdSet || []);
  const [allowedRoleIdSet, setAllowedRoleIdSet] = useState<MetaIdRole[]>();
  const [actionKind, setActionKind] = useState<EnumDefnKindAction>();
  const [actionName, setActionName] = useState<string>();

  const defnForm = getDefnForm(allowGrouping,
    includeActionIdSet,
    addedActions,
    allowSystemRoles,
    includeOptionMap,
    allowShowMessageTooltip,
    allowedRoleIdSet,
    isGroupAction,
    actionKind
  );

  const onSubmit = useCallback((values: FieldValues) =>
  {
    const dto = valueToDto(values, allowGrouping, actionName);
    setAddedActions(prevState => [...prevState, dto.metaId]);
    props.onClickOk(dto);
  }, [actionName, allowGrouping]);

  const onWatch = useCallback((key: string, value: any) =>
  {
    if(key === fieldAction)
    {
      const action = value
        ? actionMap?.map[value]
        : undefined;

      setActionKind(action?.kind);
      action && setActionName(action.details.name);
      if(action?.kind !== "rowInsert")
      {
        if(action?.kind !== "report")
        {
          cbRef.setValue && cbRef.setValue(fieldGroupIdSet, null);
        }
        cbRef.setValue && cbRef.setValue(fieldMappingVarId, null);
      }
    }
    if(key === fieldAllowedRoleIdSet)
    {
      setAllowedRoleIdSet(fnFieldValueToRawValue("setOfRole", value) as MetaIdRole[]);
    }
  }, [actionMap?.map]);

  useEffect(() =>
  {
    if(values)
    {
      Object.entries(dtoToValue(values)).forEach(([key, value]) =>
      {
        onWatch(key, value);
      });
    }
  }, []);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} assign action`}
      contentHeight={HEIGHT_CONTENT}
      contentWidth={WIDTH_CONTENT}
      formProps={{
        cbRef: cbRef,
        defnForm: defnForm,
        store: formStore,
        formReadonly: props.isFormReadOnly,
        onSubmit: onSubmit,
        initValues: values ? dtoToValue(values, allowGrouping) : undefined,
        onWatch: onWatch
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? "Add more assign action"
        : undefined}
    />
  );
}

function valueToDto(values: FieldValues, allowGrouping?: boolean, actionName?: string): StudioDtoActionPermission
{
  return {
    metaId: fnFieldValueToRawValue("pickActionId", values[fieldAction]),
    roleIdSet: fnFieldValueToRawValue("setOfRole", values[fieldAllowedRoleIdSet]),
    notAllowedRoleIdSet: fnFieldValueToRawValue("setOfRole", values[fieldNotAllowedRoleIdSet]),
    menuGroup: allowGrouping
      ? fnFieldValueToRawValue("text", values[fieldMenuGroup])
      : undefined,
    deviceSizeSet: fnFieldValueToRawValue("chipSetDeviceSize", values[fieldDeviceSizeSet]),
    showMessageTooltip: fnFieldValueToRawValue("bool", values[fieldShowMessageTooltip]),
    mappingVarId: fnFieldValueToRawValue("pickVarId", values[fieldMappingVarId]),
    groupIdSet: fnFieldValueToRawValue("studioSetOfGroupId", values[fieldGroupIdSet]),
    name: actionName
  } as StudioDtoActionPermission;
}

function dtoToValue(dto: StudioDtoActionPermission, allowGrouping?: boolean)
{
  return {
    [fieldAction]: fnRawValueToFieldValue("pickActionId", dto.metaId),
    [fieldAllowedRoleIdSet]: fnRawValueToFieldValue("setOfRole", dto.roleIdSet),
    [fieldNotAllowedRoleIdSet]: fnRawValueToFieldValue("setOfRole", dto?.notAllowedRoleIdSet),
    [fieldMenuGroup]: allowGrouping
      ? fnRawValueToFieldValue("text", dto?.menuGroup)
      : undefined,
    [fieldDeviceSizeSet]: fnRawValueToFieldValue("chipSetDeviceSize", dto?.deviceSizeSet),
    [fieldShowMessageTooltip]: fnRawValueToFieldValue("bool", dto?.showMessageTooltip),
    [fieldMappingVarId]: fnRawValueToFieldValue("pickVarId", dto?.mappingVarId),
    [fieldGroupIdSet]: fnRawValueToFieldValue("studioSetOfGroupId", dto?.groupIdSet)
  };
}

function getDefnForm(
  allowGrouping?: boolean,
  includeActionIdSet?: MetaIdAction[],
  excludeActionIdSet?: MetaIdAction[],
  allowSystemRoles?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption,
  allowShowMessageTooltip?: boolean,
  allowedRoleIdSet?: MetaIdRole[],
  isGroupAction?: boolean,
  actionKind?: EnumDefnKindAction
)
{
  const isRowInsert = actionKind === "rowInsert";
  const isReportAction = actionKind === "report";

  const enableShowMessageTooltip = isReportAction && allowShowMessageTooltip;
  const showGroupIdSet = !isGroupAction && (isRowInsert || isReportAction);
  const showMappingVar = !isGroupAction;

  return createDefaultDefnFormStudio({
    [fieldAction]: {
      type: "pickActionId",
      name: fieldAction,
      metaId: fieldAction,
      required: true,
      filterActionKindSet: ["report", "spreadsheetEditor", "rowInsert"],
      includeActionIdSet: includeActionIdSet,
      excludeActionIdSet: excludeActionIdSet
    } as DefnStudioPickActionId,

    ...showGroupIdSet && {
      [fieldGroupIdSet]: {
        type: "studioSetOfGroupId",
        metaId: fieldGroupIdSet,
        name: fieldGroupIdSet,
        label: "Groups"
      } as DefnStudioPickGroupId
    },

    ...allowGrouping && {
      [fieldMenuGroup]: {
        type: "text",
        name: fieldMenuGroup,
        metaId: fieldMenuGroup,
        label: "Menu group"
      } as DefnFieldText
    },

    [fieldDeviceSizeSet]: {
      type: "chipSetDeviceSize",
      metaId: fieldDeviceSizeSet,
      name: fieldDeviceSizeSet,
      filterKindSet: ["desktop", "mobile"]
    } as DefnFieldChipSetDeviceSize,

    ...enableShowMessageTooltip && {
      [fieldShowMessageTooltip]: {
        type: "bool",
        metaId: fieldShowMessageTooltip,
        label: "Show message tooltip",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    ...showMappingVar && {
      [fieldMappingVarId]: {
        type: "pickVarId",
        metaId: fieldMappingVarId,
        name: fieldMappingVarId,
        label: "Mapping variable",
        varKind: "mapping"
      } as DefnStudioPickVarId
    },

    ...getFieldGap(fieldGap1, "thick"),

    [fieldTitleRoleSet]: {
      type: "label",
      metaId: fieldTitleRoleSet,
      name: fieldTitleRoleSet,
      label: "Role id set",
      disabled: true
    } as DefnFieldLabel,

    [fieldAllowedRoleIdSet]: {
      type: "setOfRole",
      name: fieldAllowedRoleIdSet,
      metaId: fieldAllowedRoleIdSet,
      label: "Allowed",
      allowSystemRoles: allowSystemRoles !== false,
      required: true,
      includeOptionMap: includeOptionMap
    } as DefnFieldSetOfRole,

    [fieldNotAllowedRoleIdSet]: {
      type: "setOfRole",
      name: fieldNotAllowedRoleIdSet,
      metaId: fieldNotAllowedRoleIdSet,
      label: "Not allowed",
      allowSystemRoles: allowSystemRoles !== false,
      includeOptionMap: includeOptionMap,
      excludeRoleIdSet: allowedRoleIdSet
    } as DefnFieldSetOfRole,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [
        fieldAction,
        ...showGroupIdSet
          ? [fieldGroupIdSet]
          : [],
        ...allowGrouping
          ? [fieldMenuGroup]
          : [],
        fieldDeviceSizeSet,
        ...enableShowMessageTooltip
          ? [fieldShowMessageTooltip]
          : [],
        ...showMappingVar
          ? [fieldMappingVarId]
          : [],
        fieldGap1,
        fieldTitleRoleSet,
        fieldAllowedRoleIdSet,
        fieldNotAllowedRoleIdSet
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);

}


