import {Box} from "@mui/material";
import {DateTimeValidationError} from "@mui/x-date-pickers";
import {DateTimePicker} from "@mui/x-date-pickers";
import {PickerChangeHandlerContext} from "@mui/x-date-pickers/models";
import {useCallback} from "react";
import {ChangeEvent} from "react";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {DefnFieldDateTimeRange} from "../../../../api/meta/base/dto/DefnFieldDateTimeRange";
import {FieldValueDateRange} from "../../../../api/meta/base/dto/FieldValueDateRange";
import {formatDateToISO} from "../../../../base/plus/DatePlus";
import {is12HourFormat} from "../../../../base/plus/DatePlus";
import {getLocalDateFormat} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {CLEAR} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {CLOSE} from "../../../../base/types/TypesIcon";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import {getRawIcon} from "../../../atom/raw/RawIcon";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import {useFormCtx} from "../base/CtxForm";
import FieldRawRefButton from "./FieldRawRefButton";

export default function FieldRawDateTimeRange(props: {
  defn: DefnFieldDateTimeRange
  onBlur: () => void
  onChange: (value?: FieldValueDateRange | null) => void
  fieldValue?: FieldValueDateRange
  readOnly?: boolean
  disabled?: boolean,
  isError?: boolean,
  required?: boolean,
  placeHolder?: string,
  icon?: string,
  minDateTime?: string,
  maxDateTime?: string,
  helperText?: string,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const defn = props.defn;
  const isError = props.isError;
  const fieldValue = props.fieldValue;
  const onBlur = props.onBlur;
  const readOnly = props.readOnly;
  const disabled = props.disabled;
  const onChange = props.onChange;
  const required = props.required;
  const placeHolder = props.placeHolder;
  const icon = props.icon;
  const minDateTime = props.minDateTime ? new Date(props.minDateTime) : undefined;
  const maxDateTime = props.maxDateTime ? new Date(props.maxDateTime) : undefined;

  const is12hrFormat = is12HourFormat(defn.displayDateFormat);

  const fieldId = defn.metaId;
  const autoFill = defn.autoFill;
  const autoFocus = defn.autoFocus;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const [startDate, setStartDate] = useState<string | undefined>();

  const dateTime = defn?.displayDateFormat
    ? defn.displayDateFormat
    : getLocalDateFormat();

  const fromValue = fieldValue?.from
    ? new Date(fieldValue.from)
    : null;
  const toValue = fieldValue?.to
    ? new Date(fieldValue.to)
    : null;

  const onChangeFrom = useCallback((date?: string) =>
  {
    if(date)
    {
      onChange({
        from: date,
        to: fieldValue?.to
      });
    }
    else
    {
      onChange(null);
    }

    setStartDate(date);

  }, [fieldValue?.to]);

  const onChangeTo = useCallback((date?: string) =>
  {
    if(fieldValue?.from)
    {
      onChange({
        from: fieldValue.from,
        to: date
      });
    }
    else
    {
      onChange(null);
    }

  }, [fieldValue?.from]);

  useEffect(() =>
  {
    if(fieldValue?.from)
    {
      setStartDate(fieldValue.from);
    }

  }, []);

  return (
    <LayoutFlexCol
      width={"100%"}
      height={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
    >
      <RawLocalizationProvider>
        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DateTimePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            format={dateTime}
            value={fromValue}
            label={"Date time from"}
            disabled={disabled}
            readOnly={readOnly}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}
            autoFocus={autoFocus}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            onClose={onBlur}
            ampm={is12hrFormat}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const dateTimeValue = new Date(event);

                onChangeFrom(formatDateToISO(dateTimeValue));
              }
            }}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            slotProps={{
              textField: {
                fullWidth: true,
                type: "text",
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: fieldVariant,
                autoComplete: autoFill ? "on" : "off",
                required: required,
                onClick: onClick,
                onBlur: onBlur,
                error: isError,
                placeholder: placeHolder,
                value: fromValue,
                //@ts-ignore
                onChange: (
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                  context: PickerChangeHandlerContext<DateTimeValidationError>) =>
                {
                  const eventValue = event as unknown as string;

                  if(context.validationError !== "invalidDate")
                  {
                    const dateTimeValue = new Date(eventValue);

                    onChangeFrom(formatDateToISO(dateTimeValue));
                  }
                }
              }
            }}
          />
          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
                iconStrip={[CLOSE]}
                onClick={(icon) =>
                {
                  if(icon === CLOSE)
                  {
                    onChangeFrom(undefined);
                  }
                }}
                iconStripDisable={(!fieldValue?.from || readOnly || disabled) ? [CLOSE] : []}
              />
            </LayoutFlexRow>
          }
          <FieldRawRefButton
            defn={defn}
          />
        </LayoutFlexRow>

        <LayoutGap height={gapStd} />

        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DateTimePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            format={dateTime}
            value={toValue}
            label={"Date time to"}
            disabled={(!startDate || disabled) && !readOnly}
            minDateTime={startDate ? new Date(startDate) : undefined}
            maxDateTime={maxDateTime}
            readOnly={readOnly}
            autoFocus={autoFocus}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            timeSteps={{
              hours: 1,
              minutes: 1,
              seconds: 1
            }}
            ampm={is12hrFormat}
            onOpen={onBlur}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const dateTimeValue = new Date(event);

                onChangeTo(formatDateToISO(dateTimeValue));
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                type: "text",
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: fieldVariant,
                autoComplete: autoFill ? "on" : "off",
                required: required,
                onClick: onClick,
                onBlur: onBlur,
                error: isError,
                placeholder: placeHolder,
                value: toValue,
                //@ts-ignore
                onChange: (
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                  context: PickerChangeHandlerContext<DateTimeValidationError>) =>
                {
                  const eventValue = event as unknown as string;

                  if(context.validationError !== "invalidDate")
                  {
                    const dateTimeValue = new Date(eventValue);

                    onChangeTo(formatDateToISO(dateTimeValue));
                  }
                }
              }
            }}
          />
          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
                iconStrip={[CLOSE]}
                onClick={(icon) =>
                {
                  if(icon === CLOSE)
                  {
                    onChangeTo(undefined);
                  }
                }}
                iconStripDisable={(!fieldValue?.to || readOnly || disabled) ? [CLOSE] : []}
              />
            </LayoutFlexRow>
          }
          <Box sx={{visibility: "hidden"}}>
            <FieldRawRefButton
              defn={defn}
            />
          </Box>

        </LayoutFlexRow>
      </RawLocalizationProvider>
    </LayoutFlexCol>
  );
}

