import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {IDataGridCellRendererParams} from "../../../../base/types/TypeDataGrid";
import {getCellTextColor} from "../DataGridPlus";
import GridCellDefault from "./GridCellDefault";
import GridCellDocument from "./GridCellDocument";
import GridCellImage from "./GridCellImage";
import GridCellInfo from "./GridCellInfo";
import GridCellLocation from "./GridCellLocation";
import GridCellSignature from "./GridCellSignature";
import GridCellTime from "./GridCellTime";
import GridCellUser from "./GridCellUser";

export default function GridCellFactory<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  params: IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>,
  cellType?: EnumDefnCompType
})
{
  const cellType = props.cellType;
  const color = getCellTextColor(props.params);

  switch(cellType)
  {
    case "signature":
      return <GridCellSignature {...props.params} />;
    case "location":
      return <GridCellLocation {...props.params} />;
    case "info":
      return <GridCellInfo {...props.params} />;
    case "camera":
    case "image":
      return <GridCellImage {...props.params} />;
    case "document":
      return <GridCellDocument {...props.params} />;
    case "refUser":
    case "userId":
    case "pickUser":
    case "setOfUser":
      return <GridCellUser {...props.params} />;
    case "time":
      return <GridCellTime {...props.params} color={color} />;
    default:
      return <GridCellDefault {...props.params} color={color} />;
  }
}

