import {SigSpreadsheetRowExpiry} from "../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {DtoMessagePayload} from "../../api/home/base/dto/DtoMessagePayload";
import {DtoMessagePayloadCamera} from "../../api/home/base/dto/DtoMessagePayloadCamera";
import {DtoMessagePayloadImage} from "../../api/home/base/dto/DtoMessagePayloadImage";
import {DtoMessagePayloadSpreadsheetPartition} from "../../api/home/base/dto/DtoMessagePayloadSpreadsheetPartition";
import {DtoMessagePayloadSpreadsheetRow} from "../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {DtoMessagePayloadVideo} from "../../api/home/base/dto/DtoMessagePayloadVideo";
import {SigMessage} from "../../api/home/main/sig/SigMessage";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldHyperlink} from "../../api/meta/base/dto/DefnFieldHyperlink";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdComp} from "../../api/meta/base/Types";
import {BubbleLineFooterProgress} from "../../nucleus/atom/bubble/line/BubbleLineFooter";
import {IBubbleMessage} from "../types/TypesBubble";
import {STR_EXPIRED_TEXT} from "./ConstantsPlus";
import {convertMillisecondsToLargestTwoUnit} from "./DatePlus";
import {formatDetailedDateTime} from "./DatePlus";
import {getFormFieldValueAsTextWithPrefixSuffix} from "./FieldValuePlus";
import {fnFieldValueToRawValue} from "./FieldValuePlus";
import {getValidFormFieldValue} from "./FormPlus";

const TIME_LEFT_TEXT = "left";

export function createBubble(sigMessage: SigMessage): IBubbleMessage
{

  const sigSpreadsheetRow = sigMessage.payload.messageType === "spreadsheetRow"
    ? (sigMessage.payload as DtoMessagePayloadSpreadsheetRow)?.spreadsheetRow
    : sigMessage.payload.messageType === "spreadsheetPartition"
      ? (sigMessage.payload as DtoMessagePayloadSpreadsheetPartition)?.spreadsheetRow
      : undefined;

  return {
    ...sigMessage,
    creationTime: formatDetailedDateTime(new Date(sigMessage.creationTime)),
    sigSpreadsheetRow: sigSpreadsheetRow
  };
}

export function getMediaMessageBubbleSrc(payload: DtoMessagePayload)
{
  if(payload === undefined)
  {
    return;
  }

  switch(payload.messageType)
  {
    case "image":
      return (payload as DtoMessagePayloadImage).mediaIdImage;
    case "camera":
      return (payload as DtoMessagePayloadCamera).mediaIdImage;
    case "video":
      return (payload as DtoMessagePayloadVideo).mediaId;
  }
}

export function getMediaMessageBubbleBlurSrc(payload: DtoMessagePayload)
{
  if(payload === undefined)
  {
    return;
  }

  switch(payload.messageType)
  {
    case "image":
      return (payload as DtoMessagePayloadImage).mediaIdBlurImage;
    case "camera":
      return (payload as DtoMessagePayloadCamera).mediaIdBlurImage;
    case "video":
      return (payload as DtoMessagePayloadVideo).mediaIdBlurImage;
  }
}

export function resolveBubbleFooterTimer(sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry): BubbleLineFooterProgress | undefined
{
  if(sigSpreadsheetRowExpiry)
  {
    const readProgressPercentage = sigSpreadsheetRowExpiry.remainingReadProgressPercentage;
    const readTimeMillis = sigSpreadsheetRowExpiry.remainingReadTimeMillis;
    const invisibleProgressPercentage = sigSpreadsheetRowExpiry.remainingInvisibleProgressPercentage;
    const invisibleTimeMillis = sigSpreadsheetRowExpiry.remainingInvisibleTimeMillis;
    const showTimer = sigSpreadsheetRowExpiry.showTimer;

    const readProgress = fnResolveProgress(readProgressPercentage, readTimeMillis);
    const invisibleProgress = fnResolveProgress(invisibleProgressPercentage, invisibleTimeMillis);

    if(!readProgress && !invisibleProgress)
    {
      return undefined;
    }

    if(!showTimer)
    {
      return undefined;
    }

    if(readProgress
      && ((invisibleProgress && readProgress.value !== STR_EXPIRED_TEXT)
        || !invisibleProgress))
    {
      return {
        ...readProgress,
        icon: "lock"
      };
    }
    else if(invisibleProgress)
    {
      return {
        ...invisibleProgress,
        icon: "invisible"
      };
    }

  }
}

function fnResolveProgress(
  remainingProgressPercentage?: number,
  remainingReadTimeMillis?: number): BubbleLineFooterProgress | undefined
{
  if(remainingProgressPercentage !== undefined && remainingReadTimeMillis !== undefined)
  {
    if(remainingProgressPercentage >= 0 && remainingReadTimeMillis >= 0)
    {
      const {
        curr,
        prev
      } = convertMillisecondsToLargestTwoUnit(remainingReadTimeMillis);

      const currVal = curr?.value;
      const currLabel = curr?.label;
      const prevVal = prev?.value;
      const prevLabel = prev?.label;
      const labelText = currVal
        ? `${currVal} ${currLabel} ${prevVal ? `${prevVal} ${prevLabel}` : ""} ${TIME_LEFT_TEXT}`
        : STR_EXPIRED_TEXT;

      return {
        value: labelText,
        timer: 100 - remainingProgressPercentage // flip the percentage
      };

    }
    else
    {
      return {
        value: STR_EXPIRED_TEXT,
        timer: 100    //percentage
      };
    }
  }
}

export function isBubbleFormValueFieldMediaType(comp?: DefnComp): boolean
{
  if(comp?.type === "hyperlink" && (comp as DefnFieldHyperlink).defaultVar?.kind === "youtube")
  {
    return true;
  }
  return (comp?.type === "image"
    || comp?.type === "camera"
    || comp?.type === "voice"
    || comp?.type === "video"
    || comp?.type === "audio"
    || comp?.type === "html"
  );
}

export function getMsgPayloadRowId(payload: DtoMessagePayload)
{
  if(payload?.messageType === "spreadsheetPartition")
  {
    return (payload as DtoMessagePayloadSpreadsheetPartition)?.rowId;
  }
  else if(payload?.messageType === "spreadsheetRow")
  {
    return (payload as DtoMessagePayloadSpreadsheetRow)?.rowId;
  }
}

export function getMsgPayloadSpreadsheetId(payload: DtoMessagePayload)
{
  if(payload?.messageType === "spreadsheetRow")
  {
    return (payload as DtoMessagePayloadSpreadsheetRow)?.spreadsheetId;
  }
  else if(payload?.messageType === "spreadsheetPartition")
  {
    return (payload as DtoMessagePayloadSpreadsheetPartition)?.spreadsheetId;
  }
}

export function getMsgPayloadSpreadsheetPartitionId(payload: DtoMessagePayload)
{
  if(payload?.messageType === "spreadsheetRow")
  {
    return (payload as DtoMessagePayloadSpreadsheetRow)?.spreadsheetPartitionId;
  }
  else if(payload?.messageType === "spreadsheetPartition")
  {
    return (payload as DtoMessagePayloadSpreadsheetPartition)?.spreadsheetPartitionId;
  }
}

export function isFormValueAndFormFieldIsMediaType(filteredForm: DefnForm, valueMap: Record<MetaIdComp, any>)
{
  return Object.values(filteredForm.compMap).some((comp) =>
  {
    const fieldId = (comp as DefnField);
    return isBubbleFormValueFieldMediaType(fieldId) && valueMap[fieldId?.metaId];
  });
}

export function fnIsHiddenBubbleFormField(field: DefnField,
  valueMap?: Record<MetaIdField, any>,
  hiddenFieldsType?: string[])
{
  const fieldId = field.metaId;
  const rawValue = fnFieldValueToRawValue(field.type, valueMap?.[fieldId]);
  if(!field || rawValue === undefined)
  {
    return true;
  }

  return field.hidden || field.invisible || hiddenFieldsType?.includes(field?.type as EnumDefnCompType);
}

export function fnGetBubbleFormFieldText(field: DefnField, valueMap?: Record<MetaIdField, any>)
{
  return getFormFieldValueAsTextWithPrefixSuffix(field, getValidFormFieldValue(valueMap?.[field.metaId]));
}
