import * as MuiIcons from "@mui/icons-material";
import {GridOnOutlined} from "@mui/icons-material";
import React from "react";
import {EnumDefnThemeColorShade} from "../../../api/meta/base/Types";
import {stripIconSx} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import {EnumDefnColoredIcon} from "../../../base/types/TypesIcon";
import Google from "../assets/google.svg";
import MicroSoft from "../assets/microsoft.svg";
import Password from "../assets/password.svg";
import QrCode from "../assets/QrCode2Rounded.svg";
import WhatsApp from "../assets/whatsapp.svg";

export default function RawIcon(props: {
  icon: string | EnumDefnColoredIcon,
  disabled?: boolean,
  color?: TypeTextColor,
  shade?: EnumDefnThemeColorShade,
  width?: string,
})
{
  const icon = props.icon;
  switch(icon)
  {
    case "WhatsAppColored":
      return <RawIconImage iconName={WhatsApp} />;
    case "MicroSoftColored":
      return <RawIconImage iconName={MicroSoft} />;
    case "GoogleColored":
      return <RawIconImage iconName={Google} />;
    case "PasswordColored":
      return <RawIconImage iconName={Password} />;
    case "QrCodeColored":
      return <RawIconImage iconName={QrCode} />;
    default:
      return <RawRealIcon {...props} />;
  }
}

function RawRealIcon(props: {
  icon: string,
  disabled?: boolean,
  color?: TypeTextColor,
  shade?: EnumDefnThemeColorShade,
  width?: string,
})
{
  const iconName = props.icon;
  const disabled = props.disabled;
  const color = props.color;
  const width = props.width;
  const shade = props.shade;

  // @ts-ignore
  const ActionIcon = iconName ? MuiIcons[iconName] : null;

  const sx = stripIconSx(disabled ? theme.common.fgcolorIconDisabled : color);
  const filteredSx = {
    ...sx,
    ...width && {
      width: width
    },
    ...(color && !disabled) && {
      color: theme.common.colorWithShade(color, shade)
    }
  };

  return (
    ActionIcon
      ? <ActionIcon
        sx={filteredSx}
      />
      :
      <GridOnOutlined
        sx={filteredSx}
      />
  );
}

export function getRawIcon(iconName: string)
{
  // @ts-ignore
  return MuiIcons[iconName];
}

function RawIconImage(props: {iconName: React.ReactNode})
{
  return (<img
    src={props.iconName as EnumDefnColoredIcon}
    alt={"icon"}
  />);
}
