import {px} from "../../../base/plus/StringPlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import BannerMain from "../assets/NeomeLogoWhite.svg";
import LayoutFlexCol from "../layout/LayoutFlexCol";

const MAX_HEIGHT_BANNER_MOBILE = 180;
const MAX_HEIGHT_BANNER_WIDGET = 130;
const WIDGET_BANNER_SIZE = 110;
const MOBILE_BANNER_SIZE = 150;
const WIDGET_SIZE = 357;
const MOBILE_SIZE = 1110;

export default function LogoMainAuth(props: {
  winWidth?: number,
  borderRadius?: number,
})
{
  const shrinkImage = props.winWidth ? props.winWidth <= MOBILE_SIZE : false;
  const isWidget = props.winWidth ? props.winWidth <= WIDGET_SIZE : false;

  return (
    <LayoutFlexCol
      flexGrow={1}
      width={"100%"}
      bgcolor={theme.common.color("primaryDark")}
      height={"100%"}
      maxHeight={shrinkImage ? isWidget ? px(MAX_HEIGHT_BANNER_WIDGET) : px(MAX_HEIGHT_BANNER_MOBILE) : undefined}
      padding={px(gapHalf)}
      borderRadius={px(props.borderRadius)}
    >
      <img
        style={{
          width: shrinkImage ? isWidget ? px(WIDGET_BANNER_SIZE) : px(MOBILE_BANNER_SIZE) : undefined,
          height: shrinkImage ? isWidget ? px(WIDGET_BANNER_SIZE) : px(MOBILE_BANNER_SIZE) : undefined
        }}
        src={BannerMain}
        alt={"neome banner"}
      />
    </LayoutFlexCol>
  );
}
