import {combineReducers} from "@reduxjs/toolkit";
import {sliceCacheAdminPanelFilter} from "./filter/SliceCacheAdminPanelFilter";
import {sliceCacheAdminPanel} from "./SliceCacheAdminPanel";

export function reducersCacheAdminPanel()
{
  return combineReducers({
    app: sliceCacheAdminPanel.reducer,
    filter: sliceCacheAdminPanelFilter.reducer
  });
}
