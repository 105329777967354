import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {DateValidationError} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers";
import {PickerChangeHandlerContext} from "@mui/x-date-pickers/models";
import {Property} from "csstype";
import {ChangeEvent} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {Noop} from "react-hook-form";
import {DefnBuildDate} from "../../../../api/meta/base/dto/DefnBuildDate";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldDate} from "../../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueDate} from "../../../../api/meta/base/dto/FieldValueDate";
import {formatDateToISO} from "../../../../base/plus/DatePlus";
import {getLocalDateFormat} from "../../../../base/plus/DatePlus";
import {extractDateFormat} from "../../../../base/plus/DatePlus";
import {getBuildDateDefaultValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {CLEAR} from "../../../../base/types/TypesIcon";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {CLOSE} from "../../../../base/types/TypesIcon";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {getRawIcon} from "../../../atom/raw/RawIcon";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

const dateFormat = getLocalDateFormat();

export default function FieldDate(props: {
  defn: DefnFieldDate
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const getOnClick = formCtx.getOnClick();
  const hideLabel = defn.hideLabel;
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;
  const label = getCompLabel(defn);
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const {
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);

  const helperText = getFieldHelperText();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;

        const isError = Boolean(error);
        const fieldValue = field.value === null ? undefined : field.value as FieldValueDate | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <RawDate
                defn={defn}
                defnTheme={defnTheme}
                onClick={onClick}
                onChange={field.onChange}
                fieldValue={fieldValue}
                label={label}
                onBlur={field.onBlur}
                error={error}
                isError={isError}
                readOnly={readOnly}
                disabled={disabled}
                hideLabel={hideLabel}
                borderColor={borderColor}
              />
              <FormHelperText
                error={isError}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1
                }}
              >
                {error?.message ? error.message : helperText}
              </FormHelperText>
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawDate(props: {
  defn: DefnFieldDate,
  defnTheme: DefnDtoFormTheme,
  label?: string,
  fieldValue?: FieldValueDate | null,
  readOnly?: boolean,
  disabled?: boolean,
  isError?: boolean,
  error?: FieldError,
  onChange: (value?: FieldValueDate | null) => void
  onBlur?: Noop,
  onClick?: () => void,
  hideLabel?: boolean,
  borderColor?: Property.BorderColor
})
{
  const {
    defn,
    label,
    isError,
    defnTheme,
    fieldValue,
    disabled,
    readOnly,
    onBlur,
    onClick,
    onChange,
    hideLabel,
    borderColor
  } = props;

  const {
    getFieldPlaceHolder,
    getFieldRequired,
    getFieldMinDate,
    getFieldMaxDate,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const icon = getFieldIcon();
  const placeholder = getFieldPlaceHolder();
  const dateOnly = defn?.displayDateFormat
    ? extractDateFormat(defn.displayDateFormat)
    : extractDateFormat(dateFormat);

  const buildMinDate = getFieldMinDate() ? getBuildDateDefaultValue(getFieldMinDate() as DefnBuildDate) : undefined;
  const buildMaxDate = getFieldMaxDate() ? getBuildDateDefaultValue(getFieldMaxDate() as DefnBuildDate) : undefined;
  const minDate = buildMinDate ? new Date(buildMinDate) : undefined;
  const maxDate = buildMaxDate ? new Date(buildMaxDate) : undefined;
  const required = getFieldRequired();

  return (
    <LayoutFlexRow
      width={"100%"}
      height={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
      onClick={event =>
      {
        onClick && onClick();
        event.stopPropagation();
      }}
    >
      <RawLocalizationProvider>
        <DatePicker
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          minDate={minDate}
          maxDate={maxDate}
          label={hideLabel ? undefined : label}
          format={dateOnly}
          value={fieldValue?.value
            ? new Date(fieldValue?.value)
            : null}
          views={["year", "month", "day"]}
          autoFocus={Boolean(defn.autoFocus)}
          readOnly={readOnly}
          disabled={disabled}
          slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
          slotProps={{
            inputAdornment: {},
            textField: {
              placeholder: placeholder,
              fullWidth: true,
              size: defnTheme.fieldSize,
              margin: defnTheme.fieldMargin,
              variant: defnTheme.fieldVariant,
              autoComplete: Boolean(defn.autoFill) ? "on" : "off",
              required: Boolean(required),
              onBlur: onBlur,
              //@ts-ignore
              onChange: (
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                context: PickerChangeHandlerContext<DateValidationError>) =>
              {
                const eventValue = event as unknown as string;

                if(context.validationError !== "invalidDate")
                {
                  const dateValue = new Date(eventValue);

                  onChange({
                    value: formatDateToISO(dateValue)
                  });
                }
              },
              error: isError
            }
          }}
          onChange={(event, context) =>
          {
            if(event && !context.validationError)
            {
              const selectedDate = new Date(event);

              onChange({
                value: formatDateToISO(selectedDate)
              } as FieldValueDate);
            }
          }}
        />

      </RawLocalizationProvider>

      {!(readOnly || disabled) &&
        <LayoutFlexRow
          ml={px(gapHalf)}
          mr={`-${gapHalf}px`}
        >
          <RawIconStrip
            toolTipMap={{[CLOSE]: CLEAR} as Record<EnumIconStrip, string>}
            iconStrip={[CLOSE]}
            onClick={(icon) =>
            {
              if(icon === CLOSE)
              {
                onChange(null);
              }
            }}
            iconStripDisable={(!fieldValue?.value || readOnly || disabled) ? [CLOSE] : []}
          />
        </LayoutFlexRow>
      }
      <FieldRawRefButton
        defn={defn}
      />
    </LayoutFlexRow>
  );
}

