import {combineReducers} from "@reduxjs/toolkit";
import {reducersCacheAdminPanel} from "./adminPanel/StoreCacheAdminPanel";
import {reducersCacheApiBr} from "./apiBr/StoreCacheApiBr";
import {reducersCacheApp} from "./app/StoreCacheApp";
import {reducersCacheHome} from "./home/StoreCacheHome";
import {reducersCacheStore} from "./store/StoreCacheStore";
import {reducersCacheStudio} from "./studio/StoreCacheStudio";
import {sliceTerminal} from "./terminal/SliceCacheTerminal";
import {sliceCacheTools} from "./tools/SliceCacheTools";

export function reducersCache()
{
  return combineReducers({
    app: reducersCacheApp(),
    apiBr: reducersCacheApiBr(),
    home: reducersCacheHome(),
    studio: reducersCacheStudio(),
    store: reducersCacheStore(),
    adminPanel: reducersCacheAdminPanel(),
    tools: sliceCacheTools.reducer,
    terminal: sliceTerminal.reducer
  });
}
